import React, { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// redux
import { useDispatch } from "react-redux";
import * as ReduxDialogs from "../../../redux/features/dialogs";
import apiService from "../../../redux/services/api";
import { HTTPMethod } from "../../../redux/services/api";

// mobx
import { bookingStore } from "./../../../stores/booking-store";
import { toastStore } from "../../../stores/toast-store";

// utils
import { colors } from "./../../../util/consts";

// components
import Error from "./../../../components/form/Error";
import LoadingButton from "./../../../components/LoadingButton";
import Section from "./../../../components/form/Section";
import DropdownSelect from "./../../../components/DropdownSelect";
import LoadingIcon from "./../../../components/icons/Loading";

import {
  AccordianForm,
  AccordianContainer,
  AccordianEdit,
  NewHeader,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  ButtonContainer,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";
import { SpecificLocationClientSelector } from "./../../../components/form/ResourceSelector";

import ClearIcon from "./../../../components/icons/Clear";
import Options from "./../../../components/icons/Options";

// grpc
import { Booking, BookingClient } from "sdk/dist/bookings_pb";

interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject | null;
  setClient: (clientId: string) => void;
  closeNew?: () => void;
}

type FormSchema = { clientID: string };

export const ClientAccordian: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const schema = Yup.object().shape<FormSchema>({
    clientID: Yup.string().required("Client id is required")
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormSchema>({
    resolver: yupResolver(schema)
  });

  const [tmpClientObj, setTmpClientObj] = useState<FormSchema | undefined>(undefined);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const cancelClient = () => {
    const { client, booking } = props;
    if (!client) {
      return;
    }
    dispatch(ReduxDialogs.openCancel(null, booking.id, client.clientId, () => {}));
  };

  const approveClient = async () => {
    const { booking, client } = props;
    if (!client) {
      return;
    }
    setIsLoading(true);
    try {
      await apiService.performRequest({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${booking.locationId}/bookings/${booking.id}/clients/${client.clientId}/action`,
        data: { Action: "ApproveBooking" }
      });
      bookingStore.load(booking.id);
      toastStore.success("Client approved.");
      setIsLoading(false);
    } catch (err) {
      const errRes = apiService.errorProcess(err).userMessages;
      toastStore.error(errRes);
      setIsLoading(false);
    }
  };

  const rejectClient = () => {
    const { booking, client } = props;
    if (!client) {
      return;
    }
    // RejectDialog is in type, requiring a protobuff booking and client
    // it only uses ID now so we just set the ID.
    //If it requires the complete obj, we can initialize manually, get it from the mobx store or call gRpc
    const bookingTyped = new Booking();
    bookingTyped.setId(booking.id);
    const clientTyped = new BookingClient();
    clientTyped.setClientId(client.clientId);

    dispatch(
      ReduxDialogs.openReject(null, bookingTyped.toObject(), clientTyped.toObject(), () => {})
    );
    bookingStore.load(booking.id);
  };

  const onSubmit = handleSubmit(async ({ clientID }: FormSchema) => {
    const { booking } = props;
    try {
      await apiService.performRequest({
        method: HTTPMethod.Post,
        path: `/api/v1/locations/${booking.locationId}/bookings/${booking.id}/clients`,
        data: { Client: clientID }
      });
      bookingStore.load(booking.id);
      props.closeNew!();
    } catch (err) {
      const errRes = apiService.errorProcess(err).userMessages;
      toastStore.error(errRes);
    }
    setIsSubmitting(false);
  });

  const renderContent = () => {
    const { booking, client } = props;
    return (
      <AccordianForm id={`book-off-${booking.id}-form`} onSubmit={onSubmit}>
        <Section>
          <SpecificLocationClientSelector
            placeholder="Select a client"
            location={booking.locationId}
            onChange={(e: any) => {
              setValue("clientID", e.target.value.ID);
              setTmpClientObj(e.target.value);
            }}
            value={tmpClientObj}
            org={booking.organisationId}
          />
          <input {...register("clientID")} hidden={true}></input>
          {errors.clientID && <Error>{errors.clientID!.message}</Error>}
        </Section>

        <ButtonContainer>
          <LoadingButton
            style={{
              minWidth: 150
            }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  };

  const { client, closeNew, setClient } = props;

  let editOptions;
  if (client) {
    switch (client.status) {
      case BookingClient.Status.CREATED:
        editOptions = [
          {
            label: "Approve",
            onClick: approveClient
          },
          {
            label: "Reject",
            onClick: rejectClient
          }
        ];
        break;
      case BookingClient.Status.APPROVED:
        editOptions = [
          {
            label: "Reject",
            onClick: rejectClient
          }
        ];
        break;
      case BookingClient.Status.REJECTED:
        editOptions = [
          {
            label: "Approve",
            onClick: approveClient
          }
        ];
        break;
      case BookingClient.Status.CANCELLED:
        editOptions = [
          {
            label: "Approve",
            onClick: approveClient
          }
        ];
        break;
    }
  }

  return (
    <AccordianContainer>
      {!client ? null : (
        <AccordianEdit mayClick onClick={() => setClient(client.clientId)}>
          <ButtonHead>
            {client.firstName} {client.lastName}
          </ButtonHead>
          <Separator />
          <ButtonDetails>
            {client.mobileNumber !== "" ? client.mobileNumber : client.email}
          </ButtonDetails>
          <Separator />
          <ButtonDetails>{mapClientStatus(client)}</ButtonDetails>
          {isLoading ? (
            <OptionsContainer>
              <LoadingIcon width={16} height={16} color="#2c2e3c" />
            </OptionsContainer>
          ) : (
            <OptionsContainer>
              {open ? (
                <NoSpacingIconButton onClick={toggleOpen}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              ) : (
                <DropdownSelect options={editOptions}>
                  <NoSpacingIconButton>
                    <Options />
                  </NoSpacingIconButton>
                </DropdownSelect>
              )}
            </OptionsContainer>
          )}
        </AccordianEdit>
      )}
      {(open || !client) && (
        <React.Fragment>
          {!client && (
            <NewHeader>
              <ButtonHead>Add Client</ButtonHead>
              <NoSpacingIconButton onClick={closeNew}>
                <ClearIcon fill={colors.primary.main} />
              </NoSpacingIconButton>
            </NewHeader>
          )}
          {renderContent()}
        </React.Fragment>
      )}
    </AccordianContainer>
  );
};

function mapClientStatus(client: BookingClient.AsObject) {
  switch (client.status) {
    case BookingClient.Status.CREATED:
      return "Waiting for Approval";
    case BookingClient.Status.APPROVED:
      return "Approved";
    case BookingClient.Status.REJECTED:
      return "Rejected";
    case BookingClient.Status.CANCELLED:
      return "Cancelled";
  }
  return "";
}
