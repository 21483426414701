import React, { Component } from "react";
import styled from "@emotion/styled";

import { colors, hexToRgba } from "./../util/consts";

import Dropdown from "./../components/Dropdown";

class DropdownCheck extends Component {
  state = {
    showDropdown: false
  };

  onClick = () => {
    this.setState({ showDropdown: true });
  };
  onMouseLeave = () => {
    this.setState({ showDropdown: false });
  };
  onSelect = (op) => () => {
    const { value, options, onChange } = this.props;
    const withoutVal = value.filter((v) => v !== op.value);
    if (value.length === withoutVal.length) {
      onChange(
        options
          .filter((v) => v.value === op.value || value.indexOf(v.value) >= 0)
          .map((v) => v.value)
      );
    } else {
      onChange(withoutVal);
    }
  };

  render() {
    const { showDropdown } = this.state;
    const { options, value, children } = this.props;
    return (
      <Dropdown
        isOpen={showDropdown}
        shouldTop
        onClickOutside={this.onMouseLeave}
        render={({ styles, ref }) => (
          <Container style={styles} ref={ref}>
            {options.map((op) => (
              <Label key={op.value}>
                <Input
                  type="checkbox"
                  checked={value.indexOf(op.value) >= 0}
                  onChange={this.onSelect(op)}
                />
                <Checkmark checked={value.indexOf(op.value) >= 0} />
                <Lbl>{op.label}</Lbl>
              </Label>
            ))}
          </Container>
        )}
      >
        {React.cloneElement(React.Children.only(children), { onClick: this.onClick })}
      </Dropdown>
    );
  }
}

const Container = styled.div`
  margin-top: -25%;
  background-color: ${colors.surface.light};
  border-radius: 4px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  width: 250px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: calc(100% + 1px);
    right: 8px;
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${hexToRgba(colors.primary.main, 0.1)} transparent;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 8px;
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${colors.surface.light} transparent;
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 10px;

  color: #00b1b6;
  transition: border-color 0.5s ease, background-color 0.5s ease;
  &:hover {
    color: black;
    opacity: 0.8;
  }
`;

const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  background: transparent;
  border: none;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &::after {
    content: "";
    position: absolute;
    display: none;
    color: black;
  }
`;

const Checkmark = styled.span`
  height: 25px;
  flex: 0 0 25px;
  margin-right: 20px;
  background-color: white;
  border: 2px solid #eee;
  border-radius: 50%;
  color: black;
  /* -webkit-transition: width 0.25s ease-out, height 0.25s ease-out 0.25s; */
  transition: border-color 0.5s ease, background-color 0.5s ease;

  &:hover {
    background-color: #00b1b6;
    box-shadow: inset 0px 0px 0px 4px #fff;
  }
  input:checked + & {
    background-color: #00b1b6;
    box-shadow: inset 0px 0px 0px 4px #fff;
    border: 2px solid #00b1b6;
  }
  &:after {
    background-color: black;
  }
`;

const Lbl = styled.span``;

export default DropdownCheck;
