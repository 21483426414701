import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { UserAppointmentsIframe } from "../../mobile-app-iframes/appointments/UserAppointmentsIframe";
import { BookingSessionIframePage } from "../../mobile-app-iframes/bookings/BookingSessionIframe";
import Footer, { ClientType } from "../../mobile-app-iframes/footerItems/Footer";
import LyfeSubscriptionInfo from "../../mobile-app-iframes/footerItems/LyfeSubscriptionInfo";
import PrivacyPolicy from "../../mobile-app-iframes/footerItems/Privacy";
import TermsOfUse from "../../mobile-app-iframes/footerItems/Terms";
import { UserPaymentMethodIframePage } from "../../mobile-app-iframes/profile/UserPaymentMethodIframePage";
import { UpgradeClientAccountIFramePage } from "../../mobile-app-iframes/upgrade-client-account-to-organisation/UpgradeClientAccountPage";
import AuthRoute from "../elements/AuthRoute";
import { LocationAccounts } from "./Accounts/LocAccounts";
import LocEdit from "./LocEdit";
import NotFound from "./NotFound";
import OrgEdit from "./OrgEdit";
import OrgLocationClients from "./OrgLocationClients";
import { OrgLocationDashboard } from "./OrgLocationDashboard";
import OrgLocationOfferings from "./OrgLocationOfferings";
import OrgLocationSchedule from "./OrgLocationSchedule";
import OrgLocationStaff from "./OrgLocationStaff";
import Insight from "./InsightDashboard";

export const IFrameRoutes = () => {
  const match = useRouteMatch();
  const params = useParams<{ appName: string }>();
  const path = match && match.path ? match.path : "";

  if (params.appName.toLowerCase() !== "lyfe" && params.appName.toLowerCase() !== "athletehub") {
    return (
      <div>
        <Route component={NotFound} />
      </div>
    );
  }

  return (
    <div>
      <Switch>
        <Route component={BookingSessionIframePage} path={`${path}/providers/:pid`} exact />
        <Route component={UserPaymentMethodIframePage} path={`${path}/users/edit`} exact />
        <Route component={UserAppointmentsIframe} path={`${path}/users/appointments`} exact />
        <Route component={UpgradeClientAccountIFramePage} path={`${path}/account/upgrade`} exact />

        {/* Organization dashboard page routes */}
        <AuthRoute
          path={`${path}/admin/locs/:lid/dashboard`}
          component={OrgLocationDashboard}
          exact
        />
        <AuthRoute
          path={[`${path}/admin/locs/:lid/appointments`]}
          component={OrgLocationSchedule}
          exact
        />
        <AuthRoute
          path={[
            `${path}/admin/locs/:lid/appointments?view=calendar`,
            `${path}/admin/locs/:lid/appointments?view=table`
          ]}
          component={OrgLocationSchedule}
          exact
        />
        <AuthRoute
          path={`${path}/admin/locs/:lid/offerings`}
          component={OrgLocationOfferings}
          exact
        />
        <AuthRoute exact path={`${path}/admin/locs/:lid/edit`} component={LocEdit} />

        <AuthRoute path={`${path}/admin/locs/:lid/clients`} component={OrgLocationClients} exact />
        <AuthRoute path={`${path}/admin/locs/:lid/staff`} component={OrgLocationStaff} exact />
        <AuthRoute path={`${path}/admin/locs/:lid/accounts`} component={LocationAccounts} exact />
        <AuthRoute path={`${path}/admin/insight/orgs/:oid/loc/:lid/insights`} component={Insight} exact />

        {/* Business settings page route */}
        <AuthRoute exact path={`${path}/admin/orgs/:oid/edit`} component={OrgEdit} />

        {/* Footer page routes */}
        <AuthRoute exact path={`${path}/terms-of-use`} component={TermsOfUse} />
        <AuthRoute exact path={`${path}/privacy-policy`} component={PrivacyPolicy} />
        <AuthRoute exact path={`${path}/subscription`} component={LyfeSubscriptionInfo} />
      </Switch>
      <Switch>
        <Route>
          <Footer
            type={params.appName === "athletehub" ? ClientType.Athletehub : ClientType.Lyfe}
          />
        </Route>
      </Switch>
    </div>
  );
};
