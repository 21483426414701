import apiService from "./../redux/services/api";
import { toastStore } from "./../stores/toast-store";

export function parseFormError(err, values, setFieldError, props) {
  const apiErr = apiService.errorProcess(err);

  // Check for field errors.
  if (apiErr.statusCode === 400) {
    let fieldRefd = false;
    for (let i = 0; i < apiErr.apiErrors.length; i++) {
      if (apiErr.apiErrors[i].FieldType === "body") {
        if (values.hasOwnProperty(apiErr.apiErrors[i].FieldName)) {
          setFieldError(apiErr.apiErrors[i].FieldName, apiErr.apiErrors[i].UserMessage);
          fieldRefd = true;
        }
      }
    }

    if (fieldRefd) {
      toastStore.error(`There are errors in the form. Please correct them and try again.`);
      return;
    }
  }

  // Otherwise display a generate error dialog.
  toastStore.error(apiErr.userMessages);
}
