import React from "react";
import { ProviderSchedule } from "./index";

interface IProps {
  schedule: ProviderSchedule;
  day: "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const ScheduleBlock: React.FC<IProps> = ({ schedule, day }) => (
  <div
    className={`flex space-y-1 md:pt-2 ${
      days[new Date().getDay()].toLocaleLowerCase() === day.toLocaleLowerCase()
        ? "font-semibold"
        : ""
    }`}
  >
    <div className="w-2/6 md:w-6/12">{day}</div>
    <div className="w-4/6 md:w-6/12">
      {Object.keys(schedule[day]).length
        ? `${schedule[day].OpensAt} - ${schedule[day].ClosesAt}`
        : "Closed"}
    </div>
  </div>
);

export default ScheduleBlock;
