import classNames from "classnames";
import React from "react";

type Spacing =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 32
  | 40
  | 44
  | 48
  | 52
  | 64
  | 72
  | 80
  | 96
  | "auto";

type Metrics =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 32
  | 40
  | 44
  | 48
  | 52
  | 64
  | 72
  | 80
  | 96
  | "auto"
  | "full";

type MaxWidth = "xs" | "xs" | "sm" | "lg" | "xl" | "5xl" | "7xl";

interface IProps {
  centerContainer?: boolean;
  children: React.ReactNode;
  width?: Metrics;
  maxWidth?: MaxWidth;
  height?: {
    base?: Metrics;
    xs?: Metrics;
    sm?: Metrics;
    md?: Metrics;
    lg?: Metrics;
    xl?: Metrics;
    xxl?: Metrics;
  };
  noPadding?: boolean;
  type?: "inline" | "block" | "inlineBlock";
  spacing?: [Spacing, Spacing];
  xs?: [Spacing, Spacing];
  sm?: [Spacing, Spacing];
  md?: [Spacing, Spacing];
  lg?: [Spacing, Spacing];
  xl?: [Spacing, Spacing];
  xxl?: [Spacing, Spacing];
  mobileVisibleOnly?: boolean;
  desktopVisibleOnly?: boolean;
  position?: "static" | "fixed" | "relative" | "absolute" | "sticky";
}

export const Container = (props: IProps) => {
  return (
    <div
      className={classNames(
        !props.noPadding && "px-4 py-6 md:p-8",
        getContainerType(props.type),
        props.position,
        props.centerContainer && "mx-auto",
        props.height && `h-${props.height.base}`,
        props.width && `w-${props.width}`,
        props.maxWidth && `max-w-${props.maxWidth}`,
        props.height && props.height.xs && `xs:h-${props.height.xs} xs:h-${props.height.xs}`,
        props.height && props.height.sm && `sm:h-${props.height.sm} sm:h-${props.height.sm}`,
        props.height && props.height.md && `md:h-${props.height.md} md:h-${props.height.md}`,
        props.height && props.height.lg && `lg:h-${props.height.lg} lg:h-${props.height.lg}`,
        props.height && props.height.xl && `xl:h-${props.height.xl} xl:h-${props.height.xl}`,
        props.height && props.height.xxl && `2xl:h-${props.height.xxl} 2xl:h-${props.height.xxl}`,
        props.spacing && `mx-${props.spacing[0]} my-${props.spacing[1]}`,
        props.xs && `xs:mx-${props.xs[0]} xs:my-${props.xs[1]}`,
        props.sm && `sm:mx-${props.sm[0]} sm:my-${props.sm[1]}`,
        props.md && `md:mx-${props.md[0]} md:my-${props.md[1]}`,
        props.lg && `lg:mx-${props.lg[0]} lg:my-${props.lg[1]}`,
        props.xl && `xl:mx-${props.xl[0]} xl:my-${props.xl[1]}`,
        props.xxl && `2xl:mx-${props.xxl[0]} 2xl:my-${props.xxl[1]}`,
        props.mobileVisibleOnly && "sm:hidden",
        props.desktopVisibleOnly && "hidden sm:block"
      )}
    >
      {props.children}
    </div>
  );
};

const getContainerType = (type?: IProps["type"]) => {
  switch (type) {
    case "block":
      return "block";
    case "inline":
      return "inline";
    case "inlineBlock":
      return "inline-block";
    default:
      return "container";
  }
};
