/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var user_pb = require('./user_pb.js')

var media_pb = require('./media_pb.js')
const proto = {};
proto.proto = require('./notes_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.NotesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.NotesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateNoteRequest,
 *   !proto.proto.Note>}
 */
const methodDescriptor_Notes_Create = new grpc.web.MethodDescriptor(
  '/proto.Notes/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateNoteRequest,
  proto.proto.Note,
  /**
   * @param {!proto.proto.CreateNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateNoteRequest,
 *   !proto.proto.Note>}
 */
const methodInfo_Notes_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Note,
  /**
   * @param {!proto.proto.CreateNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @param {!proto.proto.CreateNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Note)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Note>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.NotesClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Notes/Create',
      request,
      metadata || {},
      methodDescriptor_Notes_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Note>}
 *     Promise that resolves to the response
 */
proto.proto.NotesPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Notes/Create',
      request,
      metadata || {},
      methodDescriptor_Notes_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Note,
 *   !proto.proto.Note>}
 */
const methodDescriptor_Notes_Update = new grpc.web.MethodDescriptor(
  '/proto.Notes/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.Note,
  proto.proto.Note,
  /**
   * @param {!proto.proto.Note} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.Note,
 *   !proto.proto.Note>}
 */
const methodInfo_Notes_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Note,
  /**
   * @param {!proto.proto.Note} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @param {!proto.proto.Note} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Note)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Note>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.NotesClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Notes/Update',
      request,
      metadata || {},
      methodDescriptor_Notes_Update,
      callback);
};


/**
 * @param {!proto.proto.Note} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Note>}
 *     Promise that resolves to the response
 */
proto.proto.NotesPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Notes/Update',
      request,
      metadata || {},
      methodDescriptor_Notes_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Note,
 *   !proto.proto.Note>}
 */
const methodDescriptor_Notes_Delete = new grpc.web.MethodDescriptor(
  '/proto.Notes/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.Note,
  proto.proto.Note,
  /**
   * @param {!proto.proto.Note} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.Note,
 *   !proto.proto.Note>}
 */
const methodInfo_Notes_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.Note,
  /**
   * @param {!proto.proto.Note} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Note.deserializeBinary
);


/**
 * @param {!proto.proto.Note} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.Note)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Note>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.NotesClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Notes/Delete',
      request,
      metadata || {},
      methodDescriptor_Notes_Delete,
      callback);
};


/**
 * @param {!proto.proto.Note} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Note>}
 *     Promise that resolves to the response
 */
proto.proto.NotesPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Notes/Delete',
      request,
      metadata || {},
      methodDescriptor_Notes_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListNotesRequest,
 *   !proto.proto.ListNotesResponse>}
 */
const methodDescriptor_Notes_Get = new grpc.web.MethodDescriptor(
  '/proto.Notes/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.ListNotesRequest,
  proto.proto.ListNotesResponse,
  /**
   * @param {!proto.proto.ListNotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListNotesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListNotesRequest,
 *   !proto.proto.ListNotesResponse>}
 */
const methodInfo_Notes_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListNotesResponse,
  /**
   * @param {!proto.proto.ListNotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListNotesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListNotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListNotesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListNotesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.NotesClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Notes/Get',
      request,
      metadata || {},
      methodDescriptor_Notes_Get,
      callback);
};


/**
 * @param {!proto.proto.ListNotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListNotesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.NotesPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Notes/Get',
      request,
      metadata || {},
      methodDescriptor_Notes_Get);
};


module.exports = proto.proto;

