import { useEffect, useState } from "react";

type AsyncCallback<T> = () => Promise<T>;

interface AsyncResource<T> {
  loading: boolean;
  value?: T;
  error?: Error;
  refresh(): Promise<void>;
}

export function useAsync<T>(
  fetcher: AsyncCallback<T>,
  deps?: React.DependencyList
): AsyncResource<T> {
  const [value, setValue] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  async function getResource() {
    try {
      setLoading(true);
      const result = await fetcher();
      setValue(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(
    () => {
      getResource();
    },
    deps ? deps : []
  );

  return {
    value,
    error,
    loading,
    refresh: getResource
  };
}
