import React, { Component } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Dropdown from "./common/Dropdown";
import IconButton from "../IconButton";
import { colors, hexToRgba } from "../../util/consts";
import { observer } from "mobx-react";
import { paymentModalStore } from "./../../mobx/stores/PaymentModalStore";

interface Props {
  creditCards: any;
  medicareFunds: any;
  dispatch?: any;
  history: any;
}

@observer
export class PaymentModal extends Component<Props> {
  componentDidMount() {
    // If env is not AU && medicarefund is not setup then close the popup
    if (
      !paymentModalStore.isMedicareFundSet &&
      process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== "AU"
    ) {
      paymentModalStore.isOpen = false;
    }
  }

  onClose = () => {
    paymentModalStore.isOpen = false;
  };

  onClickYes = () => {
    this.props.history.push("/users/edit?page=ccards");
    paymentModalStore.isOpen = false;
  };

  render() {
    let header;
    if (!paymentModalStore.isCreditCardSet && !paymentModalStore.isMedicareFundSet) {
      header = `
      You don't have any credit card ${
        process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU"
          ? " or medicare fund set to your account yet"
          : ""
      }
      `;
    } else if (!paymentModalStore.isCreditCardSet) {
      header = "You don't have any credit card set to your account yet";
    } else if (!paymentModalStore.isMedicareFundSet) {
      if (!paymentModalStore.isMedicareFundSet)
        header = "You don't have any medicare fund set to your account yet";
    }

    return (
      <Modal>
        <CloseButton onClick={this.onClose}>&times;</CloseButton>
        <Header>{header}</Header>
        <SubHeader>Do you want to set it up now?</SubHeader>

        <BottomRow>
          <StyledIconButton style={{ marginRight: "10px" }} onClick={this.onClickYes}>
            <Text>Yes</Text>
          </StyledIconButton>
          <StyledIconButton style={{ marginLeft: "10px" }} onClick={this.onClose}>
            <Text>No</Text>
          </StyledIconButton>
        </BottomRow>
      </Modal>
    );
  }
}

const Header = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SubHeader = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  margin-top: -5px;
  color: white;
`;

const CloseButton = styled.a`
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 0px;
  top: 0px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
`;

const BottomRow = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${colors.secondary.main};
  border-radius: 9px !important;
  width: 70px !important;
  height: 25px !important;
  &:hover {
    background-color: ${hexToRgba(colors.secondary.main, 0.6)} !important;
  }
  margin: 0;
`;

const Modal = styled.div`
  width: 80%;
  font-size: 12px;
  margin-left: 10%;
  margin-top: 10%;
`;
