import React from "react";
import DatePicker from "react-datepicker";
import * as styled from "../TableStyledComponents";
import { accTableFilters } from "../../../../stores/filters_acc_table-store";
import { fundTypeToText } from "../../../../util/funds";
import { statusAccRowToText, accItemTypeToText, accTableTypeToText } from "../../../../util/account-items";
import { TableInstance } from "react-table";
import { format } from 'date-fns'

const heightFilters = "23px";

export const SelectColumnFilter = (props: TableInstance) => {
  const {
    column: { id, filterValue, setFilter, preFilteredRows },
    gotoPage
  } = props;

  const options = React.useMemo(() => {
    const options = new Set();
    switch (id) {
      case "status":
        accTableFilters
          .getAll()
          .statusList.filter((type) => {
            const excludeTypes = [7];
            if (process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US")
              return !excludeTypes.includes(type);
            return true;
          })
          .forEach((status) => {
            options.add(statusAccRowToText(status));
          });
        break;
      case "bookingType":
        accTableFilters.getAll().typeList.forEach((type) => {
          options.add("All");
          options.add(accTableTypeToText(type));
        });
        break;
      case "fundType":
        accTableFilters
          .getAll()
          .fundTypeList.filter((type) => {
            const excludeTypes = [0, 2, 3, 4, 5, 6, 7];
            if (process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US")
              return !excludeTypes.includes(type);
            return true;
          })
          .forEach((fundType) => {
            options.add("All");
            options.add(fundTypeToText(fundType));
          });
        break;
      case "accItemType":
        accTableFilters.getAll().accItemTypeList.forEach((accType) => {
          options.add("All");
          options.add(accItemTypeToText(accType));
        });
        break;
    }

    return [...options.values()];
  }, [
    accTableFilters.getAll().statusList,
    accTableFilters.getAll().fundTypeList,
    accTableFilters.getAll().accItemTypeList,
    accTableFilters.getAll().typeList,
    id,
    preFilteredRows
  ]);

  // Render a multi-select box
  return (
    <styled.Select
      value={filterValue || ""}
      onChange={(e: any) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        gotoPage(0);
      }}
      width={"100px"}
    >
      {options.map((option, i) => (
        <option key={i} value={option as any}>
          {option as any}
        </option>
      ))}
    </styled.Select>
  );
};

export const DateFromToColumnFilter = (props: TableInstance) => {
  const {
    gotoPage,
    setFilter,
    state: { filters }
  } = props;

  const dateFrom = filters.find((item) => item.id === "bookingDateFrom");
  const dateTo = filters.find((item) => item.id === "bookingDateTo");

  return (
    <styled.BookingDateContainer>
      <DatePicker
        key="bkDateFrom"
        className="customDatePicker"
        selected={dateFrom ? new Date(dateFrom.value) : undefined}
        onChange={(e) => {
          setFilter("bookingDateFrom", e ? format(e, "MM/dd/yyyy") : undefined);
          gotoPage(0);
        }}
        dateFormat="dd/MM/yyyy"
        isClearable
        fixedHeight
        placeholderText={"01/21/2001"}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="top-end"
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
      />
      <styled.SeparatorTex> To </styled.SeparatorTex>
      <DatePicker
        key="bkDateTo"
        className="customDatePicker"
        selected={dateTo ? new Date(dateTo.value) : undefined}
        onChange={(e) => {
          setFilter("bookingDateTo", e ? format(e, "MM/dd/yyyy") : undefined);
          gotoPage(0);
        }}
        dateFormat="dd/MM/yyyy"
        isClearable
        fixedHeight
        placeholderText={"01/21/2001"}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="top-end"
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
      />
    </styled.BookingDateContainer>
  );
};

// Define a default UI for filtering
export const DefaultColumnFilter = (props: TableInstance) => {
  const {
    column: { filterValue, setFilter },
    gotoPage
  } = props;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        gotoPage(0);
      }}
      placeholder={`Search ${props.pageCount} records...`}
      style={{ width: "120px", height: heightFilters }}
    />
  );
};

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}: any) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
        marginTop: 5
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old: any = []) => [val ? parseFloat(val) : undefined, old[1]]);
        }}
        placeholder={`0.00`}
        style={{
          width: "51px",
          marginRight: "0.2rem",
          height: heightFilters
        }}
      />
      <p style={{ height: heightFilters, marginTop: 0, paddingTop: 5 }}>to</p>
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old: any = []) => [old[0], val ? parseFloat(val) : undefined]);
        }}
        placeholder={`9.99`}
        style={{
          width: "51px",
          marginLeft: "0.2rem",
          height: heightFilters
        }}
      />
    </div>
  );
}
