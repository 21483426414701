import * as actions from "./actions";
import reducers, {
  DialogReduxStoreState as dialogReduxStoreState,
  DialogActions as dialogActions
} from "./reducer";
import DialogType from "./ids";
import { BuyProdDialogProps } from "../../../components/dialogs/BuyProductDialog";
import { BookServiceDialogProps } from "../../../components/dialogs/BookServiceDialog";
import { PayoutDialogProps } from "../../../components/dialogs/PayoutDialog";
import { StripeSetupDialogProps } from "../../../components/dialogs/StripeSetupDialog";

export function openInfo(
  message: string,
  trigger: HTMLElement | undefined,
  cb: () => void = () => {}
) {
  return actions.open(DialogType.InfoDialog, { message, trigger, cb });
}

export function openAction(
  message: string,
  checkMessage: string | null,
  trigger: HTMLElement | null,
  cb: (...args: any[]) => void = () => {},
  btn1: string = "Yes",
  btn2: string = "No"
) {
  return actions.open(DialogType.ActionDialog, {
    message,
    checkMessage,
    trigger,
    btn1,
    btn2,
    cb
  });
}

export function openAccount(
  trigger: HTMLElement | null,
  page: string,
  data: object,
  cb: () => void
) {
  return actions.open(DialogType.AccountDialog, { trigger, page, data, cb });
}

export function openQuickBook(stuff: any, cb: () => void) {
  return actions.open(DialogType.QuickBookDialog, { stuff, cb });
}

export function openSplit(cb: () => void) {
  return actions.open(DialogType.SplitDialog, { cb });
}

export function openNewBooking(trigger: HTMLElement, location: object, cb: () => void) {
  return actions.open(DialogType.NewBookingDialog, { trigger, location, cb });
}

export function openAppointment(booking: string, client: string, payment: boolean, cb: () => void) {
  return actions.open(DialogType.AppointmentDialog, { booking, client, payment, cb });
}

export function openTimeOff(booking: string, cb: () => void) {
  return actions.open(DialogType.TimeOffDialog, { booking, cb });
}

export function clientActionDialog(item: object, cb: () => void) {
  return actions.open(DialogType.ClientActionDialog, { item, cb });
}

export function staffActionDialog(item: object, lid: string, cb: () => void) {
  return actions.open(DialogType.StaffActionDialog, { item, lid, cb });
}

export function offeringDialog(
  offerID: string | null,
  loc: object,
  cb: (val: any) => void,
  setType?: "Service" | "Product",
  duplicate?: boolean
) {
  return actions.open(DialogType.OfferingDialog, { offerID, loc, cb, setType, duplicate });
}

export function addStaffDialog(loc: object, cb: () => void) {
  return actions.open(DialogType.AddStaffDialog, { loc, cb });
}

export function addClientDialog(org: object, loc: object, cb: (val: any) => void) {
  return actions.open(DialogType.AddClientDialog, { org, loc, cb });
}

export function openWelcomeDialog(
  title: string,
  subtitle: string,
  items: string[],
  buttonText: string,
  onClick: () => void,
  cb: () => void
) {
  return actions.open(DialogType.WelcomeDialog, {
    title,
    subtitle,
    items,
    buttonText,
    onClick,
    cb
  });
}

export function openCancel(
  trigger: HTMLElement | null,
  booking_id: string,
  client_id?: string | undefined,
  cb: () => void = () => {}
) {
  return actions.open(DialogType.CancelDialog, {
    trigger,
    booking_id,
    client_id,
    cb
  });
}

export function openReject(
  trigger: HTMLElement | null,
  booking: any,
  client: any,
  cb: () => void = () => {}
) {
  return actions.open(DialogType.RejectDialog, {
    trigger,
    booking,
    client,
    cb
  });
}

export function openBatchCharge(
  trigger: HTMLElement | null,
  booking: any,
  cb: (...args: any[]) => void = () => {}
) {
  return actions.open(DialogType.BatchChargeDialog, {
    trigger,
    booking,
    cb
  });
}

export function openConsumerApt(trigger: HTMLElement | null, booking: any, cb: () => void) {
  return actions.open(DialogType.ConsumerAptDialog, { trigger, booking, cb });
}

export function openExportTransactions(
  trigger: HTMLElement | null,
  org: any,
  loc: any,
  cb?: () => void
) {
  return actions.open(DialogType.ExportTransactionsDialog, { trigger, org, loc, cb });
}

export function openPullServices(trigger: HTMLElement | null, org: any, loc: any, cb: () => void) {
  return actions.open(DialogType.PullServicesDialog, { trigger, org, loc, cb });
}

export function openBuyProduct(props: BuyProdDialogProps) {
  return actions.open(DialogType.BuyProductDialog, props.data);
}

export function openBookService(props: BookServiceDialogProps) {
  return actions.open(DialogType.BookServiceDialog, props.data);
}

export function openPayout(props: PayoutDialogProps) {
  return actions.open(DialogType.PayoutDialog, props.data);
}

export function openStripeSetup(props: StripeSetupDialogProps) {
  return actions.open(DialogType.StripeSetupDialog, props.data);
}

export { actions, reducers, DialogType };

// This is to get around the isolated modules as required by Babel.
export type DialogReduxStoreState = dialogReduxStoreState;
export type DialogActions = dialogActions;
