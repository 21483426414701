export const calculateSubscriptionCost = (sub: string, count: number) => {
  switch (sub.toLowerCase()) {
    case "pro":
      return 19 * count;
    case "basic":
      return 9 * count;
    case "starter":
    default:
      return 0;
  }
};
