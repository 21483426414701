import { observable } from 'mobx';
import { ITaxonomyCategory } from '../../IRestApiResponse';

class TaxonomyCategoryStore {

  @observable
  categories: ITaxonomyCategory[] = []


  getCategory(categoryId: string) {
    return this.categories.filter(a => a.ID === categoryId)[0];
  }
}

export const taxonomyCategoryStore = new TaxonomyCategoryStore();
