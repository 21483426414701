import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20"
  },
  medium: {
    width: "24",
    height: "24"
  },
  large: {
    width: "28",
    height: "28"
  }
};

export default class ArrowDropDown extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    color: PropTypes.string
  };

  static defaultProps = {
    size: "medium",
    color: "#fff"
  };

  render() {
    const { size, color, ...otherProps } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...sizeProps[size]}
        {...otherProps}
        fill={color}
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </svg>
    );
  }
}
