import { Component } from "react";
import { connect } from "react-redux";
import apiService from "./../../redux/services/api";


class AuthLogout extends Component {
  componentDidMount() {
    if (this.isLoggedIn) {
      console.info("logging out");
      this.logout();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    if (prevProps.auth !== auth && this.isLoggedIn) {
      this.logout();
    }
  }
  isLoggedIn() {
    const { auth } = this.props;
    return auth && auth.Type === "user" && auth.UserID;
  }
  async logout() {
    try {
      await apiService.userLogout();
      localStorage.removeItem('persist:root');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    } catch (err) {
      console.info(`failed to logout: ${err.message}`);
    }
  }
  render() {
    return null;
  }
}

function mapStateToProps(state, props) {
  const { auth } = state;
  return {
    auth
  };
}

export default connect(mapStateToProps)(AuthLogout);
