import React, { Component } from "react";
import styled from "@emotion/styled";

import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Components
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";
import ResourcePageError from "./../../../components/elements/ResourcePageError";

// Pages
import General from "./General";
import Market from "./Market";
import Sched from "./Sched";

export default class LocCreateAccount extends Component {
  renderContent = React.memo(({ resource: org }) => (
    <PagesContainer
      sidePanel={true}
      queryp="page"
      entryBlacklist={["sched", "providers"]}
      blockNotSeen={true}
      extraProps={{ org }}
    >
      <PageGroup label="Basics">
        <Page name="general" label="General" component={General} />
        <Page name="market" label="Market" component={Market} />
        <Page name="sched" label="Schedule" component={Sched} />
      </PageGroup>
      {/* <PageGroup label="Services">
        <Page name="services" showInTree={false} component={dummyCreator("providers", "products")} />
      </PageGroup>
      <PageGroup label="Providers">
        <Page name="providers" showInTree={false} component={dummyCreator("providers", "products")} />
      </PageGroup> */}
    </PagesContainer>
  ));

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <ResourceFetch type="Organisation" ids={{ ID: match.params.oid }} />
        <ResourceRender
          type="Organisation"
          ids={{ ID: match.params.oid }}
          denorm={true}
          compSuccess={this.renderContent}
          compError={ResourcePageError}
        />
      </React.Fragment>
    );
  }
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 69px);
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  padding: 200px 0;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 500px;
`;

export const FormWide = styled.form`
  width: 100%;
  max-width: 800px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ChoiceButton = styled.button`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  margin: 50px 50px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const HeaderTitle = styled.span`
  ${typography.heading5};
`;

export const HeaderSubtitle = styled.span`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  margin-top: 12px;
`;

export const ButtonText = styled.span`
  margin-top: 10px;
  font-size: 17px;
  letter-spacing: 0.25px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${colors.surfaceText.highEmphasis};
`;

export const ErrorText = styled.div`
  color: red;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
`;
