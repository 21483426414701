import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Avatar from "../../../components/Avatar";
import * as ReduxDialogs from "../../../redux/features/dialogs";
import { Booking } from "sdk/dist/bookings_pb";
import { BookingSearchResponse } from "sdk/dist/location_pb";
import { LocationUserDTO, ProviderOffering, ProviderOfferingDay } from "sdk/dist/location_user_pb";
import { MinusIcon } from "../../../ui-kit/icons/MinusIcon";
import { PlusIcon } from "../../../ui-kit/icons/PlusIcon";
import { toDateTz } from "../../../util/timestamp";
import { BookServiceDialogProps } from "../../dialogs/BookServiceDialog";

interface IProps {
  service: ProviderOffering.AsObject;
  provider: LocationUserDTO.AsObject;
  searchResults: BookingSearchResponse.AsObject;
  filterDate: any;
  fetchData: () => Promise<void>;
}

const ClassCard: React.FC<IProps> = ({
  service,
  provider,
  searchResults,
  filterDate,
  fetchData
}) => {
  const dispatch = useDispatch();

  const [isTimingsVisible, toggleTimingsVisibility] = useState(false);

  let dayOffset: number;
  if (moment(filterDate, "yyyy-MM-DD").isSame(moment(), "day")) {
    dayOffset = 0;
  } else {
    dayOffset = moment(filterDate, "yyyy-MM-DD").diff(moment(), "days") + 1;
  }

  // Book Service
  const bookService = async (booking: any) => {
    const dialogProps: BookServiceDialogProps = {
      data: {
        booking: booking,
        service: service,
        provider: provider,
        searchResults: searchResults,
        fetchData: fetchData
      }
    };
    dispatch(ReduxDialogs.openBookService(dialogProps));
  };

  // Renders Service Details
  const renderHeader = () => {
    return (
      <div className="block h-full" onClick={() => toggleTimingsVisibility(!isTimingsVisible)}>
        <div className="md:flex w-full ">
          <div className="overflow-y-auto text-xl">{service.name}</div>
          <div className="overflow-y-auto flex space-x-2 md:float-right md:mr-0 md:ml-auto">
            <div>{provider.firstName + " " + provider.lastName}</div>
            <div>|</div>
            <div>{service.duration}m</div>
            <div>|</div>
            <button className="rounded-full w-16 bg-green-100">${service.fee}</button>
          </div>
        </div>
        {service.description.length > 0 && (
          <div className="pt-2 italic overflow-y-auto h-24 md:h-32">{service.description}</div>
        )}
        {renderVisibiltyToggle()}
      </div>
    );
  };

  // Toggles timings view of bookings
  const renderVisibiltyToggle = () => {
    return (
      <div className="uppercase text-green-900 bottom-0">
        <button
          className="inline-flex items-center"
          onClick={() => toggleTimingsVisibility(!isTimingsVisible)}
        >
          <span className="pr-2">See session times</span>
          {isTimingsVisible ? <MinusIcon /> : <PlusIcon />}
        </button>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="md:w-1/3 md:h-48 overflow-auto w-full overflow-hidden rounded border-gray-400 flex-shrink-0 flex-grow-0 bg-white">
        {service.image && service.image!.downloadUrl ? (
          <img
            src={service.image!.downloadUrl}
            alt={service.name}
            className="h-48 object-cover w-full"
          />
        ) : (
          <Avatar className="" letters={`${service.name.charAt(0)}`} size={90} lfrac={0.3} />
        )}
      </div>
    );
  };

  // Renders a button for each booking in that day
  const renderDayBookings = (day: ProviderOfferingDay.AsObject) => {
    let times = day.futureBookingsList.map((booking: Booking.AsObject, index) => {
      let time: string = booking.startDatetime
        ? toDateTz(booking.startDatetime).format("HH:mm")
        : "";
      let spotsLeft = "";
      if (booking.spotsLeft != "") {
        spotsLeft = " | " + booking.spotsLeft;
      }
      return (
        <button
          key={`time-${booking.startDatetime}-${index}`}
          onClick={() => bookService(booking)}
          className="btn btn-light-green font-light hover:secondary mr-2 whitespace-pre"
        >
          {time + spotsLeft}
        </button>
      );
    });
    return (
      <div
        className="block overflow-x-scroll md:overflow-auto mb-2"
        style={{ whiteSpace: "nowrap" }}
      >
        {times.length > 0 ? times : <p className="text-gray-600">None</p>}
      </div>
    );
  };

  // Renders a list of days
  const renderDay = () => {
    let dayBookings = [];
    dayBookings = service.dayList.map((day, i) => {
      let date = moment();
      if (day.date) {
        date = toDateTz(day.date);
      }
      const isToday = date.isSame(moment(), "days");
      return (
        <div key={i} className="border-b-2 md:h-full p-2">
          <h3 className="font-semibold">{isToday ? "Today" : date.format("dddd, Do of MMMM")}</h3>
          {renderDayBookings(day)}
        </div>
      );
    });

    return dayBookings;
  };

  return (
    <div className="w-full md:max-w-full space-4 rounded shadow md:p-6 mt-3 bg-white">
      <div className="md:flex space-x-4">
        {renderImage()}
        <div className="md:w-2/3 md:flex flex-col justify-between md:h-48">
          <div className="w-full h-full">{renderHeader()}</div>
        </div>
      </div>

      <div id="session-times" className="block p-2 w-full md:p-0">
        <div className={`${!isTimingsVisible ? "hidden" : ""} pt-2`}>{renderDay()}</div>
      </div>
    </div>
  );
};

export default ClassCard;
