import React, { Component } from "react";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import { IFilter } from "./../../../components/routes/InsightDashboard/CustomTypes";

interface Props {
  buttonText: string;
  onUpdateFilter(newFilter: Partial<IFilter>): any;
  dateDiff: number;
  isSelect: boolean;
  buttonIndex: number;
  onUpdateClickedButton(index: number): void;
}

class DateButton extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handClick = this.handClick.bind(this);
  }

  handClick(e: any) {
    e.preventDefault();
    var startDate: Date = new Date();
    startDate.setDate(startDate.getDate() - this.props.dateDiff);
    this.props.onUpdateFilter({ dateStart: startDate, dateEnd: new Date() });
    this.props.onUpdateClickedButton(this.props.buttonIndex);
  }

  render() {
    const { buttonText, isSelect } = this.props;
    return (
      <button
        onClick={this.handClick}
        css={
          isSelect
            ? css`
                ${ButtonCSS}background:#00b1b6;
                color: #fff;
              `
            : ButtonCSS
        }
      >
        {buttonText}
      </button>
    );
  }
}

export default DateButton;

export const ButtonCSS = css`
  height: auto;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  border-radius: 99999em;
  transition: background-color 0.3s;
  display: inline-block;
  width: auto;
  padding: 8px 24px 10px;
  color: #00b1b6;
  background: #fff;
  box-shadow: inset 0 0 0 1px #00b1b6;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
  border: none;

  font-size: 14px;
  line-height: 1.1;
  cursor: pointer;

  -webkit-font-smoothing: antialiased;

  outline-style: none;
`;
