import { css, jsx } from "@emotion/core";
import { Stripe, StripeCardElement, StripeCardElementOptions } from "@stripe/stripe-js";
import { withFormik } from "formik";
import * as H from "history";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import Button from "../../../components/Button";
import DayPickerInput from "../../../components/form/DayPickerInput";
import Error from "../../../components/form/Error";
import FooterContainer from "../../../components/form/FooterContainer";
import FooterText from "../../../components/form/FooterText";
import Form from "../../../components/form/Form";
import FormHeader from "../../../components/form/FormHeader";
import Input from "../../../components/form/Input";
import Label from "../../../components/form/Label";
import MobileInput from "../../../components/form/MobileInput";
import Section from "../../../components/form/Section";
import SectionsContainer from "../../../components/form/SectionsContainer";
import {
  CARD_ELEMENT_OPTIONS,
  StripeElementsContainer
} from "../../../components/stripe/CreditCard/NewCardFormStripe";
import { StripeTrustBadge } from "../../../components/stripe/StripeTrustBadge";
import { metadata, rpc } from "../../../grpc";
import { ResourceTypes } from "../../../redux/features/resources/definitions";
import * as ResourceActions from "../../../redux/features/resources/thunkactions";
import { Resource } from "../../../redux/features/resources/types";
import apiService from "../../../redux/services/api";
import { CreateUserRequest, UserType } from "sdk/dist/user_pb";
import { stripeStore } from "../../../stores/stripe-store";
import { toastStore } from "../../../stores/toast-store";
import { FlexBox } from "../../../ui-kit/FlexBox";
import { colors } from "../../../util/consts";
import { parseFormError } from "../../../util/form";
import { dateToTimestamp } from "../../../util/time";
import { tracking } from "../../../util/tracking";

let cardElement: StripeCardElement;
let stripeInstance: Stripe | null = null;
interface Props extends RouteComponentProps<any> {
  values: any;
  errors: any;
  touched: any;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  isSubmitting: () => void;
  closeDialog: () => void;
  setPage: (name: string) => void;
  closeModal: () => void;
  dispatch?: any;
}

class SignUp extends React.Component<Props> {
  async componentDidMount() {
    stripeStore.loadScriptOnDom();
    stripeInstance = await stripeStore.getPKInstance();
    if (stripeInstance !== null) {
      var elements = stripeInstance.elements();
      if (CARD_ELEMENT_OPTIONS.style) {
        cardElement = elements.create("card", {
          style: CARD_ELEMENT_OPTIONS.style.base
        } as StripeCardElementOptions);
        cardElement.mount("#card-element");
      }
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      closeDialog
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader title="Get Started" subtitle="Create a personal account" />
        <SectionsContainer>
          <Section>
            <Label htmlFor="signup-Email">Email</Label>
            <Input
              id="signup-Email"
              placeholder="example@example.com"
              type="text"
              name="Email"
              value={values.Email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-Phone">Mobile Number</Label>
            <MobileInput
              id="signup-Phone"
              name="MobileNumber"
              onPhoneChange={(e) => {
                values.MobileNumber = e;
              }}
              onCountryChange={(e) => {
                values.MobileCountryCode = e;
              }}
            />
            {!!errors.MobileNumber && touched.MobileNumber && <Error>{errors.MobileNumber}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-FirstName">First Name</Label>
            <Input
              id="signup-FirstName"
              placeholder="Joe"
              type="text"
              name="FirstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-LastName">Last Name</Label>
            <Input
              id="signup-LastName"
              placeholder="Smith"
              type="text"
              name="LastName"
              value={values.LastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-DateOfBirth">Date of Birth</Label>
            <DayPickerInput
              id="signup-DateOfBirth"
              name="DateOfBirth"
              placeholder="Select a date of birth"
              value={values.DateOfBirth}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.DateOfBirth && errors.DateOfBirth}
            />
            {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
          </Section>
          <Section>
            <Label htmlFor="signup-CreditCard">Credit Card</Label>
            <StripeElementsContainer>
              <div id="card-element"></div>
            </StripeElementsContainer>
            <FlexBox justifyContent="end">
              <StripeTrustBadge />
            </FlexBox>
          </Section>
          <Section>
            <Label htmlFor="signup-Password">Password</Label>
            <Input
              id="signup-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              value={values.Password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={
              !!errors.Email ||
              !!errors.MobileNumber ||
              !!errors.FirstName ||
              !!errors.LastName ||
              !!errors.Password ||
              !!errors.DateOfBirth ||
              isSubmitting
            }
          >
            Continue
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText
            css={css`
              text-align: center;
            `}
          >
            By clicking “Continue” I agree to Booklyfe’s{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Terms of Service
            </Link>{" "}
            &amp;{" "}
            <Link
              to="/"
              onClick={closeDialog}
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
              `}
            >
              Privacy Policy
            </Link>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const findParamsValue = (history: H.History, searchParam: string) => {
  if (history.location.search) {
    const rawParams = history.location.search;
    const sanitizedParams = rawParams.replace("?", "");
    const paramsList = sanitizedParams.split("&");

    const query = paramsList.find((str) => str.includes(searchParam));
    if (query) {
      return query.replace(`${searchParam}=`, "");
    }
    return undefined;
  }
};

const formikEnhancer = withFormik({
  mapPropsToValues: (props: Props) => {
    const email = findParamsValue(props.history, "email");

    return {
      Email: email || "",
      MobileNumber: "",
      MobileCountryCode: "",
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      Password: ""
    };
  },
  validationSchema: Yup.object().shape({
    Email: Yup.lazy((v) => Yup.string().email("Email is invalid").required("Email is required")),
    MobileNumber: Yup.string()
      .min(8, "Mobile number is required")
      .required("Mobile number is required"),
    FirstName: Yup.string().required("Please enter your first name"),
    LastName: Yup.string().required("Please enter your last name"),
    DateOfBirth: Yup.date().required("Please enter your date of birth"),
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum")
      .required("Please enter a password")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true);
    tracking.clientSignUpCompleted();
    try {
      if (!stripeInstance) {
        toastStore.error("stripe instance not defined");
        setSubmitting(false);
        return;
      }
      const result = await stripeInstance.createToken(cardElement);
      if (result.error) {
        toastStore.error("Credit card couldn't be added, error: \n" + result.error.message);
        setSubmitting(false);
        return;
      }
      if (!result.token) {
        toastStore.error("Stripe token undefined");
        setSubmitting(false);
        return;
      }
      // Create new user
      const userSignupReq = new CreateUserRequest();
      userSignupReq.setFirstName(values.FirstName.trim());
      userSignupReq.setLastName(values.LastName.trim());
      userSignupReq.setEmail(values.Email.trim());
      userSignupReq.setMobileNumber(values.MobileNumber.trim());
      userSignupReq.setMobileCountryCode(values.MobileCountryCode.trim());
      userSignupReq.setStripeCcToken(result.token.id);
      userSignupReq.setPassword(values.Password);
      userSignupReq.setType(UserType.USER_TYPE_LYFE);
      userSignupReq.setGender("Unknown");
      userSignupReq.setDateOfBirth(dateToTimestamp(new Date(values.DateOfBirth)));
      const rpcRes = await rpc.user.createUser(userSignupReq, metadata());
      const logRes = await apiService.userLogin(
        values.Email ? values.Email : null,
        values.Email ? null : values.MobileNumber,
        values.Email ? null : values.MobileCountryCode,
        values.Password
      );

      const resource: Resource = {
        $Metadata: { Type: ResourceTypes.User } as any,
        ID: logRes.Payload.UserID
      };
      const res = await props.dispatch(ResourceActions.action(resource, "Fetch", {}));

      const mustVerifyEmail =
        res.Payload.Email && (res.Payload.ValidatedFields || []).indexOf("Email") < 0;
      const mustVerifyPhone =
        res.Payload.MobileNumber && (res.Payload.ValidatedFields || []).indexOf("MobileNumber") < 0;

      if (mustVerifyEmail || mustVerifyPhone) {
        props.setPage("verify");
      } else if (!res.Payload.Address || !res.Payload.Gender) {
        props.setPage("signupextra");
      } else {
        props.closeModal();
      }
    } catch (err) {
      if (err.code && err.message) {
        toastStore.grpcError(err);
      } else {
        parseFormError(err, values, setFieldError, props);
      }
    }
    setSubmitting(false);
  },
  displayName: "SignUpForm"
});

// export default connect()(formikEnhancer(SignUp));
export default compose(connect(), withToastManager, formikEnhancer)(SignUp as any);
