import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxTypes } from "ReduxTypes";
import * as ReduxDialogs from "../../redux/features/dialogs";
import { APITokenType } from "../../redux/services/api";
import { Order } from "sdk/dist/products_pb";
import { sharedPurchaseService } from "../../services/PurchaseService";
import { toastStore } from "../../stores/toast-store";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Typography } from "../../ui-kit/Typography";
import Section from "../form/Section";
import Dialog from "./Dialog";

export interface BuyProdDialogProps {
  data: {
    productID: string;
    cb?: () => void;
  };
  dialogId?: any;
}

export const BuyProductDialog: React.FC<BuyProdDialogProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order.AsObject>(new Order().toObject());
  const dispatch = useDispatch();
  const auth = useSelector((state: ReduxTypes.RootState) => state.auth);

  useEffect(() => {
    async function fetchData(prodId: string) {
      try {
        const res = await sharedPurchaseService.getPreConfirmData(prodId, 1);
        setOrder(res.order!);
        setIsLoading(false);
      } catch (err) {
        toastStore.grpcError(err);
        setIsLoading(false);
      }
    }

    fetchData(props.data.productID);
  }, []);

  const closeDialog = () => {
    if (!isLoading) {
      dispatch(ReduxDialogs.actions.close(props.dialogId));
    }
  };

  const buyProduct = async (prodID: string) => {
    // Disabling this dialog
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const res = await sharedPurchaseService.confirmPurchase(prodID, 1);
      setIsLoading(false);
      setOrder(res.order!);
      toastStore.success(
        `Order no ${
          res.order!.friendlyOrderNo
        } successfully created, you"ll receive an email soon with the purchase details`
      );
    } catch (err) {
      toastStore.grpcError(err);
      setIsLoading(false);
    }
  };

  return (
    <Dialog dialogId={props.dialogId} overideCloseDialog={closeDialog} noClose>
      <Container height={{ base: "full" }}>
        <FlexBox direction="col" spacing={[0, 5]} justifyContent="between" fullHeight>
          <FlexItem>
            <Section>
              <Typography.H1 noPadding>
                {order.friendlyOrderNo.length === 0
                  ? "Do you want to confirm this order?"
                  : "Order Confirmed"}
              </Typography.H1>
              <Typography.H3>To</Typography.H3>
              <Typography.Body noPadding>{`Client name: ${order.clientName}`}</Typography.Body>
              <Typography.Body noPadding>{`Client email: ${order.clientEmail}`}</Typography.Body>
              <Typography.Body
                noPadding
              >{`Client address: ${order.clientAddress}`}</Typography.Body>
              <Typography.H3>Order</Typography.H3>
              {order.friendlyOrderNo.length > 0 && `Order No: ${order.friendlyOrderNo}`}
              <Typography.Body noPadding>{`Product name: ${order.productName}`}</Typography.Body>
              <Typography.Body noPadding>{`Quantity: ${order.quantity}`}</Typography.Body>
              <Typography.Body noPadding>{`Pay method: ${order.paymentMethod}`}</Typography.Body>
              <Typography.Body
                noPadding
              >{`Credit Card last 4 digits: ${order.ccLast4}`}</Typography.Body>
              <Typography.H3>Cost</Typography.H3>
              <Typography.Body noPadding>{`Subtotal: $${order.subtotal}`}</Typography.Body>
              <Typography.Body
                noPadding
              >{`Sales tax: $${order.saleTax} - tax rate ${order.taxRate}%`}</Typography.Body>
              <Typography.Body
                noPadding
              >{`Total: $${order.total} ${order.orgDfltCurrency}`}</Typography.Body>
            </Section>

            {auth!.Type === APITokenType.Client && (
              <Typography.Label color="danger" weight="light" italics>
                *You need to login/signup to be able to purchase this product
              </Typography.Label>
            )}
          </FlexItem>

          <FlexItem>
            <FlexBox direction="col" spacing={[0, 3]}>
              {order.friendlyOrderNo.length === 0 ? (
                <>
                  <FlexItem>
                    <AsyncButton.Action
                      onClick={() => dispatch(ReduxDialogs.actions.close(props.dialogId))}
                      isDisabled={isLoading}
                      rounded="full"
                      size="md"
                      fullWidth
                    >
                      CANCEL
                    </AsyncButton.Action>
                  </FlexItem>
                  <FlexItem>
                    <AsyncButton.PrimaryGradient
                      onClick={() => buyProduct(props.data.productID)}
                      isDisabled={auth!.Type === APITokenType.Client}
                      rounded="full"
                      size="md"
                      fullWidth
                    >
                      ORDER
                    </AsyncButton.PrimaryGradient>
                  </FlexItem>
                </>
              ) : (
                <FlexItem>
                  <AsyncButton.Action onClick={closeDialog} rounded="full" size="md" fullWidth>
                    CLOSE
                  </AsyncButton.Action>
                </FlexItem>
              )}
            </FlexBox>
          </FlexItem>
        </FlexBox>
      </Container>
    </Dialog>
  );
};
