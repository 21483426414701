import React, { Component } from "react";
import styled from "@emotion/styled";
import * as fns from "date-fns";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";

import { colors } from "./../../../util/consts";
import { toPriceString, subVarToPercentage } from "./../../../util";
import { Organisation } from "../../../redux/features/resources/schema";

interface Props {
  org: Organisation;
  values: any;
  errors: any;
  touched: any;
  dirty: any;
  handleChange: (state: any)=>void;
  handleBlur: ()=>void;
  handleSubmit: ()=>void;
  dispatch: Function;
  isSubmitting: boolean;
  pageData: any;
}
class OrgEditAccount_SubDets extends Component<Props> {
  render() {
    const {
      org
    } = this.props;
    return (
      <React.Fragment>
        <FormHeader
          title="Subscription Details"
          subtitle="The following is a breakdown of your current subscription."
        />
        <SectionsContainer>
          <Section>
            <Table>
              <tbody>
                <Row><ColH>Type:</ColH><Col>{org.SubscriptionType}</Col></Row>
                <Row><ColH>Status:</ColH><Col>{org.SubscriptionStatusMessage}</Col></Row>
                <Row><ColH>StartedOn:</ColH><Col>{fns.format(new Date(org.SubscriptionStartedAt), "dd/MM/yyyy")}</Col></Row>
                <Row><ColH>Max Number of Providers:</ColH><Col>{org.SubscriptionDetails.MaxProviders}</Col></Row>
                <Row><ColH>Monthly Cost:</ColH><Col>${toPriceString(org.SubscriptionDetails.SubscriptionMonthly, true)}</Col></Row>
                {/* <Row><ColH>New Client Fee:</ColH><Col>{subVarToPercentage(org.SubscriptionDetails.NewTransactionVar)}</Col></Row> */}
                <Row><ColH>Transaction Fee:</ColH><Col>{subVarToPercentage(org.SubscriptionDetails.StandardTransactionVar)}</Col></Row>
                {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" && <Row><ColH>Fund Fee (Bulk Billing/DVA/HICAPS):</ColH><Col>{subVarToPercentage(org.SubscriptionDetails.FundVar)}</Col></Row>}
                <Row><ColH>Card Fee:</ColH><Col>{subVarToPercentage(org.SubscriptionDetails.CardVar)} + 30c
                <TooltipText> This surcharge fee is charged to your business by stripe. This covers credit card, bank and merchant fees. </TooltipText> 
                <BlockSubtitle>?</BlockSubtitle></Col></Row>
              </tbody>
            </Table>
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  connect((state) => ({})),
)(OrgEditAccount_SubDets as any);

export const Table = styled.table`
`;

export const Row = styled.tr`
`;

export const ColH = styled.td`
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 25px;
  padding-top: 5px;
`;

 const TooltipText = styled.span`
  position: absolute;
  visibility: hidden;
  width: 240px;
  background-color: #555;
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 10;
  transition: opacity .6s;
  margin-left: 2px;
  margin-top: -5px;
`
const Col = styled.td`
color: ${colors.blackText.highEmphasis};
font-size: 15.8px;
letter-spacing: 0.15px;
line-height: 24px;
padding-top: 5px;
&:hover ${TooltipText} {
  visibility: visible;
}
`;

export const ColS = styled.td`
color: ${colors.blackText.highEmphasis};
font-size: 15.8px;
letter-spacing: 0.15px;
line-height: 24px;
padding-top: 5px;
`;

const BlockSubtitle = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 12.82px;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-left: 6px;
`;
