import { userStore } from "../mobx/stores/UserStore";
import { toastStore } from "../stores/toast-store";


class RestTaxonomyCategoryService {
  async fetchCategories() {
    try {
      const res = await fetch(`/api/v1/taxonomies/Categories/items`, {
        headers: {
          Authorization: userStore.getAuthHeader()
        }
      });
      const json = await res.json();
      if (json.StatusCode !== 200) {
        return Promise.resolve([]);
      }
      return json.Payload;
    } catch(err) {
      toastStore.error('Error occurred while fetching categories');
      return Promise.resolve([]);
    }
  }
}

export const restTaxonomyCategoryService = new RestTaxonomyCategoryService();
