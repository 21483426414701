import React, { Component } from "react";
import LoadingIcon from "./../../../components/icons/Loading";
import { GroupOverview } from "./PaymentWizard/GroupOverview";
import { observer } from "mobx-react";
import { observable, computed, toJS } from "mobx";
import { Booking, BookingClient, ProcessBookingRequest } from "sdk/dist/bookings_pb";
import {
  LargeButtonText,
  RightBottomButtonContainer,
  LargeBottomButton,
  BackButton
} from "./Entry";
import { LoadingIndicator } from "./../../../util/loading";
import { rpc, metadata } from "./../../../grpc";
import { bookingStore } from "./../../../stores/booking-store";
import { Payment } from "./Payment";
import { AccountItem } from "sdk/dist/account_items_pb";
import { accItemStore } from "./../../../stores/acc_item-store";
import { FundType } from "sdk/dist/offerings_pb";
import { toastStore } from "./../../../stores/toast-store";

interface Props {
  booking: Booking.AsObject;
  onRequestClose: () => void;
  dispatch?: any;
}

@observer
export class GroupPayment extends Component<Props> {
  @observable
  private indicator = new LoadingIndicator();
  @observable
  private clientSelected = new BookingClient().toObject();
  private interval: any;
  @observable
  private mounted = false;

  @computed
  get accountItems() {
    const accStoreFilter = accItemStore.all();
    return accStoreFilter.filter(
      (ai: AccountItem.AsObject) => ai.booking!.id == this.props.booking.id
    );
  }

  async componentDidMount() {
    const { booking, dispatch } = this.props;
    await accItemStore.loadByBookingClient(booking.id);

    this.mounted = true; // Toggle to activate refresh
    // Refresh stores every 5 seconds
    this.loadStores(booking);
  }

  componentWillUnmount() {
    // stop call execution
    this.mounted = false;
    clearInterval(this.interval);
  }

  async loadStores(booking: Booking.AsObject) {
    this.interval = setInterval(() => {
      if (
        this.mounted &&
        this.accountItems
          .filter(
            (ai) =>
              ai.status != AccountItem.Status.ACC_ITEM_ERROR &&
              ai.fundType != FundType.THIRD_PARTY_INVOICE
          )
          .filter(
            (ai) =>
              ai.status == AccountItem.Status.ACC_ITEM_PROCESSING ||
              ai.status == AccountItem.Status.ACC_ITEM_CREATED
          ).length > 0
      ) {
        bookingStore.load(booking.id);
        accItemStore.loadByBookingClient(booking.id);
      }
    }, 5000);
  }

  onBackClicked = () => {
    this.props.onRequestClose();
  };

  async processGroupBooking(booking: Booking.AsObject) {
    await this.indicator.while(async () => {
      try {
        const req = new ProcessBookingRequest();
        req.setBookingId(booking.id);
        const res = await rpc.bookings.processBooking(req, metadata());
        bookingStore.add(res.toObject().booking!);
        await accItemStore.loadByBookingClient(booking.id);
        this.loadStores(booking);
        toastStore.success("Group booking processed successfully.");
      } catch (err) {
        toastStore.grpcError(err);
      }
    });
  }

  checkProcessButtonDisabledGroupBooking() {
    const { booking } = this.props;
    // if booking not in created status
    if (booking.status !== Booking.Status.BOOKING_CREATED) {
      return true;
    }
    // if no clients
    if (booking.clientsList.length === 0) {
      return true;
    }
    // if booking clients are not cancelled or rejected
    const validClients = booking.clientsList.filter(
      (client) =>
        client.status !== BookingClient.Status.CANCELLED &&
        client.status !== BookingClient.Status.REJECTED
    );
    // if there is a client which is not approved and not rejected
    if (
      validClients.filter((client) => client.status !== BookingClient.Status.APPROVED).length > 0
    ) {
      return true;
    }
    // if not any client with payment ready
    const noClientsReadyToPay = booking.clientsList.filter(
      (c) => c.statusMessage === "Ready for Payment"
    ).length;
    if (noClientsReadyToPay === 0) {
      return true;
    }

    return false;
  }

  onClientSelect = (client: BookingClient.AsObject) => {
    this.clientSelected = client;
  };

  render() {
    const { booking, dispatch } = this.props;
    const noClientsReadyToPay = booking.clientsList.filter(
      (c) => c.statusMessage === "Ready for Payment"
    ).length;

    return this.clientSelected.paymentStatus ? (
      <Payment
        booking={booking}
        client={this.clientSelected}
        onRequestClose={() => (this.clientSelected = new BookingClient().toObject())}
        dispatch={dispatch}
      />
    ) : (
      <>
        <GroupOverview
          booking={booking}
          onClientSelect={this.onClientSelect}
          indicator={this.indicator}
          {...this.props}
        />
        {booking.status === Booking.Status.BOOKING_CREATED && (
          <RightBottomButtonContainer>
            <LargeBottomButton
              disabled={this.checkProcessButtonDisabledGroupBooking()}
              onClick={() => this.processGroupBooking(this.props.booking!)}
            >
              {this.indicator.isLoading() ? (
                <LoadingIcon
                  width={16}
                  height={16}
                  color="#FFF"
                  style={{ display: "block", margin: "auto" }}
                />
              ) : (
                <LargeButtonText>{`Process ${noClientsReadyToPay} payment(s)`}</LargeButtonText>
              )}
            </LargeBottomButton>
          </RightBottomButtonContainer>
        )}
        <BackButton onClick={this.onBackClicked}>Back</BackButton>
      </>
    );
  }
}
