import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Components
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import TextArea from "./../../../components/form/TextArea";

class OrgEditAccount_IFrame extends Component {
  render() {
    const {
      org
    } = this.props;
    return (
      <React.Fragment>
        <FormHeader
          title="IFrame"
          subtitle="Copy the code below to enable booking at this organisation from your website."
        />
        <SectionsContainer>
          <Section>
            <Code value={org.IFrameSrc} rows={8} disabled/>
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  connect((state) => ({})),
  withToastManager
)(OrgEditAccount_IFrame);

const Code = styled(TextArea)`
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
`;
