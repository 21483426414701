import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import * as Yup from "yup";

import * as ReduxDialogs from "./../../redux/features/dialogs";

// components
import AdminSubHeader from "../../components/elements/AdminSubHeader";
import ResourceTable from "./../../components/elements/ResourceTable";

import ResourceFetch from "./../../components/elements/ResourceFetch";
import ResourceRender from "./../../components/elements/ResourceRender";

import ResourcePageError from "./../../components/elements/ResourcePageError";

import UserBlockDeprecated from "./../../components/elements/UserBlock";

class OrgLocationStaff extends Component {
  tableRef = React.createRef();

  state = {
    filter: {
      Filter: "All",
      SortBy: "Info",
      SortDir: "Asc"
    }
  };

  static getDerivedStateFromProps = (props, state) => {
    if (state.filter.Location !== props.resource.ID) {
      return {
        filter: {
          ...state.filter,
          Location: props.resource.ID
        }
      };
    }
    return null;
  };

  handleSort = (sortColumn, sortDesc) => {
    this.setState({
      filter: {
        ...this.state.filter,
        SortBy: sortColumn,
        SortDir: sortDesc ? "Desc" : "Asc"
      }
    });
  };

  handleSearch = (val) => {
    this.setState({
      filter: {
        ...this.state.filter,
        Search: val
      }
    });
  };

  handleAdd = () => {
    this.props.dispatch(
      ReduxDialogs.addStaffDialog(this.props.resource, () => {
        if (this.tableRef.current) {
          this.tableRef.current.refreshTable();
        }
      })
    );
  };

  handleSelect = (uloc) => {
    const { resource } = this.props;
    this.props.dispatch(ReduxDialogs.staffActionDialog(uloc, resource.ID, () => { }));
  };

  render() {
    const { filter } = this.state;
    const { resource } = this.props;

    return (
      <React.Fragment>
        <AdminSubHeader
          type="table"
          subHeader="Our Staff"
          // settings={[
          //   {
          //     label: "Export Data",
          //     onClick: ()=>{}
          //   }
          // ]}
          onAdd={this.handleAdd}
          onSearch={this.handleSearch}
        />
        <ResourceTable
          ref={this.tableRef}
          listType="LocationUsersPage"
          resourceType="OrganisationUser"
          filter={filter}
          onSort={this.handleSort}
          columns={["Info", "Email", "Profession", "Role", "Status"]}
          columnInfo={{
            Info: {
              type: "Component",
              label: "Basic Info",
              weight: 1.5,
              min: 200,
              component: React.memo(({ resource: res }) => (
                <StyleUserBlock user={res.User} alt={res.User.MobileNumber} size="small" />
              )),
              onClick: this.handleSelect
            },
            Email: {
              type: "Label",
              label: "Email",
              weight: 1,
              min: 200,
              max: 400,
              format: (res) => res.User.Email,
              onClick: this.handleSelect
            },
            Profession: {
              type: "Input",
              label: "Profession",
              weight: 1,
              min: 200,
              max: 300,
              prop: "RoleProperties",
              validator: Yup.string().required("Provider profession is required"),
              initialVal: (res) => (!res || !res.ProviderProfession ? "" : res.ProviderProfession),
              finalObj: (res, v) => ({
                ...res,
                Org: res.Org.ID,
                User: {
                  ID: res.User.ID,
                  FirstName: res.User.FirstName,
                  LastName: res.User.LastName,
                  Email: !res.User.Email ? null : res.User.Email,
                  MobileNumber: !res.User.MobileNumber ? null : res.User.MobileNumber,
                  MobileCountryCode: !res.User.MobileCountryCode ? null : res.User.MobileCountryCode
                },
                RoleProperties: {
                  ProviderProfession: !v ? "" : v.trim(),
                  ProviderBio:
                    !res.RoleProperties || !res.RoleProperties.ProviderBio
                      ? null
                      : res.RoleProperties.ProviderBio
                },
                Roles: res.Roles,
                IsActive: res.IsActive
              }),
              inputProps: {
                type: "string"
              }
              // onClick: this.handleSelect
            },
            Role: {
              type: "Label",
              label: "Role",
              weight: 1,
              min: 200,
              max: 300,
              format: (res) => {
                const roles = res.Locations.find((locU) => locU.Location.ID === resource.ID).Roles;
                if (roles.length > 1) {
                  return "Multiple";
                }
                return roles[0];
              },
              onClick: this.handleSelect,
              noOrder: true
            },
            Status: {
              type: "Pill",
              label: "Status",
              weight: 0.1,
              min: 170,
              max: 200,
              format: (res) =>
                res.Locations.find((v) => v.Location.ID === resource.ID).IsActive
                  ? "Active"
                  : "Inactive"
            }
          }}
        rowOptions={[
          {
            label: "Edit",
            onClick: this.handleSelect
          }
        ]}
        />
      </React.Fragment>
    );
  }
}

const ConnectedOrgLocationOfferings = connect()(OrgLocationStaff);

export default React.memo(({ match, history }) => (
  <React.Fragment>
    <ResourceFetch
      type="Location"
      ids={{ ID: match.params.lid }}
      extraData={{ Context: "Admin" }}
    />
    <ResourceRender
      type="Location"
      ids={{ ID: match.params.lid }}
      denorm={true}
      compSuccess={ConnectedOrgLocationOfferings}
      compError={ResourcePageError}
      forwardedProps={{ history }}
    />
  </React.Fragment>
));

const StyleUserBlock = styled(UserBlockDeprecated)`
  padding: 0 20px;
  height: 100%;
`;

// CSS overall for Main content
// const MainContainer = styled.div`
//   width: 100%;
//   background-color: #F4F8F9;
//   overflow-y: hidden;
//   display: inline-block;
//   padding: 0 36px;
//   min-height: calc(100vh - 69px);
// `;
