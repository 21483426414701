import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import MediaInput from "./../../../components/form/MediaInput";
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import TextArea from "./../../../components/form/TextArea";

class OrgEditAccount_Desc extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Description"
          subtitle="Please fill out a relevant description of your business"
        />
        <SectionsContainer>
          <Section>
            <TextArea
              id="editorg-description"
              name="Description"
              type="textarea"
              placeholder="Description"
              autoFocus={true}
              value={values.Description}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={8}
              error={touched.Description && errors.Description}
            />
            {!!errors.Description && touched.Description && <Error>{errors.Description}</Error>}
          </Section>
        </SectionsContainer>
        <FormHeader
          title="Logo"
          subtitle="Upload your organisation's logo that will be displayed in your invoices and tax receipts"
        />
        <SectionsContainer>
          <Section>
            <MediaInput
              type="avatar"
              name="Logo"
              value={values.Logo}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Logo && touched.Logo && <Error>{errors.Logo}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150, marginTop: "40px" }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Description}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }) => ({
    Description: org.Description || "",
    Logo: org.Logo.ID || ""
  }),
  validationSchema: Yup.object().shape({
    Description: Yup.string().required("Description is required."),
    Logo: Yup.string().required("Logo is required.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updOrg = {
        ...props.org,
        Description: values.Description.trim(),
        Logo: values.Logo
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountDesc"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgEditAccount_Desc);
