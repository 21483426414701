import * as Sentry from "@sentry/browser";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as Redux from "redux";
import { getType } from "typesafe-actions";

import ReduxTypes from "ReduxTypes";
import { userStore } from "../../mobx/stores/UserStore";
import { authService } from "../../services/AuthService";
import { userService } from "../../services/UserService";
import * as ReduxAuth from "./../../redux/features/auth";
import { delay, generateID } from "./../../util";
import LockGroup from "./../../util/LockGroup";

// HTTPMethod descibes the type of http methods allowed.
export enum HTTPMethod {
  Get = "get",
  Post = "post",
  Put = "put",
  Delete = "delete",
  Patch = "patch",
  Options = "options"
}

// HTTPResponseType descibes the type responses from an http request.
export enum HTTPResponseType {
  ArrayBuffer = "arraybuffer",
  Blob = "blob",
  Document = "document",
  JSON = "json",
  Text = "text"
}

// APIErrorItemFieldType descibes the type of possible field errors expected
// from the Ineffable backends.
export enum APIErrorItemFieldType {
  Body = "body",
  Query = "query",
  Header = "header"
}

// APIMetadata implements the metadata structure you can expect from the
// Ineffable backend.
export interface APIMetadata {
  ResponseCount?: number;
  TotalCount?: number;
  NextPage?: string;
  PrevPage?: string;
}

// APIError implements the error structure you can expect from the Ineffable
// backends.
export interface APIErrorItem {
  ErrorCode: string;
  RefID?: string;
  FieldName?: string;
  FieldType?: APIErrorItemFieldType;
  UserContext?: string;
  UserMessage?: string;
}

// APIResponse implements the error structure you can expect from the
// Ineffable backends.
export interface APIResponse {
  Status: string;
  StatusCode: number;
  Metadata: APIMetadata;
  Payload: any;
  Errors?: APIErrorItem[];
}

// isAPIResponse check if any is of APIResponse.
function isAPIResponse(arg: any): arg is APIResponse {
  const st = arg && arg.Status && typeof arg.Status === "string";
  const sc = arg && arg.StatusCode && typeof arg.StatusCode === "number";
  const md = arg && arg.Metadata && typeof arg.Metadata === "object";
  const pl =
    arg &&
    arg.Payload &&
    (typeof arg.Payload === "object" ||
      typeof arg.Payload === "boolean" ||
      typeof arg.Payload === "string" ||
      arg.Payload === null ||
      Array.isArray(arg.Payload));
  return st && sc && md && pl;
}

// APIErrorResponse implements the error structure you can expect from the
// Ineffable backends.
export interface APIErrorResponse {
  Status: string;
  StatusCode: number;
  Errors: APIErrorItem[];
}

// isAPIErrorResponse check if any is of APIErrorResponse.
function isAPIErrorResponse(arg: any): arg is APIErrorResponse {
  const st = arg && arg.Status && typeof arg.Status === "string";
  const sc = arg && arg.StatusCode && typeof arg.StatusCode === "number";
  const ed = arg && arg.Errors && Array.isArray(arg.Errors);
  return st && sc && ed;
}

// APITokenType defines the type of token returned from the API.
export enum APITokenType {
  User = "user",
  Client = "client"
}

// AuthCreds describes the current authentication credentials.
export interface AuthCreds {
  Type: APITokenType;
  Token: string;
  ExpiresAt: number; // Unix time.
  UserID?: string;
  RefreshToken?: string;
}

// APIError wraps API errors returned by Ineffable backends.
export class APIError extends Error {
  name: string = "APIError";
  userMessages: string = "";
  message: string = "";
  stack?: string;

  method: HTTPMethod;
  path: string;
  statusCode: number;
  status: string;
  apiErrors: APIErrorItem[];

  constructor(method: HTTPMethod, path: string, response: APIErrorResponse) {
    super("APIError");
    this.method = method;
    this.path = path;
    this.statusCode = response.StatusCode;
    this.status = response.Status;
    this.apiErrors = response.Errors;

    this.userMessages = this.apiErrors.map((v) => v.UserMessage).join(", ");

    const msgs = this.apiErrors
      .map((v) => `${v.ErrorCode} (${v.RefID || "NONE"}) "${v.UserMessage}"`)
      .join(", ");
    this.message = `API ERROR: (${this.method}) ${this.path}: [${this.statusCode}] ${msgs}`;

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(this.message).stack;
    }
  }
}

interface APIRequestOptions {
  method: HTTPMethod;
  path: string;
  id?: string;
  group?: string;
  host?: string;
  query?: any;
  data?: any;
  headers?: { [key: string]: string };
  responseType?: HTTPResponseType;
  maxContentLength?: number;
  noAuth?: boolean;
  timeout?: number;
  progress?: (upload: boolean, event: any) => void;
}

interface APIMediaUploadRequest {
  id: string;
  file: File;
  headers?: { [key: string]: string };
  progress?: (upload: boolean, event: any) => void;
}

// APIConnectorOptions defines the options that may be passed to APIConnector.
interface APIConnectorOptions {
  name: string;
  host?: string;
  debug: boolean;
  authClient: {
    id: string;
    secret: string;
  };
  maxRetries: number;
  maxContentLength: number;
}

// APIConnector provides the interface to Ineffable backends.
// It handles client and user authentication while maintaining the redux
// auth state. Note that any auth actions such as userLogin, userLogout, and
// refreshing the authentication will wait until previous auth actions have
// completed.
class APIConnector {
  // Defines the chunk size for media uploads.
  static MEDIA_UPLOAD_CHUNK_SIZE = 5 << 20; // 5MB
  // config provides the API options.
  config: APIConnectorOptions;
  // redux provides a way of dispatching redux actions to the store through
  // the created middleware.
  redux: Redux.MiddlewareAPI<Redux.Dispatch, ReduxTypes.RootState> | null = null;
  // curAuth stores the current authentication credentials as they are in the
  // redux store.
  curAuth: AuthCreds | null = null;
  // authLock makes sure any auth requests are completed sequentially.
  authLock: LockGroup<Error | null> | null = null;
  // requests contains the current running requests' cancel source and group id.
  requests: { [id: string]: { group?: string; csource: CancelTokenSource } } = {};
  // requestGroups contains the request ids for all requests in the given group.
  requestGroups: { [id: string]: string[] } = {};

  // newRequestId creates a new request id and adds the request to the requests
  // and requestGroups props.
  private newRequestId(options: APIRequestOptions): string {
    const reqID = options.id || generateID();
    if (!this.requests[reqID]) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.requests[reqID] = {
        group: options.group,
        csource: source
      };
      if (typeof options.group === "string") {
        if (!this.requestGroups[options.group]) {
          this.requestGroups[options.group] = [reqID];
        } else {
          this.requestGroups[options.group].push(reqID);
        }
      }
    }
    return reqID;
  }

  // clearRequestId removes a request from the requests and requestGroups props.
  private clearRequestId(reqID: string) {
    if (!this.requests[reqID]) {
      return;
    }
    const rg = this.requests[reqID].group;
    if (typeof rg === "string" && this.requestGroups[rg]) {
      this.requestGroups[rg] = this.requestGroups[rg].filter((v) => v === reqID);
      if (this.requestGroups[rg].length === 0) {
        delete this.requestGroups[rg];
      }
    }
    delete this.requests[reqID];
  }

  // debugMsg prints a formatted message in debug sessions, otherwise is
  // considered a nop.
  private debugMsg(place: string, extra: string, ...args: any[]) {
    if (this.config.debug) {
      console.info(`API: ${place}: ${extra}`, ...args);
    }
  }

  // splitJWT splits the given JWT token into its metadata, data, and signature
  // objects.
  private splitJWT(token: string) {
    const [meta, data, sig] = token.split(".");
    return {
      meta: JSON.parse(atob(meta)),
      data: JSON.parse(atob(data)),
      sig
    };
  }

  constructor(config: APIConnectorOptions) {
    this.config = config;
    this.debugMsg("constructor", "initial config", this.config);
  }

  async userLoginWithToken(token: string) {
    const payload = authService.parseJwtPayload(token);
    const authCreds = {
      Type: APITokenType.User,
      Token: token,
      ExpiresAt: 1000 * payload!.exp!,
      UserID: "",
      RefreshToken: ""
    };
    if (payload && payload.sub) {
      userStore.setToken(token);
      const user = await userService.fetchUser(payload.sub, () => {
        return { authorization: `Bearer ${token}` };
      });
      if (user && user.user) {
        userStore.setUser(user.user);
        authCreds.UserID = user.user.id;
      }
    }

    this.redux!.dispatch(ReduxAuth.actions.set(authCreds));
    return authCreds;
  }

  // userLogin creates user authentication credentials for the given
  // email/password combination. It returns the result of the set auth request
  // before the redux auth action fires.
  async userLogin(
    email: string | null,
    phone: string | null,
    ccode: string | null,
    password: string
  ): Promise<APIResponse> {
    this.debugMsg("userLogin", "entry");

    // If we're already doing something with the auth then wait for that to
    // complete.
    while (this.authLock !== null) {
      await this.authLock.waitHere();
    }

    this.authLock = new LockGroup<Error | null>();
    try {
      let username = phone ? phone! : email!;

      const res = await authService.login(username, password);
      if (res.error) {
        throw new Error(res.error_description);
      }

      const authCreds = this.userLoginWithToken(res.access_token);

      // Return the result.
      return {
        Payload: {
          UserID: (await authCreds).UserID
        },
        Status: "ok",
        StatusCode: 200,
        Metadata: {}
      };
    } catch (err) {
      this.authLock!.release(null);
      this.authLock = null;
      throw err;
    }
  }

  // userLogin invalidates the current user authentication credentials.
  // It returns the result of the auth request before the redux clear auth
  // action fires.
  // NOTE: This function is only clearing the token from the localstorage
  // We are not calling Identity server for logout
  async userLogout() {
    this.debugMsg("userLogout", "entry");
    // If we're already doing something with the auth then wait for that to
    // complete.
    while (this.authLock !== null) {
      await this.authLock.waitHere();
    }

    // Ignore logouts if we aren't logged in.
    if (this.curAuth === null) {
      return {
        Status: "ok",
        StatusCode: 200,
        Metadata: {},
        Payload: {}
      };
    }

    userStore.token = undefined;
    userStore.user = undefined;

    // Emit a set auth action.
    this.redux!.dispatch(ReduxAuth.actions.clear());

    this.refreshAuthentication("");
  }

  // refreshAuthentication makes sure the current authentication credentials
  // exist and not expired. If the credentials do not exist then we generate
  // a new client authentication token, otherwise if they are expired it will
  // refresh the correct token type.
  //
  // The function will block until the refresh request has completed. If it
  // completes successfully then it fires a refresh auth redux action and
  // returns null once the action has completed. If it does not refresh
  // successfully due to an invalid or expired refresh token then it it will
  // fire a invalid auth redux action and then return the returned APIError
  // once the action has completed; any other error will be returned
  // immediately.
  async refreshAuthentication(reqID: string) {
    this.debugMsg("refreshAuthentication", `${reqID} entered`, this.curAuth);

    // Check if we are already waiting for an auth operation, if so wait
    // until it has finished.
    while (this.authLock !== null) {
      this.debugMsg("refreshAuthentication", `${reqID} waiting for lock`);
      await this.authLock.waitHere();
    }

    // Check if we need to refresh.
    // Check if we have clientToken
    // We add a second to the expires time to try and get ahead of the
    // request.
    if (
      this.curAuth !== null &&
      this.curAuth.ExpiresAt > Date.now() + 1000 &&
      localStorage.getItem("clientToken")
    ) {
      return null;
    }

    // Before refreshing authentication, clear local storage
    localStorage.clear();

    // Refresh the required authentication type.
    this.authLock = new LockGroup<Error | null>();
    let refreshType: APITokenType = !this.curAuth ? APITokenType.Client : this.curAuth.Type;

    this.debugMsg("refreshAuthentication", `${reqID} starting ${refreshType}`);
    try {
      let authCreds: AuthCreds;
      switch (refreshType) {
        case APITokenType.Client: {
          const res = await this.performRequest({
            method: HTTPMethod.Get,
            path: "/api/v1/token/client",
            noAuth: true
          });

          const { data } = this.splitJWT(res.Payload.ClientToken);

          authCreds = {
            Type: APITokenType.Client,
            Token: res.Payload.ClientToken,
            ExpiresAt: 1000 * data.exp
          };
          userStore.setClientToken(res.Payload.ClientToken);
          break;
        }
        case APITokenType.User: {
          const res = await this.performRequest({
            method: HTTPMethod.Post,
            path: "/api/v1/token/refresh",
            data: {
              UserID: this.curAuth!.UserID,
              RefreshToken: this.curAuth!.RefreshToken
            },
            noAuth: true
          });

          const { data } = this.splitJWT(res.Payload.UserToken);

          authCreds = {
            Type: APITokenType.User,
            Token: res.Payload.UserToken,
            ExpiresAt: 1000 * data.exp,
            UserID: this.curAuth!.UserID,
            RefreshToken: this.curAuth!.RefreshToken
          };
          break;
        }
      }

      // Emit a refresh auth action.
      setTimeout(() => this.redux!.dispatch(ReduxAuth.actions.refresh(authCreds)), 0);

      // Wait here until the refresh auth reducer has run.
      return await this.authLock.waitHere();
    } catch (err) {
      this.debugMsg("refreshAuthentication", `${reqID} error ${err.message}`, err);
      // If the refresh token has expired or become invalidated then fire a
      // invalidate auth action.
      if (
        err instanceof APIError &&
        err.apiErrors.findIndex(
          (v) =>
            v.ErrorCode === "request_token_invalid" ||
            v.ErrorCode === "request_token_expired" ||
            v.ErrorCode === "request_json_invalid_value"
        ) >= 0
      ) {
        setTimeout(() => this.redux!.dispatch(ReduxAuth.actions.invalid(err)), 0);
      } else {
        // Make sure we release the lock in the case the backend cannot be contacted.
        this.authLock!.release(err);
        this.authLock = null;
      }

      return err;
    }
  }

  // Performs an XHR request with the given options.
  //
  // if noAuth has been set then the client id/secret are appended to the
  // query parameters, otherwise the current authenetication is refreshed
  // and the request performed.
  //
  // If the responseType is JSON then the standard APIResponse is expected.
  // Otherwise the given response type is returned.
  // All non 200/300 codes are expected to return a JSON object mapping
  // to APIErrorResponse.
  async performRequest(options: APIRequestOptions): Promise<APIResponse> {
    // Generate the id and cancel token.
    const reqID = this.newRequestId(options);
    // Try the request a few times.
    let lastErrorResponse: Error | APIError = new Error("unknown API error");
    let curTry = 0;
    while (curTry < this.config.maxRetries) {
      this.debugMsg("performRequest", `${reqID} try ${curTry}`, options);

      // Construct authentication query params and headers.
      const authQuery: any = {};
      const authHeaders: any = {};

      if (options.noAuth) {
        // Only pass the client id/secret as query params when not using auth.
        authQuery.ClientID = this.config.authClient.id;
        authQuery.ClientSecret = this.config.authClient.secret;
      } else {
        // Check authentication.
        this.debugMsg("performRequest", `${reqID} refreshAuthentication`);
        const err = await this.refreshAuthentication(reqID);
        if (err !== null) {
          throw err;
        }
        authHeaders["Authorization"] = "Bearer " + this.curAuth!.Token;
      }

      try {
        this.debugMsg("performRequest", `${reqID} sending request`);
        const responseType = options.responseType || HTTPResponseType.JSON;
        const result: AxiosResponse = await axios.request({
          method: options.method,
          url: options.path,
          baseURL: options.host || this.config.host,
          params: {
            ...authQuery,
            ...options.query
          },
          data: options.data,
          headers: {
            ...authHeaders,
            ...options.headers
          },
          timeout: options.timeout || 30000,
          responseType,
          maxContentLength: options.maxContentLength || this.config.maxContentLength,
          onUploadProgress: (event: any) => {
            if (typeof options.progress === "function") {
              options.progress(true, event);
            }
          },
          onDownloadProgress: (event: any) => {
            if (typeof options.progress === "function") {
              options.progress(false, event);
            }
          },
          cancelToken: this.requests[reqID].csource.token
        });
        this.debugMsg("performRequest", `${reqID} axios Result`, result);
        // Check the content type is JSON.
        const contentType = result.headers["content-type"];
        if (!contentType && result.data === null) {
          result.data = {
            Status: result.statusText || "ok",
            StatusCode: result.status,
            Metadata: {},
            Payload: {}
          };
        } else if (
          responseType === HTTPResponseType.JSON &&
          contentType.indexOf("application/json") < 0
        ) {
          throw new Error(
            `Unexpected content-type from (${options.method}) ${options.path}: ${contentType}`
          );
        }

        // Check the content is structured as expected.
        if (responseType === HTTPResponseType.JSON && !isAPIResponse(result.data)) {
          throw new Error(
            `Unexpected content from (${options.method}) ${options.path}: does not implement APIResponse`
          );
        }

        // Return the successfull response!
        this.clearRequestId(reqID);
        return Promise.resolve(result.data as APIResponse);
      } catch (error) {
        if (axios.isCancel(error)) {
          this.debugMsg("performRequest", `${reqID} axios Cancel Request`, error);
          this.clearRequestId(reqID);
          throw new APIError(options.method, options.path, {
            Status: "Request Cancelled",
            StatusCode: 0,
            Errors: [
              {
                ErrorCode: "request_cancelled",
                UserContext: "Network Request Error",
                UserMessage: "This request was cancelled by the user."
              }
            ]
          });
        } else if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx.
          this.debugMsg("performRequest", `${reqID} axios Response Error`, error.response);

          // Generate an API error from the given response.
          if (!error.response.data) {
            let userMessage = `The request failed with status: ${error.response.statusText}`;
            switch (error.response.status) {
              case 429: // Too Many Requests
                userMessage = "Too many requests have been performed, " + "please try again later.";
                break;
              case 502: // Bad Gateway
              case 504: // Gateway Timeout
                userMessage =
                  "We were unable to contact the backend servers. " +
                  "Please make sure you are connected to the internet and try again.";
                break;
              case 503: // Service Unavailable
                userMessage =
                  "The backend service is currently unavailible, " + "please try again later.";
                break;
            }
            lastErrorResponse = new APIError(options.method, options.path, {
              Status: error.response.statusText,
              StatusCode: error.response.status,
              Errors: [
                {
                  ErrorCode: "unknown",
                  UserContext: "Request Error",
                  UserMessage: userMessage
                }
              ]
            });
          } else if (isAPIErrorResponse(error.response.data)) {
            lastErrorResponse = new APIError(options.method, options.path, {
              Status: error.response.data.Status,
              StatusCode: error.response.data.StatusCode,
              Errors: error.response.data.Errors
            });
          } else {
            this.clearRequestId(reqID);
            throw new Error(
              `Unexpected error content from (${options.method}) ${options.path}: does not implement APIErrorResponse`
            );
          }

          // Check if auth has expired or become invalid.
          if (
            lastErrorResponse instanceof APIError &&
            lastErrorResponse.apiErrors.findIndex(
              (v) =>
                v.ErrorCode === "request_token_invalid" || v.ErrorCode === "request_token_expired"
            ) >= 0
          ) {
            return this.performRequest({
              ...options,
              id: reqID
            });
          }

          // If it isn't a auth error check if we may recover by waiting.
          switch (error.response.status) {
            case 429: // Too Many Requests
            case 502: // Bad Gateway
            case 503: // Service Unavailable
            case 504: // Gateway Timeout
              break;
            default:
              // Other codes are unlikely to resolve themselves by waiting so
              // throw this error straight away.
              this.clearRequestId(reqID);
              throw lastErrorResponse;
          }
        } else if (error.request) {
          // The request was made but no response was received.
          this.debugMsg("performRequest", `${reqID} axios Request Error`, error.request);

          lastErrorResponse = new APIError(options.method, options.path, {
            Status: "Request Error",
            StatusCode: 0,
            Errors: [
              {
                ErrorCode: "bad_network",
                UserContext: "Network Request Error",
                UserMessage:
                  "We were unable to contact the backend servers. " +
                  "Please make sure you are connected to the internet and try again."
              }
            ]
          });
        } else {
          // Something happened in setting up the request that triggered an Error.
          // Push this error upstream.
          this.debugMsg("performRequest", `${reqID} axios unknown Error`, error);
          this.clearRequestId(reqID);
          throw error;
        }
      }

      // If we got here then the request failed and may recover by waiting
      // and trying again, therefore wait before trying the request again.
      const waitTime = 500 * Math.pow(2, curTry);
      curTry = await delay(curTry + 1, waitTime);
    }
    this.clearRequestId(reqID);
    throw lastErrorResponse;
  }

  // cancelRequest cancels all requests with the given group id.
  cancelRequest(reqID: string) {
    if (!this.requests[reqID]) {
      return;
    }
    this.debugMsg("cancelRequest", reqID, this.requests[reqID]);
    this.requests[reqID].csource.cancel();
  }

  // cancelGroupRequests cancels all requests with the given group id.
  cancelGroupRequests(group: string) {
    this.debugMsg("cancelGroupRequests", group, this.requestGroups[group]);
    (this.requestGroups[group] || []).forEach((v) => this.cancelRequest(v));
  }

  // uploadMedia uploads a file in chunks to a media object.
  // In order to cancel call cancelGroupRequests with the id passed
  // to options.
  async uploadMedia(options: APIMediaUploadRequest) {
    this.debugMsg("uploadMedia", `entry`, options);

    if (!(options.file instanceof File)) {
      throw new Error("invalid file, expected instance of File.");
    }

    let uploadID: string = "";
    let lastPartID: number = 0;
    try {
      let partTags = [];

      // Begin the upload.
      this.debugMsg("uploadMedia", `begin upload`);
      const beginResponse = await this.performRequest({
        method: HTTPMethod.Post,
        path: `/api/v1/media/${options.id}/upload`,
        headers: {
          ...options.headers,
          "X-Upload-Action": "begin"
        },
        group: options.id
      });

      uploadID = beginResponse.Payload.UploadID;
      lastPartID = beginResponse.Payload.PartID;

      // Upload the parts.
      const totalSize = options.file.size; // Size of the file in bytes.
      let totalRead = 0; // Number of bytes read from the file.
      let totalTransferred = 0; // Number of bytes transferred.
      while (totalRead < totalSize) {
        this.debugMsg("uploadMedia", `part upload start=${totalRead}`);

        const blob = options.file.slice(
          totalRead,
          Math.min(totalRead + APIConnector.MEDIA_UPLOAD_CHUNK_SIZE, totalSize)
        );
        totalRead += blob.size;

        const reqData = new FormData();
        reqData.append("file", blob);
        const partResponse: APIResponse = await this.performRequest({
          method: HTTPMethod.Post,
          path: `/api/v1/media/${options.id}/upload`,
          data: reqData,
          timeout: 120000,
          headers: {
            ...options.headers,
            "X-Upload-Action": "part",
            "X-Upload-Id": uploadID,
            "X-Upload-Part": `${lastPartID}`
          },
          progress: (upload: boolean, event: any) => {
            if (typeof options.progress !== "function") {
              return;
            }
            if (upload) {
              options.progress(upload, {
                ...options.headers,
                loaded: totalTransferred + event.loaded,
                total: totalSize
              });
            } else {
              options.progress(upload, event);
            }
          },
          group: options.id
        });

        uploadID = partResponse.Payload.UploadID;
        lastPartID = partResponse.Payload.PartID;
        partTags.push(partResponse.Payload.PartTag);

        totalTransferred += blob.size;
      }

      // End the upload.
      this.debugMsg("uploadMedia", `end upload`);
      const endResponse = await this.performRequest({
        method: HTTPMethod.Post,
        path: `/api/v1/media/${options.id}/upload`,
        data: {
          PartTags: partTags
        },
        headers: {
          ...options.headers,
          "X-Upload-Action": "end",
          "X-Upload-Id": uploadID,
          "X-Upload-Part": `${lastPartID}`
        },
        group: options.id
      });

      return endResponse;
    } catch (err) {
      if (uploadID !== null) {
        await this.performRequest({
          method: HTTPMethod.Post,
          path: `/api/v1/media/${options.id}/upload`,
          headers: {
            ...options.headers,
            "X-Upload-Action": "abort",
            "X-Upload-Id": uploadID,
            "X-Upload-Part": `${lastPartID}`
          },
          group: options.id
        });
      }
      throw err;
    }
  }

  // Redux middleware to handle refreshing of authentication.
  reduxMiddleware: Redux.Middleware = (api: Redux.MiddlewareAPI) => {
    // Bind the store to the current api.
    this.redux = api;
    // Make sure we capture any changes to the auth data in the redux store.
    return (next: Redux.Dispatch) =>
      (action: Redux.AnyAction): Redux.AnyAction => {
        // We schedule the release of the locks before the action is run in case
        // there is an error thrown.
        switch (action.type) {
          case getType(ReduxAuth.actions.set):
          case getType(ReduxAuth.actions.clear):
          case getType(ReduxAuth.actions.refresh):
            if (this.authLock !== null) {
              setTimeout(() => {
                this.debugMsg("reduxMiddleware", `releasing auth lock`);
                this.authLock!.release(null);
                this.authLock = null;
              }, 0);
            }
            break;
          case getType(ReduxAuth.actions.invalid):
            if (this.authLock !== null) {
              this.debugMsg("reduxMiddleware", `releasing auth lock`, action.payload);
              setTimeout(() => {
                this.authLock!.release(action.payload);
                this.authLock = null;
              }, 0);
            }
            break;
        }
        // Run the reducers and get the current auth state.
        const result = next(action);
        this.curAuth = api.getState().auth;
        return result;
      };
  };

  errorProcess(err: Error | APIError): APIError {
    if (err instanceof APIError) {
      return err;
    } else {
      // Not an API Error.
      if (process.env.BOOKLYFE_ENVIRONMENT!.includes("production")) {
        console.warn("Unknown API Error", err);
        Sentry.captureException(err);
      } else {
        console.error(err);
      }
      // Send to sentry.
      const sentryId = Sentry.lastEventId();
      return new APIError(HTTPMethod.Get, "", {
        Status: "Internal Error",
        StatusCode: 0,
        Errors: [
          {
            ErrorCode: "internal_error",
            RefID: sentryId,
            UserContext: "Internal error",
            UserMessage:
              "There was an internal application error (Code: " +
              sentryId +
              "). " +
              "Please try again later. If the problem persists, contact support."
          }
        ]
      });
    }
  }
}

const apiService = new APIConnector({
  name: "booklyfe-backend",
  debug: !process.env.BOOKLYFE_ENVIRONMENT!.includes("production"),
  authClient: {
    id: "9R8KF2B2w8hbwNQU5L5Z",
    secret: "zwyrWQft2xAbVSMezPfZuLZKnuqTD9UJvGEeUMJeVEr"
  },
  maxRetries: 5,
  maxContentLength: 1024 * 1024
});

export default apiService;
