import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import MediaInput from "./../../../components/form/MediaInput";
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";

class OrgEditAccount_Market extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      setFieldError
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Market to your clients"
         subtitle="Download free banner images to make your Lyfe profile stand out at Unsplash.com (https://unsplash.com/search/photos/health)"        />
        <SectionsContainer>
          <Section>
            <MediaInput
              type="banner"
              name="Banner"
              value={values.Banner}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Banner && touched.Banner && <Error>{errors.Banner}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }) => ({
    Banner: org.Banner.ID || ""
  }),
  validationSchema: Yup.object().shape({
    Banner: Yup.string().required("Banner is required.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updOrg = {
        ...props.org,
        Banner: values.Banner
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountMarket"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgEditAccount_Market);
