const FAQClient = [{
  "question": "Is it free to become a member?",
  "answer": "<p>Yes, you can create an account free of charge. Client users do not pay for a Lyfe account, you only pay for the services your book using the Lyfe system.</p>"
}, {
  "question": "Where are the healthcare & wellness providers located?",
  "answer": "<p>Health & wellness professionals on Lyfe are located all over Australia.</p>"
}, {
  "question": "Is our payment and data information secure?",
  "answer": "<p>Yes, your payment and data information is secure and confidential. Lyfe Group is A-EP Level 4 PCI compliant and integrate into NAB’s (National Australia Bank) secure payment gateway. All data is secured using 256-bit encryption and complies with Australian health data and payment regulations. See Terms of Use for further information.</p>"
}, {
  "question": "Do you share patient or client information with 3rd party organisations?",
  "answer": "<p>Lyfe DOES NOT share user/patient/client data with 3rd party organisations. User data privacy is our core value and we take pride in our high security and ethical standards.</p>"
}, {
  "question": "My service provider is not listed on Lyfe?",
  "answer": "<p>You can email support@lyfe.io the contact information of your preferred service providers and we can look into listing them on our platform so they are easier to book with in the future.</p>"
}, {
  "question": "What should I do if I experience technical issues?",
  "answer": "<p>Reset the cache of your browser.</p>"
}, {
  "question": "How do I reset my password?",
  "answer": "<p>Click on Login/Signup in the top right corner and click on \"Forgot?\" to reset your password.</p>"
}, {
  "question": "How do I sign up?",
  "answer": "<p>Click on Login/Signup button in the top right corner and click on \"Sign up\" at the bottom.</p>"
}, {
  "question": "How quickly will I be refunded by Medicare?",
  "answer": "<p>The portion of the fee covered by medicare will be refunded in your bank account over night.</p>",
  "hideOnUS": true
}, {
  "question": "What is your support email?",
  "answer": "<p><a href=\"mailto:support@lyfe.io\">support@lyfe.io</a></p>"
}, {
  "question": "Where are you based out of?",
  "answer": "<p>We are based out of Melbourne, Australia.</p>"
}, {
  "question": "Can I see my transaction history?",
  "answer": "<p>Yes, you can see your transaction history and we will also send you a tax receipt by email after each payment is processed.</p>"
}, {
  "question": "How to cancel a booking?",
  "answer": "<p>At the moment the system allows the patient/client to book an appointment but if they need to change or cancel the appointment they need to speak to their health provider directly.</p>"
}, {
  "question": "How can I communicate with a healthcare or wellness provider?",
  "answer": "<p>All client bookings initiated by the client notify the provider via email.</p><p>Same goes the other way, all bookings initiated by the provider notify the client via email.</p><p>There are up to 50 predefined email notifications that are trigger based so both parties are fully informed of all actions on either side.</p>"
}, {
  "question": "How do I set up my payment & medicare information?",
  "answer": "<p>Click your initials in the top right corner after logging in. Then click on \"Personal Profile\" and go to \"Payments\" where you will be able to add you payment information.</p>",
  "hideOnUS": true
}, {
  "question": "How do I set up my payment information?",
  "answer": "<p>Click your initials in the top right corner after logging in. Then click on \"Personal Profile\" and go to \"Payments\" where you will be able to add you payment information.</p>",
  "hideOnAU": true
}, {
  "question": "Why do I need to enter my phone number?",
  "answer": "<p>So that we can send you an SMS reminder for your booking or so that your healthcare provider can contact you.</p>"
}, {
  "question": "Where can I see my past appointments?",
  "answer": "<p>Simply login to your Lyfe account to see all of your past and upcoming bookings. By clicking on one of the bookings, you will see the transaction details for that booking.</p>"
}, {
  "question": "Can you send me my Medicare rebate back from Medicare?",
  "answer": "<p>Lyfe doesn’t provide the rebate, what we do is facilitate the payment and send the claim info to Medicare (or HICAPS) so they can deposit the funds into your account overnight.</p>",
  "hideOnUS": true
}]


export default FAQClient
