import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Error from "./../../components/form/Error";
import { createForm, updateArrayItem, removeArrayItem, addArrayItem } from "./../../forms/forms";
import {
  ThirdPartyInvoiceDetails,
  ThirdPartyInvoiceLineItem
} from "sdk/dist/third_party_invoices_pb";
import { LoadingIndicator } from "./../../util/loading";
import {
  AccordianContainer,
  AccordianForm,
  ButtonContainer,
  ButtonHead,
  NewHeader
} from "../elements/AccordianElements";
import { CloseButton } from "../elements/CloseButton";
import Input from "../form/Input";
import Label from "../form/Label";
import Section from "../form/Section";
import LoadingButton from "../LoadingButton";
import SearchInput from "../form/SearchInput";
import { rpc, metadata } from "./../../grpc";
import * as Yup from "yup";
import { validABN } from "./../../util";
import { lineItemSchema, NewLineItemForm, emptyTpiLineItem } from "./NewLineItemForm";
import LargeDottedButton from "../LargeDottedButton";
import { HistoryThirdParty } from "sdk/dist/history_third_parties_pb";
import { tpiTemplateStore } from "./../../stores/tpi_template-store";
import {
  NewThirdPartyInvoiceTemplateLineItem,
  UpdateThirdPartyInvoiceTemplateRequest,
  ThirdPartyInvoiceTemplate,
  UpdatedTemplate
} from "sdk/dist/third_party_invoice_templates_pb";
import { Money, Charge } from "sdk/dist/money_pb";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

interface Props {
  client: any;
  template: ThirdPartyInvoiceTemplate.AsObject;
  onRequestClose: () => void;
  dfltCcy: ISOCurrencyName;
}

export const tpiDetailsSchema = Yup.object<ThirdPartyInvoiceDetails.AsObject>({
  companyName: Yup.string().required("The entities name is required"),
  companyAbn: Yup.string().default("").test("ABN Check", "Invalid ABN Number", validABN),
  companyEmailAddress: Yup.string(),
  providerId: Yup.string().default(""),
  claimId: Yup.string().default(""),
  lineItemsList: Yup.array().of(lineItemSchema).min(1, "At least one item is required")
});

@observer
export class ThirdPartyTemplateEditForm extends React.Component<Props> {
  @observable
  private history = new Array<HistoryThirdParty.AsObject>();

  @observable
  private indicator = new LoadingIndicator();

  private onSubmit = async (details: ThirdPartyInvoiceDetails.AsObject) => {
    const { client, template, onRequestClose } = this.props;

    // Create Third Party Invoice
    await this.indicator.while(async () => {
      const req = new UpdateThirdPartyInvoiceTemplateRequest();
      req.setTemplateId(template.id);

      const updatedTemplate = new UpdatedTemplate();
      updatedTemplate.setEntityName(details.companyName);
      updatedTemplate.setEntityAbn(details.companyAbn);
      updatedTemplate.setEntityEmailAddress(details.companyEmailAddress);
      updatedTemplate.setProviderId(details.providerId);
      updatedTemplate.setClaimNumber(details.claimId);
      updatedTemplate.setItemsList(
        // itemsList
        details.lineItemsList.map((item) => {
          const itemMsg = new NewThirdPartyInvoiceTemplateLineItem();
          itemMsg.setCode(item.code);
          itemMsg.setDescription(item.description);
          const money = new Money();
          if (item.charge && item.charge.amount && item.charge.tax) {
            money.setCurrencyCode(item.charge.amount.currencyCode);
            money.setUnits(item.charge.amount.units + item.charge.tax.units);
            money.setNanos(item.charge.amount.nanos + item.charge.tax.nanos);
          }
          itemMsg.setFee(money);
          itemMsg.setTaxType(item.taxType);
          return itemMsg;
        })
      );

      req.setUpdatedtemplate(updatedTemplate);
      const res = await rpc.thirdPartyInvoiceTemplate.update(req, metadata());
      await tpiTemplateStore.add(res.toObject());
    });

    // close form
    onRequestClose();
  };

  initialLineItem(): Array<ThirdPartyInvoiceLineItem.AsObject> {
    const { template } = this.props;
    const initialLineItems = new Array<ThirdPartyInvoiceLineItem.AsObject>();
    template.itemsList.map((item) => {
      const charge = new Charge();
      const amount = new Money();
      const tax = new Money();

      if (item.fee) {
        item.fee.currencyCode && amount.setCurrencyCode(item.fee.currencyCode);
        item.fee.units && amount.setUnits(item.fee.units);
        item.fee.nanos && amount.setNanos(item.fee.nanos);
      }

      charge.setAmount(amount);
      charge.setTax(tax);

      initialLineItems.push({
        code: item.code,
        description: item.description,
        charge: charge.toObject(),
        taxType: item.taxType
      });
    });
    return initialLineItems;
  }

  render() {
    const { client, template } = this.props;
    const Form = createForm<ThirdPartyInvoiceDetails.AsObject>();

    const initial: Partial<ThirdPartyInvoiceDetails.AsObject> = {
      claimId: template.claimNumber,
      companyAbn: template.entityAbn,
      companyEmailAddress: template.entityEmailAddress,
      companyName: template.entityName,
      providerId: template.providerId,
      lineItemsList: this.initialLineItem()
    };

    return (
      <AccordianContainer>
        {this.props.onRequestClose && <CloseButton onClick={this.props.onRequestClose} />}
        <NewHeader>
          <ButtonHead>New Third Party Invoice</ButtonHead>
        </NewHeader>
        {this.history.length >= 0 && (
          <Form
            initial={initial}
            schema={tpiDetailsSchema}
            onSubmit={this.onSubmit}
            component={AccordianForm}
          >
            {({ fields, updateField, errors }) => (
              <>
                <Section>
                  <Label>Entity Name</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.name }))}
                    inputValue={fields.companyName || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyName: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the name of the entity you wish to invoice"
                  />
                  {!!errors.companyName && <Error>{errors.companyName}</Error>}
                </Section>
                <Section>
                  <Label>Entity ABN</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.abn }))}
                    inputValue={fields.companyAbn || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyAbn: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the entity's ABN (optional)"
                  />
                  {!!errors.companyAbn && <Error>{errors.companyAbn}</Error>}
                </Section>
                <Section>
                  <Label>Entity Email Address</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.email }))}
                    inputValue={fields.companyEmailAddress || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyEmailAddress: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the entity's Email Address (optional)"
                  />
                  {!!errors.companyEmailAddress && <Error>{errors.companyEmailAddress}</Error>}
                </Section>
                <Section>
                  <Label>Provider ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter the ID of the provider (optional)"
                    value={fields.providerId}
                    onChange={(event: any) =>
                      updateField({ providerId: event.currentTarget.value })
                    }
                  />

                  {!!errors.providerId && <Error>{errors.providerId}</Error>}
                </Section>
                <Section>
                  <Label>Claim or ID number</Label>
                  <Input
                    type="text"
                    placeholder="Enter the ID of the claim (optional)"
                    value={fields.claimId}
                    onChange={(event: any) => {
                      updateField({ claimId: event.currentTarget.value });
                    }}
                  />
                  {!!errors.claimId && <Error>{errors.claimId}</Error>}
                </Section>
                <Section>
                  <Label>Line Items</Label>
                  {fields.lineItemsList &&
                    fields.lineItemsList.map((lineItem: ThirdPartyInvoiceLineItem.AsObject, i) => (
                      <div key={i}>
                        <NewLineItemForm
                          key={i + "_lineitem"}
                          initial={lineItem}
                          onChange={(event: any) => {
                            updateField({
                              lineItemsList: updateArrayItem(fields.lineItemsList, i, event)
                            });
                          }}
                          onRequestDelete={
                            i === 0
                              ? undefined
                              : () =>
                                  updateField({
                                    lineItemsList: removeArrayItem(fields.lineItemsList, i)
                                  })
                          }
                          dfltCcy={this.props.dfltCcy}
                        />
                        <Error key={i + "_code"}>{errors["lineItemsList[" + i + "].code"]}</Error>
                        <Error key={i + "_description"}>
                          {errors["lineItemsList[" + i + "].description"]}
                        </Error>
                        <Error key={i + "_charge"}>
                          {errors["lineItemsList[" + i + "].charge"]}
                        </Error>
                      </div>
                    ))}

                  <LargeDottedButton
                    type="button"
                    onClick={() =>
                      updateField({
                        lineItemsList: addArrayItem(
                          fields.lineItemsList,
                          emptyTpiLineItem(this.props.dfltCcy)
                        )
                      })
                    }
                  >
                    ADD NEW ITEM
                  </LargeDottedButton>
                  {errors.lineItemsList && <Error>{errors.lineItemsList}</Error>}
                </Section>
                <ButtonContainer>
                  <LoadingButton
                    style={{ minWidth: 150 }}
                    loading={this.indicator.isLoading()}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </ButtonContainer>
              </>
            )}
          </Form>
        )}
      </AccordianContainer>
    );
  }
}
