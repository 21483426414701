import React, { Component } from "react";

// components
import LargeDottedButton from "../../components/LargeDottedButton";
import FormHeader from "../../components/form/FormHeader"
import SectionsContainer from "../../components/form/SectionsContainer";
import FundAccordian from "./FundAccordian";

interface Props {
  user: any;
}

export default class Funds extends Component<Props> {
  state = {
    adding: false
  };
  addCard = () => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <FormHeader title="Funds" subtitle="Please modify your primary payment methods below" />
        <SectionsContainer></SectionsContainer>
        {Object.keys(user.FundProperties).map((k) => (
          <FundAccordian key={k} id={k} client={user} fund={k} />
        ))}
        {!this.state.adding ? null : (
          <FundAccordian
            key="newref"
            id="newref"
            client={user}
            fund={null}
            closeNew={this.closeNew}
          />
        )}
        {this.state.adding ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW FUND</LargeDottedButton>
        )}
      </React.Fragment>
    );
  }
}
