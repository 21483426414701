import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";
import LoadingButton from "./../../../components/LoadingButton";

class LocEditAccount_Fund_AUHICAPS extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="HICAPS/Medipass Details"
          subtitle="Please fill out the relevant HICAPS/Medipass information"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="locedit-hicaps-apikey">API Key</Label>
            <Input
              id="locedit-hicaps-apikey"
              name="APIKey"
              type="text"
              placeholder="Enter your Medipass API key here."
              value={values.APIKey}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.APIKey && errors.APIKey}
            />
            {!!errors.APIKey && touched.APIKey && <Error>{errors.APIKey}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
        <FooterContainer style={{ marginTop: 16 }}>
          <FooterText>
            If you are not an existing HICAPS customer please complete{" "}
            <a
              target="_blank"
              href="https://www.hicaps.com.au/content/dam/hicapsrwd/Forms/Formsfinal/Form%201%20New%20Facility%20Provider%20Details.pdf"
            >
              this form
            </a>{" "}
            and submit to HICAPS (note this is the same form for terminal and HICAPS Go/Medipass
            applications, but providers using Lyfe can sign up to HICAPS Go/Medipass only, they do
            not need a terminal as Lyfe acts as the payment terminal). If you are an existing HICAPS
            customer with a terminal, you can{" "}
            <a target="_blank" href="https://www.hicaps.com.au/forms/hicaps-go-signup">
              sign up online here
            </a>{" "}
            for HICAPS Go/Medipass.{" "}
            <a
              target="_blank"
              href="http://help.medipass.com.au/medipass-for-providers/transactions/private-health-insurance-transactions-without-an-app-beta"
            >
              Follow this link
            </a>{" "}
            for instructions on using HICAPS claiming.
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData, org }) => {
    if (!org.Integrations || !org.Integrations.AUHICAPS) {
      return {
        APIKey: ""
      };
    } else {
      return {
        APIKey: !org.Integrations.AUHICAPS.APIKey ? "" : org.Integrations.AUHICAPS.APIKey
      };
    }
  },
  validationSchema: Yup.object().shape({
    APIKey: Yup.string().notRequired()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      const updInts = props.org.Integrations || {};
      if (values.APIKey === "") {
        updInts.AUHICAPS = null;
      } else {
        updInts.AUHICAPS = {
          APIKey: values.APIKey
        };
      }
      const updOrg = {
        ...props.org,
        Integrations: updInts
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LocEditAccountFundAUHICAPS"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(LocEditAccount_Fund_AUHICAPS);
