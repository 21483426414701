import React, { Component, CSSProperties } from "react";
import * as Yup from "yup";

// importing components
import TextArea from "./../../../../components/form/TextArea";
import AddIcon from "./../../../../components/icons/Add";
import IconButton from "./../../../../components/IconButton";
import Label from "./../../../../components/form/Label";
import Error from "./../../../../components/form/Error";
import { DocumentsInput } from "./../../../../components/form/DocumentsInput";
import LoadingButton from "./../../../../components/LoadingButton";
import Section from "./../../../../components/form/Section";
import { css, jsx } from "@emotion/core";
import { colors, hexToRgba } from "./../../../../util/consts";
import {
  AccordianForm,
  ButtonContainer
} from "./../../../../components/elements/AccordianElements";
import { createForm } from "./../../../../forms/forms";
import { Select } from "./../../../../components/form/Select";

import { observer } from "mobx-react";
import { observable, computed } from "mobx";

import { BookingClient, Booking } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "./../../../../grpc";
import { CreateNoteRequest, Note } from "sdk/dist/notes_pb";
import { Media } from "sdk/dist/media_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ListNoteTemplateRequest, NoteTemplate } from "sdk/dist/note_template_pb";
import { getUserLocationID, getUserID } from "./../../../../util/localStore";
import { CreateNoteTemplate } from "./../NoteTemplate/CreateNoteTemplate";
import Popup from "reactjs-popup";
import Edit from "../../../icons/Edit";

interface Props {
  note?: Note.AsObject;
  client: BookingClient.AsObject;
  clientID: string;
  booking?: Booking.AsObject;
  closeNew: () => void;
  refreshNotes: (bookingId: string, clientId: string) => void;
  saveAndUpdateNote: (isOpen: boolean) => void;
}

const schema = Yup.object<Note.AsObject>({
  id: Yup.string(),
  bookingId: Yup.string(),
  clientId: Yup.string().required(),
  noteMsg: Yup.string().required(),
  status: Yup.mixed(),
  mediasList: Yup.array().of(
    Yup.object<Media.AsObject>({
      createdAt: Yup.mixed(),
      description: Yup.string(),
      downloadUrl: Yup.string(),
      filename: Yup.string(),
      id: Yup.string(),
      mime: Yup.string(),
      status: Yup.string(),
      type: Yup.string(),
      updatedAt: Yup.mixed()
    })
  )
});

@observer
export class CreateNote extends Component<Props> {
  @observable isSubmitting = false;
  @observable editing = false;
  @observable customError = "";
  // @observable templateMap: Map<string, NoteTemplate> = new Map<string, NoteTemplate>();
  @observable templates: Array<NoteTemplate> = new Array<NoteTemplate>();
  @observable selectedTemplate: string = EMPTY;
  @observable isCreateTemplateDialog = false;
  @observable isEditTemplateDialog = false;

  @computed get templateMap(): Map<string, NoteTemplate> {
    return new Map<string, NoteTemplate>(
      this.templates.map((template) => [template.getId(), template] as [string, NoteTemplate])
    );
  }
  @observable templateDialogOpen = false;

  state = {
    editing: false
  };
  componentDidMount() {
    if (this.props.note === undefined) {
      this.setState({ editing: true });
    }
    this.getTemplate();
  }

  async getTemplate() {
    const getTemplate = new ListNoteTemplateRequest();
    const locationID = getUserLocationID();
    getTemplate.setLocId(locationID.LocID);
    const templateReq = rpc.noteTemplate.list(getTemplate, metadata());
    templateReq.then((res) => {
      this.templates = res.getNoteTemplatesList();
    });
  }

  onSubmit = async (note: Note.AsObject) => {
    const noteReq = new CreateNoteRequest();
    if (note.bookingId) {
      noteReq.setBookingId(note.bookingId);
    }
    noteReq.setClientId(note.clientId);
    noteReq.setNoteMsg(note.noteMsg);

    const mediaArray = new Array<Media>();
    if (note.mediasList !== undefined) {
      note.mediasList.map((media: Media.AsObject) => {
        // Create new Media protobuf object
        const tempMedia = new Media();
        tempMedia.setId(media.id);
        const tempCreatedAt = new Timestamp();
        tempCreatedAt.setSeconds(media.createdAt ? media.createdAt.seconds : 0);
        tempCreatedAt.setNanos(media.createdAt ? media.createdAt.nanos : 0);
        tempMedia.setCreatedAt(tempCreatedAt);
        tempMedia.setDescription(media.description);
        tempMedia.setDownloadUrl(media.downloadUrl);
        tempMedia.setFilename(media.filename);
        tempMedia.setMime(media.mime);
        tempMedia.setStatus(media.status);
        tempMedia.setType(media.type);
        const tempUpdatedAt = new Timestamp();
        tempUpdatedAt.setSeconds(media.updatedAt ? media.updatedAt.seconds : 0);
        tempUpdatedAt.setNanos(media.updatedAt ? media.updatedAt.nanos : 0);
        tempMedia.setUpdatedAt(tempUpdatedAt);

        // Add new Media Protobuf obj to mediaArray
        mediaArray.push(tempMedia);
      });
      noteReq.setMediasList(mediaArray);
    }

    if (note.id === "" || note.id === undefined || note.id === null) {
      const noteRes = await rpc.notes.create(noteReq, metadata());
      this.props.closeNew();
    } else {
      const noteUpd = new Note();
      noteUpd.setId(note.id);
      noteUpd.setNoteMsg(note.noteMsg);
      noteUpd.setMediasList(mediaArray);
      const noteRes = await rpc.notes.update(noteUpd, metadata());
      this.props.saveAndUpdateNote(true);
    }
    this.props.refreshNotes(note.bookingId, note.clientId);
  };

  onSaveTemplate() {
    this.getTemplate();
    this.isCreateTemplateDialog = false;
    this.isEditTemplateDialog = false;
    this.selectedTemplate = EMPTY;
  }

  canEdit(note: Note.AsObject): boolean {
    // only the author can edit the note
    const currentUserID = getUserID().UserID;
    return note.writtenBy !== undefined && note.writtenBy.id == currentUserID;
  }

  render() {
    const { note, booking, client, clientID } = this.props;
    const locationID: string = getUserLocationID().LocID;
    const Form = createForm<Note.AsObject>();
    // evaluate the observable here so mobx will re-render the the form component
    // The reason why Mobx cannot react to the observable is probably the section
    // is rendered in the anyomous function
    const templateOptions = this.templates.map((template) => ({
      label: template.getTitle(),
      value: template.getId()
    }));
    // add the default value
    templateOptions.unshift({ label: "Please select a template", value: EMPTY });
    const selectedTemplateId = this.selectedTemplate;
    const editTemplate = this.templateMap.get(this.selectedTemplate);
    return (
      <>
        <Form
          schema={schema}
          onSubmit={this.onSubmit}
          component={AccordianForm}
          initial={note ? note : new Note().toObject()}
        >
          {({ fields, updateField, errors }) => (
            <>
              <Section>
                <Label htmlFor={`addnote-${note}-Note`}>Note</Label>
                {note == undefined && (
                  <div style={{ marginBottom: "16px", display: "flex", alignItems: "center" }}>
                    <Select
                      style={{ width: "300px" }}
                      value={selectedTemplateId}
                      values={templateOptions}
                      onChange={(option) => {
                        this.selectedTemplate = option.value;
                        const templateContent = this.templateMap.get(this.selectedTemplate);
                        fields.noteMsg = templateContent
                          ? templateContent.getContent()
                          : fields.noteMsg;
                        booking
                          ? updateField({
                              bookingId: booking.id,
                              clientId: clientID
                            })
                          : updateField({
                              clientId: clientID
                            });
                      }}
                    ></Select>
                    <IconButton
                      mini
                      css={templateButtonCSS}
                      type="button"
                      onClick={() => (this.isCreateTemplateDialog = true)}
                    >
                      <AddIcon fill={colors.surface.light} />
                    </IconButton>
                    <IconButton
                      css={templateButtonCSS}
                      type="button"
                      disabled={editTemplate === undefined ? true : false}
                      onClick={() => (this.isEditTemplateDialog = true)}
                    >
                      <Edit fill={colors.surface.light} />
                    </IconButton>
                  </div>
                )}

                <Section>
                  <TextArea
                    id={`addnote-${note}-Note`}
                    name="Note"
                    type="textarea"
                    placeholder="Please add a new note"
                    autoFocus={true}
                    value={fields.noteMsg ? fields.noteMsg : note ? note.noteMsg : fields.noteMsg}
                    setFieldValue={(name: string, media: Media.AsObject[]) => {
                      updateField({ mediasList: media });
                    }}
                    setFieldError={(name: string, error: string) => {
                      this.customError = error;
                      updateField({ noteMsg: fields.noteMsg });
                    }}
                    onChange={(event: any) => {
                      booking
                        ? updateField({
                            bookingId: booking.id,
                            clientId: clientID,
                            noteMsg: event.currentTarget.value
                          })
                        : updateField({
                            clientId: clientID,
                            noteMsg: event.currentTarget.value
                          });
                    }}
                    disabled={!this.state.editing}
                  />
                  {!!errors.noteMsg && <Error>{errors.noteMsg}</Error>}
                </Section>
              </Section>
              {(this.state.editing || fields.mediasList) && (
                <Section>
                  <Label htmlFor={`addnote-${note}-Media`}>Documents</Label>
                  <DocumentsInput
                    value={note ? note.mediasList : fields.mediasList}
                    name="Media"
                    setFieldValue={(name: string, media: Media.AsObject[]) => {
                      booking
                        ? updateField({
                            bookingId: booking.id,
                            clientId: clientID,
                            noteMsg: fields.noteMsg,
                            mediasList: media
                          })
                        : updateField({
                            clientId: clientID,
                            noteMsg: fields.noteMsg,
                            mediasList: media
                          });
                    }}
                    setFieldTouched={() => {}}
                    setFieldError={(name: string, error: string) => {
                      this.customError = error;
                      updateField({ noteMsg: fields.noteMsg });
                    }}
                    disabled={!this.state.editing}
                  />
                  {!!this.customError && <Error>{this.customError}</Error>}
                  {!!errors.mediasList && <Error>{errors.mediasList}</Error>}
                  {!!errors.bookingId && <Error>{errors.bookingId}</Error>}
                  {!!errors.clientId && !!!errors.noteMsg && <Error>{errors.clientId}</Error>}
                </Section>
              )}
              <ButtonContainer>
                {this.state.editing ? (
                  <LoadingButton
                    key="save"
                    style={{
                      minWidth: 150
                    }}
                    loading={this.isSubmitting}
                    disabled={!this.state.editing}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    key="edit"
                    style={{
                      minWidth: 150
                    }}
                    loading={false}
                    disabled={note !== undefined && this.canEdit(note) ? this.state.editing : true}
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={() => this.setState({ editing: !this.state.editing })}
                  >
                    Edit
                  </LoadingButton>
                )}
              </ButtonContainer>
            </>
          )}
        </Form>
        <Popup
          open={this.isCreateTemplateDialog}
          closeOnDocumentClick
          onClose={() => {
            this.isCreateTemplateDialog = false;
          }}
          lockScroll={false}
          contentStyle={popUpContentCSS}
          modal
        >
          {(close) => (
            <>
              <a onClick={close} style={popUpCloseButtonCSS}>
                &times;
              </a>
              <CreateNoteTemplate
                locationId={locationID}
                onSaveTemplate={() => {
                  this.onSaveTemplate();
                }}
              />
            </>
          )}
        </Popup>
        {/* editing pop up */}
        {editTemplate !== undefined && (
          <Popup
            open={this.isEditTemplateDialog}
            closeOnDocumentClick
            onClose={() => {
              this.isEditTemplateDialog = false;
            }}
            lockScroll={false}
            contentStyle={popUpContentCSS}
            modal
          >
            {(close) => (
              <>
                <a onClick={close} style={popUpCloseButtonCSS}>
                  &times;
                </a>
                <CreateNoteTemplate
                  locationId={locationID}
                  onSaveTemplate={() => {
                    this.onSaveTemplate();
                  }}
                  templateId={editTemplate.getId()}
                  content={editTemplate.getContent()}
                  title={editTemplate.getTitle()}
                />
              </>
            )}
          </Popup>
        )}
      </>
    );
  }
}

const EMPTY: string = "empty";

const templateButtonCSS = css`
  background-color: ${colors.secondary.main} !important;
  &:hover {
    background-color: ${hexToRgba(colors.secondary.main, 0.6)} !important;
  }
  margin-left: 20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
`;

const popUpCloseButtonCSS: CSSProperties = {
  float: "right",
  cursor: "pointer",
  marginRight: "5px"
};

const popUpContentCSS: CSSProperties = {
  maxHeight: "80%",
  overflowY: "scroll"
};
