// source: insight_metrics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.GetInsightMetricsRequest', null, global);
goog.exportSymbol('proto.proto.Metrics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Metrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Metrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Metrics.displayName = 'proto.proto.Metrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetInsightMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetInsightMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetInsightMetricsRequest.displayName = 'proto.proto.GetInsightMetricsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Metrics.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Metrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Metrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Metrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalAppointment: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdBookings: jspb.Message.getFieldWithDefault(msg, 11, 0),
    processingBookings: jspb.Message.getFieldWithDefault(msg, 12, 0),
    completedBookings: jspb.Message.getFieldWithDefault(msg, 13, 0),
    cancelledBookings: jspb.Message.getFieldWithDefault(msg, 14, 0),
    clientsPerDay: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    newReferrals: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rebookingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    clientVisitationAverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    missedAppointmentRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    cancellationWithoutRescheduleRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    utilisationRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    averageBillingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    providerQualityTracking: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Metrics}
 */
proto.proto.Metrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Metrics;
  return proto.proto.Metrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Metrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Metrics}
 */
proto.proto.Metrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAppointment(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedBookings(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcessingBookings(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedBookings(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCancelledBookings(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClientsPerDay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewReferrals(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRebookingRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClientVisitationAverage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMissedAppointmentRate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCancellationWithoutRescheduleRate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUtilisationRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAverageBillingRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProviderQualityTracking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Metrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Metrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Metrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Metrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalAppointment();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreatedBookings();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getProcessingBookings();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCompletedBookings();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getCancelledBookings();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getClientsPerDay();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getNewReferrals();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRebookingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getClientVisitationAverage();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMissedAppointmentRate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCancellationWithoutRescheduleRate();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getUtilisationRate();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getAverageBillingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getProviderQualityTracking();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
};


/**
 * optional int32 total_appointment = 9;
 * @return {number}
 */
proto.proto.Metrics.prototype.getTotalAppointment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setTotalAppointment = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 created_bookings = 11;
 * @return {number}
 */
proto.proto.Metrics.prototype.getCreatedBookings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setCreatedBookings = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 processing_bookings = 12;
 * @return {number}
 */
proto.proto.Metrics.prototype.getProcessingBookings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setProcessingBookings = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 completed_bookings = 13;
 * @return {number}
 */
proto.proto.Metrics.prototype.getCompletedBookings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setCompletedBookings = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 cancelled_bookings = 14;
 * @return {number}
 */
proto.proto.Metrics.prototype.getCancelledBookings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setCancelledBookings = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional float clients_per_day = 1;
 * @return {number}
 */
proto.proto.Metrics.prototype.getClientsPerDay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setClientsPerDay = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 new_referrals = 2;
 * @return {number}
 */
proto.proto.Metrics.prototype.getNewReferrals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setNewReferrals = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float rebooking_rate = 3;
 * @return {number}
 */
proto.proto.Metrics.prototype.getRebookingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setRebookingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float client_visitation_average = 4;
 * @return {number}
 */
proto.proto.Metrics.prototype.getClientVisitationAverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setClientVisitationAverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float missed_appointment_rate = 5;
 * @return {number}
 */
proto.proto.Metrics.prototype.getMissedAppointmentRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setMissedAppointmentRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float cancellation_without_reschedule_rate = 6;
 * @return {number}
 */
proto.proto.Metrics.prototype.getCancellationWithoutRescheduleRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setCancellationWithoutRescheduleRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float utilisation_rate = 7;
 * @return {number}
 */
proto.proto.Metrics.prototype.getUtilisationRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setUtilisationRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float average_billing_rate = 8;
 * @return {number}
 */
proto.proto.Metrics.prototype.getAverageBillingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setAverageBillingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float provider_quality_tracking = 10;
 * @return {number}
 */
proto.proto.Metrics.prototype.getProviderQualityTracking = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Metrics} returns this
 */
proto.proto.Metrics.prototype.setProviderQualityTracking = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetInsightMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetInsightMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetInsightMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetInsightMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    locationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    serviceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tagId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetInsightMetricsRequest}
 */
proto.proto.GetInsightMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetInsightMetricsRequest;
  return proto.proto.GetInsightMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetInsightMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetInsightMetricsRequest}
 */
proto.proto.GetInsightMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetInsightMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetInsightMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetInsightMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetInsightMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTagId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp from = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.GetInsightMetricsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
*/
proto.proto.GetInsightMetricsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetInsightMetricsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp to = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.GetInsightMetricsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
*/
proto.proto.GetInsightMetricsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetInsightMetricsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string location_id = 3;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string provider_id = 4;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string organisation_id = 6;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string service_id = 7;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tag_id = 8;
 * @return {string}
 */
proto.proto.GetInsightMetricsRequest.prototype.getTagId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetInsightMetricsRequest} returns this
 */
proto.proto.GetInsightMetricsRequest.prototype.setTagId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.proto);
