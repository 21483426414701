
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import Input from "./../components/form/Input";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

import * as ResourceActions from "./../redux/features/resources/thunkactions";
import { parseFormError } from "./../util/form";

import debounce from "lodash.debounce";

import LoadingIcon from "./../components/icons/Loading";
import CheckIcon from "./../components/icons/Check";

const DetailsFont = css`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.09px;
  font-weight: 400;
  width: calc(100% - 15px);
  background-color: transparent;
  border-color: transparent;
  text-overflow: ellipsis;
  margin-left: -16px;
`;

const Container = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IconPosition = css`
  position: absolute;
  right: 0;
  top: 12px;
`;

class FormInputValidation extends Component {
  sucUT = null;

  state = {
    loading: false,
    success: false,
  }

  doUpdate = debounce(async () => {
    const { resource, errors, prop, values, dispatch, setFieldError, finalVal, finalObj } = this.props;
    if (this.state.loading || errors[prop]) { return; }
    this.sucUT&&clearTimeout(this.sucUT);
    this.setState({ loading: true, success: false });
    try {
      let updRes = null;
      if (!finalObj) {
        updRes = {
          ...resource,
          [prop]: !finalVal ? values[prop] : finalVal(values[prop])
        };
      } else {
        updRes = finalObj(resource, values[prop]);
      }

      await dispatch(ResourceActions.action(updRes, "Update", {}));
      this.setState({ loading: false, success: true });
      this.sucUT = setTimeout(()=>this.setState({ success: false }), 2000);
    } catch (err) {
      parseFormError(err, values, setFieldError, this.props);
      this.setState({ loading: false });
    }
  }, 250);

  handleBlur = (e) => {
    this.props.handleBlur(e);
    this.doUpdate();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doUpdate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors, values, prop, toastManager } = this.props;
    if (
      prevProps.errors[prevProps.prop] !== errors[prop] &&
      !prevProps.errors[prevProps.prop] &&
      this.props.errors[this.props.prop]
    ) {
      toastManager.add(`Field is invalid: ${errors[prop]}`, { appearance: "error", autoDismiss: true, autoDismissTimeout: 3000 });
    }
    if (prevProps.values[prevProps.prop] !== values[prop] && this.state.success) {
      this.sucUT&&clearTimeout(this.sucUT);
      setTimeout(()=>this.setState({ success: false }), 17);
    }
  }
  componentWillUnmount() {
    this.sucUT&&clearTimeout(this.sucUT);
  }
  render() {
    const {
      prop,
      values,
      touched,
      errors,
      handleChange,
      inputProps
    } = this.props;
    return (
      <div css={Container}>
        <Input
          css={DetailsFont}
          {...inputProps}
          name={prop}
          value={values[prop]}
          onChange={handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          error={errors[prop] && touched[prop]}
        />
        {this.state.loading && <LoadingIcon css={IconPosition} color="#2c2e3c" size="small" />}
        {this.state.success && <CheckIcon css={IconPosition} color="#008800" size="small" />}
      </div>
    );
  }
}

const formikEnhancer = withFormik({
  validationSchema: (props) => Yup.object().shape({
    [props.prop]: props.validator
  }),
  mapPropsToValues: (props) => ({
    [props.prop]: !props.initialVal ? props.resource[props.prop] : props.initialVal(props.resource[props.prop])
  }),
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting }) => {}
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(FormInputValidation);
