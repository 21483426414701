import React from "react";

interface IProps {
  size?: "small" | "medium" | "large";
}

export const LockIcon = (props: IProps) => {
  return (
    <svg
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={renderIconSize(props.size)}
    >
      <circle cx="5.5" cy="5.5" r="5.5" fill="#4EAEB4" />
      <path
        d="M3.76485 3.74932V4.3465H3.72116C3.46525 4.3465 3.25928 4.54759 3.25928 4.79743V7.28976C3.25928 7.5396 3.46525 7.74069 3.72116 7.74069H7.27888C7.53479 7.74069 7.74076 7.5396 7.74076 7.28976V4.79743C7.74076 4.54759 7.53479 4.3465 7.27888 4.3465H7.2664V3.74932C7.2664 2.80479 6.47995 2.03699 5.5125 2.03699C4.55129 2.03699 3.76485 2.80479 3.76485 3.74932ZM5.78089 6.15024C5.74344 6.17461 5.7372 6.19898 5.7372 6.24164C5.7372 6.42445 5.7372 6.60726 5.7372 6.79007C5.74344 6.8632 5.69975 6.94242 5.63109 6.97288C5.46881 7.0521 5.30653 6.94242 5.30653 6.79007C5.30653 6.60726 5.30653 6.42445 5.30653 6.24164C5.30653 6.20508 5.30029 6.1807 5.26284 6.15633C5.09431 6.03446 5.03814 5.82727 5.11928 5.64446C5.20042 5.46774 5.40015 5.36415 5.59364 5.40071C5.80586 5.44337 5.94941 5.6079 5.95566 5.81508C5.95566 5.95524 5.89948 6.07102 5.78089 6.15024ZM4.53257 3.74932C4.53257 3.21917 4.97572 2.78651 5.51874 2.78651C6.06176 2.78651 6.50492 3.21917 6.50492 3.74932V4.3465H4.53257V3.74932Z"
        fill="white"
      />
    </svg>
  );
};

const renderIconSize = (size: IProps["size"]) => {
  switch (size) {
    case "small":
      return "w-4 h-4";
    case "medium":
    default:
      return "w-8 h-8";
    case "large":
      return "w-10 h-10";
  }
};
