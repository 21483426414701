import React from "react";
import styled from "styled-components";
import typography from "../../../util/typography";
import Pill from "./../../../components/Pill";
import { UserBlock } from "./../../../components/elements/UserBlock";
import { css } from "@emotion/core";
import { colors } from "./../../../util/consts";
import "./accDataTable.css";
import { AccTableRowStatus } from "sdk/dist/accounts_table_pb";
import { statusAccRowToText } from "../../../util/account-items";

/**
 * Render functions
 **/

// This returns the column width according to the accessor name from react-table
export function WidthPerColumn(columnAccessor: string): string {
  switch (columnAccessor) {
    case "bookingDate":
      return "230";
    case "friendlyId":
      return "180";
    case "status":
      return "170";
    case "fundingType":
      return "180";
    case "provider":
      return "180";
    case "client":
      return "180";
    case "locationName":
      return "180";
    default:
      return "120";
  }
}

export function RenderPill(status: AccTableRowStatus) {
  let color;
  switch (status) {
    case AccTableRowStatus.ACC_ROW_CLAIM_ERROR:
    case AccTableRowStatus.ACC_ROW_UNPAID_TPI:
    case AccTableRowStatus.ACC_ROW_PAYMENT_ERROR:
      color = "#e32418";
      break;
    case AccTableRowStatus.ACC_ROW_UNPROCESSED:
      color = "#d3d3d3";
      break;
    case AccTableRowStatus.ACC_ROW_PROCESSING:
      color = "#04b1b6";
      break;
    case AccTableRowStatus.ACC_ROW_CREATED:
      color = "#00ccff";
      break;
    case AccTableRowStatus.ACC_ROW_COMPLETED:
    case AccTableRowStatus.ACC_ROW_PAID_TPI:
      color = "#30bf0a";
      break;
    case AccTableRowStatus.ACC_ROW_CANCELLED:
      color = "#f09800";
      break;
    case AccTableRowStatus.ACC_ROW_UNKNOWN:
      color = "#cd2393";
      break;
    default:
      color = "secondary";
      break;
  }

  return (
    <Pill variant="contained" color={color}>
      {statusAccRowToText(status)}
    </Pill>
  );
}
/**
 * End Render functions
 **/

/**
 * CSS table
 **/

export const OneRow = styled.tr`
  column-span: all;
  top: 192px;
  background-color: #f4f8f9;
  margin: 0;
  padding: 13px 0;
  border: none;
  outline: none;
  height: 64px;
  vertical-align: middle;
  text-align: center;
`;

export const SpinnerContainer = styled.div`
  text-align: center;
  padding-top: 25%;
`;

export const OverrideSpinner = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const StyleUserBlock = styled(UserBlock)`
  padding: 0 20px;
  height: 100%;
`;

export const DateTimeContainer = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: start;
`;

export const DateContent = styled.div`
  font-size: 13.82px;
  letter-spacing: 0.09px;
  line-height: 19px;
  color: #2c2e3c;
  margin: 0;
`;

export const TimeContent = styled.div`
  ${typography.tableSub};
  opacity: 0.6;
`;

export const Label = styled.div`
  color: #2c2e3c;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.09px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableContainer = styled.div`
  padding: 0 36px;
  ${({ colRows }) => !colRows && "min-height: calc(100vh - 192px)"};
  border: none;
  outline: none;
  margin: 0 auto;
  background-color: #f4f8f9;
  overflow: scroll;
`;

export const TableStyle = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
  padding: 0;
  width: ${({ tableSize }) => tableSize + ""};
  border: none;
  outline: none;
  background-color: #f4f8f9;
  th {
    top: 0px;
  }
`;

export const HeaderRow = styled.tr`
  margin: 0;
  padding: 13px 0;
  border: none;
  outline: none;
  height: 64px;
  vertical-align: middle;
`;

export const RowContainer = styled.tr`
  margin: 0;
  padding: 13px 0;
  border: none;
  outline: none;
  height: 64px;
  vertical-align: middle;
`;

export const HeaderColumn = styled.th`
  width: ${({ twd }) => twd}px;
  ${({ twMin }) => twMin && `max-width: ${twMin}px;`}
  ${({ twMax }) => twMax && `max-width: ${twMax}px;`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`}
  position: sticky;
  top: 192px;
  background-color: #f4f8f9;
`;

export const Row = styled.tr`
  height: 64px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.05);
  text-align: left;
  margin: 5px 0;
`;

export const Column = styled.td`
  word-break: break-all;
  transition: padding 0.25s ease;
  padding: 0 14px;
  vertical-align: middle;

  ${({ select }) =>
    select &&
    `
    cursor: pointer;
    user-select: none;
    &:hover{
      background-color: rgba(44,46,60,0.05);
      border-radius: 4px;
    }
  `};
`;

export const HeaderDropdownColumn = styled.th`
  position: sticky;
  top: 192px;
  width: 64px;
  z-index: 3;
  background-color: #f4f8f9;
`;

export const CardContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 50px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  cursor: pointer;
  color: #2c2e3c;
  font-size: 11.82px;
  font-weight: 600;
  letter-spacing: 0.09px;
  cursor: ${({ noOrder }) => (!noOrder ? "pointer" : "auto")};
  user-select: none;
  padding: 0 13px;
`;

export const BookingDateContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SeparatorTex = styled.p`
  margin: 3px;
`;

export const Select = styled.select`
  letter-spacing: 0.15px;
  text-align: left;
  padding: 0;
  height: 23px;
  width: ${({ width }) => width + ""};
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;

  &:disabled {
    background-color: white;
    border: none;
    padding: 0;
    height: 25px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0.6;
    font-size: 12.82px;
  }

  &:hover {
    background-color: white;
  }
  &:focus {
    border-color: ${colors.primary.main};
    /* padding: 10px 16px; */
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;
/**
 * EndCSS table
 **/
