import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { FlexBox, FlexItem } from "../FlexBox";
import CancelIcon from "../icons/CancelIcon";
import { Typography } from "../Typography";
import { ErrorMessage } from "./ErrorMessage";

interface IProps {
  label: string;
  subLabel?: string;
  startAdornment?: React.ReactNode;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  error?: string;
}

export const TimePicker = (props: IProps) => {
  return (
    <Container className="mb-4 sm:mb-6">
      <FlexBox spacing={[0, 1]} direction="col">
        <FlexItem>
          <div>
            <Typography.Body color="primary" noPadding>
              {props.label}
            </Typography.Body>
            {props.subLabel && (
              <Typography.Label color="secondary" noPadding>
                {props.subLabel}
              </Typography.Label>
            )}
          </div>
        </FlexItem>
        <FlexItem>
          <div className="relative rounded-md shadow-sm">
            {props.startAdornment && (
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {props.startAdornment}
              </div>
            )}
            <input
              type="time"
              step={1}
              disabled={props.disabled}
              name={props.label}
              id={props.label.toLowerCase()}
              value={props.value || undefined}
              onChange={(v) => props.onChange && props.onChange(v.target.value)}
              className={classNames(
                "focus:ring-primary-500 focus:border-primary-900 block w-full sm:text-sm border-secondary-300 rounded-md h-10 sm:h-12 md:h-14",
                props.disabled && "disabled:opacity-50 cursor-not-allowed",
                props.startAdornment && "pl-10",
                props.value ? "pr-10" : ""
              )}
              placeholder={props.placeholder}
            />
            {props.value ? (
              <div className="absolute inset-y-0 right-0 flex items-center px-2">
                <CancelIcon
                  onClick={() => props.onChange && props.onChange("")}
                  variant="secondary"
                  size="medium"
                />
              </div>
            ) : undefined}
          </div>
        </FlexItem>
        {props.error && (
          <FlexItem>
            <ErrorMessage messages={[props.error]} />
          </FlexItem>
        )}
      </FlexBox>
    </Container>
  );
};

const Container = styled.div`
  input[type="search"]::-webkit-search-cancel-button {
    height: 10px;
    width: 10px;
    display: inline-block;
    background: blue;
  }
`;
