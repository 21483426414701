import React from "react";
import { TabContainer, TabHeader } from "../elements/Tabs";
import { Label } from "../elements/Label";
import { ThirdPartyRow } from "./ThirdPartyRow";
import { observable, toJS, computed } from "mobx";
import {
  ThirdPartyInvoice,
  DeleteThirdPartyInvoiceRequest,
  SetPaidInvoiceRequest,
  ThirdPartyInvoiceLineItem,
  ThirdPartyInvoiceDetails
} from "sdk/dist/third_party_invoices_pb";
import { observer } from "mobx-react";
import LargeDottedButton from "../LargeDottedButton";
import { NewThirdPartyForm } from "./NewThirdPartyForm";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "./../../grpc";
import { accItemStore } from "./../../stores/acc_item-store";
import { thirdPartyInvStore } from "./../../stores/third_party_inv-store";
import { chargeToProto } from "./../../util/money";
import { FundType } from "sdk/dist/offerings_pb";
import { tpiTemplateStore } from "../../stores/tpi_template-store";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";
import { toastStore } from "../../stores/toast-store";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  required?: boolean;
}

@observer
export class ThirdPartyInvoices extends React.Component<Props> {
  @observable
  private adding = false;

  @observable
  private dfltCcy = ISOCurrencyName.XXX;

  async componentWillMount() {
    try {
      const res = await rpc.currencies.getAvailableCcyByServerInstanceLoc(new Empty(), metadata());
      this.dfltCcy = res.toObject().currenciesList[0].ccyIsoName;
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async componentDidMount() {
    const { booking, client } = this.props;
    await thirdPartyInvStore.load(booking.id, client!.clientId);
    if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE) {
      this.adding = true;
    }
    await tpiTemplateStore.loadByClientId(this.props.client.clientId);
  }

  @computed
  get thirdPartyTemplateByClient() {
    return tpiTemplateStore.getByClientId(this.props.client.clientId);
  }

  private async removeInvoice(invoice: ThirdPartyInvoice.AsObject) {
    const req = new DeleteThirdPartyInvoiceRequest();
    req.setThirdPartyInvoiceId(invoice.id);
    await rpc.thirdPartyInvoices.delete(req, metadata());
    await accItemStore.delete(invoice.accountItem!);
    await thirdPartyInvStore.delete(invoice);
  }

  private async setPaid(invoice: ThirdPartyInvoice.AsObject, isPaid: boolean) {
    const req = new SetPaidInvoiceRequest();
    const detailsMsg = new ThirdPartyInvoiceDetails();
    detailsMsg.setClaimId(invoice.details!.claimId);
    detailsMsg.setCompanyAbn(invoice.details!.companyAbn.replace(/\s+/g, ""));
    detailsMsg.setCompanyEmailAddress(invoice.details!.companyEmailAddress);
    detailsMsg.setCompanyName(invoice.details!.companyName);
    detailsMsg.setProviderId(invoice.details!.providerId);
    detailsMsg.setLineItemsList(
      invoice.details!.lineItemsList.map((item) => {
        const itemMsg = new ThirdPartyInvoiceLineItem();
        itemMsg.setCode(item.code);
        itemMsg.setDescription(item.description);
        itemMsg.setCharge(chargeToProto(item.charge!));
        itemMsg.setTaxType(item.taxType);
        return itemMsg;
      })
    );
    req.setDetails(detailsMsg);
    req.setThirdPartyInvoiceId(invoice.id);
    req.setIsPaid(isPaid);
    const res = await rpc.thirdPartyInvoices.setPaid(req, metadata());
    thirdPartyInvStore.add(res.toObject());
  }

  render() {
    const { booking, client } = this.props;
    const template = this.thirdPartyTemplateByClient[0];
    return (
      <TabContainer>
        <TabHeader>
          <Label required={this.props.required}>Third Party Invoices</Label>
        </TabHeader>

        {thirdPartyInvStore
          .all()
          .filter(
            (invoice: ThirdPartyInvoice.AsObject) => invoice.accountItem!.booking!.id === booking.id
          )
          .map((invoice) => (
            <ThirdPartyRow
              key={invoice.id}
              booking={booking}
              client={client}
              invoice={invoice}
              onRemove={() => this.removeInvoice(invoice)}
              setPaid={(isPaid: boolean) => this.setPaid(invoice, isPaid)}
              dfltCcy={this.dfltCcy}
            />
          ))}

        {this.adding && (
          <NewThirdPartyForm
            template={template}
            booking={booking}
            client={client}
            onRequestClose={() => (this.adding = false)}
            dfltCcy={this.dfltCcy}
          />
        )}

        {!this.adding && this.props.required && (
          <LargeDottedButton onClick={() => (this.adding = true)}>
            ADD NEW THIRD PARTY INVOICE
          </LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
