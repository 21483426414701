import React from "react";
import ResourceRender from "./../../components/elements/ResourceRender";

// const CategoryName = React.memo(({ catID })=>
//       <ResourceRender
//         type="Taxonomy"
//         ids={{ "ID": auth.catID }}
//         compSuccess={({ resource })=>resource.Value}
//         forwardedProps={props}
//       />
//     );
export default React.memo(({ auth, catID, props }) => (
  <ResourceRender
    type="Taxonomy"
    ids={{ ID: auth.catID }}
    compSuccess={({ resource }) => resource.Value}
    forwardedProps={props}
  />
));
