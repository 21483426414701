import React from 'react';
import { observable, action, isObservable } from 'mobx';
import { string } from 'yup';

interface Entity {
  id: string;
}

export class EntityStore<T extends Entity> {

  @observable
  private entities = new Map<string, T>();

  @action
  add(...entities: T[]) {
    entities.forEach(entity => { if (entity.id) { this.entities.set(entity.id, entity) } });
  }

  @action
  delete(...entities: T[]) {
    entities.forEach(entity => { if (entity.id) { this.entities.delete(entity.id) } });
  }

  @action
  deleteAll() {
    this.entities.clear();
  }

  get(id: string): T | undefined {
    return this.entities.get(id);
  }

  all(): T[] {
    return Array.from(this.entities.values());
  }

}
