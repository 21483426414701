// source: third_party_invoice_templates.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var third_party_invoices_pb = require('./third_party_invoices_pb.js');
goog.object.extend(proto, third_party_invoices_pb);
goog.exportSymbol('proto.proto.CreateThirdPartyInvoiceTemplateRequest', null, global);
goog.exportSymbol('proto.proto.DeleteThirdPartyInvoiceTemplateRequst', null, global);
goog.exportSymbol('proto.proto.ListThirdPartyInvoiceTemplateRequest', null, global);
goog.exportSymbol('proto.proto.ListThirdPartyInvoiceTemplateResponse', null, global);
goog.exportSymbol('proto.proto.NewThirdPartyInvoiceTemplateLineItem', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoiceTemplate', null, global);
goog.exportSymbol('proto.proto.ThirdPartyInvoiceTemplateLineItem', null, global);
goog.exportSymbol('proto.proto.UpdateThirdPartyInvoiceTemplateRequest', null, global);
goog.exportSymbol('proto.proto.UpdatedTemplate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ThirdPartyInvoiceTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ThirdPartyInvoiceTemplate.repeatedFields_, null);
};
goog.inherits(proto.proto.ThirdPartyInvoiceTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ThirdPartyInvoiceTemplate.displayName = 'proto.proto.ThirdPartyInvoiceTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ThirdPartyInvoiceTemplateLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ThirdPartyInvoiceTemplateLineItem.displayName = 'proto.proto.ThirdPartyInvoiceTemplateLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.NewThirdPartyInvoiceTemplateLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.NewThirdPartyInvoiceTemplateLineItem.displayName = 'proto.proto.NewThirdPartyInvoiceTemplateLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListThirdPartyInvoiceTemplateResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListThirdPartyInvoiceTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListThirdPartyInvoiceTemplateResponse.displayName = 'proto.proto.ListThirdPartyInvoiceTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListThirdPartyInvoiceTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListThirdPartyInvoiceTemplateRequest.displayName = 'proto.proto.ListThirdPartyInvoiceTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CreateThirdPartyInvoiceTemplateRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.CreateThirdPartyInvoiceTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateThirdPartyInvoiceTemplateRequest.displayName = 'proto.proto.CreateThirdPartyInvoiceTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdatedTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdatedTemplate.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdatedTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdatedTemplate.displayName = 'proto.proto.UpdatedTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateThirdPartyInvoiceTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateThirdPartyInvoiceTemplateRequest.displayName = 'proto.proto.UpdateThirdPartyInvoiceTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteThirdPartyInvoiceTemplateRequst, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteThirdPartyInvoiceTemplateRequst.displayName = 'proto.proto.DeleteThirdPartyInvoiceTemplateRequst';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ThirdPartyInvoiceTemplate.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ThirdPartyInvoiceTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ThirdPartyInvoiceTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityAbn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityEmailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    claimNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ThirdPartyInvoiceTemplateLineItem.toObject, includeInstance),
    clientId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    id: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ThirdPartyInvoiceTemplate}
 */
proto.proto.ThirdPartyInvoiceTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ThirdPartyInvoiceTemplate;
  return proto.proto.ThirdPartyInvoiceTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ThirdPartyInvoiceTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ThirdPartyInvoiceTemplate}
 */
proto.proto.ThirdPartyInvoiceTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityAbn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityEmailAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClaimNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = new proto.proto.ThirdPartyInvoiceTemplateLineItem;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ThirdPartyInvoiceTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ThirdPartyInvoiceTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityAbn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClaimNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.proto.ThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string entity_name = 1;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getEntityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setEntityName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_abn = 2;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getEntityAbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setEntityAbn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_email_address = 3;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getEntityEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setEntityEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string provider_id = 4;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string claim_number = 5;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getClaimNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setClaimNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_by = 6;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
*/
proto.proto.ThirdPartyInvoiceTemplate.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
*/
proto.proto.ThirdPartyInvoiceTemplate.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ThirdPartyInvoiceTemplateLineItem items = 9;
 * @return {!Array<!proto.proto.ThirdPartyInvoiceTemplateLineItem>}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ThirdPartyInvoiceTemplateLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ThirdPartyInvoiceTemplateLineItem, 9));
};


/**
 * @param {!Array<!proto.proto.ThirdPartyInvoiceTemplateLineItem>} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
*/
proto.proto.ThirdPartyInvoiceTemplate.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.proto.ThirdPartyInvoiceTemplateLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.proto.ThirdPartyInvoiceTemplateLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string client_id = 10;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string id = 11;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplate} returns this
 */
proto.proto.ThirdPartyInvoiceTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ThirdPartyInvoiceTemplateLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ThirdPartyInvoiceTemplateLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fee: (f = msg.getFee()) && money_pb.Money.toObject(includeInstance, f),
    taxType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ThirdPartyInvoiceTemplateLineItem;
  return proto.proto.ThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ThirdPartyInvoiceTemplateLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ThirdPartyInvoiceTemplateLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money fee = 3;
 * @return {?proto.proto.Money}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getFee = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 3));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
*/
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.hasFee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaxType tax_type = 4;
 * @return {!proto.proto.TaxType}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getTaxType = function() {
  return /** @type {!proto.proto.TaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.TaxType} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string template_id = 6;
 * @return {string}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
*/
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
*/
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ThirdPartyInvoiceTemplateLineItem.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.NewThirdPartyInvoiceTemplateLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fee: (f = msg.getFee()) && money_pb.Money.toObject(includeInstance, f),
    taxType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.NewThirdPartyInvoiceTemplateLineItem;
  return proto.proto.NewThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.TaxType} */ (reader.readEnum());
      msg.setTaxType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.NewThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getTaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money fee = 3;
 * @return {?proto.proto.Money}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.getFee = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 3));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} returns this
*/
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.hasFee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TaxType tax_type = 4;
 * @return {!proto.proto.TaxType}
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.getTaxType = function() {
  return /** @type {!proto.proto.TaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.TaxType} value
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem} returns this
 */
proto.proto.NewThirdPartyInvoiceTemplateLineItem.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListThirdPartyInvoiceTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.proto.ThirdPartyInvoiceTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateResponse}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListThirdPartyInvoiceTemplateResponse;
  return proto.proto.ListThirdPartyInvoiceTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateResponse}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.proto.ThirdPartyInvoiceTemplate;
      reader.readMessage(value,proto.proto.ThirdPartyInvoiceTemplate.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListThirdPartyInvoiceTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.ThirdPartyInvoiceTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ThirdPartyInvoiceTemplate templates = 2;
 * @return {!Array<!proto.proto.ThirdPartyInvoiceTemplate>}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.proto.ThirdPartyInvoiceTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ThirdPartyInvoiceTemplate, 2));
};


/**
 * @param {!Array<!proto.proto.ThirdPartyInvoiceTemplate>} value
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateResponse} returns this
*/
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.ThirdPartyInvoiceTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ThirdPartyInvoiceTemplate}
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.ThirdPartyInvoiceTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateResponse} returns this
 */
proto.proto.ListThirdPartyInvoiceTemplateResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListThirdPartyInvoiceTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateRequest}
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListThirdPartyInvoiceTemplateRequest;
  return proto.proto.ListThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateRequest}
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListThirdPartyInvoiceTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.ListThirdPartyInvoiceTemplateRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateThirdPartyInvoiceTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityAbn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityEmailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    claimNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.NewThirdPartyInvoiceTemplateLineItem.toObject, includeInstance),
    clientId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateThirdPartyInvoiceTemplateRequest;
  return proto.proto.CreateThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityAbn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityEmailAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClaimNumber(value);
      break;
    case 9:
      var value = new proto.proto.NewThirdPartyInvoiceTemplateLineItem;
      reader.readMessage(value,proto.proto.NewThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityAbn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClaimNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.proto.NewThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string entity_name = 1;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getEntityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setEntityName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_abn = 2;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getEntityAbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setEntityAbn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_email_address = 3;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getEntityEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setEntityEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string provider_id = 4;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string claim_number = 5;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getClaimNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setClaimNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated NewThirdPartyInvoiceTemplateLineItem items = 9;
 * @return {!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.NewThirdPartyInvoiceTemplateLineItem, 9));
};


/**
 * @param {!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
*/
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.proto.NewThirdPartyInvoiceTemplateLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.proto.NewThirdPartyInvoiceTemplateLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string client_id = 10;
 * @return {string}
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.CreateThirdPartyInvoiceTemplateRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdatedTemplate.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdatedTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdatedTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdatedTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdatedTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityAbn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityEmailAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    claimNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.NewThirdPartyInvoiceTemplateLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdatedTemplate}
 */
proto.proto.UpdatedTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdatedTemplate;
  return proto.proto.UpdatedTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdatedTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdatedTemplate}
 */
proto.proto.UpdatedTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityAbn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityEmailAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClaimNumber(value);
      break;
    case 9:
      var value = new proto.proto.NewThirdPartyInvoiceTemplateLineItem;
      reader.readMessage(value,proto.proto.NewThirdPartyInvoiceTemplateLineItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdatedTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdatedTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdatedTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdatedTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityAbn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClaimNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.proto.NewThirdPartyInvoiceTemplateLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string entity_name = 1;
 * @return {string}
 */
proto.proto.UpdatedTemplate.prototype.getEntityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.setEntityName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_abn = 2;
 * @return {string}
 */
proto.proto.UpdatedTemplate.prototype.getEntityAbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.setEntityAbn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_email_address = 3;
 * @return {string}
 */
proto.proto.UpdatedTemplate.prototype.getEntityEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.setEntityEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string provider_id = 4;
 * @return {string}
 */
proto.proto.UpdatedTemplate.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string claim_number = 5;
 * @return {string}
 */
proto.proto.UpdatedTemplate.prototype.getClaimNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.setClaimNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated NewThirdPartyInvoiceTemplateLineItem items = 9;
 * @return {!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>}
 */
proto.proto.UpdatedTemplate.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.NewThirdPartyInvoiceTemplateLineItem, 9));
};


/**
 * @param {!Array<!proto.proto.NewThirdPartyInvoiceTemplateLineItem>} value
 * @return {!proto.proto.UpdatedTemplate} returns this
*/
proto.proto.UpdatedTemplate.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.proto.NewThirdPartyInvoiceTemplateLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.NewThirdPartyInvoiceTemplateLineItem}
 */
proto.proto.UpdatedTemplate.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.proto.NewThirdPartyInvoiceTemplateLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdatedTemplate} returns this
 */
proto.proto.UpdatedTemplate.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateThirdPartyInvoiceTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updatedtemplate: (f = msg.getUpdatedtemplate()) && proto.proto.UpdatedTemplate.toObject(includeInstance, f),
    mask: (f = msg.getMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateThirdPartyInvoiceTemplateRequest;
  return proto.proto.UpdateThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = new proto.proto.UpdatedTemplate;
      reader.readMessage(value,proto.proto.UpdatedTemplate.deserializeBinaryFromReader);
      msg.setUpdatedtemplate(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdatedtemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.UpdatedTemplate.serializeBinaryToWriter
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UpdatedTemplate updatedTemplate = 3;
 * @return {?proto.proto.UpdatedTemplate}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.getUpdatedtemplate = function() {
  return /** @type{?proto.proto.UpdatedTemplate} */ (
    jspb.Message.getWrapperField(this, proto.proto.UpdatedTemplate, 3));
};


/**
 * @param {?proto.proto.UpdatedTemplate|undefined} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} returns this
*/
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.setUpdatedtemplate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.clearUpdatedtemplate = function() {
  return this.setUpdatedtemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.hasUpdatedtemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.FieldMask mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.getMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} returns this
*/
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateThirdPartyInvoiceTemplateRequest} returns this
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateThirdPartyInvoiceTemplateRequest.prototype.hasMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteThirdPartyInvoiceTemplateRequst.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst}
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteThirdPartyInvoiceTemplateRequst;
  return proto.proto.DeleteThirdPartyInvoiceTemplateRequst.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst}
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteThirdPartyInvoiceTemplateRequst.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DeleteThirdPartyInvoiceTemplateRequst} returns this
 */
proto.proto.DeleteThirdPartyInvoiceTemplateRequst.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
