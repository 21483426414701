import React from "react";
import { useHistory } from "react-router";
import { SubTiers } from "../../pages/list-your-business/SubTiers";
import SubscriptionTier from "../../pages/list-your-business/SubscriptionTier";
import { Container } from "../../ui-kit/Container";
import { Stack } from "../../ui-kit/Stack";
import { Tabs } from "../../ui-kit/Tabs";
import { ChevronLeftIcon } from "../../ui-kit/icons/ChevronLeftIcon";

const LyfeSubscriptionInfo = () => {
  const history = useHistory();

  return (
    <Container centerContainer position={"relative"}>
      <Container noPadding position={"relative"} mobileVisibleOnly>
        <div
          style={{ position: "absolute", top: ".625rem", left: "1rem" }}
          onClick={() => history.goBack()}
        >
          <ChevronLeftIcon color={"white"} />
        </div>
        <Tabs
          tabs={[
            {
              label: "Starter",
              detail: (
                <SubscriptionTier.Tab
                  label="Starter"
                  type="starter"
                  variant="tertiary"
                  cost={SubTiers.free.cost}
                  featureAvailable={SubTiers.free.availableFeatures}
                  featureNotAvailable={SubTiers.free.notAvailableFeatures}
                />
              ),
              color: "orange"
            },
            {
              label: "Pro",
              detail: (
                <SubscriptionTier.Tab
                  label="Pro"
                  type="basic"
                  variant="blue"
                  cost={SubTiers.basic.cost}
                  featureAvailable={SubTiers.basic.availableFeatures}
                  featureNotAvailable={SubTiers.basic.notAvailableFeatures}
                />
              ),
              color: "blue"
            },
            {
              label: "Premium",
              detail: (
                <SubscriptionTier.Tab
                  label="Premium"
                  type="pro"
                  variant="primary"
                  cost={SubTiers.pro.cost}
                  featureAvailable={SubTiers.pro.availableFeatures}
                  featureNotAvailable={SubTiers.pro.notAvailableFeatures}
                />
              ),
              color: "primary"
            }
          ]}
        />
      </Container>

      <Container width="full" type="block" maxWidth="7xl" centerContainer desktopVisibleOnly>
        <div
          style={{ position: "absolute", top: ".625rem", left: "1rem" }}
          onClick={() => history.goBack()}
        >
          <ChevronLeftIcon />
        </div>
        <Stack direction="col" gap={[10, 10]} span={1} lg={{ span: 3, direction: "col" }}>
          <Stack.Item direction="row" span={1}>
            <SubscriptionTier.Card
              label="Starter"
              type="starter"
              variant="tertiary"
              cost={SubTiers.free.cost}
              featureAvailable={SubTiers.free.availableFeatures}
              featureNotAvailable={SubTiers.free.notAvailableFeatures}
            />
          </Stack.Item>
          <Stack.Item direction="row" span={1}>
            <SubscriptionTier.Card
              label="Pro"
              type="basic"
              variant="blue"
              cost={SubTiers.basic.cost}
              featureAvailable={SubTiers.basic.availableFeatures}
              featureNotAvailable={SubTiers.basic.notAvailableFeatures}
            />
          </Stack.Item>
          <Stack.Item direction="row" span={1}>
            <SubscriptionTier.Card
              label="Premium"
              type="pro"
              variant="primary"
              cost={SubTiers.pro.cost}
              featureAvailable={SubTiers.pro.availableFeatures}
              featureNotAvailable={SubTiers.pro.notAvailableFeatures}
            />
          </Stack.Item>
        </Stack>
      </Container>
    </Container>
  );
};

export default LyfeSubscriptionInfo;
