import React, { Component } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";

// Utils
import { colors } from "./../util/consts";
import typography from "./../util/typography";

// Custom components
import ButtonBase from "./../components/ButtonBase";

interface ITab {
  label: string;
  content: React.ComponentType;
  props: any;
  icon?: any;
}

interface Props {
  tabs: ITab[];
  initialTab?: string;
}

interface State {
  activeTab?: string; // the tab's label
}

// @deprecated
export default class Accordian extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      activeTab: props.initialTab || Accordian.activeTab(this.props)
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.tabs.findIndex((v) => v.label === state.activeTab) < 0) {
      return { activeTab: Accordian.activeTab(props) };
    }
    return null;
  }

  static activeTab(props: Props) {
    if (props.tabs.length > 0) {
      return props.tabs[0].label;
    }
    return undefined;
  }

  render() {
    const { activeTab } = this.state;
    const { tabs } = this.props;
    if (tabs.length === 0) {
      return null;
    }
    return (
      <Container>
        <List>
          {tabs.map((t) => (
            <ListItem
              key={t.label}
              css={css`
                flex: 0 1 calc(100% / ${tabs.length});
                border: 1px solid #f3f5fd;
              `}
            >
              <ListItemButton
                isActive={activeTab === t.label}
                onClick={() => this.setState({ activeTab: t.label })}
              >
                <ListItemText isActive={activeTab === t.label} padding={!!t.icon}>
                  {t.label}
                </ListItemText>
                {t.icon &&
                  React.cloneElement(t.icon, {
                    color:
                      activeTab === t.label
                        ? colors.whiteText.highEmphasis
                        : colors.surfaceText.highEmphasis
                  })}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Content>
          {tabs.map((t) =>
            activeTab === t.label ? React.createElement(t.content, t.props || {}) : null
          )}
        </Content>
      </Container>
    );
  }
}

interface TabProps {
  label: string;
  render: () => React.ReactNode;
}

export class Tab extends React.Component<TabProps> {}

interface TabsProps {
  children: React.ReactElement<TabProps> | Array<React.ReactElement<TabProps> | undefined>;
}

export class Tabs extends React.Component<TabsProps, State> {
  state: State = {
    activeTab: undefined as string | undefined
  };

  static getDerivedStateFromProps(props: TabsProps, state: State) {
    if (Tabs.getTabs(props).findIndex((v) => v.label === state.activeTab) < 0) {
      return { activeTab: Tabs.activeTab(props) };
    }
    return null;
  }

  static activeTab(props: TabsProps) {
    const tabs = Tabs.getTabs(props);
    if (tabs.length > 0) {
      return tabs[0].label;
    }
    return undefined;
  }

  static getTabs(props: TabsProps): TabProps[] {
    return React.Children.toArray(props.children)
      .filter((child) => !!child)
      .map((child) => (child as any).props);
  }

  render() {
    const tabs = Tabs.getTabs(this.props);
    const activeTabLabel = this.state.activeTab;
    const activeTab = tabs.find((tab) => tab.label === activeTabLabel);
    return (
      <Container>
        <List>
          {tabs.map((tab) => (
            <ListItem
              key={tab.label}
              css={css`
                flex: 0 1 calc(100% / ${tabs.length});
                border: 1px solid #f3f5fd;
              `}
            >
              <ListItemButton
                isActive={activeTabLabel === tab.label}
                onClick={() => this.setState({ activeTab: tab.label })}
              >
                <ListItemText isActive={activeTabLabel === tab.label}>{tab.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Content>{activeTab && activeTab.render()}</Content>
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 24px 0;
`;

const List = styled.ol`
  width: 100%;
  height: 60px;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  margin-bottom: 48px;
`;

const ListItem = styled.li`
  list-style: none;
`;

const ListItemButton = styled(ButtonBase)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: ${({ isActive }) => (isActive ? colors.primary.main : colors.surface.light)};
`;

const ListItemText = styled.span<{ isActive: boolean; padding?: boolean }>`
  ${typography.subtitle1};
  font-weight: 600;
  color: ${({ isActive }) =>
    isActive ? colors.whiteText.highEmphasis : colors.surfaceText.highEmphasis};
  ${({ padding }) => padding && "margin-right: 10px;"}
`;

const Content = styled.div``;
