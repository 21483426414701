import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router";

// Redux
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";
import { colors } from "./../../../util/consts";

// Components
import Form from "./../../../components/form/Form";
import Label from "./../../../components/form/Label";
import Info from "./../../../components/form/Info";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Select from "./../../../components/form/BaseSelect";

import { getResource } from "./../../../redux/features/resources/helpers";

class OrgCreateAccount_FundPool extends Component {
  skipFunds = () => {
    const { org, history, dispatch } = this.props;
    dispatch(
      ReduxDialogs.openWelcomeDialog(
        "Welcome",
        "Thank you for choosing Lyfe. Before you get started you will need to ",
        ["Set up your location(s)"],
        "Create Location",
        () => history.push("/admin/orgs/" + this.props.pageData.orgID + "/createloc")
      )
    );
    history.push(`/admin/orgs/${this.props.pageData.orgID}/locations`);
  };

  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    if (this.props.pageData.Country != "AU" && this.props.pageData.orgID){
      this.skipFunds()
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Fund Pool"
          subtitle={
            "If you wish to enable Medicare bulk billing, HICAPS/Medipass, and/or TAC claiming " +
            "you must enable fund pooling below."
          }
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-fundpool">Initial Topup Fund Pool Amount</Label>
            <br />
            <Info>
              This specifies the initial amount of the fund pool from which Medicare bulk billing,
              HICAPS/Medipass, and/or TAC claiming fees are retrieved from. If the fund drops below
              30% of the topup amount then your organisation's credit card is charged the amount
              required to bring the balance back up. Future topup amounts will be automatically
              adjusted based on your daily usage. A good initial value would be roughly equal to
              your estimated daily total claims across your organistaion. For example, if you claim
              $1800/day across your organisation then select $2000 from the dropdown below.
            </Info>
            <Select
              id="createorg-fundpool"
              name="InitialFundPoolAmount"
              type="select"
              placeholder="InitialFundPoolAmount"
              autoFocus={true}
              value={values.InitialFundPoolAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.InitialFundPoolAmount && errors.InitialFundPoolAmount}
            >
              <option value="25000">$250</option>
              <option value="50000">$500</option>
              <option value="100000">$1000</option>
              <option value="200000">$2000</option>
              <option value="400000">$4000</option>
              <option value="800000">$8000</option>
              <option value="1600000">$16000</option>
            </Select>
            {!!errors.InitialFundPoolAmount && touched.InitialFundPoolAmount && (
              <Error>{errors.InitialFundPoolAmount}</Error>
            )}
          </Section>
        </SectionsContainer>

        <FooterContainer>
          <LoadingButton
            style={{ width: 250 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={errors.Subscription && !pageData.Subscription}
          >
            Enable Fund Pool
          </LoadingButton>
          <span style={{ margin: "0 16px" }}>or</span>
          <SkipButton onClick={this.skipFunds}>Don't Enable</SkipButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    InitialFundPoolAmount: "25000"
  }),
  validationSchema: Yup.object().shape({
    InitialFundPoolAmount: Yup.string().required("Initial fund amount is required.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { org, dispatch, history } = props;
      await props.dispatch(
        ResourceActions.action(org, "Action", {
          Action: "ActivateFundPool",
          PoolTopupAmount: parseInt(values.InitialFundPoolAmount)
        })
      );

      dispatch(
        ReduxDialogs.openWelcomeDialog(
          "Welcome",
          "Thank you for choosing Lyfe. Before you get started you will need to ",
          ["Set up your location(s)"],
          "Create Location",
          () => history.push("/admin/orgs/" + org.ID + "/createloc")
        )
      );

      props.toastManager.add("Fund Pool Enabled!", { appearance: "success" });
      history.push(`/admin/orgs/${org.ID}/locations`);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountFundPool"
});

export default compose(
  connect((state, props) => ({
    org: getResource(state.resources, "Organisation", { ID: props.pageData.orgCreatedID })
  })),
  withRouter,
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_FundPool);

const SkipButton = styled.button`
  outline: 0;
  border: 0;
  background: none;
  margin-top: 16px;
  text-decoration: none;
  color: ${colors.secondary.main};
  cursor: pointer;
`;
