
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";

// Utils
import { colors } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";

import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Redux
import apiService from "./../../../redux/services/api";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FormHeader, {HeaderSubtitle} from "../../form/FormHeader";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";

class VerifyOrg extends Component {
  handleResend = async () => {
    const { authUser, values, toastManager } = this.props;
    try {
      await apiService.performRequest({
        method: "post",
        path: `/api/v1/users/${authUser.ID}/validate`,
        data: {
          Type: this.validateType() === "Email" ? "Email" : "MobileNumber",
          Token: null
        }
      });
      toastManager.add(`Verification code sent.`, {
        appearance: "success"
      });
    } catch (err) {
      parseFormError(err, values, () => {}, this.props);
    }
  };
  handleChange = () => {
    const type = this.validateType();
    this.props.setPage("change", { changeType: type });
  };
  handleLogout = async () => {
    const { values, setPage } = this.props;
    try {
      await apiService.userLogout();
      setPage("login");
    } catch (err) {
      parseFormError(err, values, () => {}, this.props);
    }
  };
  validateType = () => {
    const { authUser } = this.props;
    return "Email"

  };
  render() {
    const {
      authUser,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    if (!authUser) {
      return null;
    }
    const type = this.validateType();
    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader
          title={`Email Verification`}
          subtitle={`Enter your verification code for ${
            authUser.Email
          }`}
        />
        <HeaderSubtitle>If you don’t see the email in your inbox shortly you may need to check your spam/junk folder</HeaderSubtitle>
        <SectionsContainer>
          <Section>
            <Label htmlFor="verify-Code">Verification Code</Label>
            <Input
              id="verify-Code"
              placeholder="000000"
              type="text"
              name="Token"
              value={values.Token}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.Token && touched.Token && <Error>{errors.Token}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </FooterContainer>
        {/* <FooterContainer
          css={css`
            margin-top: 32px;
          `}
        >
          <FooterText>
            Not your correct {type === "Email" ? "email" : "phone number"}?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.handleChange}
            >
              Change
            </span>
          </FooterText>
        </FooterContainer> */}
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText>
            Haven't gotten your code?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.handleResend}
            >
              Resend
            </span>
          </FooterText>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          {/* <FooterText>
            Want to escape?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={this.handleLogout}
            >
              Logout
            </span>
          </FooterText> */}
        </FooterContainer>
      </Form>
    );
  }
}
const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    Token: ""
  }),
  validationSchema: Yup.object().shape({
    Token: Yup.number().required("Please set your verification code")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { dispatch, authUser } = props;
      const type ="Email"

      await apiService.performRequest({
        method: "post",
        path: `/api/v1/users/${authUser.ID}/validate`,
        data: {
          Type: type,
          Token: values.Token.trim()
        }
      });

      const userData = await dispatch(ResourceActions.action(authUser, "Fetch", {}));

      props.setPage("desc");

    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "VerifyForm"
});
export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(VerifyOrg);
