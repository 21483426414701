import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import * as grpcWeb from "grpc-web";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { metadata, rpc } from "../../../grpc";
import { CreateCreditCardRequest, CreditCard } from "sdk/dist/credit_cards_pb";
import { toastStore } from "../../../stores/toast-store";
import { FlexBox } from "../../../ui-kit/FlexBox";
import {
  AccordianContainer,
  ButtonContainer,
  ButtonHead,
  NewHeader
} from "../../elements/AccordianElements";
import { CloseButton } from "../../elements/CloseButton";
import Section from "../../form/Section";
import LoadingButton from "../../LoadingButton";
import { ownerTypeToText } from "../CreditCard";
import { StripeTrustBadge } from "../StripeTrustBadge";

interface Props {
  ownerId: string;
  ownerType: CreditCard.OwnerType;
  onCardCreated: (card: CreditCard.AsObject) => void;
  onRequestClose?: () => void;
}

export const NewCardStripeForm = observer((props: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement === null) {
      return;
    }
    const result = await stripe.createToken(cardElement);
    if (result.error) {
      toastStore.error("Stripe error: \n" + result.error.message!);
    } else {
      try {
        // Add credit card to client
        const req = new CreateCreditCardRequest();
        if (!result.token) {
          toastStore.error("Stripe token undefined");
          return;
        }
        req.setToken(result.token.id);
        req.setOwner(props.ownerId);
        req.setOwnerType(props.ownerType);
        let res = new CreditCard();
        switch (props.ownerType) {
          case CreditCard.OwnerType.CLIENT:
            res = await rpc.clientCards.create(req, metadata());
            break;
          case CreditCard.OwnerType.ORGANISATION:
            res = await rpc.orgCards.create(req, metadata());
            break;
          case CreditCard.OwnerType.USER:
            res = await rpc.userCards.create(req, metadata());
            break;
          default:
            throw {
              code: 3,
              message: `Can't create credit card(s), owner type ${ownerTypeToText(
                props.ownerType
              )} unimplemented`
            } as grpcWeb.Error;
        }
        // close
        props.onCardCreated(res.toObject());
        if (props.onRequestClose) {
          props.onRequestClose();
        }
        toastStore.success("Credit card added successfully");
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
  };

  return (
    <AccordianContainer>
      {props.onRequestClose && <CloseButton onClick={props.onRequestClose} />}
      <NewHeader style={{ padding: "1.5rem" }}>
        <ButtonHead>New Card</ButtonHead>
      </NewHeader>
      <StyledForm onSubmit={handleSubmit}>
        <>
          <Section>
            <StripeElementsContainer>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </StripeElementsContainer>
          </Section>

          <FlexBox justifyContent="end">
            <StripeTrustBadge />
          </FlexBox>
          <ButtonContainer>
            <LoadingButton
              style={{
                minWidth: 150
              }}
              loading={!stripe}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Save
            </LoadingButton>
          </ButtonContainer>
        </>
      </StyledForm>
    </AccordianContainer>
  );
});

const StyledForm = styled.form`
  padding: 1.5rem;
`;

export const StripeElementsContainer = styled.div`
  -webkit-letter-spacing: 0.15px;
  -moz-letter-spacing: 0.15px;
  -ms-letter-spacing: 0.15px;
  letter-spacing: 0.15px;
  text-align: left;
  height: 44px;
  background-color: #fff;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #d2d5e3;
  border-radius: 4px;
  outline: none;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
`;

export const CARD_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
  style: {
    base: {
      fontSize: "15.8px",
      color: "rgba(44, 46, 60, 1)",
      "::placeholder": {
        color: "#9e9fa4"
      },
      fontFamily: '"Open Sans" ,sans-serif',
      letterSpacing: "0.15px"
    }
  }
};
