import React from "react";
import { Container } from "../../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../../ui-kit/FlexBox";
import { PaymentCardIcon } from "../../../ui-kit/icons/PaymentCardIcon";
import { Paper } from "../../../ui-kit/Paper";
import { Typography } from "../../../ui-kit/Typography";

export const NoCards = () => (
  <Container spacing={[0, 4]} noPadding>
    <FlexBox justifyContent="center">
      <Paper rounded="full" color="neutral" width={64} height={64}>
        <FlexBox direction="col" justifyContent="center" alignItems="center" fullHeight>
          <FlexItem>
            <PaymentCardIcon.Solid opacity />
          </FlexItem>
          <FlexItem>
            <Typography.H4 alignment="center" color="secondary" noPadding>
              You have no <br /> cards setup
            </Typography.H4>
          </FlexItem>
        </FlexBox>
      </Paper>
    </FlexBox>
  </Container>
);
