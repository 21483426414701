import { rpc, metadata } from "./../grpc";
import { Tag, ListReferralTagsRequest } from "sdk/dist/tag_pb";
import { observable, action } from "mobx";

export class ReferralTagStore {
  @observable
  private TagEntities = new Map<string, Array<Tag.AsObject>>();

  @action
  add(referralId: string, tagEntity: Tag.AsObject) {
    const currentTags = this.get(referralId);

    if (currentTags) {
      currentTags.push(tagEntity);
    } else {
      const newArrayTag = Array<Tag.AsObject>();
      newArrayTag.push(tagEntity);
      this.TagEntities.set(referralId, newArrayTag);
    }
  }

  @action
  delete(referralId: string, tagEntity: Tag.AsObject) {
    const currentTags = this.get(referralId);
    if (currentTags) {
      currentTags.splice(currentTags.indexOf(tagEntity), 1);
    }
  }

  @action
  deleteAll() {
    this.TagEntities.clear();
  }

  @action
  get(referralId: string): Array<Tag.AsObject> | undefined {
    return this.TagEntities.get(referralId);
  }

  // all(): Tag.AsObject[] {
  //   return Array.from(this.TagEntities.values());
  // }

  async load(referralId: string, orgId: string) {
    const req = new ListReferralTagsRequest();
    req.setOrganisationId(orgId);
    req.setReferralId(referralId);
    const res = await rpc.freeFormTag.listReferralTags(req, metadata());

    if (res.toObject().tagsList.length === 0) {
      const newArrayTag = Array<Tag.AsObject>();
      this.TagEntities.set(referralId, newArrayTag);
    } else {
      await res.toObject().tagsList.map((tag) => {
        this.add(referralId, tag);
      });
    }
  }
}

export const referralTagStore = new ReferralTagStore();
