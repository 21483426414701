import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import * as fns from "date-fns";

export function dateToTimestamp(date: Date) {
  const timestamp = new Timestamp();
  timestamp.fromDate(date);
  return timestamp;
}

// this function will return the 12:00 AM of the passed in Date
export function setMidnightOfDate(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
}

export function intToTimestamp(timeInt: number): Timestamp{
    var timestamp = new Timestamp()
    timestamp.setSeconds(timeInt)
    timestamp.setNanos(0)
    return timestamp
}

export function startOfDayUTCTimestamp(date: Date): Timestamp{
    const currentStartDateInt = Math.round(Date.parse(fns.format(date, "yyyy-MM-dd")+"T00:00:00+11:00")/1000)
    return intToTimestamp(currentStartDateInt)
}

export function endOfDayUTCTimestamp(date: Date): Timestamp{
    const currentEndDateInt = Math.round(Date.parse(fns.format(date, "yyyy-MM-dd")+"T23:59:59+11:00")/1000)
    return intToTimestamp(currentEndDateInt)
}


