import styled from "@emotion/styled";
import { Formik } from "formik";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastManager, withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import { metadata, rpc } from "../../../grpc";
import { ApplicationType } from "sdk/dist/applications_and_tokens_pb";
import { TokenAvailableRequest, TokenAvailableResponse } from "sdk/dist/physitrack_pb";
import { toastStore } from "../../../stores/toast-store";
import Button from "./../../../components/Button";
import TagsDropdown from "./../../../components/elements/Dropdown/TagsDropdown";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";
import Select, { GenderSelect, StatusSelect } from "./../../../components/form/BaseSelect";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import MobileInput from "./../../../components/form/MobileInput";
import Section from "./../../../components/form/Section";
import LoadingIcon from "./../../../components/icons/Loading";
import LoadingButton from "./../../../components/LoadingButton";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { ListTagRequest, ReplaceClientTagsResquest, Tag } from "sdk/dist/tag_pb";
import { bookingStore } from "./../../../stores/booking-store";
import { clientTagStore } from "./../../../stores/client_tag-store";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";
import { availableFundTypes, colors, FundType, offFundToText } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";
import typography from "./../../../util/typography";
import { TabContainer } from "./index";
import { PhysitrackButton } from "./Physitrack/PhysitrackButton";

interface IProps {
  resource: any;
  values: any;
  status: any;
  touched: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  isSubmitting: any;
  handleSubmit: any;
  setStatus: (status: boolean) => void;
  toastManager: ToastManager;
  tokenAndData: TokenAvailableResponse.AsObject;
  dispatch: (func: any) => void;
  orgID: string;
  clientID: string;
  bookingId?: string;
}

interface IState {
  tokenAndData?: TokenAvailableResponse.AsObject;
}

@observer
class Details extends Component<IProps, IState> {
  @observable
  tagOptions = new Array<Tag.AsObject>();
  @observable
  selectedTags = new Array<Tag.AsObject>();
  state = {
    tokenAndData: undefined
  };

  async componentDidMount() {
    const resTagOptions = await this.getTags();

    // set tag options based on organisation tag
    await this.onChangeTagOptions(resTagOptions);

    // clear client tag store
    await clientTagStore.deleteAll();

    // load client's selected tags
    await clientTagStore.load(this.props.resource.ID, this.props.resource.Org.ID);

    // set client's selected tags
    const clientTags = clientTagStore.get(this.props.resource.ID);
    if (clientTags) {
      this.selectedTags = clientTags;
    }

    const req = new TokenAvailableRequest();
    req.setOrgid(this.props.orgID);
    req.setClientid(this.props.clientID);
    req.setType(ApplicationType.PHYSITRACK_LYFE);

    const res = await rpc.physitrackV1.tokenAvailable(req, metadata());
    this.setState({ tokenAndData: res.toObject() });
  }

  getTags = async (): Promise<Array<Tag.AsObject>> => {
    // request
    const req = new ListTagRequest();
    req.setOrganisationId(this.props.resource.Org.ID);

    // response
    const res = await rpc.freeFormTag.list(req, metadata());

    return res.toObject().tagsList;
  };

  onChangeTagOptions = (tagOptions: Array<Tag.AsObject>) => {
    this.tagOptions = tagOptions;
  };

  onChangeSelectedTags = (
    selectedTags: Array<Tag.AsObject>,
    selectedTag: Tag.AsObject,
    action: string
  ) => {
    // change selected tags to be shown in react-select
    this.selectedTags = selectedTags;

    // change selected tags in mobx store
    const currentTags = clientTagStore.get(this.props.resource.ID);
    if (currentTags) {
      if (action === "select-option") {
        clientTagStore.add(this.props.resource.ID, selectedTag);
      } else if (action === "deselect-option") {
        clientTagStore.delete(this.props.resource.ID, selectedTag);
      }
    }
  };

  submitForm = async (values: any, { setSubmitting, setStatus, setFieldError }: any) => {
    const { resource: client, dispatch, bookingId } = this.props;
    try {
      if (values.Address !== "") {
        if (!client.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prAddRes: any = {
            OwnerType: "Client",
            Owner: client.ID,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await dispatch(ResourceActions.action(prAddRes as any, "Create", {}));
        } else if (client.Address.Raw !== values.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          const prUpdAddr = {
            OwnerType: "Client",
            ...client.Address,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
        }
      } else if (client && client.Address) {
        await this.props.dispatch(ResourceActions.action(client.Address, "Remove", {}));
      }

      const updClient = {
        ...client,
        Org: client.Org.ID,
        FirstName: values.FirstName.trim(),
        LastName: values.LastName.trim(),
        Email: !values.Email ? null : values.Email.trim(),
        MobileNumber: !values.MobileNumber ? null : values.MobileNumber,
        MobileCountryCode: !values.MobileCountryCode ? null : values.MobileCountryCode,
        Gender: !values.Gender ? null : values.Gender,
        DateOfBirth: !values.DateOfBirth ? null : values.DateOfBirth,
        Status: values.Status,
        preferred_fund_type: values.preferred_fund_type
      };

      // request
      const req = new ReplaceClientTagsResquest();
      req.setOrganisationId(updClient.Org);
      req.setClientId(updClient.ID);

      const tagListId = Array<string>();
      const clientTags = clientTagStore.get(updClient.ID);
      if (clientTags) {
        clientTags.map((tagList) => {
          tagListId.push(tagList.id);
        });
        req.setTagsIdList(tagListId);

        // response
        await rpc.freeFormTag.replaceClientTags(req, metadata());
      }

      await dispatch(ResourceActions.action(updClient, "Update", {}));
      if (bookingId && bookingId !== "") {
        await bookingStore.load(bookingId);
      }
      setStatus(false);
    } catch (err) {
      parseFormError(err, values, setFieldError, this.props);
      setSubmitting(false);
    }

    try {
      this.setState({ tokenAndData: undefined });
      const req = new TokenAvailableRequest();
      req.setOrgid(this.props.orgID);
      req.setClientid(this.props.clientID);
      req.setType(ApplicationType.PHYSITRACK_LYFE);

      const res = await rpc.physitrackV1.tokenAvailable(req, metadata());
      this.setState({ tokenAndData: res.toObject() });
    } catch (err) {
      toastStore.error("Error fetching physitrack token");
    }

    setSubmitting(false);
  };

  render() {
    const resource = this.props.resource;

    return (
      <TabContainer>
        {this.state.tokenAndData && (
          <PhysitrackButton
            tokenAndData={this.state.tokenAndData}
            buttonLabel="Open client in physitrack"
            isButtonDisabled={this.props.resource.Org.SubscriptionType !== "Pro"}
          />
        )}
        <Formik
          onSubmit={this.submitForm}
          validationSchema={Yup.object().shape({
            FirstName: Yup.string().required("First name is required."),
            LastName: Yup.string().required("Last name is required."),
            Email: Yup.lazy((v: any) => Yup.string().email("Email is invalid").notRequired()),
            MobileNumber: Yup.lazy((v: any) =>
              Yup.string().min(5, "Number is too short").notRequired()
            ),
            Gender: Yup.string().required("Please enter the client's gender"),
            DateOfBirth: Yup.date().notRequired(),
            Address: Yup.string().notRequired(),
            Status: Yup.string().notRequired(),
            preferred_fund_type: Yup.string().notRequired()
          })}
          enableReinitialize
          initialValues={{
            FirstName: resource ? resource.FirstName : "",
            LastName: resource ? resource.LastName : "",
            Email: resource ? resource.Email : "",
            MobileNumber: resource ? resource.MobileNumber : "",
            MobileCountryCode: resource ? resource.MobileCountryCode : "",
            DateOfBirth: resource ? resource.DateOfBirth : "",
            Gender: resource ? resource.Gender : "Unknown",
            Address: !(resource && resource.Address)
              ? ""
              : resource && resource.Address && resource.Address.Raw
              ? resource.Address.Raw
              : "",
            Status: resource ? resource.Status : "",
            preferred_fund_type: resource ? resource.preferred_fund_type : FundType.None
          }}
        >
          {({
            handleSubmit,
            values,
            status,
            touched,
            errors,
            handleChange,
            handleBlur,
            setStatus,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <HeaderContainer>
                Client Details
                {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
                {!isSubmitting && !status && (
                  <HeaderButton onClick={() => setStatus(true)}>Edit</HeaderButton>
                )}
                {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
              </HeaderContainer>

              <Container>
                <Section>
                  <Label>First Name</Label>
                  <DetailInput
                    type="text"
                    name="FirstName"
                    value={values.FirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
                </Section>

                <Section>
                  <Label>Last Name</Label>
                  <DetailInput
                    type="text"
                    name="LastName"
                    value={values.LastName}
                    onBlur={handleBlur}
                    autoFocus={true}
                    onChange={handleChange}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
                </Section>

                <Section>
                  <Label>Email Address</Label>
                  <DetailInput
                    type="text"
                    name="Email"
                    placeholder="example@app.lyfe.io"
                    value={values.Email}
                    onBlur={handleBlur}
                    autoFocus={true}
                    onChange={handleChange}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
                </Section>

                <Section>
                  <Label>Phone Number</Label>
                  {status ? (
                    <MobileInput
                      name="MobileNumber"
                      value={values.MobileNumber}
                      onCountryChange={(e: string) => {
                        values.MobileCountryCode = e;
                      }}
                      onPhoneChange={(e: string) => {
                        values.MobileNumber = e;
                      }}
                    />
                  ) : (
                    <DetailInput
                      type="text"
                      name="MobileNumber"
                      value={values.MobileNumber}
                      autoFocus={true}
                      onBlur={handleBlur}
                      disabled={!status}
                      regularText
                    />
                  )}

                  {!!errors.MobileNumber && touched.MobileNumber && (
                    <Error>{errors.MobileNumber}</Error>
                  )}
                </Section>

                <Section>
                  <Label htmlFor="addclient-DateOfBirth">Date of Birth</Label>
                  <DayPickerInput
                    id="addclient-DateOfBirth"
                    name="DateOfBirth"
                    placeholder="Select a date of birth"
                    value={values.DateOfBirth}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={!status}
                    regularText
                    input={DetailInput}
                  />
                  {!!errors.DateOfBirth && touched.DateOfBirth && (
                    <Error>{errors.DateOfBirth}</Error>
                  )}
                </Section>

                <Section>
                  <Label htmlFor="signupextra-Gender">Gender</Label>
                  <DetailGenderSelect
                    id="signupextra-Gender"
                    name="Gender"
                    value={values.Gender}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
                </Section>

                <Section>
                  <Label>Address</Label>
                  <GMPlacesAutocomplete
                    id="loccreate-address"
                    name="Address"
                    type="text"
                    placeholder="Address"
                    inputComponent={DetailInput}
                    value={values.Address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ opacity: "0.6", fontSize: "12.82px", border: "none" }}
                    disabled={!status}
                    regularText
                    error={touched.Address && errors.Address}
                  />
                  {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
                </Section>

                <Section style={{ display: "flex", flexDirection: "column" }}>
                  <Label>Tags</Label>
                  <TagsDropdown
                    orgId={this.props.resource.Org.ID}
                    tagOptions={this.tagOptions}
                    selectedTags={this.selectedTags}
                    onChangeSelectedTags={this.onChangeSelectedTags}
                    onChangeTagOptions={this.onChangeTagOptions}
                    getTags={this.getTags}
                    isDisabled={!status}
                    isEditingClient={true}
                    {...this.props}
                  />

                  {/* need to handle errors later */}
                  {/* {!!errors.preferred_fund_type && touched.preferred_fund_type && <Error>{errors.preferred_fund_type}</Error>} */}
                </Section>

                <Section style={{ display: "flex", flexDirection: "column" }}>
                  <Label>Preferred Payment Method</Label>
                  <Select
                    id="client-payment-method"
                    name="preferred_fund_type"
                    disabled={!status}
                    value={values.preferred_fund_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: 200 }}
                  >
                    {availableFundTypes
                      .filter((v) => {
                        if (process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== "US") return true;
                        if (v === FundType.CreditCard) return true;
                        return false;
                      })
                      .map((v) => (
                        <option key={v} value={v}>
                          {offFundToText(v)}
                        </option>
                      ))}
                  </Select>
                  {!!errors.preferred_fund_type && touched.preferred_fund_type && (
                    <Error>{errors.preferred_fund_type}</Error>
                  )}
                </Section>

                <Section>
                  <Label htmlFor="signupextra-Status">Status</Label>
                  <DetailStatusSelect
                    id="signupextra-Status"
                    name="Status"
                    value={values.Status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!status}
                    regularText
                  />
                  {!!errors.Status && touched.Status && <Error>{errors.Status}</Error>}
                </Section>

                {status && (
                  <Section>
                    <LoadingButton
                      style={{ minWidth: 150 }}
                      loading={isSubmitting}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Save
                    </LoadingButton>
                  </Section>
                )}
              </Container>
            </Form>
          )}
        </Formik>
      </TabContainer>
    );
  }
}

const enhancedDetails = compose<any, { clientId: string; bookingId: string }>(
  connect(),
  withToastManager
)(Details);

class Loader extends React.Component<{ clientId: string; bookingId: string }> {
  render() {
    return (
      <>
        <ResourceFetch type="Client" ids={this.props.clientId} force />
        <ResourceRender
          type="Client"
          ids={this.props.clientId}
          compSuccess={enhancedDetails}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}

export default Loader;

const Container = styled.div`
  border-radius: 4px;
  margin: 8px 0 30px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

const HeaderContainer = styled.div`
  /* margin-top: 30px; */

  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

const HeaderButton = styled(Button)`
  padding: 0;
`;

export const DetailInput = styled(Input)`
  &:disabled {
    ${typography.body2};
    letter-spacing; 0.19px;
    line-height: 18px;
    opacity: 0.6;
  }
`;

const DetailGenderSelect = styled(GenderSelect)<any>`
  &:disabled {
    ${typography.body2};
    letter-spacing; 0.19px;
    line-height: 18px;
    opacity: 0.6;
  }
`;

const DetailStatusSelect = styled(StatusSelect)<any>`
  &:disabled {
    ${typography.body2};
    letter-spacing; 0.19px;
    line-height: 18px;
    opacity: 0.6;
  }
`;
