import React from "react";

interface IProps {
  children: React.ReactNode;
  position: "top" | "bottom";
  onBannerClose?: () => void;
}

export const FloatingBanner = (props: IProps) => {
  return (
    <div
      className={`transition transform fixed inset-x-0 pb-2 sm:pb-5 ${
        props.position === "top" ? "top-0" : "bottom-0"
      }`}
      style={{
        zIndex: 1000
      }}
    >
      <div className="max-w-screen-xl mx-auto px-2 sm:px-4">
        <div className="p-3 rounded-lg bg-white shadow-lg sm:p-3">{props.children}</div>
      </div>
    </div>
  );
};
