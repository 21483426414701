import "normalize.css";
import React from "react";
import { render } from "react-dom";
import * as Sentry from '@sentry/browser';

import configureStore from "./redux/store";

import App from "./app";
import "./styles/index.scss";
import "./styles/common.scss";

// React app.
function renderApp(BaseCmpt, store, persistor) {
  render(
    <BaseCmpt store={store} persistor={persistor} />,
    document.getElementById("root")
  );
}

function setupReact() {
  const { store, persistor } = configureStore();
  if (process.env.BOOKLYFE_ENVIRONMENT.includes("production")){
    Sentry.init({
      dsn: "https://987c84eebf7e46f1b9fcbd94a5e9c1ca@sentry.io/5184503",
      environment: 'production'});
  }
  else if(process.env.BOOKLYFE_ENVIRONMENT === 'master'){
    Sentry.init({
      dsn: "https://987c84eebf7e46f1b9fcbd94a5e9c1ca@sentry.io/5184503",
      environment: 'master'});
  }
  renderApp(App, store, persistor);
  console.log(`Booklyfe Application Configuration: {VERSION:"${process.env.BOOKLYFE_VERSION}", ENV:"${process.env.BOOKLYFE_ENVIRONMENT}", COMMIT_SHA:"${process.env.BOOKLYFE_GIT_COMMIT_TAG}"}`)
}

// Entry Point.
try {
  setupReact();
} catch (e) {
  if (process.env.BOOKLYFE_ENVIRONMENT.includes("production")) {
    Sentry.captureException(e);
  } else {
    console.warn("Uncaught Exception", e);
  }
}
