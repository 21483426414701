import React from "react";
import styled from "@emotion/styled";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";
import { UserBlock } from "./../../../../components/elements/UserBlock";
import Avatar from "./../../../../components/Avatar";
import Divider from "./../../../../components/Divider";
import typography from "./../../../../util/typography";
import { colors } from "./../../../../util/consts";
import { timezoneAbbrev, toDateTz } from "./../../../../util/timestamp";

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
}

export class BreakdownHeader extends React.Component<Props> {
  render() {
    const { booking, client } = this.props;
    const startTime = toDateTz(booking.startDatetime!);

    const endTime = toDateTz(booking.endDatetime!);

    return (
      <>
        <LeftHeader>
          {booking.type === Booking.Type.BOOKING_SINGLE ? (
            <UserBlock firstName={client!.firstName} lastName={client!.lastName} size="small" />
          ) : (
            <GBHeadContainer>
              {/* Change the Avatar letters to provider and title to the name of the service */}
              {/* https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1227 */}
              <Avatar
                letters={`${
                  (booking.provider &&
                    booking.provider.firstName &&
                    booking.provider.firstName[0].toUpperCase()) ||
                  ""
                }${
                  (booking.provider &&
                    booking.provider.lastName &&
                    booking.provider.lastName[0].toUpperCase()) ||
                  ""
                }`}
                size={32}
              />
              <GBHeadTitle>
                {(booking.offeringsList[0] &&
                  booking.offeringsList[0].lineItem &&
                  booking.offeringsList[0].lineItem.offering &&
                  booking.offeringsList[0].lineItem.offering.name) ||
                  ""}
              </GBHeadTitle>
            </GBHeadContainer>
          )}
        </LeftHeader>
        <Divider />
        <LeftHeader>
          <LeftHeaderTitle>{startTime.format("dddd, DD/MM/YYYY")}</LeftHeaderTitle>
          <LeftHeaderBody>
            {startTime.format("hh:mmA")} - {endTime.format("hh:mmA")}
          </LeftHeaderBody>
          <LeftHeaderBody>Timezone, {timezoneAbbrev()}</LeftHeaderBody>
          <LeftHeaderBody>
            {booking.provider!.firstName} {booking.provider!.lastName}
          </LeftHeaderBody>
          <LeftHeaderBody>#{booking.friendlyId}</LeftHeaderBody>
        </LeftHeader>
      </>
    );
  }
}

const LeftHeader = styled.div`
  flex: 0 0 auto;
  padding: 32px 32px 22px 32px;
`;

const GBHeadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const GBHeadTitle = styled.div`
  font-size: 13.82px;
  font-weight: normal;
  letter-spacing: 0.09px;
  line-height: 19px;
  color: #2c2e3c;
  margin: 0;
  margin-left: 10px;
`;

const LeftHeaderTitle = styled.div`
  ${typography.subtitle1};
  letter-spacing: 1.44px;
  line-height: 20px;
  font-weight: 600;
  margin: 3px 0;
`;

const LeftHeaderBody = styled.div`
  ${typography.body1};
  letter-spacing: 1.44px;
  line-height: 20px;
  color: ${colors.surfaceText.medEmphasis};
  margin: 3px 0;
`;
