import React from "react";

interface IProps {
  variant?: "primary" | "danger" | "information";
}

export const CheckIcon = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${computeColor(props.variant)}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
  </svg>
);

const computeColor = (variant: IProps["variant"]) => {
  switch (variant) {
    case "primary":
    default:
      return "text-primary-900";
    case "information":
      return "text-tertiary-200";
    case "danger":
      return "text-red-500";
  }
};
