import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";
import LoadingButton from "./../../../components/LoadingButton";

class OrgEdit_Hubspot extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Hubspot Account Details"
          subtitle="Please fill out the following keys in order to allow clients from the booking system to fill into your Hubspot"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="locedit-portal-id">Portal ID</Label>
            <Input
              id="locedit-portal-id"
              name="PortalID"
              type="text"
              placeholder="Enter the ID belonging to the portal of the form here."
              value={values.PortalID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.PortalID && errors.PortalID}
            />
            {!!errors.PortalID && touched.PortalID && <Error>{errors.PortalID}</Error>}
          </Section>
          <Section>
            <Label htmlFor="locedit-form-id">Form ID </Label>
            <Input
              id="locedit-form-id"
              name="FormID"
              type="text"
              placeholder="Enter the ID of the form here."
              value={values.FormID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.FormID && errors.FormID}
            />
            {!!errors.FormID && touched.FormID && <Error>{errors.FormID}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
        <FooterContainer style={{ marginTop: 16 }}>
          <FooterText>
            <span style={{textAlign:'center', display:'flex'}}>
            <a> In order to configure your Hubspot account a sign up form must be created in your Hubspot account which is compatible with our sytem. In order to understand the process of creating this form, please refer to our documentation <a href={'/hubspot'}>here.</a> </a>
            </span>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData, org }) => {
    if (!org.Integrations || !org.Integrations.Hubspot) {
      return {
        PortalID: "",
        FormID: ""
      };
    } else {
      return {
        PortalID: !org.Integrations.Hubspot.PortalID ? "" : org.Integrations.Hubspot.PortalID,
        FormID: !org.Integrations.Hubspot.FormID ? "" : org.Integrations.Hubspot.FormID,
      };
    }
  },
  validationSchema: Yup.object().shape({
    PortalID: Yup.string().notRequired(),
    FormID: Yup.string().notRequired()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      const updInts = props.org.Integrations || {};
      if (values.PortalID === "" || values.FormID === "") {
        updInts.Hubspot = null;
      } else {
        updInts.Hubspot = {
          PortalID: values.PortalID ? values.PortalID : null,
          FormID: values.FormID ? values.FormID : null

        };
      }
      const updOrg = {
        ...props.org,
        Integrations: updInts
      };
      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditHubspot"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgEdit_Hubspot);
