import React, { Component } from "react";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import ReactDatePicker from "react-datepicker";
import { observer } from "mobx-react";

interface Props {
  dateValue: Date;
  onUpdateFilter(selectedDate: Date): void;
}

@observer
class DateRangerFilter extends Component<Props> {
  render() {
    return (
      <ReactDatePicker
        selected={this.props.dateValue}
        css={InputCSS}
        dateFormat="dd/MM/yyy"
        onChange={(date) => {
          if (date) {
            this.props.onUpdateFilter(date);
          }
        }}
      ></ReactDatePicker>
    );
  }
}

export default DateRangerFilter;

const InputCSS = css`
  font-size: 14px;
  line-height: 1.1;
  padding: 8px 12px 10px;
  border: 1px solid #ddd;
  width: 100%;
  outline-style: none;
  :focus {
    border: 1px solid #0f75c2;
  }
  :hover {
    border: 1px solid #999;
  }
  :focus:hover {
    border: 1px solid #0f75c2;
  }
`;
const IconCSS = css`
  position: absolute;
  top: 14px;
  right: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  cursor: default;
  pointer-events: none;
`;
