import styled from "@emotion/styled";
import debounce from "lodash.debounce";
import React, { PureComponent } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { match, withRouter } from "react-router";
import { restLocationService } from "../../../services/LocationService";
import Button from "./../../../components/Button";
import ButtonBase from "./../../../components/ButtonBase";
import ResourceListFetch from "./../../../components/elements/ResourceListFetch";
import ResourceListRender from "./../../../components/elements/ResourceListRender";
import ResourceRender from "./../../../components/elements/ResourceRender";
import Calendar from "./../../../components/icons/Calendar";
import Location from "./../../../components/icons/Location";
import Loop from "./../../../components/icons/Loop";
import Monetization from "./../../../components/icons/Monetization";
import PersonAdd from "./../../../components/icons/PersonAdd";
import ThumbDown from "./../../../components/icons/ThumbDown";
import ThumbUp from "./../../../components/icons/ThumbUp";
import { SubscriptionUpgradeMessage } from "./../../../components/SubscriptionUpgradeMessage";
import { getResource } from "./../../../redux/features/resources/helpers";
import { AsyncButton } from "./../../../ui-kit/AsyncButton";
import { FlexBox, FlexItem } from "./../../../ui-kit/FlexBox";
import { AppIcon } from "./../../../ui-kit/icons/AppIcon";
import { LockIcon } from "./../../../ui-kit/icons/LockIcon";
import { Modal } from "./../../../ui-kit/Modal";
import { Typography } from "./../../../ui-kit/Typography";
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";
import { Appointment } from "./Appointment";
import { NewClient } from "./NewClient";
import { SmallBlock } from "./SmallBlock";
import { StaffUtilization } from "./StaffUtilization";

function getRevData(data, soff) {
  const res = [];
  let cday = soff;
  for (let i = 0; i < data.length; i++) {
    if (cday !== data[i].Day) {
      for (; cday > data[i].Day; cday--) {
        res.push(0);
      }
    }
    res.push(data[i].Revenue / 100.0);
    cday--;
  }
  for (; cday > soff - 28; cday--) {
    res.push(0);
  }
  return res.reverse();
}

interface IProps {
  orgUser: { Org: { SubscriptionType: string }; Roles: string[] };
  history: any;
  loc: any;
  match: match<{ appName?: string }>;
}

class OrgLocationDashboardRender extends PureComponent<IProps> {
  doughnutContainer = React.createRef();

  state = {
    height: undefined,
    isModalOpen: false,
    providers: [],
  };

  async componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // Fetch the location details to get the provider list
    const res = await restLocationService.fetchLocation(this.props.loc.ID)
    this.setState({providers: res.Providers})
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = debounce(() => {
    this.doughnutContainer.current &&
      this.setState({ height: this.doughnutContainer.current.clientHeight });
  }, 100);

  render() {
    const { height } = this.state;
    const { loc, resource: dash, orgUser, history } = this.props;

    let type;
    if (orgUser && orgUser.Roles && orgUser.Roles.length > 0) {
      if (orgUser.Roles.includes("Admin")) {
        type = "Admin";
      } else if (orgUser.Roles.includes("Receptionist")) {
        type = "Reception";
      }
    } else if (loc.AuthUser && loc.AuthUser.Roles && loc.AuthUser.Roles.length > 0) {
      if (loc.AuthUser.Roles.includes("Admin")) {
        type = "Admin";
      } else if (loc.AuthUser.Roles.includes("Receptionist")) {
        type = "Reception";
      } else if (loc.AuthUser.Roles.includes("Provider")) {
        type = "Provider";
      }
    }

    const isLocAdminRecep =
      loc.AuthUser &&
      ((loc.AuthUser.Roles || []).indexOf("Admin") >= 0 ||
        (loc.AuthUser.Roles || []).indexOf("Receptionist") >= 0);
    const isOrgAdminRecep =
      (orgUser.Roles || []).indexOf("Admin") >= 0 ||
      (orgUser.Roles || []).indexOf("Receptionist") >= 0;
    const isAdminRecep = isLocAdminRecep || isOrgAdminRecep;
    const isOrgAdmin = (orgUser.Roles || []).indexOf("Admin") >= 0;
    const isLocProv = loc.AuthUser && (loc.AuthUser.Roles || []).indexOf("Provider") >= 0;
    const isIframe = window.location.pathname.includes("/iframe/");

    return (
      <Container>
        <Content>
          {this.props.loc &&
            this.props.loc.Org &&
            this.props.loc.Org.SubscriptionType === "Starter" &&
            !isIframe && <SubscriptionUpgradeMessage id={this.props.loc.Org.ID} />}
          <SubHeader>
            <div>
              <Title>{loc.Name}</Title>
              <CenterHorizontally style={{ marginTop: 4 }}>
                <Location style={{ flex: "0 0 auto" }} />
                <SubTitle>{loc.Address.Raw}</SubTitle>
              </CenterHorizontally>
            </div>
            <div style={{ marginTop: 20 }}>
              {isAdminRecep && (
                <Button
                  variant="contained"
                  color="secondary"
                  align="right"
                  onClick={() => {
                    if (isIframe) {
                      return history.push(
                        `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/edit${history.location.hash}`
                      );
                    }
                    history.push(`/admin/locs/${loc.ID}/edit`);
                  }}
                >
                  Edit Location
                </Button>
              )}
            </div>
          </SubHeader>
          <NavContainer>
            <Modal
              onClose={() => this.setState({ isModalOpen: false })}
              isOpen={this.state.isModalOpen}
            >
              <div>
                <SubscriptionUpgradeMessageContainer>
                  <Typography.Body>
                    Upgrade to Lyfe
                    <SubscriptionButton
                      onClick={() => history.push(`/admin/orgs/${loc.Org.ID}/edit?page=subtype`)}
                    >
                      <AppIcon.Blue size="small" />{" "}
                      <Typography.Body color="blue" noPadding>
                        basic
                      </Typography.Body>{" "}
                    </SubscriptionButton>
                    or
                    <SubscriptionButton
                      onClick={() => history.push(`/admin/orgs/${loc.Org.ID}/edit?page=subtype`)}
                    >
                      <AppIcon size="small" />{" "}
                      <Typography.Body color="primary" noPadding>
                        pro
                      </Typography.Body>{" "}
                    </SubscriptionButton>
                    to access the premium features that will super charge your business.
                  </Typography.Body>
                </SubscriptionUpgradeMessageContainer>
                <FlexBox justifyContent="end">
                  <FlexItem>
                    <AsyncButton
                      onClick={() => this.setState({ isModalOpen: false })}
                      variant="secondary"
                      rounded="md"
                      size="md"
                    >
                      CLOSE
                    </AsyncButton>
                  </FlexItem>
                </FlexBox>
              </div>
            </Modal>
            {(!isIframe || !(this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter")) && (
              <NavButton
                onClick={() => {
                  if (this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter") {
                    return this.setState({ isModalOpen: true });
                  }
                  const provider =
                  Array.isArray(this.state.providers) && this.state.providers.length > 0
                      ? this.state.providers.find((p) => p.User !== undefined && p.User.ID === loc.AuthUser.User.ID)
                      : undefined;
                  if (isAdminRecep || !loc.AuthUser) {
                    // Based on Dallas's request, set the provider appointment view as the default view.
                    // https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1227
                    return history.push(
                      isIframe
                        ? `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/appointments?view=calendar${history.location.hash}`
                        : provider
                        ? `/admin/locs/${loc.ID}/providers/${provider.User.ID}`
                        : `/admin/locs/${loc.ID}/appointments`
                    );
                  } else {
                    return history.push(
                      isIframe
                        ? `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/providers/${loc.AuthUser.User.ID}${history.location.hash}`
                        : `/admin/locs/${loc.ID}/appointments`
                    );
                  }
                }}
              >
                {this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter" && (
                  <IconContainer>
                    <LockIcon size="small" />
                  </IconContainer>
                )}
                <NavButtonText>Appointments</NavButtonText>
              </NavButton>
            )}
            {(!isIframe || !(this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter")) && (isAdminRecep || isLocProv) && (
              <NavButton
                onClick={() => {
                  if (this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter") {
                    return this.setState({ isModalOpen: true });
                  }
                  return isIframe
                    ? history.push(
                        `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/offerings${history.location.hash}`
                      )
                    : history.push(`/admin/locs/${loc.ID}/offerings`);
                }}
              >
                <NavButtonText>Services &amp; Products</NavButtonText>{" "}
                {this.props.orgUser.Org.SubscriptionType.toLowerCase() === "starter" && (
                  <IconContainer>
                    <LockIcon size="small" />
                  </IconContainer>
                )}
              </NavButton>
            )}
            {(isAdminRecep || isLocProv) && (
              <NavButton
                onClick={() => {
                  if (isIframe) {
                    return history.push(
                      `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/staff${history.location.hash}`
                    );
                  }
                  history.push(`/admin/locs/${loc.ID}/staff`);
                }}
              >
                <NavButtonText>Staff</NavButtonText>
              </NavButton>
            )}
            {(isAdminRecep || isLocProv) && (
              <NavButton
                onClick={() => {
                  if (isIframe) {
                    return history.push(
                      `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/clients${history.location.hash}`
                    );
                  }
                  history.push(`/admin/locs/${loc.ID}/clients`);
                }}
              >
                <NavButtonText>Clients</NavButtonText>
              </NavButton>
            )}
            {(isAdminRecep || isLocProv) && (
              <NavButton
                onClick={() => {
                  if (isIframe) {
                    return history.push(
                      `/iframe/${this.props.match.params.appName}/admin/locs/${loc.ID}/accounts${history.location.hash}`
                    );
                  }
                  history.push(`/admin/locs/${loc.ID}/accounts`);
                }}
              >
                <NavButtonText>Accounts</NavButtonText>
              </NavButton>
            )}
            {isOrgAdmin && (
              <NavButton
                onClick={() => {
                  if (isIframe) {
                    return history.push(
                      `/iframe/${this.props.match.params.appName}/admin/insight/orgs/${loc.Org.ID}/loc/${loc.ID}/insights${history.location.hash}`
                    );
                  }
                  history.push(`/admin/insight/orgs/${loc.Org.ID}/loc/${loc.ID}/insights`);
                }}
              >
                <NavButtonText>Insights</NavButtonText>
              </NavButton>
            )}
          </NavContainer>
          {!type ? null : (
            <MainContainer>
              <Block>
                <BlockAspectRatio>
                  <BlockContent>
                    {type === "Admin" ? (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Staff Utilisation</BlockTitle>
                          <BlockSubtitle>This Month</BlockSubtitle>
                        </CenterHorizontally>
                        <ScrollContainer>
                          {dash.StaffUtilization.map((v) => (
                            <StaffUtilization key={v.Provider.ID} resource={v} />
                          ))}
                        </ScrollContainer>
                      </>
                    ) : (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Upcoming appointments</BlockTitle>
                          <BlockSubtitle>Latest</BlockSubtitle>
                        </CenterHorizontally>
                        <ScrollContainer>
                          <ResourceListFetch
                            type="LocationUpcomingBookings"
                            listctx={{ Location: loc.ID }}
                            force
                          />
                          <ResourceListRender
                            type="LocationUpcomingBookings"
                            listctx={{ Location: loc.ID }}
                            compSuccess={({ list }) =>
                              list.IDs.length > 0 ? (
                                list.IDs.filter((v, i, a) => a.indexOf(v) === i).map((bid) => (
                                  <ResourceRender
                                    key={bid}
                                    type="LocBooking"
                                    ids={bid}
                                    denorm={true}
                                    compSuccess={Appointment}
                                    forwardedProps={{ type: "upcoming" }}
                                  />
                                ))
                              ) : (
                                <CenterEverything>
                                  <LegendText>There's nothing here</LegendText>
                                </CenterEverything>
                              )
                            }
                          />
                        </ScrollContainer>
                      </>
                    )}
                  </BlockContent>
                </BlockAspectRatio>
              </Block>
              <Block style={{ background: "transparent" }}>
                <MainContainer>
                  <Block>
                    <BlockAspectRatio>
                      <BlockContent>
                        {type === "Admin" ? (
                          <SmallBlock
                            icon={PersonAdd}
                            iconProps={{ color: colors.secondary.main }}
                            number={dash.NewClients}
                            title="New clients"
                            subtitle="(month to date)"
                          />
                        ) : (
                          <SmallBlock
                            icon={Calendar}
                            iconProps={{ color: colors.secondary.main }}
                            number={0}
                            title="Appointments"
                            subtitle="(next 3 days)"
                          />
                        )}
                      </BlockContent>
                    </BlockAspectRatio>
                  </Block>
                  <Block>
                    <BlockAspectRatio>
                      <BlockContent>
                        {type === "Admin" ? (
                          <SmallBlock
                            icon={Loop}
                            iconProps={{ color: "#f9b69c" }}
                            number={dash.RetentionRate}
                            title="Retention rate"
                            subtitle="(avg number of sessions per client)"
                          />
                        ) : (
                          <SmallBlock
                            icon={Calendar}
                            iconProps={{ color: colors.secondary.main }}
                            number={0}
                            title="Appointments"
                            subtitle="(next 7 days)"
                          />
                        )}
                      </BlockContent>
                    </BlockAspectRatio>
                  </Block>
                  <Block>
                    <BlockAspectRatio>
                      <BlockContent>
                        {type === "Admin" ? (
                          <SmallBlock
                            icon={ThumbUp}
                            iconProps={{ color: "#80caa6" }}
                            number={
                              Math.round(
                                (100 * dash.PreviousMonthTotalRebookings) /
                                  (dash.PreviousMonthTotalBookings || 1)
                              ) + "%"
                            }
                            title="Rebookings"
                            subtitle="(month to date)"
                          />
                        ) : (
                          <SmallBlock
                            icon={ThumbDown}
                            iconProps={{ color: "#f9b69c" }}
                            number="0%"
                            title="Cancellations not rebooked"
                            subtitle="(past 7 days)"
                          />
                        )}
                      </BlockContent>
                    </BlockAspectRatio>
                  </Block>
                  <Block>
                    <BlockAspectRatio>
                      <BlockContent>
                        <SmallBlock
                          icon={Monetization}
                          iconProps={{ color: "#f2708a" }}
                          number={dash.UnpaidInvoices}
                          title="Unprocessed Payments"
                          subtitle="(year to date)"
                        />
                      </BlockContent>
                    </BlockAspectRatio>
                  </Block>
                </MainContainer>
              </Block>
              <Block>
                <BlockAspectRatio>
                  <BlockContent>
                    {type === "Admin" ? (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Attendance/cancellations</BlockTitle>
                          <BlockSubtitle>This month</BlockSubtitle>
                        </CenterHorizontally>
                        <BlockChart ref={this.doughnutContainer}>
                          <ChartContainer
                            style={{
                              width: height,
                              height: height
                            }}
                          >
                            <Doughnut
                              data={{
                                labels: ["Rebooked", "Cancelled", "Processed"],
                                datasets: [
                                  {
                                    data: [
                                      dash.PreviousMonthTotalRebookings,
                                      dash.PreviousMonthTotalCancellations,
                                      dash.PreviousMonthTotalBookings -
                                        dash.PreviousMonthTotalCancellations -
                                        dash.PreviousMonthTotalRebookings
                                    ],
                                    backgroundColor: [colors.secondary.main, "#F2708A", "#dfe0e2"]
                                  }
                                ]
                              }}
                              options={{
                                animation: false,
                                responsive: true,
                                maintainAspectRatio: false,
                                cutoutPercentage: 92,
                                legend: {
                                  display: false
                                }
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                              }}
                            >
                              <NumberText>{dash.PreviousMonthTotalBookings}</NumberText>
                              <LegendText style={{ color: colors.surfaceText.lowEmphasis }}>
                                Bookings
                              </LegendText>
                            </div>
                          </ChartContainer>
                          <LegendContainer>
                            <LegendItem>
                              <LegendColor style={{ backgroundColor: colors.secondary.main }} />
                              <LegendText>
                                {dash.PreviousMonthTotalRebookings} <MedEmph>Rebooked</MedEmph>
                              </LegendText>
                            </LegendItem>
                            <LegendItem>
                              <LegendColor style={{ backgroundColor: "#F2708A" }} />
                              <LegendText>
                                {dash.PreviousMonthTotalCancellations} <MedEmph>Cancelled</MedEmph>
                              </LegendText>
                            </LegendItem>
                            <LegendItem>
                              <LegendColor style={{ backgroundColor: "#dfe0e2" }} />
                              <LegendText>
                                {dash.PreviousMonthTotalBookings -
                                  dash.PreviousMonthTotalCancellations -
                                  dash.PreviousMonthTotalRebookings}{" "}
                                <MedEmph>Processed</MedEmph>
                              </LegendText>
                            </LegendItem>
                            <Divider />
                            <LegendItem>
                              <LegendText>
                                {dash.PreviousMonthTotalBookings} <MedEmph>Bookings total</MedEmph>
                              </LegendText>
                            </LegendItem>
                          </LegendContainer>
                        </BlockChart>
                      </>
                    ) : (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Bookings</BlockTitle>
                          <BlockSubtitle>This month</BlockSubtitle>
                        </CenterHorizontally>
                      </>
                    )}
                  </BlockContent>
                </BlockAspectRatio>
              </Block>
              <Block>
                <BlockAspectRatio>
                  <BlockContent>
                    {type === "Admin" && (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Revenue Graph</BlockTitle>
                          <BlockSubtitle>by week</BlockSubtitle>
                        </CenterHorizontally>
                        <BlockChart
                          style={{ flexDirection: "column" }}
                          ref={this.doughnutContainer}
                        >
                          <div style={{ display: "flex", margin: "12px 0" }}>
                            <CenterHorizontally style={{ marginRight: 12 }}>
                              <LegendColor style={{ backgroundColor: "#F2708A" }} />
                              <LegendText>Last 4 weeks</LegendText>
                            </CenterHorizontally>
                            <CenterHorizontally>
                              <LegendColor style={{ backgroundColor: colors.primary.main }} />
                              <LegendText>Previous 4 weeks</LegendText>
                            </CenterHorizontally>
                          </div>
                          <Line
                            data={{
                              labels: [
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "3 Weeks Ago",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "2 Weeks Ago",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "1 Week Ago",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "",
                                "Today"
                              ],
                              datasets: [
                                {
                                  label: "Last 4 Weeks",
                                  backgroundColor: "#F2708A",
                                  borderColor: "#F2708A",
                                  data: getRevData(dash.Last4WeeksRevenue, 0),
                                  fill: false
                                },
                                {
                                  label: "Prev 4 Weeks",
                                  backgroundColor: colors.primary.main,
                                  borderColor: colors.primary.main,
                                  data: getRevData(dash.Prev4WeeksRevenue, -28),
                                  fill: false
                                }
                              ]
                            }}
                            options={{
                              legend: {
                                display: false
                              },
                              scales: {
                                xAxes: [
                                  {
                                    gridLines: {
                                      display: false
                                    },
                                    ticks: {
                                      autoSkip: false,
                                      maxRotation: 0,
                                      minRotation: 0,
                                      fontSize: 11,
                                      fontColor: colors.surfaceText.lowEmphasis
                                    }
                                  }
                                ],
                                yAxes: [
                                  {
                                    gridLines: {
                                      drawBorder: false
                                    },
                                    ticks: {
                                      beginAtZero: true,
                                      padding: 10,
                                      fontSize: 11,
                                      fontColor: colors.surfaceText.lowEmphasis
                                    }
                                  }
                                ]
                              }
                            }}
                          />
                        </BlockChart>
                      </>
                    )}
                    {type === "Provider" && (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>New clients</BlockTitle>
                          <BlockSubtitle>latest</BlockSubtitle>
                        </CenterHorizontally>
                        <ScrollContainer>
                          <ResourceListFetch
                            type="OrganisationClientsPage"
                            listctx={{ Org: loc.Org.ID }}
                            force
                          />
                          <ResourceListRender
                            type="OrganisationClientsPage"
                            listctx={{ Org: loc.Org.ID }}
                            compSuccess={({ list }) =>
                              (list.IDs || [])
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .map((cid) => (
                                  <ResourceRender
                                    key={cid}
                                    type="Client"
                                    ids={cid}
                                    denorm={true}
                                    compSuccess={NewClient}
                                  />
                                ))
                            }
                          />
                        </ScrollContainer>
                      </>
                    )}
                    {type === "Reception" && (
                      <>
                        <CenterHorizontally>
                          <BlockTitle>Unpaid appointments</BlockTitle>
                          <BlockSubtitle>Latest</BlockSubtitle>
                        </CenterHorizontally>
                        <ScrollContainer>
                          <ResourceListFetch
                            type="LocationPastProcessingBookings"
                            listctx={{ Location: loc.ID }}
                            force
                          />
                          <ResourceListRender
                            type="LocationPastProcessingBookings"
                            listctx={{ Location: loc.ID }}
                            compSuccess={({ list }) =>
                              list.IDs.length > 0 ? (
                                list.IDs.filter((v, i, a) => a.indexOf(v) === i).map((bid) => (
                                  <ResourceRender
                                    key={bid}
                                    type="LocBooking"
                                    ids={bid}
                                    denorm={true}
                                    compSuccess={Appointment}
                                    forwardedProps={{ type: "unpaid" }}
                                  />
                                ))
                              ) : (
                                <CenterEverything>
                                  <LegendText>There's nothing here</LegendText>
                                </CenterEverything>
                              )
                            }
                          />
                        </ScrollContainer>
                      </>
                    )}
                  </BlockContent>
                </BlockAspectRatio>
              </Block>
            </MainContainer>
          )}
        </Content>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { loc } = props;
  const { auth, resources } = state;

  const orgUser = getResource(
    resources,
    "OrganisationUser",
    { Org: loc.Org.ID, User: auth.UserID },
    true
  );

  return {
    orgUser
  };
}

export default connect(mapStateToProps)(withRouter(OrgLocationDashboardRender));

// CSS for dashboard
const Container = styled.div`
  background-color: #f4f8f9;
  min-height: calc(100vh - 69px);
`;

// CSS for Main Content
const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  padding: 24px 18px;

  ${breakpoints["tablet-up"]} {
    padding: 36px 24px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 50px 36px;
  }
`;

// CSS to create margin for table content to header
const SubHeader = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const CenterHorizontally = styled.div`
  display: flex;
  align-items: center;
`;

const CenterEverything = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 50px);
  margin-top: 30px;
`;

// CSS for Header Title
const Title = styled.div`
  color: ${colors.surfaceText.highEmphasis};
  font-family: "Open Sans";
  font-size: 33.57px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;

// CSS for secondary header
const SubTitle = styled.div`
  width: 100%;
  color: ${colors.surfaceText.highEmphasis};
  font-family: "Open Sans";
  font-size: 13.82px;
  letter-spacing: 0.25px;
  line-height: 16px;
`;

const mobileSpace = 6;
const tabletSpace = 8;
const laptopSpace = 12;

// CSS for overall container
const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + ${mobileSpace * 2}px);
  margin-top: 0;
  margin-bottom: 40px;
  margin-left: -${mobileSpace}px;
  margin-right: -${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(100% + ${tabletSpace * 2}px);
    margin-left: -${tabletSpace}px;
    margin-right: -${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(100% + ${laptopSpace * 2}px);
    margin-left: -${laptopSpace}px;
    margin-right: -${laptopSpace}px;
  }
`;

// CSS for each box
const NavButton = styled(ButtonBase)`
  background-color: ${colors.primary.main};
  height: 68px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  opacity: ${({ disabled }) => (!disabled ? 1 : 0.2)};

  &:hover:enabled {
    opacity: 0.8;
  }

  width: calc(50% - ${mobileSpace * 2}px);
  margin: ${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(calc(100% / 3) - ${tabletSpace * 2}px);
    margin: ${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(calc(100% / 6) - ${laptopSpace * 2}px);
    margin: ${tabletSpace}px ${laptopSpace}px;
  }
`;

const NavButtonText = styled.span`
  color: ${colors.whiteText.highEmphasis};
  font-family: "Open Sans";
  font-size: 14.82px;
  font-weight: 600;
  letter-spacing: 0.27px;
  text-align: center;
  padding: 0 12px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + ${mobileSpace * 2}px);
  margin: -${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(100% + ${tabletSpace * 2}px);
    margin: -${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(100% + ${laptopSpace * 2}px);
    margin: -${laptopSpace}px;
  }
`;

const Block = styled.div`
  background-color: ${colors.surface.light};
  border-radius: 4px;

  width: calc(100% - ${mobileSpace * 2}px);
  margin: ${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(50% - ${tabletSpace * 2}px);
    margin: ${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(50% - ${laptopSpace * 2}px);
    margin: ${laptopSpace}px;
  }
`;

const BlockAspectRatio = styled.div`
  padding-top: 66%;
  position: relative;
`;

const BlockContent = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;

  padding: 24px 36px;
`;

const BlockTitle = styled.span`
  color: ${colors.surfaceText.highEmphasis};
  font-size: 13.82px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const BlockSubtitle = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 9.82px;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-left: 6px;
`;

const BlockChart = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
`;

const ChartContainer = styled.div`
  position: relative;
  padding: 24px;
  flex: 0 0 auto;
`;

const LegendContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
`;

const LegendColor = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;

const LegendText = styled.span`
  ${typography.body2};
  line-height: 18px;
`;

const NumberText = styled.span`
  ${typography.heading4};
`;

const MedEmph = styled.span`
  opacity: 0.6;
`;

const Divider = styled.div`
  width: 28px;
  height: 1px;
  background-color: ${hexToRgba(colors.primary.main, 0.1)};
  margin: 6px 0;
`;

const IconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
`;

const SubscriptionUpgradeMessageContainer = styled.div`
  div,
  svg {
    vertical-align: bottom;
    display: inline-block;
  }
`;

// CSS for Subscription link button
const SubscriptionButton = styled.button`
  padding: 0 3px;
`;
