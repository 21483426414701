import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import MediaInput from "./../../../components/form/MediaInput";
import LoadingButton from "./../../../components/LoadingButton";
import Label from "./../../../components/form/Label";
import Tooltip from "./../../../components/elements/Tooltip";

class LocEditAccount_Market extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      setFieldError
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Market to your clients"
          subtitle="Download free banner images to make your Lyfe profile stand out at Unsplash.com (https://unsplash.com/search/photos/health)"
        />
        <SectionsContainer>
          <Section>
          <Label htmlFor="loccreate-phone">Banner&nbsp;</Label>
          <Tooltip>
              This image will appear on the home page of booklyfe, if this is left blank then the organsation image will be used by default
            </Tooltip>
            <MediaInput
              type="banner"
              name="Banner"
              value={values.Banner}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Banner && touched.Banner && <Error>{errors.Banner}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ loc }) => ({
    Banner: !loc.Banner ? "" : loc.Banner.ID
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updLoc = {
        ...props.loc,
        Banner: values.Banner || null
      };

      await props.dispatch(ResourceActions.action(updLoc, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LocEditAccountMarket"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(LocEditAccount_Market);
