import React, { Component } from "react";
import styled from "@emotion/styled";

// components
import {
  SplitDialog,
  LeftContent,
  RightContent,
  Seperator,
  SideHeader,
  SideLabel,
  SideValue,
  RightPadding
} from "../SplitDialog";
import Tabs from "./../../../components/Tabs";
// import IconButton from "./../../../components/IconButton";
// import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";
import UserBlockDeprecated from "./../../../components/elements/UserBlock";

// modal components
import ContactDetails from "./ContactDetails";
import OrgRoles from "./OrgRoles";
import OrgType from "./OrgType"
import ProfessionalDetails from "./ProfessionalDetails";
import Appointments from "./Appointments";
import Locations from "./Locations";
import { IntegratedApps } from './IntegratedApps/IntegratedApps';


function userProfession(ul) {
  if (ul.Locations.findIndex((l) => l.Roles.indexOf("Provider") >= 0) >= 0) {
    return ul.RoleProperties.ProviderProfession;
  }
  if (
    (ul.Roles || []).indexOf("Admin") >= 0 ||
    ul.Locations.findIndex((l) => l.Roles.indexOf("Admin") >= 0) >= 0
  ) {
    return "Admin";
  }
  if (
    (ul.Roles || []).indexOf("Receptionist") >= 0 ||
    ul.Locations.findIndex((l) => l.Roles.indexOf("Receptionist") >= 0) >= 0
  ) {
    return "Receptionist";
  }
  return "";
}

class StaffActionDialog extends Component {
  leftComponent = React.memo(({ resource }) => (
    <LeftContent>
      <UserBlockDeprecated user={resource.User} post="- Staff" alt={userProfession(resource)} />
      <Seperator />
      <SideHeader>Contact Info</SideHeader>
      {!resource.User.Email ? null : (
        <React.Fragment>
          <SideLabel>Email Address</SideLabel>
          <SideValue>{resource.User.Email}</SideValue>
        </React.Fragment>
      )}
      {!resource.User.MobileNumber ? null : (
        <React.Fragment>
          <SideLabel>Phone Number</SideLabel>
          <SideValue>{resource.User.MobileNumber}</SideValue>
        </React.Fragment>
      )}
      {!resource.User.Address ? null : (
        <React.Fragment>
          <SideLabel>Address</SideLabel>
          <SideValue>{resource.User.Address.Raw}</SideValue>
        </React.Fragment>
      )}

      <Seperator />
      <SideHeader>Locations</SideHeader>
      {resource.Locations.map((ul) => (
        <React.Fragment key={ul.Location.ID}>
          <SideLabel>{ul.Location.Address.Locality} Provider Numbers</SideLabel>
          {ul.ProviderFundProperties && (ul.ProviderFundProperties.AUMedicare || ul.ProviderFundProperties.AUDVA ||ul.ProviderFundProperties.AUHICAPS) ? null : <SideValue>No Provider Numbers</SideValue>}
          {!ul.ProviderFundProperties || !ul.ProviderFundProperties.AUMedicare ? null : (
            <SideValue>{ul.ProviderFundProperties.AUMedicare.ProviderID} (Medicare)</SideValue>
          )}
          {!ul.ProviderFundProperties || !ul.ProviderFundProperties.AUDVA ? null : (
            <SideValue>{ul.ProviderFundProperties.AUDVA.ProviderID} (DVA)</SideValue>
          )}
          {!ul.ProviderFundProperties || !ul.ProviderFundProperties.AUHICAPS ? null : (
            <SideValue>{ul.ProviderFundProperties.AUHICAPS.ProviderID} (HICAPS)</SideValue>
          )}
        </React.Fragment>
      ))}
    </LeftContent>
  ));

  detailsTab = React.memo(({ orgUser, isProvider }) => (
    <TabContainer>
      <ContactDetails orgUser={orgUser} />
      <OrgRoles orgUser={orgUser} />
      {!isProvider ? null : 
      <>
        <ProfessionalDetails orgUser={orgUser} />
        <IntegratedApps orgUser={orgUser}/>
      </>
      }
    </TabContainer>
  ));

  rightComponent = React.memo(({ resource, dialogId }) => {
    const isProvider = resource.Locations.findIndex((l) => l.Roles.indexOf("Provider") >= 0) >= 0;
    const tabs = [
      {
        label: "Details",
        content: this.detailsTab,
        props: { key: "details-key", orgUser: resource, isProvider }
      },
      {
        label: "Locations",
        content: Locations,
        props: { key: "locations-key", orgUser: resource }
      }
    ];

    if (isProvider) {
      tabs.push({
        label: "Appointments",
        content: Appointments,
        props: { key: "appointments-key", orgUser: resource, dialogId }
      });
    }

    return (
      <RightContent>
        <Tabs tabs={tabs} />
        <RightPadding />
      </RightContent>
    );
  });

  contentComponent = React.memo((props) => (
    <SplitDialog
      data={props.data}
      dialogId={props.dialogId}
      leftRender={this.leftComponent}
      rightRender={this.rightComponent}
      forwardedProps={props}
    />
  ));

  render() {
    const { data } = this.props;
    const ids = { Org: data.item.Org.ID, User: data.item.User.ID };
    return (
      <React.Fragment>
        {/* <ResourceFetch type="LocationUser" ids={ids} /> */}
        <ResourceRender
          type="OrganisationUser"
          ids={ids}
          compSuccess={this.contentComponent}
          forwardedProps={this.props}
          denorm={true}
        />
      </React.Fragment>
    );
  }
}

export default StaffActionDialog;

export const TabContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
`;
