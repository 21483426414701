import React from "react";

interface IProps {
  heading: string;
  stepperPosition: number;
  steps: { content: React.ReactNode }[];
}

export const FormBlock: React.FC<IProps> = (props) => {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">
        <div>
          <div className="border-b-2 py-4">
            <div className="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight">
              Step: {props.stepperPosition + 1} of {props.steps.length}
            </div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div className="flex-1">
                {props.steps.length && (
                  <div className="text-lg font-bold text-gray-700 leading-tight">
                    {props.heading}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>{props.steps.length && props.steps[props.stepperPosition].content}</div>
      </div>
    </div>
  );
};
