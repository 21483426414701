import React, { PureComponent } from "react";

import { FundInvoice } from "./FundInvoice";

import { TabContainer, TabHeader } from "../../elements/Tabs";
import { AccountItem } from "sdk/dist/account_items_pb";
import { ClientInvoice } from "../ClientActionDialog/Invoices";

interface Props {
  invoices: Array<AccountItem.AsObject>;
  dispatch?: any;
}
export class ShowInvoices extends PureComponent<Props> {
  render() {
    const { invoices } = this.props;

    const compPay = invoices.filter(
      (v: AccountItem.AsObject) =>
        (v.status == AccountItem.Status.ACC_ITEM_PROCESSING ||
          v.status == AccountItem.Status.ACC_ITEM_COMPLETED) &&
        v.type == AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT
    );
    const compFunds = invoices.filter(
      (v: AccountItem.AsObject) =>
        (v.status == AccountItem.Status.ACC_ITEM_CREATED ||
          v.status == AccountItem.Status.ACC_ITEM_PROCESSING ||
          v.status == AccountItem.Status.ACC_ITEM_COMPLETED) &&
        v.type == AccountItem.Type.ACC_ITEM_FUND
    );

    return (
      <>
        {compPay.length > 0 && (
          <TabContainer>
            <TabHeader>Payment Invoice</TabHeader>
            {compPay.map((v: AccountItem.AsObject) => (
              <ClientInvoice key={v.id} invoice={v} {...this.props} />
            ))}
          </TabContainer>
        )}

        {compFunds.length > 0 && (
          <TabContainer>
            <TabHeader>Fund Claims</TabHeader>
            {compFunds.map((v: AccountItem.AsObject) => (
              <FundInvoice key={v.id} invoice={v} {...this.props} />
            ))}
          </TabContainer>
        )}
      </>
    );
  }
}
