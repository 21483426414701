import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Form from "../../components/form/Form";
import { useAsync } from "../../hooks/useAsync";
import { userStore } from "../../mobx/stores/UserStore";
import { IOrganization } from "../../pages/create-organization/form/IOrganization";
import { sharedAddressService } from "../../services/AddressService";
import { sharedAffiliateCodeService } from "../../services/AffiliateCodeService";
import { sharedCountryService } from "../../services/CountryService";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Dropdown } from "../../ui-kit/Dropdown";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { AddressSearchWidget } from "../../ui-kit/form/AddressSearchWidget";
import { FormContainer } from "../../ui-kit/form/FormContainer";
import { TextInput } from "../../ui-kit/TextInput";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "../../util/address";
import { tracking } from "../../util/tracking";

interface IProps {
  organization: Partial<IOrganization>;
  setOrgDetails: (o: Partial<IOrganization>) => void;
  nextStep: () => void;
}

export const BusinessInformationForm = (props: IProps) => {
  const schema = Yup.object<IOrganization>().shape({
    Name: Yup.string().required("Name is required."),
    ContactEmail: Yup.string().email("Email is invalid.").required("Email is required."),
    ContactPhone: Yup.string().required("Phone number is required."),
    Country: Yup.string().required("Country is required."),
    Address: Yup.string().required("Address is required.")
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    getValues,
    watch
  } = useForm<IOrganization>({
    resolver: yupResolver(schema),
    defaultValues: {
      ContactEmail: props.organization.ContactEmail
        ? props.organization.ContactEmail
        : userStore.user
        ? userStore.user.email
        : "",
      ContactPhone: props.organization.ContactPhone
        ? props.organization.ContactPhone
        : userStore.user
        ? userStore.user.mobileNumber
        : "",
      Name: props.organization.Name,
      Country: props.organization.Country,
      Address: props.organization.Address,
      AddressID: props.organization.AddressID
    }
  });

  useEffect(() => {
    watch();
    // If country field value changes then reset address value
    // This is done to ensure address chosen is within the selected country
    const subscription = watch((value, { name, type }) => {
      if (name === "Country") {
        setValue("Address", "");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data: IOrganization) => {
    tracking.organizationStepperFormCompleted("info");
    let addrID;
    if (data.Address) {
      const prDet = await findPlaceDetails(data.Address, "Address");
      const { $Metadata, ...restObj } = {
        OwnerType: "Organisation",
        ...getAddressResourceFromPlaceDetails(prDet, data.Address)
      };
      const prRes = await sharedAddressService.createAddress(restObj);
      if (prRes.Payload) {
        addrID = prRes.Payload.ID;
      } else {
        return setError("Address", {
          type: "validate",
          message: "Address is invalid"
        });
      }
    }

    if (data.Affiliate) {
      const isAffiliateValid = await sharedAffiliateCodeService.checkAffiliateCode(data.Affiliate);
      // Throw error if affiliate code is not valid
      if (!isAffiliateValid) {
        return setError("Affiliate", {
          type: "validate",
          message: "Not a valid code"
        });
      }
    }

    props.setOrgDetails({ ...props.organization, ...data, AddressID: addrID });
    props.nextStep();
  };

  const countriesListFetcher = useAsync(
    async () =>
      (await sharedCountryService.getSupportedCountriesList()).map((c) => ({
        label: c.name,
        value: c.alpha2Code
      })) || [],
    []
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer
        label="Business Information"
        description="This information will be displayed publicly on Lyfe website while listing your products & services."
      >
        <TextInput
          label="Business Name"
          placeholder="Enter business name"
          value={getValues("Name")}
          onChange={(v) => setValue("Name", v || "")}
          error={errors.Name ? errors.Name.message : ""}
          required
        />

        <TextInput
          label="Contact Email"
          placeholder="example@example.com"
          value={getValues("ContactEmail")}
          onChange={(v) => setValue("ContactEmail", v || "")}
          error={errors.ContactEmail ? errors.ContactEmail.message : ""}
          required
        />

        <TextInput
          label="Contact Phone"
          placeholder="406 750 9999"
          value={getValues("ContactPhone")}
          onChange={(v) => setValue("ContactPhone", v || "")}
          error={errors.ContactPhone ? errors.ContactPhone.message : ""}
          required
        />

        <Dropdown
          label="Country"
          options={countriesListFetcher.value || []}
          value={getValues("Country")}
          onChange={(v) => setValue("Country", v || "")}
          error={errors.Country ? errors.Country.message : ""}
          required
        />

        <AddressSearchWidget
          placeholder="Enter business address"
          value={getValues("Address")}
          disabled={!getValues("Country")}
          countryCodes={getValues("Country")}
          onChange={(v) => setValue("Address", v)}
          error={errors.Address ? errors.Address.message : ""}
          label="Business Address"
          required
        />

        <TextInput
          label="Affiliate code"
          placeholder="Enter affiliate code"
          value={getValues("Affiliate")}
          onChange={(v) => setValue("Affiliate", v || "")}
        />

        <FlexBox direction="row" justifyContent="end">
          <FlexItem>
            <AsyncButton
              onClick={() => undefined}
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Next
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </FormContainer>
    </Form>
  );
};
