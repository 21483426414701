import classNames from "classnames";
import * as dfns from "date-fns";
import React, { forwardRef } from "react";
import { default as Picker } from "react-datepicker";
import { Typography } from "./Typography";

interface IProps {
  value?: string;
  onChange: (v?: string) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
}

export const DatePicker = (props: IProps) => {
  // This is required to get IOS clear button working.
  const onNativeInput = (e: any) => {
    const target = e.nativeEvent.target;
    setTimeout(() => {
      target.defaultValue = "";
    }, 0);
  };

  if (typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1) {
    return (
      <>
        {props.label && (
          <Typography.Body noPadding color="primary">
            {props.label}
          </Typography.Body>
        )}
        <input
          type="date"
          value={props.value}
          onInput={onNativeInput}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e) => props.onChange(e.target.value)}
          className={classNames(
            "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-900 focus:border-primary-500",
            props.disabled && "disabled:opacity-30"
          )}
        />
      </>
    );
  } else {
    return (
      <>
        {props.label && (
          <Typography.Body noPadding color="primary">
            {props.label}
          </Typography.Body>
        )}
        <Picker
          className={classNames(
            "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-900 focus:border-primary-500",
            props.disabled && "disabled:opacity-30"
          )}
          selected={
            !props.value
              ? null
              : dfns.parse(props.value, "yyyy-MM-dd", new Date(1980, 1, 1, 12, 0, 0, 0))
          }
          onChange={(v) => props.onChange(v ? dfns.format(v, "yyyy-MM-dd") : undefined)}
          dateFormat="d/M/yyyy"
          placeholderText={props.placeholder}
          disabled={props.disabled}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="top-end"
          customInput={<CustomDatePicker />}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "scrollParent"
            }
          }}
        />
      </>
    );
  }
};

const CustomDatePicker = forwardRef<HTMLInputElement>((props: any, ref) => (
  <input
    ref={ref}
    type="text"
    value={props.value}
    onClick={props.onClick}
    className={classNames(
      "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-900 focus:border-primary-500"
    )}
  />
));
