import { userStore } from "../mobx/stores/UserStore";
import { metadata, rpc } from "../grpc";
import { UserBookingRequest } from "sdk/dist/bookings_pb";
import { toastStore } from "../stores/toast-store";

class BookingService {
  async create(id: string) {
    const req = new UserBookingRequest();
    req.setBookingId(id);

    const res = await rpc.userBookings.create(req, metadata());
    return res.toObject();
  }
}

class RestBookingService {
  async fetchBookings(userId: string) {
    if (!userStore.user) {
      return Promise.resolve([]);
    }
    try {
      const res = await fetch(`/api/v1/users/${userId}/bookings`, {
        headers: {
          Authorization: userStore.getAuthHeader()
        }
      });
      const json = await res.json();
      if (json.StatusCode !== 200) {
        toastStore.error("Error occurred while fetching bookings");
        return Promise.resolve([]);
      }
      return json.Payload;
    } catch (err) {
      toastStore.error("Error occurred while fetching bookings");
      return Promise.resolve([]);
    }
  }
}

export const restBookingService = new RestBookingService();

export const sharedBookingService = new BookingService();
