import React, { Component } from "react";
import styled from "@emotion/styled";
import * as fns from "date-fns";
import { colors } from "./../../../util/consts";
import DropdownSelect from "./../../../components/DropdownSelect";

import {
  AccordianContainer,
  AccordianEdit,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";

import LoadingIcon from "./../../../components/icons/Loading";
import Options from "./../../../components/icons/Options";
import Download from "./../../../components/icons/Download";
import { AccountItem, RetryInvGenRequest } from "sdk/dist/account_items_pb";
import { fundTypeToText } from "./../../../util/funds";
import { aiStatusToText } from "./../../../util/account-items";
import { toDateTz } from "./../../../util/timestamp";
import {
  CheckClaimRequest,
  RetryClaimRequest,
  CancelAndChargeClientClaimRequest
} from "sdk/dist/funds_pb";
import { metadata, rpc } from "./../../../grpc";
import { accItemStore } from "./../../../stores/acc_item-store";
import { bookingStore } from "./../../../stores/booking-store";
import { toastStore } from "./../../../stores/toast-store";
import { toJS } from "mobx";

interface Props {
  invoice: AccountItem.AsObject;
  dispatch?: any;
}
export class FundInvoice extends Component<Props> {
  state = {
    isLoading: false
  };

  recheckInvoice = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    try {
      const req = new CheckClaimRequest();
      req.setAccountItem(invoice.id);
      const res = await rpc.clientFunds.checkClaim(req, metadata());
      accItemStore.add(res.toObject());
      toastStore.success("Resubmit fund claim check successfully.");
    } catch (err) {
      toastStore.grpcError(err);
    }
    this.setState({ isLoading: false });
  };

  retryInvoice = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    try {
      const req = new RetryClaimRequest();
      req.setAccountItem(invoice.id);
      req.setClientId(invoice.clientId);
      req.setBookingId(invoice.booking!.id);
      const res = await rpc.clientFunds.retryClaim(req, metadata());
      // add new acc item from store
      accItemStore.add(res.toObject());
      // remove old acc item from store
      accItemStore.delete(invoice);
      // update redux store
      bookingStore.load(invoice.booking!.id);
      toastStore.success("Retry fund claim invoice successfully.");
    } catch (err) {
      toastStore.grpcError(err);
    }
    this.setState({ isLoading: false });
  };
  chargeClient = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    try {
      const req = new CancelAndChargeClientClaimRequest();
      req.setAccountItemIdToCancel(invoice.id);
      req.setClientId(invoice.clientId);
      req.setBookingId(invoice.booking!.id);
      const res = await rpc.clientFunds.cancelAndChargeClientClaim(req, metadata());
      accItemStore.add(res.toObject());
      toastStore.success("Cancelling fund claim and processing client charge.");
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };
  processingOptions = [
    {
      label: "Recheck Claim",
      onClick: this.recheckInvoice
    }
  ];
  errorOptions = [
    {
      label: "Recheck Claim",
      onClick: this.recheckInvoice
    },
    {
      label: "Retry Claim",
      onClick: this.retryInvoice
    },
    {
      label: "Charge Client",
      onClick: this.chargeClient
    }
  ];

  download = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    if (invoice.invDownloadUrl) {
      window.open(invoice.invDownloadUrl, "_blank");
    } else {
      try {
        const req = new RetryInvGenRequest();
        req.setAccountItemId(invoice.id);
        await rpc.accountItems.retryInvGeneration(req, metadata());
        await setTimeout(async () => {
          await accItemStore.loadByBookingClient(invoice.booking!.id, invoice.clientId);
          accItemStore
            .all()
            .filter(
              (inv) =>
                inv.fundType == invoice.fundType &&
                inv.status == AccountItem.Status.ACC_ITEM_COMPLETED &&
                inv.booking!.id == invoice.booking!.id &&
                inv.clientId == invoice.clientId
            )
            .map((inv) => window.open(inv.invDownloadUrl, "_blank"));
        }, 3000);
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
    this.setState({ isLoading: false });
  };

  renderErrorContent() {
    const { invoice } = this.props;
    return (
      <Table>
        <tbody>
          <Row>
            <ColH>Error Code: </ColH>
            <Col>{invoice.statusCode}</Col>
          </Row>
          <Row>
            <ColH>Error Message: </ColH>
            <Col>{invoice.statusMessage}</Col>
          </Row>
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading } = this.state;
    const { invoice } = this.props;
    return (
      <AccordianContainer maySelect={invoice.status === AccountItem.Status.ACC_ITEM_ERROR}>
        <AccordianEdit>
          <ButtonHead style={{ width: "50px" }}>{fundTypeToText(invoice.fundType)}</ButtonHead>
          <Separator />
          <ButtonHead style={{ width: "0px" }}>#{invoice.invoiceId}</ButtonHead>
          <Separator />
          <ButtonDetails style={{ width: "50px" }}>
            {toDateTz(invoice.lastModifiedAt!).format("h:mmA DD/MM/yyyy")}
          </ButtonDetails>
          <Separator />
          <ButtonDetails style={{ width: "50px" }}>{aiStatusToText(invoice.status)}</ButtonDetails>
          {invoice.status === AccountItem.Status.ACC_ITEM_CREATED && (
            <OptionsContainer style={{ marginLeft: "0px", width: "24px" }} />
          )}
          {invoice.status === AccountItem.Status.ACC_ITEM_COMPLETED && (
            <OptionsContainer style={{ marginLeft: "0px", width: "24px" }}>
              <NoSpacingIconButton onClick={this.download}>
                {isLoading ? (
                  <LoadingIcon width={24} height={24} color="#2C2E3C" style={{ opacity: 0.3 }} />
                ) : (
                  <Download style={{ width: "0px" }} />
                )}
              </NoSpacingIconButton>
            </OptionsContainer>
          )}
          {invoice.status === AccountItem.Status.ACC_ITEM_PROCESSING && (
            <DropdownSelect
              style={{ marginLeft: "0px", width: "24px" }}
              options={!isLoading && this.processingOptions}
            >
              <NoSpacingIconButton>
                {isLoading ? (
                  <LoadingIcon width={24} height={24} color="#2C2E3C" style={{ opacity: 0.3 }} />
                ) : (
                  <Options />
                )}
              </NoSpacingIconButton>
            </DropdownSelect>
          )}
          {invoice.status === AccountItem.Status.ACC_ITEM_ERROR && (
            <DropdownSelect
              style={{ marginLeft: "0px", width: "24px" }}
              options={!isLoading && this.errorOptions}
            >
              <NoSpacingIconButton>
                {isLoading ? (
                  <LoadingIcon width={24} height={24} color="#2C2E3C" style={{ opacity: 0.3 }} />
                ) : (
                  <Options />
                )}
              </NoSpacingIconButton>
            </DropdownSelect>
          )}
        </AccordianEdit>
        {invoice.status === AccountItem.Status.ACC_ITEM_ERROR && this.renderErrorContent()}
      </AccordianContainer>
    );
  }
}

const Table = styled.table`
  padding: 20px 40px;
`;

const Row = styled.tr``;

const ColH = styled.td`
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 25px;
  padding-top: 5px;
`;

const Col = styled.td`
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-top: 5px;
`;
