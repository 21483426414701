import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { ThirdPartyInvoiceLineItem, TaxType } from "sdk/dist/third_party_invoices_pb";
import { Charge } from "sdk/dist/money_pb";
import { ItemContainer } from "../elements/AccordianElements";
import { createForm } from "./../../forms/forms";
import Section from "../form/Section";
import Label from "../form/Label";
import Error from "./../../components/form/Error";
import Input from "../form/Input";
import { FormSplitContainer, FormSplitItem } from "../form/FormSplitContainer";
import {
  chargeFromFloat,
  moneyToFloat,
  chargeZero,
  chargeToFloat,
  taxTypeToString
} from "./../../util/money";
import { observable } from "mobx";
import { Select } from "../form/Select";
import Button from "../Button";
import { observer } from "mobx-react";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

export function emptyTpiLineItem(ccyIsoName: ISOCurrencyName): ThirdPartyInvoiceLineItem.AsObject {
  return {
    code: ccyIsoName.toString(),
    description: "",
    charge: chargeZero(ccyIsoName),
    taxType: TaxType.INCLUDED
  };
}

export const lineItemSchema = Yup.object<ThirdPartyInvoiceLineItem.AsObject>({
  code: Yup.string().default("-"),
  description: Yup.string().required("The item description is required"),
  charge: Yup.object<Charge.AsObject>().required("Fee is required"),
  taxType: Yup.lazy(() => Yup.mixed())
});

interface Props {
  initial?: ThirdPartyInvoiceLineItem.AsObject;
  onChange: (item: ThirdPartyInvoiceLineItem.AsObject) => void;
  onError?: (error: string | []) => void;
  onRequestDelete?: () => void;
  dfltCcy: ISOCurrencyName;
}

@observer
export class NewLineItemForm extends React.Component<Props> {
  @observable
  private taxType = TaxType.INCLUDED;
  private tpiLineItem = new ThirdPartyInvoiceLineItem().toObject();

  private onSubmit = () => {};

  render() {
    const Form = createForm<ThirdPartyInvoiceLineItem.AsObject>();
    var initialValue;
    const { initial } = this.props;
    if (initial) {
      initialValue = initial;
      this.tpiLineItem.charge = initial.charge;
      this.tpiLineItem.code = initial.code;
      this.tpiLineItem.description = initial.description;
      this.tpiLineItem.taxType = initial.taxType;
      this.taxType = initial.taxType;
    } else {
      initialValue = {
        charge: chargeZero(this.props.dfltCcy)
      };
    }

    const { onChange, onError } = this.props;
    return (
      <ItemContainer>
        <Form initial={initialValue} schema={lineItemSchema} onSubmit={this.onSubmit}>
          {({ fields, updateField, errors }) => (
            <>
              <Section>
                <Label>Code</Label>
                <Input
                  type="text"
                  placeholder="Enter a code for this item"
                  value={fields.code}
                  onChange={(event: any) => {
                    updateField({ code: event.currentTarget.value });
                    this.tpiLineItem.code = event.currentTarget.value;
                    onChange(this.tpiLineItem);
                  }}
                />
                {!!errors.code && <Error>{errors.code}</Error>}
              </Section>
              <Section>
                <Label>Description</Label>
                <Input
                  type="text"
                  placeholder="Enter a description for this item"
                  value={fields.description}
                  onChange={(event: any) => {
                    updateField({ description: event.currentTarget.value });
                    this.tpiLineItem.description = event.currentTarget.value;
                    this.props.onChange(this.tpiLineItem);
                    if (errors.description && onError) {
                      onError(errors.description);
                    }
                  }}
                />
                {!!errors.description && <Error>{errors.description}</Error>}
              </Section>
              {/* can probably take the below fee and tax inputs */}
              {/* and create a reusable component so we can reuse them elsewhere */}
              <FormSplitContainer>
                <FormSplitItem>
                  <Label>Fee</Label>
                  <Input
                    type="number"
                    placeholder="0.00"
                    defaultValue={chargeToFloat(fields.charge!, this.taxType) || null}
                    // defaultValue={ moneyToFloat(add(fields.charge!.amount!,fields.charge!.tax!))}
                    onChange={(event: any) => {
                      this.tpiLineItem.charge = chargeFromFloat(
                        this.props.dfltCcy,
                        event.currentTarget.valueAsNumber,
                        this.taxType
                      );
                      this.props.onChange(this.tpiLineItem);
                      updateField({
                        charge: chargeFromFloat(
                          this.props.dfltCcy,
                          event.currentTarget.valueAsNumber,
                          this.taxType
                        )
                      });
                    }}
                  />
                  {!!errors.charge && <Error>{errors.charge}</Error>}
                </FormSplitItem>
                <FormSplitItem>
                  <Label>Tax</Label>
                  <Select
                    value={this.taxType}
                    values={[
                      {
                        label: taxTypeToString(TaxType.INCLUDED),
                        value: TaxType.INCLUDED
                      },
                      {
                        label: taxTypeToString(TaxType.EXCLUDED),
                        value: TaxType.EXCLUDED
                      },
                      {
                        label: taxTypeToString(TaxType.EXEMPT),
                        value: TaxType.EXEMPT
                      }
                    ]}
                    onChange={(option) => {
                      this.taxType = option.value;
                      updateField({
                        charge: chargeFromFloat(
                          this.props.dfltCcy,
                          moneyToFloat(fields.charge!.amount!),
                          this.taxType
                        )
                      });
                    }}
                  />
                </FormSplitItem>
              </FormSplitContainer>
              {this.props.onRequestDelete && (
                <CenterContainer>
                  <Button color="secondary" type="button" onClick={this.props.onRequestDelete}>
                    Remove
                  </Button>
                </CenterContainer>
              )}
            </>
          )}
        </Form>
      </ItemContainer>
    );
  }
}

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
