
import { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Transition from "react-transition-group/Transition";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import { format, isSameDay, addDays, parse } from "date-fns";
import qs from "qs";
import memoize from "memoize-one";

import { getResource } from "./../../../redux/features/resources/helpers";

// components
import Section from "./../../../components/form/Section";
import AutoComplete from "./../../../components/form/SearchInput";
import Button from "./../../../components/Button";
import Divider from "./../../../components/Divider";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import { IFrameCalendar } from "./Calendar";
import CheckRadioBox from "./../../../components/form/CheckRadioBox";
import Select from "./../../../components/form/BaseSelect";

// utils
import { colors, breakpoints } from "./../../../util/consts";
import typography from "./../../../util/typography";
import Error from "./../../../components/form/Error";

const smallButton = css`
  height: 30px;
  width: 80px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin: 4px;
`;

const smallButtonText = css`
  ${typography.caption};
  color: ${colors.whiteText.highEmphasis};
  line-height: 18px;
`;

const wideButton = css`
  height: 30px;
  width: 256px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`;

const duration = 300;
const leftBaseWidth = 332;

function calcMinsFromMidnight(time) {
  const [hrs, mins, _] = time.split(":").map((v) => parseFloat(v));
  return hrs * 60 + mins;
}

class InitialPage extends PureComponent {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = debounce((e) => {
    this.setState({ width: window.innerWidth });
  }, 100);

  getLeftStyles = () => {
    const { width } = this.state;

    return {
      entering: {
        transition: `width ${duration}ms ease`,
        width: width < 744 ? "100%" : leftBaseWidth
      },
      entered: {
        width: width < 744 ? "100%" : leftBaseWidth
      },
      exiting: {
        transition: `width ${duration}ms ease`,
        width: width < 744 ? "100%" : width
      },
      exited: {
        width: width < 744 ? "100%" : width
      }
    };
  };

  getRightStyles = () => {
    const { width } = this.state;

    return {
      entering: {
        transition: `width ${duration}ms ease`,
        width: width < 744 ? "100%" : width - leftBaseWidth
      },
      entered: {
        width: width < 744 ? "100%" : width - leftBaseWidth
      },
      exiting: {
        transition: `width ${duration}ms ease`,
        width: 0
      },
      exited: {
        width: 0,
        display: "none"
      }
    };
  };

  getDateString = (date) => {
    if (isSameDay(date, new Date())) {
      return "Today";
    } else if (isSameDay(date, addDays(new Date(), 1))) {
      return "Tomorrow";
    } else {
      return format(date, "EEEE do MMM");
    }
  };

  getSpotsLeft = (cur, max) => {
    let spotsString = "";
    let spotsLeft = max - cur;
    if (spotsLeft <= 3 && spotsLeft >= 1) {
      spotsString = " | " + spotsLeft + " Left";
    } else if (spotsLeft == 0) {
      spotsString = " | Full";
    } else {
    }
    return spotsString;
  };

  atoReducer = (acc, cur) => ({ ...acc, [cur.Date]: cur.Times });
  uniqueFilter = (value, index, self) => self.indexOf(value) === index;

  getAnyGroupTimes = memoize((Providers) =>
    Providers.reduce((acc, cur) => [...acc, ...cur.GroupTimes], [])
  );

  renderServicesDropdownIndividual = () => {
    const { setFieldValue, values, touched, errors, resource, Loc } = this.props;

    const isOrgBooking = resource.$Metadata.Type === "Organisation";
    const Services = isOrgBooking ? Loc && Loc.Services : resource && resource.Services;

    if (!Services) return null;
    // eslint-disable-line curly
    else if (!Array.isArray(Services)) return null; // eslint-disable-line curly
    return (
      <Section>
        <Select
          name="Offering"
          type="select"
          value={values.OfferingID}
          onChange={(e) => {
            setFieldValue("Offering", e.currentTarget.value);
          }}
        >
          {Services.map((o) => (
            <option key={o.ID} value={o.ID}>
              {o.Name +
                " | " +
                o.ServiceDuration +
                "min | $" +
                o.Fee +
                (o.Level ? " | " + o.Level[0] + o.Level.slice(1).toLowerCase() : "")}
            </option>
          ))}
        </Select>
        {!!errors.Offering && touched.Offering && <Error>{errors.Offering}</Error>}
      </Section>
    );
  };

  renderServicesDropdownGroup = (GroupOfferingOptions) => {
    const { setFieldValue, values, touched, errors, resource, Loc } = this.props;
    return (
      <Section>
        <Select
          name="Offering"
          type="select"
          value={values.OfferingID}
          onChange={(e) => {
            setFieldValue("OfferingID", e.currentTarget.value);
          }}
        >
          <Fragment>
            <option value="none">None</option>
            {GroupOfferingOptions.map((o) => (
              <option key={o.ID} value={o.ID}>
                {o.Name +
                  " | " +
                  o.ServiceDuration +
                  "min | $" +
                  o.Fee +
                  (o.Level ? " | " + o.Level[0] + o.Level.slice(1).toLowerCase() : "")}
              </option>
            ))}
          </Fragment>
        </Select>
        {!!errors.Offering && touched.Offering && <Error>{errors.Offering}</Error>}
      </Section>
    );
  };

  renderProvidersDropdownIndividual = () => {
    const { setFieldValue, values, touched, errors, resource, Loc } = this.props;

    const isOrgBooking = resource.$Metadata.Type === "Organisation";
    const Services = isOrgBooking ? Loc && Loc.Services : resource && resource.Services;

    if (!Services) return null;
    // eslint-disable-line curly
    else if (!Array.isArray(Services)) return null; // eslint-disable-line curly

    const Providers = isOrgBooking ? Loc && Loc.Providers : resource && resource.Providers;

    if (!Providers) return null;
    // eslint-disable-line curly
    else if (!Array.isArray(Providers)) return null; // eslint-disable-line curly

    const offeringSelectedItem = Services && Services.find((s) => s.ID === values.OfferingID);

    let ProviderOptions = (offeringSelectedItem
      ? Providers.filter((p) =>
          p.ProviderCategories.find(
            (pc) =>
              typeof pc !== "undefined" &&
              offeringSelectedItem.Categories.find((c) =>
                typeof c === "object" ? pc.ID === c.ID : pc.ID === c
              )
          )
        )
      : Providers
    ).map((p) => ({
      label: `${p.User.FirstName} ${p.User.LastName}`,
      value: p.User.ID
    }));

    return (
      <Section>
        {values.OfferingID ? (
          <Select
            name="Provider"
            type="select"
            value={values.ProviderID}
            onChange={(e) => {
              setFieldValue("Provider", e.currentTarget.value);
            }}
          >
            {offeringSelectedItem && (
              <Fragment>
                <option value="any">Any</option>
                {ProviderOptions.map((p) => p && <option value={p.value}>{p.label}</option>)}
              </Fragment>
            )}
          </Select>
        ) : (
          <Fragment />
        )}
        {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
      </Section>
    );
  };

  renderProvidersDropdownGroup = (GroupProviderOptions) => {
    const { setFieldValue, values, touched, errors, resource, Loc } = this.props;
    return (
      <Section>
        <Select
          name="Provider"
          type="select"
          value={values.ProviderID}
          onChange={(e) => {
            setFieldValue("ProviderID", e.currentTarget.value);
          }}
        >
          <Fragment>
            <option value="any">Any</option>
            {GroupProviderOptions.map((gp) => (
              <option key={gp.User.ID} value={gp.User.ID}>
                {`${gp.User.FirstName} ${gp.User.LastName}`}
              </option>
            ))}
          </Fragment>
        </Select>
        {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
      </Section>
    );
  };

  filterFreeTimesForServiceDuration = memoize((freeTimes, service) => {
    const INTERVAL_TIME = 15;
    if (!freeTimes) {
      return [];
    }
    if (!service) {
      return freeTimes;
    }
    return freeTimes.map((ft) => ({
      ...ft,
      Times: ft.Times.filter((_, i) => {
        const thisTime = calcMinsFromMidnight(ft.Times[i]);
        const numIntervals = Math.ceil(service.ServiceDuration / INTERVAL_TIME);
        if (i + numIntervals > ft.Times.length) {
          return false;
        }
        for (let j = 1; j < numIntervals; j++) {
          if (thisTime + INTERVAL_TIME * j !== calcMinsFromMidnight(ft.Times[i + j])) {
            return false;
          }
        }
        return true;
      })
    }));
  });

  getAnyGroupTimes = (providers, offering) => {
    let GroupTimes = [];
    let Providers = [];
    let Services = [];
    var i, j, k;
    for (i in providers) {
      for (j in providers[i].GroupTimes) {
        for (k in providers[i].GroupTimes[j].GroupOfferings) {
          let notExpired = providers[i].GroupTimes[j]
            ? new Date(
                providers[i].GroupTimes[j].StartDate + "T" + providers[i].GroupTimes[j].StartTime
              ).getTime() > Date.now()
            : false;
          if (Services.length === 0) {
            if (notExpired) {
              Services.push(providers[i].GroupTimes[j].GroupOfferings[k]);
            }
          } else {
            if (notExpired) {
              if (
                !Services.some(
                  (services) => services.ID === providers[i].GroupTimes[j].GroupOfferings[k].ID
                )
              ) {
                Services.push(providers[i].GroupTimes[j].GroupOfferings[k]);
              }
            }
          }
          if (providers[i].GroupTimes[j].GroupOfferings[k].ID == offering && notExpired) {
            GroupTimes.push(providers[i].GroupTimes[j]);
            if (!Providers.includes(providers[i])) {
              Providers.push(providers[i]);
            }
          }
        }
      }
    }
    return { GroupTimes, Providers, Services };
  };

  getAnyIndividualTimes = (providers, offering) => {
    let IndividualTimes = [];
    let Providers = [];
    let Services = [];
    var i, j, k, l, m;
    for (i in providers) {
      for (j in providers[i].ProviderCategories) {
        if (offering) {
          for (k in offering.Categories) {
            if (providers[i].ProviderCategories[j]) {
              if (providers[i].ProviderCategories[j].ID === offering.Categories[k]) {
                if (!Providers.includes(providers[i])) {
                  Providers.push(providers[i]);
                }
              }
            }
          }
        }
      }
    }
    for (l in Providers) {
      for (m in Providers[l].FreeTimes) {
        IndividualTimes.push(Providers[l].FreeTimes[m]);
      }
    }
    return { IndividualTimes, Providers, Services };
  };

  getServicesForProvider = (Services, Providers) => {
    let y = [];
    var i1, j1, k1;
    for (i1 in Services) {
      for (j1 in Services[i1].Categories) {
        for (k1 in Providers[0].ProviderCategories) {
          if (Services[i1].Categories[j1] === Providers[0].ProviderCategories[k1].ID) {
            if (!y.includes(Services[i1])) {
              y.push(Services[i1]);
            }
          }
        }
      }
    }
    return y;
  };

  render() {
    const {
      resource,
      Loc,
      values,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
      date,
      setDate
    } = this.props;
    const isOrgBooking = resource.$Metadata.Type === "Organisation";
    let Providers = isOrgBooking ? Loc && Loc.Providers : resource && resource.Providers;
    values.Providers = Providers;
    const Services = isOrgBooking ? Loc && Loc.Services : resource && resource.Services;
    values.Services = Services;
    // if the user clicks on a particular provider then implement these changes
    if (this.props.location.search && Providers[0].ProviderCategories[0]) {
      let x = Providers.find(
        (provider) => provider.User.ID === this.props.location.search.split("=").pop()
      );
      Providers = [x];
      let y = this.getServicesForProvider(Services, Providers);
      resource.Services = y;

      // let y = resource.Services.find((service)=> service.Categories.find((serviceCategory)=> Providers[0].ProviderCategories.find((providerCategories) => serviceCategory === providerCategories.ID) ))
    }

    const choosenProviser = Providers && Providers.find((p) => p.User.ID === values.ProviderID);
    const choosenProviderTimes = choosenProviser ? choosenProviser.FreeTimes : [];
    // Really should do change everything to an obj

    const offeringSelectedItem = Services && Services.find((s) => s.ID === values.OfferingID);
    // this function gets ALL OF THE freetimes and list of providers for each service
    let IndividualProviderOptions = this.getAnyIndividualTimes(Providers, offeringSelectedItem);

    // times for individual search
    const FreeTimes = this.filterFreeTimesForServiceDuration(
      values.ProviderID === "any" // eslint-disable-line no-nested-ternary
        ? IndividualProviderOptions.IndividualTimes
        : choosenProviderTimes,
      ((isOrgBooking ? Loc && Loc.Services : resource.Services) || []).find(
        (v) => v.ID === values.OfferingID
      )
    );
    let GroupTimes =
      values.ProviderID === "any"
        ? this.getAnyGroupTimes(Providers, values.OfferingID).GroupTimes
        : choosenProviser
        ? choosenProviser.GroupTimes
        : [];
    let GroupProviderOptions = this.getAnyGroupTimes(Providers, values.OfferingID).Providers;
    let GroupOfferingOptions = this.getAnyGroupTimes(Providers, values.OfferingID).Services;
    let showTimes = Boolean(values.Location && values.OfferingID && values.ProviderID);
    if (values.BookingType === "Group" && GroupTimes.length === 0) {
      showTimes = false;
    }
    if (values.BookingType === "Individual" && FreeTimes.length === 0) {
      showTimes = false;
    }

    return (
      <Container
        isBookingPage={this.props.location.pathname.split("/").find((el) => el === "bookings")}
        isInternalIFrame={values.IsInternalIFrame}
        onSubmit={handleSubmit}
      >
        <Transition in={showTimes} timeout={duration}>
          {(s) => (
            <Fragment>
              <LeftContainer style={{ ...this.getLeftStyles()[s] }}>
                <LeftContent>
                  {!values.IsInternalIFrame && (
                    <ContainerHeader>
                      <Logo
                        src={
                          isOrgBooking ? resource.Logo.DownloadURL : resource.Org.Logo.DownloadURL
                        }
                      ></Logo>
                      <Title>{resource.Name}</Title>
                    </ContainerHeader>
                  )}
                  {isOrgBooking && (
                    <Fragment>
                      <ResourceFetch
                        type="Location"
                        ids={values.Location}
                        extraData={{ StartDate: date }}
                        noLoginReq={true}
                        force
                      />
                      <Section>
                        <AutoComplete
                          name="Location"
                          items={resource.Locations.map((l) => ({ label: l.Name, value: l.ID }))}
                          setFieldValue={setFieldValue}
                          placeholder="Select a Location..."
                          containerStyle={{
                            height: "37px"
                          }}
                          inputStyle={{
                            fontSize: "18.85px",
                            fontWeight: "550",
                            letterSpacing: "0.16px"
                          }}
                          hasClearButton
                        />
                      </Section>
                    </Fragment>
                  )}
                  <Fragment>
                    <LeftSectionTitle>Service</LeftSectionTitle>
                    {values.BookingType === "Individual"
                      ? this.renderServicesDropdownIndividual()
                      : this.renderServicesDropdownGroup(GroupOfferingOptions)}
                    <LeftSectionTitle>Trainer</LeftSectionTitle>
                    {values.BookingType === "Individual"
                      ? this.renderProvidersDropdownIndividual()
                      : this.renderProvidersDropdownGroup(GroupProviderOptions)}
                  </Fragment>
                  {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== "US" && (
                    <Fragment>
                      <LeftSectionTitle>Booking Type</LeftSectionTitle>
                      <Section>
                        <FormSplitContainer>
                          <FormSplitItem>
                            <CheckRadioBox
                              name="BookingType"
                              value="Individual"
                              type="radio"
                              checked={values.BookingType === "Individual"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              Individual
                            </CheckRadioBox>
                          </FormSplitItem>
                          <FormSplitItem>
                            <CheckRadioBox
                              name="BookingType"
                              value="Group"
                              type="radio"
                              checked={values.BookingType === "Group"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              Group
                            </CheckRadioBox>
                          </FormSplitItem>
                        </FormSplitContainer>
                      </Section>
                    </Fragment>
                  )}
                  <Divider style={{ margin: "30px 0" }} />
                  <IFrameCalendar date={new Date(date)} onChange={setDate} />
                </LeftContent>
              </LeftContainer>
              <RightContainer style={{ ...this.getRightStyles()[s] }}>
                <RightContent>
                  <ContainerHeader>
                    <Title>Select the time you would like to book</Title>
                  </ContainerHeader>
                  {(values.ProviderID === "any" && values.BookingType !== "Group") ||
                  values.BookingType === "Individual" ? (
                    FreeTimes.length > 0 &&
                    FreeTimes.map((ft, i) => {
                      const DateString = this.getDateString(new Date(ft.Date));

                      if (i === 0 && ft.Times.length > 1) {
                        return (
                          <Fragment key={i}>
                            <div>
                              <SectionTitle>Next Available</SectionTitle>
                              <Button
                                variant="contained"
                                color="secondary"
                                css={wideButton}
                                onClick={() => setFieldValue("Time", `${ft.Date}T${ft.Times[0]}`)}
                                type="submit"
                              >
                                <span css={smallButtonText}>
                                  {DateString} at{" "}
                                  {format(parse(ft.Times[0], "HH:mm:ss", new Date()), "hh:mmaaaaa")}
                                  m
                                </span>
                              </Button>
                            </div>
                            <Divider style={{ margin: "30px 0" }} />
                            <div>
                              <SectionTitle>{DateString}</SectionTitle>
                              <ButtonsContainer>
                                {ft.Times.slice(1).map((t, j) => (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    css={smallButton}
                                    key={j}
                                    onClick={() => setFieldValue("Time", `${ft.Date}T${t}`)}
                                    type="submit"
                                  >
                                    <span css={smallButtonText}>
                                      {format(parse(t, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m
                                    </span>
                                  </Button>
                                ))}
                              </ButtonsContainer>
                            </div>
                            <Divider style={{ margin: "30px 0" }} />
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment>
                            <div key={i}>
                              <SectionTitle>{DateString}</SectionTitle>
                              <ButtonsContainer>
                                {ft.Times.map((t, j) => (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    css={smallButton}
                                    key={j}
                                    onClick={() => setFieldValue("Time", `${ft.Date}T${t}`)}
                                    type="submit"
                                  >
                                    <span css={smallButtonText}>
                                      {format(parse(t, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m
                                    </span>
                                  </Button>
                                ))}
                              </ButtonsContainer>
                            </div>
                            <Divider style={{ margin: "30px 0" }} />
                          </Fragment>
                        );
                      }
                    })
                  ) : (
                    <Fragment></Fragment>
                  )}

                  {values.BookingType === "Group" && GroupTimes.length > 0 ? (
                    GroupTimes.filter(
                      (gt) => new Date(gt.StartDate + "T" + gt.StartTime).getTime() > Date.now()
                    ).map((gt, i) => (
                      <Fragment key={i}>
                        <div>
                          <SectionTitle>{this.getDateString(new Date(gt.StartDate))}</SectionTitle>
                          <Button
                            variant="contained"
                            color="primary"
                            css={wideButton}
                            onClick={() => {
                              setFieldValue("Time", `${gt.StartDate}T${gt.StartTime}`);
                              setFieldValue("BookingID", gt.ID);
                            }}
                            type="submit"
                          >
                            <span css={smallButtonText}>
                              {this.getDateString(new Date(gt.StartDate))} at{" "}
                              {format(parse(gt.StartTime, "HH:mm:ss", new Date()), "hh:mmaaaaa")}m{" "}
                              {this.getSpotsLeft(gt.CurGroupSize, gt.MaxGroupSize)}
                            </span>
                          </Button>
                        </div>
                        <Divider style={{ margin: "30px 0" }} />
                      </Fragment>
                    ))
                  ) : (
                    <Fragment></Fragment>
                  )}
                </RightContent>
              </RightContainer>
            </Fragment>
          )}
        </Transition>
      </Container>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => {
    const query = qs.parse(props.location.search.substring(1));
    const offeringID = props.serviceID || query.Offering || "none";
    const providerID = props.providerID || query.Provider || "any";
    const bookingType =
      process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
        ? "Group"
        : props.bookingType || "Individual";
    return {
      Location: props.resource.$Metadata.Type === "Location" ? props.resource.ID : "",
      OfferingID: offeringID,
      ProviderID: providerID,
      Time: "",
      BookingType: bookingType,
      BookingID: "",
      IsInternalIFrame: props.isInternalIFrame || false
    };
  },
  validationSchema: (props) =>
    Yup.object().shape({
      Location:
        props.resource.$Metadata.Type === "Organisation"
          ? Yup.string().required("Location is required.")
          : Yup.string(),
      OfferingID: Yup.string().required("Offering is required."),
      ProviderID: Yup.string().required("Provider is required."),
      Time: Yup.string().required("Date is required"),
      BookingType: Yup.string().required(""),
      BookingID: Yup.string()
    }),
  handleSubmit: async (values, { props }) => {
    const [date, time] = values.Time.split("T");
    const isOrgBooking = props.resource.$Metadata.Type === "Organisation";
    // // HOTIFX: change #405 providers to be randomised based on the service; couldn't figure out how to use the redux store so I just duplicated functions

    const getAnyIndividualTimes = (providers, offering) => {
      let IndividualTimes = [];
      let Providers = [];
      var i, j, k, l, m;
      for (i in providers) {
        for (j in providers[i].ProviderCategories) {
          if (offering) {
            for (k in offering.Categories) {
              if (providers[i].ProviderCategories[j].ID === offering.Categories[k]) {
                if (!Providers.includes(providers[i])) {
                  Providers.push(providers[i]);
                }
              }
            }
          }
        }
      }
      for (l in Providers) {
        for (m in Providers[l].FreeTimes) {
          IndividualTimes.push(Providers[l].FreeTimes[m]);
        }
      }
      return { IndividualTimes, Providers };
    };

    const getAnyGroupTimes = (providers, offering) => {
      let GroupTimes = [];
      let Providers = [];
      let Services = [];
      var i, j, k;
      for (i in providers) {
        for (j in providers[i].GroupTimes) {
          for (k in providers[i].GroupTimes[j].GroupOfferings) {
            let notExpired = providers[i].GroupTimes[j]
              ? new Date(
                  providers[i].GroupTimes[j].StartDate + "T" + providers[i].GroupTimes[j].StartTime
                ).getTime() > Date.now()
              : false;
            if (notExpired) {
              Services.push(providers[i].GroupTimes[j].GroupOfferings[k]);
            }
            if (providers[i].GroupTimes[j].GroupOfferings[k].ID == offering && notExpired) {
              GroupTimes.push(providers[i].GroupTimes[j]);
              if (!Providers.includes(providers[i])) {
                Providers.push(providers[i]);
              }
            }
          }
        }
      }
      return { GroupTimes, Providers, Services };
    };

    let IndividualProviderOptionsSubmit = {};
    const offeringSelectedItem =
      values.Services && values.Services.find((s) => s.ID === values.OfferingID);

    if (values.BookingType === "Individual") {
      IndividualProviderOptionsSubmit = getAnyIndividualTimes(
        values.Providers,
        offeringSelectedItem
      );
    }
    if (values.BookingType === "Group") {
      IndividualProviderOptionsSubmit = getAnyGroupTimes(values.Providers, values.OfferingID);
    }

    props.setPage("form", {
      Location: values.Location,
      OfferingID: values.OfferingID,
      Booking: values.BookingType === "Group" ? values.BookingID : undefined,
      ProviderID:
        values.BookingType !== "Group"
          ? values.ProviderID === "any"
            ? IndividualProviderOptionsSubmit.Providers.find((provider) =>
                provider.FreeTimes.find(
                  (ft) => ft.Date === date && ft.Times.find((t) => t === time)
                )
              ).User.ID
            : values.ProviderID
          : values.ProviderID === "any"
          ? IndividualProviderOptionsSubmit.Providers.find((provider) =>
              provider.GroupTimes.find((gp) => gp.StartDate === date && gp.StartTime === time)
            ).User.ID
          : values.ProviderID,
      Date: date,
      Time: time
    });
  },
  displayName: "IFInitial"
});

const mapPropsToValues = (state, props) => {
  const { values } = props;
  const { resources } = state;
  return {
    Loc: getResource(resources, "Location", values.Location, true)
  };
};

export default compose(formikEnhancer, connect(mapPropsToValues))(InitialPage);

const Container = styled.form`
  width: 100%;
  min-height: ${({ isBookingPage, isInternalIFrame }) =>
    isInternalIFrame ? "50vh" : isBookingPage ? "calc(100vh - 69px)" : "100vh"};
  display: flex;
  flex-direction: column;

  ${breakpoints["tablet-up"]} {
    flex-direction: row;
  }
`;

const ContainerHeader = styled.div`
  margin-bottom: 43px;
`;

const Logo = styled.img`
  max-width: 100px;
  margin-left: 35%;
  max-height: 100px;
  margin-bottom: 5px;
`;

const LeftContainer = styled.div`
  background-color: ${colors.surface.light};
  padding: 36px 24px;
  // transition: width 0.3s ease;
`;

const LeftContent = styled.div`
  width: 100%;
  margin: 0 auto;

  ${breakpoints["tablet-up"]} {
    width: 284px;
  }
`;

const LeftSectionTitle = styled.div`
  font-size: 14px;
  color: ${colors.surfaceText.highEmphasis};
  letter-spacing: 0.16px;
  font-weight: 600;
  margin-bottom: 18px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  background-color: #f4f8f9;
  padding: 36px 24px;

  ${breakpoints["tablet-up"]} {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  ${breakpoints["laptop-up"]} {
    padding: 44px 80px;
  }
`;

const RightContent = styled.div`
  width: 100%;
`;

const Title = styled.div`
  ${typography.heading5};
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.25px;
  line-height: 19px;
  opacity: 0.6;
  margin-top: 8px;
`;

const SectionTitle = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.19px;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`;

const FormSplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const FormSplitItem = styled.div`
  flex: 1 1 0;

  &:first-of-type {
    margin-right: 12px;
  }
`;
