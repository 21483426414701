import { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import Dropzone from "react-dropzone";

import apiService from "./../../redux/services/api";
import { hexToRgba, colors } from "./../../util/consts";
import typography from "./../../util/typography";

import IconButton from "./../../components/IconButton";
import ClearIcon from "./../../components/icons/Clear";

import { getResource } from "./../../redux/features/resources/helpers";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { ResourceTypes } from "./../../redux/features/resources/definitions";
import { rpc, metadata } from "./../../grpc";
import { CreateMediaRequest } from "sdk/dist/media_pb";
import { readFileBytes } from "./../../util/files";

export const IMAGE_MIMES = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
export const DOCUMENT_MIMES = ["application/pdf"];
export const ALL_MIMES = [...IMAGE_MIMES, ...DOCUMENT_MIMES];

const mimeTypeMap = {
  "image/gif": "Image",
  "image/jpeg": "Image",
  "image/png": "Image",
  "image/bmp": "Image",
  "application/pdf": "Document"
};

function mimeToType(mime: string) {
  if (mime in mimeTypeMap) {
    return mimeTypeMap[mime];
  }
  return "Document";
}

interface Props {
  media: any;
  type?: "avatar" | "banner";
  value: any;
  name: string;
  setFieldValue: Function;
  setFieldTouched: Function;
  setFieldError: Function;
  dispatch: any;
}

class MediaInput extends Component<Props> {
  uploadMediaID: string | null = null;

  state = {
    isUploading: false,
    uploadingFilename: null,
    uploadProgress: 0
  };

  handleUpload = async (acceptedFiles: File[]) => {
    const { name, setFieldValue, setFieldTouched, setFieldError, dispatch } = this.props;

    if (!acceptedFiles[0]) {
      return;
    }

    const file = acceptedFiles[0];

    if (this.uploadMediaID !== null) {
      return;
    }

    this.uploadMediaID = "";

    this.setState({
      isUploading: true,
      uploadingFilename: file.name,
      uploadProgress: 0
    });

    try {
      const req = new CreateMediaRequest();
      req.setFile(await readFileBytes(file));
      req.setFilename(file.name);
      req.setMime(file.type);
      const media = await rpc.media.create(req, metadata());

      // Get the newly uploaded media.
      await dispatch(
        ResourceActions.action(
          { $Metadata: { Type: ResourceTypes.Media } as any, ID: media.getId() },
          "Fetch",
          {}
        )
      );

      this.setState(
        (prevState) => ({
          isUploading: false,
          uploadProgress: 0,
          uploadingFilename: null
        }),
        () => setFieldValue(name, media.getId())
      );
    } catch (error) {
      const apiError = apiService.errorProcess(error);
      this.setState(
        {
          isUploading: false,
          uploadingFilename: null,
          uploadProgress: 0
        },
        () => setFieldError(name, apiError.userMessages)
      );
    }

    setFieldTouched(name, true);
    this.uploadMediaID = null;
  };

  clearMedia = () => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, "");
  };

  render() {
    const { isUploading, uploadingFilename, uploadProgress } = this.state;
    const { media } = this.props;
    const { type, value } = this.props;

    return (
      /* eslint-disable no-nested-ternary */
      <Container type={type}>
        {value && (media.DownloadURL || media.downloadUrl) ? (
          <Image type={type} imageURL={media.DownloadURL || media.downloadUrl}>
            <IconButton
              css={css`
                position: absolute;
                top: 0;
                right: 0;
              `}
              onClick={this.clearMedia}
            >
              <ClearIcon />
            </IconButton>
          </Image>
        ) : isUploading ? (
          <TheZone type={type} isDragActive={false}>
            <TheZoneContent type={type}>
              {type === "banner" && <UploadBarText>Uploading {uploadingFilename}</UploadBarText>}
            </TheZoneContent>
          </TheZone>
        ) : (
          <Dropzone onDrop={this.handleUpload}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <TheZone {...getRootProps()} type={type} isDragActive={isDragActive}>
                <TheZoneContent type={type}>
                  <input {...getInputProps()} />
                  {type === "avatar" ? (
                    <UploadText>Upload photo</UploadText>
                  ) : (
                    <Fragment>
                      <FakeButton>Upload photo</FakeButton>
                      <UploadText>or drag them in</UploadText>
                    </Fragment>
                  )}
                </TheZoneContent>
              </TheZone>
            )}
          </Dropzone>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: any, props: Props) => ({
  media: getResource(state.resources, ResourceTypes.Media, props.value, false)
});

export default connect(mapStateToProps)(MediaInput);

const Container = styled.div<{ type: Props["type"] }>`
  width: ${({ type }) => (type === "avatar" ? "132px" : "100%")};
  height: ${({ type }) => (type === "avatar" ? "132px" : "300px")};
  border-radius: 4px;
  overflow: hidden;
`;

interface ImageProps {
  imageURL: string;
  type: Props["type"];
}

const Image = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  background-image: url(${({ imageURL }) => imageURL});
  background-position: center;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: ${({ type }) => (type === "avatar" ? "inherit" : "none")};
    background: radial-gradient(
      circle at center,
      transparent,
      transparent 70%,
      rgba(255, 255, 255, 0.9) 71%
    );
  }
`;

const UploadBarText = styled.div`
  ${typography.caption};
  margin-bottom: 6px;
`;

interface ZoneProps {
  type: Props["type"];
  isDragActive: boolean;
}

const TheZone = styled.div<ZoneProps>`
  width: 100%;
  height: 100%;

  cursor: pointer;
  padding ${({ type }) => (type === "avatar" ? "0" : "20px")};
  border: 2px ${({ isDragActive }) => (isDragActive ? "solid" : "dashed")} ${hexToRgba(
    colors.primary.main,
    0.3
  )};
  border-radius: 4px;
`;

const TheZoneContent = styled.div<{ type: Props["type"] }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ type }) => (type === "avatar" ? "50%" : "4px")};
  background-color: ${hexToRgba(colors.primary.main, 0.02)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FakeButton = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.09px;
  color: ${colors.whiteText.highEmphasis};
  height: 48px;
  width: 234px;
  border-radius: 34.5px;
  background-color: ${colors.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

const UploadText = styled.div`
  ${typography.subtitle1};
  letter-spacing: 0.09px;
  line-height: 22px;
`;
