import styled from "@emotion/styled";
import * as fns from "date-fns";
import debounce from "lodash.debounce";
import { computed } from "mobx";
import { observer } from "mobx-react";
import qs from "qs";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AdminSubHeader from "../../components/elements/AdminSubHeader";
import Calendar from "../../components/elements/Calendar";
import ResourceFetch from "../../components/elements/ResourceFetch";
// Components
import ResourceListFetch from "../../components/elements/ResourceListFetch";
import ResourceListRender from "../../components/elements/ResourceListRender";
import ResourcePageError from "../../components/elements/ResourcePageError";
import ResourceRender from "../../components/elements/ResourceRender";
import ResourceTable from "../../components/elements/ResourceTable";
import UserBlockDeprecated from "../../components/elements/UserBlock";
import { rebookDataStore } from '../../mobx/stores/RebookDataStore';
import * as ReduxDialogs from "../../redux/features/dialogs";
// Redux
import { getResource } from "../../redux/features/resources/helpers";
import { accItemStore } from "../../stores/acc_item-store";
import { bookingStore } from "../../stores/booking-store";
import { daysOfWeek } from "../../util";
import { endOfDayUTCTimestamp, startOfDayUTCTimestamp } from "../../util/time";
// Utils
import typography from "../../util/typography";





@observer
class OrgLocationSchedule extends PureComponent {
  getNumColumns = (width) => {
    const minColumnWidth = 180;
    const maxNumColumns = 7;
    const numPosColumns = Math.floor(width / minColumnWidth) - 1;
    if (numPosColumns > maxNumColumns) {
      return maxNumColumns;
    } else {
      return numPosColumns;
    }
  };

  tableRef = React.createRef();

  state = {
    date: new Date(),
    numColumns: this.getNumColumns(window.innerWidth),
    providerIndex: 0,
    filter: {
      Selector: "Upcoming",
      SortBy: "Time",
      SortDir: "Desc"
    }
  };

  componentDidMount = async () => {
    window.addEventListener("resize", this.resizeEvent);
    await bookingStore.loadByLocation(this.props.loc.ID, startOfDayUTCTimestamp(this.state.date), endOfDayUTCTimestamp(this.state.date));
    await accItemStore.loadByLocation(this.props.loc.ID, startOfDayUTCTimestamp(this.state.date), endOfDayUTCTimestamp(this.state.date));
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
  };

  @computed
  get bookingsLocation() {
    return bookingStore.all().filter((bk) => bk.locationId === this.props.loc.ID);
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.filter.Location !== props.loc.ID) {
      return {
        filter: {
          ...state.filter,
          Location: props.loc.ID
        }
      };
    }
    return null;
  };

  resizeEvent = debounce(() => {
    this.setState({ numColumns: this.getNumColumns(window.innerWidth) });
  }, 150);

  setDate = async (newDate) => {
    this.setState({ date: newDate });
    bookingStore.deleteAll();
    await bookingStore.loadByLocation(this.props.loc.ID, startOfDayUTCTimestamp(newDate), endOfDayUTCTimestamp(newDate));
    await accItemStore.loadByLocation(this.props.loc.ID, startOfDayUTCTimestamp(newDate), endOfDayUTCTimestamp(newDate));
  };

  nextPage = async () => {
    this.setState((prevState, props) => {
      const newProviderIndex = prevState.providerIndex + prevState.numColumns;
      return {
        providerIndex:
          newProviderIndex > props.providers.length - 1 ? prevState.providerIndex : newProviderIndex
      };
    });
  };

  prevPage = async () => {
    this.setState((prevState) => {
      const newProviderIndex = prevState.providerIndex - prevState.numColumns;
      return {
        providerIndex: newProviderIndex < 0 ? 0 : newProviderIndex
      };
    });
  };

  handleSubPage = (sp) => {
    this.setState({
      filter: {
        ...this.state.filter,
        Selector: sp
      }
    });
  };

  handleSort = (sortColumn, sortDesc) => {
    this.setState({
      filter: {
        ...this.state.filter,
        SortBy: sortColumn,
        SortDir: sortDesc ? "Desc" : "Asc"
      }
    });
  };

  handleSearch = (val) => {
    this.props.history.replace(`?view=table${this.props.history.location.hash}`);
    this.setState({
      filter: {
        ...this.state.filter,
        Search: val
      }
    });
  };

  handleSelect = (res) => {
    this.props.dispatch(ReduxDialogs.openAppointment(res.ID, "", false, () => {}));
  };

  handleAdd = (e) => {
    const trigger = e.target;
    this.props.dispatch(
      ReduxDialogs.openNewBooking(trigger, this.props.loc, () => {
        if (this.tableRef.current) {
          this.tableRef.current.refreshTable();
        }
      })
    );
  };

  switchViewToCalendar = () => {
    this.props.history.replace(`?view=calendar${this.props.history.location.hash}`);
  }

  switchViewToTable = () => {
    this.props.history.replace(`?view=table${this.props.history.location.hash}`);
  }

  render() {
    const { date, numColumns, providerIndex, filter } = this.state;
    const { providers, location, loc, history, query, ...otherProps } = this.props;

    const { view } = qs.parse(query.substring(1));

    if (view === "calendar") {
      const numProviderPages = Math.ceil(providers.length / numColumns);
      const shownProviders =
        providers.length > numColumns
          ? providers.slice(providerIndex, providerIndex + numColumns)
          : providers;
      const test =
        shownProviders.length < numColumns
          ? [...shownProviders, ...new Array(numColumns - shownProviders.length).fill(null)]
          : shownProviders;

      const currentPage = Math.floor(providerIndex / numColumns) + 1;

      const day = daysOfWeek[fns.getDay(date)];
      const openingHours = {
        ClosesAt:
          shownProviders.reduce((acc, curr) => {
            if (!curr || !curr.ProviderSchedule) { return acc; }
            const { ClosesAt } = curr.ProviderSchedule[day];
            return !acc || (ClosesAt && ClosesAt.localeCompare(acc) > 0) ? ClosesAt : acc;
          }, null) || "17:00:00",
        OpensAt:
          shownProviders.reduce((acc, curr) => {
            if (!curr || !curr.ProviderSchedule) { return acc; }
            const { OpensAt } = curr.ProviderSchedule[day];
            return !acc || (OpensAt && OpensAt.localeCompare(acc) < 0) ? OpensAt : acc;
          }, null) || "09:00:00"
      };

      return (
        <>
          <AdminSubHeader
            type="calendar"
            date={date}
            setDate={this.setDate}
            weeklyView={false}
            switchView= {this.switchViewToTable}
            isRebooking={rebookDataStore.isRebooking}
            settings={[
              {
                label: "Switch to Table View",
                onClick: () => history.replace(`?view=table${history.location.hash}`)
              }
            ]}
            onAdd={this.handleAdd}
            // onSearch={this.handleSearch}
          />
          <Calendar
            date={date}
            providers={test}
            openingHours={openingHours}
            setDate={this.setDate}
            numPages={numProviderPages}
            currentPage={currentPage}
            prevPage={this.prevPage}
            nextPage={this.nextPage}
            history={history}
            location={loc}
            bookings={this.bookingsLocation}
            {...otherProps}
          />
        </>
      );
    } else if (view === "table") {
      return (
        <>
          <AdminSubHeader
            type="table"
            subHeaders={["Upcoming", "Past"]}
            onSubPage={this.handleSubPage}
            switchView={this.switchViewToCalendar}
            isRebooking={rebookDataStore.isRebooking}
            settings={[
              {
                label: "Switch to Calendar View",
                onClick: () => history.replace(`?view=calendar${history.location.hash}`)
              }
            ]}
            onAdd={this.handleAdd}
            onSearch={this.handleSearch}
          />
          <ResourceTable
            ref={this.tableRef}
            listType="LocationBookingListPage"
            resourceType="LocBooking"
            filter={filter}
            onSort={this.handleSort}
            columns={["Time", "FriendlyID", "Type", "ProviderInfo", "ClientInfo", "Status"]}
            columnInfo={{
              Time: {
                type: "Component",
                label: "Time",
                weight: 1,
                min: 200,
                component: React.memo(({ resource: res }) => {
                  const rdate = fns.format(fns.parseISO(res.StartDateTimeTZ), 'EEEE, dd/MM/yyyy')
                  const stime = fns.format(fns.parseISO(res.StartDateTimeTZ), "hh:mma")
                  const etime = fns.format(fns.parseISO(res.EndDateTimeTZ), "hh:mma")
                  return (
                    <DateTimeContainer>
                      <DateContent>{rdate}</DateContent>
                      <TimeContent>
                        {stime} - {etime}
                      </TimeContent>
                    </DateTimeContainer>
                  );
                }),
                onClick: this.handleSelect
              },
              FriendlyID: {
                type: "Label",
                label: "ID",
                weight: 0.2,
                min: 110,
                max: 120,
                format: (res) => "#" + res.FriendlyID,
                onClick: this.handleSelect,
                noOrder: true
              },
              Type: {
                type: "Label",
                label: "Type",
                weight: 0.2,
                min: 150,
                max: 200,
                format: (res) => res.Type,
                onClick: this.handleSelect,
                noOrder: true
              },
              ProviderInfo: {
                type: "Component",
                label: "Provider Info",
                weight: 1.5,
                min: 200,
                component: React.memo(({ resource: res }) => {
                  const offerings =
                    res.Type === "Single" ? (res.Clients[0] != undefined && res.Clients[0].Offerings) : res.GroupOfferings;
                  let numServices = 0;
                  let firstService = {Offering: { Name: "" }};
                  if (Array.isArray(offerings)){
                    numServices = offerings.reduce(
                      (a, v) => a + (v.Offering.Type === "Service" ? 1 : 0),
                      0
                    );
                    firstService = offerings.find((v) => v.Offering.Type === "Service") || {
                      Offering: { Name: "" }
                    };
                  }
                  return (
                    <StyleUserBlock
                      user={res.Provider}
                      alt={numServices > 1 ? "Multiple Services" : firstService.Offering.Name}
                      size="small"
                    />
                  );
                }),
                onClick: this.handleSelect
              },
              ClientInfo: {
                type: "Component",
                label: "Client Info",
                weight: 1.5,
                min: 200,
                component: React.memo(({ resource: res }) =>
                  (res.Type !== "Single" ? (
                    <DateContent>{res.Clients.length} Clients</DateContent>
                  ) : (
                    <StyleUserBlock
                      type="Client"
                      user={res.Clients[0] != undefined && res.Clients[0].Client}
                      alt={res.Clients[0] != undefined && (res.Clients[0].Client.MobileNumber || res.Clients[0].Client.Email)}
                      size="small"
                    />
                  ))
                ),
                onClick: this.handleSelect,
                noOrder: true
              },
              Status: {
                type: "Pill",
                label: "Status",
                weight: 0.1,
                min: 170,
                max: 200,
                // format: (res) => { bookingsUtil.renderBookingClientStatus(res)},
                noOrder: true
              }
            }}
            rowOptions={[
              {
                label: "Edit",
                onClick: this.handleSelect
              }
            ]}
          />
        </>
      );
    } else {
      return <Redirect to={location.pathname + "?view=calendar"} />; // Temporarily.
    }
  }
}

const mapStateToProps = (state, props) => ({
  providers: (props.list.IDs || []).map((id) =>
    getResource(state.resources, "OrganisationUser", id, true).Locations.find(
      (l) => l.Location.ID === props.loc.ID
    )
  )
});

const ConnectedOrgLocationSchedule = connect(mapStateToProps)(OrgLocationSchedule);

const OrgLocationScheduleContainer = React.memo(({ resource, location, history, query }) => (
  <>
    <ResourceListFetch type="AppointmentProvidersPage" listctx={{ Location: resource.ID }} force />
    <ResourceListRender
      type="AppointmentProvidersPage"
      listctx={{ Location: resource.ID }}
      ctx={"asdasd"}
      compSuccess={ConnectedOrgLocationSchedule}
      compError={ResourcePageError}
      forwardedProps={{
        loc: resource,
        location,
        history,
        query
      }}
    />
  </>
));

export default ({ match, history, location }) => (
  <React.Fragment>
    <ResourceFetch
      type="Location"
      ids={{ ID: match.params.lid }}
      extraData={{ Context: "Admin" }}
    />
    <ResourceRender
      type="Location"
      ids={{ ID: match.params.lid }}
      denorm={true}
      compSuccess={OrgLocationScheduleContainer}
      compError={ResourcePageError}
      forwardedProps={{ history, location, query: location.search }}
    />
  </React.Fragment>
);

const StyleUserBlock = styled(UserBlockDeprecated)`
  padding: 0 20px;
  height: 100%;
`;

export const DateTimeContainer = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
`;

export const DateContent = styled.div`
  font-size: 13.82px;
  letter-spacing: 0.09px;
  line-height: 19px;
  color: #2c2e3c;
  margin: 0;
`;

export const TimeContent = styled.div`
  ${typography.tableSub};
  opacity: 0.6;
`;
