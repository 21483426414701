import { EntityStore } from "./entity-store";
import { rpc, metadata } from "./../grpc";
import {
  AccountItem,
  ListAccountItemsByBookingClientRequest,
  ListByLocationBookingDateRequest
} from "sdk/dist/account_items_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { toastStore } from "./toast-store";

class AccItemStore extends EntityStore<AccountItem.AsObject> {
  async loadByBookingClient(bookingId: string, clientId?: string) {
    const req = new ListAccountItemsByBookingClientRequest();
    req.setBookingId(bookingId);
    if (clientId) {
      req.setClientId(clientId);
    }
    try {
      const res = await rpc.accountItems.listByBookingClient(req, metadata());
      res.toObject().accItemList.map((ai) => this.add(ai));
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async loadByLocation(locationId: string, startDate?: Timestamp, endDate?: Timestamp) {
    const req = new ListByLocationBookingDateRequest();
    req.setLocationId(locationId);
    req.setBookingStartDate(startDate);
    req.setBookingEndDate(endDate);
    try {
      const res = await rpc.accountItems.listByLocationBookingDate(req, metadata());
      res.toObject().accItemList.map((ai) => this.add(ai));
    } catch (err) {
      toastStore.grpcError(err);
    }
  }
}

export const accItemStore = new AccItemStore();
