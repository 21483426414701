import { userStore } from "../mobx/stores/UserStore";

interface IAddress {
  Country: string;
  CountryCode: string;
  GooglePlaceID: string;
  Locality: string;
  Location: { Latitude: number; Longitude: number };
  OwnerType: string;
  PostCode: string;
  Raw: string;
  State: string;
  StateCode: string;
  Street1: string;
  Street2: string;
  ID?: string;
  CreatedAt?: string;
}

class AddressService {
  async createAddress(data: IAddress) {
    const res = await fetch("/api/v1/addresses", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    return res.json();
  }
}

export const sharedAddressService = new AddressService();
