import React, { PureComponent } from "react";
import styled from "@emotion/styled";

// utils
import { colors } from "./../../util/consts";
import typography from "./../../util/typography";

import UserAvatar from "./../../components/UserAvatar";
import Avatar from "../Avatar";
import { Media } from "sdk/dist/media_pb";

// @deprecated
export default class UserBlockDeprecated extends PureComponent<any> {
  render() {
    const { user, type, post, alt, size } = this.props;
    return (
      <Container>
        <UserAvatar userID={user.ID} type={type} size={size === "small" ? 40 : 75} />
        <ColContainer>
          <UserHead size={size}>
            {user.FirstName} {user.LastName} {post}
          </UserHead>
          <UserAlt size={size}>{alt}</UserAlt>
        </ColContainer>
      </Container>
    );
  }
}

interface Props {
  firstName: string;
  lastName: string;
  size?: "small";
  avatar?: Media.AsObject;
  date?: string;
  lfrac?: string;
}

export class UserBlock extends React.Component<Props> {
  render() {
    const { firstName, lastName, size, avatar, date, lfrac } = this.props;

    return (
      <Container>
        {avatar !== undefined ? (
          <Avatar
            letters={`${firstName ? firstName.charAt(0) : ""}${lastName ? lastName.charAt(0) : ""}`}
            image={avatar.downloadUrl}
            size={32}
            lfrac={lfrac}
          />
        ) : (
          <Avatar
            letters={`${firstName ? firstName.charAt(0) : ""}${lastName ? lastName.charAt(0) : ""}`}
            size={32}
            lfrac={lfrac}
          />
        )}

        <ColContainer>
          <UserHead size={size}>
            {firstName} {lastName}
          </UserHead>
          <UserAlt size={size}>{date}</UserAlt>
        </ColContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const ColContainer = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
`;

const UserHead = styled.div<{ size?: "small" }>`
  font-size: ${({ size }) => (size === "small" ? "13.82px" : "18px")};
  font-weight: ${({ size }) => (size === "small" ? "normal" : "bold")};
  letter-spacing: ${({ size }) => (size === "small" ? "0.09px" : "0.23px")};
  line-height: ${({ size }) => (size === "small" ? "19px" : "24px")};
  color: ${({ size }) => (size === "small" ? "#2C2E3C" : colors.surfaceText.highEmphasis)};
  margin: ${({ size }) => (size === "small" ? "0" : "7px")} 0;
`;

const UserAlt = styled.div<{ size?: "small" }>`
  ${({ size }) => (size === "small" ? typography.tableSub : typography.body2)};
  opacity: ${({ size }) => (size === "small" ? "0.6" : "0.68")};
`;
