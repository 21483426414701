import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import { colors, hexToRgba } from "../../../util/consts";
import typography from "../../../util/typography";

interface Props {
  resource: any;
}

export class StaffUtilization extends PureComponent<Props> {
  render() {
    const { resource } = this.props;

    return (
      <Container>
        <Section>
          <Avatar
            style={{
              backgroundImage: !resource.Provider.Photo ? null : resource.Provider.Photo.DownloadURL
            }}
          >
            <AvatarText>
              {resource.Provider.FirstName[0] + resource.Provider.LastName[0]}
            </AvatarText>
          </Avatar>
        </Section>
        <Section style={{ width: "180px" }}>
          <UserName>
            {resource.Provider.FirstName} {resource.Provider.LastName}
          </UserName>
          <UserCategories>
            {resource.LocationRoles.map((role: string, i: number) => `${i > 0 ? ", " : ""}${role}`)}
          </UserCategories>
        </Section>
        <Section style={{ flex: "1 1 auto" }}>
          <ProgressBar percent={resource.Utilisation} />
        </Section>
        <Section>
          <UserName>
            {resource.Utilisation}%
            <TooltipText>
              {resource.TotalWorkingHours} / {resource.EstimatedAvailabilityHours}
            </TooltipText>
          </UserName>
        </Section>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Section = styled.div`
  margin: 0 8px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${colors.primary.main};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarText = styled.span`
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 19px;
  text-transform: uppercase;
`;

const TooltipText = styled.span`
  position: absolute;
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 10;
  transition: opacity 0.6s;
  margin-left: 2px;
  margin-top: -5px;
`;

const UserName = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.09px;
  line-height: 19px;
  ${typography.elipse};
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

const UserCategories = styled.div`
  ${typography.caption};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  line-height: 15px;
  ${typography.elipse};
`;

const ProgressBar = styled.div<{ percent: number }>`
  width: 100%;
  height: 8px;
  border-radius: 100px;
  background-color: ${hexToRgba(colors.primary.main, 0.15)};
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: ${({ percent }) => percent || 0}%;
    background-color: #80caa6;
    border-radius: 100px;
  }
`;
