import { History } from "history";
import React, { useEffect, useState } from "react";
import { SearchSvgIcon } from "../../../static/";
import { searchQueryString } from "../../../util/parseQueryString";

interface IDefaultSearchTag {
  history: History;
  tagId: string;
  isHomepageSearchTag: boolean;
  className?: string;
}

export const DefaultSearchTag: React.FC<IDefaultSearchTag> = ({
  history,
  tagId,
  className,
  isHomepageSearchTag
}) => {
  const { push, location } = history;
  const [userSearch, storeQuery] = useState("");
  const onButtonClickAction = () => push(`/search?query=${userSearch}`);

  useEffect(() => {
    if (location.pathname.includes("/search")) {
      // parse value from url
      const value = searchQueryString(location.search, "query");
      storeQuery(value);
      return;
    }
    storeQuery("");
  }, [location.pathname]);

  if (isHomepageSearchTag)
    return (
      <div
        className={`flex h-16 md:h-12 px-8 md:px-0 md:w-72 md:mt-4 ${className}`}
        id="search-tag"
      >
        <input
          className="form-input flex-1 block rounded-none transition duration-150 ease-in-out rounded-r-none rounded-l-full px-4 text-xl outline-none"
          placeholder="Service, post code"
          aria-label="Search box"
          value={userSearch}
          id={tagId}
          onChange={(e) => storeQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") onButtonClickAction();
            return;
          }}
        />
        <span
          className="bg-yellow-300 cursor-pointer w-16 inline-flex items-center px-3 rounded-l-none rounded-r-full border border-l-0 border-transparent"
          onClick={onButtonClickAction}
        >
          <img
            src={SearchSvgIcon}
            alt="search icon"
            className="h-6 w-6 flex-1 max-w-none"
            id="search-svg"
          />
        </span>
      </div>
    );
  return (
    <div className="flex rounded-md shadow-sm h-10 border border-green-300">
      <input
        id={tagId}
        className="form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out bg-transparent leading-4 pl-4 placeholder-white focus:bg-white focus:outline-none"
        placeholder="Search for a product or service"
        value={userSearch}
        onChange={(e) => storeQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") onButtonClickAction();
          return;
        }}
      />
      <span
        className="inline-flex cursor-pointer items-center px-3 rounded-r-md bg-transparent"
        onClick={onButtonClickAction}
      >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20.22 20"
          className="fill-current pointer-events-none text-white w-5 h-5"
        >
          <path
            className="cls-1"
            d="M16.69,12.86a3.65,3.65,0,0,1,.32.26l4,4a1.81,1.81,0,0,1,0,2.81,4.57,4.57,0,0,1-.7.62,1.74,1.74,0,0,1-2.2-.28l-4.17-4.17a.29.29,0,0,0-.43-.08A8.06,8.06,0,0,1,1.59,10.4,7.72,7.72,0,0,1,3.9,3.14a7.82,7.82,0,0,1,8-1.95,8.14,8.14,0,0,1,4.89,11.38A3.15,3.15,0,0,0,16.69,12.86ZM14.89,9a5.36,5.36,0,1,0-5.44,5.32A5.36,5.36,0,0,0,14.89,9Z"
            transform="translate(-1.44 -0.82)"
          />
        </svg>
      </span>
    </div>
  );
};
