import React, { Component } from "react";

// importing components
import { ClientAccordian } from "./ClientAccordian";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import { TabContainer, TabHeader } from "../../elements/Tabs";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";

interface Props {
  booking: Booking.AsObject;
  setClient: () => void;
}
export default class Clients extends Component<Props> {
  state = {
    adding: false
  };
  addCard = () => {
    this.setState({ adding: true });
  };
  closeNew = () => {
    this.setState({ adding: false });
  };

  render() {
    const { booking, setClient } = this.props;
    const allowNew =
      booking.status === Booking.Status.BOOKING_CREATED &&
      booking.clientsList.filter(
        (c) => c.status === (BookingClient.Status.APPROVED || BookingClient.Status.CREATED)
      ).length < booking.maxClients;
    return (
      <TabContainer>
        <TabHeader>Clients</TabHeader>
        {(booking.clientsList || []).map((cl) => (
          <ClientAccordian key={cl.clientId} client={cl} booking={booking} setClient={setClient} />
        ))}
        {this.state.adding || !allowNew ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD CLIENT</LargeDottedButton>
        )}
        {!this.state.adding ? null : (
          <ClientAccordian
            key="newcl"
            client={null}
            booking={booking}
            closeNew={this.closeNew}
            setClient={setClient}
          />
        )}
      </TabContainer>
    );
  }
}
