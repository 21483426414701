import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { metadata, rpc } from "../../../grpc";
import {
  CreditCard,
  DeleteCreditCardRequest,
  ListCreditCardsRequest,
  SetPrimaryCardRequest,
  ListCreditCardsResponse
} from "sdk/dist/credit_cards_pb";
import { TabContainer, TabHeader } from "../../elements/Tabs";
import LargeDottedButton from "../../LargeDottedButton";
import { CardRow } from "./CardRow";
import { Label } from "../../elements/Label";
import { toDate } from "../../../util/timestamp";
import { toastStore } from "../../../stores/toast-store";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { NewCardStripeForm } from "./NewCardFormStripe";
import { ErrorText } from "../../routes/LocCreate";
import * as grpcWeb from "grpc-web";
import FormHeader from "../../form/FormHeader";
import SectionsContainer from "../../form/SectionsContainer";
import { stripeStore } from "../../../stores/stripe-store";

interface Props {
  ownerId: string;
  ownerType: CreditCard.OwnerType;
  required?: boolean;
  useFormHeaderComp?: boolean;
  selected?: CreditCard.AsObject["id"];
  onCardCreated?: () => void;
  onCardClick?: (card: CreditCard.AsObject) => void;
  dispatch?: any;
}

export function ownerTypeToText(ownerType: CreditCard.OwnerType): string {
  switch (ownerType) {
    case CreditCard.OwnerType.CLIENT:
      return "CLIENT";
    case CreditCard.OwnerType.LOCATION:
      return "LOCATION";
    case CreditCard.OwnerType.ORGANISATION:
      return "ORGANISATION";
    case CreditCard.OwnerType.PROVIDER:
      return "PROVIDER";
    case CreditCard.OwnerType.USER:
      return "USER";
    default:
      return "UNSPECIFIED";
  }
}

@observer
export class Cards extends React.Component<Props> {
  @observable
  private adding = false;

  @observable
  private cards = new Array<CreditCard.AsObject>();

  @observable
  private stripeObj: Stripe | null = null;

  async componentDidMount() {
    this.stripeObj = await stripeStore.getPKInstance();
    await this.load();
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  private sortCardByLastUsed(): CreditCard.AsObject[] {
    return this.cards.sort((a: CreditCard.AsObject, b: CreditCard.AsObject) => {
      if (a.lastUsedDate && b.lastUsedDate) {
        return this.getTime(toDate(a.lastUsedDate!)) - this.getTime(toDate(b.lastUsedDate!));
      }
      return 0;
    });
  }

  private async load() {
    const { onCardCreated, onCardClick, ownerId, ownerType, selected } = this.props;
    try {
      const req = new ListCreditCardsRequest();
      req.setOwner(ownerId);
      req.setOwnerType(ownerType);
      let res = new ListCreditCardsResponse();
      switch (ownerType) {
        case CreditCard.OwnerType.CLIENT:
          res = await rpc.clientCards.list(req, metadata());
          break;
        case CreditCard.OwnerType.ORGANISATION:
          res = await rpc.orgCards.list(req, metadata());
          break;
        case CreditCard.OwnerType.USER:
          res = await rpc.userCards.list(req, metadata());
          break;
        default:
          throw {
            code: 3,
            message: `Can't list credit card(s), owner type ${ownerTypeToText(
              ownerType
            )} unimplemented`
          } as grpcWeb.Error;
      }
      this.cards = res.toObject().cardsList;
      this.cards = this.sortCardByLastUsed().reverse();
      // select the primary card, or the last used card
      if (!selected && this.cards.length > 0 && onCardClick) {
        if (this.cards.filter((card) => card.isPrimary === true).length > 0) {
          onCardClick(this.cards.filter((card) => card.isPrimary === true)[0]);
        } else {
          onCardClick(this.cards[0]);
        }
      }
      if (onCardCreated) {
        onCardCreated();
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  private async setPrimary(card: CreditCard.AsObject) {
    const { ownerType } = this.props;
    try {
      const req = new SetPrimaryCardRequest();
      req.setCardId(card.id);
      req.setOwnerType(this.props.ownerType);
      switch (ownerType) {
        case CreditCard.OwnerType.CLIENT:
          await rpc.clientCards.setPrimaryCard(req, metadata());
          break;
        case CreditCard.OwnerType.ORGANISATION:
          await rpc.orgCards.setPrimaryCard(req, metadata());
          break;
        case CreditCard.OwnerType.USER:
          await rpc.userCards.setPrimaryCard(req, metadata());
          break;
        default:
          throw {
            code: 3,
            message: `Can't set default credit card(s), owner type ${ownerTypeToText(
              ownerType
            )} unimplemented`
          } as grpcWeb.Error;
      }
      await this.load();
      toastStore.success("new credit card successfully set as primary");
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  private async removeCard(card: CreditCard.AsObject) {
    const { ownerType } = this.props;
    try {
      const req = new DeleteCreditCardRequest();
      req.setCardId(card.id);
      req.setOwnerType(this.props.ownerType);
      switch (ownerType) {
        case CreditCard.OwnerType.CLIENT:
          await rpc.clientCards.delete(req, metadata());
          break;
        case CreditCard.OwnerType.ORGANISATION:
          await rpc.orgCards.delete(req, metadata());
          break;
        case CreditCard.OwnerType.USER:
          await rpc.userCards.delete(req, metadata());
          break;
        default:
          throw {
            code: 3,
            message: `Can't remove credit card(s), owner type ${ownerTypeToText(
              ownerType
            )} unimplemented`
          } as grpcWeb.Error;
      }
      await this.load();
      toastStore.success("credit card removed successfully");
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  headerTitle(ownerType: CreditCard.OwnerType): string {
    switch (ownerType) {
      case CreditCard.OwnerType.CLIENT:
        return "Client's Cards";
      case CreditCard.OwnerType.ORGANISATION:
        return "Organisation's Cards";
      case CreditCard.OwnerType.USER:
        return "User's Cards";
      default:
        return "Credit Cards";
    }
  }

  render() {
    const { selected, ownerId, ownerType, useFormHeaderComp, onCardClick, required } = this.props;
    return (
      <TabContainer>
        {useFormHeaderComp ? (
          <>
            <FormHeader
              title={this.headerTitle(ownerType)}
              subtitle="Please modify your primary payment methods below"
            />
            <SectionsContainer></SectionsContainer>
          </>
        ) : (
          <TabHeader>
            <Label required={required}>{this.headerTitle(ownerType)}</Label>
          </TabHeader>
        )}
        {this.cards.map((card) => (
          <CardRow
            key={card.id}
            card={card}
            selected={card.id === selected}
            onSetPrimary={() => this.setPrimary(card)}
            onRemove={() => this.removeCard(card)}
            onClick={() => {
              if (onCardClick) {
                onCardClick(card);
              }
            }}
            isUserCC={
              card.ownerType === CreditCard.OwnerType.USER &&
              ownerType !== CreditCard.OwnerType.USER
                ? true
                : false
            }
          />
        ))}

        {this.adding &&
          (this.stripeObj !== null ? (
            <Elements stripe={this.stripeObj}>
              <NewCardStripeForm
                ownerId={ownerId}
                ownerType={ownerType}
                onCardCreated={() => this.load()}
                onRequestClose={() => (this.adding = false)}
              />
            </Elements>
          ) : (
            <ErrorText>Stripe key undefined</ErrorText>
          ))}

        {!this.adding && (
          <LargeDottedButton onClick={() => (this.adding = true)}>ADD NEW CARD</LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
