import React from "react";

interface IProps {
  variant?: "primary" | "danger" | "information";
  fill?: boolean;
  size?: "large";
}

export const CheckCircleIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${!props.size ? "h-6 w-6" : "h-12 w-12"} ${computeColor(props.variant)}${
        props.fill && " fill-current"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

CheckCircleIcon.Solid = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${!props.size ? "h-6 w-6" : "h-12 w-12"} ${computeColor(props.variant)}${
        props.fill && " fill-current"
      }`}
      fill="none"
      viewBox="0 0 19 19"
      stroke="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const computeColor = (variant: IProps["variant"]) => {
  switch (variant) {
    case "primary":
    default:
      return "text-primary-900";
    case "information":
      return "text-tertiary-200";
    case "danger":
      return "text-red-500";
  }
};
