import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { ResourceActionTypes } from "../../../redux/features/resources/types";
import { ResourceTypes } from "../../../redux/features/resources/definitions";
import { Organisation } from "../../../redux/features/resources/schema";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import CheckRadioBox from "./../../../components/form/CheckRadioBox";
import { toastStore } from "../../../stores/toast-store";

interface Props {
  org: Organisation;
  values: any;
  errors: any;
  touched: any;
  dirty: any;
  handleChange: (state: any)=>void;
  handleBlur: ()=>void;
  handleSubmit: ()=>void;
  rollSubsToggle: (b: boolean)=>void;
  dispatch: Function;
  isSubmitting: boolean;
  pageData: any;
}

class OrgEditAccount_SubCan extends Component<Props> {
  state = {
    isChecked: false,
  }

  render() {
    const {
      errors,
      touched,
      dirty,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Cancel Subscription"
          subtitle="To close your subscription please check the box and click the button below."
        />
        <SectionsContainer>
          <Section>
            <CheckRadioBox
              id="createorg-subcan-ch"
              name="Checked"
              type="checkbox"
              checked={this.state.isChecked}
              onChange={(e:any)=> {
                this.setState({isChecked: !this.state.isChecked});
                this.props.handleChange({target: {name:"Checked", value: !this.state.isChecked}});
              }}
              onBlur={handleBlur}
              error={touched.Checked && errors.Checked}
            >
              I wish to cancel my subscription for this organisation.
            </CheckRadioBox>
            {!!errors.Checked && touched.Checked && <Error>{errors.Checked}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Subscription}
          >
            Confirm
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }: any) => ({
    Checked: false
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      await props.dispatch(
        ResourceActions.action(props.org, "Action", {
          Action: "DeactivateSubscription"
        })
      );
      toastStore.success("Saved Successfully!");
      props.rollSubsToggle(false);
      props.setPage("subact");
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountSubCan"
});

export default compose(
  connect((state) => ({})),
  formikEnhancer
)(OrgEditAccount_SubCan as any);
