import React from "react";
import styled from "styled-components";

export const StripeTrustBadge = () => {
  return (
    <Image src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg" />
  );
};

const Image = styled.img`
  width: 10rem;
  height: 3rem;
`;
