import { FundType } from "sdk/dist/offerings_pb";

export function fundTypeToText(fund: FundType): string {
  switch (fund) {
    case FundType.CREDIT_CARD:
      return "Credit card";
    case FundType.MEDICARE_STANDARD:
      return "Credit Card + Medicare Standard";
    case FundType.MEDICARE_BULK:
      return "Medicare (Bulk Billing)";
    case FundType.DVA_STANDARD:
      return "DVA (Allied Health)";
    case FundType.DVA_ALLIED:
      return "DVA (Allied Health)";
    case FundType.HICAPS:
      return "AUHICAPS";
    case FundType.THIRD_PARTY_INVOICE:
      return "Third Party Invoice";
    default:
      return "Unknown";
  }
}

export function fundTypeFilterToInternal(fund_type: string): FundType {
  switch (fund_type) {
    case "Credit card" || "Charges":
      return FundType.CREDIT_CARD;
    case "Credit Card + Medicare Standard":
      return FundType.MEDICARE_STANDARD;
    case "Medicare (Bulk Billing)":
      return FundType.MEDICARE_BULK;
    case "DVA (Allied Health)":
      return FundType.DVA_STANDARD;
    case "AUHICAPS":
      return FundType.HICAPS;
    case "Third Party Invoice":
      return FundType.THIRD_PARTY_INVOICE;
    default:
      return FundType.FUND_TYPE_UNSPECIFIED;
  }
}
