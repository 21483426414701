import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
// Components
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
// Utils
import { parseFormError } from "./../../../util/form";





class OrgCreateAccount_Reimb extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
        <Form
          onSubmit={
            isSubmitting
              ? (e) => {
                e.preventDefault();
                return false;
              }
              : handleSubmit
          }
        >
          <FormHeader
            title="Tax Information"
            subtitle="Please fill out the tax information relating to your business"
          />
          <SectionsContainer>
            <Section>
              <Label htmlFor="createorg-td-ename">Entity Name</Label>
              <Input
                id="createorg-td-ename"
                name="TaxDetailsAU_EntityName"
                type="text"
                placeholder="Name"
                value={values.TaxDetailsAU_EntityName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.TaxDetailsAU_EntityName && errors.TaxDetailsAU_EntityName}
              />
              {!!errors.TaxDetailsAU_EntityName && touched.TaxDetailsAU_EntityName && (
                <Error>{errors.TaxDetailsAU_EntityName}</Error>
              )}
            </Section>
            <Section>
              <Label htmlFor="createorg-td-abn">Tax ID</Label>
              <Input
                id="createorg-td-abn"
                name="TaxDetailsAU_ABN"
                type="text"
                placeholder="Name"
                value={values.TaxDetailsAU_ABN}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.TaxDetailsAU_ABN && errors.TaxDetailsAU_ABN}
              />
              {!!errors.TaxDetailsAU_ABN && touched.TaxDetailsAU_ABN && (
                <Error>{errors.TaxDetailsAU_ABN}</Error>
              )}
            </Section>
          </SectionsContainer>
          <FooterContainer>
            <LoadingButton
              style={{ width: 150 }}
              loading={isSubmitting}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!dirty && !pageData.TaxDetailsAU_EntityName}
            >
              Save
          </LoadingButton>
          </FooterContainer>
        </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }) => ({
    TaxDetailsAU_EntityName: org.TaxDetails.EntityName || "",
    TaxDetailsAU_ABN: org.TaxDetails.ABN || "",
  }),
  validationSchema: Yup.object().shape({
    TaxDetailsAU_EntityName: Yup.string().required("Entity name is required."),
    TaxDetailsAU_ABN: Yup.string()
      .required("Tax ID is required."),
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updOrg = {
        ...props.org,
        TaxDetails: {
          EntityName: values.TaxDetailsAU_EntityName.trim(),
          ABN: values.TaxDetailsAU_ABN.trim()
        }
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountReimb"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_Reimb);
