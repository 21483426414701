import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import LoadingButton from "./../../../components/LoadingButton";
import Tooltip from "./../../../components/elements/Tooltip";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import MediaInput from "./../../../components/form/MediaInput";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import WeekSchedule from "./../../../components/form/WeekSchedule";

// grpc
import { metadata, rpc } from "../../../grpc";
import { GetLocationRequest, Location, UpdateLocationRequest } from "sdk/dist/location_pb";
import { Media } from "sdk/dist/media_pb";
import { toastStore } from "../../../stores/toast-store";
class LocEditAccount_Sched extends Component {

  async componentDidMount() {
    try {
      const req = new GetLocationRequest();
      req.setLocationId(this.props.loc.ID);
      const res = await (await rpc.location.get(req, metadata())).toObject()
      this.props.values.ScheduleMedia = res.location.scheduleMedia
      }
      catch (err) {
          toastStore.grpcError(err)
      }
   }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      isSubmitting
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Location Schedule"
          subtitle="Please fill out the opening times for your location"
        />
        <SectionsContainer>
          <Section>
            <WeekSchedule
              name="Schedule"
              value={values.Schedule}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Schedule && errors.Schedule}
            />
            {!!errors.Schedule && touched.Schedule && <Error>{errors.Schedule}</Error>}
          </Section>
          <Section>
          <Label htmlFor="loccreate-phone">Schedule Image&nbsp;</Label>
          <Tooltip>
              This image will appear on the home page of booklyfe, if this is left blank then your schedule will be displayed as text
            </Tooltip>
            <MediaInput
              type="scheduleMedia"
              name="ScheduleMedia"
              value={values.ScheduleMedia}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.ScheduleMedia && touched.ScheduleMedia && <Error>{errors.ScheduleMedia}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ loc }) => ({
    Schedule: loc.Schedule,
    ScheduleMedia: ""
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updLoc = {
        ...props.loc,
        Schedule: values.Schedule
      };

      await props.dispatch(ResourceActions.action(updLoc, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    try {
      const req = new UpdateLocationRequest();
      const locationReq = new Location();
      const mediaReq = new Media()
      locationReq.setId(props.loc.ID)
      if (values.ScheduleMedia !== undefined) {
        if (typeof values.ScheduleMedia === 'string') {
          mediaReq.setId(values.ScheduleMedia.trim())
        }
      }
      locationReq.setScheduleMedia(mediaReq)
      req.setLocation(locationReq)
      const res = await rpc.location.update(req, metadata())
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    setSubmitting(false);
  },
  displayName: "LocEditAccountSched"
});

export default compose(
  connect((state) => ({})),
  withRouter,
  withToastManager,
  formikEnhancer
)(LocEditAccount_Sched);
