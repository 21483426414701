import React, { Component } from "react";
import * as fsn from "date-fns";

import { colors } from "./../../../../util/consts"; // , hexToRgba, breakpoints

import { UserBlock } from "./../../../../components/elements/UserBlock";
import Minus from "./../../../../components/icons/Minus";
import ClearIcon from "./../../../../components/icons/Clear";
import AddCard from "./../../../../components/icons/AddIcon";

import {
  AccordianContainer,
  AccordianButton,
  NewHeader,
  OptionsContainer,
  ButtonHead,
  NoSpacingIconButton
} from "./../../../../components/elements/AccordianElements";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { BookingClient, Booking } from "sdk/dist/bookings_pb";
import { CreateNote } from "./CreateNote";
import { Note } from "sdk/dist/notes_pb";

interface Props {
  note?: Note.AsObject;
  client: BookingClient.AsObject;
  clientID: string;
  booking: Booking.AsObject | undefined;
  closeNew: () => void;
  refreshNotes: (bookingId: string, clientId: string) => void;
}

@observer
export class NoteAccordian extends Component<Props> {
  @observable open = false;
  saveAndUpdateNote = (isOpen: boolean) => {
    this.open = !isOpen;
  };

  toggleOpen = () => {
    const nextState = !this.open;
    this.open = nextState;
  };

  render() {
    const { closeNew, client, booking, note, refreshNotes, clientID } = this.props;
    return (
      <AccordianContainer>
        {!note ? null : (
          <AccordianButton onClick={this.toggleOpen}>
            {note.writtenBy !== undefined ? (
              <UserBlock
                firstName={note.writtenBy.firstName}
                lastName={note.writtenBy.lastName}
                avatar={note.writtenBy.photo}
                date={
                  note.createdAt
                    ? fsn.format(new Date(note.createdAt.seconds * 1000), "dd/MM/yyyy")
                    : "01/01/2001"
                }
                lfrac={"0.5"}
              />
            ) : null}

            <OptionsContainer>
              {this.open ? (
                <Minus fill={colors.primary[100]} />
              ) : (
                <AddCard fill={colors.primary[100]} />
              )}
            </OptionsContainer>
          </AccordianButton>
        )}
        {(this.open || !note) && (
          <React.Fragment>
            {note ? null : (
              <NewHeader>
                <ButtonHead>New Note</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>
            )}
            {
              <CreateNote
                note={note || undefined}
                booking={booking}
                client={client}
                clientID={clientID}
                closeNew={closeNew}
                refreshNotes={refreshNotes}
                saveAndUpdateNote={(isOpen: boolean) => this.saveAndUpdateNote(isOpen)}
              />
            }
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}
