import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Card } from "../../ui-kit/Card";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Typography } from "../../ui-kit/Typography";
import { AppIcon } from "../../ui-kit/icons/AppIcon";
import CancelIcon from "../../ui-kit/icons/CancelIcon";
import { CheckIcon } from "../../ui-kit/icons/CheckIcon";
import { tracking } from "../../util/tracking";

interface IText {
  label: string;
  image?: {
    url: string;
    altName: string;
    title: string;
  };
}

interface IProps {
  type: "starter" | "basic" | "pro";
  label: string;
  cost?: number;
  featureAvailable: IText[];
  featureNotAvailable: IText[];
  variant: "primary" | "blue" | "tertiary";
  fullWidth: boolean;
  buttonURL?: string;
}

const SubscriptionTier = (props: IProps) => {
  const history = useHistory();

  return (
    <FlexBox direction="col" justifyContent="between" fullHeight>
      <FlexItem fullHeight>
        <Container height={{ base: "full" }}>
          <FlexBox
            justifyContent="between"
            alignItems="center"
            direction="col"
            spacing={[0, 3]}
            fullHeight
          >
            <FlexItem fullWidth>
              <FlexBox direction="col" alignItems="center">
                <FlexItem>
                  {props.variant === "primary" ? (
                    <AppIcon />
                  ) : props.variant === "blue" ? (
                    <AppIcon.Blue />
                  ) : (
                    <AppIcon.Orange />
                  )}
                </FlexItem>

                <FlexItem fullWidth>
                  <Typography.H2 alignment="center" weight="bold" uppercase noPadding>
                    {props.label}
                  </Typography.H2>

                  <Typography.H1 color="secondary" alignment="center" noPadding>
                    {!!props.cost ? `$${props.cost} USD` : "Free"}
                  </Typography.H1>

                  {props.cost !== undefined && (
                    <Typography.H3 alignment="center" noPadding>
                      Per provider
                    </Typography.H3>
                  )}
                </FlexItem>
              </FlexBox>
            </FlexItem>

            <FlexItem fullWidth>
              <FlexBox direction="col" spacing={[0, 4]}>
                <FlexItem fullWidth>
                  <FlexBox
                    direction="col"
                    spacing={[0, 1]}
                    alignItems="start"
                    justifyContent="start"
                  >
                    <FeatureRow features={props.featureAvailable} isFeatureAvailable />
                    <FeatureRow features={props.featureNotAvailable} isFeatureAvailable={false} />
                  </FlexBox>
                </FlexItem>
              </FlexBox>
            </FlexItem>
          </FlexBox>
        </Container>
      </FlexItem>

      {props.fullWidth ? (
        <AsyncButton
          onClick={() => {
            tracking.subscriptionCardClicked(props.type.toLowerCase());
            if (props.buttonURL) {
              return history.push(props.buttonURL);
            }
            history.push(`/createorg?page=general&type=${props.type.toLowerCase()}`);
          }}
          size="sm"
          variant={
            props.variant === "primary" ? "primary" : props.variant === "blue" ? "blue" : "tertiary"
          }
          fullWidth
        >
          <Typography.H3 color="neutral" weight="bold" noPadding uppercase>
            GET STARTED
          </Typography.H3>
        </AsyncButton>
      ) : (
        <FlexItem fullWidth>
          <AsyncButton
            onClick={() => {
              tracking.subscriptionCardClicked(props.type.toLowerCase());
              if (props.buttonURL) {
                return history.push(props.buttonURL);
              }
              history.push(`/createorg?page=general&type=${props.type.toLowerCase()}`);
            }}
            size="sm"
            variant={
              props.variant === "primary"
                ? "primary"
                : props.variant === "blue"
                ? "blue"
                : "tertiary"
            }
            fullWidth
          >
            <Typography.H3 color="neutral" weight="bold" noPadding uppercase>
              GET STARTED
            </Typography.H3>
          </AsyncButton>
        </FlexItem>
      )}
    </FlexBox>
  );
};

const FeatureRow = (props: { features: IText[]; isFeatureAvailable: boolean }) => {
  return (
    <>
      {props.features.map((r, key) => (
        <StyledRowContainer isFeatureAvailable={props.isFeatureAvailable} key={key}>
          {r.image && (
            <FlexItem>
              <FlexBox alignItems="center" spacing={[1, 0]}>
                <StyledImage src={r.image.url} alt={r.image.altName} width="120" height="80" />
                <Typography.Body weight="bold" noPadding>
                  {r.image.title}
                </Typography.Body>
              </FlexBox>
            </FlexItem>
          )}
          <FlexItem>
            <FeatureText label={r.label} image={r.image} isAvailable={props.isFeatureAvailable} />
          </FlexItem>
        </StyledRowContainer>
      ))}
    </>
  );
};

const FeatureText = (props: IText & { isAvailable: boolean }) => {
  return (
    <>
      <FlexBox direction="row" alignItems="start" spacing={[2, 0]}>
        <FlexItem>
          <IconContainer>
            {props.isAvailable ? <CheckIcon /> : <CancelIcon variant="danger" size="small" />}
          </IconContainer>
        </FlexItem>

        <FlexItem>
          <Typography.Body noPadding>{props.label}</Typography.Body>
        </FlexItem>
      </FlexBox>
    </>
  );
};

SubscriptionTier.Tab = (props: Omit<IProps, "fullWidth">) => (
  <Container width="full" height={{ base: "full" }}>
    <SubscriptionTier {...props} fullWidth={false} />
  </Container>
);

SubscriptionTier.Card = (props: Omit<IProps, "fullWidth">) => (
  <Card.Colored variant={props.variant} fullHeight>
    <Container width="full" height={{ base: "full" }} type="block" noPadding>
      <SubscriptionTier {...props} fullWidth />
    </Container>
  </Card.Colored>
);

const IconContainer = styled.div`
  padding-top: 3px;
`;

const StyledImage = styled.img`
  padding: 0.5rem 0;
`;

const StyledRowContainer = styled.div<{ isFeatureAvailable: boolean }>`
  opacity: ${(props) => (props.isFeatureAvailable ? 1 : 0.5)};
`;

export default SubscriptionTier;
