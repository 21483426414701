import React from "react";
import { useDispatch } from "react-redux";
import * as ReduxDialogs from "../../redux/features/dialogs";
import Dialog from "./Dialog";
import { Onboarding } from "../stripe/Onboarding";
import { breakpoints } from "../../util/consts";
import styled from "@emotion/styled";

export interface StripeSetupDialogProps {
  data: {
    orgId: string;
  };
  dialogId?: any;
}

export function StripeSetupDialog(props: StripeSetupDialogProps) {
  const dispatch = useDispatch();

  const closeDialog = () => {
    const { dialogId } = props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  return (
    <Dialog dialogId={props.dialogId} overideCloseDialog={closeDialog} noClose noClickAwayClose>
      <Container>
        <Onboarding orgId={props.data.orgId} />
      </Container>
    </Dialog>
  );
}

const Container = styled.div`
  position: relative;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;
