import styled from "@emotion/styled";

export const TabDetail = styled.div`
  font-weight: 400;
`;

export const TabContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
`;

export const TabHeader = styled.div`
  margin-top: 30px;
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;