import React from "react";
import styled from "@emotion/styled";
import $ from 'jquery';


// Utils
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";

// Images
import bannerImage from "./../../../static/lyfesalesbanner.jpg";
import firstSectionImage from "./../../../static/mybb.png";
import secondSectionImage from "./../../../static/ppfa.png";
import thirdSectionImage from "./../../../static/tsffc.png";
import forthSectionImage from "./../../../static/funds-logos.png";
import fifthSectionImage from "./../../../static/insights.jpg";

import step2 from './StepImages/Step2.png'
import step26 from './StepImages/Step26.png'
import step27 from './StepImages/Step27.png'
import step28 from './StepImages/Step28.png'
import step29 from './StepImages/Step29.png'
import step210 from './StepImages/Step210.png'
import step211 from './StepImages/Step211.png'
import step212 from './StepImages/Step212.png'
import step41 from './StepImages/Step41.png'
import step42 from './StepImages/Step42.png'
import step43 from './StepImages/Step43.png'
import step51 from './StepImages/Step51.png'
import step52 from './StepImages/Step52.png'
import step53 from './StepImages/Step53.png'
import step54 from './StepImages/Step54.png'
import step61 from './StepImages/Step61.png'
import step63 from './StepImages/Step63.png'
import step64 from './StepImages/Step64.png'
import step65 from './StepImages/Step65.png'
import step71 from './StepImages/Step71.png'
import step72 from './StepImages/Step72.png'
import logo from './StepImages/Physitrack-Logo.png'

// Components
import Button from "./../../../components/Button";
import CheckIcon from "./../../../components/icons/Check";

const TickSection = React.memo(({ label }) => (
  <Tick>
    <TickContainer>
      <CheckIcon color={colors.secondary.main} />
    </TickContainer>
    <TickContent>{label}</TickContent>
  </Tick>
));

const Tick = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const TickContainer = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.38)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  flex: 0 0 auto;
`;

const TickContent = styled.div`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  flex: 1 1 auto;
`;

const ListYourBusiness = React.memo((props) => {

  const NavigateToCreateOrg = () => {
    props.history.push("/createorg");
  };

  const handleScrollTo = (id) => {
    function isMobile() {
      if(window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
   }
   var x = document.getElementById(id);
   var block = 'start'

   if(id == 'video'){
     block = 'end'
   }
    
    if (isMobile() == false){
      x.scrollIntoView({
        behavior: 'auto',
        block: block
      })
      var y = function(){window.scrollTo({top: 0, behavior: 'smooth'});}
      setTimeout(y, 0);
    } else {
      x.scrollIntoView({
        behavior: 'smooth',
        block: block
      })
    }
  }

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  
  $("body").width(100)

  return (
    <Container id='menu'>
      <FullScreenSection >
      <ScrollToTop  onClick={() => { handleScrollToTop() }}>⬆</ScrollToTop>
        <NavMenu >
            {/* <Logo src={logo} ></Logo> */}
            <h1>Physitrack Integration Guide</h1>
            <NavGroup>
            <NavLink onClick={() => { handleScrollTo('video') }}> Demo Video</NavLink>
            <NavLink onClick={() => { handleScrollTo('step1') }}> Link Lyfe and Physitrack accounts</NavLink>
            <NavLink onClick={() => { handleScrollTo('step2') }}> Add Patient to Physitrack </NavLink>
            <NavLink onClick={() => { handleScrollTo('step3') }}> Import patients from Physitrack </NavLink>
            <NavLink onClick={() => { handleScrollTo('step4') }}> Make a Telehealth Booking </NavLink>
            <NavBottom/>
            </NavGroup>

        </NavMenu>
        <ContentContainer>
            <ContentCard>
                <Video/>
                <Step1/>
                <Step2/>
                <Step3/>
                <Step4/>
            </ContentCard>
        </ContentContainer>
      </FullScreenSection>
    </Container>
  );
});

const NavBottom = () => {
  return (
  <DiscountSection>
  If you are not currently a Physitrack member but want to sign up:<br/>
  <div><ol><li>
 Go to <a style={{color: '#ffffffd4'}}href="https://au.physitrack.com/register"> https://au.physitrack.com/register</a>
 <br/></li><li>On payment page: select Lyfe as your affiliate and enter <strong>lyfe10</strong> for your 10% discount off your Physitrack subscription fee.
 </li> </ol> </div>
  </DiscountSection>)
}

const Video = () =>{
  return(
  <Step id='video'>
    <span style={{display:'flex'}}><h1 style={{color:colors.secondary.main}}>{"Demo Video"}</h1></span>
    <Vimeo  id='step1' src="https://player.vimeo.com/video/424014178" height="100" weight="200" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></Vimeo>
  </Step>)
}

const Step1 = () =>{
    return (
        <Step  >
        <span style={{display:'flex'}}><h1 style={{color:colors.secondary.main}}>{"Link Lyfe and Physitrack accounts to allow seamless telehealth video appointments and client data push/pull capabilities"}</h1></span>
        <p> <strong>1.</strong> Once a staff member is created select them by clicking them on the table and ensure you have the “Details” tab selected </p>
        <Image src={step2}></Image>
        <p><strong>2.</strong> Scroll down to the physitrack Integration section </p>
        <p><strong>3.</strong> Select “Physitrack Lyfe” from the dropdown menu and click on “Generate”. Kindly COPY the generated token as it will be used in step 6 </p>
        <Image src={step26} width="400px"></Image>
        <p><strong>4.</strong> Go to your physitrack account and click on the “My account” tab </p>
        <Image src={step27} width="400px"></Image>
        <p><strong>5.</strong> Click on the “Settings” tab</p>
        <Image src={step28} width="400px"></Image>
        <p><strong>6.</strong> Scroll down until you find the “Integrations” section and select Lyfe from the dropdown. After that copy the token generated in step 3 into the “API key” input</p>
        <Image src={step29} width="400px"></Image>
        <p><strong>7.</strong> Below the integrations section locate “API Key” and copy the code</p>
        <Image src={step210} width="400px"></Image>
        <p><strong>8.</strong> Return to the Booklyfe add “Staff” page Click “Edit” in the top right corner</p>
        <Image src={step211} width="400px"></Image>
        <p><strong>9.</strong> Select “Physitrack API”, copy paste it from your physitrack profile and then click “Save”</p>
        <Image src={step212} width="400px" id={'step2'}></Image>
        </Step>
    )
}

const Step2 = () =>{
    return (
        <Step  >
        <span style={{display:'flex'}} ><h1 style={{color:colors.secondary.main}}>{"Add Patient to Physitrack"}</h1></span>
        <p> <strong>1.</strong> In order to add an existing Booklyfe client to Physitrack, Click on the “Clients” tab</p>
        <Image src={step41} width="400px"></Image>
        <p><strong>2.</strong>  Click on the client that you want to add to Physitrack</p>
        <Image src={step42} width="400px"></Image>
        <p><strong>3.</strong> Click on the “Details” tab and ensure the client has a <strong>date of birth</strong> on their profile. Select “Open client in physitrack” (note: you must be signed in to the Staff account that has Physitrack set up). <br/>This will add a client to physitrack or update their details if they already exist</p>
        <Image src={step43} width="400px" id={'step3'}></Image>
        </Step>
    )
}

const Step3 = ()=> {
  return(
  <Step>
      <span style={{display:'flex'}} ><h1 style={{color:colors.secondary.main}}>{"Import patients from Physitrack"}</h1></span>
     <p><strong>1.</strong> Open the dashboard for a Location and click "Clients"</p>
        <Image src={step71} width="400px" ></Image>
        <p id={'step4'}><strong>2.</strong> In the top right corner click the settings icon and select "Import Physitrack Clients" (note: you must be signed in to the Staff account that has Physitrack set up AND the patient must have an <strong> email address</strong> in physitrack) </p>
        <Image src={step72} width="400px" ></Image>
  </Step>
  )
}

const Step4 = () =>{
    return (
        <Step>
        <span style={{display:'flex'}} ><h1 style={{color:colors.secondary.main}}>{"Make a Telehealth Booking"}</h1></span>
        <p> <strong>1.</strong> First you will need to create a Telehealth service. Navigate to the dashboard and select the “Services &amp; Products” tab </p>
        <Image src={step51} width="400px"></Image>
        <p><strong>2.</strong> Click on the “+” icon or edit an existing service</p>
        <Image src={step52} width="400px"></Image>
        <p><strong>3.</strong> Fill out the service as normal. Except in the “Type” select “Telehealth Session” </p>
        <Image src={step53} width="400px"></Image>
        <p><strong>4.</strong> Finalise it by clicking “Save” </p>
        <Image src={step54} width="400px"></Image>
        <p><strong>5.</strong> Click on “Appointments” tab </p>
        <Image src={step61} width="400px"></Image>
        <p><strong>6.</strong> Click a timeslot on the calendar </p>
        <p><strong>7.</strong> Select the “Service” and “Client” then click “Save” </p>
        <Image src={step63} width="400px"></Image>
        <p><strong>8.</strong> From here you will be able to start the meeting in Physitrack when it is time </p>
        <Image src={step64} width="400px"></Image>
        <p><strong>9.</strong> At the same time the client will receive an email with the booking confirmation and a link to the telehealth session </p>
        <Image src={step65} width="400px"></Image>
        </Step>
    )
}

export default ListYourBusiness;

const Container = styled.main`
    width: 100%;
`;

const FullScreenSection = styled.div`
width: 100%;
  @media only screen and (min-width: 768px) {
    width: 100%;
    height: calc(100vh - 40px);
    max-height: 900px;
    display: flex;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
}
`;

const Vimeo = styled.iframe`
width: 100%;
height: 180px;
border: none;

@media (min-width: 700px) {
  width: 100%;
  height: 350px;
  border: none;
}
`

const DiscountSection = styled.div`
@media only screen and (min-width: 768px) {
  position: absolute;
  bottom: 0;
  padding: 20px;
  padding-left: 0px;
  overflow-wrap: anywhere;
  div{
    padding-left: 5px;
  }
}
`

const NavMenu = styled.div`
border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
background-color: ${hexToRgba(colors.secondary.main, 1)};
color: white;
padding: 5px;
 h1{
      font-size: 20px;
  }
  img{
    margin-left: 30%;
  }
  @media only screen and (min-width: 768px) {
    width: 30%;
  height: calc(100vh - 40px);
  max-height: 900px;
  display: block;
  position: relative;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  background-color: ${hexToRgba(colors.secondary.main, 1)};
  color: white;
  padding: 20px;
}
`;

const NavLink = styled.a`
  display: block;
  text-decoration: none;
  color: white;
  padding-top: 10px;
  border-bottom: solid ${hexToRgba(colors.secondary[500], 1)} 1px;
  padding-bottom: 5px;
  &:hover {
      background-color: #c0c0c070;
      text-shadow: 2px 2px 5px silver;
  }
`;

const ScrollToTop = styled.button`
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background:  ${hexToRgba(colors.secondary.main, 1)};
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px #00000036;
  @media only screen and (min-width: 768px) {
    visibility: hidden;
}
`

const NavGroup = styled.div`
`

const Logo = styled.img`
  width: 150px;
  background-color: white;
`

const Step = styled.div`
  h1{
      font-size: 20px;
  }
@media only screen and (min-width: 768px) {
}
`;

const Image = styled.img`
width: 300px;
justify-content: center;
display:flex;
margin:auto;
@media only screen and (min-width: 768px) {
    width: 450px;
    justify-content: center;
    display:flex;
    margin:auto;
}

`

const ContentContainer = styled.div`
  @media only screen and (min-width: 768px) {
    width: 70%;
    height: 100%;
    max-height: 900px;
    display: block;
    background-color: #0000000d;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
}
`;

const ContentCard = styled.div`
padding: 5px;
  @media only screen and (min-width: 768px) {
    width: 100%
    height: 100%;
    margin: 20px;
    margin-bottom: 0px;
    padding: 20px;
    display: block;
    background-color: #ffffff;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
    overflow: scroll;
    height: -webkit-fill-available;
}
  
`;
