import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import { colors } from "../../../util/consts";
import typography from "../../../util/typography";

interface Props {
  resource: any;
}

export class NewClient extends PureComponent<Props> {
  render() {
    const { resource } = this.props;
    return (
      <Container>
        <Section style={{ flexGrow: 0 }}>
          <Avatar style={{ backgroundImage: resource.Photo && resource.Photo.DownloadURL }}>
            <AvatarText>{`${resource.FirstName[0]}${resource.LastName[0]}`}</AvatarText>
          </Avatar>
        </Section>
        <Section>
          <UserName>
            {resource.FirstName} {resource.LastName}
          </UserName>
        </Section>
        <Section>
          <UserContact>{resource.Email}</UserContact>
        </Section>
        <Section>
          <UserContact>{resource.MobileNumber}</UserContact>
        </Section>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Section = styled.div`
  flex: 0 1 auto;
  margin: 0 8px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${colors.primary.main};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarText = styled.span`
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 19px;
  text-transform: uppercase;
`;

const UserName = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.09px;
  line-height: 19px;
  ${typography.elipse};
`;

const UserContact = styled.div`
  ${typography.caption};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  line-height: 15px;
  ${typography.elipse};
`;
