// TODO: remove this file after moving these functions to userStore

// TODO: move this to userStore
export function getUserOrgID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const global = JSON.parse(store.global);
        const orgID = global.UserContext.OrgID
        return { orgID }
    } catch (error) {
        return {}
    }
}

// TODO: move this to userStore
export function getUserLocationID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const global = JSON.parse(store.global);
        const LocID = global.UserContext.LocID
        return { LocID }
    } catch (error) {
        return {}
    }
}

export function getUserID(){
    try{
        const store = JSON.parse(localStorage.getItem("persist:root")!);
        const auth = JSON.parse(store.auth);
        const UserID = auth.UserID
        return { UserID }
    } catch (error) {
        return {}
    }
}



// TODO: remove this, as this has been moved to persistence
export function restore<T>(key: string, fallback: T | undefined = undefined): T | undefined {
  try {
    return JSON.parse(localStorage.getItem(key)!)!.value;
  } catch {
    return fallback;
  }
}

// TODO: remove this, as this has been moved to persistence
export function store<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify({ value }));
}

// TODO: this should be in userStore
export function getToken() : string | undefined {
  const store = JSON.parse(localStorage.getItem("persist:root")!);
  const auth = store ? JSON.parse(store.auth) : undefined;
  if (!auth) {
    return undefined;
  }
  return auth.Token;
}

export function clear() {
  localStorage.clear()
}
