import styled from "@emotion/styled";
import * as fns from "date-fns";
// import { css, jsx } from "@emotion/core";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import { breakpoints, colors, hexToRgba } from "../../util/consts";
import typography from "../../util/typography";
// components
import Button from "../Button";
import ButtonBase from "../ButtonBase";
import Dropdown from "../Dropdown";
import Input from "../form/Input";
import IconButton from "../IconButton";
import AddIcon from "../icons/Add";
import ArrowDropDownIcon from "../icons/ArrowDropDown";
import Help from "../icons/Help";
import InsertInvitationIcon from "../icons/InsertInvitation";
// icons
import KeyboardArrowLeftIcon from "../icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "../icons/KeyboardArrowRight";
import Search from "../icons/Search";
import Settings from "../icons/Settings";
import { LightTooltip } from "./MaterialTooltip";

interface Props {
  type: string;
  subHeaders: string[];
  onSubPage: (subPage: string) => void;
  clickExport: () => void;
  setDate: (date: Date) => void;
  date: number | Date;
  weeklyView: number;
  subHeader?: string;
  curSubHeader?: string;
  onSearch?: (val: any) => void;
  switchView?: (val: any) => void;
  onAdd?: (val: any) => void;
  isRebooking?: boolean;
  settings?: any;
  exportIsLoading?: boolean;
}

interface State {
  isSettingsMenuOpen: boolean;
  isCalendarDropdownOpen: boolean;
  showSearch: boolean;
  searchValue: string;
  curSubHeader: number;
  hover: boolean;
  showSubHeaderDropdown: boolean;
  isMobile: boolean;
}

class AdminSubHeader extends Component<Props, State> {
  searchInput: React.RefObject<any> = React.createRef();

  state = {
    isSettingsMenuOpen: false,
    isCalendarDropdownOpen: false,
    showSearch: false,
    searchValue: "",
    curSubHeader: 0,
    hover: false,
    showSubHeaderDropdown: false,
    isMobile: false
  };

  checkIfAppointmentPage = () => {
    const appointmentPageURL = new RegExp("/admin/locs/.*/appointment.*");

    if (appointmentPageURL.test(window.location.href)) {
      return true;
    } else {
      return false;
    }
  };

  exportCSV = () => {
    this.props.clickExport();
  };

  toggleSettingsMenu = () => {
    this.setState((prevState) => ({ isSettingsMenuOpen: !prevState.isSettingsMenuOpen }));
  };

  closeSettingsMenu = () => {
    if (this.state.isSettingsMenuOpen) {
      this.setState({ isSettingsMenuOpen: false });
    }
  };

  toggleCalendarDropdown = () => {
    this.setState((prevState) => ({ isCalendarDropdownOpen: !prevState.isCalendarDropdownOpen }));
  };

  closeCalendarDropdown = () => {
    this.setState({ isCalendarDropdownOpen: false });
  };

  setDateToday = () => {
    this.props.setDate(new Date());
  };

  setDateNext = () => {
    this.props.setDate(fns.addDays(this.props.date, this.props.weeklyView ? 7 : 1));
  };

  setDatePrevious = () => {
    this.props.setDate(fns.subDays(this.props.date, this.props.weeklyView ? 7 : 1));
  };

  hoverOn = () => {
    this.setState({ hover: true });
  };

  hoverOff = () => {
    this.setState({ hover: false });
  };

  showDropdown = () => {
    this.setState({ showSubHeaderDropdown: true });
  };

  openSearch = (e: any) => {
    this.setState({ showSearch: true });
  };

  handleSearchInput = (e: any) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearchBlur = (e: any) => {
    const { showSearch, searchValue } = this.state;
    if (showSearch && searchValue === "") {
      this.setState({ showSearch: false });
    }
  };

  handleSubHeaderChange = (val: any) => {
    const { subHeaders } = this.props;
    this.setState({ curSubHeader: subHeaders.indexOf(val.currentTarget.value) });
  };

  updateSearch = debounce((val) => {
    const { onSearch } = this.props;
    if (onSearch) {
      onSearch(val);
    }
  }, 250);

  handleResize = debounce(() => {
    this.setState({ isMobile: window.innerWidth < 744 });
  }, 100);

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Focus on the search when it is enabled.
    if (this.state.showSearch && !prevState.showSearch && this.searchInput.current) {
      this.searchInput.current.focus();
    }

    // Forward the updated sub header.
    if (this.state.curSubHeader !== prevState.curSubHeader) {
      const { onSubPage, subHeaders } = this.props;
      onSubPage && onSubPage(subHeaders[this.state.curSubHeader]);
    }

    // Forward the updated search.
    if (this.state.searchValue !== prevState.searchValue) {
      this.updateSearch(this.state.searchValue);
    }
  }

  settingsMenu = React.memo(({ styles, innerRef, settings }: any) => (
    <SettingsContainer style={styles} ref={innerRef}>
      <SettingsContent>
        {settings.map((s: any) => (
          <SettingsClickableItem
            key={s.label}
            onClick={() => {
              s.onClick();
              this.closeSettingsMenu();
            }}
          >
            <SettingsBody>{s.label}</SettingsBody>
          </SettingsClickableItem>
        ))}
      </SettingsContent>
    </SettingsContainer>
  ));

  calendarDropdown = React.memo(({ styles, innerRef, date, setDate }: any) => (
    <div style={styles} ref={innerRef}>
      <DatePicker selected={date} onChange={setDate} inline />
    </div>
  ));

  renderContent() {
    const { isSettingsMenuOpen, isCalendarDropdownOpen, showSearch, searchValue, curSubHeader } =
      this.state;
    const {
      subHeader,
      subHeaders,
      onSubPage,
      settings,
      onAdd,
      onSearch,
      date,
      setDate,
      type,
      clickExport,
      exportIsLoading
    } = this.props;

    const ListNavButtons = () => {
      const navButtons = subHeaders.map((sh: string) => (
        <NavButton
          style={
            sh === subHeaders[curSubHeader!]
              ? { backgroundColor: colors.primary.main }
              : { backgroundColor: colors.secondary.main }
          }
          value={sh}
          onClick={this.handleSubHeaderChange}
          key={sh}
        >
          <NavButtonText>{sh}</NavButtonText>
        </NavButton>
      ));
      return <>{navButtons}</>;
    };

    const isAppointmentPage = this.checkIfAppointmentPage();

    return (
      <>
        <Block style={type === "calendar" ? { flex: "0 0 auto" } : {}}>
          {type === "calendar" && (
            <>
              <NotMobileButton
                variant="contained"
                color="secondary"
                onClick={this.setDateToday}
                disabled={fns.isSameDay(new Date(), date)}
              >
                <ButtonText>TODAY</ButtonText>
              </NotMobileButton>
              <NotMobileIconButton mini onClick={this.setDatePrevious}>
                <KeyboardArrowLeftIcon />
              </NotMobileIconButton>
              <NotMobileIconButton mini onClick={this.setDateNext}>
                <KeyboardArrowRightIcon />
              </NotMobileIconButton>
              <Dropdown
                isOpen={isCalendarDropdownOpen}
                onClickOutside={this.closeCalendarDropdown}
                render={({ styles, ref }: any) =>
                  React.createElement(this.calendarDropdown, {
                    styles,
                    innerRef: ref,
                    date,
                    setDate
                  })
                }
              >
                <IconButton mini onClick={this.toggleCalendarDropdown}>
                  <ButtonSpacing>
                    <InsertInvitationIcon />
                    <ButtonTextLarger>{fns.format(date, "do MMMM yyyy")}</ButtonTextLarger>
                    <ArrowDropDownIcon />
                  </ButtonSpacing>
                </IconButton>
              </Dropdown>

              {this.props.isRebooking === true && <ButtonTextLarger> Rebooking </ButtonTextLarger>}
            </>
          )}
        </Block>
        {!onSubPage ? (
          subHeader && <HeaderText>{subHeader}</HeaderText>
        ) : (
          <React.Fragment>
            <NavContainer>
              <ListNavButtons />
            </NavContainer>
          </React.Fragment>
        )}
        <Block style={type === "calendar" ? { flex: "0 0 auto" } : {}}>
          {showSearch && (
            <SearchInput
              ref={this.searchInput}
              placeholder="Text"
              value={searchValue}
              onChange={this.handleSearchInput}
              onBlur={this.handleSearchBlur}
              onMouseEnter={this.hoverOn}
              onMouseLeave={this.hoverOff}
            />
          )}
          {onSearch && (
            <IconButton mini onClick={this.openSearch} disabled={this.state.showSearch}>
              <Search />
            </IconButton>
          )}
          {settings && (
            <Dropdown
              isOpen={isSettingsMenuOpen}
              onClickOutside={this.closeSettingsMenu}
              render={({ styles, ref }: any) =>
                React.createElement(this.settingsMenu, { styles, innerRef: ref, settings })
              }
            >
              <IconButton
                mini
                onClick={
                  isAppointmentPage === true ? this.props.switchView : this.toggleSettingsMenu
                }
              >
                <Settings isAppointmentPage={isAppointmentPage} viewType={this.props.type} />
              </IconButton>
            </Dropdown>
          )}
          {clickExport && (
            <>
              <Button
                style={{ width: "150px", marginLeft: "5px" }}
                variant="contained"
                color="secondary"
                onClick={this.exportCSV}
                disabled={exportIsLoading}
              >
                {exportIsLoading ? (
                  <PulseLoader color={colors.tertiary.main} loading={exportIsLoading} size={10} />
                ) : (
                  <NavButtonText>Export CSV</NavButtonText>
                )}
              </Button>
              <LightTooltip
                disableFocusListener
                disableTouchListener
                title={"Data is exported using the filters from the table below"}
                placement="bottom"
              >
                <div>
                  <Help />
                </div>
              </LightTooltip>
            </>
          )}
          {onAdd && (
            <>
              <AddIconButton mini onClick={onAdd}>
                <AddIcon fill={colors.surface.light} />
              </AddIconButton>
            </>
          )}
        </Block>
      </>
    );
  }

  render() {
    const isIframe = window.location.pathname.includes("/iframe/");
    const { type } = this.props;
    if (type === "calendar") {
      return (
        <ContainerCalendar viewWidth={window.innerWidth} isIframe={isIframe}>
          {this.renderContent()}
        </ContainerCalendar>
      );
    } else if (type === "table") {
      return (
        <ContainerTable viewWidth={window.innerWidth} isIframe={isIframe}>
          <Content>{this.renderContent()}</Content>
        </ContainerTable>
      );
    } else {
      return (
        <ContainerPage viewWidth={window.innerWidth}>
          <Content>{this.renderContent()}</Content>
        </ContainerPage>
      );
    }
  }
}

export default connect()(AdminSubHeader);

interface cssProps {
  viewWidth: number;
}

const ContainerCalendar = styled.div<{ isIframe: boolean; viewWidth: number }>`
  width: ${({ viewWidth }: cssProps) => viewWidth}px;
  height: 80px;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  background-color: #f4f8f9;
  position: sticky;
  top: ${({ isIframe }) => (isIframe ? 0 : "4.313rem")};
  left: 0;
  z-index: 5;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
`;

const ContainerTable = styled.div<{ isIframe: boolean; viewWidth: number }>`
  width: ${({ viewWidth }: cssProps) => viewWidth}px;
  box-shadow: 0px 0.5px silver;
  justify-content: space-between;
  background-color: #f4f8f9;
  display: flex;
  position: sticky;
  left: 0;
  top: ${({ isIframe }) => (isIframe ? 0 : "4.313rem")};
  z-index: 4;
`;

const ContainerPage = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: ${({ viewWidth }: cssProps) => viewWidth}px;
  box-shadow: none;
  justify-content: space-between;
  background-color: #f4f8f9;
  display: flex;
  position: sticky;
  left: 0;
  top: 69px;
  z-index: 4;
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  margin: 0 auto;
  min-width: 100%;
  /* padding: 50px 25px 22px 75px; */
  padding: 50px 36px 33px 36px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const SearchInput = styled(Input)`
  position: relative;
  left: 40px;
  width: 294px;
  height: 36px;
  ${({ isOpen }) => (!isOpen ? "" : "border-radius: 4px 4px 0px 0px;")};
  &:hover:enabled {
    opacity: 0.8;
    background-color: white;
  }
`;

const HeaderText = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 33.57px;
  font-weight: 600;
  letter-spacing: 0.25px;
  user-select: none;
  cursor: pointer;
`;

const ButtonText = styled.span`
  ${typography.body2};
  letter-spacing: 1.25px;
  margin: 0 8px;
  color: inherit;
`;

const ButtonTextLarger = styled.div`
  ${typography.subtitle2};
  font-weight: 600;
  line-height: 24px;
  margin-left: 7px;
  margin-right: 2px;
`;

const ButtonSpacing = styled.span`
  display: flex;
  margin: 0 4px;
`;

const AddIconButton = styled(IconButton)`
  margin-left: 10px;
  background-color: ${colors.secondary.main} !important;

  &:hover {
    background-color: ${hexToRgba(colors.secondary.main, 0.6)} !important;
  }
`;

const SettingsContainer = styled.div`
  margin-top: 7px;
  background-color: ${colors.surface.light};
  border-radius: 4px;
  width: 280px;
  border: 1px solid ${hexToRgba(colors.primary.main, 0.1)};

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: calc(100% + 1px);
    right: 16px;
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${hexToRgba(colors.primary.main, 0.1)} transparent;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: 100%;
    right: 16px;
    border-width: 0 7px 7px 7px;
    border-style: solid;
    border-color: ${colors.surface.light} transparent;
  }
`;

const SettingsContent = styled.div`
  padding: 8px 0;
`;

const SettingsClickableItem = styled.div`
  width: 100%;
  height: 32px;
  padding: 0px 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  background: none;
  outline: none;
  text-decoration: none;

  &:hover {
    background: #eee;
  }
`;

const SettingsBody = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 12.82px;
  letter-spacing: 0.18px;
  line-height: 26px;
  user-select: none;
`;

const NotMobileButton = styled(Button)`
  ${breakpoints["phone-only"]} {
    display: none;
  }
`;
const NotMobileIconButton = styled(IconButton)`
  ${breakpoints["phone-only"]} {
    display: none;
  }
`;

const mobileSpace = 1;
const tabletSpace = 4;
const laptopSpace = 6;

// CSS for overall container
const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: calc(100% + ${mobileSpace * 2}px);
  margin-left: -${mobileSpace}px;
  margin-right: -${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(100% + ${tabletSpace * 2}px);
    margin-left: -${tabletSpace}px;
    margin-right: -${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(100% + ${laptopSpace * 2}px);
    margin-left: -${laptopSpace}px;
    margin-right: -${laptopSpace}px;
  }
`;

// CSS for each box
const NavButton = styled(ButtonBase)`
  height: 40px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  &:hover:enabled {
    opacity: 0.8;
  }

  &:focus:enabled {
    background-color: black;
  }

  &:active:enabled {
    background-color: black;
  }
  width: calc(50% - ${mobileSpace * 2}px);
  margin: ${mobileSpace}px;

  ${breakpoints["tablet-up"]} {
    width: calc(calc(100% / 3) - ${tabletSpace * 2}px);
    margin: ${tabletSpace}px;
  }

  ${breakpoints["laptop-up"]} {
    width: calc(calc(100% / 6) - ${laptopSpace * 2}px);
    margin: ${tabletSpace}px ${laptopSpace}px;
  }
`;

const NavButtonText = styled.span`
  color: ${colors.whiteText.highEmphasis};
  font-family: "Open Sans";
  font-size: 14.82px;
  font-weight: 600;
  letter-spacing: 0.27px;
  text-align: center;
  padding: 0 12px;
`;
