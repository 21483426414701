import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router";
import moment from "moment";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader, {HeaderSubtitle} from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Button from "./../../../components/Button";
import Select from "./../../../components/form/BaseSelect";


class OrgCreateAccount_Subscription extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    const handleGoBack = (e)=>{
      e.preventDefault();
     this.props.setPage("desc")
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Subscription"
        />
        <HeaderSubtitle>
              Please choose the subscription you require for your business. The first month is free.
              <br/>
              The subscription fee will not be charged until {moment().add(60, 'days').format('dddd, [the] Do of MMMM YYYY')}
        </HeaderSubtitle>
        <SectionsContainer>
          <Section>
            <Select
              id="createorg-sub"
              name="Subscription"
              type="select"
              placeholder="Subscription"
              autoFocus={true}
              value={values.Subscription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Subscription && errors.Subscription}
            >    
              <option value="Solo">Solo | $28/mo. | 1 Provider | 2.0% Lyfe Fee | 2.9%+30c Stripe Fee</option>
              <option value="Team">Team | $48/mo. | 5 Providers | 2.0% Lyfe Fee | 2.9%+30c Stripe Fee</option>
              <option value="Medium">Medium | $96/mo. | 10 Providers | 2.0% Lyfe Fee | 2.9%+30c Stripe Fee</option>
              <option value="Large">Large | $165/mo. | 15 Providers | 2.0% Lyfe Fee | 2.9%+30c Stripe Fee</option>   
            </Select>
            {!!errors.Subscription && touched.Subscription && <Error>{errors.Subscription}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
        <Button
            style={{ width: 150, marginRight: "5px", backgroundColor: "rgba(44,46,60,0.12)", color:"rgba(0,0,0,0.38)"}}
            type="button"
            onClick={(handleGoBack)}
          >
            Go Back
          </Button>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={errors.Subscription && !pageData.Subscription}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    Subscription: pageData.Subscription || "Starter-Kit"
  }),
  validationSchema: Yup.object().shape({
    Subscription: Yup.string().required("Subscription is required.")
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    props.setPage("payment", {
        SubscriptionType: values.Subscription,
  });
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountSubscription"
});

export default compose(
  connect((state) => ({})),
  withRouter,
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_Subscription);
