import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "../../../util/form";

// Redux
import apiService from "../../../redux/services/api";

// Custom components
import Button from "../../Button";
import FormHeader from "../../form/FormHeader";
import Form from "../../form/Form";
import FooterContainer from "../../form/FooterContainer";
import { getAuthenticatedUser } from "../../elements/AuthGetUser";

//grpc
import { rpc, metadata } from "../../../grpc";
import { GetUserRequest, GetUserResponse } from "sdk/dist/user_pb";
import {
  GetUserDetailsRequest,
  IsURLValidRequest,
  IsURLValidResponse
} from "sdk/dist/user_invite_pb";
import { ClickedURLRequest, ClickedURLResponse } from "sdk/dist/user_invite_pb";
import { CreateURLRequest, CreateURLResponse } from "sdk/dist/user_invite_pb";
import { SendEmailRequest, SendEmailResponse } from "sdk/dist/user_invite_pb";
import { toastStore } from "../../../stores/toast-store";
import { userService } from "../../../services/UserService";

interface Props {
  data: any;
  values: Values;
  errors: any;
  touched: any;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  setPage: (name: string) => void;
  isSubmitting: boolean;
  pageData: PageData;
  historys: any;
}

interface Values {
  FirstName: string;
  LastName: string;
  EmailPhone: string;
  PhoneEmail: string;
  Email: string;
  MobileNumber: string;
  MobileCountryCode: string;
  Password: string;
  PasswordCheck: string;
}

interface PageData {
  Email: string;
  MobileNumber: string;
  MobileCountryCode: string;
  ID: string;
  userId: string;
}

interface State {
  userID: string | null;
  email: string | null;
  mobile: string | null;
  mobileCountryCode: string | null;
}

interface FormattedResponse {
  FirstName: string;
  LastName: string;
  MobileNumber: string;
  MobileCountryCode: string;
  DateOfBirth: string;
  Email: string;
}

class VerifyURLToken extends Component<Props> {
  constructor(Props: Props) {
    super(Props);
  }

  componentDidMount() {
    this.handleLogout();
    this.isURLValid();
  }

  handleLogout = async () => {
    const { values, setPage } = this.props;
    try {
      await apiService.userLogout();
    } catch (err) {
      parseFormError(err, values, () => {}, this.props);
    }
  };

  state: State = {
    userID: null,
    email: null,
    mobile: null,
    mobileCountryCode: null
  };

  async getUser() {
    let formattedResponse: FormattedResponse;
    try {
      const res = await userService.getUserDetailsFromInvitationUrl(
        `${window.location.origin}${window.location.pathname}`
      );
      if (res && res.user && res.user.status == "Active") {
        toastStore.success("Account has already been created");
        this.props.historys.push("/");
      }
      if (res && res.user) {
        formattedResponse = {
          FirstName: res.user.firstName,
          LastName: res.user.lastName,
          MobileNumber: res.user.mobileNumber,
          MobileCountryCode: res.user.mobileCountryCode,
          DateOfBirth: res.user.dateOfBirth,
          Email: res.user.email
        };
        return formattedResponse;
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  // TODO: function name does not reflect its implementation
  // this function is bit of too much
  async isURLValid() {
    let isURLValidResponse: IsURLValidResponse.AsObject;
    try {
      const req = new IsURLValidRequest();
      req.setUrl(window.location.origin + window.location.pathname);
      const res = await rpc.userInvite.isURLValid(req, metadata());
      isURLValidResponse = res.toObject();
      const user = await this.getUser();
      if (!user) {
        toastStore.error("Invalid url");
        return;
      }
      if (user.Email && user.MobileNumber) {
        user.Email == "" ? null : this.setState({ email: user.Email }),
          user.MobileNumber == ""
            ? null
            : this.setState({
                mobile: user.MobileNumber,
                mobileCountryCode: user.MobileCountryCode
              });
      }
      Object.keys(user).map((key, i) => {
        this.props.pageData[key] = user[key];
      });
      if (isURLValidResponse.valid == true) {
        this.setURLClicked();
        this.props.pageData.ID = isURLValidResponse.userId;
        this.props.pageData.userId = isURLValidResponse.userId;
        this.props.setPage("password");
      } else if (this.state.email != null || this.state.mobile != null) {
        this.setState({ userID: isURLValidResponse.userId });
      }
      return isURLValidResponse;
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async setURLClicked() {
    let clickURLResponse = {};
    try {
      const req = new ClickedURLRequest();
      req.setUrl(window.location.origin + window.location.pathname);
      const res = await rpc.userInvite.clickedURL(req, metadata());
      clickURLResponse = res.toObject();
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async reSendInvitation() {
    try {
      // create URL
      let createURLResponse: CreateURLResponse.AsObject;
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(this.props.pageData.userId);
      const urlRes = await rpc.userInvite.createURL(urlReq, metadata());
      createURLResponse = urlRes.toObject();

      // send Email/sms
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(this.props.pageData.userId);
      emailReq.setUrl(createURLResponse.url);
      const emailRes = await rpc.userInvite.sendEmail(emailReq, metadata());
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  hideemail = (email: string) => {
    var hiddenEmail = "";
    var i;
    for (i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  };

  hidemobile = (mobile: string) => {
    var hiddenMobile = "";
    var i;
    for (i = 0; i < mobile.length; i++) {
      if (i > 6) {
        hiddenMobile += mobile[i];
      } else {
        hiddenMobile += "*";
      }
    }
    return hiddenMobile;
  };

  getButtonText = () => {
    if (this.state.email) {
      return "Resend Email to " + this.hideemail(this.state.email);
    } else if (this.state.mobile) {
      return "Resend SMS to " + this.hidemobile(this.state.mobile);
    } else {
      return "Error: No details found";
    }
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader title="Oops, looks like your link has expired" />
        <p style={{ color: "grey" }}>
          Click the link below to resend the inviation, alternatively click the signup button or
          contact your provider to resend the invitation
        </p>
        <FooterContainer>
          {this.state.email || this.state.mobile ? (
            <Button
              style={{ width: "300px", marginTop: "30px" }}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
            >
              {this.getButtonText()}
            </Button>
          ) : (
            <div></div>
          )}
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }: any) => ({}),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      // create URL
      let createURLResponse: CreateURLResponse.AsObject;
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(props.pageData.ID);
      const urlRes = await rpc.userInvite.createURL(urlReq, metadata());
      createURLResponse = urlRes.toObject();

      // send Email/sms
      let emailResponse: SendEmailResponse.AsObject;
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(props.pageData.ID);
      emailReq.setUrl(createURLResponse.url);
      const emailRes = await rpc.userInvite.sendEmail(emailReq, metadata());
      emailResponse = emailRes.toObject();
      if (emailResponse.userId) {
        props.toastManager.add(`Invitation has been resent`, {
          appearance: "success"
        });
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

export default compose<any, any>(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(VerifyURLToken);
