import * as Sentry from '@sentry/browser';
import React, { Component } from "react";
import { connect } from "react-redux";

import { getResource } from "./../../redux/features/resources/helpers";

class ResourceRender extends Component {
  state = {
    errorCode: null
  }

  componentDidCatch(error, info) {
    if (Sentry) {
      if (process.env.BOOKLYFE_ENVIRONMENT.includes("production")) {
        console.warn("Unknown Internal Error", error);
        Sentry.captureException(error, { extra: info });
      } else {
        console.error(error);
      }
      this.setState({ errorCode: Sentry.lastEventId() || "unknown" });
    }
  }

  render() {
    const { errorCode } = this.state;
    const {
      resource,
      compLoading: CompLoading,
      compSuccess: CompSuccess,
      compError: CompError,
      forwardedProps,
      propsReq
    } = this.props;

    if (errorCode) {
      return !CompError ? null : <CompError errorOveride={errorCode} {...forwardedProps} />;
    }
    this.lastRes = resource;
    if (resource.$Metadata.DataStatus === "Ok") {
      if (propsReq && propsReq.filter((v) => !resource.hasOwnProperty(v) || resource[v] === null).length > 0) {
        return !CompLoading ? null : <CompLoading resource={resource} {...forwardedProps} />;
      } else {
        return !CompSuccess ? null : <CompSuccess resource={resource} {...forwardedProps} />;
      }
    } else if (resource.$Metadata.DataStatus === "Error") {
      return !CompError ? null : <CompError resource={resource} {...forwardedProps} />;
    } else {
      return !CompLoading ? null : <CompLoading resource={resource} {...forwardedProps} />;
    }
  }
}

function mapStateToProps(state, props) {
  const { type, ids, denorm } = props;
  const { auth, resources } = state;

  return {
    auth,
    resource: getResource(resources, type, ids, denorm)
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ResourceRender);
