import classNames from "classnames";
import React from "react";
import { FlexBox, FlexItem } from "./FlexBox";
import { ErrorMessage } from "./form/ErrorMessage";
import { Typography } from "./Typography";

interface IProps {
  label: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (v?: string) => void;
  error?: string;
  rows?: number;
  required?: boolean;
}

export const TextArea = (props: IProps) => {
  return (
    <div className="mb-4 sm:mb-6">
      <FlexBox spacing={[0, 1]} direction="col">
        <FlexItem>
          <Typography.Body color="primary" noPadding>
            {props.label} {props.required && "*"}
          </Typography.Body>
        </FlexItem>
        <FlexItem>
          <div className="relative rounded-md shadow-sm">
            <textarea
              disabled={props.disabled}
              name={props.label}
              rows={props.rows || 4}
              id={props.label.toLowerCase()}
              value={props.value || ""}
              onChange={(v) => props.onChange && props.onChange(v.target.value)}
              className={classNames(
                "resize-y mt-1 block w-full rounded-md bg-gray-100 border-transparent text-sm",
                props.error
                  ? "ring-danger border-danger focus:bg-white"
                  : "focus:ring-primary-500 focus:border-primary-900 focus:bg-white",
                props.disabled && "disabled:opacity-50 cursor-not-allowed"
              )}
              placeholder={props.placeholder}
            />
          </div>
        </FlexItem>
        {props.error && (
          <FlexItem>
            <ErrorMessage messages={[props.error]} />
          </FlexItem>
        )}
      </FlexBox>
    </div>
  );
};
