import { metadata, rpc } from "../grpc";
import {
  CreateCreditCardRequest,
  CreditCard,
  DeleteCreditCardRequest,
  ListCreditCardsRequest,
  SetPrimaryCardRequest
} from "sdk/dist/credit_cards_pb";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class OrganizationCardService {
  async create(token: string, id: string, type?: CreditCard.OwnerType) {
    const req = new CreateCreditCardRequest();
    req.setOwner(id);
    req.setToken(token);
    if (type) {
      req.setOwnerType(type);
    }
    req.setOwnerType(CreditCard.OwnerType.ORGANISATION);

    return (await rpc.orgCards.create(req, metadata())).toObject();
  }

  @grpcResponseHandler({ successMessage: "new credit card successfully set as primary" })
  async setPrimaryCard(id: string) {
    const req = new SetPrimaryCardRequest();
    req.setCardId(id);
    req.setOwnerType(CreditCard.OwnerType.ORGANISATION);

    return (await rpc.orgCards.setPrimaryCard(req, metadata())).toObject();
  }

  @grpcResponseHandler({ successMessage: "credit card removed successfully" })
  async deleteCard(id: string) {
    const req = new DeleteCreditCardRequest();
    req.setCardId(id);
    req.setOwnerType(CreditCard.OwnerType.ORGANISATION);

    return (await rpc.orgCards.delete(req, metadata())).toObject();
  }

  @grpcResponseHandler()
  async getCards(id: string) {
    const req = new ListCreditCardsRequest();
    req.setOwner(id);
    req.setOwnerType(CreditCard.OwnerType.ORGANISATION);

    return (await rpc.orgCards.list(req, metadata())).toObject().cardsList;
  }
}

export const sharedOrganizationCardService = new OrganizationCardService();
