import React, { Component } from "react";
import styled from '@emotion/styled';

// components
import LargeDottedButton from "./../../../components/LargeDottedButton";

// import { AddButton } from "./../../../components/elements/AccordianElements";

import { TabContainer, TabHeader, TabDetail } from "./index";

import FundAccordian, { availFunds } from "./FundAccordian";
import ResourceFetch from './../../../components/elements/ResourceFetch';
import ResourceRender from './../../../components/elements/ResourceRender';

interface Props {
  resource: any;
  rightMessage: any;
  rightMessageProps: any;
  disallowNew: any;
  noshowList: any;
}

class Funds extends Component<Props> {
  state = {
    adding: false
  };

  addCard = () => {
    this.setState({ adding: true });
  };

  closeNew = () => {
    this.setState({ adding: false });
  };

  render() {
    const { resource: client, rightMessage, rightMessageProps, disallowNew, noshowList } = this.props;
    const fundsLeft = availFunds.filter((v)=>!client.FundProperties[v]).length;
    return (
      <TabContainer>
        <TabHeader>
          <div>Client's Funds</div>
          {rightMessage && <TabDetail {...rightMessageProps}>{rightMessage}</TabDetail>}
        </TabHeader>
        {!noshowList && Object.keys(client.FundProperties).map((k) => (
          <FundAccordian key={k} idRef={k} client={client} fund={k} />
        ))}
        {!this.state.adding ? null : (
          <FundAccordian
            idRef="newref"
            client={client}
            fund={""}
            closeNew={this.closeNew}
          />
        )}
        {(this.state.adding || fundsLeft === 0 || disallowNew) ? null : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW FUND</LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}

interface ActualProps {
  clientId: string;
}

export default class Loader extends React.Component<ActualProps> {
  render() {
    return (
      <>
        <ResourceFetch type="Client" ids={this.props.clientId} force />
        <ResourceRender
          type="Client"
          ids={this.props.clientId}
          compSuccess={Funds}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}

const Small = styled.span`
  font-size: 10px;
  opacity: 0.4;
  margin-left: 10px;
`;
