import classNames from "classnames";
import React from "react";
import styled from "styled-components";

interface IProps {
  children: React.ReactNode;
  rounded?: "sm" | "md" | "full";
  shadow?: boolean;
  border?: { color: "" };
  fullHeight?: boolean;
}

export const Card = (props: IProps) => {
  return (
    <div
      className={classNames(
        props.shadow && "shadow",
        getBorderRadius(props.rounded),
        props.fullHeight && "h-full"
      )}
    >
      {props.children}
    </div>
  );
};

interface IColoredCardProps extends Omit<IProps, "shadow"> {
  variant: "primary" | "blue" | "tertiary";
}

const ColoredCardVariant = (props: IColoredCardProps) => {
  return (
    <CardContainer
      variant={props.variant}
      className={classNames(
        "shadow-md border-t-8 transform hover:scale-110",
        getBorderRadius(props.rounded),
        props.fullHeight && "h-full",
        getColorIntent(props.variant)
      )}
    >
      {props.children}
    </CardContainer>
  );
};

const CardContainer = styled.div<{ variant: IColoredCardProps["variant"] }>`
  border-left: rgba(245, 245, 245, var(--tw-border-opacity));
  border-right: rgba(245, 245, 245, var(--tw-border-opacity));
`;

const getColorIntent = (color?: IColoredCardProps["variant"]) => {
  switch (color) {
    case "primary":
      return "border-primary-700";
    case "tertiary":
      return "border-tertiary-200";
    case "blue":
      return "border-blue-400";
    default:
      return "";
  }
};

const getBorderRadius = (rounded?: IProps["rounded"]) => {
  switch (rounded) {
    case "full":
      return "rounded-full";
    case "md":
      return "rounded-md";
    case "sm":
      return "rounded-sm";
    default:
      return "rounded-none";
  }
};

Card.Colored = ColoredCardVariant;
