import styled from "@emotion/styled";
import { Grid } from "@material-ui/core";
import Markdown from 'markdown-to-jsx';
import React, { Component } from "react";
import typography from "./../../util/typography";
import FAQClient from './FAQClients';
import FAQProvider from './FAQProviders';


export default class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = { selectFirst: true };
  }
  render() {
    const handleTabChange = () => {
      this.setState({ selectFirst: !this.state.selectFirst });
    };
    const JSONFile = this.state.selectFirst ? FAQClient : FAQProvider
    return (
      <StyledContainer>
        <Header>
          <Title>Frequently Asked Questions</Title>
        </Header>
        <Content>
          <Tabs onClick={handleTabChange}>
            {this.state.selectFirst ? <ClientActive /> : <ProviderActive />}
          </Tabs>
          { JSONFile.map((item, index)=>{
            if(item.hideOnUS && process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"){
              return false
            }
            if (item.hideOnAU && process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU"){
              return false
            }
           return <Provider key={index} question={item.question} answer={item.answer}/>
          })
          }
          </Content>
      </StyledContainer>
    );
  }
}

class ClientActive extends Component {
  render() {
    return (
      <>
        <Active class="c-tab c-tab--active">
          <a href={'#'}style={{ color: "white", textDecoration: "none" }}>For Clients</a>
        </Active>
        <InActive class="c-tab">
          <a style={{ color: "black", textDecoration: "none" }}>For Providers</a>
        </InActive>
      </>
    );
  }
}

class ProviderActive extends Component {
  render() {
    return (
      <>
        <InActive class="c-tab c-tab--active">
          <a style={{ color: "black", textDecoration: "none" }}>For Clients</a>
        </InActive>
        <Active class="c-tab">
          <a style={{ color: "white", textDecoration: "none" }}>For Providers</a>
        </Active>
      </>
    );
  }
}

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false, hovering: false };
  }
  render() {
    const handleCollapse = () =>{
      this.setState({expanded: !this.state.expanded})
    }
    return (
      <QuestionItem>
        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center">
          <Grid container item xs={12} onClick={handleCollapse} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={1}>
              <StyledIcon xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" rotate={this.state.expanded}>
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                <path d="M0 0h24v24H0V0z" fill="none"></path>
              </StyledIcon>
            </Grid>
            <Grid item xs={11}>
              <StyledQuestionBlock>{this.props.question}</StyledQuestionBlock>
            </Grid>
          </Grid>

          {this.state.expanded ? <Grid xs={11} item><StyledMarkdown><Markdown >{this.props.answer}</Markdown> </StyledMarkdown></Grid>: false}
        </Grid>
      </QuestionItem>
    );
  }
}

const Tabs = styled.ul`
margin-top: 0;
margin-bottom: 1.5rem;
display: flex;
flex-direction: row;
clear: both;
background: #fff;
`;

const StyledMarkdown = styled.div`
color: #2c2e3c;
font-size: 14.8px;
letter-spacing: 0.5px;
line-height: 28px;
margin: 0px 0px 12px;
margin-top: 1rem;
`;

const QuestionItem = styled.div`
padding: 1rem 0;
align-items: center;
flex-direction: row;
color: #000;
font-size: 1.2rem;
border-top: 1px solid #ddd;
transition: color .25s;
cursor: pointer;
&:hover {
  color: steelblue;
}

ul {
  margin-left: 2rem;
}
`;

const InActive = styled.li`
  cursor: pointer;
  padding: 0.75rem;
  display: block;
  width: 100%;
  color: #000;
  background: #ecf7ff;
  font-size: 0.85rem;
  text-align: center;
  text-transform: uppercase;
`;

const Active = styled.li`
  cursor: pointer;
  padding: 0.75rem;
  display: block;
  width: 100%;
  color: #000;
  background: #ecf7ff;
  font-size: 0.85rem;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #0f75c2;
  color: #fff;
  background: #0f75c2;
`;

const StyledContainer = styled.div`
  max-width: 826px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  padding: 50px 0;
`;

const Content = styled.div`
  padding: 30px 0;
`;

const Title = styled.h2`
  ${typography.heading3};
`;

const StyledQuestionBlock = styled.h3`
  font-size: 1.17em;
  font-weight: bold;
  &:hover {
    color: steelblue;
  }
`;

const StyledIcon = styled.svg`
  margin: auto;
  transform: ${({rotate}) => rotate ? 'rotate(90deg)': 'none'};
`;
