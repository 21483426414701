import React, { PureComponent } from "react";
import { colors } from "./../../util/consts";

export default class Logo extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 56 33"
        width="56"
        height="33"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <g fill="white" fillRule="evenodd">
          <path d="M12.6 24c.24 0 .35.13.35.38s-.11.37-.35.37H7.82c-2.16 0-4-.8-5.53-2.42A8.17 8.17 0 0 1 0 16.5V1.28C0 1.03.12.9.36.9c.22 0 .33.13.33.38V16.5c0 2.07.7 3.84 2.1 5.3A6.67 6.67 0 0 0 7.82 24h4.78zM26.99 24.1c0 2.23-.68 4.15-2.04 5.73a6.21 6.21 0 0 1-4.9 2.39c-2.44 0-4.35-1.18-5.73-3.54-.13-.2-.1-.37.1-.5s.35-.09.47.11c1.25 2.14 2.97 3.2 5.16 3.2a5.6 5.6 0 0 0 4.43-2.18 7.79 7.79 0 0 0 1.84-5.21v-4.26a7.67 7.67 0 0 1-2.53 3.34 6.09 6.09 0 0 1-3.74 1.29c-1.92 0-3.56-.8-4.9-2.38a8.51 8.51 0 0 1-2.05-5.74V8.6c0-.24.11-.37.34-.37.22 0 .33.13.33.37v7.75c0 2.04.61 3.77 1.84 5.22a5.64 5.64 0 0 0 8.87 0 7.78 7.78 0 0 0 1.84-5.22V8.6c0-.24.1-.37.33-.37.22 0 .34.13.34.37v15.5zM37.65.83c.23 0 .35.13.35.38s-.12.38-.35.38c-1.77 0-3.31.64-4.63 1.92a7.57 7.57 0 0 0-2.28 4.73h6.58c.23 0 .35.13.35.38s-.12.38-.35.38h-6.64v15.51c0 .26-.11.38-.33.38-.23 0-.35-.12-.35-.38V9.16c0-.17 0-.34.02-.54A8.34 8.34 0 0 1 32.4 3.1 6.72 6.72 0 0 1 37.65.83M55.88 13.55v.01c.08.23.02.4-.2.48l-11.2 4.84a.26.26 0 0 1-.13.03c-.16 0-.26-.08-.31-.24-.09-.23-.03-.38.19-.48l10.89-4.7a7.44 7.44 0 0 0-2.6-3.27 6.44 6.44 0 0 0-3.82-1.25 6.5 6.5 0 0 0-4.93 2.2 7.5 7.5 0 0 0-2.07 5.32c0 2.07.69 3.85 2.07 5.33a6.5 6.5 0 0 0 4.93 2.2c2.28 0 4.12-.97 5.51-2.9.15-.2.31-.22.5-.07.17.15.2.32.06.53a7.17 7.17 0 0 1-6.07 3.17c-2.11 0-3.92-.8-5.43-2.42a8.28 8.28 0 0 1-2.25-5.84c0-2.28.75-4.23 2.25-5.85a7.17 7.17 0 0 1 5.43-2.4c1.58 0 3.02.48 4.34 1.45a7.9 7.9 0 0 1 2.82 3.82l.02.04" />
        </g>
      </svg>
    );
  }
}
