import React, { Component } from "react";
import styled from "@emotion/styled";

import typography from "./../../util/typography";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { ChevronLeftIcon } from "../../ui-kit/icons/ChevronLeftIcon";
import { useHistory } from "react-router";

const PrivacyPolicy = () => {
  const history = useHistory();
  return (
    <Container>
      <FlexBox>
        <FlexItem>
          <div
            style={{ paddingTop: "1.25rem" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <ChevronLeftIcon />
          </div>
        </FlexItem>
        <FlexItem>
          <Header>
            <Title>Lyfe Group Pty Ltd - Privacy Policy</Title>
            <Subtitle>LAST UPDATED: March 29, 2021</Subtitle>
          </Header>
        </FlexItem>
      </FlexBox>
      <Content>
        <Body>
          This web site is owned and operated by Lyfe Group Pty Ltd and will be referred to as “We”,
          “our” and “us” in this Internet Privacy Policy. By using this site, you agree to the
          Internet Privacy Policy of this web site (“the web site”), which is set out on this web
          site page. The Internet Privacy Policy relates to the collection and use of personal
          information you may supply to us through your conduct on the web site.
        </Body>
        <Body>
          We reserve the right, at our discretion, to modify or remove portions of this Internet
          Privacy Policy at any time. This Internet Privacy Policy is in addition to any other terms
          and conditions applicable to the web site. We do not make any representations about third
          party web sites that may be linked to the web site.
        </Body>
        <Body>
          We recognise the importance of protecting the privacy of information collected about
          visitors to our web site, in particular information that is capable of identifying an
          individual (“personal information”). This Internet Privacy Policy governs the manner in
          which your personal information, obtained through the web site, will be dealt with. This
          Internet Privacy Policy should be reviewed periodically so that you are updated on any
          changes. We welcome your comments and feedback.
        </Body>
        <SectionTitle>Personal Information</SectionTitle>
        <UnorderedList>
          <ListItem>
            Personal information about visitors to our site is collected only when knowingly and
            voluntarily submitted. For example, we may need to collect such information to provide
            you with further services or to answer or forward any requests or enquiries. It is our
            intention that this policy will protect your personal information from being dealt with
            in any way that is inconsistent with applicable privacy laws in Australia.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Use of Information</SectionTitle>
        <UnorderedList>
          <ListItem>
            Personal information that visitors submit to our site is used only for the purpose for
            which it is submitted or for such other secondary purposes that are related to the
            primary purpose, unless we disclose other uses in this Internet Privacy Policy or at the
            time of collection. Copies of correspondence sent from the web site, that may contain
            personal information, are stored as archives for record-keeping and back-up purposes
            only.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Collecting information on Registered members</SectionTitle>
        <UnorderedList>
          <ListItem>
            As part of registering with us, we collect personal information about you in order for
            you to take full advantage of our services. To do this it may be necessary for you to
            provide additional information to us as detailed below.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Registration</SectionTitle>
        <UnorderedList>
          <ListItem>
            Registration is completely optional. Registration may include submitting your name,
            email address, address, telephone numbers, option on receiving updates and promotional
            material and other information. You may access this information at any time by logging
            in and going to your account.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Credit Card Details</SectionTitle>
        <UnorderedList>
          <ListItem>Credit Card details are only stored for the processing of payment.</ListItem>
          <ListItem>Lyfe Group is A-EP Level 4 PCI compliant.</ListItem>
        </UnorderedList>
        <SectionTitle>Direct Debit Details</SectionTitle>
        <UnorderedList>
          <ListItem>Account details are only stored for the processing of payment.</ListItem>
        </UnorderedList>
        <SectionTitle>Disclosure</SectionTitle>
        <UnorderedList>
          <ListItem>
            Apart from where you have consented or disclosure is necessary to achieve the purpose
            for which it was submitted, personal information may be disclosed in special situations
            where we have reason to believe that doing so is necessary to identify, contact or bring
            legal action against anyone damaging, injuring, or interfering (intentionally or
            unintentionally) with our rights or property, users, or anyone else who could be harmed
            by such activities. Also, we may disclose personal information when we believe in good
            faith that the law requires disclosure.
          </ListItem>
          <ListItem>
            We may engage third parties to provide you with goods or services on our behalf. In that
            circumstance, we may disclose your personal information to those third parties in order
            to meet your request for goods or services.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Security and Data Storage</SectionTitle>
        <UnorderedList>
          <ListItem>
            We strive to ensure the security, integrity and privacy of personal information
            submitted to our sites, and we review and update our security measures in light of
            current technologies. Unfortunately, no data transmission over the Internet can be
            guaranteed to be totally secure.
          </ListItem>
          <ListItem>
            However, we will endeavour to take all reasonable steps to protect the personal
            information you may transmit to us or from our online products and services. Once we do
            receive your transmission, we will also make our best efforts to ensure its security on
            our systems.
          </ListItem>
          <ListItem>
            In addition, our employees and the contractors who provide services related to our
            information systems are obliged to respect the confidentiality of any personal
            information held by us. However, we will not be held responsible for events arising from
            unauthorised access to your personal information.
          </ListItem>
          <ListItem>
            Google Cloud Storage:
            <UnorderedList>
              <ListItem>Unlimited storage with no minimum object size</ListItem>
              <ListItem>Worldwide accessibility and worldwide storage locations</ListItem>
              <ListItem>Low latency</ListItem>
              <ListItem>High durability (99.999999999% annual durability)</ListItem>
              <ListItem>
                Encrypt object data with encryption keys stored by the Cloud Key Management Service.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Google Kubernetes Engine(GKE):
            <UnorderedList>
              <ListItem>
                High-availability control plane including multi-zonal and regional clusters
              </ListItem>
              <ListItem>
                Secure by default, including vulnerability scanning of container images and data
                encryption
              </ListItem>
              <ListItem>
                GKE clusters natively support Kubernetes Network Policy to restrict traffic with
                pod-level firewall rules.
              </ListItem>
              <ListItem>
                Database accessible from the application container only and not from outside the
                cluster
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Google Virtual Private Cloud(VPC):
            <UnorderedList>
              <ListItem>Virtual topology with network policies used by our GKE instance.</ListItem>
              <ListItem>Allow private access to Google services.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Google Cloud SQL: PostgreSQL database:
            <UnorderedList>
              <ListItem>
                Customer data encrypted on Google's internal networks and in database tables,
                temporary files, and backups.
              </ListItem>
              <ListItem>
                Automated and on-demand daily backups, which are stored for 7 days.
              </ListItem>
              <ListItem>All connections to the database are through SSL/TLS.</ListItem>
              <ListItem>Instances available in US, EU, Asia, and Australia.</ListItem>
              <ListItem>Data replication between multiple zones with automatic failover.</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <Body>
          You can see the Google Cloud terms here{" "}
          <a href="https://cloud.google.com/terms">https://cloud.google.com/terms</a>.
        </Body>
        <SectionTitle>Collecting Information from Users</SectionTitle>
        <UnorderedList>
          <ListItem>
            IP Addresses Our web servers gather your IP address to assist with the diagnosis of
            problems or support issues with our services. Again, information is gathered in
            aggregate only and cannot be traced to an individual user.
          </ListItem>
          <ListItem>
            Cookies and Applets We use cookies to provide you with a better experience. These
            cookies allow us to increase your security by storing your session ID and are a way of
            monitoring single user access. This aggregate, non-personal information is collated and
            provided to us to assist in analysing the usage of the site.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Access to Information</SectionTitle>
        <UnorderedList>
          <ListItem>
            We will endeavour to take all reasonable steps to keep secure any information which we
            hold about you, and to keep this information accurate and up to date. If, at any time,
            you discover that information held about you is incorrect, you may contact us to have
            the information corrected.
          </ListItem>
          <ListItem>
            In addition, our employees and the contractors who provide services related to our
            information systems are obliged to respect the confidentiality of any personal
            information held by us.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Links to other sites</SectionTitle>
        <UnorderedList>
          <ListItem>
            We provide links to Web sites outside of our web sites, as well as to third party Web
            sites. These linked sites are not under our control, and we cannot accept responsibility
            for the conduct of companies linked to our website. Before disclosing your personal
            information on any other website, we advise you to examine the terms and conditions of
            using that Web site and its privacy statement.
          </ListItem>
        </UnorderedList>
        <SectionTitle>Problems or questions</SectionTitle>
        <UnorderedList>
          <ListItem>
            If we become aware of any ongoing concerns or problems with our web sites, we will take
            these issues seriously and work to address these concerns. If you have any further
            queries relating to our Privacy Policy, or you have a problem or complaint, please
            contact us.
          </ListItem>
          <ListItem>
            For more information about privacy issues in Australia and protecting your privacy,
            visit the Australian Federal Privacy Commissioner’s web site.
            <a href="http://www.privacy.gov.au/"> http://www.privacy.gov.au/</a>.
          </ListItem>
        </UnorderedList>
      </Content>
    </Container>
  );
};

export default PrivacyPolicy;

const Container = styled.div`
  max-width: 826px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  padding: 50px 0;
`;

const Content = styled.div`
  padding: 30px 0;
`;

const UnorderedList = styled.ul`
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
`;

const ListItem = styled.li`
  ${typography.body1};
`;

const Title = styled.h2`
  ${typography.heading4};
`;

const Subtitle = styled.p`
  ${typography.subtitle1};
  font-weight: 600;
  opacity: 0.6;
  text-transform: uppercase;
  margin-top: 24px;
`;

// ${typography.heading6};
const SectionTitle = styled.h6`
  ${typography.subtitle1};
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const Body = styled.p`
  ${typography.body1};
  margin-bottom: 24px;
`;
