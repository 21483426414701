import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import { colors, breakpoints } from "./../../util/consts";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Input from "./Input";
import Select from "./../../components/form/BaseSelect";

export default class StyledDayTimePickerInput extends PureComponent {
  times = [];

  componentWillMount(){

    for (let i = 0; i < 24; i++) {
      const hour24 = `${i < 10 ? "0" : ""}${i}`;
      const h = i === 0 ? 12 : i;
      const hour12 = `${h < 10 ? "0" : ""}${h <= 12 ? h : h - 12}`;
      const ap = i < 12 ? "am" : "pm";
      for (let j = 0; j < 60; j += this.props.interval) {
        const min = `${j < 10 ? "0" : ""}${j}`;
        this.times.push({
          label: `${hour12}:${min}${ap}`,
          value: `${hour24}:${min}:00`
        });
      }
    }
  }
  handleDayChange = (res) => {
    const { dayName, onChange } = this.props;
    onChange({ target: { name: dayName, value: res } });
  };
  handleStartChange = (res) => {
    const { startName, startValue, endName, endValue, onChange } = this.props;
    onChange({ target: { name: startName, value: res.target.value } });
    const svOff = this.times.findIndex((v)=>v.value===startValue);
    const evOff = this.times.findIndex((v)=>v.value===endValue);
    const nsvOff = this.times.findIndex((v)=>v.value===res.target.value);
    if (nsvOff >= 0 && svOff >= 0 && evOff >= 0) {
      const et = this.times[Math.min(nsvOff + (evOff - svOff), this.times.length-1)];
      onChange({ target: { name: endName, value: et.value } });
    }
  };
  handleEndChange = (res) => {
    const { endName, onChange } = this.props;
    onChange({ target: { name: endName, value: res.target.value } });
  };
  render() {
    const {
      id,
      dayValue,
      dayName,
      startName,
      startValue,
      endName,
      endValue,
      startDisabled,
      endDisabled,
      placeholder,
      error,
      interval
    } = this.props;
    const svOff = this.times.findIndex((v)=>v.value===startValue);
    const evOff = this.times.findIndex((v)=>v.value===endValue);
    return (
      <Container error={error} disabled={startDisabled}>
        <DatePicker
          selected={dayValue}
          onChange={this.handleDayChange}
          dateFormat="E, MMMM do, yyyy"
          customInput={<StyledInput id={id} type="text" name={dayName} placeholder={placeholder} />}
          disabled={startDisabled}
          popperPlacement="top-end"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "scrollParent"
            }
          }}
        />
        <TimeSelect
          name={startName}
          value={startValue}
          disabled={startDisabled}
          onChange={this.handleStartChange}
        >
          <option key={""} value={""}>--:--</option>
          {this.times
            .map((tv) => <option key={tv.value} value={tv.value}>{tv.label}</option>)}
        </TimeSelect>
        <SepText>{" - "}</SepText>
        <TimeSelect
          name={endName}
          value={endValue}
          disabled={endDisabled}
          onChange={this.handleEndChange}
        >
          <option key={""} value={""}>--:--</option>
          {this.times
            .filter((v) => !startValue || v.value.localeCompare(startValue) > 0)
            .map((tv) => <option key={tv.value} value={tv.value}>{tv.label}</option>)}
        </TimeSelect>
        {svOff >= 0 && evOff >= 0 &&
          <TotalText>
            ({interval*(evOff-svOff)}mins)
          </TotalText>}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 450px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &:focus-within {
    box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.15);
  }
  ${breakpoints["laptop-up"]} {
    flex-grow: 0;
  }
  ${({ disabled }) => (!disabled ? "" : "border: none; padding: 0")};
`;

const StyledInput = styled(Input)`
  height: 40px;
  border: none;
  outline: none;
  flex: 1 0 100px;
  padding-left: 0;
`;

const TimeSelect = styled(Select)`
  height: 40px;
  border: none;
  outline: none;
  flex: 0 0 75px;
  padding: 0 5px;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  text-align-last: center;
`;

const SepText = styled.div`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  color: ${colors.surfaceText.highEmphasis};
  border: none;
  outline: none;
  flex: 0 0 10px;
  padding: 0;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  text-align: center;
`;

const TotalText = styled.div`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  color: ${colors.surfaceText.highEmphasis};
  border: none;
  outline: none;
  flex: 0 0 10px;
  padding: 0;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
`;
