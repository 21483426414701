import React, { useEffect, useState } from "react";
import SearchInput from "./form/SearchInput";
import { rpc, metadata } from "../grpc";
import { Provider } from "sdk/dist/bookings_pb";
import { throttle } from "lodash";
import { GetLocationProviderRequest } from "sdk/dist/location_pb";
import UserAvatar from "./UserAvatar";
import styled from "@emotion/styled";
import typography from "../util/typography";
import { toJS } from "mobx";
import { colors, hexToRgba } from "../util/consts";
import Avatar from "./Avatar";

interface Props {
  locationId: string;
  provName: string;
  disabled: boolean;
  setProvName: (value: string) => void;
  onSelect: (Provider: Provider.AsObject | undefined) => void;
}

export const LocationProviderSelector: React.FC<Props> = (props) => {
  const [locProviders, setLocProviders] = useState<Provider.AsObject[]>([]);

  useEffect(() => {
    async function getLocProvs() {
      const req = new GetLocationProviderRequest();
      req.setLocationId(props.locationId);
      const res = await rpc.location.getProviders(req, metadata());
      setLocProviders(res.toObject().providersList);
    }
    getLocProvs();
  }, []);

  const load = throttle(async (search: string = "") => {
    const req = new GetLocationProviderRequest();
    req.setLocationId(props.locationId);
    req.setNameSearch(search);
    const res = await rpc.location.getProviders(req, metadata());
    setLocProviders(res.toObject().providersList);
    props.setProvName(search);
  }, 2000);

  const label = (prov: Provider.AsObject) => {
    return `${prov.firstName} ${prov.lastName}`;
  };

  return (
    <>
      {props.disabled ? (
        props.provName && (
          <DropItem>
            <Avatar letters={props.provName} size={30} />
            <DropItemText>&nbsp;&nbsp;{props.provName} </DropItemText>
          </DropItem>
        )
      ) : (
        <SearchInput
          name="provider"
          placeholder="Select a provider"
          hasClearButton
          items={locProviders.map((prov) => ({
            label: label(prov),
            value: prov
          }))}
          setFieldValue={(_, value) => {
            props.onSelect(value);
          }}
          onInputValueChange={(value) => load(value)}
          inputValue={props.provName}
          allowFreeText
        />
      )}
    </>
  );
};

const DropItem = styled.li<any>`
  position: relative;
  height: 40px;
  padding: 14px;
  align-items: center;
  background-color: ${({ highlighted }) =>
    !highlighted ? "none" : hexToRgba(colors.primary.main, 0.05)};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const DropItemText = styled.div`
  ${typography.overline};
  letter-spacing: inherit;
`;
