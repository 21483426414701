import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import { toastStore } from "../../../stores/toast-store";
import Select from "./../../../components/form/BaseSelect";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
// Components
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
// Utils
import { parseFormError } from "./../../../util/form";

interface Props {
  values: any;
  errors: any;
  touched: any;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  rollSubsToggle: (b: boolean) => void;
  isSubmitting: boolean;
}
class OrgEditAccount_SubAct extends Component<Props> {
  render() {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } =
      this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Reactivate Subscription"
          subtitle="To reactivate your subscription choose the type and click the button below."
        />
        <SectionsContainer>
          <Section>
            <Select
              id="editorg-sub"
              name="Subscription"
              type="select"
              placeholder="Subscription"
              autoFocus={true}
              value={values.Subscription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Subscription && errors.Subscription}
              defaultValue=""
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Starter">Starter</option>
              <option value="Basic">Pro | $9/mo./provider | 2% Transaction Fee</option>
              <option value="Pro">Premium | $19/mo./provider | 2% Transaction Fee</option>
            </Select>
            {!!errors.Subscription && touched.Subscription && <Error>{errors.Subscription}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Reactivate Subscription
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }: any) => ({
    Subscription: org.SubscriptionType
  }),
  validationSchema: Yup.object().shape({
    Subscription: Yup.string().required("Subscription is required.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      if (props.org.SubscriptionStatus !== "Ended") {
        await props.dispatch(
          ResourceActions.action(props.org, "Action", {
            Action: "ChangeSubscription",
            SubscriptionType: props.org.SubscriptionType
          })
        );
      } else {
        await props.dispatch(
          ResourceActions.action(props.org, "Action", {
            Action: "ActivateSubscription",
            SubscriptionType: values.Subscription
          })
        );
      }
      toastStore.success("Saved Successfully!");
      props.rollSubsToggle(true);
      props.setPage("subdets");
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountSubAct"
});

export default compose(
  connect((state) => ({})),
  formikEnhancer
)(OrgEditAccount_SubAct as any);
