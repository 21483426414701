import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import Popup from "reactjs-popup";
// mobx
import { tagDataStore } from "../../../mobx/stores/TagDataStore";
import "../../../styles/button.scss";
import LogoIcon from "./../../../components/icons/Logo";
import { PaymentModal } from "./../../../components/modal/PaymentModal";
// Testing
import { paymentModalStore } from "./../../../mobx/stores/PaymentModalStore";
import apiService from "./../../../redux/services/api";
import "./index.scss";
import Menubar from "./Menubar";
import { DefaultSearchTag } from "./Search";

const Header: React.FC<any> = observer((props) => {
  const { userContext, auth, history } = props;
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.pathname === "/signup") {
        if (props.auth.UserID) {
          try {
            await apiService.userLogout();
          } catch {}
        }
        var el = document.getElementById("signup-button")!;
        el.click();
      }
    })();
  }, []);

  // get organisation ID for getting organisation's tags
  tagDataStore.organisationID = userContext.OrgID;

  return (
    <div
      className={`gradient relative ${
        props.history.location.pathname.includes("/admin") ? "z-10 sticky top-0" : ""
      }`}
      id="home-page-header-block"
    >
      {/* open payment modal after user is logged in */}
      {paymentModalStore.isOpen && (
        <Popup
          modal
          open={paymentModalStore.isOpen}
          onClose={() => {
            paymentModalStore.isOpen = false;
          }}
          contentStyle={{
            width: "360px",
            height: "175px",
            borderRadius: "9px",
            borderWidth: "0px",
            margin: "auto auto auto auto"
          }}
        >
          {<PaymentModal history={history} creditCards={null} medicareFunds={null} />}
        </Popup>
      )}

      <Menubar />

      <div
        className={`flex flex-wrap px-8 py-6 md:py-10 md:px-12 md:mx-0 pt-0 ${
          props.history.location.pathname !== "/" ? "hidden" : ""
        }`}
        id="header-content"
      >
        <div className="w-full md:w-1/3">
          <div className="md:flex md:justify-end md:pr-16 lg:pr-24">
            <LogoIcon className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40" />
          </div>
        </div>
        <div className="w-full md:w-2/3 pt-0 pb-16 md:pt-6">
          <p
            className="text-white text-xl md:text-2xl lg:text-3xl"
            aria-label="Book a personal trainer, dietition,"
          >
            Book a personal trainer, dietition,
          </p>
          <p
            className="text-white text-xl md:text-2xl lg:text-3xl"
            aria-label="psychologist and more..."
          >
            psychologist and more...
          </p>
          <DefaultSearchTag
            className="hidden md:flex"
            tagId="mobile-home-search-box"
            history={props.history}
            isHomepageSearchTag
          />
        </div>
        <DefaultSearchTag
          className={`md:hidden ${props.history.location.pathname !== "/" ? "hidden" : ""}`}
          tagId="desktop-home-search-box"
          history={props.history}
          isHomepageSearchTag
        />
      </div>
    </div>
  );
});

function mapStateToProps(state: any) {
  const { auth, global } = state;
  return {
    auth,
    userContext: global.UserContext
  };
}

export default connect(mapStateToProps)(Header);
