import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import SectionsContainer from "../../form/SectionsContainer";
import Section from "../../form/Section";
import { Cards } from "../../../components/stripe/CreditCard";
import { CreditCard } from "sdk/dist/credit_cards_pb";
import FormHeader from "../../../components/form/FormHeader";

interface Props {
  org: any;
}
class OrgEditAccount_SubPayment extends Component<Props> {
  render() {
    const { org } = this.props;
    return (
      <React.Fragment>
        <FormHeader
          title="Payment Details"
          subtitle="Please add/edit your credit cards for subscription and bulk fee processing"
        />
        <SectionsContainer>
          <Section>
            <Cards ownerId={org.ID} ownerType={CreditCard.OwnerType.ORGANISATION} />
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }
}

export default connect()(OrgEditAccount_SubPayment);
