/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.proto = require('./credit_cards_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientCreditCardsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientCreditCardsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodDescriptor_ClientCreditCards_List = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCreditCardsRequest,
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodInfo_ClientCreditCards_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListCreditCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCreditCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_List,
      callback);
};


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCreditCardsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_ClientCreditCards_Create = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCreditCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_ClientCreditCards_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ClientCreditCards_Delete = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCreditCardRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ClientCreditCards_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_ClientCreditCards_SetPrimaryCard = new grpc.web.MethodDescriptor(
  '/proto.ClientCreditCards/SetPrimaryCard',
  grpc.web.MethodType.UNARY,
  proto.proto.SetPrimaryCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_ClientCreditCards_SetPrimaryCard = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientCreditCardsClient.prototype.setPrimaryCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_SetPrimaryCard,
      callback);
};


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.ClientCreditCardsPromiseClient.prototype.setPrimaryCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_ClientCreditCards_SetPrimaryCard);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.OrganisationCreditCardsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.OrganisationCreditCardsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodDescriptor_OrganisationCreditCards_List = new grpc.web.MethodDescriptor(
  '/proto.OrganisationCreditCards/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCreditCardsRequest,
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodInfo_OrganisationCreditCards_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListCreditCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCreditCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationCreditCardsClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_List,
      callback);
};


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCreditCardsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationCreditCardsPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_OrganisationCreditCards_Create = new grpc.web.MethodDescriptor(
  '/proto.OrganisationCreditCards/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCreditCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_OrganisationCreditCards_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationCreditCardsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationCreditCardsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OrganisationCreditCards_Delete = new grpc.web.MethodDescriptor(
  '/proto.OrganisationCreditCards/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCreditCardRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OrganisationCreditCards_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationCreditCardsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationCreditCardsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_OrganisationCreditCards_SetPrimaryCard = new grpc.web.MethodDescriptor(
  '/proto.OrganisationCreditCards/SetPrimaryCard',
  grpc.web.MethodType.UNARY,
  proto.proto.SetPrimaryCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_OrganisationCreditCards_SetPrimaryCard = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationCreditCardsClient.prototype.setPrimaryCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_SetPrimaryCard,
      callback);
};


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationCreditCardsPromiseClient.prototype.setPrimaryCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_OrganisationCreditCards_SetPrimaryCard);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserCreditCardsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserCreditCardsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodDescriptor_UserCreditCards_List = new grpc.web.MethodDescriptor(
  '/proto.UserCreditCards/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCreditCardsRequest,
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListCreditCardsRequest,
 *   !proto.proto.ListCreditCardsResponse>}
 */
const methodInfo_UserCreditCards_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListCreditCardsResponse,
  /**
   * @param {!proto.proto.ListCreditCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCreditCardsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListCreditCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCreditCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserCreditCardsClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_List,
      callback);
};


/**
 * @param {!proto.proto.ListCreditCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCreditCardsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserCreditCardsPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserCreditCards/List',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_UserCreditCards_Create = new grpc.web.MethodDescriptor(
  '/proto.UserCreditCards/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCreditCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateCreditCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_UserCreditCards_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.CreateCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserCreditCardsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.UserCreditCardsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserCreditCards/Create',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserCreditCards_Delete = new grpc.web.MethodDescriptor(
  '/proto.UserCreditCards/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCreditCardRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteCreditCardRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserCreditCards_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteCreditCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserCreditCardsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteCreditCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.UserCreditCardsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserCreditCards/Delete',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodDescriptor_UserCreditCards_SetPrimaryCard = new grpc.web.MethodDescriptor(
  '/proto.UserCreditCards/SetPrimaryCard',
  grpc.web.MethodType.UNARY,
  proto.proto.SetPrimaryCardRequest,
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetPrimaryCardRequest,
 *   !proto.proto.CreditCard>}
 */
const methodInfo_UserCreditCards_SetPrimaryCard = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.CreditCard,
  /**
   * @param {!proto.proto.SetPrimaryCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreditCard.deserializeBinary
);


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.CreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserCreditCardsClient.prototype.setPrimaryCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_SetPrimaryCard,
      callback);
};


/**
 * @param {!proto.proto.SetPrimaryCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreditCard>}
 *     Promise that resolves to the response
 */
proto.proto.UserCreditCardsPromiseClient.prototype.setPrimaryCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserCreditCards/SetPrimaryCard',
      request,
      metadata || {},
      methodDescriptor_UserCreditCards_SetPrimaryCard);
};


module.exports = proto.proto;

