import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import { userStore } from "../../../../src/mobx/stores/UserStore";
import * as media_pb from "../../../../src/sdk/media_pb";
import { dateToTimestamp } from "../../../../src/util/time";
import { ResourceTypes } from "../../../redux/features/resources/definitions";
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";
import { GenderSelect } from "./../../../components/form/BaseSelect";
import DayPickerInput from "./../../../components/form/DayPickerInput";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import MediaInput from "./../../../components/form/MediaInput";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";
import { parseFormError } from "./../../../util/form";

interface Props {
  values: any;
  errors: any;
  touched: any;
  dirty: any;
  handleChange: any;
  handleBlur: any;
  handleSubmit: any;
  isSubmitting: any;
  pageData: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
}

interface IAddress {
  Owner: string;
  OwnerType: string;
  Country: string;
  CountryCode: string;
  GooglePlaceID: string;
  $Metadata: { Type: string };
  ID?: string;
  Locality: string;
  Location: {
    Latitude: number;
    Longitude: number;
  };
  PostCode: string;
  Raw: string;
  State: string;
  StateCode: string;
  Street1: string;
  Street2: string;
}

interface IRequestBodyParams {
  ID: string;
  Email: string;
  FundProperties: string;
  MobileCountryCode: string;
  MobileNumber: string;
  Role: string;
  $Metadata: { Type: string };
  Photo?: media_pb.Media.AsObject;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  Gender: string;
}
class UserEditAccount_General extends Component<Props> {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData,
      setFieldValue,
      setFieldTouched,
      setFieldError
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="User Information"
          subtitle="Please fill out the relevant information about yourself"
        />
        <SectionsContainer>
          <Section>
            <Label>Profile Image</Label>
            <MediaInput
              type="avatar"
              name="Photo"
              value={values.Photo}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Photo && touched.Photo && <Error>{errors.Photo}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-firstname">First Name</Label>
            <Input
              id="edituser-firstname"
              name="FirstName"
              type="text"
              placeholder="First Name"
              autoFocus={true}
              value={values.FirstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.FirstName && errors.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-lastname">Last Name</Label>
            <Input
              id="edituser-lastname"
              name="LastName"
              type="text"
              placeholder="Last Name"
              autoFocus={true}
              value={values.LastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.LastName && errors.LastName}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-DateOfBirth">Date of Birth</Label>
            <DayPickerInput
              id="edituser-DateOfBirth"
              name="DateOfBirth"
              placeholder="Select your date of birth"
              value={values.DateOfBirth}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!errors.DateOfBirth && touched.DateOfBirth && <Error>{errors.DateOfBirth}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-Gender">Gender</Label>
            <GenderSelect
              id="edituser-Gender"
              name="Gender"
              value={values.Gender}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
          </Section>
          <Section>
            <Label htmlFor="edituser-address">Address</Label>
            <GMPlacesAutocomplete
              id="edituser-address"
              name="Address"
              type="text"
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              css={{ margin: "0px !important" }}
              error={touched.Address && errors.Address}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik<any, any>({
  mapPropsToValues: ({ user }) => ({
    Photo: user.photo ? user.photo.id : "",
    FirstName: user.firstName || "",
    LastName: user.lastName || "",
    Address: !user.address ? "" : user.address.Raw || "",
    DateOfBirth: user.dateOfBirth || "",
    Gender: user.gender || "Unknown"
  }),
  validationSchema: Yup.object().shape({
    Photo: Yup.string().notRequired(),
    FirstName: Yup.string().required("First name is required."),
    LastName: Yup.string().required("Last name is required."),
    Address: Yup.string().notRequired(),
    DateOfBirth: Yup.date().required("Please enter your date of birth"),
    Gender: Yup.string().notRequired()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      let prUpdAddr: IAddress | undefined;
      if (values.Address !== "") {
        if (!props.user.address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          prUpdAddr = {
            OwnerType: "User",
            Owner: props.user.id,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          const res = await props.dispatch(ResourceActions.action(prUpdAddr, "Create", {}));
          // Add address ID
          prUpdAddr = {
            ...prUpdAddr,
            ID: res.Payload.ID
          };
        } else if (props.user.address !== values.Address) {
          const prDet = await findPlaceDetails(values.Address, "Address");
          prUpdAddr = {
            OwnerType: "User",
            Owner: props.user.id,
            ...props.user.address,
            ...getAddressResourceFromPlaceDetails(prDet, values.Address)
          };
          await props.dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
        }
      } else if (props.user.address) {
        await props.dispatch(ResourceActions.action(props.user.address, "Remove", {}));
      }

      // cant send country code without mobile number
      if (props.user.MobileNumber == undefined) {
        delete props.user.MobileCountryCode;
      }

      // create the request body
      const reqBodyParams: IRequestBodyParams = {
        ID: props.user.id,
        Email: props.user.email,
        FundProperties: JSON.parse(props.user.fundProperties),
        MobileCountryCode: props.authUser.MobileCountryCode,
        MobileNumber: props.authUser.MobileNumber,
        Role: props.user.role,
        $Metadata: { Type: ResourceTypes.User },
        Photo: values.Photo || null,
        FirstName: values.FirstName,
        LastName: values.LastName,
        DateOfBirth: values.DateOfBirth,
        Gender: values.Gender
      };
      const res = await props.dispatch(ResourceActions.action(reqBodyParams, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);

      if (userStore.user) {
        // update/overwrite the user details
        userStore.setUser({
          ...userStore.user,
          email: reqBodyParams.Email,
          mobileCountryCode: reqBodyParams.MobileCountryCode,
          mobileNumber: reqBodyParams.MobileNumber,
          photo: !res.Payload.Photo
            ? undefined
            : {
                createdAt: dateToTimestamp(new Date(res.Payload.Photo.CreatedAt)).toObject(),
                description: res.Payload.Photo.Description,
                downloadUrl: res.Payload.Photo.DownloadURL,
                filename: res.Payload.Photo.Filename,
                id: res.Payload.Photo.ID,
                mime: res.Payload.Photo.Mime,
                status: "",
                type: res.Payload.Photo.filename,
                updatedAt: dateToTimestamp(new Date(res.Payload.Photo.LastModifiedAt)).toObject()
              },
          firstName: reqBodyParams.FirstName,
          lastName: reqBodyParams.LastName,
          dateOfBirth: reqBodyParams.DateOfBirth,
          gender: reqBodyParams.Gender,
          address: prUpdAddr ? prUpdAddr : props.user.address // update address in userStore
        });
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "UserEditAccountGeneral"
});

export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(UserEditAccount_General as any);
