import React, { Component, useEffect, useState } from "react";

// Components
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";
import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";
import ResourcePageError from "./../../../components/elements/ResourcePageError";

// Pages
import General from "./General";
import Desc from "./Desc";
import Market from "./Market";
import Reimb from "./Reimb";
import IFrame from "./IFrame";
import SubDets from "./SubDets";
import SubType from "./SubType";
import SubAct from "./SubAct";
import SubPayment from "./SubPayment";
import SubCan from "./SubCan";
import Settings from "./Settings";
import Fund_AUHICAPS from "./Fund_AUHICAPS";
import FundPool from "./FundPool";
import Hubspot from "./Hubspot";
import { Onboarding } from "../../../components/stripe/Onboarding";
import { UserUIType } from "sdk/dist/location_user_pb";
import { Organisation } from "../../../redux/features/resources/schema";

interface Props {
  resource: Organisation;
  match: { params: { oid: string; lid: string; pid: string } };
}
export default class OrgEditAccount extends Component<Props> {
  renderContent = ({ resource }: Props) => {
    const [rolloverSubscription, setRolloverSubscription] = useState(false);

    useEffect(() => {
      setRolloverSubscription(resource.RolloverSubscriptionType ? true : false);
    }, []);

    return (
      <PagesContainer
        sidePanel={true}
        queryp="page"
        blockNotSeen={false}
        extraProps={{ org: resource, rollSubsToggle: setRolloverSubscription }}
      >
        <PageGroup label="Basics">
          <Page name="general" label="General" component={General} />
          <Page name="desc" label="Description" component={Desc} />
          <Page name="market" label="Marketing" component={Market} />
          <Page name="reimb" label="Reimbursement" component={Reimb} />
          <Page name="stripe" label="Stripe" component={() => <Onboarding orgId={resource.ID} />} />
          <Page name="iframe" label="IFrame" component={IFrame} />
        </PageGroup>
        {resource.SubscriptionStatus !== "Ended" ? (
          <PageGroup label="Subscription">
            <Page name="subdets" label="Details" component={SubDets} />
            {rolloverSubscription && <Page name="subtype" label="Type" component={SubType} />}
            <Page name="subpay" label="Payment" component={SubPayment} />
            {rolloverSubscription && <Page name="subcan" label="Cancel" component={SubCan} />}
            {!rolloverSubscription && <Page name="subact" label="Reactivate" component={SubAct} />}
          </PageGroup>
        ) : (
          <PageGroup label="Subscription">
            <Page name="subact" label="Reactivate" component={SubAct} />
            <Page name="subpay" label="Payment" component={SubPayment} />
          </PageGroup>
        )}
        <PageGroup label="Settings">
          <Page name="settings" showInTree={false} component={Settings} />
        </PageGroup>
        <PageGroup label="Integrations">
          {!(
            localStorage.getItem("UserUIType") === String(UserUIType.FITNESS) ||
            process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
          ) ? (
            <Page name="aufundhicaps" label="HICAPS/Medipass/DVA" component={Fund_AUHICAPS} />
          ) : (
            false
          )}
          <Page name="hubspot" label="Hubspot" component={Hubspot} />
        </PageGroup>
        {!(
          localStorage.getItem("UserUIType") === String(UserUIType.FITNESS) ||
          process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
        ) ? (
          <PageGroup label="Fund Pool">
            <Page name="fundpool" showInTree={false} label="Fund Pool" component={FundPool} />
          </PageGroup>
        ) : (
          false
        )}
      </PagesContainer>
    );
  };

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <ResourceFetch type="Organisation" ids={{ ID: match.params.oid }} force />
        <ResourceRender
          type="Organisation"
          ids={{ ID: match.params.oid }}
          denorm={true}
          compSuccess={this.renderContent}
          compError={ResourcePageError}
        />
      </React.Fragment>
    );
  }
}
