class ClientTokenService {
  async getClientToken() {
    // TODO: Find out why is the client ID & secret hardcoded
    const res = await fetch(
      `/api/v1/token/client?ClientID=9R8KF2B2w8hbwNQU5L5Z&ClientSecret=zwyrWQft2xAbVSMezPfZuLZKnuqTD9UJvGEeUMJeVEr`
    );
    return res.json();
  }
}

export const sharedClientTokenService = new ClientTokenService();
