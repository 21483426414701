import styled from "@emotion/styled";

import { colors } from "./../../util/consts";
import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";

import IconButton from "./../../components/IconButton";
import Form from "./../../components/form/Form";
import ButtonBase from "./../../components/ButtonBase";

export const AccordianForm = styled(Form)`
  padding: 24px 26px 24px;

  ${breakpoints["desktop-up"]} {
    padding: 0 46px;
  }

  ${breakpoints["phone-only"]} {
    padding-left: 13px;
    padding-right: 13px;
  }
`;

export const AccordianContainer = styled.div<{ selected?: boolean, maySelect?: boolean }>`
  position: relative;
  border-radius: 4px;
  margin: 8px 0;
  background-color: ${colors.surface.light};
  overflow: visible;
  ${({ selected }) =>
    selected &&
    `
  border: 1px solid #00B1B6;`}
  ${({ maySelect }) =>
    maySelect &&
    `
  cursor: pointer;`}
`;

export const AccordianButton = styled(ButtonBase)`
  width: 100%;
  padding: 24px 26px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  display: flex;
`;

export const AccordianEdit = styled.div<{ mayClick?: boolean }>`
  width: 100%;
  padding: 24px 26px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  display: flex;
  ${({ mayClick }) => mayClick && "cursor: pointer"};

  ${breakpoints["phone-only"]} {
    position: relative;
    padding: 18px 34px 18px 13px;
    flex-direction: column;
    align-items: flex-start;
    > * {
      margin-bottom: 13px !important;
    }
  }
`;

export const NewHeader = styled.div`
  width: 100%;
  padding: 24px 26px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  display: flex;

  ${breakpoints["phone-only"]} {
    padding: 18px 13px 18px;
  }
`;

export const Separator = styled.div`
  box-sizing: border-box;
  height: 25px;
  width: 1px;
  border: 1px solid #f3f5fd;
  margin: 0 10px;

  ${breakpoints["phone-only"]} {
    display: none;
  }
`;

export const InvisibleSeparator = styled.div`
  box-sizing: border-box;
  height: 25px;
  width: 1px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 0 48px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 42px;

  ${breakpoints["phone-only"]} {
    position: absolute;
    top: 13px;
    right: 13px;
  }
`;

export const ButtonHead = styled.div`
  /* color: #2c2e3c;
  font-size: 13.82px;
  font-weight: 600;
  letter-spacing: 0.22px;
  line-height: 19px;
  text-align: left; */
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  text-align: left;
  flex: 1 1 auto;
`;

export const ButtonDetails = styled.div`
  ${typography.body1};
  letter-spacing: 0.63px;
  line-height: 20px;
  justify-content: flex-start;
  user-select: none;
  flex: 1 1 auto;
`;

export const ButtonContainer = styled.div`
  display: inline-grid;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 9px 0 6px;
  margin-bottom: 20px;
`;

export const RolesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  & > * {
    flex: 1 1 200px;
  }
`;

export const AddButton = styled(ButtonBase)`
  ${typography.overline};
  opacity: 0.6;
  background-color: transparent;
  display: flex;
  height: 70px;
  border-radius: 4px;
  border: 1px rgba(0, 0, 0, 0.3);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  justify-content: center;
  border-style: dashed;
  outline: none;
  width: 100%;
  user-select: none;
  cursor: pointer;
`;

export const NoSpacingIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
  :disabled {
    opacity: 0.2;
  }
`;

export const ItemContainer = styled.div`
  border: solid 1px rgba(44,46,60,0.19);
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
