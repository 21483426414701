import { format } from "date-fns";
import { IAddress, IUpcomingBooking } from "../IRestApiResponse";
import { userStore } from "../mobx/stores/UserStore";
import { toastStore } from "../stores/toast-store";
import { httpResponseHandler } from "../util/httpResponseHandler";
import { ILocationSchedule } from "./IOpeningHours";

class RestLocationService {
  async fetchLocation(locationId: string) {
    try {
      const res = await fetch(
        `/api/v1/locations/${locationId}?` +
          new URLSearchParams({
            StartDate: format(new Date(), "yyyy-MM-dd")
          }),
        {
          headers: {
            Authorization: userStore.getAuthHeader()
          }
        }
      );
      const json = await res.json();
      if (json.StatusCode !== 200) {
        toastStore.error("Error occurred while fetching location");
        return Promise.resolve(undefined);
      }
      return json.Payload;
    } catch (err) {
      toastStore.error("Error occurred while fetching location");
      return Promise.resolve(undefined);
    }
  }
}

interface IUser {
  Email: string;
  FirstName: string;
  LastName: string;
  MobileCountryCode: string;
  MobileNumber: string;
}

interface ILocationUser {
  IsActive: boolean;
  ProviderCategories: string[];
  ProviderSchedule?: ILocationSchedule;
  Roles: string[];
  User: IUser;
}

interface ICreateLocation {
  Address: string;
  Banner: string;
  Org: string;
  Phone: string;
  Schedule?: ILocationSchedule;
}

interface ILocation {
  Address: IAddress;
  ID: string;
  Name: string;
  Org: string;
  Phone: string;
  Schedule: any;
  Status: string;
}

class LocationService {
  @httpResponseHandler()
  async create(v: ICreateLocation): Promise<ILocation> {
    const res = await fetch("/api/v1/locations", {
      method: "POST",
      body: JSON.stringify(v),
      headers: {
        Authorization: userStore.getAuthHeader(),
        "Content-Type": "application/json"
      }
    });

    const json = await res.json();
    return json.Payload;
  }

  @httpResponseHandler()
  async addUser(locationId: string, v: ILocationUser) {
    const res = await fetch(`/api/v1/locations/${locationId}/users`, {
      method: "POST",
      body: JSON.stringify(v),
      headers: {
        Authorization: userStore.getAuthHeader(),
        "Content-Type": "application/json"
      }
    });

    const json = await res.json();
    return json.Payload;
  }

  @httpResponseHandler()
  async getLocUpcomingBookings(locationId: string): Promise<IUpcomingBooking[]> {
    const res = await fetch(`/api/v1/locations/${locationId}/upcomingBookings`, {
      method: "GET",
      headers: {
        Authorization: userStore.getAuthHeader(),
        "Content-Type": "application/json"
      }
    });

    const json = await res.json();
    return json.Payload;
  }
}

export const sharedLocationService = new LocationService();
export const restLocationService = new RestLocationService();
