import jwtdecode, { JwtPayload } from "jwt-decode";
import { UserManager, UserManagerSettings } from "oidc-client";
import { AppConfig } from "../AppConfig";
import { userStore } from "../mobx/stores/UserStore";
import { toastStore } from "../stores/toast-store";
import { getToken } from "../util/localStore";
import { sharedClientTokenService } from "./ClientTokenService";

class AuthService {
  private config: UserManagerSettings;
  private userManager: UserManager;

  constructor() {
    this.config = {
      authority: AppConfig.BOOKLYFE_IDENTITY_ISSUER,
      client_id: "booklyfe-webapp",
      response_type: "token",
      scope: "openid"
    };
    this.userManager = new UserManager(this.config);
  }

  async login(username: string, password: string) {
    const tokenEndpoint = await this.userManager.metadataService.getTokenEndpoint();

    const res = await fetch(tokenEndpoint!, {
      method: "POST",
      body: new URLSearchParams({
        grant_type: "password",
        client_id: this.config.client_id!,
        scope: this.config.scope!,
        username: username.trim(),
        password: password
      })
    });
    return res.json();
  }

  // TODO: use user manager to logout? reference all logout to this method
  // https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1012
  // This will need refactoring for redux auth
  async logout() {}

  isLoggedIn() {
    const token = getToken();
    if (!token) {
      return false;
    }
    const jwtToken = this.parseJwtPayload(token);
    if (!jwtToken || !jwtToken.exp) {
      return false;
    }
    return new Date(jwtToken.exp * 1000) >= new Date();
  }

  parseJwtPayload(tokenStr: string | undefined) {
    if (!tokenStr) {
      return undefined;
    }
    return jwtdecode<JwtPayload>(tokenStr);
  }

  async generateClientToken() {
    const res = await sharedClientTokenService.getClientToken();
    if (res && res.Payload && res.Payload.ClientToken) {
      userStore.setClientToken(res.Payload.ClientToken);
    } else if (res.Errors && res.Error.length) toastStore.error(res.Error[0].UserMessage);
  }

  isClientTokenValid() {
    const token = localStorage.getItem("clientToken");
    if (token) {
      const decodedToken = jwtdecode<JwtPayload>(token);
      return decodedToken.exp ? decodedToken.exp * 1000 > Date.now() : false;
    }
    return false;
  }
}

export const authService = new AuthService();
