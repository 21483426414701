import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { ClientPhysitrack, TokenAvailableResponse } from "sdk/dist/physitrack_pb";
import { FlexBox, FlexItem } from "../../../../ui-kit/FlexBox";
import { InformationIcon } from "../../../../ui-kit/icons/InformationIcon";
import { LockIcon } from "../../../../ui-kit/icons/LockIcon";
import { Tooltip } from "../../../../ui-kit/Tooltip";
import { LoadingIndicator } from "../../../../util/loading";
import LoadingIcon from "../../../icons/Loading";
import LoadingButton from "../../../LoadingButton";
import { Seperator } from "../../SplitDialog";

interface Props {
  tokenAndData: TokenAvailableResponse.AsObject;
  buttonLabel: string;
  isButtonDisabled: boolean;
}

@observer
export class PhysitrackButton extends Component<Props> {
  @observable
  private indicator = new LoadingIndicator();
  private patient = new ClientPhysitrack().toObject();

  pushClient = async () => {
    await this.indicator.while(async () => {});
  };

  render() {
    const { tokenAndData, buttonLabel } = this.props;
    if (tokenAndData) {
      this.patient = tokenAndData.clientPhysitrack!;
    }

    return (
      <>
        {tokenAndData && tokenAndData.isAvailable && (
          <>
            <Seperator style={{ height: "0px" }} />
            <form action={tokenAndData.signInAndPushClientUrl} target="_blank">
              <input
                type="text"
                hidden={true}
                name="data[token]"
                value={tokenAndData.token}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][id]"
                value={this.patient.externalId}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][email]"
                value={this.patient.email}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][title]"
                value={this.patient.gender}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][first_name]"
                value={this.patient.firstName}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][last_name]"
                value={this.patient.lastName}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][birth_year]"
                value={this.patient.yearOfBirth}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][diagnosis_code]"
                value={this.patient.diagnosisCode}
                readOnly
              ></input>
              <input
                type="text"
                hidden={true}
                name="data[patient][mobile_phone]"
                value={this.patient.mobilePhone}
                readOnly
              ></input>
              <FlexBox alignItems="center" spacing={[2, 0]}>
                <FlexItem>
                  <LoadingButton
                    loading={this.indicator.isLoading()}
                    disabled={this.indicator.isLoading() || this.props.isButtonDisabled}
                    onClick={(e) => {
                      if (this.props.isButtonDisabled) {
                        e.preventDefault();
                        return;
                      }
                      return;
                    }}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    {this.indicator.isLoading() ? (
                      <LoadingIcon width={16} height={16} color="#FFF" />
                    ) : (
                      <FlexBox alignItems="center">
                        {buttonLabel}
                        {this.props.isButtonDisabled && <LockIcon />}
                      </FlexBox>
                    )}
                  </LoadingButton>
                </FlexItem>
                <FlexItem>
                  <Tooltip title="Select Lyfe Pro to enable remote video (Telehealth) appointments with your clients from anywhere in the world. Access and share from a library of 15,000+ workout videos with client progress tracking and feedback.">
                    <InformationIcon />
                  </Tooltip>
                </FlexItem>
              </FlexBox>
            </form>
            <Seperator style={{ height: "0px" }} />
          </>
        )}
      </>
    );
  }
}
