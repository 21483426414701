import React from "react";
import { ProviderInterface } from "./index";
import { Link } from "react-router-dom";
import UserAvatar from "../../../components/UserAvatar"

interface IProps {
  data: ProviderInterface;
}

const TrainerCard: React.FC<IProps> = ({ data }) => {
  return (
    <div className="trainer-card">
      <UserAvatar userID={data.User.ID} size={192} alt={`Trainer name ${data.User.FirstName} ${data.User.LastName}`}/>
      <div className="text-white text-xl pt-4" id="trainer-name">
        {`${data.User.FirstName} ${data.User.LastName}`}
      </div>
      <div className="text-white" id="job">
        {data.ProviderCategories.length && data.ProviderCategories[0]
          ? data.ProviderCategories[0].Value
          : " "}
      </div>
    </div>
  );
};

export default TrainerCard;
