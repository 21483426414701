import React from "react";
import styled from "@emotion/styled";
import { colors, allowedCountries, allowedCountriesFull } from "../../util/consts";

const Select = styled.select<any>`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;

  &:disabled {
    background-color: white;
    border: none;
    padding: 0;
    height: 25px;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    opacity: 0.6;
    font-size: 12.82px;
  }

  &:hover {
    background-color: white;
  }
  &:focus {
    border-color: ${colors.primary.main};
    /* padding: 10px 16px; */
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;

export default Select;


export const GenderSelect = React.memo(({ isUser, ...props }: any)=>
  <Select {...props}>
    <option key={0} value="Unknown">{isUser ? "" : "Unknown"}</option>
    <option key={1} value="Male">Male</option>
    <option key={2} value="Female">Female</option>
    <option key={3} value="Indeterminate">Other</option>
  </Select>
);

export const CountrySelect = React.memo(({ isUser, ...props }: any)=>
  <Select {...props}>
    {
      allowedCountriesFull.map((country, index)=>{
          return <option key={index} value={allowedCountries[index]}>{country}</option>
      })
    }
  </Select>
);

export const FrequencySelect = ({ isUser, ...props }: any)=>{
  let options = [
    "does not repeat",
    "repeats every day",
    "repeats every week",
    "every two weeks",
    "repeats every month",
  ]
return(
  <Select {...props}>
    {
      options.map((options, index)=>{
          return <option key={index} value={index}>{options}</option>
      })
    }
  </Select>
)}

export const StatusSelect = React.memo(({ isUser, ...props }: any)=>
  <Select {...props}>
    <option key={0} value="Active">Active</option>
    <option key={1} value="Deleted">Deleted</option>
    <option key={2} value="OnHold">OnHold</option>
    <option key={3} value="Archived">Archived</option>
  </Select>
);

function getRange(from: number, to: number) {
  const rr = [];
  for (let i = from; i <= to; i++) {
    rr.push(i);
  }
  return rr;
}

export const YearSelect = React.memo(({ from, to, ...props }: any)=>
  <Select {...props}>
    {getRange(from, to).map((yr)=>
      <option key={yr} value={`${yr}`}>{yr}</option>
    )}
  </Select>
);

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const MonthSelect = React.memo(({ from, to, ...props }: any)=>
  <Select {...props}>
    {getRange(from%12, to%12).map((n)=>
      <option key={n} value={`${n}`}>{months[n]}</option>
    )}
  </Select>
);
