import React from "react";
import {
  Booking,
  BookingClient,
  CreateBookingClientRequest,
  CreateBookingOfferingRequest,
  CreateClientOfferingRequest
} from "sdk/dist/bookings_pb";
import {
  AccordianForm,
  ButtonContainer,
  NewHeader,
  ButtonHead
} from "../elements/AccordianElements";
import Label from "../form/Label";
import Section from "../form/Section";
import LoadingButton from "../LoadingButton";
import { LocationOfferingSelector } from "../LocationOfferingSelector";
import IconButton from "../IconButton";
import Clear from "../icons/Clear";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Offering } from "sdk/dist/offerings_pb";
import { rpc, metadata } from "./../../grpc";
import { LoadingIndicator } from "./../../util/loading";
import { bookingStore } from "./../../stores/booking-store";
import { CloseButton } from "../elements/CloseButton";

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  onRequestClose?: () => void;
}

@observer
export class NewLineItemForm extends React.Component<Props> {
  @observable
  offering?: Offering.AsObject;

  @observable
  indicator = new LoadingIndicator();

  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await this.indicator.while(async () => {
      if (!this.offering) {
        return;
      }
      if (this.props.client) {
        const req = new CreateClientOfferingRequest();
        req.setBookingId(this.props.booking.id);
        req.setClientId(this.props.client.clientId);
        req.setOfferingId(this.offering.id);
        await rpc.clientOfferings.create(req, metadata());
      } else {
        const req = new CreateBookingOfferingRequest();
        req.setBookingId(this.props.booking.id);
        req.setOfferingId(this.offering.id);
        await rpc.bookingOfferings.create(req, metadata());
      }
      await bookingStore.load(this.props.booking.id);
    });
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  render() {
    const { booking } = this.props;
    return (
      <AccordianForm onSubmit={this.onSubmit}>
        {this.props.onRequestClose && <CloseButton onClick={this.props.onRequestClose} />}
        <Section>
          <NewHeader style={{ padding: "24px 0 24px" }}>
            <ButtonHead>Add Service</ButtonHead>
          </NewHeader>
          <LocationOfferingSelector
            locationId={booking.locationId}
            onSelect={(offering) => (this.offering = offering)}
          />
        </Section>
        <ButtonContainer>
          <LoadingButton
            style={{ minWidth: 150 }}
            variant="contained"
            color="secondary"
            type="submit"
            loading={this.indicator.isLoading()}
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  }
}
