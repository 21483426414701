import styled from "@emotion/styled";
import { withFormik } from "formik";
import { observable } from "mobx";
// mobx
import { observer } from "mobx-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
// grpc
import { metadata, rpc } from "../../grpc";
// Redux
import * as ReduxDialogs from "../../redux/features/dialogs";
import { Client, NewClientRequest } from "sdk/dist/clients_pb";
import { GetLocationRequest } from "sdk/dist/location_pb";
import { UserUIType } from "sdk/dist/location_user_pb";
// sdk
import { ListTagRequest, ReplaceClientTagsResquest, Tag } from "sdk/dist/tag_pb";
import { CreateURLRequest, CreateURLResponse, SendEmailRequest } from "sdk/dist/user_invite_pb";
import { AddUserRequest, AddUserResponse, User } from "sdk/dist/user_pb";
import { clientTagStore } from "../../stores/client_tag-store";
import { toastStore } from "../../stores/toast-store";
// Utils
import {
  availableFundTypes,
  breakpoints,
  excludeHealthRelatedFundType,
  FundType,
  offFundToText
} from "../../util/consts";
import { toDate } from "../../util/timestamp";
import TagsDropdown from "../elements/Dropdown/TagsDropdown";
import Select, { GenderSelect } from "../form/BaseSelect";
import DayPickerInput from "../form/DayPickerInput";
import Error from "../form/Error";
import FooterContainer from "../form/FooterContainer";
import Form from "../form/Form";
import FormHeader from "../form/FormHeader";
import Input from "../form/Input";
import Label from "../form/Label";
import Section from "../form/Section";
import SectionsContainer from "../form/SectionsContainer";
import LoadingButton from "../LoadingButton";
import MobileInput from "./../../components/form/MobileInput";
// Custom components
import Dialog from "./Dialog";

interface Props {
  data: any;
  dispatch: (func: any) => void;
  dialogId: string;
  values: any;
  errors: any;
  touched: any;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

interface Values {
  FirstName: string;
  LastName: string;
  Email: string;
  MobileNumber: string;
  MobileCountryCode: string;
  Gender: string;
  preferred_fund_type: string;
}

@observer
class AddClientModal extends Component<Props> {
  @observable
  tagOptions = Array<Tag.AsObject>();
  @observable
  selectedTags = Array<Tag.AsObject>();
  @observable
  orgId = "";

  componentWillMount = async () => {
    try {
      const locReq = new GetLocationRequest();
      if (this.props.data.loc.ID) {
        locReq.setLocationId(this.props.data.loc.ID);
      } else {
        locReq.setLocationId(this.props.data.loc);
      }
      const locRes = await rpc.location.get(locReq, metadata());
      this.props.data.org = { ID: locRes.toObject().location!.org };
      this.orgId = locRes.toObject().location!.org;
      const resTagOptions = await this.getTags();
      // set tag options based on organisation tag
      await this.onChangeTagOptions(resTagOptions);

      // clear client tag store
      await clientTagStore.deleteAll();
    } catch (err) {
      toastStore.grpcError(err);
    }
  };

  getTags = async () => {
    const { data } = this.props;
    // request
    const req = new ListTagRequest();
    req.setOrganisationId(data.org.ID);
    // response
    const res = await rpc.freeFormTag.list(req, metadata());

    return res.toObject().tagsList;
  };

  onChangeTagOptions = (tagOptions: Array<Tag.AsObject>) => {
    this.tagOptions = tagOptions;
  };

  onChangeSelectedTags = (
    selectedTags: Array<Tag.AsObject>,
    selectedTag: Tag.AsObject,
    action: string
  ) => {
    // change selected tags to be shown in react-select
    this.selectedTags = selectedTags;

    // change selected tags in mobx store
    if (action === "select-option") {
      clientTagStore.add("tempClientId", selectedTag);
    } else if (action === "deselect-option") {
      clientTagStore.delete("tempClientId", selectedTag);
    }
  };

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, data } =
      this.props;
    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            <FormHeader title="New Client" />
            <SectionsContainer>
              <Section>
                <Label htmlFor="addclient-FirstName">First Name</Label>
                <Input
                  id="addclient-FirstName"
                  name="FirstName"
                  type="text"
                  placeholder="First name"
                  value={values.FirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.FirstName && errors.FirstName}
                />
                {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-LastName">Last Name</Label>
                <Input
                  id="addclient-LastName"
                  name="LastName"
                  type="text"
                  placeholder="Last name"
                  value={values.LastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.LastName && errors.LastName}
                />
                {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-Email">Email</Label>
                <Input
                  id="addclient-Email"
                  name="Email"
                  type="email"
                  placeholder="example@app.lyfe.io"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Email && errors.Email}
                />
                {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
              </Section>
              <Section>
                <Label htmlFor="addclient-Phone">Phone Number</Label>
                <MobileInput
                  onCountryChange={(e) => {
                    values.MobileCountryCode = e;
                  }}
                  onPhoneChange={(e) => {
                    values.MobileNumber = e;
                  }}
                />
                {!!errors.MobileNumber && touched.MobileNumber && (
                  <Error>{errors.MobileNumber}</Error>
                )}
              </Section>
              <Section>
                <Label htmlFor="addclient-Gender">Gender (optional)</Label>
                <GenderSelect
                  id="addclient-Gender"
                  name="Gender"
                  value={values.Gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!!errors.Gender && touched.Gender && <Error>{errors.Gender}</Error>}
              </Section>
              <Section style={{ display: "flex", flexDirection: "column" }}>
                {this.orgId != "" && (
                  <>
                    <Label>Tags</Label>
                    <TagsDropdown
                      orgId={this.orgId}
                      tagOptions={this.tagOptions}
                      selectedTags={this.selectedTags}
                      onChangeSelectedTags={this.onChangeSelectedTags}
                      onChangeTagOptions={this.onChangeTagOptions}
                      getTags={this.getTags}
                      isDisabled={false}
                      isEditingClient={false}
                      {...this.props}
                    />
                  </>
                )}
              </Section>
              {localStorage.getItem("UserUIType") != String(UserUIType.FITNESS) ? (
                <Section style={{ display: "flex", flexDirection: "column" }}>
                  <Label>Preferred Payment Method</Label>
                  <Select
                    id="addclient-payment-method"
                    name="preferred_fund_type"
                    value={values.preferred_fund_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.preferred_fund_type && errors.preferred_fund_type}
                    style={{ width: 200 }}
                  >
                    {availableFundTypes
                      .filter((type) => {
                        if (process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US")
                          return !excludeHealthRelatedFundType.includes(type);
                        return true;
                      })
                      .map((v) => (
                        <option key={v} value={v}>
                          {offFundToText(v)}
                        </option>
                      ))}
                  </Select>
                  {!!errors.preferred_fund_type && touched.preferred_fund_type && (
                    <Error>{errors.preferred_fund_type}</Error>
                  )}
                </Section>
              ) : (
                false
              )}
            </SectionsContainer>

            <FooterContainer>
              <LoadingButton
                style={{
                  minWidth: 150
                }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </FooterContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ data }: any) => ({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    MobileCountryCode: "",
    Gender: "Unknown",
    preferred_fund_type: FundType.CreditCard
  }),
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.lazy((v) => Yup.string().email("Email is invalid").required("Email is required")),
    MobileNumber: Yup.lazy((v) => Yup.string().min(5, "Number is too short").notRequired()),
    Gender: Yup.string().notRequired(),
    preferred_fund_type: Yup.string().required("Please choose a preferred payment method")
  }),
  handleSubmit: async (values: Values, { setSubmitting, setFieldError, props }) => {
    try {
      // Create client obj
      const tmpClient = new Client();
      tmpClient.setFirstName(values.FirstName.trim());
      tmpClient.setLastName(values.LastName.trim());
      tmpClient.setOrgId(props.data.org.ID);
      if (props.data.loc.ID) {
        tmpClient.setLocationId(props.data.loc.ID);
      } else {
        tmpClient.setLocationId(props.data.loc);
      }
      tmpClient.setEmail(values.Email.trim());
      tmpClient.setMobileNumber(values.MobileNumber);
      tmpClient.setMobileCountryCode(values.MobileCountryCode);
      tmpClient.setGender(values.Gender);
      tmpClient.setPreferredFundType(values.preferred_fund_type);

      const clientReq = new NewClientRequest();
      clientReq.setClient(tmpClient);

      const clientRes = await (await rpc.clientService.newClient(clientReq, metadata())).toObject();

      // client tag request
      const clientTagReq = new ReplaceClientTagsResquest();
      clientTagReq.setOrganisationId(props.data.org.ID);
      clientTagReq.setClientId(clientRes!.clientId);

      const tagListId = Array<string>();
      const clientTags = clientTagStore.get("tempClientId");
      if (clientTags) {
        clientTags.map((tagList) => {
          tagListId.push(tagList.id);
        });
        clientTagReq.setTagsIdList(tagListId);
        // response
        await rpc.freeFormTag.replaceClientTags(clientTagReq, metadata());
      }
      // create User Stub for Client
      let addUserResponse = new AddUserResponse().toObject();
      const userReq = new AddUserRequest();
      let user = new User();
      user.setFirstName(values.FirstName.trim());
      user.setLastName(values.LastName.trim());
      user.setEmail(!values.Email ? "" : values.Email.trim());
      user.setMobileNumber(!values.MobileNumber ? "" : values.MobileNumber.trim());
      user.setMobileCountryCode(!values.MobileNumber ? "" : values.MobileCountryCode.trim());
      user.setGender(values.Gender);
      userReq.setUser(user);
      userReq.setClientId(clientRes!.clientId);
      const userRes = await rpc.user.addUser(userReq, metadata());
      addUserResponse = userRes.toObject();

      // create URL
      let addURLResponse = new CreateURLResponse().toObject();
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(addUserResponse.id);
      const urlRes = await rpc.userInvite.createURL(urlReq);
      addURLResponse = urlRes.toObject();
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(addUserResponse.id);
      emailReq.setOrgId(props.data.org.ID);
      emailReq.setUrl(addURLResponse.url);
      await rpc.userInvite.sendEmail(emailReq);
      const clientRedux = {
        ID: clientRes.clientId,
        $Metadata: {
          DataStatus: "Ok",
          Type: "Client"
        },
        Email: clientRes.email,
        FirstName: clientRes.firstName,
        LastName: clientRes.lastName,
        Gender: clientRes.gender,
        Status: clientRes.status,
        preferred_fund_type: "CreditCard",
        CreatedAt: clientRes.createAt ? toDate(clientRes.createAt).toISOString() : "",
        LastModifiedAt: clientRes.lastModifiedAt
          ? toDate(clientRes.lastModifiedAt).toISOString()
          : "", //"2021-02-15T06:22:54.877706Z",
        Org: {
          ID: props.data.org.ID
        }
      };
      props.data.cb && props.data.cb(clientRedux);
      toastStore.success("Client added. Please set up the payment method.");
      props.dispatch(
        ReduxDialogs.actions.swap(props.dialogId, ReduxDialogs.DialogType.ClientActionDialog, {
          item: clientRedux,
          initialTab: "Payment"
        })
      );
    } catch (err) {
      toastStore.grpcError(err);
    }

    setSubmitting(false);
  },
  displayName: "AddClient"
});

export default compose(connect(), withToastManager, formikEnhancer)(AddClientModal as any);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;
