import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import "moment-timezone";

// convert proto Timestamp.AsObject to Date
export function toDate(timestamp: Timestamp.AsObject): Date {
  const t = new Timestamp();
  t.setSeconds(timestamp.seconds);
  t.setNanos(timestamp.nanos);
  return t.toDate();
}

// convert proto Timestamp.AsObject to Moment date obj using user's browser timezone
export function toDateTz(date: Timestamp.AsObject | Date): moment.Moment {
  let t: moment.Moment;
  // if date has property seconds, then it's type Timestamp.AsObject, otherwise it's just a normal Date
  if (date.seconds) {
    t = moment.tz(moment.unix(date.seconds), timezone());
  } else {
    t = moment.tz(date, timezone());
  }

  return t;
}

// get user browser timezone abbreviation
// User browser API to identify the timezone. Sometime browser might return undefined, therefore we use moment library to guess the user's timezone
export const timezoneAbbrev = () => {
  const timeZone: string = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
  return !!timeZone ? timeZone : moment().tz(moment.tz.guess()).zoneAbbr();
};

// get user browser timezone
// User browser API to identify the timezone. Sometime browser might return undefined, therefore we use moment library to guess the user's timezone
export const timezone = () => {
  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return !!timeZone ? timeZone : moment.tz.guess();
};
