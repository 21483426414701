/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var money_pb = require('./money_pb.js')

var media_pb = require('./media_pb.js')
const proto = {};
proto.proto = require('./search_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.SearchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.SearchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SearchQueryRequest,
 *   !proto.proto.SearchQueryResponse>}
 */
const methodDescriptor_SearchService_SearchQuery = new grpc.web.MethodDescriptor(
  '/proto.SearchService/SearchQuery',
  grpc.web.MethodType.UNARY,
  proto.proto.SearchQueryRequest,
  proto.proto.SearchQueryResponse,
  /**
   * @param {!proto.proto.SearchQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SearchQueryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SearchQueryRequest,
 *   !proto.proto.SearchQueryResponse>}
 */
const methodInfo_SearchService_SearchQuery = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.SearchQueryResponse,
  /**
   * @param {!proto.proto.SearchQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.SearchQueryResponse.deserializeBinary
);


/**
 * @param {!proto.proto.SearchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.SearchQueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.SearchQueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.SearchServiceClient.prototype.searchQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.SearchService/SearchQuery',
      request,
      metadata || {},
      methodDescriptor_SearchService_SearchQuery,
      callback);
};


/**
 * @param {!proto.proto.SearchQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.SearchQueryResponse>}
 *     Promise that resolves to the response
 */
proto.proto.SearchServicePromiseClient.prototype.searchQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.SearchService/SearchQuery',
      request,
      metadata || {},
      methodDescriptor_SearchService_SearchQuery);
};


module.exports = proto.proto;

