import React, { Component } from "react";
import { jsx, css, Global } from "@emotion/core";
import ReactTable, { Column } from "react-table-6";
import "react-table-6/react-table.css";
import * as ReactCsv from "react-csv";
import moment from "moment";

interface Props {
  tableData: Array<any>;
  tab: string;
}

export default class DataTable extends Component<Props> {
  //cannot create a ReactTable obj or ref
  private reactTable: any;
  private csvLink: any;

  private revenueColumns: Column[] = [
    {
      Header: "Provider First Name",
      accessor: "providerFirstName",
      Footer: (
        <span>
          <strong>Total</strong>
        </span>
      )
    },
    {
      Header: "Provider Last Name",
      accessor: "providerLastName"
    },
    {
      Header: "Location",
      accessor: "location"
    },
    {
      Header: "OutstandingDebt",
      id: "outstandingDebt",
      accessor: (c) => {
        return c.outstandingDebt ? parseFloat(c.outstandingDebt.toFixed(2)) : 0;
      },
      Footer: (columnProps) => {
        return (
          <span>
            <strong>{this.getTotalDebt()}</strong>
          </span>
        );
      }
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      Footer: (columnProps) => {
        return (
          <span>
            <strong>{this.getTotalRevenue()}</strong>
          </span>
        );
      }
    }
  ];

  private clientColumns: Column[] = [
    {
      Header: "Client First Name",
      accessor: "firstName"
    },
    {
      Header: "Client Last Name",
      accessor: "lastName"
    },
    {
      Header: "Mobile",
      accessor: "mobile"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Address",
      accessor: "address"
    },
    {
      Header: "Revenue",
      id: "revenue",
      accessor: (c) => {
        return c.revenue ? parseFloat(c.revenue.toFixed(2)) : 0;
      }
    },
    {
      Header: "Referrals Remaining",
      accessor: "referralsRemaining"
    },
    {
      Header: "Referrers",
      accessor: "referrers"
    },
    {
      Header: "Appointments",
      accessor: "appointments"
    },
    {
      Header: "Last active",
      id: "last_active",
      accessor: (c) => {
        return c.lastActive != 1 ? `${c.lastActive} days` : `${c.lastActive} day`;
      }
    }
  ];

  private referralColumns: Column[] = [
    {
      Header: "Referrer First Name",
      accessor: "referrerFirstName"
    },
    {
      Header: "Referrer Last Name",
      accessor: "referrerLastName"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Referrals",
      accessor: "referrals"
    },
    {
      Header: "Revenue",
      id: "revenue",
      accessor: (c) => {
        return c.revenue ? parseFloat(c.revenue.toFixed(2)) : 0;
      }
    },
    {
      Header: "Average Revenue",
      id: "average_revenue",
      accessor: (c) => {
        return c.averageRevenue ? parseFloat(c.averageRevenue.toFixed(2)) : 0;
      }
    },
    {
      Header: "Last Referral",
      accessor: "lastReferral"
    }
  ];

  getTotalRevenue(): Number {
    let result = 0;
    var displayedData = this.getDisplayedData();
    displayedData.forEach((element) => {
      result = result + parseFloat(element.revenue);
    });

    return parseFloat(result.toFixed(2));
  }

  getTotalDebt(): Number {
    let result = 0;
    var displayedData = this.getDisplayedData();
    displayedData.forEach((element) => {
      result = result + element.outstandingDebt;
    });

    return parseFloat(result.toFixed(2));
  }

  private getDisplayedData(): Array<any> {
    let start: number, end: number;
    if (this.reactTable) {
      let page: number = this.reactTable.getResolvedState().page;
      let pageSize: number = this.reactTable.getResolvedState().pageSize;
      start = page * pageSize;
      end = (page + 1) * pageSize;
      return this.reactTable.getResolvedState().sortedData.slice(start, end);
    } else {
      // if this reactTable is null, it is in the initial state
      start = 0;
      end = DefaultPageSize;
      return this.props.tableData.slice(start, end);
    }
  }

  download(event: any) {}

  getCSVData(): Array<any> {
    return [];
  }

  render() {
    const { tableData, tab } = this.props;
    let columns: Column[] = [];

    // there is no table for metrics
    if (tab === "metrics") {
      return <div></div>;
    }
    if (tab === "revenue") {
      columns = this.revenueColumns;
    } else if (tab === "clients") {
      columns = this.clientColumns;
    } else if (tab === "referrals") {
      columns = this.referralColumns;
    }
    return (
      <React.Fragment>
        <Global styles={CustomReactTableCss}></Global>
        <ReactTable
          data={tableData}
          columns={columns}
          defaultPageSize={DefaultPageSize}
          ref={(r) => (this.reactTable = r)}
        ></ReactTable>

        <ReactCsv.CSVLink
          data={tableData}
          filename={`${tab}-table-generated-on-${moment().format("YYYY-MM-DD_HH-MM-SS")}.csv`}
          css={exportButtonCss}
          target="_blank"
        >
          Export table
        </ReactCsv.CSVLink>
      </React.Fragment>
    );
  }
}

const DefaultPageSize = 5;

const CustomReactTableCss = css`
  .ReactTable {
    border: none !important;
  }
  .ReactTable .rt-thead .rt-tr {
    text-align: left !important;
    color: rgba(0, 0, 0, 0.54) !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    line-height: 1.1 !important;
  }
  .ReactTable .rt-thead.-header {
    box-shadow: none !important;
  }
`;

const exportButtonCss = css`
  margin-top: 16px;
  display: inline-block;
  width: auto;
  padding: 8px 24px 10px;
  color: #00b1b6;
  background: #fff;
  box-shadow: inset 0 0 0 1px #00b1b6;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  border-radius: 99999em;
  transition: background-color 0.3s;
  height: auto;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
`;
