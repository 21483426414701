import { observable, computed, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import LoadingIcon from "./../../../components/icons/Loading";
import {
  Booking,
  BookingClient,
  CancellationChargeClientRequest,
  ProcessBookingRequest
} from "sdk/dist/bookings_pb";
import { allLineItems, requiredFundsForBooking, isGroupBooking } from "./../../../util/bookings";
import { LoadingIndicator } from "./../../../util/loading";
import {
  BackButton,
  LargeBottomButton,
  LargeButtonText,
  RightBottomButtonContainer
} from "./Entry";
import { ClientOverview } from "./PaymentWizard/ClientOverview";
import { FundType } from "sdk/dist/offerings_pb";
import { rpc, metadata } from "./../../../grpc";
import { bookingStore } from "./../../../stores/booking-store";
import { AccountItem } from "sdk/dist/account_items_pb";
import { accItemStore } from "./../../../stores/acc_item-store";

import { clientFundStore } from "./../../../stores/client_fund-store";
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import Popup from "reactjs-popup";
import { thirdPartyInvStore } from "./../../../stores/third_party_inv-store";
import { toastStore } from "./../../../stores/toast-store";
import { rebookDataStore } from "../../../mobx/stores/RebookDataStore";
import { RebookModal } from "../../../components/modal/RebookModal";
import * as Sentry from "@sentry/browser";
interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  onRequestClose: () => void;
  dispatch?: any;
}

interface State {
  popupHeight: String;
  open: boolean;
}

@observer
export class Payment extends Component<Props, State> {
  @observable
  private indicator = new LoadingIndicator();
  private interval: any;
  @observable
  private mounted = false;

  @computed
  get accountItems() {
    const accStoreFilter = accItemStore.all();
    const { booking, client } = this.props;
    if (this.props.client) {
      return accStoreFilter.filter(
        (ai: AccountItem.AsObject) =>
          ai.booking!.id == booking.id && ai.clientId == client!.clientId
      );
    } else {
      return accStoreFilter.filter(
        (ai: AccountItem.AsObject) => ai.booking!.id == this.props.booking.id
      );
    }
  }

  async componentDidMount() {
    const { booking, client } = this.props;
    await accItemStore.loadByBookingClient(booking.id, client!.clientId);
    await clientFundStore.load(client!.clientId);

    this.mounted = true; // Toggle to activate refresh
    // Refresh stores every 5 seconds
    this.loadStores(booking, client!);
  }

  async loadStores(booking: Booking.AsObject, client: BookingClient.AsObject) {
    this.interval = setInterval(() => {
      if (
        this.mounted &&
        this.accountItems
          .filter(
            (ai) =>
              ai.status != AccountItem.Status.ACC_ITEM_ERROR &&
              ai.fundType != FundType.THIRD_PARTY_INVOICE
          )
          .filter(
            (ai) =>
              ai.status == AccountItem.Status.ACC_ITEM_PROCESSING ||
              ai.status == AccountItem.Status.ACC_ITEM_CREATED
          ).length > 0
      ) {
        bookingStore.load(booking.id);
        accItemStore.loadByBookingClient(booking.id, client!.clientId);
      }
    }, 5000);
  }

  componentWillUnmount() {
    // stop call execution
    this.mounted = false;
    clearInterval(this.interval);
  }

  checkClientReferrals(): Promise<boolean> {
    if (this.props.client!.activeReferralId === "") {
      return new Promise((resolve, reject) => {
        this.props.dispatch(
          ReduxDialogs.openAction(
            "To send this claim with a referral you must click on the referral to highlight it. Are you sure you want to send this claim without a referral?",
            null,
            null,
            (check: any, yay: any, notify: any) => resolve(yay),
            "Yes",
            "No"
          )
        );
      });
    } else {
      return Promise.resolve(true);
    }
  }

  checkProcessButtonDisabled(): boolean {
    const { booking, client } = this.props;

    var needRequiredFunds = true;
    // If it's group booking, this will check only the client selected
    if (isGroupBooking(booking)) {
      needRequiredFunds =
        requiredFundsForBooking(booking, client, this.accountItems).missing.length > 0;
    } else {
      // we can process the booking when all clients:
      booking.clientsList.every(async (client) => {
        // have all the required funds entered in the system
        needRequiredFunds =
          requiredFundsForBooking(booking, client, this.accountItems).missing.length > 0;
      });
    }
    // if any of the following is true, then booking can't be processed
    return needRequiredFunds;
  }

  async processBooking(booking: Booking.AsObject, client: BookingClient.AsObject) {
    for (const item of allLineItems(booking, client)) {
      switch (item.fundType) {
        case FundType.DVA_ALLIED ||
          FundType.MEDICARE_BULK ||
          FundType.MEDICARE_STANDARD ||
          FundType.HICAPS:
          if (!(await this.checkClientReferrals())) {
            return;
          }
          break;
      }
    }

    const processBookingReq = new ProcessBookingRequest();
    processBookingReq.setBookingId(booking.id);
    processBookingReq.setClientId(client!.clientId);
    await this.indicator.while(async () => {
      const res = await rpc.bookings.processBooking(processBookingReq, metadata());
      await bookingStore.add(res.toObject().booking!);
      await accItemStore.loadByBookingClient(booking.id, client.clientId);
      await thirdPartyInvStore.load(booking.id, client.clientId);
      toastStore.success(`Successfully created account items for booking ${booking.friendlyId}`);
      this.loadStores(booking, client!);
    });
  }

  async cancellationCharge(booking: Booking.AsObject, client: BookingClient.AsObject) {
    const reqCancellationCharge = new CancellationChargeClientRequest();
    reqCancellationCharge.setBookingId(booking.id);
    reqCancellationCharge.setClientId(client.clientId);
    await this.indicator.while(async () => {
      await rpc.bookingClients.cancellationCharge(reqCancellationCharge, metadata());
      toastStore.success(
        `Successfully submitted cancellation payment for client ${
          (client.firstName, " ", client.lastName)
        }`
      );
      await accItemStore.loadByBookingClient(booking.id, client.clientId);
      this.loadStores(booking, client!);
    });
  }

  onBackClicked = () => {
    this.props.onRequestClose();
  };

  render() {
    const { booking, client } = this.props;

    return (
      <React.Fragment>
        {booking && (
          <>
            <ClientOverview
              booking={booking}
              client={client}
              accountItems={this.accountItems}
              {...this.props}
            />
            {rebookDataStore.isOpen && (
              <Popup
                modal
                open={rebookDataStore.isOpen}
                onClose={() => {
                  rebookDataStore.isOpen = false;
                }}
                contentStyle={{
                  width: "360px",
                  height: "175px",
                  borderRadius: "9px",
                  borderWidth: "0px",
                  margin: "auto auto auto auto"
                }}
              >
                {
                  <RebookModal
                    duration={booking.duration}
                    startTime={booking.startDatetime}
                    bookingId={booking.id}
                    clientId={client.clientId}
                    offeringId={booking.offeringsList[0].lineItem!.offering!.id}
                    dispatch={this.props.dispatch}
                    locationId={booking.locationId}
                    providerId={booking.provider!.id}
                  />
                }
              </Popup>
            )}

            {client!.paymentStatus == BookingClient.PaymentStatus.PENDING &&
              this.accountItems.filter(
                (v: AccountItem.AsObject) =>
                  v.status == AccountItem.Status.ACC_ITEM_PROCESSING &&
                  v.type != AccountItem.Type.ACC_ITEM_THIRD_PARTY
              ).length == 0 && (
                <RightBottomButtonContainer>
                  <LargeBottomButton
                    disabled={this.indicator.isLoading() ? true : this.checkProcessButtonDisabled()}
                    onClick={() => {
                      if (booking.status == Booking.Status.BOOKING_CANCELLED) {
                        this.cancellationCharge(booking, client!);
                      } else {
                        this.processBooking(booking, client!)
                          .then((result) => {
                            // show the RebookModal popup when the payment request is successfully submitted
                            if (booking.status != Booking.Status.BOOKING_CANCELLED) {
                              rebookDataStore.isOpen = true;
                            }
                          })
                          .catch((err) => {
                            Sentry.captureException(err);
                          });
                      }
                    }}
                  >
                    {this.indicator.isLoading() ? (
                      <LoadingIcon
                        width={16}
                        height={16}
                        color="#FFF"
                        style={{ display: "block", margin: "auto" }}
                      />
                    ) : (
                      <LargeButtonText>Process</LargeButtonText>
                    )}
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              )}
          </>
        )}
        <BackButton onClick={this.onBackClicked}>Back</BackButton>
      </React.Fragment>
    );
  }
}
