import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { ResourceTypes } from "./../../redux/features/resources/definitions";

import { colors, hexToRgba } from "./../../util/consts";
import typography from "./../../util/typography";

// Utils
import { breakpoints } from "./../../util/consts";

import Dialog from "./Dialog";
import ButtonBase from "./../../components/ButtonBase";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import Label from "./../../components/form/Label";
import TextArea from "./../../components/form/TextArea";
import Section from "./../../components/form/Section";

import LoadingIcon from "./../../components/icons/Loading";
import { CancelBookingClientRequest } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "./../../grpc";
import { bookingStore } from "./../../stores/booking-store";
import { toastStore } from "./../../stores/toast-store";
import { rebookDataStore } from "../../mobx/stores/RebookDataStore";

interface Props {
  data: Data;
  dispatch?: any;
  dialogId?: any;
}

interface Data {
  booking_id: string;
  client_id?: string;
  cb?: any;
  trigger?: any;
}

class CancelDialog extends React.Component<Props> {
  state = {
    message: "",
    checked: true,
    allEvents: false,
    isLoading: false
  };

  closeDialog = () => {
    if (this.state.isLoading) {
      return;
    }
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  cancelBooking = async () => {
    const {
      data: { booking_id, client_id },
      dispatch,
      dialogId
    } = this.props;
    const { message, checked, allEvents } = this.state;

    // Rebook modal appears
    rebookDataStore.isOpen = true;

    // Disabling this dialog
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });

    // Making grpc request
    try {
      const req = new CancelBookingClientRequest();

      req.setBookingId(booking_id);
      if (client_id) req.setClientId(client_id);
      req.setMessage(message);
      req.setSendNotification(checked);
      req.setAllEvents(allEvents);

      if (allEvents) {
        const res = await rpc.bookings.cancelBulk(req, metadata());

        let bookingsList = res.toObject().bookingsList;
        for (var index in bookingsList) {
          bookingStore.add(bookingsList[index]);
        }
      } else {
        const res = await rpc.bookingClients.cancel(req, metadata());
        bookingStore.add(res.toObject());
      }

      this.setState({ isLoading: false });

      // Close this dialog
      dispatch(ReduxDialogs.actions.close(dialogId));
      toastStore.success("Booking cancelled.");
    } catch (err) {
      toastStore.grpcError(err);
      this.setState({ isLoading: false });
    }
  };

  handleMessageChange = (e: any) => {
    this.setState({ message: e.target.value });
  };

  handleCheckChange = (e: any) => {
    this.setState({ checked: !this.state.checked });
  };

  handleThisEventCheckChange = (e: any) => {
    this.setState({ allEvents: !this.state.allEvents });
  };

  render() {
    const { isLoading, message, checked, allEvents } = this.state;
    const { data } = this.props;
    return (
      <Dialog
        dialogId={this.props.dialogId}
        trigger={data.trigger}
        overideCloseDialog={this.closeDialog}
        noClose
      >
        <Container>
          <Section>
            <FormSection style={{ marginBottom: 20 }}>Cancel Appointment</FormSection>
            <Label htmlFor="cancel-Message">Message to user(s)</Label>
            <TextArea
              id="cancel-Message"
              name="Message"
              type="text"
              placeholder="Enter a message (Optional)"
              value={message}
              onChange={this.handleMessageChange}
              disabled={isLoading}
              maxHeight={200}
            />
          </Section>
          <Section style={{ marginTop: 20 }}>
            <CheckRadioBox
              type="checkbox"
              checked={checked}
              onChange={this.handleCheckChange}
              disabled={isLoading}
            >
              Notify the user?
            </CheckRadioBox>
          </Section>
          <Section style={{ marginTop: 0 }}>
            <CheckRadioBox
              type="checkbox"
              checked={!allEvents}
              onChange={this.handleThisEventCheckChange}
              disabled={isLoading}
            >
              This Event
            </CheckRadioBox>
          </Section>
          <Section style={{ marginTop: 0 }}>
            <CheckRadioBox
              type="checkbox"
              checked={allEvents}
              onChange={this.handleThisEventCheckChange}
              disabled={isLoading}
            >
              This and following events
            </CheckRadioBox>
          </Section>

          <ButtonContainer>
            <LargeBottomButton isPrimary={true} onClick={this.closeDialog}>
              <LargeButtonText>No</LargeButtonText>
            </LargeBottomButton>
            <LargeBottomButton onClick={this.cancelBooking}>
              {isLoading ? (
                <LoadingIcon width={16} height={16} color="#FFF" />
              ) : (
                <LargeButtonText>Yes</LargeButtonText>
              )}
            </LargeBottomButton>
          </ButtonContainer>
        </Container>
      </Dialog>
    );
  }
}

const FormSection = styled.div`
  ${typography.heading6};
  font-weight: 600;
  margin-top: 36px;
`;

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;

export default compose(connect())(CancelDialog as any);
