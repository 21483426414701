import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Select from "./../../../components/form/BaseSelect";

class LocEditAccount_Fund_AUClaiming extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Medicare/DVA Details"
          subtitle="Please fill out the relevate Medicare/DVA information about your location"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="locedit-claiming-minorid">Minor ID</Label>
            <Input
              id="locedit-claiming-minorid"
              name="MinorID"
              type="text"
              placeholder=""
              value={values.MinorID}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.MinorID && errors.MinorID}
              disabled
            />
            {!!errors.MinorID && touched.MinorID && <Error>{errors.MinorID}</Error>}
          </Section>
          <Section>
            <Label htmlFor="locedit-claiming-facilitytype">Facility Type</Label>
            <Select
              id="locedit-claiming-facilitytype"
              name="Type"
              value={values.Type}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Type && errors.Type}
            >
              <option value="" />
              <option value="HomeVisit">Home Visit</option>
              <option value="Hospital">Hospital</option>
              <option value="Rooms">Rooms</option>
              <option value="ResidentialCareFacility">Residential Care Facility</option>
              <option value="CommunityHealthCentres">Community Health Centres</option>
            </Select>
            {!!errors.Type && touched.Type && <Error>{errors.Type}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

// There's a lot of unused code here because we removed a bunch
// of fields from the form.
// the only remaining form field is not user editable.
// we can purge a lot of dead code here when we have time :D
const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData, loc }) => {
    if (!loc.FundProperties || !loc.FundProperties.AUClaiming) {
      return {
        MinorID: "",
        Payee: "",
        FacilityProvider: "",
        Type: "",
      };
    } else {
      return {
        MinorID: !loc.FundProperties.AUClaiming.MinorID ? "" : loc.FundProperties.AUClaiming.MinorID,
        Payee: !loc.FundProperties.AUClaiming.Payee ? "" : loc.FundProperties.AUClaiming.Payee,
        FacilityProvider: !loc.FundProperties.AUClaiming.FacilityProvider ? "" : loc.FundProperties.AUClaiming.FacilityProvider,
        Type: !loc.FundProperties.AUClaiming.Type ? "" : loc.FundProperties.AUClaiming.Type,
      };
    }
  },
  validationSchema: Yup.object().shape({
    MinorID: Yup.string().notRequired(),
    Payee: Yup.string().notRequired(),
    FacilityProvider: Yup.string().notRequired(),
    Type: Yup.string().notRequired()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      const updLoc = {
        ...props.loc,
        FundProperties: {
          ...(props.loc.FundProperties||{}),
          AUClaiming: {
            ...(props.loc.FundProperties&&props.loc.FundProperties.AUClaiming||{}),
            MinorID: values.MinorID,
            Payee: values.Payee || null,
            FacilityProvider: values.FacilityProvider || null,
            Type: values.Type || null,
          }
        }
      };

      await props.dispatch(ResourceActions.action(updLoc, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LocEditAccountFundAUClaiming"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(LocEditAccount_Fund_AUClaiming);
