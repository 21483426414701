// source: accounts_table.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var account_items_pb = require('./account_items_pb.js');
goog.object.extend(proto, account_items_pb);
var offerings_pb = require('./offerings_pb.js');
goog.object.extend(proto, offerings_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
goog.exportSymbol('proto.proto.AccTableRowStatus', null, global);
goog.exportSymbol('proto.proto.AccountRow', null, global);
goog.exportSymbol('proto.proto.FiltersAccountTable', null, global);
goog.exportSymbol('proto.proto.FiltersAccountTable.Type', null, global);
goog.exportSymbol('proto.proto.GetAccountTableRequest', null, global);
goog.exportSymbol('proto.proto.GetFiltersTableRequest', null, global);
goog.exportSymbol('proto.proto.ListAccountTableResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAccountTableResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAccountTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountTableResponse.displayName = 'proto.proto.ListAccountTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountRow.displayName = 'proto.proto.AccountRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetAccountTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetAccountTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetAccountTableRequest.displayName = 'proto.proto.GetAccountTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetFiltersTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetFiltersTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetFiltersTableRequest.displayName = 'proto.proto.GetFiltersTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.FiltersAccountTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.FiltersAccountTable.repeatedFields_, null);
};
goog.inherits(proto.proto.FiltersAccountTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.FiltersAccountTable.displayName = 'proto.proto.FiltersAccountTable';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAccountTableResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountrowsList: jspb.Message.toObjectList(msg.getAccountrowsList(),
    proto.proto.AccountRow.toObject, includeInstance),
    totalRowCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountTableResponse}
 */
proto.proto.ListAccountTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountTableResponse;
  return proto.proto.ListAccountTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountTableResponse}
 */
proto.proto.ListAccountTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountRow;
      reader.readMessage(value,proto.proto.AccountRow.deserializeBinaryFromReader);
      msg.addAccountrows(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRowCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountRow.serializeBinaryToWriter
    );
  }
  f = message.getTotalRowCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated AccountRow AccountRows = 1;
 * @return {!Array<!proto.proto.AccountRow>}
 */
proto.proto.ListAccountTableResponse.prototype.getAccountrowsList = function() {
  return /** @type{!Array<!proto.proto.AccountRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountRow, 1));
};


/**
 * @param {!Array<!proto.proto.AccountRow>} value
 * @return {!proto.proto.ListAccountTableResponse} returns this
*/
proto.proto.ListAccountTableResponse.prototype.setAccountrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountRow}
 */
proto.proto.ListAccountTableResponse.prototype.addAccountrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAccountTableResponse} returns this
 */
proto.proto.ListAccountTableResponse.prototype.clearAccountrowsList = function() {
  return this.setAccountrowsList([]);
};


/**
 * optional int64 total_row_count = 2;
 * @return {number}
 */
proto.proto.ListAccountTableResponse.prototype.getTotalRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAccountTableResponse} returns this
 */
proto.proto.ListAccountTableResponse.prototype.setTotalRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountRow.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastUpdated: jspb.Message.getFieldWithDefault(msg, 3, ""),
    friendlyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fundType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 7, ""),
    providerImgUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    client: jspb.Message.getFieldWithDefault(msg, 9, ""),
    clientImgUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    noClients: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bookingDate: jspb.Message.getFieldWithDefault(msg, 15, ""),
    accItemType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    bookingId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    bookingStartTime: jspb.Message.getFieldWithDefault(msg, 18, ""),
    bookingEndTime: jspb.Message.getFieldWithDefault(msg, 19, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    clientEmail: jspb.Message.getFieldWithDefault(msg, 21, ""),
    clientMobile: jspb.Message.getFieldWithDefault(msg, 22, ""),
    clientStatus: jspb.Message.getFieldWithDefault(msg, 23, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    orgName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    orgAbn: jspb.Message.getFieldWithDefault(msg, 26, ""),
    offeringId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    offeringName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    feeNoGst: jspb.Message.getFieldWithDefault(msg, 29, ""),
    gst: jspb.Message.getFieldWithDefault(msg, 30, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 31, ""),
    booklyfeFee: jspb.Message.getFieldWithDefault(msg, 32, ""),
    taxType: jspb.Message.getFieldWithDefault(msg, 33, ""),
    invoiceDate: jspb.Message.getFieldWithDefault(msg, 34, ""),
    invoiceNo: jspb.Message.getFieldWithDefault(msg, 35, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    invoiceDownloadUrl: jspb.Message.getFieldWithDefault(msg, 37, ""),
    stripePaymentId: jspb.Message.getFieldWithDefault(msg, 38, ""),
    stripePayoutId: jspb.Message.getFieldWithDefault(msg, 39, ""),
    stripePayoutStatus: jspb.Message.getFieldWithDefault(msg, 40, ""),
    stripeDestinationPaymentId: jspb.Message.getFieldWithDefault(msg, 41, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountRow}
 */
proto.proto.AccountRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountRow;
  return proto.proto.AccountRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountRow}
 */
proto.proto.AccountRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderImgUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientImgUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 12:
      var value = /** @type {!proto.proto.AccTableRowStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoClients(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingDate(value);
      break;
    case 16:
      var value = /** @type {!proto.proto.AccountItem.Type} */ (reader.readEnum());
      msg.setAccItemType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingStartTime(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingEndTime(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientEmail(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientMobile(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientStatus(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgAbn(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferingId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferingName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeNoGst(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setGst(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setBooklyfeFee(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxType(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceDate(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNo(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceDownloadUrl(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePaymentId(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePayoutId(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePayoutStatus(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeDestinationPaymentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastUpdated();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFriendlyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFundType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProviderImgUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getClientImgUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNoClients();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBookingDate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAccItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBookingStartTime();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBookingEndTime();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getClientEmail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getClientMobile();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getClientStatus();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getOrgAbn();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getOfferingId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getOfferingName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getFeeNoGst();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getGst();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getBooklyfeFee();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getTaxType();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getInvoiceDate();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getInvoiceNo();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getInvoiceDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getStripePaymentId();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getStripePayoutId();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getStripePayoutStatus();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getStripeDestinationPaymentId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_updated = 3;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getLastUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setLastUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string friendly_id = 4;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getFriendlyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setFriendlyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_type = 5;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBookingType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBookingType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fund_type = 6;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getFundType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setFundType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string provider = 7;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string provider_img_url = 8;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getProviderImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setProviderImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string client = 9;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string client_img_url = 10;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClientImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClientImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string fee = 11;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional AccTableRowStatus status = 12;
 * @return {!proto.proto.AccTableRowStatus}
 */
proto.proto.AccountRow.prototype.getStatus = function() {
  return /** @type {!proto.proto.AccTableRowStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.proto.AccTableRowStatus} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string location_id = 13;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string no_clients = 14;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getNoClients = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setNoClients = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string booking_date = 15;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBookingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBookingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional AccountItem.Type acc_item_type = 16;
 * @return {!proto.proto.AccountItem.Type}
 */
proto.proto.AccountRow.prototype.getAccItemType = function() {
  return /** @type {!proto.proto.AccountItem.Type} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.proto.AccountItem.Type} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setAccItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string booking_id = 17;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string booking_start_time = 18;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBookingStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBookingStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string booking_end_time = 19;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBookingEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBookingEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string location_name = 20;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string client_email = 21;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClientEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClientEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string client_mobile = 22;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClientMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClientMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string client_status = 23;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getClientStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setClientStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string provider_id = 24;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string org_name = 25;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string org_abn = 26;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getOrgAbn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setOrgAbn = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string offering_id = 27;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getOfferingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setOfferingId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string offering_name = 28;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getOfferingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setOfferingName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string fee_no_gst = 29;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getFeeNoGst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setFeeNoGst = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string gst = 30;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getGst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setGst = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string currency = 31;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string booklyfe_fee = 32;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getBooklyfeFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setBooklyfeFee = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string tax_type = 33;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getTaxType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setTaxType = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string invoice_date = 34;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getInvoiceDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setInvoiceDate = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string invoice_no = 35;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getInvoiceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setInvoiceNo = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string organisation_id = 36;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string invoice_download_url = 37;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getInvoiceDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setInvoiceDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string stripe_payment_id = 38;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getStripePaymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setStripePaymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string stripe_payout_id = 39;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getStripePayoutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setStripePayoutId = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string stripe_payout_status = 40;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getStripePayoutStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setStripePayoutStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string stripe_destination_payment_id = 41;
 * @return {string}
 */
proto.proto.AccountRow.prototype.getStripeDestinationPaymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountRow} returns this
 */
proto.proto.AccountRow.prototype.setStripeDestinationPaymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetAccountTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetAccountTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetAccountTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetAccountTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && proto.proto.FiltersAccountTable.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageNo: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetAccountTableRequest}
 */
proto.proto.GetAccountTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetAccountTableRequest;
  return proto.proto.GetAccountTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetAccountTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetAccountTableRequest}
 */
proto.proto.GetAccountTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.FiltersAccountTable;
      reader.readMessage(value,proto.proto.FiltersAccountTable.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetAccountTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetAccountTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetAccountTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetAccountTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.FiltersAccountTable.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPageNo();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional FiltersAccountTable filters = 1;
 * @return {?proto.proto.FiltersAccountTable}
 */
proto.proto.GetAccountTableRequest.prototype.getFilters = function() {
  return /** @type{?proto.proto.FiltersAccountTable} */ (
    jspb.Message.getWrapperField(this, proto.proto.FiltersAccountTable, 1));
};


/**
 * @param {?proto.proto.FiltersAccountTable|undefined} value
 * @return {!proto.proto.GetAccountTableRequest} returns this
*/
proto.proto.GetAccountTableRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetAccountTableRequest} returns this
 */
proto.proto.GetAccountTableRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetAccountTableRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.proto.GetAccountTableRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetAccountTableRequest} returns this
 */
proto.proto.GetAccountTableRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 page_no = 3;
 * @return {number}
 */
proto.proto.GetAccountTableRequest.prototype.getPageNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetAccountTableRequest} returns this
 */
proto.proto.GetAccountTableRequest.prototype.setPageNo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetFiltersTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetFiltersTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetFiltersTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetFiltersTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && proto.proto.FiltersAccountTable.toObject(includeInstance, f),
    getAccItemType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetFiltersTableRequest}
 */
proto.proto.GetFiltersTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetFiltersTableRequest;
  return proto.proto.GetFiltersTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetFiltersTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetFiltersTableRequest}
 */
proto.proto.GetFiltersTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.FiltersAccountTable;
      reader.readMessage(value,proto.proto.FiltersAccountTable.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 2:
      var value = /** @type {!proto.proto.AccountItem.Type} */ (reader.readEnum());
      msg.setGetAccItemType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetFiltersTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetFiltersTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetFiltersTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetFiltersTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.FiltersAccountTable.serializeBinaryToWriter
    );
  }
  f = message.getGetAccItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional FiltersAccountTable filters = 1;
 * @return {?proto.proto.FiltersAccountTable}
 */
proto.proto.GetFiltersTableRequest.prototype.getFilters = function() {
  return /** @type{?proto.proto.FiltersAccountTable} */ (
    jspb.Message.getWrapperField(this, proto.proto.FiltersAccountTable, 1));
};


/**
 * @param {?proto.proto.FiltersAccountTable|undefined} value
 * @return {!proto.proto.GetFiltersTableRequest} returns this
*/
proto.proto.GetFiltersTableRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetFiltersTableRequest} returns this
 */
proto.proto.GetFiltersTableRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetFiltersTableRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountItem.Type get_acc_item_type = 2;
 * @return {!proto.proto.AccountItem.Type}
 */
proto.proto.GetFiltersTableRequest.prototype.getGetAccItemType = function() {
  return /** @type {!proto.proto.AccountItem.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.proto.AccountItem.Type} value
 * @return {!proto.proto.GetFiltersTableRequest} returns this
 */
proto.proto.GetFiltersTableRequest.prototype.setGetAccItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.FiltersAccountTable.repeatedFields_ = [3,4,5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.FiltersAccountTable.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.FiltersAccountTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.FiltersAccountTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FiltersAccountTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    friendlyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    fundTypeList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    accItemTypeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    provider: jspb.Message.getFieldWithDefault(msg, 6, ""),
    providerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    client: jspb.Message.getFieldWithDefault(msg, 8, ""),
    feeFrom: (f = msg.getFeeFrom()) && money_pb.Money.toObject(includeInstance, f),
    feeTo: (f = msg.getFeeTo()) && money_pb.Money.toObject(includeInstance, f),
    statusList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    bookingDateFrom: jspb.Message.getFieldWithDefault(msg, 12, ""),
    bookingDateTo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    bookingId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.FiltersAccountTable}
 */
proto.proto.FiltersAccountTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.FiltersAccountTable;
  return proto.proto.FiltersAccountTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.FiltersAccountTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.FiltersAccountTable}
 */
proto.proto.FiltersAccountTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.proto.FiltersAccountTable.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addType(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.proto.FundType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFundType(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<!proto.proto.AccountItem.Type>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccItemType(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 9:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setFeeFrom(value);
      break;
    case 10:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setFeeTo(value);
      break;
    case 11:
      var values = /** @type {!Array<!proto.proto.AccTableRowStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingDateFrom(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingDateTo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.FiltersAccountTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.FiltersAccountTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.FiltersAccountTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.FiltersAccountTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFriendlyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getFundTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getAccItemTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFeeFrom();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getFeeTo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getBookingDateFrom();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBookingDateTo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.FiltersAccountTable.Type = {
  UNSPECIFIED: 0,
  SINGLE: 1,
  GROUP: 2,
  PRODUCT: 3
};

/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.FiltersAccountTable.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.FiltersAccountTable} returns this
*/
proto.proto.FiltersAccountTable.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FiltersAccountTable.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string friendly_id = 2;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getFriendlyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setFriendlyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Type type = 3;
 * @return {!Array<!proto.proto.FiltersAccountTable.Type>}
 */
proto.proto.FiltersAccountTable.prototype.getTypeList = function() {
  return /** @type {!Array<!proto.proto.FiltersAccountTable.Type>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.proto.FiltersAccountTable.Type>} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setTypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.proto.FiltersAccountTable.Type} value
 * @param {number=} opt_index
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.addType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearTypeList = function() {
  return this.setTypeList([]);
};


/**
 * repeated FundType fund_type = 4;
 * @return {!Array<!proto.proto.FundType>}
 */
proto.proto.FiltersAccountTable.prototype.getFundTypeList = function() {
  return /** @type {!Array<!proto.proto.FundType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.proto.FundType>} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setFundTypeList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.proto.FundType} value
 * @param {number=} opt_index
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.addFundType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearFundTypeList = function() {
  return this.setFundTypeList([]);
};


/**
 * repeated AccountItem.Type acc_item_type = 5;
 * @return {!Array<!proto.proto.AccountItem.Type>}
 */
proto.proto.FiltersAccountTable.prototype.getAccItemTypeList = function() {
  return /** @type {!Array<!proto.proto.AccountItem.Type>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.proto.AccountItem.Type>} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setAccItemTypeList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.proto.AccountItem.Type} value
 * @param {number=} opt_index
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.addAccItemType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearAccItemTypeList = function() {
  return this.setAccItemTypeList([]);
};


/**
 * optional string provider = 6;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string provider_id = 7;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string client = 8;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Money fee_from = 9;
 * @return {?proto.proto.Money}
 */
proto.proto.FiltersAccountTable.prototype.getFeeFrom = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 9));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.FiltersAccountTable} returns this
*/
proto.proto.FiltersAccountTable.prototype.setFeeFrom = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearFeeFrom = function() {
  return this.setFeeFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FiltersAccountTable.prototype.hasFeeFrom = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Money fee_to = 10;
 * @return {?proto.proto.Money}
 */
proto.proto.FiltersAccountTable.prototype.getFeeTo = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 10));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.FiltersAccountTable} returns this
*/
proto.proto.FiltersAccountTable.prototype.setFeeTo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearFeeTo = function() {
  return this.setFeeTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.FiltersAccountTable.prototype.hasFeeTo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated AccTableRowStatus status = 11;
 * @return {!Array<!proto.proto.AccTableRowStatus>}
 */
proto.proto.FiltersAccountTable.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.proto.AccTableRowStatus>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.proto.AccTableRowStatus>} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.proto.AccTableRowStatus} value
 * @param {number=} opt_index
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional string booking_date_from = 12;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getBookingDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setBookingDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string booking_date_to = 13;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getBookingDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setBookingDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string organisation_id = 14;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string location_id = 15;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string booking_id = 16;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string location_name = 17;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string timezone = 18;
 * @return {string}
 */
proto.proto.FiltersAccountTable.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.FiltersAccountTable} returns this
 */
proto.proto.FiltersAccountTable.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * @enum {number}
 */
proto.proto.AccTableRowStatus = {
  ACC_ROW_UNKNOWN: 0,
  ACC_ROW_UNPROCESSED: 1,
  ACC_ROW_PROCESSING: 2,
  ACC_ROW_CREATED: 3,
  ACC_ROW_COMPLETED: 4,
  ACC_ROW_CANCELLED: 5,
  ACC_ROW_PAYMENT_ERROR: 6,
  ACC_ROW_CLAIM_ERROR: 7,
  ACC_ROW_UNPAID_TPI: 8,
  ACC_ROW_PAID_TPI: 9,
  ACC_ROW_ALL: 10
};

goog.object.extend(exports, proto.proto);
