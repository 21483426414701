import React, { useState } from "react";
import Avatar from "../../../components/Avatar";
import { ProviderOfferings } from ".";
import { useDispatch } from "react-redux";
import * as ReduxDialogs from "../../../redux/features/dialogs";
import { BuyProdDialogProps } from "../../../components/dialogs/BuyProductDialog";

interface Props {
  product: ProviderOfferings;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  // State for info button
  const [isInfoVisible, toggleInfoVisibility] = useState(false);

  const dispatch = useDispatch();

  // Buy Product
  const buyProduct = async (productID: string) => {
    const dialogProps: BuyProdDialogProps = {
      data: { productID: productID },
    }
    dispatch(ReduxDialogs.openBuyProduct(dialogProps));
  }

  return (
    <div className="rounded overflow-hidden shadow-lg">
      {product.image.DownloadURL ?
        <img
          className="w-full h-40 object-cover	"
          src={product.image.DownloadURL}
          alt={`Product name `}
        />
        :
        <Avatar letters={`${product.Name.charAt(0)}`} size={90} lfrac={0.3} />
      }
      <div className="p-2">
        <div className="overflow-hidden font-bold text-2xl mb-2 h-auto md:h-20">{product.Name}</div>
        <p className="text-green-900 text-base">${product.Fee}</p>
        <div className="pt-10 pb-2">
          <div className="inline-flex justify-between items-center w-full">
            <button className="uppercase btn btn-green" onClick={() => buyProduct(product.ID)}>
              BUY
            </button>
            <div>
              <button
                className="text-green-900 uppercase font-bold inline-flex items-center"
                onClick={() => toggleInfoVisibility(!isInfoVisible)}
              >
                <span>More Info</span>
                {isInfoVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20 12H4"
                    />
                  </svg>
                ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  )}
              </button>
            </div>
          </div>
          <div className={`w-full pt-8 ${!isInfoVisible ? "hidden" : ""}`}>{product.Description}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
