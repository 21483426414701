import React from "react";
import { CreditCard } from "sdk/dist/credit_cards_pb";
import { AsyncButton } from "../../../ui-kit/AsyncButton";
import { Container } from "../../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../../ui-kit/FlexBox";
import { MastercardIcon } from "../../../ui-kit/icons/MastercardIcon";
import { PaymentCardIcon } from "../../../ui-kit/icons/PaymentCardIcon";
import { VisaCardIcon } from "../../../ui-kit/icons/VisaCardIcon";
import { Paper } from "../../../ui-kit/Paper";
import { Typography } from "../../../ui-kit/Typography";

interface IProps {
  card: CreditCard.AsObject;
  setPrimaryCard: () => void;
  onRemove: () => void;
  isUserCC?: boolean;
  displayTitle?: boolean;
}

export const PaymentCard = (props: IProps) => {
  const renderCardTypeIcon = () => {
    switch (props.card.brand) {
      case "Visa":
        return <VisaCardIcon />;
      case "Mastercard":
        return <MastercardIcon />;
      default:
        return <PaymentCardIcon />;
    }
  };

  return (
    <FlexBox direction="col">
      {props.card.isPrimary && (
        <FlexItem>
          <Typography.H3>{props.isUserCC ? "Primary (user)" : "Primary"}</Typography.H3>
        </FlexItem>
      )}

      {props.displayTitle && (
        <FlexItem>
          <Typography.H3>Other cards</Typography.H3>
        </FlexItem>
      )}

      <FlexItem>
        <FlexBox direction="col" alignItems="center">
          <Paper color="neutral" width="full" rounded="sm">
            <Container type="block" spacing={[3, 5]} noPadding>
              <FlexItem fullWidth>
                <FlexBox alignItems="center" direction="row" spacing={[6, 0]}>
                  <FlexItem>{renderCardTypeIcon()}</FlexItem>

                  <FlexItem>
                    <FlexBox direction="col" justifyContent="start">
                      <FlexItem fullWidth>
                        <Typography.Body truncate noPadding>
                          {props.card.brand} **** {props.card.last4}
                        </Typography.Body>
                      </FlexItem>

                      <FlexItem fullWidth>
                        <Typography.Label truncate noPadding>
                          Expires {props.card.expirationMonth}/{props.card.expirationYear % 100}
                        </Typography.Label>
                      </FlexItem>
                    </FlexBox>
                  </FlexItem>
                </FlexBox>
              </FlexItem>
            </Container>
          </Paper>

          <FlexItem fullWidth>
            <FlexBox direction="row" justifyContent="end" spacing={[8, 0]}>
              <FlexItem>
                <AsyncButton.Action onClick={props.onRemove}>Remove</AsyncButton.Action>
              </FlexItem>

              <FlexItem>
                <AsyncButton.Action
                  onClick={props.setPrimaryCard}
                  isDisabled={props.card.isPrimary}
                >
                  {props.card && props.card.isPrimary ? "Primary card" : "Set as primary card"}
                </AsyncButton.Action>
              </FlexItem>
            </FlexBox>
          </FlexItem>
        </FlexBox>
      </FlexItem>
    </FlexBox>
  );
};
