import React, { PureComponent } from "react";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";
import ClientDetails from "../ClientActionDialog/Details";
import { AppDetails } from "./AppDetails";
import Clients from "./Clients";
import { bookingStore } from "./../../../stores/booking-store";

interface Props {
  bookingObj: Booking.AsObject;
  client?: BookingClient.AsObject;
}

export class Details extends PureComponent<Props> {
  onSetClient() {
    if (this) {
      bookingStore.load(this.props.bookingObj.id);
    }
  }

  render() {
    const { bookingObj, client } = this.props;
    return (
      <React.Fragment>
        {bookingObj.type === Booking.Type.BOOKING_GROUP ? (
          <Clients booking={bookingObj} setClient={this.onSetClient} />
        ) : (
          client && <ClientDetails clientId={client.clientId} bookingId={bookingObj.id} />
        )}
        <AppDetails booking={bookingObj} />
      </React.Fragment>
    );
  }
}
