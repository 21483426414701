export const persistence = {
  restore: <T>(key: string, fallback?: T): T | undefined => {
    try {
      return JSON.parse(localStorage.getItem(key)!)!.value;
    } catch {
      return fallback;
    }
  },

  store: <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify({ value }));
  }
}
