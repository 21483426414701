import React from "react";
import MediaInput from "./../../../components/form/MediaInput";
import { Media } from "sdk/dist/media_pb";

interface IProps {
  scheduleMedia: Media.AsObject;
}

const ScheduleBlock: React.FC<IProps> = ({ scheduleMedia }) => (
  <img
    src={scheduleMedia.downloadUrl}
    alt="timetable image"
    style={{ width: "inherit" }}
    className="h-auto w-auto m-auto"
  />
);

export default ScheduleBlock;
