import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import styled from "styled-components";
import { metadata, rpc } from "../../grpc";
import { AccLinkOnbRequest, OrgStripeAccRequest, StripeAccount } from "sdk/dist/stripe_pb";
import { toastStore } from "../../stores/toast-store";
import { Separator } from "../elements/AccordianElements";
import FormHeader, { HeaderSubtitle } from "../form/FormHeader";
import TextArea from "../form/TextArea";
import LoadingButton from "../LoadingButton";

interface Props {
  orgId: string;
}

@observer
export class Onboarding extends Component<Props> {
  @observable
  private account = new StripeAccount().toObject();

  @observable
  private enableButton = false;

  @observable
  private isLoading = false;

  @observable
  private onboardingUrl = "";

  async componentDidMount() {
    const { orgId } = this.props;
    try {
      const req = new OrgStripeAccRequest();
      req.setOrganisationId(orgId);
      const res = await rpc.stripe.getOrgStripeAccount(req, metadata());
      this.account = res.toObject();
    } catch (err) {
      toastStore.grpcError(err);
    }
    this.enableButton = true;
  }

  async connectAccount(orgId: string) {
    this.isLoading = true;
    try {
      const req = new AccLinkOnbRequest();
      req.setOrganisationId(orgId);
      req.setFailureUrl(
        `${window.location.protocol}//${window.location.hostname}:${window.location.port}/admin/orgs/${orgId}/edit?page=stripe`
      );
      req.setSuccessUrl(
        `${window.location.protocol}//${window.location.hostname}:${window.location.port}/admin/orgs/${orgId}/edit?page=stripe`
      );
      const res = await rpc.stripe.createAccLinkForOnboarding(req, metadata());
      this.onboardingUrl = res.toObject().onboardingUrl;
      window.open(this.onboardingUrl, "_self");
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  render() {
    return (
      <OnboardingContainer>
        <FormHeader title="Stripe Account" />
        <HeaderSubtitle>
          <div style={{ padding: "0 0 1rem 0" }}>
            A Stripe account is neccessary in order to recieve payments from clients, once you have
            registered in order to make changes to your account please{" "}
            <a target="_blank" href="https://dashboard.stripe.com/dashboard">
              click here
            </a>
          </div>
        </HeaderSubtitle>
        <Separator />
        {this.account.detailsSubmitted ? (
          <TextArea
            id="stripe-account-id"
            disabled={true}
            value={this.account.accId}
            style={{ maxHeight: "44px" }}
          />
        ) : (
          this.enableButton && (
            <LoadingButton
              style={{
                width: 150
              }}
              loading={this.isLoading}
              onClick={() => this.connectAccount(this.props.orgId)}
              variant="contained"
              color="secondary"
            >
              Connect
            </LoadingButton>
          )
        )}
      </OnboardingContainer>
    );
  }
}

const OnboardingContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 10%;
`;
