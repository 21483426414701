import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAsync } from "./../../hooks/useAsync";
import { sharedCountryService } from "./../../services/CountryService";

interface IProps {
  value?: string;
  defaultCountry?: string;
  name?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onPhoneChange(phoneNumber: string): any;
  onCountryChange(countryCode: string): any;
}

interface ICountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const MobileInput = (props: IProps) => {
  const countriesListFetcher = useAsync(
    async () => await sharedCountryService.getSupportedCountriesList(),
    []
  );

  const handleOnChange = (
    value: string,
    data: ICountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    var str = value.slice(data.dialCode.length);
    if (str[0] == "0") {
      str = str.slice(1);
    }
    var chunks = str.match(/.{1,3}/g);
    if (chunks) {
      var new_value = chunks!.join(" ");
    } else {
      new_value = "";
    }
    props.onCountryChange(data.countryCode.toUpperCase());
    props.onPhoneChange("+" + data.dialCode + " " + new_value);
  };

  return (
    <PhoneInput
      autoFormat={false}
      country={props.defaultCountry ? props.defaultCountry : "au"}
      countryCodeEditable={false}
      onlyCountries={
        countriesListFetcher.value
          ? countriesListFetcher.value.map((c) => c.alpha2Code.toLowerCase())
          : []
      }
      value={props.value}
      disabled={props.disabled}
      onChange={handleOnChange}
      inputStyle={{ width: "100%", height: "44px", lineHeight: "22px" }}
      inputProps={{
        name: name,
        id: props.id
      }}
    />
  );
};
export default MobileInput;
