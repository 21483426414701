import React from "react";
import styled from "@emotion/styled";
import Divider from "./../../../../components/Divider";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";
import {
  calculateSubtotal,
  calculateTotal,
  calculateTotalGST,
  calculateLyfeFee,
  calculateAmountRebate
} from "./../../../../util/bookings";
import { colors } from "./../../../../util/consts";
import { moneyToText, mul } from "./../../../../util/money";
import typography from "./../../../../util/typography";
import { LeftFooterText, LeftSection } from "../../SplitDialog";

interface Props {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
}

export class BreakdownFooter extends React.Component<Props> {
  render() {
    const { booking, client } = this.props;
    return (
      <>
        <LeftFooter>
          {booking.status != Booking.Status.BOOKING_CANCELLED ? (
            process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? (
              <LeftSection style={{ opacity: 0.7 }}>
                <LeftFooterText fontWeight={400}>Rebate (estimated)</LeftFooterText>
                <LeftFooterText fontWeight={400}>
                  -{moneyToText(mul(calculateAmountRebate(booking, client), -1, false), false)}
                </LeftFooterText>
              </LeftSection>
            ) : (
              ""
            )
          ) : (
            <LeftSection>
              <LeftFooterText fontWeight={600}>Cancellation Fee</LeftFooterText>
              <LeftFooterText fontWeight={700}>
                {moneyToText(calculateSubtotal(booking, client), false)}
              </LeftFooterText>
            </LeftSection>
          )}
          {parseFloat(booking.lyfeFee) > 0 && (
            <LeftSection>
              <LeftFooterText fontWeight={600}>
                Transaction Fee <LeftFooterSubText>({booking.lyfeFee}%)</LeftFooterSubText>
              </LeftFooterText>
              <LeftFooterText fontWeight={700}>
                {moneyToText(calculateLyfeFee(booking, client), false)}
              </LeftFooterText>
            </LeftSection>
          )}
        </LeftFooter>
        <Divider />
        <LeftFooter>
          {booking.status != Booking.Status.BOOKING_CANCELLED ? (
            <>
              <LeftSection>
                <LeftFooterText fontWeight={600}>Sub Total</LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateSubtotal(booking, client), false)}
                </LeftFooterText>
              </LeftSection>
              <LeftSection>
                <LeftFooterText fontWeight={600}>Sales Tax</LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateTotalGST(booking, client), false)}
                </LeftFooterText>
              </LeftSection>
              <LeftSection>
                <LeftFooterText fontWeight={600}>
                  Total <LeftFooterSubText>(to client)</LeftFooterSubText>
                </LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateTotal(booking, client), true)}
                </LeftFooterText>
              </LeftSection>
            </>
          ) : (
            <>
              <LeftSection>
                <LeftFooterText fontWeight={600}>Sub Total</LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateSubtotal(booking, client), false)}
                </LeftFooterText>
              </LeftSection>
              <LeftSection>
                <LeftFooterText fontWeight={600}>Sales tax</LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateTotalGST(booking, client), false)}
                </LeftFooterText>
              </LeftSection>
              <LeftSection>
                <LeftFooterText fontWeight={600}>
                  Total <LeftFooterSubText>(to client)</LeftFooterSubText>
                </LeftFooterText>
                <LeftFooterText fontWeight={700}>
                  {moneyToText(calculateTotal(booking, client), true)}
                </LeftFooterText>
              </LeftSection>
            </>
          )}
        </LeftFooter>
      </>
    );
  }
}

const LeftFooterSubText = styled.span`
  ${typography.overline};
  letter-spacing: 1.44px;
  font-weight: 400;
  color: ${colors.surfaceText.lowEmphasis};
`;

const LeftFooter = styled.div`
  flex: 0 0 auto;
  padding: 18px 30px;
`;
