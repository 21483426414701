import React from "react";

// Redux
import { useDispatch } from "react-redux";
import * as ReduxDialogs from "../../redux/features/dialogs";

// Components
import Dialog from "./Dialog";
import ButtonBase from "../ButtonBase";
import Section from "../form/Section";

// Utils
import styled from "@emotion/styled";
import { breakpoints } from "../../util/consts";
import { colors, hexToRgba } from "../../util/consts";
import typography from "../../util/typography";


export interface PayoutDialogProps {
  data: {
    stripePayoutID: string;
    destinationPaymentID: string;
    invoiceURL: string;
    stripeStatusMessage: string;
    cb?: () => void;
  },
  dialogId?: any;
}

export const PayoutDialog: React.FC<PayoutDialogProps> = (props) => {

  const dispatch = useDispatch();

  const closeDialog = () => {
    const { dialogId } = props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  }


  return (
    <Dialog
      dialogId={props.dialogId}
      overideCloseDialog={closeDialog}
      noClose
    >
      <Container>
        <Section>
          <p className="mt-5 mb-2 text-lg">Stripe Group Payout</p>
          <div className="flex text-xs">
            {props.data.stripePayoutID.length > 0 ?
              <>
                <p className="font-bold">{`id:`}</p>
              &nbsp;
              <p>{props.data.stripePayoutID}</p>
              </> :
              <p>{"No stripe payout created yet, this make take up to 5 business days"}</p>
            }
          </div>
          {props.data.stripePayoutID.length > 0 &&
            <div className="flex text-xs">
              <p className="font-bold">{`message:`}</p>
              &nbsp;
              <p>{props.data.stripeStatusMessage}</p>
            </div>
          }
          {props.data.destinationPaymentID.length > 0 &&
            <div className="flex text-xs">
              <p className="font-bold">{`paymentID:`}</p>
              &nbsp;
              <p>{props.data.destinationPaymentID}</p>
            </div>
          }
          <div className="flex text-xs">
            <p className="font-bold">{`invoice:`}</p>
            &nbsp;
            {props.data.invoiceURL.length === 0 ?
              <p>invoice has not been generated, try again later</p> :
              <a href={`${props.data.invoiceURL}`}
                className="text-xs" target="_blank" rel="noopener noreferrer" style={{ outline: "none" }}
              > download pdf
          </a>
            }
          </div>
          <div className="flex text-xs">
            <p className="font-bold">{`dashboard:`}</p>
            &nbsp;
            <a href={`https://dashboard.stripe.com/`}
              className="text-xs" target="_blank" rel="noopener noreferrer" style={{ outline: "none" }}
            > click here to go to your stripe console
            </a>
          </div>
        </Section>

        <ButtonContainer>
          <>
            <LargeBottomButton
              isPrimary={true}
              onClick={closeDialog}
            >
              <LargeButtonText>Ok</LargeButtonText>
            </LargeBottomButton>
          </>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
}

const FormSection = styled.div`
  ${typography.heading6};
  font-weight: 600;
  margin-top: 36px;
`;

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 74px;

  ${breakpoints["tablet-up"]} {
    padding: 42px 90px;
    padding-bottom: 82px;
    max-width: 700px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
`;

const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.secondary.main, 0.12)};
  }
`;