import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import { getResource } from "./../../../redux/features/resources/helpers";
// import apiService from "./../../../redux/services/api";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";
import MediaInput from "./../../../components/form/MediaInput";
import LoadingButton from "./../../../components/LoadingButton";

class LocCreateAccount_Market extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleSubmit,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Market to your clients"
          subtitle="Download free banner images to make your Lyfe profile stand out at Unsplash.com (https://unsplash.com/search/photos/health)"        
        />
        <SectionsContainer>
          <Section>
            <MediaInput
              type="banner"
              name="Banner"
              value={values.Banner}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
            />
            {!!errors.Banner && touched.Banner && <Error>{errors.Banner}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            {!dirty && !pageData.Banner ? "Skip" : "Continue"}
          </LoadingButton>
        </FooterContainer>
        {!dirty && !pageData.Banner && (
          <FooterContainer style={{ marginTop: 16 }}>
            <FooterText>Skip to use your organisations banner image</FooterText>
          </FooterContainer>
        )}
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    Banner: pageData.Banner || ""
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, props }) => {
    props.setPage("sched", {
      Banner: values.Banner
    });
    setSubmitting(false);
  },
  displayName: "LocCreateAccountMarket"
});

export default compose(
  connect((state) => ({
    org: getResource(state.resources, "Organisation", state.global.UserContext.OrgID, true)
  })),
  withToastManager,
  formikEnhancer
)(LocCreateAccount_Market);
