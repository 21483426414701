import styled from "@emotion/styled";
import React from "react";
import { useAsync } from "./../../hooks/useAsync";
import { sharedCountryService } from "./../../services/CountryService";
import { colors } from "./../../util/consts";

export const CountrySelect = React.forwardRef((props, ref) => {
  const countriesListFetcher = useAsync(
    async () => await sharedCountryService.getSupportedCountriesList(),
    []
  );

  return (
    <Select {...props} defaultValue="" ref={ref}>
      <option value="">Select ...</option>
      {countriesListFetcher.value &&
        countriesListFetcher.value.map((c) => {
          return (
            <option key={c.id} value={c.alpha2Code}>
              {c.name}
            </option>
          );
        })}
    </Select>
  );
});

const Select = styled.select<any>`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;

  &:disabled {
    background-color: white;
    border: none;
    padding: 0;
    height: 25px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0.6;
    font-size: 12.82px;
  }

  &:hover {
    background-color: white;
  }
  &:focus {
    border-color: ${colors.primary.main};
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;
