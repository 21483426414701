import React, { Component } from "react";
import moment from "moment";

import * as ReduxDialogs from "../../../../redux/features/dialogs";

// components
import { AccDataTable } from "./AccDataTable";
import { accTableStore } from "../../../../stores/acc_table-store";
import { AccountItem, RetryInvGenRequest } from "sdk/dist/account_items_pb";
import * as ReactCsv from "react-csv";
import { AccountRow } from "sdk/dist/accounts_table_pb";
import { toastStore } from "../../../../stores/toast-store";
import AdminSubHeader from "../../../elements/AdminSubHeader";
import { accToCsv, ExportData, AccTableType } from "../AccUtils";
import { UserUIType } from "sdk/dist/location_user_pb";
import { rpc, metadata } from "../../../../grpc";

interface Props {
  resource: any;
  match: any;
  dispatch: (redux: any) => void;
}

interface State {
  csvIsLoading: boolean;
  accountRows: ExportData[];
}

export class LocationAccounts extends Component<Props, State> {
  state: State = {
    csvIsLoading: false,
    accountRows: []
  };

  handleSubPage = (sp: string) => {
    switch (sp) {
      case "Charges":
        accTableStore.setActiveTab(6);
        accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT);
        break;
      case "Invoices":
        accTableStore.setActiveTab(7);
        accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_THIRD_PARTY);
        break;
      case "Claims":
        accTableStore.setActiveTab(8);
        accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_FUND);
        break;
      case "All":
        accTableStore.setActiveTab(9);
        accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_TYPE_UNSPECIFIED);
        break;
      case "Products":
        accTableStore.setActiveTab(10);
        accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_PRODUCT);
        break;
    }
  };

  onClick = async (accountRow: AccountRow.AsObject) => {
    switch (accountRow.accItemType) {
      case AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT:
      case AccountItem.Type.ACC_ITEM_FUND:
      case AccountItem.Type.ACC_ITEM_THIRD_PARTY:
        this.props.dispatch(
          ReduxDialogs.openAppointment(accountRow.id, accountRow.clientId, true, () => {})
        );
        break;
      default:
        if (accountRow.invoiceDownloadUrl === "") {
          this.props.dispatch(
            ReduxDialogs.openInfo(
              "Invoice has not been generated, try again later",
              undefined,
              () => {}
            )
          );
          try {
            const req = new RetryInvGenRequest();
            req.setAccountItemId(accountRow.id);
            await rpc.accountItems.retryInvGeneration(req, metadata());
          } catch (err) {
            toastStore.grpcError(err);
          }
        } else {
          window.open(accountRow.invoiceDownloadUrl, "_blank");
        }
    }
  };

  exportCSV = async () => {
    try {
      this.setState({ csvIsLoading: true });
      const csvRows = await accToCsv(AccTableType.LocAccounts);
      this.setState({ accountRows: csvRows });

      // Click CSVLink now that we have already received the data
      if (this.state.accountRows.length > 0) {
        let btn: any = this.refs.csv;
        btn.link.click();
      } else {
        toastStore.error("Couldn't download csv, no data found");
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
    this.setState({ csvIsLoading: false });
  };

  render() {
    const locId = this.props.match.params.lid;

    return (
      <React.Fragment>
        <AdminSubHeader
          type="table"
          subHeaders={
            localStorage.getItem("UserUIType") === String(UserUIType.FITNESS) ||
            process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
              ? ["All", "Charges", "Products"]
              : ["All", "Charges", "Claims", "Invoices", "Products"]
          }
          onSubPage={this.handleSubPage}
          clickExport={this.exportCSV.bind(this)}
          exportIsLoading={this.state.csvIsLoading}
        />
        {this.state.accountRows.length > 0 && (
          <ReactCsv.CSVLink
            data={this.state.accountRows}
            filename={`${this.state.accountRows[0].Location}-on-${moment().format(
              "YYYY-MM-DD_HH-MM-SS"
            )}.csv`}
            target="_blank"
            style={{ display: "none" }}
            ref="csv"
          >
            Export table
          </ReactCsv.CSVLink>
        )}
        <AccDataTable locationId={locId} onClick={this.onClick} />
      </React.Fragment>
    );
  }
}
