import React, { useEffect } from "react";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Paper } from "../../ui-kit/Paper";
import { Typography } from "../../ui-kit/Typography";
import { tracking } from "../../util/tracking";

export const ProviderAccountUpgradeSuccessMessage = () => {
  useEffect(() => {
    tracking.iframeOrganizationRegistrationCompleted();
  }, []);

  return (
    <div>
      <Paper color="neutral" rounded="md" shadow>
        <Container>
          <FlexBox direction="col" alignItems="center" spacing={[0, 2]}>
            <FlexItem>
              <svg
                className="mb-4 h-20 w-20 text-green-500 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </FlexItem>
            <FlexItem>
              <Typography.H2 weight="bold" alignment="center">
                Account Upgrade Success
              </Typography.H2>
            </FlexItem>
            <FlexItem>
              <Typography.H4 weight="light" alignment="center">
                Thank you. We have sent you a welcome email to your email address.
              </Typography.H4>
            </FlexItem>
          </FlexBox>
        </Container>
      </Paper>
    </div>
  );
};
