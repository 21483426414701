import styled from "@emotion/styled";
import { Tooltip as HoverToolTip } from "@material-ui/core";
import React from "react";
import { Typography } from "./Typography";

interface IProps {
  children: React.ReactElement;
  title: string;
}

export const Tooltip = (props: IProps) => {
  return (
    <StyledToolTip
      placement="top"
      title={
        <React.Fragment>
          <Typography.Label color="neutral">{props.title}</Typography.Label>
        </React.Fragment>
      }
    >
      <div>{props.children}</div>
    </StyledToolTip>
  );
};

const StyledToolTip = styled(HoverToolTip)`
  background-color: "white";
  color: "rgba(0, 0, 0, 0.87)";
  border: "1px solid #dadde9";
  font-size: 20;
`;
