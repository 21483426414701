import React, { Component } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { ResourceTypes } from "../../redux/features/resources/definitions";
import * as ResourceActions from "../../redux/features/resources/thunkactions";
import * as ReduxDialogs from "../../redux/features/dialogs";

// mobx
import { rebookDataStore } from "../../mobx/stores/RebookDataStore";

// components
import Dropdown from "./common/Dropdown";
import IconButton from "../IconButton";

// utils
import typography from "../../util/typography";
import { colors, hexToRgba } from "../../util/consts";

// stylings
import { observer } from "mobx-react";
import { observable } from "mobx";

const weekOptions = [
  { value: 7, label: "1 week from now" },
  { value: 14, label: "2 weeks from now" },
  { value: 21, label: "3 weeks from now" },
  { value: 28, label: "1 month from now" },
  { value: 56, label: "2 months from now" },
  { value: 84, label: "3 months from now" }
];

interface Props {
  offeringId: string;
  dispatch: any;
  providerId: string;
  duration: any;
  startTime: any;
  locationId: string;
  clientId: string;
  bookingId: string;
}

interface Option {
  value: number;
  label: string;
}

@observer
export class RebookModal extends Component<Props> {
  @observable
  private selectedWeekOption: Option = { value: 0, label: "" };

  handleWeekOptionChange = (selectedWeekOption: Option) => {
    this.selectedWeekOption = selectedWeekOption;
  };

  onClose = () => {
    rebookDataStore.isOpen = false;
  };

  directToWeekView = () => {
    rebookDataStore.isOpen = false;
    rebookDataStore.isRebooking = true;
    rebookDataStore.daysAfter = this.selectedWeekOption.value;
    rebookDataStore.bookingID = this.props.bookingId;
    rebookDataStore.clientId = this.props.clientId;
    rebookDataStore.offering = this.props.offeringId;
    rebookDataStore.provider = this.props.providerId;
    rebookDataStore.locationId = this.props.locationId;
    rebookDataStore.duration = this.props.duration;
    rebookDataStore.startTime = this.props.startTime;

    this.props.dispatch(ReduxDialogs.actions.close(rebookDataStore.dialogID));
  };

  render() {
    const { locationId, providerId } = this.props;
    const isOptionSelected = this.selectedWeekOption.value !== 0;
    const weekViewLink = `/admin/locs/${locationId}/providers/${providerId}?view=calendar`;

    return (
      <Modal>
        <CloseButton onClick={this.onClose}>&times;</CloseButton>
        <Header> Book Again? </Header>

        <StyledDropdown
          placeholder={"Choose When..."}
          selectedOption={this.selectedWeekOption.value === 0 ? null : this.selectedWeekOption}
          handleChange={this.handleWeekOptionChange}
          options={weekOptions}
        />

        <BottomRow>
          <NoButton onClick={this.onClose}>No Thanks</NoButton>

          {isOptionSelected ? (
            <StyledLink to={weekViewLink}>
              <StyledIconButton
                disabled={!isOptionSelected}
                onClick={this.directToWeekView}
                isOptionSelected={isOptionSelected}
                mini
              >
                <StyledButtonText>CHECK</StyledButtonText>
              </StyledIconButton>
            </StyledLink>
          ) : (
            <StyledIconButton
              disabled={!isOptionSelected}
              onClick={this.directToWeekView}
              isOptionSelected={isOptionSelected}
              mini
            >
              <StyledButtonText>CHECK</StyledButtonText>
            </StyledIconButton>
          )}
        </BottomRow>
      </Modal>
    );
  }
}

const Header = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledButtonText = styled.div`
  color: white;
  font-size: 11px;
  letter-spacing: 0px;
`;

const CloseButton = styled.a`
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 0px;
  top: 0px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  margin-top: 10px;
`;

const BottomRow = styled.div`
  width: 100%;
  margin-top: 15px;
  display: inline-block;
`;

const NoButton = styled.button`
  border: none;
  color: #0066ff;
  text-decoration: underline;
`;

const StyledIconButton = styled(IconButton)<{ isOptionSelected: boolean }>`
  background-color: ${({ isOptionSelected }) =>
    isOptionSelected ? colors.secondary.main : "#a6a6a6"} !important;
  border-radius: 9px !important;
  width: 70px !important;
  height: 25px !important;
  &:hover {
    background-color: ${({ isOptionSelected }) =>
      isOptionSelected && hexToRgba(colors.secondary.main, 0.6)} !important;
  }
  margin: 0;
  margin-left: 150px;
`;

const Modal = styled.div`
  width: 80%;
  font-size: 12px;
  margin-left: 10%;
  margin-top: 10%;
`;
