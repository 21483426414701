import { css, Global } from "@emotion/core";
import * as Sentry from '@sentry/react';
import React, { Children, Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { TransitionGroup } from "react-transition-group";
import { PersistGate } from "redux-persist/integration/react";
import { AppConfig } from "./AppConfig";
import { CookieBanner } from "./components/cookie-banner/CookieBanner";
import Base from "./components/routes/Base";
import { ToastConnector } from "./toast-connector";
import { Paper } from "./ui-kit/Paper";
import { colors } from "./util/consts";
import ScrollToTop from "./util/scrollToTop";


const placements = {
  "top-left": { top: 0, left: 0 },
  "top-center": { top: 0, left: "50%", transform: "translateX(-50%)" },
  "top-right": { top: 0, right: 0 },
  "bottom-left": { bottom: 0, left: 0 },
  "bottom-center": { bottom: 0, left: "50%", transform: "translateX(-50%)" },
  "bottom-right": { bottom: 0, right: 0 }
};

export const ToastContainer = ({ children, placement }) => (
<div
    style={{
      boxSizing: "border-box",
      maxHeight: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      padding: 8,
      pointerEvents: Children.count(children) ? "auto" : "none",
      position: "fixed",
      zIndex: 50,
      ...placements[placement]
    }}
  >
    <TransitionGroup component={null}>{children}</TransitionGroup>
  </div>
  );

class App extends Component {

  render() {
    const { store, persistor } = this.props;
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <>
            {AppConfig.BOOKLYFE_VERSION !== "development" ? (
              <div>An error has occurred. Please refresh application and try again.</div>
            ) : (
              <>
                <div>You have encountered an error</div>
                <div>{error.toString()}</div>
                <div>{componentStack}</div>
              </>
            )}
          </>
        )}
      >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastProvider
            ref={this.onToastProviderRef}
            autoDismiss={true}
            autoDismissTimeout={10000}
            components={{ ToastContainer }}
          >
            <ToastConnector />
            <Global
              styles={css`
                html {
                  overflow: hidden;
                }

                *,
                *:before,
                *:after {
                  box-sizing: border-box;

                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  text-rendering: optimizeLegibility;

                  -webkit-tap-highlight-color: transparent;

                  -webkit-overflow-scrolling: touch;
                  font-family: "Open Sans", sans-serif;
                }

                body {
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  font-family: sans-serif;
                  display: inline-block;
                  min-width: 100%;
                }

                html,
                body {
                  padding: 0;
                  margin: 0;
                }

                div#root {
                  width: 100%;
                }

                .react-datepicker-wrapper {
                  display: block;
                  width: 100%;
                }

                .react-datepicker__input-container {
                  display: block;
                  width: 100%;
                }

                .react-datepicker__header--time {
                  min-height: 58.3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .react-datepicker__current-month {
                  font-weight: 600;
                }

                .react-datepicker-time__header {
                  font-weight: 600;
                }

                .react-datepicker__time-list-item--selected {
                  background-color: ${colors.primary.main} !important;
                }

                .react-datepicker__header {
                  background-color: unset;
                }

                .react-datepicker__day {
                  color: ${colors.primary.main};
                }

                .react-datepicker__day:hover {
                  border-radius: 50%;
                }

                .react-datepicker__day--selected,
                .react-datepicker__day--keyboard-selected {
                  border-radius: 50%;
                  background-color: ${colors.primary.main};
                  color: ${colors.whiteText.highEmphasis} !important;
                }

                .react-datepicker__day--selected:hover,
                .react-datepicker__day--keyboard-selected:hover {
                  background-color: ${colors.primary[800]};
                }

                .react-datepicker__month-select,
                .react-datepicker__year-select {
                  background: transparent;
                  border: none;
                }

                .react-datepicker__day--today {
                  color: ${colors.secondary.main};
                }

                .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
                  display: none;
                }
              `}
            />
            <Router>
              <ScrollToTop>
              <Paper width="screen" height="screen" color="secondary" overflow={{x: 'hidden'}}>
              <Route component={Base} />
              </Paper>
              </ScrollToTop>
              <CookieBanner />
            </Router>
          </ToastProvider>
        </PersistGate>
      </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
