import { css } from "@emotion/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import * as H from "history";
import { observer } from "mobx-react";
import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, NavLink, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Logo from "../../../components/icons/Logo";
import { HamburgerNavIcon } from "../../../static";
import "../../../styles/menu.scss";
import { tracking } from "../../../util/tracking";
// Components
import Button from "./../../../components/Button";
import Dropdown from "./../../../components/Dropdown";
import IconButton from "./../../../components/IconButton";
import ClearIcon from "./../../../components/icons/Clear";
import LogoIcon from "./../../../components/icons/Logo";
import MenuIcon from "./../../../components/icons/Menu";
import OrgAvatar from "./../../../components/OrgAvatar";
import UserAvatar from "./../../../components/UserAvatar";
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import { colors } from "./../../../util/consts";
import Breadcrumbs from "./Breadcrumbs";
// Testing
import { OrgMenu, UserMenu } from "./Menus";
import { DefaultSearchTag } from "./Search";

const Menubar: React.FC<any> = observer((props) => {
  const { userContext, auth } = props;
  const location = useLocation();
  const menuBarRef = useRef<HTMLDivElement>(null);

  const [isLoggedIn, toggleLoggedInState] = useState(false);
  const [isUserMenuOpen, toggleUserMenuState] = useState(false);
  const [isOrgMenuOpen, toggleOrgMenuState] = useState(false);

  useEffect(() => {
    toggleLoggedInState(Boolean(auth && auth.Type === "user" && auth.UserID));
  }, [auth]);

  const openLoginModal = () => {
    props.dispatch(ReduxDialogs.openAccount(menuBarRef.current, "login", {}, () => {}));
  };

  const openSignUpModal = () => {
    tracking.clientInterestedInRegistration();
    props.dispatch(ReduxDialogs.openAccount(menuBarRef.current, "signup", {}, () => {}));
  };

  const isMobile = window.innerWidth < 744;

  const toggleUserMenu = () => {
    toggleUserMenuState(!isUserMenuOpen);
  };

  const closeUserMenu = () => {
    toggleUserMenuState(false);
  };

  const toggleOrgMenu = () => {
    toggleOrgMenuState(!isOrgMenuOpen);
  };

  const closeOrgMenu = () => {
    toggleOrgMenuState(false);
  };

  return (
    <div id="menubar" ref={menuBarRef}>
      <div className="relative px-8 sm:px-6 lg:px-8 md:hidden py-6 md:py-0   md:px-12">
        <nav className="relative flex items-center sm:h-10 lg:justify-start">
          <div className="inline-flex lg:flex-grow-0 w-full">
            <div
              className={`flex items-center w-full ${
                location.pathname === "/" ? "row" : ""
              } space-x-4`}
            >
              <div className={` w-2/12 contents`}>
                {isMobile ? (
                  <Dropdown
                    isOpen={isUserMenuOpen}
                    onClickOutside={closeUserMenu}
                    render={({ ref }: any) => (
                      <UserMenu
                        containerStyles={{
                          position: "fixed",
                          top: 69,
                          left: 0,
                          zIndex: 99
                        }}
                        innerRef={ref}
                        closeMenu={closeUserMenu}
                      />
                    )}
                  >
                    <TransparentButton onClick={toggleUserMenu}>
                      <img
                        src={HamburgerNavIcon}
                        alt="hamburger icon"
                        className="h-8 flex-1"
                        id="search-svg"
                      />
                    </TransparentButton>
                  </Dropdown>
                ) : (
                  <Link
                    to={
                      userContext.OrgID === "" ? "/" : `/admin/orgs/${userContext.OrgID}/locations`
                    }
                  >
                    <LogoIcon
                      css={css`
                        flex: 0 0 auto;
                      `}
                    />
                  </Link>
                )}
              </div>
              <div
                className={`${location.pathname === "/" ? "hidden" : ""} ${
                  isLoggedIn ? "w-10/12" : "w-9/12"
                }`}
              >
                {isLoggedIn && userContext.OrgID ? (
                  <Route component={Breadcrumbs} />
                ) : (
                  <DefaultSearchTag
                    history={props.history}
                    tagId="mobile-search-box"
                    isHomepageSearchTag={false}
                  />
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        className={classNames(
          "hidden md:flex justify-end items-center md:px-10",
          location.pathname !== "/" ? " md:py-6" : " md:pt-6"
        )}
      >
        <div
          className={`${
            isLoggedIn && userContext.OrgID ? "md:w-9/12" : "w-1/2"
          }  flex md:space-x-5 lg:space-x-10 align-middle ${
            location.pathname === "/" ? "hidden" : ""
          }`}
        >
          <div className="my-auto">
            <Link to="/" aria-label="booklyfe-logo">
              <Logo />
            </Link>
          </div>
          <div className="lg:w-full mx-auto">
            {isLoggedIn && userContext.OrgID ? (
              <Route component={Breadcrumbs} />
            ) : (
              <Switch>
                <Route exact path="/createorg" component={Breadcrumbs} />
                <Route path="/bookings/locs" />
                <DefaultSearchTag
                  history={props.history}
                  tagId="desktop-search-box"
                  isHomepageSearchTag={false}
                />
              </Switch>
            )}
          </div>
        </div>
        <div
          className={`${
            isLoggedIn && userContext.OrgID ? "md:w-3/12" : "w-1/2"
          } text-right space-x-4 `}
        >
          {!isLoggedIn ? (
            <>
              <Link to="/listyourbusiness" className="uppercase text-white">
                List your business
              </Link>
              <button
                className="btn btn-pill w-32 m-2"
                onClick={openSignUpModal}
                id="signup-button"
                aria-label="Sign up"
              >
                Sign Up
              </button>
              <button className="btn btn-pill w-32 m-2" onClick={openLoginModal} aria-label="Login">
                Login
              </button>
            </>
          ) : (
            <>
              <Route exact path="/createorg" render={(p) => <RightFullPageForm {...p} />} />
              <Route
                exact
                path="/admin/orgs/:oid/createloc"
                render={(p) => <RightFullPageForm {...p} />}
              />
              {userContext.OrgID === "" ? (
                <Fragment>
                  <FadingLink to="/listyourbusiness" exact className="text-white">
                    List your Business
                  </FadingLink>
                  {isLoggedIn && (
                    <FadingLink to={`/users/appointments`} exact className="text-white">
                      My Appointments
                    </FadingLink>
                  )}
                </Fragment>
              ) : (
                <Switch>
                  <Route path="/admin/orgs/:oid/locations" render={() => null} />
                  <Route
                    render={() => (
                      <Dropdown
                        isOpen={isOrgMenuOpen}
                        onClickOutside={closeOrgMenu}
                        render={({ styles, ref }: any) => (
                          <OrgMenu
                            containerStyles={styles}
                            innerRef={ref}
                            closeMenu={closeOrgMenu}
                          />
                        )}
                      >
                        <IconButton onClick={toggleOrgMenu}>
                          <MenuIcon size="small" />
                        </IconButton>
                      </Dropdown>
                    )}
                  />
                </Switch>
              )}
              {isLoggedIn ? (
                <Dropdown
                  isOpen={isUserMenuOpen}
                  onClickOutside={isMobile ? () => {} : closeUserMenu}
                  render={({ styles, ref }: any) => (
                    <UserMenu containerStyles={styles} innerRef={ref} closeMenu={closeUserMenu} />
                  )}
                >
                  <IconButton mini onClick={toggleUserMenu}>
                    {userContext.OrgID === "" && auth ? (
                      <UserAvatar userID={auth.UserID} size={32} />
                    ) : (
                      <OrgAvatar orgID={userContext.OrgID} size={32} />
                    )}
                  </IconButton>
                </Dropdown>
              ) : (
                <Fragment>
                  <SignUpButtom
                    variant="contained"
                    color="primary"
                    onClick={openSignUpModal}
                    id="signup-button"
                  >
                    <ButtonText>Sign Up</ButtonText>
                  </SignUpButtom>
                  {/* </FadingLink> */}
                  <Button variant="contained" color="secondary" onClick={openLoginModal}>
                    <ButtonText>Login</ButtonText>
                  </Button>
                </Fragment>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});

function mapStateToProps(state: any) {
  const { auth, global } = state;
  return {
    auth,
    userContext: global.UserContext
  };
}

export default compose(withRouter, connect(mapStateToProps))(Menubar);

const TransparentButton = styled.button`
  outline: none;
  margin: 0;
  padding: 0;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const SignUpButtom = styled(Button)`
  background-color: ${colors.primary[500]};
  margin-right: 18px;
`;

interface Props {
  history: H.History;
}
class RightFullPageForm extends Component<Props> {
  render() {
    const { history } = this.props;
    return (
      <IconButton onClick={() => history.length > 2 && history.goBack()}>
        <ClearIcon />
      </IconButton>
    );
  }
}

const ButtonText = styled.span`
  margin: 0 12px;
`;

const FadingLink = styled(NavLink)`
  text-decoration: none;
  margin-right: 18px;
  margin-left: 18px;
  opacity: 0.38;
  transition: opacity 200ms ease-in;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
  }
`;
