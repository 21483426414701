import PhysitrackIcon from "../../../src/pages/list-your-business/Physitrack_logo.svg";

interface ISubTier {
  free: IAvailability;
  basic: IAvailability;
  pro: IAvailability;
}

interface IAvailability {
  cost: number;
  availableFeatures: IFeature[];
  notAvailableFeatures: IFeature[];
}

interface IFeature {
  label: string;
  image?: { url: string; altName: string; title: string };
}

export const SubTiers: ISubTier = {
  free: {
    cost: 0,
    availableFeatures: [
      { label: "Web directory listing" },
      { label: "Mobile app profile" },
      { label: "Access to potential new clients" },
      { label: "New leads tracker" },
      { label: "Access to Lyfe community social feed" },
      { label: "Promote your brand and services" }
    ],
    notAvailableFeatures: [
      { label: "Custom calendar with option to embed into your website" },
      { label: "Appointment bookings & reminders" },
      { label: "Single or group bookings" },
      { label: "Sell products" },
      { label: "In-app payments" },
      { label: "Financial reporting" },
      { label: "Business reporting & analytics" },
      {
        label: "Prescribe engaging exercises in seconds",
        image: {
          url: PhysitrackIcon,
          altName: "Physitrack logo",
          title: "Certified Lyfe partner"
        }
      },
      {
        label:
          "Choose from over 15,000 narrated exercise videos or create and share your own videos"
      },
      { label: "Track client progress in real-time" },
      { label: "Reach clients everywhere with secure video appointments" }
    ]
  },
  basic: {
    cost: 9,
    availableFeatures: [
      { label: "Web directory listing" },
      { label: "Mobile app profile" },
      { label: "Access to potential new clients" },
      { label: "New leads tracker" },
      { label: "Access to Lyfe community social feed" },
      { label: "Promote your brand and services" },
      { label: "Custom calendar with option to embed into your website" },
      { label: "Appointment bookings & reminders" },
      { label: "Single or group bookings" },
      { label: "Sell products" },
      { label: "In-app payments" },
      { label: "Financial reporting" },
      { label: "Business reporting & analytics" }
    ],
    notAvailableFeatures: [
      {
        label: "Prescribe engaging exercises in seconds",
        image: {
          url: PhysitrackIcon,
          altName: "Physitrack logo",
          title: "Certified Lyfe partner"
        }
      },
      {
        label:
          "Choose from over 15,000 narrated exercise videos or create and share your own videos"
      },
      { label: "Track client progress in real-time" },
      { label: "Reach clients everywhere with secure video appointments" }
    ]
  },
  pro: {
    cost: 19,
    availableFeatures: [
      { label: "Web directory listing" },
      { label: "Mobile app profile" },
      { label: "Access to potential new clients" },
      { label: "New leads tracker" },
      { label: "Access to Lyfe community social feed" },
      { label: "Promote your brand and services" },
      { label: "Custom calendar with option to embed into your website" },
      { label: "Appointment bookings & reminders" },
      { label: "Single or group bookings" },
      { label: "Sell products" },
      { label: "In-app payments" },
      { label: "Financial reporting" },
      { label: "Business reporting & analytics" },
      {
        label: "Prescribe engaging exercises in seconds",
        image: {
          url: PhysitrackIcon,
          altName: "Physitrack logo",
          title: "Certified Lyfe partner"
        }
      },
      {
        label:
          "Choose from over 15,000 narrated exercise videos or create and share your own videos"
      },
      { label: "Track client progress in real-time" },
      { label: "Reach clients everywhere with secure video appointments" }
    ],
    notAvailableFeatures: []
  }
};
