import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import { colors } from "../../util/consts";
import typography from "../../util/typography";
import Tooltip from "../elements/Tooltip";

interface Props {
  title: string;
  subtitle?: string;
  popover?: string;
}
export default class FormHeader extends PureComponent<Props> {
  render() {
    const { title, subtitle, popover } = this.props;
    return (
      <HeaderContainer>
        <HeaderTitle>{title}</HeaderTitle>
        {Boolean(subtitle) && (
          <HeaderSubtitle>
            {subtitle} {Boolean(popover) && <Tooltip>{popover}</Tooltip>}
          </HeaderSubtitle>
        )}
      </HeaderContainer>
    );
  }
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.span`
  ${typography.heading5};
  font-weight: 600;
`;

export const HeaderSubtitle = styled.span`
  ${typography.body2};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  margin-top: 8px;
`;
