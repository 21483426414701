import styled from "@emotion/styled";
import * as fns from "date-fns";
import { withFormik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import { parseFormError } from "./../../util/form";

// Utils
import { breakpoints, mapWeekDaysToDTO, weekdays } from "./../../util/consts";
import typography from "./../../util/typography";

// Custom components
import Button from "./../../components/Button";
import LoadingButton from "./../../components/LoadingButton";
import Dialog from "./Dialog";

import { rebookDataStore } from '../../mobx/stores/RebookDataStore';
import apiService from '../../redux/services/api';
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import DayTimeRangePickerInput from "./../../components/form/DayTimeRangePickerInput";
import Error from "./../../components/form/Error";
import FooterContainer from "./../../components/form/FooterContainer";
import Form from "./../../components/form/Form";
import Input from "./../../components/form/Input";
import Label from "./../../components/form/Label";
import {
  LocationOfferingSelector,
  LocationProviderSelector
} from "./../../components/form/ResourceSelector";
import Section from "./../../components/form/Section";
import SectionsContainer from "./../../components/form/SectionsContainer";

import moment from 'moment';
import { FrequencySelect } from "../../components/form/BaseSelect";
import { Booking } from 'sdk/dist/bookings_pb';
import { FormSplitContainer, FormSplitItem } from "../dialogs/OfferingDialog";
import DayPickerInput from "./../../components/form/DayPickerInput";
import { bookingStore } from "./../../stores/booking-store";


const addTime = (time, duration) => {
  let times = time.split(":");
  const endTimeInMinutes = times[0] * 60 + parseInt(times[1]) + parseInt(duration)
  times[0] = Math.floor(endTimeInMinutes / 60)
  times[1] = endTimeInMinutes % 60
  if (times[1].toString().length == 1) {
    times[1] = "0" + times[1]
  }
  if (times[0].toString().length == 1) {
    times[0] = "0" + times[0]
  }
  return times.join(":");
}

class NewBookingDialog extends Component {

  state = {
    loading: true
  };

  async componentWillMount() {
    this.props.setStatus({ Type: "Appointment" });
    if (rebookDataStore.isRebooking) {
      const resClients = await apiService.performRequest({
        method: "get",
        path: `/api/v1/locations/` + rebookDataStore.locationId + `/clients`,
      });

      const rebookedClient = resClients.Payload.find(client => client.ID === rebookDataStore.clientId);

      let endTime = fns.parse(this.props.values.StartTime, "HH:mm:ss", new Date());
      endTime = fns.addSeconds(endTime, rebookDataStore.duration.seconds);

      const service = await apiService.performRequest({
        method: "get",
        path: `/api/v1/offerings/${rebookDataStore.offering}`
      });
      this.props.handleChange({
        target: { name: "Service", value: service.Payload }
      });
      this.props.handleChange({
        target: { name: "Client", value: rebookedClient },
      })
      this.props.handleChange({
        target: { name: "EndTime", value: fns.format(endTime, "HH:mm:00") }
      });
    }
  }

  handleServiceSelect = (offering) => {
    const { values, setFieldValue } = this.props;
    if (values.StartTime) {
      const bd = new Date();
      const stime = fns.parse(values.StartTime, "HH:mm:00", bd);
      if (fns.isDate(stime)) {
        let etime = addTime(values.StartTime, offering.ServiceDuration)
        setFieldValue("EndTime", etime);
      }
    }
  };

  handleWeekChange = (value) => {
    this.setState({ loading: true })
    if (this.props.values.Week.includes(value)) {
      this.props.values.Week = this.props.values.Week.filter(item => item !== value)
    } else {
      this.props.values.Week.push(value)
    }
    this.setState({ loading: false })
  }

  handleRepeatTypeChange = (repeatTypeNum) => {
    this.setState({ loading: true })
    this.props.values.RepeatType = repeatTypeNum
    if (repeatTypeNum == "1") {
      this.props.values.Week = weekdays.slice(1, 6)
    } else {
      let day = new Date().getDay()
      let selectedDay = weekdays[(day)]
      this.props.values.Week = [selectedDay.toUpperCase()]
    }
    this.props.handleBlur()
    this.setState({ loading: false })
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      // setFieldValue,
      isSubmitting,
      data
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId} trigger={data.trigger}>
        <ScrollContainer>
          <Container>
            <Form
              onSubmit={
                isSubmitting
                  ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                  : handleSubmit
              }
            >
              <HeaderTitle>New Booking</HeaderTitle>
              <SectionsContainer>
                <Section style={{ display: "flex" }}>
                  <Button
                    color="primary"
                    variant={values.Type === "Appointment" ? "contained" : "outlined"}
                    onClick={() =>
                      handleChange({
                        target: { name: "Type", value: "Appointment" }
                      })
                    }
                    style={{ marginRight: 12, flex: "1 1 50px" }}
                  >
                    Appointment
                  </Button>
                </Section>
              </SectionsContainer>
              {values.Type === "Appointment" && (
                <SectionsContainer>
                  <Section>
                    <Label htmlFor="newbook-service">Service</Label>
                    <LocationOfferingSelector
                      id="newbook-service"
                      name="Service"
                      placeholder="Select a provided service"
                      location={data.location}
                      onlyServices
                      value={values.Service}
                      onChange={handleChange}
                      onSelect={this.handleServiceSelect}
                      onBlur={handleBlur}
                      error={touched.Service && errors.Service}
                    />
                    {!!errors.Service && touched.Service && <Error>{errors.Service}</Error>}
                  </Section>
                  <Section>
                    <Label htmlFor="newbook-datetime">Date &amp; Time</Label>
                    <DayTimeRangePickerInput
                      id="newbook-datetime"
                      interval={5}
                      placeholder="Select the time"
                      dayName="Date"
                      dayValue={values.Date}
                      startName="StartTime"
                      startValue={values.StartTime}
                      endName="EndTime"
                      endValue={values.EndTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        (touched.Date && errors.Date) ||
                        (touched.StartTime && errors.StartTime) ||
                        (touched.EndTime && errors.EndTime)
                      }
                    />
                    {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
                  </Section>
                  <Section>
                    <Label htmlFor="newbook-provider">Provider</Label>
                    <LocationProviderSelector
                      id="newbook-provider"
                      name="Provider"
                      placeholder="Select the provider"
                      location={data.location}
                      value={values.Provider}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Provider && errors.Provider}
                    />
                    {!!errors.Provider && touched.Provider && <Error>{errors.Provider}</Error>}
                  </Section>
                  {values.ServiceType === "Group" && (
                    <React.Fragment>
                      <Section>
                        <Label htmlFor="newbook-maxgroupsize">Maximum Group Size</Label>
                        <Input
                          id="newbook-maxgroupsize"
                          name="MaxGroupSize"
                          type="number"
                          min={1}
                          max={100}
                          placeholder="Item code"
                          value={values.MaxGroupSize}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.MaxGroupSize && errors.MaxGroupSize}
                        />
                        {!!errors.MaxGroupSize && touched.MaxGroupSize && (
                          <Error>{errors.MaxGroupSize}</Error>
                        )}
                      </Section>
                      <Section>
                        <Label htmlFor="quick-end">Repeating Frequency
                        </Label>
                        <FrequencySelect
                          id="quick-repeat"
                          name="Repeat"
                          value={values.RepeatType}
                          onChange={(e) => this.handleRepeatTypeChange(String(e.target.value))}
                          onBlur={handleBlur}
                          error={touched.RepeatType && errors.RepeatType}
                        />
                        {!!errors.RepeatType && touched.RepeatType && <Error>{errors.RepeatType}</Error>}
                      </Section>
                      {values.RepeatType !== "0" && (
                        <Fragment>
                          <Label htmlFor="quick-end">Repeat On</Label>
                          <Section>
                            <Section style={{ display: "flex" }}>
                              {weekdays.map((day, index) => {
                                return (<Button
                                  key={index}
                                  variant={values.Week.includes(day) ? "contained" : "outlined"}
                                  onClick={() => this.handleWeekChange(day)}
                                  style={{ marginRight: 12, flex: "1 1 50px" }}
                                  type="button"
                                >
                                  {day.slice(0, 1)}
                                </Button>)
                              })}
                            </Section>
                            {!!errors.Level && touched.Level && <Error>{errors.Level}</Error>}
                          </Section>
                          <Label htmlFor="quick-end">Ends</Label>
                          <FormSplitContainer>
                            <FormSplitItem>
                              <CheckRadioBox
                                id="set-end-date-yes"
                                name="UseEndDate"
                                type="radio"
                                value={"true"}
                                checked={values.UseEndDate === "true"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.UseEndDate && errors.UseEndDate}
                              >
                                On
                  </CheckRadioBox>
                              <DayPickerInput
                                id="quick-end-date"
                                name="EndDate"
                                // step={900}
                                disabled={values.UseEndDate !== "true"}
                                value={values.EndDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.EndDate && errors.EndDate}
                              />
                              {!!errors.EndDate && touched.EndDate && <Error>{errors.EndDate}</Error>}
                            </FormSplitItem>
                            <FormSplitItem>
                              <CheckRadioBox
                                id="set-end-date-no"
                                name="UseEndDate"
                                type="radio"
                                value={"false"}
                                checked={values.UseEndDate === "false"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.UseEndDate && errors.UseEndDate}
                              >
                                After
                  </CheckRadioBox>
                              <Input
                                id="newbook-Repetitions"
                                name="Repetitions"
                                type="number"
                                disabled={values.UseEndDate !== "false"}
                                min={1}
                                max={100}
                                placeholder="Occurences"
                                value={values.Repetitions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.Repetitions && errors.Repetitions}
                              />
                              {!!errors.Repetitions && touched.Repetitions && (
                                <Error>{errors.Repetitions}</Error>
                              )}
                            </FormSplitItem>
                          </FormSplitContainer>
                        </Fragment>)}
                    </React.Fragment>
                  )}
                </SectionsContainer>
              )}
              <FooterContainer>
                <LoadingButton
                  style={{
                    minWidth: 150,
                    marginTop: 10
                  }}
                  loading={isSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </FooterContainer>
            </Form>
          </Container>
        </ScrollContainer>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Type: "Appointment",
    Service: null,
    Provider: null,
    Client: null,
    Date: new Date(),
    StartTime: fns.format(
      fns.roundToNearestMinutes(fns.addMinutes(new Date(), 2.5), { nearestTo: 5 }),
      "HH:mm:00"
    ),
    EndTime: "",
    ServiceType: "Group",
    FromDate: fns.addHours(fns.startOfHour(new Date()), 1),
    ToDate: fns.addHours(fns.startOfHour(new Date()), 2),
    MaxGroupSize: 1,
    UseEndDate: "true",
    EndDate: fns.format(new Date().setMonth(new Date().getMonth() + 1), 'yyyy-MM-dd'),
    Repetitions: 10,
    Week: [],
    RepeatType: "0",
  }),
  validationSchema: Yup.lazy((v) => {
    if (v.Type === "Appointment" && v.ServiceType === "Single") {
      return Yup.object().shape({
        Service: Yup.object()
          .nullable(true)
          .required("A service is required"),
        Provider: Yup.object()
          .nullable(true)
          .required("A provider is required"),
        Client: Yup.object()
          .nullable(true)
          .required("A client is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required")
      });
    } else if (v.Type === "Appointment") {
      return Yup.object().shape({
        Service: Yup.object().required("A service is required"),
        Provider: Yup.object().required("A provider is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required"),
        MaxGroupSize: Yup.number()
          .min(1, "Must be one or more.")
          .max(100, "Must be less than one hundred.")
          .required("End time is required")
      });
    } else {
      return Yup.object().shape({
        Provider: Yup.object().required("A provider is required"),
        FromDate: Yup.date().required("From date is required"),
        ToDate: Yup.date().required("To date is required")
      });
    }
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { dispatch, dialogId } = props;
    if (values.Type === "Appointment") {
      try {
        let startTime = moment(`${(fns.format(values.Date, "yyyy-MM-dd"))} ${values.StartTime}`, 'YYYY-MM-DD HH:mm:ss');
        let endTime = moment(`${(fns.format(values.Date, "yyyy-MM-dd"))} ${values.EndTime}`, 'YYYY-MM-DD HH:mm:ss');

        let type = Booking.Type.BOOKING_GROUP
        let WeekReqArray = mapWeekDaysToDTO(values.Week)
        bookingStore.Create(
          props.data.location.ID, // location
          values.MaxGroupSize, // group size
          values.Provider.User.ID, // provider
          values.RepeatType, // daily, weekly or biweekly
          WeekReqArray,
          (values.UseEndDate == "false") && values.RepeatType != "0" ? values.Repetitions : 0, // set repitions to 0 if not used
          startTime.toDate(),
          endTime.toDate(),
          (values.UseEndDate == "true") && values.RepeatType != "0" ? values.EndDate : null,
          values.Service.ID,
          type)

        rebookDataStore.reset()

        props.data.cb && props.data.cb();
        await dispatch(ReduxDialogs.actions.close(dialogId));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    } else {
      try {
        let type = Booking.Type.BOOKING_TIME_OFF

        bookingStore.Create(
          props.data.location.ID, // location
          null, // group size
          values.Provider.User.ID, // provider
          0, // daily, weekly or biweekly
          null,
          0, // set repitions to 0 if not used
          values.FromDate,
          values.ToDate,
          null,
          null,
          type)

        rebookDataStore.reset()

        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    }

    setSubmitting(false);
  },
  displayName: "NewBooking"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(NewBookingDialog);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const HeaderTitle = styled.span`
  ${typography.heading5};
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 50px);
  margin-top: 30px;
`;
