import React, { Component } from "react";
import styled from "@emotion/styled";

// utils
import { breakpoints } from "../../util/consts";
import typography from "../../util/typography";
import ResourceListFetch from "../../components/elements/ResourceListFetch";

// components
import {AppointmentsCard} from "../../components/AppointmentsCard";
import { userStore } from "../../mobx/stores/UserStore";
import { observer } from "mobx-react";
import { restBookingService } from '../../services/BookingService';
import { ActivityIndicator } from "../../ui-kit/ActivityIndicator";
import { taxonomyCategoryStore } from "../../mobx/stores/TaxonomyCategoryStore";
import { restTaxonomyCategoryService } from '../../services/TaxonomyCategoryService';

@observer
export class UserAppointmentsIframe extends Component {

  state = {
    appointments: [],
    loading: true,
  }

  async componentDidMount() {
    this.setState({loading: true});
    const appointments = await restBookingService.fetchBookings(userStore.user.id);
    taxonomyCategoryStore.categories = await restTaxonomyCategoryService.fetchCategories();
    this.setState({appointments, loading: false})
  }

  render() {

    if (this.state.loading) {
      return <ActivityIndicator />
    }
    const { appointments } = this.state;


    const now = Date.now();
    const [pastApts, upcomingApts] = appointments.reduce(
      (acc, cur) => {
        const [pa, fa] = acc;
        const isAptInPast = new Date(cur.EndDateTimeTZ).getTime() < now;
        return isAptInPast ? [[...pa, cur], fa] : [pa, [...fa, cur]];
      },
      [[], []]
    );

    return (
      <Container>
        <ResourceListFetch type="UserBookings" listctx={{ User: userStore.user.id }} force />
        {upcomingApts.length === 0 && pastApts.length === 0 && (
          <>
            <Title>No Appointments</Title>
            <Subtitle>After you've booked an appointment they will show up here</Subtitle>
          </>
        )}
        {upcomingApts.length > 0 && (
          <>
            <Title>Upcoming Appointments</Title>
            <Subtitle>View &amp; edit your upcoming appointments</Subtitle>
            <CardsContainer>
              {upcomingApts.map((apt) => (
                <AppointmentsCard key={apt.ID} resource={apt} />
              ))}
            </CardsContainer>
          </>
        )}
        {pastApts.length > 0 && (
          <>
            <Title>Past Appointments</Title>
            <Subtitle>View your past appointments</Subtitle>
            <CardsContainer>
              {pastApts.map((apt) => (
                <AppointmentsCard key={apt.ID} resource={apt} />
              ))}
            </CardsContainer>
          </>
        )}
      </Container>
    );
  }
}


const Container = styled.div`
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 48px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 48px 96px;
  }
`;

const Title = styled.h2`
  ${typography.heading6};
  font-weight: 700;
  line-height: 27px;
`;

const Subtitle = styled.span`
  ${typography.subtitle1};
  letter-spacing: 0.5px;
  line-height: 28px;
  opacity: 0.6;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
  margin: -10px;
`;
