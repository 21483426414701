import React, { Component, FormEventHandler } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";

// Redux
import apiService, { HTTPMethod } from "./../../redux/services/api";
import * as ReduxDialogs from "./../../redux/features/dialogs";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { ResourceTypes } from "../../redux/features/resources/definitions";

// Utils
import { breakpoints } from "./../../util/consts";
import typography from "./../../util/typography";
import { toPriceString } from "./../../util";
import { parseFormError } from "./../../util/form";

// Custom components
import Dialog from "./Dialog";
import Button from "./../../components/Button";
import ColorsSelect from "./../../components/form/ColorsSelector";
import CategoriesSelector from "./../../components/form/CategoriesSelector";
import LoadingButton from "./../../components/LoadingButton";

import Form from "./../../components/form/Form";
import TextArea from "./../../components/form/TextArea";
import SectionsContainer from "./../../components/form/SectionsContainer";
import Section from "./../../components/form/Section";
import FormHeader from "./../../components/form/FormHeader";
import Label from "./../../components/form/Label";
import Input from "./../../components/form/Input";
import Error from "./../../components/form/Error";
import FooterContainer from "./../../components/form/FooterContainer";
import { FundMedicareServiceSearcher } from "./../../components/form/ResourceSearcher";

import CheckRadioBox from "./../../components/form/CheckRadioBox";
import Select from "./../../components/form/BaseSelect";
import { getResource } from "./../../redux/features/resources/helpers";
import Tooltip from "../elements/Tooltip";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { rpc, metadata } from "../../grpc";
import { toastStore } from "../../stores/toast-store";
import { TokenAvailableRequest } from "sdk/dist/physitrack_pb";
import { ApplicationType } from "sdk/dist/applications_and_tokens_pb";
import { UserUIType } from "sdk/dist/location_user_pb";
import { SearchQueryRequest } from "sdk/dist/search_pb";
import MediaInput from "../form/MediaInput";

interface Props {
  dialogId: string;
  data: any;
  resource: any;
  values: Values;
  dispatch: (action: any) => any;
  setValues: (v: Values) => void;
  handleChange: (o: Object) => void;
  errors: Values;
  touched: Values;
  handleBlur: (o: Object) => void;
  setFieldValue: (v: Values) => void;
  setFieldTouched: (v: Values) => void;
  setFieldError: (v: Values) => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
}

interface Values {
  Categories: string[];
  Color: string;
  CreatedAt: string;
  Description: string;
  Fee: number;
  FundProperties: Object;
  ID: string;
  InternalCode: string;
  LastModifiedAt: string;
  Location: string;
  MedicareFee: number;
  Name: string;
  OnlyGroup: boolean;
  ServiceDuration: 20;
  Status: string;
  Taxes: {
    AUGST: string;
  };
  Type: "Service" | "Product";
  Visible: true;
  is_telehealth: "True" | "False";
  level: string;
  tax_rate: number;
  image: {
    CreatedAt: string;
    Description: string;
    DownloadURL: string;
    Filename: string;
    ID: string;
    LastModifiedAt: string;
    Mime: string;
    Name: string;
    Type: string;
  };

  Tax: string;
  Code: string;
  ServiceType: any;
  ServiceFundTypes: any;
  Fund_AUMedicare_ServiceNumber: string;
  Fund_AUMedicare_ServiceType: string;
  Fund_AUDVA_ServiceNumber: string;
  Fund_AUDVA_ServiceType: string;
  Fund_AUHICAPS_ItemNumber: string;
  Fund_AUVictoriaTAC_ItemCode: string;
  Visibility: string;
}
@observer
class OfferingModal extends Component<Props> {
  @observable
  physitrackTokenAvailable = false;

  async componentDidMount() {
    try {
      // Get token and patient data for physitrack
      const req = new TokenAvailableRequest();
      req.setOrgid(this.props.data.loc.Org.ID);
      // req.setClientid(clientId);
      req.setType(ApplicationType.PHYSITRACK_LYFE);

      const res = await rpc.physitrackV1.tokenAvailable(req, metadata());
      this.physitrackTokenAvailable = res.toObject().isAvailable;
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Based on the Dallas's request, add a simple pop-up to tell users to fill all the required fields.
    // https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1219
    if (
      prevProps.isSubmitting &&
      !this.props.isSubmitting &&
      Object.keys(this.props.errors).length !== 0
    ) {
      toastStore.error("Please fill all the required fields.");
    }
  }

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  calcPrice(rate: number) {
    const { values } = this.props;
    if (!values.Fee) {
      return "";
    }
    switch (values.Tax) {
      case "Included":
        return toPriceString(values.Fee, false);
      case "Excluded":
        return toPriceString(values.Fee * (1 + rate / 100), false);
      case "Exempt":
        return toPriceString(values.Fee, false);
      default:
        return "";
    }
  }

  handleMedicareSelect = (v: Values) => {
    const newValues: any = {
      ...this.props.values,
      Description: v.Description,
      Fee: toPriceString(v.Fee, true),
      MedicareFee: v.Fee,
      Tax: "Exempt",
      ...getFundValues({
        AUMedicare: {
          ServiceNumber: `${v.Code}`,
          ServiceType: v.ServiceType
        }
      })
    };
    if (!newValues.InternalCode) {
      newValues.InternalCode = v.Code;
    }
    if (newValues.ServiceFundTypes.indexOf("AUMedicare") < 0) {
      newValues.ServiceFundTypes = [...newValues.ServiceFundTypes, "AUMedicare"];
    }
    this.props.setValues(newValues);
  };

  renderServiceTypeSelector() {
    const { values } = this.props;

    if (values.Type !== "Service") {
      return null;
    }

    return (
      <React.Fragment>
        <FormSection style={{ marginTop: "18px" }}>Service Lookup</FormSection>
        <SectionsContainer>
          <Section style={{ position: "relative" }}>
            <FundMedicareServiceSearcher
              id={`offering-Lookup`}
              name="Lookup"
              placeholder="Search for a Medicare service"
              onSelect={this.handleMedicareSelect}
            />
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }

  renderHeader() {
    const {
      resource,
      values,
      handleChange,
      data: { setType }
    } = this.props;
    if (!resource) {
      return (
        <React.Fragment>
          <FormHeader title={"New " + (setType || "Service or Product")} />

          {!setType && (
            <SectionsContainer style={{ marginTop: "38px" }}>
              <Section style={{ display: "flex" }}>
                <Button
                  color="primary"
                  variant={values.Type === "Service" ? "contained" : "outlined"}
                  onClick={() =>
                    handleChange({
                      target: { name: "Type", value: "Service" }
                    })
                  }
                  style={{ marginRight: 24, flex: "1 1 auto" }}
                >
                  Service
                </Button>
                <Button
                  color="primary"
                  variant={values.Type === "Product" ? "contained" : "outlined"}
                  onClick={() =>
                    handleChange({
                      target: { name: "Type", value: "Product" }
                    })
                  }
                  style={{ flex: "1 1 auto" }}
                >
                  Product
                </Button>
              </Section>
            </SectionsContainer>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ marginBottom: "22px" }}>
          <FormHeader title={`Edit ${resource.Type}`} />
        </div>
      );
    }
  }

  renderDetails() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      setFieldError
    } = this.props;
    return (
      <React.Fragment>
        <FormSection style={{ marginTop: "18px" }}>Details</FormSection>
        <SectionsContainer>
          <Section>
            <Label htmlFor="offering-Name">Name</Label>
            <Input
              id="offering-Name"
              name="Name"
              type="text"
              placeholder={`${"Name of " + values.Type}`}
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Name && errors.Name}
            />
            {!!errors.Name && touched.Name && <Error>{errors.Name}</Error>}
          </Section>
          <Section>
            <Label htmlFor="offering-Description">Description</Label>
            <TextArea
              id="offering-Description"
              name="Description"
              type="text"
              placeholder={`${"Description of " + values.Type + " (not required)"}`}
              value={values.Description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Description && errors.Description}
            />
            {!!errors.Description && touched.Description && <Error>{errors.Description}</Error>}
          </Section>
          {values.Type === "Service" && (
            <Section>
              <Label>
                Type{" "}
                <Tooltip>
                  Select the type of service, session in person (one to one) or using the telehealth
                  feature from physitrack, you will need to set up the api tokens in the staff
                  dialog to use the latest{" "}
                </Tooltip>
              </Label>
              <FormSplitContainer>
                <FormSplitItem>
                  <CheckRadioBox
                    id="offering-telehealth-false"
                    name="is_telehealth"
                    value="False"
                    type="radio"
                    checked={values.is_telehealth !== "True"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.is_telehealth && errors.is_telehealth}
                  >
                    {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
                      ? "Regular/Group Session"
                      : "1:1 Session"}
                  </CheckRadioBox>
                </FormSplitItem>
                {
                  <FormSplitItem>
                    <CheckRadioBox
                      id="offering-telehealth-true"
                      name="is_telehealth"
                      value="True"
                      type="radio"
                      checked={values.is_telehealth === "True"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.is_telehealth && errors.is_telehealth}
                    >
                      Telehealth Session
                    </CheckRadioBox>
                  </FormSplitItem>
                }
              </FormSplitContainer>
              {!!errors.is_telehealth && touched.is_telehealth && (
                <Error>{errors.is_telehealth}</Error>
              )}
            </Section>
          )}
          <SectionsContainer>
            <Section>
              <Label htmlFor="offering-Image">{values.Type} Image</Label>
              <MediaInput
                type="image"
                name="image"
                value={values.image}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                setFieldError={setFieldError}
              />
              {!!errors.image && touched.image && <Error>{errors.image}</Error>}
            </Section>
          </SectionsContainer>
          <Section>
            <Label htmlFor="offering-InternalCode">
              Internal Code{" "}
              <Tooltip>Assign a code for your service for your own internal purposes.</Tooltip>
            </Label>
            {/* (?) Assign a code for your service for your own internal purposes. */}
            <Input
              id="offering-InternalCode"
              name="InternalCode"
              type="text"
              placeholder="Internal code"
              value={values.InternalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.InternalCode && errors.InternalCode}
            />
            {!!errors.InternalCode && touched.InternalCode && <Error>{errors.InternalCode}</Error>}
          </Section>
          {values.Type === "Service" && (
            <Section>
              <Label htmlFor="offering-ServiceDuration">Duration (minutes)</Label>
              <Input
                id="offering-ServiceDuration"
                name="ServiceDuration"
                type="number"
                min={5}
                max={1440}
                step={5}
                placeholder="Duration of service (minutes)"
                value={values.ServiceDuration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ServiceDuration && errors.ServiceDuration}
              />
              {!!errors.ServiceDuration && touched.ServiceDuration && (
                <Error>{errors.ServiceDuration}</Error>
              )}
            </Section>
          )}
          <Section>
            <Label htmlFor="offering-Categories">
              Categories{" "}
              <Tooltip>
                If you can’t find a category that fits your purposes email support@lyfe.io and we
                can add it to the list.
              </Tooltip>
            </Label>
            <CategoriesSelector
              id="offering-Categories"
              name="Categories"
              value={values.Categories}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Categories && errors.Categories}
            />
            {!!errors.Categories && touched.Categories && <Error>{errors.Categories}</Error>}
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }

  renderPrice() {
    const { values, errors, touched, handleChange, handleBlur } = this.props;

    return (
      <React.Fragment>
        <FormSection>Price</FormSection>
        <SectionsContainer>
          <Section>
            <FormSplitContainer>
              <FormSplitItem>
                <Label htmlFor="offering-Fee">Price</Label>
                <Input
                  id="offering-Fee"
                  name="Fee"
                  type="number"
                  placeholder="0.00"
                  value={values.Fee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Fee && errors.Fee}
                />
                {!!errors.Fee && touched.Fee && <Error>{errors.Fee}</Error>}
                {values.MedicareFee && (
                  <Small>
                    Estimated Medicare Rebate: ${toPriceString(values.MedicareFee, true)}
                    <Tooltip style={{ marginLeft: 10, display: "inline-block" }}>
                      The Medicare Rebate will be charged instead of the set price when using
                      Medicare Bulk Billing. The final charge may differ from the currently listed
                      estimate.
                    </Tooltip>
                  </Small>
                )}
              </FormSplitItem>
              <FormSplitItem>
                <Label htmlFor="offering-tax_rate">Tax rate%</Label>
                <Input
                  id="offering-tax_rate"
                  name="tax_rate"
                  type="number"
                  placeholder="8.88"
                  value={values.Tax === "Exempt" ? (values.tax_rate = 0) : values.tax_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tax_rate && errors.tax_rate}
                  disabled={values.Tax === "Exempt" ? true : false}
                />
                {!!errors.tax_rate && touched.tax_rate && <Error>{errors.tax_rate}</Error>}
              </FormSplitItem>
              <FormSplitItem>
                <Label htmlFor="offering-Tax">Tax</Label>
                <Select
                  id="offering-Tax"
                  name="Tax"
                  type="select"
                  value={values.Tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Tax && errors.Tax}
                >
                  <option value="Included">Included</option>
                  <option value="Excluded">Excluded</option>
                  <option value="Exempt">Exempt</option>
                </Select>
                {!!errors.Tax && touched.Tax && <Error>{errors.Tax}</Error>}
              </FormSplitItem>
            </FormSplitContainer>
          </Section>
          <Section>
            <Label style={{ marginBottom: 0 }}>
              Total: <PriceTotal>{this.calcPrice(values.tax_rate)}</PriceTotal>
            </Label>
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }

  renderFundDetails() {
    const { values, errors, touched, handleChange, handleBlur } = this.props;
    return (
      <React.Fragment>
        <FormSection>Fund Details</FormSection>
        <SectionsContainer>
          <Section>
            <FormSplitContainer>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-servicetype-med"
                  name="ServiceFundTypes"
                  value="AUMedicare"
                  type="checkbox"
                  values={values.ServiceFundTypes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ServiceFundTypes && errors.ServiceFundTypes}
                >
                  Medicare
                </CheckRadioBox>
              </FormSplitItem>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-servicetype-dva"
                  name="ServiceFundTypes"
                  value="AUDVA"
                  type="checkbox"
                  values={values.ServiceFundTypes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ServiceFundTypes && errors.ServiceFundTypes}
                >
                  DVA
                </CheckRadioBox>
              </FormSplitItem>
            </FormSplitContainer>
            <FormSplitContainer>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-servicetype-hicaps"
                  name="ServiceFundTypes"
                  value="AUHICAPS"
                  type="checkbox"
                  values={values.ServiceFundTypes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ServiceFundTypes && errors.ServiceFundTypes}
                >
                  HICAPS
                </CheckRadioBox>
              </FormSplitItem>
            </FormSplitContainer>
            {!!errors.ServiceFundTypes && touched.ServiceFundTypes && (
              <Error>{errors.ServiceFundTypes}</Error>
            )}
          </Section>
        </SectionsContainer>

        {values.ServiceFundTypes.indexOf("AUMedicare") >= 0 && (
          <React.Fragment>
            <SectionsContainer>
              <Section>
                <Label htmlFor="offering-funds-medicare-sn">Medicare Service Code</Label>
                <Input
                  id="offering-funds-medicare-sn"
                  name="Fund_AUMedicare_ServiceNumber"
                  type="text"
                  value={values.Fund_AUMedicare_ServiceNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.Fund_AUMedicare_ServiceNumber && errors.Fund_AUMedicare_ServiceNumber
                  }
                />
                {!!errors.Fund_AUMedicare_ServiceNumber &&
                  touched.Fund_AUMedicare_ServiceNumber && (
                    <Error>{errors.Fund_AUMedicare_ServiceNumber}</Error>
                  )}
              </Section>
              <Section>
                <Label htmlFor="ooffering-funds-medicare-servicetype">Medicare Service Type</Label>
                <Select
                  id="ooffering-funds-medicare-servicetype"
                  name="Fund_AUMedicare_ServiceType"
                  value={values.Fund_AUMedicare_ServiceType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="General">General Practitioner (Standard + Bulk Billing)</option>
                  <option value="Specialist">Allied/Specialist (Standard + Bulk Billing)</option>
                  <option value="Optical">Optical (Standard)</option>
                  <option value="SpeechPathology">Speech Pathology (Standard)</option>
                  <option value="Allied">Allied (Standard)</option>
                  <option value="Psych">Psych (Standard)</option>
                </Select>
                {!!errors.Fund_AUMedicare_ServiceType && touched.Fund_AUMedicare_ServiceType && (
                  <Error>{errors.Fund_AUMedicare_ServiceType}</Error>
                )}
              </Section>
            </SectionsContainer>
          </React.Fragment>
        )}
        {values.ServiceFundTypes.indexOf("AUDVA") >= 0 && (
          <React.Fragment>
            <SectionsContainer>
              <Section>
                <Label htmlFor="offering-funds-dva-sn">DVA Service Code</Label>
                <Input
                  id="offering-funds-dva-sn"
                  name="Fund_AUDVA_ServiceNumber"
                  type="text"
                  value={values.Fund_AUDVA_ServiceNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Fund_AUDVA_ServiceNumber && errors.Fund_AUDVA_ServiceNumber}
                />
                {!!errors.Fund_AUDVA_ServiceNumber && touched.Fund_AUDVA_ServiceNumber && (
                  <Error>{errors.Fund_AUDVA_ServiceNumber}</Error>
                )}
              </Section>
              <Section>
                <Label htmlFor="ooffering-funds-dva-servicetype">DVA Service Type</Label>
                <Select
                  id="ooffering-funds-dva-servicetype"
                  name="Fund_AUDVA_ServiceType"
                  value={values.Fund_AUDVA_ServiceType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="General">General Practitioner</option>
                  <option value="Specialist">Allied/Specialist</option>
                  <option value="Optical">Optical (Allied Health)</option>
                  <option value="SpeechPathology">Speech Pathology (Allied Health)</option>
                  <option value="Allied">Allied (Allied Health)</option>
                  <option value="Psych">Psych (Allied Health)</option>
                </Select>
                {!!errors.Fund_AUDVA_ServiceType && touched.Fund_AUDVA_ServiceType && (
                  <Error>{errors.Fund_AUDVA_ServiceType}</Error>
                )}
              </Section>
            </SectionsContainer>
          </React.Fragment>
        )}

        {values.ServiceFundTypes.indexOf("AUHICAPS") >= 0 && (
          <React.Fragment>
            <SectionsContainer>
              <Section>
                <Label htmlFor="offering-funds-hicaps-in">HICAPS/Medipass Item Number</Label>
                <Input
                  id="offering-funds-hicaps-in"
                  name="Fund_AUHICAPS_ItemNumber"
                  type="text"
                  value={values.Fund_AUHICAPS_ItemNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Fund_AUHICAPS_ItemNumber && errors.Fund_AUHICAPS_ItemNumber}
                />
                {!!errors.Fund_AUHICAPS_ItemNumber && touched.Fund_AUHICAPS_ItemNumber && (
                  <Error>{errors.Fund_AUHICAPS_ItemNumber}</Error>
                )}
              </Section>
            </SectionsContainer>
          </React.Fragment>
        )}
        {values.ServiceFundTypes.indexOf("AUVictoriaTAC") >= 0 && (
          <React.Fragment>
            <SectionsContainer>
              <Section>
                <Label htmlFor="offering-funds-TAC-in">TAC Item Number</Label>
                <Input
                  id="offering-funds-TAC-in"
                  name="Fund_AUVictoriaTAC_ItemCode"
                  type="text"
                  value={values.Fund_AUVictoriaTAC_ItemCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Fund_AUVictoriaTAC_ItemCode && errors.Fund_AUVictoriaTAC_ItemCode}
                />
                {!!errors.Fund_AUVictoriaTAC_ItemCode && touched.Fund_AUVictoriaTAC_ItemCode && (
                  <Error>{errors.Fund_AUVictoriaTAC_ItemCode}</Error>
                )}
              </Section>
            </SectionsContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderAppearance() {
    const { values, errors, touched, handleChange, handleBlur, setFieldValue } = this.props;

    if (values.Type !== "Service") {
      return null;
    }

    return (
      <React.Fragment>
        <FormSection>Appearance</FormSection>
        <SectionsContainer>
          <Section>
            <Label>
              Type{" "}
              <Tooltip>
                Make this service visible to your clients making a booking or hide the service for
                internal use. For example, you can make only a few services visible for your clients
                to make booking an appointment easier and less confusing for them, then exchange the
                service or add additional services from the payment processing screen.
              </Tooltip>
            </Label>
            <FormSplitContainer>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-visibility-vis"
                  name="Visibility"
                  value="Visible"
                  type="radio"
                  checked={values.Visibility === "Visible"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Visibility && errors.Visibility}
                >
                  Visible
                </CheckRadioBox>
              </FormSplitItem>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-visibility-hid"
                  name="Visibility"
                  value="Hidden"
                  type="radio"
                  checked={values.Visibility === "Hidden"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Visibility && errors.Visibility}
                >
                  Hidden
                </CheckRadioBox>
              </FormSplitItem>
            </FormSplitContainer>
            {!!errors.Visibility && touched.Visibility && <Error>{errors.Visibility}</Error>}
          </Section>
          <Section>
            <Label>Service Level</Label>
            <FormSplitContainer>
              <FormSplitItem>
                <CheckRadioBox
                  id="Level-Advanced"
                  name="level"
                  value={String(SearchQueryRequest.CategoryLevel.ADVANCED)}
                  checked={values.level === SearchQueryRequest.CategoryLevel.ADVANCED.toString()}
                  type="radio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.level && errors.level}
                >
                  Advanced
                </CheckRadioBox>
              </FormSplitItem>
              <FormSplitItem>
                <CheckRadioBox
                  id="Level-Intermediate"
                  name="level"
                  value={String(SearchQueryRequest.CategoryLevel.INTERMEDIATE)}
                  checked={
                    values.level === SearchQueryRequest.CategoryLevel.INTERMEDIATE.toString()
                  }
                  type="radio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.level && errors.level}
                >
                  Intermediate
                </CheckRadioBox>
              </FormSplitItem>
              <FormSplitItem>
                <CheckRadioBox
                  id="Level-Beginner"
                  name="level"
                  value={String(SearchQueryRequest.CategoryLevel.BEGINNER)}
                  checked={values.level === SearchQueryRequest.CategoryLevel.BEGINNER.toString()}
                  type="radio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.level && errors.level}
                >
                  Beginner
                </CheckRadioBox>
              </FormSplitItem>
              <FormSplitItem>
                <CheckRadioBox
                  id="offering-Level"
                  name="level"
                  value={String(SearchQueryRequest.CategoryLevel.NONE)}
                  type="radio"
                  checked={values.level === SearchQueryRequest.CategoryLevel.NONE.toString()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.level && errors.level}
                >
                  None
                </CheckRadioBox>
              </FormSplitItem>
            </FormSplitContainer>
            {!!errors.level && touched.level && <Error>{errors.level}</Error>}
          </Section>

          <Section>
            <Label htmlFor="offering-Color">Color</Label>
            <ColorsSelect
              id="offering-Color"
              name="Color"
              value={values.Color}
              setFieldValue={setFieldValue}
            />
            {!!errors.Color && touched.Color && <Error>{errors.Color}</Error>}
          </Section>
        </SectionsContainer>
      </React.Fragment>
    );
  }

  render() {
    const { handleSubmit, isSubmitting } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : handleSubmit
            }
          >
            {this.renderHeader()}
            {this.renderDetails()}
            {this.renderPrice()}
            {localStorage.getItem("UserUIType") === String(UserUIType.FITNESS) ||
            process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
              ? false
              : this.renderFundDetails()}
            {this.renderAppearance()}
            <FooterContainer style={{ marginTop: "28px" }}>
              <LoadingButton
                style={{
                  minWidth: 150
                }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </FooterContainer>
          </Form>
        </Container>
      </Dialog>
    );
  }
}

function getFundValues(fpro: any) {
  const fprops = {
    Fund_AUMedicare_ServiceNumber: "",
    Fund_AUMedicare_ServiceType: "General",
    Fund_AUDVA_ServiceNumber: "",
    Fund_AUDVA_ServiceType: "General",
    Fund_AUHICAPS_ItemNumber: "",
    Fund_AUVictoriaTAC_ItemCode: ""
  };
  if (fpro.hasOwnProperty("AUMedicare")) {
    fprops.Fund_AUMedicare_ServiceNumber = fpro.AUMedicare.ServiceNumber;
    fprops.Fund_AUMedicare_ServiceType = fpro.AUMedicare.ServiceType;
  }
  if (fpro.hasOwnProperty("AUDVA")) {
    fprops.Fund_AUDVA_ServiceNumber = fpro.AUDVA.ServiceNumber;
    fprops.Fund_AUDVA_ServiceType = fpro.AUDVA.ServiceType;
  }
  if (fpro.hasOwnProperty("AUHICAPS")) {
    fprops.Fund_AUHICAPS_ItemNumber = fpro.AUHICAPS.ItemNumber;
  }
  if (fpro.hasOwnProperty("AUVictoriaTAC")) {
    fprops.Fund_AUVictoriaTAC_ItemCode = fpro.AUVictoriaTAC.ItemCode;
  }
  return fprops;
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props: Props) => ({
    Type: props.resource ? props.resource.Type : props.data.setType || "Service",
    ServiceFundTypes: props.resource ? Object.keys(props.resource.FundProperties || {}) : [],
    Categories: props.resource ? props.resource.Categories || [] : [],
    ServiceDuration: props.resource ? props.resource.ServiceDuration || "" : "",
    Name: props.resource ? props.resource.Name || "" : "",
    Description: props.resource ? props.resource.Description || "" : "",
    InternalCode: props.resource ? props.resource.InternalCode || "" : "",
    Color: props.resource ? props.resource.Color || "#0000FF" : "#0000FF",
    Fee: props.resource ? toPriceString(props.resource.Fee, true) || "" : "",
    tax_rate: props.resource ? toPriceString(props.resource.tax_rate, true) || "" : "",
    Visibility: !props.resource || props.resource.Visible ? "Visible" : "Hidden",
    Tax: !props.resource ? "Included" : props.resource.Taxes.AUGST,
    is_telehealth: !props.resource || !props.resource.is_telehealth ? "False" : "True",
    level: props.resource
      ? props.resource.level
        ? SearchQueryRequest.CategoryLevel[props.resource.level].toString() || "0"
        : "0"
      : "0",
    image: props.resource ? props.resource.image : "",
    ...getFundValues(props.resource ? props.resource.FundProperties || {} : {})
  }),
  validationSchema: Yup.lazy((v: any) => {
    if (v.Type === "Service") {
      return Yup.object().shape({
        Type: Yup.string().required("Type is required"),
        Categories: Yup.array().of(Yup.string()).notRequired(),
        ServiceDuration: Yup.number()
          .test("is-multiple-5", "Service duration should be a multiple of 5", (sd) => sd % 5 === 0)
          .required("Service duration is required."),
        Name: Yup.string().required("Name is required"),
        Description: Yup.string().notRequired(),
        InternalCode: Yup.string().required("Code is required"),
        Color: Yup.string().notRequired(),
        Fee: Yup.number().required("Fee is required"),
        tax_rate: Yup.number().required("Tax rate is required").lessThan(100),
        Tax: Yup.string().required("Tax is required"),
        Visibility: Yup.string().required("Visibility is required"),
        Fund_AUMedicare_ServiceNumber:
          v.ServiceFundTypes.indexOf("AUMedicare") >= 0
            ? Yup.string().required("Medicare service number is required")
            : Yup.string().notRequired(),
        Fund_AUMedicare_ServiceType:
          v.ServiceFundTypes.indexOf("AUMedicare") >= 0
            ? Yup.string().required("Medicare service type required")
            : Yup.string().notRequired(),
        Fund_AUDVA_ServiceNumber:
          v.ServiceFundTypes.indexOf("AUDVA") >= 0
            ? Yup.string().required("DVA service number is required")
            : Yup.string().notRequired(),
        Fund_AUDVA_ServiceType:
          v.ServiceFundTypes.indexOf("AUDVA") >= 0
            ? Yup.string().required("DVA service type is required")
            : Yup.string().notRequired(),
        Fund_AUHICAPS_ItemNumber:
          v.ServiceFundTypes.indexOf("AUHICAPS") >= 0
            ? Yup.string().required("HICAPS item number is required")
            : Yup.string().notRequired(),
        Fund_AUVictoriaTAC_ItemCode:
          v.ServiceFundTypes.indexOf("AUVictoriaTAC") >= 0
            ? Yup.string().required("TAC item code is required")
            : Yup.string().notRequired(),
        is_telehealth: Yup.string().required("Session type is required"),
        image: Yup.string().required("Image is required.")
      });
    } else {
      return Yup.object().shape({
        Type: Yup.string().required("Type is required"),
        Categories: Yup.array().of(Yup.string()).notRequired(),
        Name: Yup.string().required("Name is required"),
        Description: Yup.string().notRequired(),
        InternalCode: Yup.string().required("Code is required"),
        Fee: Yup.number().required("Fee is required"),
        Tax: Yup.string().required("Tax is required"),
        tax_rate: Yup.number().required("Tax rate is required").lessThan(100),
        image: Yup.string().required("Image is required.")
      });
    }
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const object: any = {
        Type: values.Type,
        Location: props.data.loc.ID,
        Name: values.Name.trim(),
        Description: values.Description.trim(),
        InternalCode: values.InternalCode,
        Color: values.Color,
        Categories: values.Categories,
        Fee: 100 * parseInt(values.Fee),
        Taxes: {
          AUGST: values.Tax
        },
        FundProperties: {},
        tax_rate: 100 * parseInt(values.tax_rate),
        image: values.image.ID || values.image,
        level: values.level
      };
      if (values.Type === "Service") {
        object.ServiceDuration = parseInt(values.ServiceDuration);
        object.Visible = values.Visibility === "Visible";
        object.OnlyGroup = true;
        if (values.ServiceFundTypes.indexOf("AUMedicare") >= 0) {
          object.FundProperties.AUMedicare = {
            ServiceNumber: values.Fund_AUMedicare_ServiceNumber.trim(),
            ServiceType: values.Fund_AUMedicare_ServiceType
          };
        }
        if (values.ServiceFundTypes.indexOf("AUDVA") >= 0) {
          object.FundProperties.AUDVA = {
            ServiceNumber: values.Fund_AUDVA_ServiceNumber.trim(),
            ServiceType: values.Fund_AUDVA_ServiceType
          };
        }
        if (values.ServiceFundTypes.indexOf("AUHICAPS") >= 0) {
          object.FundProperties.AUHICAPS = {
            ItemNumber: values.Fund_AUHICAPS_ItemNumber.trim()
          };
        }
        if (values.ServiceFundTypes.indexOf("AUVictoriaTAC") >= 0) {
          object.FundProperties.AUVictoriaTAC = {
            ItemCode: values.Fund_AUVictoriaTAC_ItemCode.trim()
          };
        }
        if (values.level) {
          if (values.level != "0") {
            object.Level = Object.keys(SearchQueryRequest.CategoryLevel)[values.level];
          } else {
            object.Level = null;
          }
        }
      } else {
        object.Visible = true;
        object.OnlyGroup = false;
        object.Level = null;
      }

      if (values.is_telehealth === "True") {
        object.is_telehealth = true;
      } else {
        object.is_telehealth = false;
      }
      if (!props.resource || props.data.duplicate) {
        const res = await apiService.performRequest({
          method: HTTPMethod.Post,
          path: "/api/v1/offerings",
          data: object
        });
        props.data.cb && props.data.cb(res.Payload);
      } else {
        const updRes = {
          ...props.resource,
          ...object
        };
        const res = await props.dispatch(ResourceActions.action(updRes, "Update", {}));
        props.data.cb && props.data.cb(res.Payload);
      }

      props.dispatch(ReduxDialogs.actions.close(props.dialogId));
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "OfferingNewEdit"
});

function mapStateToProps(state: any, props: Props) {
  const resource = getResource(
    state.resources,
    ResourceTypes.Offering,
    {
      ID: props.data.offerID
    },
    true
  );

  return resource && resource.ID ? { resource } : {};
}

export default compose(connect(mapStateToProps), formikEnhancer)(OfferingModal as any);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 85px 95px 85px 99.5px;
    max-width: 700px;
  }
`;

const FormSection = styled.div`
  ${typography.heading6};
  font-weight: 600;
  margin-top: 36px;
`;

export const FormSplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const FormSplitItem = styled.div`
  flex: 1 1 0;
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

const PriceTotal = styled.span`
  color: #00b1b6;
  font-weight: 600;
`;

const Small = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.7;
  font-size: 12px;
  margin-top: 1em;
`;
