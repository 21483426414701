import { schema } from "normalizr";
import {
  resourceDefinitions,
  getIdsFromResource
} from "./definitions";

const normalizrSchemas: { [key: string]: schema.Entity } = {};

// Create the initial schema definitions.
Object.keys(resourceDefinitions).forEach((rt)=>{
  normalizrSchemas[rt] = new schema.Entity(rt, {}, {
    idAttribute: (r)=>(getIdsFromResource({ $Metadata: { Type: rt }, ...r })||[]).join("$"),
    processStrategy: !resourceDefinitions[rt].preProcess ?
                     (value)=>value :
                     resourceDefinitions[rt].preProcess,
  });
});

// Create the mapping between all the schemas.
Object.keys(resourceDefinitions).forEach((rt)=>{
  const edges = {};
  Object.keys(resourceDefinitions[rt].edges).forEach((ek)=>{
    const edge = resourceDefinitions[rt].edges[ek];
    if (Array.isArray(edge)) {
      edges[ek] = [normalizrSchemas[edge[0]]];
    } else {
      edges[ek] = normalizrSchemas[edge];
    }
  });
  normalizrSchemas[rt].define(edges);
});

export { normalizrSchemas };


// Organisation Store
export interface Organisation {
  Address: {
    Country: string; 
    CountryCode: string; 
    GooglePlaceID: string;
    ID: string;
    Locality: string;
    Location: {Longitude: number, Latitude: number};
    PostCode: string;
    Raw: string;
    State: string;
    StateCode: string;
    Street1: string;
    Street2: string;
    Timezone: string;
  };
  BankAccountDetails: {
    BSB: string;
    AccountName: string;
    AccountNumber: string;
  };
  Banner: {
    CreatedAt: string;
    Description: string;
    DownloadURL: string;
    Filename: string;
    ID: string;
    LastModifiedAt: string;
    Mime: string;
    Name: string;
    Type: string;
  };
  ContactEmail: string;
  ContactPhone: string;
  CreatedAt: string;
  CurrentFundPoolAmount: number;
  Description: string;
  FundPoolActive: boolean;
  ID: string;
  IFrameSrc: string;
  Integrations: {};
  LastFundPoolTopupAmount: number;
  LastModifiedAt: string;
  Locations: [any]
  Logo: {
    CreatedAt: string;
    Description: string;
    DownloadURL: string;
    Filename: string;
    ID: string;
    LastModifiedAt: string;
    Mime: string;
    Name: string;
    Type: string;
  };
  Name: string;
  RolloverSubscriptionType?: string;
  Settings: {
    ClientPaysCCFee: boolean;
    NotificationDaysBefore: number;
    PenaltyFeeFixed: number;
    PenaltyFeeVar: number,
    PenaltyTimePeriod: number;
    RequireBookingApproval: boolean;
    ThirdPartyTACs: string;
  }
  SubscriptionDetails: {
    CardFixed: number;
    CardVar: number;
    FundFixed: number;
    FundVar: number;
    MaxProviders: number;
    NewTransactionFixed: number,
    NewTransactionVar: number;
    NumPaymentFailDays: number;
    StandardTransactionFixed: number;
    StandardTransactionVar: number;
    SubscriptionMonthly: number;
  }
  SubscriptionStartedAt: string;
  SubscriptionStatus: string;
  SubscriptionStatusMessage: string;
  SubscriptionType: string;
  TaxDetails: {
    ABN: string;
    EntityName: string;
  }
}