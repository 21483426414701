import { EntityStore } from "./entity-store";
import { rpc, metadata } from "./../grpc";
import { HealthFunds, GetFundsRequest } from "sdk/dist/funds_pb";
import { toastStore } from "./toast-store";

class ClientFundStore extends EntityStore<HealthFunds.AsObject> {
  async load(clientId: string) {
    const req = new GetFundsRequest();
    req.setClientId(clientId);
    try {
      const res = await rpc.clientFunds.get(req, metadata());
      this.add(res.toObject());
    } catch (err) {
      toastStore.grpcError(err);
    }
  }
}

export const clientFundStore = new ClientFundStore();
