import React from "react";
import styled from "@emotion/styled";

import { colors } from "../../../util/consts";

interface Props {
  icon: any
  iconProps: any
  number: number
  title: string
  subtitle: string
}

export const SmallBlock = React.memo(({ icon: Icon, iconProps, number, title, subtitle }: Props) => (
  <Container>
    <TopContainer>
      <CenterHorizontally>
        <Icon {...iconProps} size="medium" />
        <TopTitle style={{ marginLeft: 6 }}>{number}</TopTitle>
      </CenterHorizontally>
    </TopContainer>
    <BottomContainer>
      <BottomTitle>{title}</BottomTitle>
      <BottomSubtitle>{subtitle}</BottomSubtitle>
    </BottomContainer>
  </Container>
));

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50%;
`;

const CenterHorizontally = styled.div`
  display: flex;
  align-items: center;
`;

const TopTitle = styled.span`
  color: #2c2e3c;
  font-size: 34.82px;
  letter-spacing: 0.25px;
  line-height: 1em;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  height: 50%;
`;

const BottomTitle = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 13.82px;
  letter-spacing: 0.25px;
  line-height: 19px;
`;

const BottomSubtitle = styled.span`
  color: ${colors.surfaceText.lowEmphasis};
  font-size: 9.82px;
  letter-spacing: 0.25px;
  line-height: 13px;
`;
