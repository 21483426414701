import { AccountItem } from "sdk/dist/account_items_pb";
import { Filters } from "react-table";
import { FiltersAccountTable } from "sdk/dist/accounts_table_pb";
import { fundTypeFilterToInternal } from "./funds";
import { accTableTypeToDTO, statusAccRowToDTO } from "./account-items";
import { accTableStore } from "../stores/acc_table-store";
import { accTableFilters } from "../stores/filters_acc_table-store";
import { timezone } from "./timestamp";
import moment from "moment";

type FilterKeys =
  | keyof FiltersAccountTable.AsObject
  | "bookingType"
  | "fundType"
  | "status"
  | "accItemType";

export const setAccountFilterValues = (filters: Filters<AccountItem.AsObject>) => {
  const filtersAccountTable = new FiltersAccountTable();

  const findFilterValue = (filterKeyName: FilterKeys, defaultReturnValue: any) => {
    const findValue = filters.find((item) => item.id === filterKeyName);
    return findValue !== undefined ? findValue.value : defaultReturnValue;
  };

  const filterAccTableType = findFilterValue("bookingType", undefined);
  const fundingType = findFilterValue("fundType", undefined);
  const statusType = findFilterValue("status", undefined);

  filtersAccountTable.setOrganisationId(accTableFilters.getOrgId());
  filtersAccountTable.setAccItemTypeList(accTableStore.getAccType());
  let dateFrom = findFilterValue("bookingDateFrom", undefined);
  if (dateFrom !== undefined) {
    dateFrom = moment.tz(new Date(dateFrom).toString(), timezone()).format("MM/DD/YYYY");
  }
  filtersAccountTable.setBookingDateFrom(dateFrom);
  let dateTo = findFilterValue("bookingDateTo", undefined);
  if (dateTo !== undefined) {
    dateTo = moment.tz(new Date(dateTo).toString(), timezone()).format("MM/DD/YYYY");
  }
  filtersAccountTable.setBookingDateTo(dateTo);
  filtersAccountTable.setBookingId(findFilterValue("bookingId", ""));
  filtersAccountTable.setTypeList(
    filterAccTableType !== undefined
      ? [accTableTypeToDTO(filterAccTableType)]
      : [FiltersAccountTable.Type.UNSPECIFIED]
  );
  filtersAccountTable.setClient(findFilterValue("client", ""));
  filtersAccountTable.setDate(findFilterValue("date", undefined));
  filtersAccountTable.setFeeFrom(findFilterValue("feeFrom", undefined));
  filtersAccountTable.setFeeTo(findFilterValue("feeTo", undefined));
  filtersAccountTable.setFriendlyId(findFilterValue("friendlyId", ""));
  filtersAccountTable.setFundTypeList(
    fundingType !== undefined ? [fundTypeFilterToInternal(fundingType)] : []
  );
  filtersAccountTable.setLocationId(accTableFilters.getLocationId());
  filtersAccountTable.setProvider(findFilterValue("provider", ""));
  filtersAccountTable.setStatusList(
    statusType !== undefined ? [statusAccRowToDTO(statusType)] : []
  );
  filtersAccountTable.setLocationName(findFilterValue("locationName", ""));
  filtersAccountTable.setTimezone(timezone());

  return filtersAccountTable;
};
