import React, { Suspense } from "react";
import { connect } from "react-redux";
import { RouterProps, useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import { useAsync } from "../../hooks/useAsync";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ProtectedIframeRoute } from "../../mobile-app-iframes/ProtectedIframeRoute";
import { authService } from "../../services/AuthService";
import { ActivityIndicator } from "../../ui-kit/ActivityIndicator";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { ResetPassword } from "../dialogs/AccountDialog/ResetPassword";
import { FacebookMessenger } from "../FacebookMessanger";
import Footer from "../UserFooter";
import Dialogs from "./../../components/dialogs";
import AuthGetUser from "./../../components/elements/AuthGetUser";
import AuthRoute from "./../../components/elements/AuthRoute";
import Header from "./../../components/elements/Header";
import ResourceListFetch from "./../../components/elements/ResourceListFetch";
import EmailSignUp from "./EmailSignUp";
import FAQs from "./FAQs";
import Hubspot from "./Hubspot";
import IframeInvalidSession from "./IframeInvalidSession";
import IframeLocs from "./IframeLocs";
import IframeOrgs from "./IframeOrgs";
import { IFrameRoutes } from "./IframeRoutes";
import NotFound from "./NotFound";
import OrgCreate from "./OrgCreate";
import Physitrack from "./Physitrack";
import PrivacyPolicy from "./PrivacyPolicy";
import Provider from "./Provider";
import SetLocInUserContext from "./SetLocInUserContext";
import TermsOfUse from "./TermsOfUse";
import Tutorials from "./Tutorials";
import { UserAppointments } from "./UserAppointments";
import { UserEditAccount } from "./UserEdit";
import UserLogout from "./UserLogout";

const AdminRoutes = React.lazy(() => import("./AdminRoutes"));
const Landing = React.lazy(() => import("./Landing"));
const SearchPage = React.lazy(() => import("./Search"));
const CreateOrgPage = React.lazy(
  () => import("../../pages/create-organization/CreateOrganizationPage")
);
const ListYourBusinessPage = React.lazy(
  () => import("./../../pages/list-your-business/ListYourBusinessPage")
);

const SuspenseActivityIndicator = () => {
  return (
    <FlexBox fullHeight alignItems="center" justifyContent="between">
      <FlexItem fullWidth>
        <ActivityIndicator color="primary" />
      </FlexItem>
    </FlexBox>
  );
};

const Base: React.FC<RouterProps> = (props) => {
  const [clientToken] = useLocalStorage("clientToken");
  const history = useHistory();

  const clientTokenFetcher = useAsync(async () => {
    // Check if `ClientToken` exists. If not then generate new token
    if (!clientToken || !authService.isClientTokenValid()) {
      return await authService.generateClientToken();
    }
  }, [clientToken]);

  if (clientTokenFetcher.loading) {
    return <SuspenseActivityIndicator />;
  }

  return (
    <div>
      <AuthGetUser />
      <ResourceListFetch type="Categories" listctx={{}} noLoginReq={true} />
      <Switch>
        <Route
          path={[
            "/iframe",
            "/iframe/providers/:pid",
            "/iframe/invalid-session",
            "/iframe/users/appointments",
            "/iframe/users/edit",
            "/iframe/account/upgrade",
            "/iframe/admin/orgs/:oid/edit",
            "/iframe/admin/locs/:lid/dashboard",
            "/iframe/admin/locs/:lid/appointments",
            "/iframe/admin/locs/:lid/offerings",
            "/iframe/admin/locs/:lid/clients",
            "/iframe/admin/locs/:lid/staff",
            "/iframe/admin/locs/:lid/accounts"
          ]}
          render={() => null}
        />
        <Route component={Header} />
      </Switch>
      <Route path="/admin/locs/:lid" component={SetLocInUserContext} />
      <Suspense fallback={<SuspenseActivityIndicator />}>
        <Switch>
          {/* Landing */}
          <Route path="/iframe/invalid-session/" component={IframeInvalidSession} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route exact path={["/", "/signup"]} component={Landing} />
          <Route exact path="/listyourbusiness" component={ListYourBusinessPage} />
          <Route exact path="/search" component={SearchPage} />
          <Route exact path="/physitrack" component={Physitrack} />
          <Route exact path="/hubspot" component={Hubspot} />
          <Route exact path="/signup/:token" component={EmailSignUp} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/tutorials" component={Tutorials} />
          <Route exact path="/providers/:pid" component={Provider} />
          <Route exact path="/bookings/orgs/:oid" component={IframeOrgs} />
          <Route exact path="/bookings/locs/:lid" component={IframeLocs} />

          {/* Users */}
          <AuthRoute exact path="/users/edit" component={UserEditAccount} reqUser />

          {/* Below route server iframe pages for Lyfe mobile app & AthleteHub */}
          <ProtectedIframeRoute path="/iframe/:appName" component={IFrameRoutes} />

          <AuthRoute exact path="/users/appointments" component={UserAppointments} />
          <AuthRoute exact path="/logout" component={UserLogout} redirect="/" />

          {/* Organisations */}
          {AppConfig.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? (
            <Route exact path="/createorg" component={OrgCreate} />
          ) : (
            <Route exact path="/createorg" component={CreateOrgPage} />
          )}

          {/* Admin Routes */}
          <Route path="/admin" component={AdminRoutes} reqUser />

          {/* Not Found */}
          <Route component={NotFound} />
        </Switch>
        {!history.location.pathname.includes("iframe") && (
          <Switch>
            <Route>
              <Footer />
            </Route>
          </Switch>
        )}
      </Suspense>
      {(props.history.location.pathname === "/" ||
        props.history.location.pathname === "/listyourbusiness") && <FacebookMessenger />}
      <Dialogs history={props.history} />
    </div>
  );
};

export default connect()(Base);
