// This util will save lot of time for engineers to setup different pixel providers
// Engineers can simply configure pixels like LinkedIn or Google here and it will automatically start tracking.

import { AppConfig } from "../AppConfig";

type FormStep = "info" | "verification" | "description" | "subscription" | "payment";

const fbPixelTracking = {
  pageView: () => {
    !!window.fbq && window.fbq("track", "PageView");
  },
  event: (eventName: string, parameters?: { [key: string]: string | number }) => {
    // https://developers.facebook.com/docs/facebook-pixel/advanced/
    AppConfig.BOOKLYFE_ENVIRONMENT === "us-production" &&
      !!window.fbq &&
      window.fbq("trackCustom", eventName, parameters);
  }
};

const linkedInPixelTracking = {
  event: (conversionId: number) => {
    AppConfig.BOOKLYFE_ENVIRONMENT === "us-production" &&
      !!window.lintrk &&
      window.lintrk("track", { conversion_id: conversionId });
  }
};

const pavedPixelTracking = {
  event: (parameters?: { [k: string]: string }) => {
    AppConfig.BOOKLYFE_ENVIRONMENT === "us-production" &&
      !!window.pvd &&
      window.pvd("event", "signup", parameters);
  }
};

export const tracking = {
  clientSignUpCompleted: () => {
    fbPixelTracking.event("clicked_create_client_account_button");
    linkedInPixelTracking.event(AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.signup.client);
    pavedPixelTracking.event({ page: "user-signup" });
  },
  organizationSignUpCompleted: () => {
    fbPixelTracking.event("created_new_organization");
    linkedInPixelTracking.event(AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.signup.organization);
    pavedPixelTracking.event({ page: "organisation-signup" });
  },
  clientInterestedInRegistration: () => {
    fbPixelTracking.event("clicked_client_sign_up_button");
    linkedInPixelTracking.event(AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.interested.client);
  },
  clientInterestedInOrganizationRegistration: () => {
    fbPixelTracking.event("clicked_list_your_business_button");
    linkedInPixelTracking.event(AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.interested.organization);
  },
  organizationStepperFormCompleted: (v: FormStep) => {
    switch (v) {
      case "info":
        fbPixelTracking.event("create_organization_business_information_step", {
          step: "BusinessInformation"
        });
        linkedInPixelTracking.event(
          AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.organizationFormStepper.info
        );
        break;
      case "verification":
        fbPixelTracking.event("create_organization_verification_step", {
          step: "Verification"
        });
        linkedInPixelTracking.event(
          AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.organizationFormStepper.verification
        );
        break;
      case "description":
        fbPixelTracking.event("create_organization_description_step", {
          step: "Description"
        });
        linkedInPixelTracking.event(
          AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.organizationFormStepper.description
        );
        break;
      case "subscription":
        fbPixelTracking.event("create_organization_subscription_selection_step", {
          step: "Subscription"
        });
        linkedInPixelTracking.event(
          AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.organizationFormStepper.subscription
        );
        break;
      case "payment":
        fbPixelTracking.event("create_organization_payment_step", {
          step: "Payment"
        });
        linkedInPixelTracking.event(
          AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.organizationFormStepper.payment
        );
        break;
      default:
        break;
    }
  },
  subscriptionCardClicked: (tier: string) => {
    fbPixelTracking.event("clicked_subscription_card", {
      subscriptionTier: tier
    });
  },
  iframeOrganizationRegistrationCompleted: () => {
    fbPixelTracking.event("iframe_organization_registration_completed");
    linkedInPixelTracking.event(
      AppConfig.BOOKLYFE_LINKEDIN_CONVERSION_IDS.iframe.organizationRegistrationCompleted
    );
  }
};
