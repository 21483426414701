import React, { Component } from "react";
import styled from "@emotion/styled";
import * as fns from "date-fns";
import { connect } from "react-redux";

import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

import ResourceListFetch from "./../../../components/elements/ResourceListFetch";
import ResourceListRender from "./../../../components/elements/ResourceListRender";
import ResourceRender from "./../../../components/elements/ResourceRender";

import ButtonBase from "./../../../components/ButtonBase";

import * as ReduxDialogs from "./../../../redux/features/dialogs";

class StaffAppointments extends Component {
  bookingCmpt = React.memo(({ resource: booking, dialogId, dispatch }) => {
    const date = fns.parse(booking.StartDate, "yyyy-MM-dd", new Date());
    const stime = fns.parse(booking.StartTime, "HH:mm:ss", new Date());
    const etime = fns.parse(booking.EndTime, "HH:mm:ss", new Date());

    let services = [];
    switch (booking.Type) {
    case "Single":
      services = booking.Clients[0].Offerings.filter((v)=>v.Offering.Type==="Service");
      break;
    case "Group":
      services = booking.GroupOfferings.filter((v)=>v.Offering.Type==="Service");
      break;
    case "TimeOff":
      return null;
    }

    let offering = "No Services";
    if (services.length === 1) {
      offering = booking.Clients[0].Offerings[0].Offering.Name;
    } else if (services.length > 1) {
      offering = "Multiple Services";
    }

    return (
      <BookingButtonBase
        onClick={()=>dispatch(
          ReduxDialogs.actions.swap(
            dialogId,
            ReduxDialogs.DialogType.AppointmentDialog,
            {
              booking: booking.ID
            }
          )
        )}
      >
        <LeftContainer>
          <CalendarDate>{fns.format(date, "d")}</CalendarDate>
          <CalendarMonth>{fns.format(date, "MMM").toLocaleUpperCase()}</CalendarMonth>
        </LeftContainer>
        <Separator />
        <RightContainer>
          <NextAptTime>{booking.Type === "Single" ? offering : "Group Booking"}</NextAptTime>
          <NextAptDate>
            {booking.Type === "Single" ?
              (`${booking.Clients[0].Client.FirstName} ${booking.Clients[0].Client.LastName} - ${booking.Location.Name}`) :
              (`${booking.Clients.length} Attending - ${booking.Location.Name}`)}
          </NextAptDate>
          <RightSubtitle>{fns.format(stime, "hh:mma")} - {fns.format(etime, "hh:mma")}</RightSubtitle>
        </RightContainer>
      </BookingButtonBase>
    );
  });

  upcomingListRender = React.memo(({ list, dialogId, dispatch })=>(
    list.IDs.length === 0 ?
      <NoAppointments>
        No Upcoming Appointments
      </NoAppointments> :
      list.IDs.map((ids)=>
        <ResourceRender
          key={ids}
          type="LocBooking"
          ids={ids}
          compSuccess={this.bookingCmpt}
          // compLoading={this.loadSuccess}
          // compError={this.loadError}
          denorm={true}
          forwardedProps={{ dialogId, dispatch }}
        />
      )
  ));

  // This seems like a hack but should work fine.
  lastAppMonth = null
  pastCmpt = React.memo(({ resource, dialogId, dispatch }) => {
    const date = fns.parse(resource.start_datetime, "yyyy-MM-dd", new Date());
    const title = this.lastAppMonth !== date.getMonth();
    this.lastAppMonth = date.getMonth();
    return (
      <React.Fragment>
        {title && <Title>{fns.format(date, "MMMM yyyy")}</Title>}
        {React.createElement(this.bookingCmpt, { resource, dialogId, dispatch })}
      </React.Fragment>
    );
  });

  pastListRender = React.memo(({ list, dialogId, dispatch })=>(
    list.IDs.length > 0 && list.IDs.map((ids)=>
      <ResourceRender
        key={ids}
        type="LocBooking"
        ids={ids}
        compSuccess={this.pastCmpt}
        // compLoading={this.loadSuccess}
        // compError={this.loadError}
        denorm={true}
        forwardedProps={{ dialogId, dispatch }}
      />
    )
  ));

  render() {
    const { orgUser, dialogId, dispatch } = this.props;
    const upcomingCtx = { Org: orgUser.Org.ID, Provider: orgUser.User.ID, Selector: "Upcoming" };
    const pastCtx = { Org: orgUser.Org.ID, Provider: orgUser.User.ID, Selector: "Past" };
    return (
      <Container>
        <Title>Upcoming</Title>
        <ResourceListFetch type="ProviderAppointmentTab" listctx={upcomingCtx} force />
        <ResourceListRender
          type="ProviderAppointmentTab"
          listctx={upcomingCtx}
          compSuccess={this.upcomingListRender}
          forwardedProps={{ dialogId, dispatch }}
        />
        <Title>Past</Title>
        <ResourceListFetch type="ProviderAppointmentTab" listctx={pastCtx} force />
        <ResourceListRender
          type="ProviderAppointmentTab"
          listctx={pastCtx}
          compSuccess={this.pastListRender}
          forwardedProps={{ dialogId, dispatch }}
        />
      </Container>
    );
  }
}

export default connect()(StaffAppointments);

const BookingButtonBase = styled(ButtonBase)`
  border-radius: 4px;
  margin: 10px 0;
  background-color: ${colors.surface.light} !important;
  overflow: hidden;
  width: 100%;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
`;

const LeftContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: left;
  padding: 6px;
  justify-content: space-between;
  flex: 1 0 100px;
`;

const CalendarDate = styled.div`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.23px;
  color: ${colors.surfaceText.highEmphasis};
`;

const CalendarMonth = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.23px;
  color: ${colors.surfaceText.highEmphasis};
`;

const Separator = styled.div`
  box-sizing: border-box;
  margin-right: 20px;
  height: 50px;
  width: 1px;
  border: 1px solid rgba(44, 46, 60, 0.05);
`;

const NextAptTime = styled.div`
  ${typography.body1};
  font-weight: 600;
  letter-spacing: 0.63px;
  line-height: 20px;
  margin-bottom: 2px;
`;

const NextAptDate = styled.div`
  font-size: 12.74px;
`;

const RightSubtitle = styled.div`
  ${typography.subtitle2};
  opacity: 0.6;
  letter-spacinh: 1.26px;
`;

const Container = styled.div`
  margin: 0 auto;
  overflow-y: auto;
`;

const Title = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
`;

const NoAppointments = styled.div`
  height: 74px;
  border-radius: 4px;
  margin: 10px 0;
  background-color: ${colors.surface.light};
  overflow: hidden;
  width: 100%;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.23px;
  color: ${colors.surfaceText.highEmphasis};
`;
