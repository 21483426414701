import * as Sentry from '@sentry/browser';
import React, { Component } from "react";
import { connect } from "react-redux";

import { getResourceList, listIsFetching } from "./../../redux/features/resources/helpers";

class ResourceListRender extends Component {
  state = {
    errorCode: null
  };

  componentDidCatch(error, info) {
    if (process.env.BOOKLYFE_ENVIRONMENT.includes("production")) {
      console.warn("Unknown Internal Error", error);
      Sentry.captureException(error, { extra: info });
    } else {
      console.error(error);
    }
    this.setState({ errorCode: Sentry.lastEventId() || "unknown" });
  }

  render() {
    const { errorCode } = this.state;
    const {
      list,
      compLoading: CompLoading,
      compSuccess: CompSuccess,
      compError: CompError,
      forwardedProps
    } = this.props;

    if (errorCode) {
      return !CompError ? null : (
        <CompError list={list} errorOveride={errorCode} {...forwardedProps} />
      );
    }

    if (
      (listIsFetching(list) && list.$Metadata.DataStatus === "NoData") ||
      list.$Metadata.Actions.length === 0
    ) {
      return !CompLoading ? null : <CompLoading list={list} {...forwardedProps} />;
    } else if (list.$Metadata.DataStatus === "Error") {
      return !CompError ? null : <CompError list={list} {...forwardedProps} />;
    } else {
      return !CompSuccess ? null : <CompSuccess list={list} {...forwardedProps} />;
    }
  }
}

function mapStateToProps(state, props) {
  const { listctx, type } = props;
  const { auth, lists } = state;
  return {
    auth,
    list: getResourceList(lists, type, listctx || {})
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ResourceListRender);
