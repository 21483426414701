import classNames from "classnames";
import React from "react";

type Color =
  | "primary"
  | "secondary"
  | "warning"
  | "danger"
  | "neutral"
  | "invert"
  | "tertiary"
  | "blue";
type Size = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";

interface IProps {
  color?: Color;
  weight?: "light" | "normal" | "bold" | "semi";
  alignment?: "center" | "left" | "right" | "justify";
  italics?: boolean;
  size?: Size;
  uppercase?: boolean;
  underline?: boolean;
  children: React.ReactNode;
  noPadding?: boolean;
  truncate?: boolean;
  onClick?: () => void;
}

export const Typography = (props: IProps) => {
  return (
    <div
      className={classNames(
        getFontWeight(props.weight),
        getColorIntent(props.color),
        props.uppercase && "uppercase",
        getTextAlignment(props.alignment),
        getFontSize(props.size),
        props.italics && "italic",
        props.truncate && "truncate",
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const H1 = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-3xl sm:text-4xl",
        props.noPadding ? "p-0" : "pt-5 pb-2.5",
        getFontWeight(props.weight),
        props.color ? getColorIntent(props.color) : "text-primary-900",
        getTextAlignment(props.alignment),
        props.italics && "italic",
        props.truncate && "truncate",
        props.uppercase && "uppercase",
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const H2 = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-2xl",
        getFontWeight(props.weight),
        props.noPadding ? "p-0" : "pt-2.5 sm:pt-3.5 pb-2",
        getTextAlignment(props.alignment),
        getColorIntent(props.color),
        props.italics && "italic",
        props.truncate && "truncate",
        props.uppercase && "uppercase",
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const H3 = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-xl",
        props.noPadding ? "p-0" : "pt-2.5 pb-1.5",
        getFontWeight(props.weight),
        getTextAlignment(props.alignment),
        getColorIntent(props.color),
        props.italics && "italic",
        props.truncate && "truncate",
        props.uppercase && "uppercase",
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const H4 = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-lg",
        getFontWeight(props.weight),
        props.noPadding ? "p-0" : "pt-2.5 pb-1.5",
        props.uppercase && "uppercase",
        props.italics && "italic",
        props.truncate && "truncate",
        getTextAlignment(props.alignment),
        getColorIntent(props.color),
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const Body = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-base",
        getFontWeight(props.weight),
        props.noPadding ? "p-0" : "pt-1.5 pb-0.5",
        getTextAlignment(props.alignment),
        props.color ? getColorIntent(props.color) : "text-secondary-700",
        props.italics && "italic",
        props.truncate && "truncate",
        props.uppercase && "uppercase",
        props.underline && "underline"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

const Label = (props: IProps) => {
  return (
    <div
      className={classNames(
        "text-xs sm:text-sm",
        getFontWeight(props.weight),
        props.noPadding ? "p-0" : "py-1",
        props.italics && "italic",
        props.truncate && "truncate",
        props.uppercase && "uppercase",
        getTextAlignment(props.alignment),
        props.underline && "underline",
        props.color ? getColorIntent(props.color) : "text-secondary-700"
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

Typography.H1 = H1;
Typography.H2 = H2;
Typography.H3 = H3;
Typography.H4 = H4;
Typography.Body = Body;
Typography.Label = Label;

const getColorIntent = (type?: Color) => {
  switch (type) {
    case "primary":
      return "text-primary-900";
    case "secondary":
      return "text-secondary-700";
    case "danger":
      return "text-danger";
    case "warning":
      return "text-warning";
    case "neutral":
      return "text-white";
    case "tertiary":
      return "text-tertiary-200";
    case "invert":
      return "text-primary-100";
    case "blue":
      return "text-blue-400";
    default:
      return "text-secondary-700";
  }
};

const getFontSize = (size?: Size) => {
  switch (size) {
    case "xs":
      return "text-xs";
    case "sm":
      return "text-sm";
    case "md":
      return "text-md";
    case "lg":
      return "text-lg";
    case "xl":
      return "text-xl";
    case "2xl":
      return "text-2xl";
    case "3xl":
      return "text-3xl";
    case "4xl":
      return "text-4xl";
    default:
      return "text-lg";
  }
};

const getTextAlignment = (alignment?: IProps["alignment"]) => {
  switch (alignment) {
    case "left":
      return "text-left";
    case "right":
      return "text-right";
    case "center":
      return "text-center";
    case "justify":
      return "text-justify";
    default:
      return "";
  }
};

const getFontWeight = (weight?: IProps["weight"]) => {
  switch (weight) {
    case "bold":
      return "font-bold";
    case "semi":
      return "font-semibold";
    case "light":
      return "font-light";
    case "normal":
    default:
      return "font-normal";
  }
};
