import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useParams } from "react-router";
import { metadata, rpc } from "../../grpc";
import { GetLocationRequest, Location } from "sdk/dist/location_pb";
import { toastStore } from "../../stores/toast-store";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Paper } from "../../ui-kit/Paper";
import { Typography } from "../../ui-kit/Typography";
import ResourceFetch from "../../components/elements/ResourceFetch";
import ResourceRender from "../../components/elements/ResourceRender";
import "./index.scss";
import ProductsBlock from "../../components/routes/Provider/ProductsBlock";
import ScheduleImageBlock from "../../components/routes/Provider/ScheduleImageBlock";
import ServicesBlock from "../../components/routes/Provider/ServicesBlock";

interface IResponse {
  resource: IResource;
  location: Location.AsObject;
}

interface Day {
  ClosesAt: string;
  OpensAt: string;
}

export interface ProviderSchedule {
  Monday: Day;
  Tuesday: Day;
  Wednesday: Day;
  Thursday: Day;
  Friday: Day;
  Saturday: Day;
  Sunday: Day;
}

export interface ProviderInterface {
  User: { FirstName: string; LastName: string; ID: string; Photo: any };
  RoleProperties: { ProviderProfession: string };
  Location: { Banner: { DownloadURL: string }; ID: string };
  ProviderCategories: { Value: string }[];
  GroupTimes: any;
}

export interface ProviderOfferings {
  ServiceDuration: number;
  Name: string;
  ID: string;
  Location: string;
  Fee: number;
  Description: string;
  image: Image;
  Level: string;
}

interface Image {
  CreatedAt: string;
  Description: string;
  DownloadURL: string;
  Filename: string;
  ID: string;
  LastModifiedAt: string;
  Mime: string;
  Name: string;
  Type: string;
}

interface GroupBookings {
  CurGroupSize: number;
  EndDate: string;
  EndDateTimeTZ: string;
  EndTime: string;
  GroupOfferings: ProviderOfferings[];
  ID: string;
  Location: string;
  MaxGroupSize: number;
  Provider: string;
  StartDate: string;
  StartDateTimeTZ: string;
  StartTime: string;
  SubtotalCharge: number;
  SubtotalTax: number;
  Timezone: string;
  Type: string;
}
interface IResource {
  Address: { Raw: string };
  Banner: { DownloadURL?: string };
  Schedule: ProviderSchedule;
  Providers: ProviderInterface[];
  Phone: string;
  ID: string;
  Name: string;
  Services: ProviderOfferings[];
  Products: ProviderOfferings[];
  GroupTimes: GroupBookings[];
  Org: { ID: string; Name: string; Description: string; Banner: { DownloadURL: string } };
}

// NOTE: This page is used in mobile app with iframe
export const BookingSessionIframePage = () => {
  const [location, setLocation] = useState<Location.AsObject>();

  useEffect(() => {
    async function fetchData() {
      try {
        const req = new GetLocationRequest();
        req.setLocationId(id.pid);
        const res = await rpc.location.get(req, metadata());
        const resLoc = res.toObject().location;
        if (resLoc) {
          setLocation(resLoc);
        }
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
    fetchData();
  }, []);

  const id = useParams<{ pid: string }>();
  return (
    <div id="Provider">
      <ResourceFetch
        noLoginReq={true}
        ids={id.pid}
        type="Location"
        extraData={{
          StartDate: format(new Date(), "yyyy-MM-dd")
        }}
        force
      />
      <ResourceRender
        ids={id.pid}
        type="Location"
        compSuccess={RenderProvider}
        forwardedProps={{ location: location }}
        compLoading={() => <div>loading</div>}
        compError={() => <div>error</div>}
        denorm={true}
      />
    </div>
  );
};

const RenderProvider: React.FC<IResponse> = ({ resource, location }: IResponse) => {
  const [isFiltersVisible, setFiltersVisibility] = useState(false);
  const subjectId = useLocation().search.split("?subjectId=")[1];

  useEffect(() => {
    // Hide filters on mobile device on page load
    if (isMobile) setFiltersVisibility(false);
  }, []);

  const fwdState = useLocation().state;
  if (!resource.GroupTimes) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-wrap md:flex-row-reverse">
        <Paper color="secondary">
          <FlexBox justifyContent="center">
            <Container width="full" maxWidth="5xl" centerContainer>
              <div id="class" className="">
                <FlexBox alignItems="end" justifyContent="between">
                  <FlexItem>
                    <Typography.H1 color="primary">Make a booking</Typography.H1>
                  </FlexItem>
                  <FlexItem>
                    <Typography.Label
                      color="primary"
                      underline
                      onClick={() => setFiltersVisibility(!isFiltersVisible)}
                    >
                      {isFiltersVisible ? "Hide" : "Show"} filters
                    </Typography.Label>
                  </FlexItem>
                </FlexBox>
                {location && (
                  <ServicesBlock
                    locationId={location.id}
                    subjectId={subjectId}
                    serviceID={fwdState ? fwdState.offID : "none"}
                    isFiltersVisible={isFiltersVisible}
                  />
                )}
              </div>

              <div id="products" className="">
                <Typography.H1 color="primary">Products</Typography.H1>
                <ProductsBlock products={resource.Products} />
              </div>
              {location && location.scheduleMedia && location.scheduleMedia.id && (
                <div id="timetable" className="">
                  <Typography.H1 color="primary">Timetable</Typography.H1>
                  <ScheduleImageBlock scheduleMedia={location.scheduleMedia} />
                </div>
              )}
            </Container>
          </FlexBox>
        </Paper>
      </div>
    </div>
  );
};
