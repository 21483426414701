import React from "react";
import styled from "@emotion/styled";

// Utils
import { colors, hexToRgba } from "./../../../util/consts";

// Components
import UpdateUser from "./UpdateUser";
import VerifyURLToken from "./VerifyURLToken";
import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";

interface Props {
  history: any;
}

class EmailSignUp extends React.Component<Props> {
  render() {
    return (
      <Container id="menu">
        <FullScreenSection>
          <PagesContainer
            sidePanel={false}
            queryp="page"
            blockNotSeen={false}
            extraProps={{ historys: this.props.history }}
          >
            <PageGroup label="Set Up">
              <Page name="resend" label="resend" component={VerifyURLToken} />
              <Page name="password" label="password" component={UpdateUser} />
            </PageGroup>
          </PagesContainer>
        </FullScreenSection>
      </Container>
    );
  }
}

export default EmailSignUp;

const Container = styled.main`
  width: 100%;
`;

const FullScreenSection = styled.div`
  margin: auto;
  width: 80%;
  height: calc(100vh - 80vh);
  // min-height: 400px;
  min-height: 900px;
  margin-top: 20px;

  @media only screen and (min-width: 768px) {
    padding-top: 30px;
    margin: auto;
    width: 50%;
    height: calc(100vh - 40px);
    max-height: 900px;
    display: flex;
    border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  }
`;
