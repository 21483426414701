import React, { Component } from "react";
import { connect } from "react-redux";

// Redux
import * as ReduxDialogs from "./../../../redux/features/dialogs";

// Utils
import { breakpoints } from "./../../../util/consts";

// Custom components
import Dialog from "../Dialog";
import PagesContainer, { Page } from "./../../../components/elements/PagesContainer";
import Login from "./Login";
import SignUp from "./SignUp";
import SignUpExtra from "./SignUpExtra";
import Verify from "./Verify";
import ResetPassword from "./ResetPasswordLegacy";
import ForgotPass from "./ForgotPass";
import Change from "./Change";

import styled from "@emotion/styled";
class AccountModal extends Component {
  state = {
    isDialogClosable: true
  };

  setIsDialogClosable = (isDialogClosable) => {
    this.setState({ ...this.state, isDialogClosable: isDialogClosable });
  };

  closeDialog = () => {
    const { dispatch, dialogId, data, history } = this.props;
    // if popup (login/signup) was opened from email, redirect to landing page when closing it
    if (this.props.data.trigger === null) {
      dispatch(ReduxDialogs.actions.close(dialogId));
      history.push("/");
    }
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  render() {
    const { data } = this.props;

    return (
      <Dialog
        dialogId={this.props.dialogId}
        trigger={data.trigger}
        overideCloseDialog={this.closeDialog}
        noClose={!this.state.isDialogClosable} // noClose is a props that is for displaying the X button (user can still close the dialog by clicking on the background)
        noClickAwayClose={!this.state.isDialogClosable} // noClickAwayClose is a props for closing the dialog by clicking on the background
      >
        <Container>
          <PagesContainer
            extraProps={{ closeModal: this.closeDialog }}
            initialPage={data.page}
            initialData={data.data}
            setIsDialogClosable={this.setIsDialogClosable}
          >
            <Page name="login" component={Login} />
            <Page name="signup" component={SignUp} history={this.props.history} />
            <Page name="verify" component={Verify} />
            <Page name="change" component={Change} />
            <Page name="forgotpass" component={ForgotPass} />
            <Page name="signupextra" component={SignUpExtra} />
            <Page name="resetpasswd" component={ResetPassword} />
          </PagesContainer>
        </Container>
      </Dialog>
    );
  }
}

// offerID

export default connect()(AccountModal);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    max-width: 600px;
    padding: 64px 116px;
  }
`;
