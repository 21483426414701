import React, { Component } from "react";
import styled from "@emotion/styled";
import * as fns from "date-fns";

import { colors } from "./../../../util/consts";

import DropdownSelect from "./../../../components/DropdownSelect";
import {
  AccountItem,
  RetryAccountItemRequest,
  RetryInvGenRequest
} from "sdk/dist/account_items_pb";

import {
  AccordianContainer,
  AccordianEdit,
  Separator,
  OptionsContainer,
  ButtonHead,
  ButtonDetails,
  NoSpacingIconButton
} from "./../../../components/elements/AccordianElements";

import Download from "./../../../components/icons/Download";
import LoadingIcon from "./../../../components/icons/Loading";
import Options from "./../../../components/icons/Options";
import { aiStatusToText } from "./../../../util/account-items";
import { moneyToText } from "./../../../util/money";
import { toDateTz } from "./../../../util/timestamp";
import { rpc, metadata } from "./../../../grpc";
import { accItemStore } from "./../../../stores/acc_item-store";
import { bookingStore } from "./../../../stores/booking-store";
import { toastStore } from "./../../../stores/toast-store";

interface Props {
  invoice: AccountItem.AsObject;
  dispatch?: any;
}

export class ClientInvoice extends Component<Props> {
  state = {
    isLoading: false
  };
  retryInvoice = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    try {
      const req = new RetryAccountItemRequest();
      req.setAccountItemId(invoice.id);
      const res = await rpc.accountItems.retryPayment(req, metadata());
      accItemStore.add(res.toObject());
      bookingStore.add(res.toObject().booking!);
      toastStore.success("Re-submitted invoice for payment.");
      this.setState({ isLoading: false });
    } catch (err) {
      toastStore.grpcError(err);
      this.setState({ isLoading: false });
    }
  };

  download = async () => {
    const { invoice } = this.props;
    this.setState({ isLoading: true });
    if (invoice.invDownloadUrl) {
      window.open(invoice.invDownloadUrl, "_blank");
    } else {
      try {
        const req = new RetryInvGenRequest();
        req.setAccountItemId(invoice.id);
        await rpc.accountItems.retryInvGeneration(req, metadata());
        await setTimeout(async () => {
          await accItemStore.loadByBookingClient(invoice.booking!.id, invoice.clientId);
          accItemStore
            .all()
            .filter(
              (inv) =>
                inv.fundType == invoice.fundType &&
                inv.status == AccountItem.Status.ACC_ITEM_COMPLETED &&
                inv.booking!.id == invoice.booking!.id &&
                inv.clientId == invoice.clientId
            )
            .map((inv) => window.open(inv.invDownloadUrl, "_blank"));
        }, 3000);
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
    this.setState({ isLoading: false });
  };

  errorOptions = [
    {
      label: "Retry",
      onClick: this.retryInvoice
    }
  ];
  renderErrorContent() {
    const { invoice } = this.props;
    return (
      <Table>
        <tbody>
          <Row>
            <ColH>Error Code: </ColH>
            <Col>{invoice.statusCode}</Col>
          </Row>
          <Row>
            <ColH>Error Message: </ColH>
            <Col>{invoice.statusMessage}</Col>
          </Row>
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading } = this.state;
    const { invoice } = this.props;
    return (
      <AccordianContainer maySelect={invoice.status === AccountItem.Status.ACC_ITEM_ERROR}>
        <AccordianEdit>
          <ButtonHead style={{ flex: "0 0 50px" }}>#{invoice.invoiceId}</ButtonHead>
          <Separator />
          <ButtonDetails>
            {toDateTz(invoice.lastModifiedAt!).format("h:mmA DD/MM/yyyy")}
          </ButtonDetails>
          <Separator />
          <ButtonDetails>{moneyToText(invoice.total!.amount!, false)}</ButtonDetails>
          <Separator />
          <ButtonDetails>{aiStatusToText(invoice.status)}</ButtonDetails>
          {invoice.status === AccountItem.Status.ACC_ITEM_COMPLETED && (
            <OptionsContainer>
              <NoSpacingIconButton onClick={this.download}>
                {isLoading ? (
                  <LoadingIcon width={24} height={24} color="#2C2E3C" style={{ opacity: 0.3 }} />
                ) : (
                  <Download />
                )}
              </NoSpacingIconButton>
            </OptionsContainer>
          )}
          {invoice.status === AccountItem.Status.ACC_ITEM_ERROR && (
            <DropdownSelect options={!isLoading && this.errorOptions}>
              <NoSpacingIconButton>
                {isLoading ? (
                  <LoadingIcon width={24} height={24} color="#2C2E3C" style={{ opacity: 0.3 }} />
                ) : (
                  <Options />
                )}
              </NoSpacingIconButton>
            </DropdownSelect>
          )}
        </AccordianEdit>
        {invoice.status === AccountItem.Status.ACC_ITEM_ERROR && this.renderErrorContent()}
      </AccordianContainer>
    );
  }
}

const Container = styled.div`
  margin: 0 auto;
  overflow-y: auto;
`;

const Title = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
`;

const NoInvoices = styled.div`
  height: 74px;
  border-radius: 4px;
  margin: 10px 0;
  background-color: ${colors.surface.light};
  overflow: hidden;
  width: 100%;
  padding: 8px 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.23px;
  color: ${colors.surfaceText.highEmphasis};
`;

const Table = styled.table`
  padding: 20px 40px;
`;

const Row = styled.tr``;

const ColH = styled.td`
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 25px;
  padding-top: 5px;
`;

const Col = styled.td`
  color: ${colors.blackText.highEmphasis};
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-top: 5px;
`;
