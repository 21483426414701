import { Dialog } from "@material-ui/core";
import * as grpcWeb from "grpc-web";
import * as queryString from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { userService } from "../../../services/UserService";
import { toastStore } from "../../../stores/toast-store";
import { AsyncButton } from "../../../ui-kit/AsyncButton";
import { Container } from "../../../ui-kit/Container";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Input from "./../../../components/form/Input";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";

export const ResetPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const params = queryString.parse(window.location.search, { decode: false });
      if (!params.token || !params.subject) {
        toastStore.error("Invalid url");
        return;
      }
      await userService.resetPassword(params.subject as string, params.token as string, password);
      toastStore.success("Your password has been changed");
      history.push("/");
    } catch (err: grpcWeb.Error) {
      toastStore.grpcError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open fullWidth>
      <Container spacing={[2, 4]} md={[14, 10]} lg={[16, 10]} type="block">
        <Form onSubmit={handleSubmit}>
          <FormHeader title="Reset your password" subtitle="Enter your new password" />
          <SectionsContainer>
            <Section>
              <Input
                id="newPassword"
                placeholder="new password"
                type="password"
                name="newPassword"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
                value={password}
              />
            </Section>
          </SectionsContainer>
          <FooterContainer style={{ margin: "auto" }}>
            <AsyncButton
              variant="primary"
              onClick={() => undefined}
              isDisabled={loading}
              size="lg"
              type="submit"
              rounded="full"
            >
              Set Password
            </AsyncButton>
          </FooterContainer>

          <FooterContainer style={{ marginTop: 20, fontSize: 12 }}>
            <AsyncButton.Action onClick={() => history.push("/")} size="md">
              Home
            </AsyncButton.Action>
          </FooterContainer>
        </Form>
      </Container>
    </Dialog>
  );
};
