import React, { Component } from "react";

import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";

import General from "./General";
import Market from "./Market";
import Sched from "./Sched";
import IFrame from "./IFrame";
import Fund_AUClaiming from "./Fund_AUClaiming";

import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";

import ResourcePageError from "./../../../components/elements/ResourcePageError";

export default class LocEditAccount extends Component {
  renderContent = React.memo(({ resource: loc }) => (
    <PagesContainer key={loc.ID} sidePanel={true} queryp="page" extraProps={{ loc }}>
      <PageGroup label="Basics">
        <Page name="general" label="General" component={General} />
        <Page name="market" label="Marketing" component={Market} />
        <Page name="sched" label="Schedule" component={Sched} />
        <Page name="iframe" label="IFrame" component={IFrame} />
      </PageGroup>
      {
        process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== 'US' ? (
          <PageGroup label="Funds">
            <Page name="aufundclaiming" label="Medicare/DVA" component={Fund_AUClaiming} />
          </PageGroup>
        ) : ''
      }
    </PagesContainer>
  ));

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <ResourceFetch type="Location" ids={{ ID: match.params.lid }} extraData={{ Context: "Admin" }} force />
        <ResourceRender
          type="Location"
          ids={{ ID: match.params.lid }}
          denorm={true}
          compSuccess={this.renderContent}
          compError={ResourcePageError}
        />
      </React.Fragment>
    );
  }
}
