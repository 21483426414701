import classNames from "classnames";
import React from "react";

type Spacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40 | 64;

interface IProps {
  children: React.ReactNode;
  direction?: "row" | "col" | "row-reverse" | "col-reverse";
  wrap?: "wrap" | "reverse" | "noWrap";
  alignItems?: "start" | "end" | "center";
  justifyContent?: "start" | "end" | "center" | "between" | "around" | "evenly";
  spacing?: [Spacing, Spacing];
  fullHeight?: boolean;
}

export const FlexBox = (props: IProps) => {
  return (
    <div
      className={classNames(
        "flex",
        getFlexDirection(props.direction),
        getFlexWrap(props.wrap),
        getBoxItemsAlignment(props.alignItems),
        computeItemsJustification(props.justifyContent),
        props.fullHeight && "h-full",
        props.spacing ? `space-x-${props.spacing[0]} space-y-${props.spacing[1]}` : "",
        "w-full"
      )}
    >
      {props.children}
    </div>
  );
};

interface IFlexItem {
  type?: "flex-1" | "auto" | "initial" | "none";
  grow?: boolean;
  fullWidth?: boolean;
  alignSelf?: "auto" | "start" | "end" | "center" | "stretch";
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const FlexItem = (props: IFlexItem) => {
  return (
    <div
      className={classNames(
        getFlexType(props.type),
        getFlexGrow(props.grow),
        computeAlignSelf(props.alignSelf),
        props.fullWidth ? "w-full" : "",
        props.fullHeight ? "h-full" : ""
      )}
    >
      {props.children}
    </div>
  );
};

const getFlexDirection = (direction: IProps["direction"]) => {
  switch (direction) {
    case "col":
      return "flex-col";
    case "col-reverse":
      return "flex-col-reverse";
    case "row-reverse":
      return "flex-row-reverse";
    case "row":
    default:
      return "flex-row";
  }
};

const getFlexType = (type: IFlexItem["type"]) => {
  switch (type) {
    case "flex-1":
      return "flex-1";
    case "auto":
      return "flex-auto";
    case "initial":
      return "flex-initial";
    case "none":
      return "flex-initial";
    default:
      return "";
  }
};

const getFlexWrap = (wrap?: IProps["wrap"]) => {
  switch (wrap) {
    case "wrap":
      return "flex-wrap";
    case "noWrap":
      return "flex-nowrap";
    case "reverse":
      return "flex-wrap-reverse";
    default:
      return "";
  }
};

const getFlexGrow = (grow?: IFlexItem["grow"]) => {
  if (grow === undefined || grow === null) return "";
  else if (grow) return "flex-grow";
  else if (!grow) return "flex-grow-0";
};

const getBoxItemsAlignment = (align: IProps["alignItems"]) => {
  switch (align) {
    case "start":
      return "items-start";
    case "end":
      return "items-end";
    case "center":
      return "items-center";
    default:
      return "";
  }
};

const computeAlignSelf = (align: IFlexItem["alignSelf"]) => {
  switch (align) {
    case "start":
      return "self-start";
    case "end":
      return "self-end";
    case "center":
      return "self-center";
    case "auto":
      return "self-auto";
    case "stretch":
      return "self-stretch";
    default:
      return "";
  }
};

const computeItemsJustification = (align: IProps["justifyContent"]) => {
  switch (align) {
    case "start":
      return "justify-start";
    case "end":
      return "justify-end";
    case "center":
      return "justify-center";
    case "around":
      return "justify-around";
    case "between":
      return "justify-between";
    case "evenly":
      return "justify-evenly";
    default:
      return "";
  }
};
