import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";
import { UserUIType } from "sdk/dist/location_user_pb";
import { toastStore } from "../../../stores/toast-store";
import { IFilter } from "./../../../components/routes/InsightDashboard/CustomTypes";
import { dataItemType } from "./../../../components/routes/InsightDashboard/DataArea";
import DataTable from "./../../../components/routes/InsightDashboard/DataTable";
import FilterForm from "./../../../components/routes/InsightDashboard/FilterForm";
import Tab from "./../../../components/routes/InsightDashboard/Tab";
import { metadata, rpc } from "./../../../grpc";
import { sharedInsightDataStore } from "./../../../mobx/stores/InsightDataStore";
import { GetClientTableRequest } from "sdk/dist/client_table_pb";
import { GetInsightClientDataRequest } from "sdk/dist/insight_client_pb";
import { GetInsightMetricsRequest } from "sdk/dist/insight_metrics_pb";
import { GetInsightReferralRequest } from "sdk/dist/insight_referral_pb";
import { GetInsightRevenueRequest } from "sdk/dist/insight_revenue_pb";
import { GetReferralTableRequest } from "sdk/dist/referral_table_pb";
import { GetRevenueTableRequest } from "sdk/dist/revenue_table_pb";
import { breakpoints } from "./../../../util/consts";
import { dateToTimestamp, setMidnightOfDate } from "./../../../util/time";
import DataArea from "./DataArea";

const tabs =
  localStorage.getItem("UserUIType") != String(UserUIType.FITNESS)
    ? ["metrics", "revenue", "clients", "referrals"]
    : ["metrics", "revenue", "clients"];

interface Props {
  // this props is to match what is passed from AuthRoute
  match: {
    params: {
      oid: string;
      lid: string;
    };
  };
}

@observer
class InsightDashboard extends Component<Props> {
  @observable
  private tab: string = tabs[0];
  @observable
  filterVisible: boolean = true;
  @observable
  filter: IFilter = {
    dateStart: new Date(),
    dateEnd: new Date(),
    locationID: "",
    providerID: "",
    state: "",
    serviceID: "",
    tagID: ""
  };

  mapDataToTab(selectedTab: string): Array<dataItemType> {
    if (selectedTab === tabs[0]) {
      return sharedInsightDataStore.allMetricsItem;
    } else if (selectedTab === tabs[1]) {
      return sharedInsightDataStore.allRevenueItems;
    } else if (selectedTab == tabs[2]) {
      return sharedInsightDataStore.allClientDataItems;
    } else if (selectedTab == tabs[3]) {
      return sharedInsightDataStore.allReferralDataItems;
    }
    return [];
  }

  mapTableDataToTab(selectTab: string) {
    if (selectTab === tabs[1]) {
      return sharedInsightDataStore.revenueTableRes.toObject().revenuerowsList;
    } else if (selectTab === tabs[2]) {
      return sharedInsightDataStore.clientTableRes.toObject().clientrowsList;
    } else if (selectTab === tabs[3]) {
      return sharedInsightDataStore.referralTableRes.toObject().referralrowsList;
    }
    return [];
  }

  updateFilter(newFilter: Partial<IFilter>) {
    this.filter = { ...this.filter, ...newFilter };
  }

  updateStartDateFilter(startDate: Date) {
    this.filter = { ...this.filter, ...{ dateStart: startDate } };
  }

  updateEndDateFilter(endDate: Date) {
    this.filter = { ...this.filter, ...{ dateEnd: endDate } };
  }

  setRequestParams(
    req:
      | GetInsightMetricsRequest
      | GetRevenueTableRequest
      | GetInsightRevenueRequest
      | GetInsightClientDataRequest
      | GetClientTableRequest
      | GetInsightReferralRequest
      | GetReferralTableRequest,
    filterParam: IFilter,
    orgID: string
  ) {
    req.setOrganisationId(orgID);
    req.setFrom(dateToTimestamp(filterParam.dateStart));
    req.setTo(dateToTimestamp(filterParam.dateEnd));
    req.setLocationId(filterParam.locationID);
    req.setServiceId(filterParam.serviceID);
    req.setState(filterParam.state);
    req.setProviderId(filterParam.providerID);
    req.setTagId(filterParam.tagID);
  }

  async getInsightData(filterParam: IFilter, orgID: string, selectedTab: string) {
    // set start date to midnight
    setMidnightOfDate(filterParam.dateStart);
    if (selectedTab == "metrics") {
      const req = new GetInsightMetricsRequest();
      this.setRequestParams(req, filterParam, orgID);
      try {
        const res = await rpc.insightMetrics.get(req, metadata());
        sharedInsightDataStore.metricsRes = res;
      } catch (err) {
        toastStore.grpcError(err);
      }
    } else if (selectedTab == "revenue") {
      // get the table data
      const reqTable = new GetRevenueTableRequest();
      this.setRequestParams(reqTable, filterParam, orgID);
      try {
        const resTable = await rpc.revenueTable.get(reqTable, metadata());
        sharedInsightDataStore.revenueTableRes = resTable;
      } catch (err) {
        toastStore.grpcError(err);
      }
      // get the statistic
      const req = new GetInsightRevenueRequest();
      this.setRequestParams(req, filterParam, orgID);
      try {
        const res = await rpc.insightRevenue.get(req, metadata());
        sharedInsightDataStore.revenueRes = res;
      } catch (err) {
        toastStore.grpcError(err);
      }
    } else if (selectedTab == "clients") {
      // get the statics
      const req = new GetInsightClientDataRequest();
      this.setRequestParams(req, filterParam, orgID);
      try {
        const res = await rpc.insightClient.get(req, metadata());
        sharedInsightDataStore.clientRes = res;
      } catch (err) {
        toastStore.grpcError(err);
      }

      // get the table data
      const reqTable = new GetClientTableRequest();
      this.setRequestParams(reqTable, filterParam, orgID);
      try {
        const resTable = await rpc.clientTable.get(req, metadata());
        sharedInsightDataStore.clientTableRes = resTable;
      } catch (err) {
        toastStore.grpcError(err);
      }
    } else if (selectedTab == "referrals") {
      const req = new GetInsightReferralRequest();
      this.setRequestParams(req, filterParam, orgID);
      try {
        const res = await rpc.insightReferral.get(req, metadata());
        sharedInsightDataStore.referralRes = res;
      } catch (err) {
        toastStore.grpcError(err);
      }

      // get the table data
      const reqTable = new GetReferralTableRequest();
      this.setRequestParams(reqTable, filterParam, orgID);
      try {
        const resTable = await rpc.referralTable.get(reqTable, metadata());
        sharedInsightDataStore.referralTableRes = resTable;
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
  }

  render() {
    const { match } = this.props;
    const orgID = match.params.oid;
    let chevronCollapsed = {
      transform: "rotate(90deg)",
      position: "absolute",
      right: ".5rem",
      top: ".75rem"
    };
    let chevronExpanded = {
      transform: "rotate(180deg)",
      position: "absolute",
      right: ".5rem",
      top: ".75rem"
    };
    return (
      <Container>
        <Content>
          <Header>
            <HeaderContent>Insights</HeaderContent>
          </Header>
          <FilterArea>
            <div
              onClick={() => {
                this.filterVisible = !this.filterVisible;
              }}
            >
              <span css={H2Style}>Filters</span>
              <RightDiv>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  style={
                    this.filterVisible
                      ? {
                          transform: "rotate(90deg)",
                          position: "absolute",
                          transition: "transform 0.2s"
                        }
                      : { transform: "rotate(270deg)", position: "absolute" }
                  }
                >
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                </svg>
              </RightDiv>
            </div>
            <div>
              {this.filterVisible ? (
                <FilterForm
                  organisationID={orgID}
                  selectedTab={this.tab}
                  iFilter={this.filter}
                  updateFilter={(newFilter) => this.updateFilter(newFilter)}
                  updateStartDateFilter={(newFilter) => this.updateStartDateFilter(newFilter)}
                  updateEndDateFilter={(newFilter) => this.updateEndDateFilter(newFilter)}
                  getInsightData={() => this.getInsightData(this.filter, orgID, this.tab)}
                />
              ) : (
                <div></div>
              )}
            </div>
          </FilterArea>
          <Tab
            selectedTab={this.tab}
            tabs={tabs}
            onTabPress={(newTab) => {
              this.tab = newTab;
              this.getInsightData(this.filter, orgID, this.tab);
            }}
          />
          <DataArea items={this.mapDataToTab(this.tab)}></DataArea>
          <DataTable tab={this.tab} tableData={this.mapTableDataToTab(this.tab)}></DataTable>
        </Content>
      </Container>
    );
  }
}

export default InsightDashboard;

// h2 style
const H2Style = css`
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: upperCase;
  font-size: 11px;
  line-height: 1.3;
`;

// CSS for dashboard
const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  min-height: calc(100vh - 69px);
  background-color: #f5f5f5;
`;

const RightDiv = styled.div`
  float: right;
  margin-right: 2rem;
  margin-left: auto;
  position: relative;
`;

// CSS for Main Content
const Content = styled.div`
  position: static;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 18px;

  ${breakpoints["tablet-up"]} {
    padding: 36px 24px;
  }

  ${breakpoints["laptop-up"]} {
    padding: 50px 36px;
  }
`;

// CSS for the header
const Header = styled.div`
  display: block;
`;

const HeaderContent = styled.div`
  display: inline-block;
  margin-bottom: 24px;
  font-size: 47px;
  font-weight: 900;
  line-height: 1;
`;

const FilterArea = styled.div`
  content: "";
  display: table;
  table-layout: fixed;
  padding: 1rem;
  margin-bottom: 2rem;
  background: #efefef;
  width: 100%;
`;
