import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../components/form/Form";
import { IOrganization } from "../../pages/create-organization/form/IOrganization";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { FormContainer } from "../../ui-kit/form/FormContainer";
import { TimePicker } from "../../ui-kit/form/Timepicker";
import { Stack } from "../../ui-kit/Stack";
import { Typography } from "../../ui-kit/Typography";

interface IProps {
  organization: Partial<IOrganization>;
  setOrgDetails: (o: Partial<IOrganization>) => void;
  nextStep: () => void;
  previousStep: () => void;
}

export const BusinessOpeningHours = (props: IProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    setValue,
    watch
  } = useForm<IOrganization>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      Schedule: {
        Monday: { OpensAt: "09:00:00", ClosesAt: "18:00:00" },
        Tuesday: { OpensAt: "09:00:00", ClosesAt: "18:00:00" },
        Wednesday: { OpensAt: "09:00:00", ClosesAt: "18:00:00" },
        Thursday: { OpensAt: "09:00:00", ClosesAt: "18:00:00" },
        Friday: { OpensAt: "09:00:00", ClosesAt: "18:00:00" },
        Saturday: { OpensAt: undefined, ClosesAt: undefined },
        Sunday: { OpensAt: undefined, ClosesAt: undefined }
      }
    }
  });

  const onSubmit = (data: IOrganization) => {
    props.setOrgDetails({ ...props.organization, ...data });
    props.nextStep();
  };

  useEffect(() => {
    watch();
  }, [watch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer
        label="Business Opening Hours"
        description="This will be part of your profile when people search for you."
      >
        <FlexBox spacing={[0, 1]} direction="col" alignItems="center">
          <FlexItem fullWidth>
            <Typography.Body color="primary" noPadding>
              Set Weekly Schedule
            </Typography.Body>
            <Typography.Label color="secondary" noPadding>
              Please fill out the opening times for your business.
            </Typography.Label>
            <Typography.Label>Business Schedule</Typography.Label>
          </FlexItem>
          <FlexItem fullWidth>
            <Stack
              xs={{ direction: "row", span: 1 }}
              md={{ direction: "col", span: 3 }}
              direction="row"
              gap={[5, 0]}
              span={1}
            >
              <Typography.Body>Monday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Monday.OpensAt", v || "")}
                value={getValues("Schedule.Monday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Monday.ClosesAt", v || "")}
                value={getValues("Schedule.Monday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Tuesday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Tuesday.OpensAt", v || "")}
                value={getValues("Schedule.Tuesday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Tuesday.ClosesAt", v || "")}
                value={getValues("Schedule.Tuesday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Wednesday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Wednesday.OpensAt", v || "")}
                value={getValues("Schedule.Wednesday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Wednesday.ClosesAt", v || "")}
                value={getValues("Schedule.Wednesday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Thursday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Thursday.OpensAt", v || "")}
                value={getValues("Schedule.Thursday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Thursday.ClosesAt", v || "")}
                value={getValues("Schedule.Thursday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Friday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Friday.OpensAt", v || "")}
                value={getValues("Schedule.Friday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Friday.ClosesAt", v || "")}
                value={getValues("Schedule.Friday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Saturday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Saturday.OpensAt", v || "")}
                value={getValues("Schedule.Saturday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Saturday.ClosesAt", v || "")}
                value={getValues("Schedule.Saturday.ClosesAt")}
                label="Closes At"
              />

              <Typography.Body>Sunday</Typography.Body>
              <TimePicker
                onChange={(v) => setValue("Schedule.Sunday.OpensAt", v || "")}
                value={getValues("Schedule.Sunday.OpensAt")}
                label="Opens At"
              />
              <TimePicker
                onChange={(v) => setValue("Schedule.Sunday.ClosesAt", v || "")}
                value={getValues("Schedule.Sunday.ClosesAt")}
                label="Closes At"
              />
            </Stack>
          </FlexItem>
        </FlexBox>

        <FlexBox direction="row" justifyContent="between">
          <FlexItem>
            <AsyncButton
              onClick={props.previousStep}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Previous
            </AsyncButton>
          </FlexItem>

          <FlexItem>
            <AsyncButton
              onClick={() => undefined}
              isDisabled={isSubmitting}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Next
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </FormContainer>
    </Form>
  );
};
