import qs from "qs";

// Check if url path have key value
// queryObJ: {} -> example history.location.search or useLocation().search
export const searchQueryString = (searchQuery: string, keyName: string) => {
  const obj = searchQuery ? searchQuery.replace("?", "") : {};
  const parsedObj = qs.parse(String(obj));

  if (Object.prototype.hasOwnProperty.call(parsedObj, keyName)) {
    return parsedObj[keyName];
  }
  return "";
};
