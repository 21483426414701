import { ReduxTypes } from "ReduxTypes";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userStore } from "../../mobx/stores/UserStore";
import * as ReduxDialogs from "../../redux/features/dialogs";
import { APITokenType } from "../../redux/services/api";
import { Booking } from "sdk/dist/bookings_pb";
import { BookingSearchResponse } from "sdk/dist/location_pb";
import { LocationUserDTO, ProviderOffering } from "sdk/dist/location_user_pb";
import { sharedBookingService } from "../../services/BookingService";
import { toastStore } from "../../stores/toast-store";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Stack } from "../../ui-kit/Stack";
import { Typography } from "../../ui-kit/Typography";
import { durationToText } from "../../util/duration";
import { add, moneyToText } from "../../util/money";
import { toDateTz } from "../../util/timestamp";
import Section from "../form/Section";
import Dialog from "./Dialog";

export interface BookServiceDialogProps {
  data: {
    booking: Booking.AsObject;
    service: ProviderOffering.AsObject;
    provider: LocationUserDTO.AsObject;
    searchResults: BookingSearchResponse.AsObject;
    cb?: () => void;
    fetchData: () => Promise<void>;
  };
  dialogId?: any;
}

export const BookServiceDialog: React.FC<BookServiceDialogProps> = observer((props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const auth = useSelector((state: ReduxTypes.RootState) => state.auth);

  const closeDialog = () => {
    if (!isLoading) {
      dispatch(ReduxDialogs.actions.close(props.dialogId));
    }
  };

  const processBooking = async (bookingId: string) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const res = await sharedBookingService.create(bookingId);
      setBookingConfirmed(true);
      toastStore.success(
        `Booking no ${
          res.booking!.friendlyId
        } successfully created, you"ll receive an email soon with the booking details`
      );
    } catch (err) {
      toastStore.grpcError(err);
    } finally {
      setIsLoading(false);
    }
    props.data.fetchData();
  };

  const getStartDate = () => {
    return props.data.booking.startDatetime
      ? toDateTz(props.data.booking.startDatetime).format("DD/MM/YYYY")
      : "";
  };

  const getStartTime = () => {
    return props.data.booking.startDatetime
      ? toDateTz(props.data.booking.startDatetime).format("HH:mma")
      : "";
  };

  const getEndTime = () => {
    return props.data.booking.endDatetime
      ? toDateTz(props.data.booking.endDatetime).format("HH:mma")
      : "";
  };

  const getTotal = () => {
    let total = "";
    if (
      props.data.booking.total &&
      props.data.booking.total!.amount &&
      props.data.booking.total!.amount!.currencyCode &&
      props.data.booking.total!.tax &&
      props.data.booking.total!.amount
    ) {
      let totalCur = add(
        props.data.booking.total!.amount!.currencyCode,
        props.data.booking.total!.tax,
        props.data.booking.total!.amount
      );
      total = moneyToText(totalCur, true);
    }
    return total;
  };

  return (
    <Dialog dialogId={props.dialogId} overideCloseDialog={closeDialog} noClose>
      <Container>
        {props.data.booking && (
          <Section>
            <Typography.H1 noPadding>
              {!bookingConfirmed ? "Do you want to book this appointment?" : "Booking Confirmed"}
            </Typography.H1>
            <Typography.H3>To</Typography.H3>
            <Typography.Body noPadding>
              Name:{" "}
              {userStore.isLoggedIn() && userStore.user !== undefined
                ? `${userStore.user.firstName || ""} ${userStore.user.lastName || ""}`
                : `Unspecified`}
            </Typography.Body>
            <Typography.Body noPadding>
              Email:{" "}
              {userStore.isLoggedIn() && userStore.user !== undefined
                ? userStore.user.email
                : `Unspecified`}
            </Typography.Body>

            <Typography.H3>Provider</Typography.H3>
            <Typography.Body noPadding>{`Name: ${
              props.data.provider.firstName + " " + props.data.provider.lastName
            }`}</Typography.Body>
            <Typography.Body
              noPadding
            >{`Address: ${props.data.searchResults.address}`}</Typography.Body>
            <Typography.H3>Booking</Typography.H3>
            {props.data.booking.friendlyId.length > 0 && (
              <Typography.Body
                noPadding
              >{`Booking No: ${props.data.booking.friendlyId}`}</Typography.Body>
            )}
            <Typography.Body noPadding>Date: {getStartDate()}</Typography.Body>
            <Typography.Body noPadding>Start Time: {getStartTime()}</Typography.Body>
            <Typography.Body noPadding>End Time: {getEndTime()}</Typography.Body>
            <Typography.Body noPadding>
              Duration: {durationToText(props.data.booking.duration)}
            </Typography.Body>
            <Typography.H3>Services</Typography.H3>
            <table className="table-fixed text-right">
              <thead>
                <tr>
                  <th className="w-4/12 text-left">Name</th>
                  <th className="w-1/12">Amount</th>
                  <th className="w-2/12 text-center">Tax</th>
                  <th className="w-3/12">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.data.booking.offeringsList.length > 0 &&
                  props.data.booking.offeringsList.map((offering, key) => {
                    let amount, tax;
                    if (
                      offering.lineItem &&
                      offering.lineItem!.offering &&
                      offering.lineItem!.offering!.fee
                    ) {
                      amount = offering.lineItem!.offering!.fee!.amount;
                      tax = offering.lineItem!.offering!.fee!.tax;
                    }
                    return (
                      <tr key={key}>
                        <td className="text-left">{`${offering.lineItem!.offering!.name}`}</td>
                        <td>{amount ? moneyToText(amount, false) : ""}</td>
                        <td>{tax ? moneyToText(tax, false) : ""}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                <tr className="border-t-2">
                  <td className="text-left">Total</td>
                  <td>
                    {props.data.booking.total && props.data.booking.total!.amount
                      ? moneyToText(props.data.booking.total!.amount, false)
                      : ""}
                  </td>
                  <td>
                    {props.data.booking.total && props.data.booking.total!.tax
                      ? moneyToText(props.data.booking.total!.tax, false)
                      : ""}
                  </td>
                  {<td>{getTotal()}</td>}
                </tr>
              </tbody>
            </table>
            <br />
            <Typography.Label weight="light" italics>
              *Charges only apply at the completion of the booking
            </Typography.Label>
          </Section>
        )}
        {auth!.Type === APITokenType.Client && (
          <Typography.Label color="danger" weight="light" italics>
            *You need to login/signup to be able to join this class
          </Typography.Label>
        )}

        {!bookingConfirmed ? (
          <Stack direction="col" gap={[3, 3]} span={2}>
            <Stack.Item direction="col" span={2}>
              <AsyncButton.Action
                onClick={closeDialog}
                isDisabled={isLoading}
                rounded="full"
                size="md"
                fullWidth
              >
                CANCEL
              </AsyncButton.Action>
            </Stack.Item>
            {auth !== undefined && auth.Type.toLowerCase() === "client" && (
              <>
                <Stack.Item direction="col" span={1}>
                  <AsyncButton.PrimaryGradient
                    onClick={() =>
                      dispatch(
                        ReduxDialogs.openAccount(
                          ref.current,
                          "login",
                          { disableRedirection: true },
                          () => {}
                        )
                      )
                    }
                    rounded="full"
                    size="md"
                    fullWidth
                  >
                    LOGIN
                  </AsyncButton.PrimaryGradient>
                </Stack.Item>
                <Stack.Item direction="col" span={1}>
                  <AsyncButton.PrimaryGradient
                    onClick={() =>
                      dispatch(
                        ReduxDialogs.openAccount(
                          ref.current,
                          "signup",
                          { disableRedirection: true },
                          () => {}
                        )
                      )
                    }
                    rounded="full"
                    size="md"
                    fullWidth
                  >
                    SIGN UP
                  </AsyncButton.PrimaryGradient>
                </Stack.Item>
              </>
            )}
            <Stack.Item direction="col" span={2}>
              <AsyncButton.PrimaryGradient
                onClick={() => processBooking(props.data.booking.id)}
                isDisabled={auth!.Type === APITokenType.Client}
                rounded="full"
                size="md"
                fullWidth
              >
                BOOK
              </AsyncButton.PrimaryGradient>
            </Stack.Item>
          </Stack>
        ) : (
          <FlexBox direction="col" spacing={[0, 3]}>
            <FlexItem>
              <AsyncButton.Action
                onClick={closeDialog}
                isDisabled={auth!.Type === APITokenType.Client}
                rounded="full"
                size="md"
                fullWidth
              >
                CLOSE
              </AsyncButton.Action>
            </FlexItem>
          </FlexBox>
        )}
      </Container>
    </Dialog>
  );
});
