import { observable } from "mobx";
import { Tags, ClientTags } from "sdk/dist/tag_pb";

class TagDataStore {
  @observable
  private _organisationID: string = "";
  @observable
  private _clientID: string = "";
  @observable
  private _selectedTags = new ClientTags().toObject();
  @observable
  private _filteredTags = new ClientTags().toObject();

  /**
   * Getter organisationID
   * @return {string}
   */
  public get organisationID(): string {
    return this._organisationID;
  }

  /**
   * Setter organisationID
   * @param {string} value
   */
  public set organisationID(value: string) {
    this._organisationID = value;
  }

  /**
   * Getter clientID
   * @return {string}
   */
  public get clientID(): string {
    return this._clientID;
  }

  /**
   * Setter clientID
   * @param {string} value
   */
  public set clientID(value: string) {
    this._clientID = value;
  }

  /**
   * Getter selectedTags
   * @return {ClientTags.AsObject}
   */
  public get selectedTags(): ClientTags.AsObject {
    return this._selectedTags;
  }

  /**
   * Setter selectedTags
   * @param {ClientTags.AsObject} value
   */
  public set selectedTags(value: ClientTags.AsObject) {
    this._selectedTags = value;
  }

  /**
   * Getter filteredTags
   * @return {ClientTags.AsObject}
   */
  public get filteredTags(): ClientTags.AsObject {
    return this._filteredTags;
  }

  /**
   * Setter filteredTags
   * @param {ClientTags.AsObject} value
   */
  public set filteredTags(value: ClientTags.AsObject) {
    this._filteredTags = value;
  }
}

export const tagDataStore = new TagDataStore();
