import React, { Component } from "react";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";

interface Prop {
  tabs: string[];
  selectedTab: string;
  onTabPress(newTab: string): void;
}

class Tab extends Component<Prop> {
  render() {
    const { selectedTab, tabs, onTabPress } = this.props;
    return (
      <TabList>
        {/* tabs is a statics array passed from the index */}
        {tabs.map((tab, index) => {
          return (
            <li css={TabItem} key={index}>
              <a
                className={tab === selectedTab ? "active" : ""}
                key={tab}
                onClick={(e) => {
                  onTabPress(tab);
                }}
              >
                {tab}
              </a>
            </li>
          );
        })}
      </TabList>
    );
  }
}

export default Tab;

const TabList = styled.ul`
  margin-top: 0;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  clear: both;
  background: #fff;
  padding-left: 0px;
`;
const TabItem = css`
  display: block;
  flex: 1;
  border-bottom: 2px solid #fff;

  a {
    padding: 0.5rem;
    display: block;
    width: 100%;
    color: #000;
    background: #ecf7ff;
    font-size: 0.85rem;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  & .active {
    color: #fff;
    background: #0f75c2;
  }
`;
