import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { compose } from "recompose";
import { metadata, rpc } from "../../grpc";
// redux
import * as ReduxDialogs from "../../redux/features/dialogs";
//grpc
import {
  AddClientToLocRequest,
  Client,
  DeleteClientRequest,
  NewClientRequest,
  RowErrors,
  SwitchActiveStatusRequest,
  UploadClientCsvRequest,
  UploadCsvResponse
} from "sdk/dist/clients_pb";
import {
  ClientPhysitrack,
  GetClientDetailsRequest,
  GetClientDetailsResponse,
  UpdateClientDetailsRequest
} from "sdk/dist/physitrack_pb";
import { CreateURLRequest, SendEmailRequest } from "sdk/dist/user_invite_pb";
import { AddUserRequest, User } from "sdk/dist/user_pb";
import { toastStore } from "../../stores/toast-store";
import { colors, FundType } from "../../util/consts";
import { readFileBytes } from "../../util/files";
import { toDateTz } from "../../util/timestamp";
// components
import AdminSubHeader from "../elements/AdminSubHeader";
import ResourceFetch from "../elements/ResourceFetch";
import ResourcePageError from "../elements/ResourcePageError";
import ResourceRender from "../elements/ResourceRender";
import ResourceTable from "../elements/ResourceTable";
import UserBlockDeprecated from "../elements/UserBlock";
import { DateContent, DateTimeContainer, TimeContent } from "./OrgLocationSchedule";

interface Props {
  resource: any;
  dispatch: (func: any) => void;
}

interface State {
  filter: Filter;
  options: any[];
  file: any;
}

interface Filter {
  Filter: string;
  SortBy: string;
  SortDir: string;
  Search: string;
  OrgID: string;
  LocationID: string;
}
class OrgLocationClients extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: {
        Filter: "All",
        SortBy: "Name",
        SortDir: "Asc",
        Search: "",
        OrgID: "",
        LocationID: props.resource.ID
      },
      options: [],
      file: null
    };
  }

  inputFile = React.createRef<any>();
  tableRef = React.createRef<any>();

  componentDidMount() {
    this.mapRowOptions();
  }

  mapRowOptions = () => {
    let newOptions = [
      {
        label: "Edit",
        onClick: this.handleSelect
      },
      {
        label: "Activate/Archive",
        onClick: this.handleActivateOrArchive
      },
      {
        label: "Invite To Lyfe",
        onClick: this.handleSendInvitation
      }
    ];
    const sendClientToLocOptions = Array();
    for (let i = 0; i < this.props.resource.Org.Locations.length; i++) {
      if (this.props.resource.ID != this.props.resource.Org.Locations[i].ID) {
        sendClientToLocOptions[i] = async (res: any) => {
          try {
            // Add Client to location
            const req = new AddClientToLocRequest();
            req.setClientId(res.ID);
            req.setLocationId(this.props.resource.Org.Locations[i].ID);
            await rpc.clientService.addClientToLocation(req, metadata());
            toastStore.success(
              res.FirstName +
                " " +
                res.LastName +
                " Successfully added to the " +
                this.props.resource.Org.Locations[i].Address.Locality +
                " Location"
            );
          } catch (err) {
            toastStore.grpcError(err);
          }
        };
        newOptions.push({
          label:
            "Send to " +
            this.props.resource.Org.Locations[i].Address.Street1 +
            ", " +
            this.props.resource.Org.Locations[i].Address.Locality,
          onClick: sendClientToLocOptions[i]
        });
      }
    }
    this.setState({ options: newOptions });
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (state.filter.OrgID !== props.resource.Org.ID) {
      return {
        filter: {
          ...state.filter,
          Org: props.resource.Org.ID
        }
      };
    }
    return null;
  };

  handleAdd = () => {
    const { dispatch, resource } = this.props;
    dispatch(
      ReduxDialogs.addClientDialog(resource.Org, resource, () => {
        if (this.tableRef.current) {
          this.tableRef.current.refreshTable();
        }
      })
    );
  };

  handleSort = (sortColumn: string, sortDesc: string) => {
    this.setState({
      filter: {
        ...this.state.filter,
        SortBy: sortColumn,
        SortDir: sortDesc ? "Desc" : "Asc"
      }
    });
  };

  handleSearch = (val: string) => {
    this.setState({
      filter: {
        ...this.state.filter,
        Search: val
      }
    });
  };

  handleSelect = (res: any, cl: string) => {
    if (cl === "NextApp") {
      res.NextBooking &&
        this.props.dispatch(
          ReduxDialogs.openAppointment(res.NextBooking.ID, res.ID, false, () => {})
        );
    } else if (cl === "LastApp") {
      res.PrevBooking &&
        this.props.dispatch(
          ReduxDialogs.openAppointment(res.PrevBooking.ID, res.ID, false, () => {})
        );
    } else {
      this.props.dispatch(ReduxDialogs.clientActionDialog(res, () => {}));
    }
  };

  handleActivateOrArchive = (res: any) => {
    const req = new SwitchActiveStatusRequest();
    req.setClientId(res.ID);
    const response = rpc.clientService.switchActiveStatus(req, metadata());
    response
      .then((res) => {
        toastStore.success(
          `${res.getFirstName()} ${res.getLastName()} has been successfully changed to ${res.getStatus()}`
        );
        this.tableRef.current.refreshTable();
      })
      .catch((err) => {
        toastStore.grpcError(err);
      });
  };

  async handleSendInvitation(res: any) {
    try {
      // create User Stub for Client
      const userReq = new AddUserRequest();
      let user = new User();
      user.setFirstName(res.FirstName.trim());
      user.setLastName(res.LastName.trim());
      user.setEmail(!res.Email ? null : res.Email.trim());
      user.setMobileNumber(!res.MobileNumber ? null : res.MobileNumber.trim());
      user.setMobileCountryCode(res.MobileNumber ? "AU" : "");
      user.setGender(res.Gender);
      user.setDateOfBirth(!res.DateOfBirth ? null : res.DateOfBirth);
      userReq.setUser(user);
      userReq.setClientId(res.ID);
      const userRes = await rpc.user.addUser(userReq, metadata());
      const addUserResponse = userRes.toObject();

      // create URL
      const urlReq = new CreateURLRequest();
      urlReq.setUserId(addUserResponse.id);
      const urlRes = await rpc.userInvite.createURL(urlReq);
      const addURLResponse = urlRes.toObject();

      // send Email/SMS
      const emailReq = new SendEmailRequest();
      emailReq.setUserId(addUserResponse.id);
      emailReq.setOrgId(res.Org.ID);
      emailReq.setUrl(addURLResponse.url);
      const emailRes = await rpc.userInvite.sendEmail(emailReq);
      toastStore.success("Email/SMS Sent to " + res.FirstName + " " + res.LastName);
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  handleUpload = () => {
    if (this.inputFile.current) {
      this.inputFile.current.click();
    }
  };

  handleImportPhysitrackClients = async () => {
    let getClientRes = new GetClientDetailsResponse().toObject();
    try {
      const getClientReq = new GetClientDetailsRequest();
      getClientReq.setOrgId(this.props.resource.Org.ID);
      getClientRes = (
        await rpc.physitrackV2.getClientsDetails(getClientReq, metadata())
      ).toObject();
    } catch (error) {
      toastStore.grpcError(error);
    }
    let countClients = 0;
    let clientsNotImported = Array<string>();
    getClientRes.clientsList.forEach(async (client, index) => {
      // if external id is empty, then create user in booklyfe
      if (client.externalId === "") {
        if (client.email === "" && client.mobilePhone === "") {
          clientsNotImported.push(client.id + "_" + client.firstName + "_" + client.lastName);
          return;
        }

        let gen = "Unknown";
        if (client.gender === "m") {
          gen = "Male";
        } else if (client.gender === "f") {
          gen = "Female";
        }

        // Create physitrack client data, send same values back to physitrack to avoid data changes
        const physiClient = new ClientPhysitrack();
        physiClient.setId(client.id);
        physiClient.setFirstName(client.firstName);
        physiClient.setLastName(client.lastName);
        physiClient.setGender(client.gender);
        physiClient.setMobilePhone(client.mobilePhone);
        physiClient.setEmail(client.email);
        physiClient.setYearOfBirth(client.yearOfBirth);

        // Create client in Lyfe
        var clientIdCreated = "";
        try {
          // Create client obj
          const tmpClient = new Client();
          tmpClient.setFirstName(client.firstName);
          tmpClient.setLastName(client.lastName);
          tmpClient.setOrgId(this.props.resource.Org.ID);
          tmpClient.setLocationId(this.props.resource.ID);
          tmpClient.setEmail(client.email);
          tmpClient.setMobileNumber(client.mobilePhone);
          tmpClient.setMobileCountryCode(client.mobilePhone !== "" ? "AU" : "");
          tmpClient.setGender(gen);
          tmpClient.setPreferredFundType(FundType.CreditCard);

          const clientReq = new NewClientRequest();
          clientReq.setClient(tmpClient);

          const clientRes = await (
            await rpc.clientService.newClient(clientReq, metadata())
          ).toObject();
          const clientIdCreated = clientRes.clientId;
          // Update client external id in physitrack with Lyfe internal id
          physiClient.setExternalId(clientIdCreated);
          const updateClientReq = new UpdateClientDetailsRequest();
          updateClientReq.setOrgId(this.props.resource.Org.ID);
          updateClientReq.setPhystrackData(physiClient);
          await rpc.physitrackV2.updateClientsDetails(updateClientReq, metadata());
          countClients++;
        } catch (error) {
          // if client was created, delete it so user can try again later
          if (clientIdCreated !== "") {
            const deleteClientReq = new DeleteClientRequest();
            deleteClientReq.setClientId(clientIdCreated);
            rpc.clientService.deleteClient(deleteClientReq, metadata());
          }
          if (!!error.code) {
            toastStore.grpcError(error);
          } else {
            toastStore.error(error.userMessages);
          }
        }
      }
      // if last client, then show toast to user
      if (index === getClientRes.clientsList.length - 1) {
        if (countClients > 0) {
          // Update redux client store

          toastStore.success("Successfully created " + countClients + " clients");
        }
        if (clientsNotImported.length > 0) {
          toastStore.error(
            "Please update email/phone in physitrack to be able to import the following client(s):\n" +
              clientsNotImported.toString()
          );
        }
        if (countClients === 0) {
          toastStore.success(
            "No new client(s) available to import. If it's in physitrack but you don't see it here, it may be possible that it was already imported somewhere else"
          );
        }
        this.tableRef.current.refreshTable();
      }
    });
  };

  handleUploadClientsCsv = async (e: any) => {
    const { resource: location } = this.props;
    this.setState({
      file: this.inputFile.current.files[0]
    });
    if (this.inputFile.current) {
      const uInt8 = await readFileBytes(this.inputFile.current.files[0]);
      try {
        const req = new UploadClientCsvRequest();
        req.setFile(uInt8);
        req.setLocationId(location.ID);
        req.setOrganisationId(location.Org.ID);
        req.setFileName(this.inputFile.current.files[0].name);
        req.setMime(this.inputFile.current.files[0].type);
        const res = await rpc.clientService.uploadClients(req, metadata());
        if (res.toObject().rowResponseList.length > 0) {
          this.downloadTxtFile(res.toObject());
        }
        const resMess =
          res.getNoClientsUploaded() + " users uploaded of " + res.getNoClientsTotal();
        if (
          res.toObject().status === UploadCsvResponse.Status.CORRECT ||
          res.toObject().status === UploadCsvResponse.Status.PARTIALLY_CORRECT
        ) {
          toastStore.success(resMess);
        } else {
          toastStore.error(
            "Error while trying to upload csv file, open the log file for more information"
          );
        }
        this.setState({
          file: null
        });
        this.inputFile.current.value = "";
      } catch (err) {
        toastStore.grpcError(err);
        this.setState({
          file: null
        });
        this.inputFile.current.value = "";
      }
    }
  };

  downloadTxtFile = (res: any) => {
    const element = document.createElement("a");
    let stringCsvResponse =
      "---" + res.noClientsUploaded + " clients uploaded from " + res.noClientsTotal + " rows---\n";
    stringCsvResponse = stringCsvResponse + "---Row errors separated by comma---\n";
    res.rowResponseList.forEach((row: RowErrors.AsObject) => {
      stringCsvResponse =
        stringCsvResponse + "row_" + row.no + "," + row.errorsList.toString() + "\n";
    });

    const file = new Blob([stringCsvResponse], { type: "txt" });
    element.href = URL.createObjectURL(file);
    element.download = res.fileName + "_log.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    const { filter, file } = this.state;
    return (
      <React.Fragment>
        <AdminSubHeader
          type="table"
          subHeader="Our Clients"
          settings={[
            {
              label: "Upload CSV Data",
              onClick: this.handleUpload
            },
            {
              label: (
                <DropdownItem
                  href="data:csv;
              charset=utf-8,FirstName, LastName, DateOfBirth, Gender, Email, MobileNumber, Notes
required,required,format 2006-01-02,valid values are ('M'-'F'-''),either email or phone is required,either email or phone is required,not required, delete this line and the following lines before uploading and just conserve the column names (row above)
Matias,Fradusco,1990-12-01,M,fradm@gmail.com,344231232,note1
Cristhian Fernando,Garzon G,1990-04-27,M,cris@blitzm.com,+61320123312
Shiva,Krishna,1874-03-22,,krishna@cdas.com,404183202,note1 note2 note3"
                  download="lyfe_clients_sample.csv"
                >
                  Download CSV Example Template
                </DropdownItem>
              ),
              onClick: () => {}
            },
            {
              label: "Import physitrack clients",
              onClick: this.handleImportPhysitrackClients
            }
          ]}
          onAdd={this.handleAdd}
          onSearch={this.handleSearch}
        />
        <input
          ref={this.inputFile}
          onChange={this.handleUploadClientsCsv}
          type="file"
          accept=".csv"
          style={{ display: "none" }}
        />
        {file != null ? (
          <BeatLoader
            css={css`padding: "0 40px", textAlign: "center"`}
            size={15}
            color={colors.tertiary.main}
            loading={true}
          />
        ) : (
          <ResourceTable
            ref={this.tableRef}
            listType="OrganisationClientsPage"
            resourceType="Client"
            filter={filter}
            onSort={this.handleSort}
            columns={["Name", "Status", "DateOfBirth", "Email", "LastApp", "NextApp"]}
            columnInfo={{
              Name: {
                type: "Component",
                label: "Basic Info",
                weight: 3.0,
                min: 200,
                component: React.memo(({ resource: res }: any) => (
                  <StyleUserBlock type="Client" user={res} alt={res.MobileNumber} size="small" />
                )),
                onClick: this.handleSelect
              },
              Status: {
                type: "Label",
                label: "Status",
                weight: 1,
                min: 150,
                max: 200,
                onClick: this.handleSelect
              },
              DateOfBirth: {
                type: "TitleLabel",
                label: "Date Of Birth",
                weight: 1,
                min: 150,
                max: 200,
                onClick: this.handleSelect
              },
              Email: {
                type: "Label",
                label: "Email",
                weight: 2.5,
                min: 200,
                max: 400,
                onClick: this.handleSelect
              },
              LastApp: {
                type: "Component",
                label: "Last Appointment",
                weight: 2.5,
                min: 200,
                component: React.memo<any>(({ resource: res }: any) => {
                  if (!res.PrevBooking) {
                    return "None";
                  } else {
                    if (!res.PrevBooking.start_datetime) {
                      return "None";
                    } else {
                      const startDateTime = toDateTz(new Date(res.PrevBooking.start_datetime));
                      const endDateTime = toDateTz(new Date(res.PrevBooking.end_datetime));
                      return (
                        <DateTimeContainer>
                          <DateContent>{startDateTime.format("dddd, DD/MM/yyyy")}</DateContent>
                          <TimeContent>
                            {startDateTime.format("hh:mma")} - {endDateTime.format("hh:mma")}
                          </TimeContent>
                        </DateTimeContainer>
                      );
                    }
                  }
                }),
                onClick: this.handleSelect
              },
              NextApp: {
                type: "Component",
                label: "Next Appointment",
                weight: 2.5,
                min: 200,
                component: React.memo<any>(({ resource: res }: any) => {
                  if (!res.NextBooking) {
                    return "None";
                  } else {
                    if (!res.NextBooking.start_datetime) {
                      return "None";
                    } else {
                      const startDateTime = toDateTz(new Date(res.NextBooking.start_datetime));
                      const endDateTime = toDateTz(new Date(res.NextBooking.end_datetime));
                      return (
                        <DateTimeContainer>
                          <DateContent>{startDateTime.format("dddd, DD/MM/yyyy")}</DateContent>
                          <TimeContent>
                            {startDateTime.format("hh:mma")} - {endDateTime.format("hh:mma")}
                          </TimeContent>
                        </DateTimeContainer>
                      );
                    }
                  }
                }),
                onClick: this.handleSelect
              }
            }}
            rowOptions={this.state.options}
          />
        )}
      </React.Fragment>
    );
  }
}

const ConnectedOrgLocationClients = compose(connect())(OrgLocationClients as any);

export default React.memo(({ match, history }: any) => {
  return (
    <React.Fragment>
      <ResourceFetch
        type="Location"
        ids={{ ID: match.params.lid }}
        extraData={{ Context: "Admin" }}
      />
      <ResourceRender
        type="Location"
        ids={{ ID: match.params.lid }}
        denorm={true}
        compSuccess={ConnectedOrgLocationClients}
        compError={ResourcePageError}
        forwardedProps={{ history }}
      />
    </React.Fragment>
  );
});

const StyleUserBlock = styled(UserBlockDeprecated)`
  padding: 0 20px;
  height: 100%;
`;

const DropdownItem = styled.a`
  color: rgba(44, 46, 60, 0.6);
  font-size: 12.82px;
  text-decoration: none;
`;
