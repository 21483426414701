
import { createRef, Component } from "react";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import { css, jsx } from "@emotion/core";
import { connect } from "react-redux";
import { createFocusTrap } from "focus-trap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import IconButton from "./../../components/IconButton";
import ClearIcon from "./../../components/icons/Clear";
import { breakpoints, colors } from "./../../util/consts";

const isiOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

class Dialog extends Component {
  overlayNode = createRef();
  containerNode = createRef();
  closeButtonNode = createRef();

  state = {
    errorCode: null
  };

  componentDidCatch(error, info) {
    if (process.env.BOOKLYFE_ENVIRONMENT.includes("production")) {
      console.warn("Unknown Internal Error", error);
      Sentry.captureException(error, { extra: info });
    } else {
      console.error(error);
    }
    this.setState({ errorCode: Sentry.lastEventId() || "unknown" });
  }

  componentDidMount() {
    if (!isiOS) {
      disableBodyScroll(this.containerNode.current);
    }
  }

  componentDidUpdate() {
    const focusOptions = {
      onDeactivate: this.onClose,
      returnFocusOnDeactivate: false,
      clickOutsideDeactivates: true
    };
    if (this.overlayNode.current && this.props.children && !this.props.noContent) {
      this.focusTrap = createFocusTrap(this.overlayNode.current, focusOptions);
      this.focusTrap.activate();
    }
  }

  componentWillUnmount() {
    const { trigger } = this.props;
    if (!isiOS) {
      enableBodyScroll(this.containerNode.current);
    }
    if (this.focusTrap) {
      this.focusTrap.deactivate();
      if (typeof trigger !== "undefined" && !!trigger) {
        trigger.focus();
      }
    }
  }

  closeDialog = () => {
    const { overideCloseDialog, dispatch, dialogId } = this.props;
    if (overideCloseDialog) {
      overideCloseDialog();
    } else {
      dispatch(ReduxDialogs.actions.close(dialogId));
    }
  };

  onClickAway = (e) => {
    const { noClickAwayClose } = this.props;
    if (e.target === this.overlayNode.current && !noClickAwayClose) {
      this.closeDialog();
    }
  };

  render() {
    const { errorCode } = this.state;
    const { children, noContent, noClose } = this.props;
    if (errorCode) {
      return null;
    }

    return (
      <Overlay onClick={this.onClickAway} ref={this.overlayNode}>
        {noContent ? (
          children
        ) : (
          <Container role="dialog" aria-modal={true} style={{}} ref={this.containerNode}>
            {!noClose && (
              <IconButton
                css={css`
                  position: absolute !important;
                  top: 2px;
                  right: 0px;
                  z-index: 12;
                `}
                innerRef={this.closeButtonNode}
                onClick={this.closeDialog}
                aria-labelledby="close-modal"
              >
                <ClearIcon fill={colors.primary.main} size="small" />
                <HideVisually id="close-modal">Close</HideVisually>
              </IconButton>
            )}
            <Content>{children}</Content>
          </Container>
        )}
      </Overlay>
    );
  }
}

export default connect()(Dialog);

const Overlay = styled.div`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 46, 60, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  border-radius: 0px;
  background-color: ${colors.surface.light};
  overflow: hidden;

  ${breakpoints["phone-only"]} {
    height: 100%;
    width: 100%;
  }

  ${breakpoints["tablet-up"]} {
    border-radius: 4px;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${breakpoints["tablet-up"]} {
    max-height: calc(100vh - 96px);
  }
`;

const HideVisually = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;
