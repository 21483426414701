import React from "react";
import { userStore } from "../../mobx/stores/UserStore";
import { ActivityIndicator } from "../../ui-kit/ActivityIndicator";
import PagesContainer, { Page, PageGroup } from "../../components/elements/PagesContainer";
import Funds from "./Funds";
import { ManageCreditCard } from "../../components/stripe/CreditCard/ManageCreditCard";
import { CreditCard } from "sdk/dist/credit_cards_pb";
import { UserUIType } from "sdk/dist/location_user_pb";

export const UserPaymentMethodIframePage = () => {
  if (!userStore.user) {
    return <ActivityIndicator />;
  }
  return (
    <PagesContainer
      sidePanel={true}
      queryp="page"
      blockNotSeen={false}
      extraProps={{ user: userStore.user }}
    >
      <PageGroup label="Payments">
        <Page
          name="ccards"
          label="Credit Cards"
          component={() => (
            <ManageCreditCard
              ownerId={userStore.user!.id}
              ownerType={CreditCard.OwnerType.USER}
              useFormHeaderComp={true}
            />
          )}
        />
        {!(
          localStorage.getItem("UserUIType") === String(UserUIType.FITNESS) ||
          process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
        ) ? (
          <Page name="funds" label="Funds" component={Funds} />
        ) : (
          false
        )}
      </PageGroup>
    </PagesContainer>
  );
};
