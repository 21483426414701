import React, { useState } from "react";
import { IOrganization } from "../../pages/create-organization/form/IOrganization";
import { FormBlock } from "../../ui-kit/form/FormBlock";
import { BusinessInformationForm } from "./BusinessInformationForm";
import { BusinessOpeningHours } from "./BusinessOpeningHours";
import { DescriptionForm } from "./DescriptionForm";
import { PaymentForm } from "./PaymentForm";
import { ProviderAccountUpgradeSuccessMessage } from "./ProviderAccountUpgradeSuccessMessage";

export const UpgradeClientAccountIFramePage = () => {
  const [stepperPosition, setStepperPosition] = useState(0);
  const [organisation, setOrganisationDetails] = useState<Partial<IOrganization>>({});

  return (
    <FormBlock
      heading="Upgrade Account"
      stepperPosition={stepperPosition}
      steps={[
        {
          content: (
            <BusinessInformationForm
              organization={organisation}
              setOrgDetails={(org) => setOrganisationDetails(org)}
              nextStep={() => setStepperPosition(stepperPosition + 1)}
            />
          )
        },
        {
          content: (
            <BusinessOpeningHours
              organization={organisation}
              setOrgDetails={(org) => setOrganisationDetails(org)}
              nextStep={() => setStepperPosition(stepperPosition + 1)}
              previousStep={() => setStepperPosition(stepperPosition - 1)}
            />
          )
        },
        {
          content: (
            <DescriptionForm
              organization={organisation}
              setOrgDetails={(org) => setOrganisationDetails(org)}
              nextStep={() => setStepperPosition(stepperPosition + 1)}
              previousStep={() => setStepperPosition(stepperPosition - 1)}
            />
          )
        },
        {
          content: (
            <PaymentForm
              organization={organisation}
              setOrgDetails={(org) => setOrganisationDetails(org)}
              previousStep={() => setStepperPosition(stepperPosition - 1)}
              nextStep={() => setStepperPosition(stepperPosition + 1)}
            />
          )
        },
        {
          content: <ProviderAccountUpgradeSuccessMessage />
        }
      ]}
    />
  );
};
