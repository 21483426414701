import { TablePagination as _TablePagination } from "@material-ui/core";
import React, { ReactElement, useCallback } from "react";
import { accTableStore } from "../../../stores/acc_table-store";

const rowsPerPageOptions = [5, 10, 25, 50, { label: "All", value: -1 }];

interface Props {
  gotoPage: (newPage: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  pageIndex: number;
  totalRowCount: number;
}

export const TablePagination: React.FC<Props> = (props: Props): ReactElement | null => {
  const {
    pageSize,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    pageIndex,
    totalRowCount
  } = props;
  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      if (newPage === pageIndex + 1) {
        nextPage();
      } else if (newPage === pageIndex - 1) {
        previousPage();
      } else {
        gotoPage(newPage);
      }
    },
    [gotoPage, nextPage, previousPage]
  );

  const onChangeRowsPerPage = useCallback(
    (e) => {
      setPageSize(Number(e.target.value));
    },
    [setPageSize]
  );

  return (
    <_TablePagination
      component={"td"}
      rowsPerPageOptions={rowsPerPageOptions}
      style={{ display: "flex", overflow: "inherit" }}
      colSpan={8}
      count={totalRowCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: true
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
};
