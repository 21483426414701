import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { format, isSameDay } from "date-fns";

import { colors } from "../../../util/consts";
import typography from "../../../util/typography";

interface Props {
  resource: any
  type: string
}

export class Appointment extends PureComponent<Props> {
  static propTypes = {
    type: PropTypes.oneOf(["unpaid", "upcoming"])
  };

  paymentStatus = () => {
    const { resource: booking } = this.props;

    if (booking.Status !== "Processing") {
      return booking.Status;
    }

    let status = "Processing";
    for (const client of booking.Clients) {
      if ((client.PaymentInvoices||[]).findIndex((v: any)=>v.Status === "Error") >= 0) {
        status = "Charge Failed";
        break;
      } else if ((client.FundInvoices || []).findIndex((v: any) => v.Status === "Error") >= 0) {
        status = "Fund Failed";
      }
    }

    return status;
  };

  render() {
    const { resource, type } = this.props;

    const [syear, smonth, sday] = resource.start_datetime.split("-");
    const StartDate = new Date(syear, smonth - 1, sday, ...resource.start_datetime.split(":"));
    const [eyear, emonth, eday] = resource.end_datetime.split("-");
    const EndDate = new Date(eyear, emonth - 1, eday, ...resource.end_datetime.split(":"));

    return (
      <Container>
        <Section>
          <Avatar
            style={{
              backgroundImage: resource.Provider.Photo && resource.Provider.Photo.DownloadURL
            }}
          >
            <AvatarText>
              {resource.Provider.FirstName[0] + resource.Provider.LastName[0]}
            </AvatarText>
          </Avatar>
        </Section>
        <Section>
          <Body2>
            {type === "unpaid" && "#" + resource.FriendlyID}
            {type === "upcomming" && resource.Provider.FirstName + " " + resource.Provider.LastName}
          </Body2>
          {resource.Type === "Group" ? 
          <>
              <Body>
                {resource.GroupOfferings[0].Offering.Name} -{" "}
                {resource.GroupOfferings[0].Offering.ServiceDuration}min
              </Body>
              <Body2>
                {type === "unpaid" && (
                  <>
                    {resource.start_datetime}
                    <span
                      style={{
                        color: resource.Status === "Declined" ? "#F2708A" : colors.secondary.main,
                        marginLeft: 8
                      }}
                    >
                      &bull; {this.paymentStatus()}
                    </span>
                  </>
                )}
                {type === "upcoming" && (
                  <>
                    Group Booking.
                    <span
                      style={{
                        color: colors.secondary.main,
                        marginLeft: 8
                      }}
                    >
                      &bull; {format(StartDate, "dd MMM, hh:mmaaaaa")}m -{" "}
                      {format(
                        EndDate,
                        isSameDay(StartDate, EndDate) ? "hh:mmaaaaa" : "dd MMM, hh:mmaaaaa"
                      )}
                      m
                    </span>
                  </>
                )}
              </Body2>
          </>
          :
          <>
              <Body>
                {resource.Clients[0].Offerings[0].Offering.Name} -{" "}
                {resource.Clients[0].Offerings[0].Offering.ServiceDuration}min
              </Body>
              <Body2>
                {type === "unpaid" && (
                  <>
                    {resource.start_datetime}
                    <span
                      style={{
                        color: resource.Status === "Declined" ? "#F2708A" : colors.secondary.main,
                        marginLeft: 8
                      }}
                    >
                      &bull; {this.paymentStatus()}
                    </span>
                  </>
                )}
                {type === "upcoming" && (
                  <>
                    Assigned to {resource.Clients[0].Client.FirstName} {resource.Clients[0].Client.LastName[0]}.
                    <span
                      style={{
                        color: colors.secondary.main,
                        marginLeft: 8
                      }}
                    >
                      &bull; {format(StartDate, "dd MMM, hh:mmaaaaa")}m -{" "}
                      {format(
                        EndDate,
                        isSameDay(StartDate, EndDate) ? "hh:mmaaaaa" : "dd MMM, hh:mmaaaaa"
                      )}
                      m
                    </span>
                  </>
                )}
              </Body2>
            </>
          }
        </Section>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Section = styled.div`
  margin: 0 8px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${colors.primary.main};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarText = styled.span`
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 19px;
  text-transform: uppercase;
`;

const Body = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.09px;
  line-height: 19px;
  ${typography.elipse};
`;

const Body2 = styled.div`
  ${typography.caption};
  color: ${colors.surfaceText.medEmphasis};
  letter-spacing: 0.09px;
  line-height: 15px;
  ${typography.elipse};
`;
