import React, { Component, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { Option } from "sdk/dist/insight_filter_options_pb";
import { jsx, css } from "@emotion/core";
import { IFilter } from "./../../../components/routes/InsightDashboard/CustomTypes";

interface Prop {
  label: string;
  options: Array<Option>;
  onUpdateFilter(newFilter: Partial<IFilter>): void;
}

export class TextFilter extends Component<Prop> {
  private handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    switch (this.props.label) {
      case "Location":
        this.props.onUpdateFilter({ locationID: event.target.value });
        break;
      case "Service":
        this.props.onUpdateFilter({ serviceID: event.target.value });
        break;
      case "State":
        this.props.onUpdateFilter({ state: event.target.value });
        break;
      case "Provider":
        this.props.onUpdateFilter({ providerID: event.target.value });
        break;
      case "Tag":
        this.props.onUpdateFilter({ tagID: event.target.value });
        break;
      default:
    }
  };

  render() {
    const { label, options } = this.props;
    return (
      <SelectionDiv>
        <ParamLabel>{label}</ParamLabel>
        <ISelect onChange={this.handleSelect}>
          {options.map((option, index) => (
            <option value={option.getValue()} key={index}>
              {option.getName()}
            </option>
          ))}
        </ISelect>
      </SelectionDiv>
    );
  }
}

const SelectionDiv = styled.div`
  background: 0 0;
  display: block;
  height: auto;
  border-radius: 0;

  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  will-change: opacity, transform, color;
`;

const ParamLabel = styled.label`
  display: block;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.1;
`;
const ISelect = styled.select`
  border: 1px solid #ddd;
  padding: 8px 12px 10px;
  height: 37px;
  line-height: 18px;
  background: #fff;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  align-items: center;
  -webkit-rtl-ordering: logical;
  margin: 0em;
  outline-style: none;

  :focus {
    border: 1px solid #0f75c2;
  }
  :hover {
    border: 1px solid #999;
  }
  :focus:hover {
    border: 1px solid #0f75c2;
  }
`;
