import * as grpcWeb from "grpc-web";
import { userStore } from "./mobx/stores/UserStore";
import { AccountTableServicePromiseClient } from "sdk/dist/accounts_table_grpc_web_pb";
import { AccountItemsPromiseClient } from "sdk/dist/account_items_grpc_web_pb";
import { AffiliateServicePromiseClient } from "sdk/dist/affiliate_code_grpc_web_pb";
import {
  ApiTokensPromiseClient,
  AuthApplicationsPromiseClient
} from "sdk/dist/applications_and_tokens_grpc_web_pb";
import {
  BookingClientsPromiseClient,
  BookingOfferingsPromiseClient,
  BookingsPromiseClient,
  ClientOfferingsPromiseClient,
  LineItemsPromiseClient,
  UserBookingsPromiseClient
} from "sdk/dist/bookings_grpc_web_pb";
import { CategoryServicePromiseClient } from "sdk/dist/category_grpc_web_pb";
import { ClientServicePromiseClient } from "sdk/dist/clients_grpc_web_pb";
import { ClientTableServicePromiseClient } from "sdk/dist/client_table_grpc_web_pb";
import { CountryServicePromiseClient } from "sdk/dist/country_grpc_web_pb";
import {
  ClientCreditCardsPromiseClient,
  OrganisationCreditCardsPromiseClient,
  UserCreditCardsPromiseClient
} from "sdk/dist/credit_cards_grpc_web_pb";
import { CurrenciesServicePromiseClient } from "sdk/dist/currencies_grpc_web_pb";
import {
  ClientHealthFundsPromiseClient,
  UserHealthFundsPromiseClient
} from "sdk/dist/funds_grpc_web_pb";
import { HistoryThirdPartiesPromiseClient } from "sdk/dist/history_third_parties_grpc_web_pb";
import { HubspotApiV1PromiseClient } from "sdk/dist/hubspot_grpc_web_pb";
import { InsightClientDataServicePromiseClient } from "sdk/dist/insight_client_grpc_web_pb";
import { InsightFilterOptionsServicePromiseClient } from "sdk/dist/insight_filter_options_grpc_web_pb";
import { InsightMetricsServicePromiseClient } from "sdk/dist/insight_metrics_grpc_web_pb";
import { InsightReferralServicePromiseClient } from "sdk/dist/insight_referral_grpc_web_pb";
import { InsightRevenueServicePromiseClient } from "sdk/dist/insight_revenue_grpc_web_pb";
import {
  LocationBookingServicePromiseClient,
  LocationServicePromiseClient
} from "sdk/dist/location_grpc_web_pb";
import { LocationUserPromiseClient } from "sdk/dist/location_user_grpc_web_pb";
import { MediaServicePromiseClient } from "sdk/dist/media_grpc_web_pb";
import { NotesPromiseClient } from "sdk/dist/notes_grpc_web_pb";
import { NoteTemplateServicePromiseClient } from "sdk/dist/note_template_grpc_web_pb";
import { OfferingsPromiseClient } from "sdk/dist/offerings_grpc_web_pb";
import { OrganisationUserPromiseClient } from "sdk/dist/organisation_user_grpc_web_pb";
import {
  PhysitrackApiV1PromiseClient,
  PhysitrackApiV2PromiseClient
} from "sdk/dist/physitrack_grpc_web_pb";
import { ProductServicePromiseClient } from "sdk/dist/products_grpc_web_pb";
import { ReferralTableServicePromiseClient } from "sdk/dist/referral_table_grpc_web_pb";
import { RevenueTableServicePromiseClient } from "sdk/dist/revenue_table_grpc_web_pb";
import { SearchServicePromiseClient } from "sdk/dist/search_grpc_web_pb";
import { StripeServicePromiseClient } from "sdk/dist/stripe_grpc_web_pb";
import { TagServicePromiseClient } from "sdk/dist/tag_grpc_web_pb";
import { ThirdPartyInvoicesPromiseClient } from "sdk/dist/third_party_invoices_grpc_web_pb";
import { ThirdPartyInvoiceTemplateServicePromiseClient } from "sdk/dist/third_party_invoice_templates_grpc_web_pb";
import { UserServicePromiseClient } from "sdk/dist/user_grpc_web_pb";
import { UserInviteServicePromiseClient } from "sdk/dist/user_invite_grpc_web_pb";

const server = `${window.location.origin}/api`;

export const rpc = {
  bookings: new BookingsPromiseClient(server),
  bookingClients: new BookingClientsPromiseClient(server),
  bookingOfferings: new BookingOfferingsPromiseClient(server),
  clientOfferings: new ClientOfferingsPromiseClient(server),
  clientFunds: new ClientHealthFundsPromiseClient(server),
  userFunds: new UserHealthFundsPromiseClient(server),
  clientCards: new ClientCreditCardsPromiseClient(server),
  orgCards: new OrganisationCreditCardsPromiseClient(server),
  userCards: new UserCreditCardsPromiseClient(server),
  historyThirdParties: new HistoryThirdPartiesPromiseClient(server),
  media: new MediaServicePromiseClient(server),
  offerings: new OfferingsPromiseClient(server),
  lineItems: new LineItemsPromiseClient(server),
  thirdPartyInvoices: new ThirdPartyInvoicesPromiseClient(server),
  notes: new NotesPromiseClient(server),
  accountItems: new AccountItemsPromiseClient(server),
  insightMetrics: new InsightMetricsServicePromiseClient(server),
  insightFilterOptions: new InsightFilterOptionsServicePromiseClient(server),
  revenueTable: new RevenueTableServicePromiseClient(server),
  insightRevenue: new InsightRevenueServicePromiseClient(server),
  insightClient: new InsightClientDataServicePromiseClient(server),
  clientTable: new ClientTableServicePromiseClient(server),
  insightReferral: new InsightReferralServicePromiseClient(server),
  referralTable: new ReferralTableServicePromiseClient(server),
  freeFormTag: new TagServicePromiseClient(server),
  noteTemplate: new NoteTemplateServicePromiseClient(server),
  thirdPartyInvoiceTemplate: new ThirdPartyInvoiceTemplateServicePromiseClient(server),
  clientService: new ClientServicePromiseClient(server),
  accountTable: new AccountTableServicePromiseClient(server),
  affiliateService: new AffiliateServicePromiseClient(server),
  authApplicationService: new AuthApplicationsPromiseClient(server),
  apiTokenService: new ApiTokensPromiseClient(server),
  physitrackV1: new PhysitrackApiV1PromiseClient(server),
  physitrackV2: new PhysitrackApiV2PromiseClient(server),
  user: new UserServicePromiseClient(server),
  hubspotV1: new HubspotApiV1PromiseClient(server),
  userInvite: new UserInviteServicePromiseClient(server),
  currencies: new CurrenciesServicePromiseClient(server),
  stripe: new StripeServicePromiseClient(server),
  locationUser: new LocationUserPromiseClient(server),
  organisationUser: new OrganisationUserPromiseClient(server),
  location: new LocationServicePromiseClient(server),
  category: new CategoryServicePromiseClient(server),
  search: new SearchServicePromiseClient(server),
  products: new ProductServicePromiseClient(server),
  userBookings: new UserBookingsPromiseClient(server),
  locationBookings: new LocationBookingServicePromiseClient(server),
  country: new CountryServicePromiseClient(server)
};

// https://github.com/SafetyCulture/grpc-web-devtools
const devtools = (window as any).__GRPCWEB_DEVTOOLS__;
if (devtools) {
  devtools(Object.values(rpc));
}

export function metadata(): grpcWeb.Metadata {
  return { Authorization: userStore.getAuthHeader() };
}
