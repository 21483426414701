import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router";

// Redux
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import WeekSchedule from "./../../../components/form/WeekSchedule";
import LoadingButton from "./../../../components/LoadingButton";

class LocCreateAccount_Sched extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Location Schedule"
          subtitle="Please fill out the opening times for your location"
        />
        <SectionsContainer>
          <Section>
            <WeekSchedule
              name="Schedule"
              value={values.Schedule}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Schedule && errors.Schedule}
            />
            {!!errors.Schedule && touched.Schedule && <Error>{errors.Schedule}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const standardDay = {
  OpensAt: "09:00:00",
  ClosesAt: "17:00:00"
};

const closedDay = {
  OpensAt: null,
  ClosesAt: null
};

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    Schedule: pageData.Schedule || {
      Monday: standardDay,
      Tuesday: standardDay,
      Wednesday: standardDay,
      Thursday: standardDay,
      Friday: standardDay,
      Saturday: closedDay,
      Sunday: closedDay
    }
  }),
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { pageData, org } = props;

      // Create the location.
      const loc = {
        $Metadata: { Type: "Location" },
        Org: org.ID,
        Name: pageData.Name,
        Address: pageData.AddressID,
        Phone: pageData.Phone,
        Categories: pageData.Categories,
        Banner: pageData.Banner || null,
        Schedule: values.Schedule
      };

      // Get the location.
      const locCreate = await props.dispatch(ResourceActions.action(loc, "Create", {}));
      await props.dispatch(
        ResourceActions.listAction(
          { $Metadata: { List: "AuthUserOrgs", Context: {} } },
          false,
          "Fetch"
        )
      );
      props.dispatch(
        ReduxDialogs.openWelcomeDialog(
          "New Location",
          "You will need to:",
          ["Create a Stripe Account","Services & products", "Staff members"],
          "Create a Stripe Account",
          () => props.history.push("/admin/orgs/" + loc.Org + "/edit?page=stripe")
        )
      );
      props.toastManager.add("Location Created!", { appearance: "success" });
      props.history.push(`/admin/locs/${locCreate.Payload.ID}/dashboard`);
      // props.setPage("providers", {
      //   "LocID": locCreate.Payload.ID,
      // });
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LocCreateAccountSched"
});

export default compose(
  connect((state) => ({})),
  withRouter,
  withToastManager,
  formikEnhancer
)(LocCreateAccount_Sched);
