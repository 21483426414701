import React, { Component } from "react";
import styled from "@emotion/styled";
// import { css } from "@emotion/core";

import IconButton from "./../../components/IconButton";
import DropdownSelect from "./../../components/DropdownSelect";

import Options from "./../../components/icons/Options";
import FormInputValidation from "./../../components/FormInputValidation";
import Button from "./../../components/ButtonServices";
import Pill from "./../../components/Pill";
// import ButtonService from "./../../components/ButtonService";

// Testing
// import Dropdown from "./../../components/Dropdown";

import ResourceCategoryList from "./../../components/elements/ResourceCategoryList";

export default class ResourceTableCard extends Component {
  renderTitleLabel(cl) {
    const { resource, columnInfo } = this.props;
    return (
      <TitleLabel>
        {!columnInfo[cl].format // eslint-disable-line no-nested-ternary
          ? typeof resource[cl] === "string" || typeof resource[cl] === "undefined"
            ? resource[cl]
            : resource[cl].reduce((aVal, nVal) => aVal + nVal, "")
          : columnInfo[cl].format(resource)}
      </TitleLabel>
    );
  }

  renderLabel(cl) {
    const { resource, columnInfo } = this.props;
    return <Label>{!columnInfo[cl].format ? resource[cl] : columnInfo[cl].format(resource)}</Label>;
  }

  renderInput(cl) {
    const { resource, columnInfo } = this.props;
    return (
      <FormInputValidation
        prop={columnInfo[cl].prop}
        resource={resource}
        validator={columnInfo[cl].validator}
        inputProps={columnInfo[cl].inputProps}
        initialVal={columnInfo[cl].initialVal}
        finalVal={columnInfo[cl].finalVal}
        finalObj={columnInfo[cl].finalObj}
      />
    );
  }

  renderButton(cl) {
    const { resource, columnInfo, rowOptions } = this.props;
    return (
      <DropdownSelect options={rowOptions}>
        <Button variant="contained" color="secondary">
          {!columnInfo[cl].format ? resource[cl] : columnInfo[cl].format(resource)}
        </Button>
      </DropdownSelect>
    );
  }

  renderPill(cl) {
    const { resource, columnInfo } = this.props;
    let color;
    switch (resource.Status) {
      case "Created":
        color = "#d3d3d3";
        break;
      case "Processing":
          color = "#04b1b6";
          break;
      case "Error":
        color = "#e32418";
        break;
      case "Completed":
          color = "#30bf0a";
          break;
      case "Cancelled":
          color = "#f09800";
          break;
      case "Refunded":
          color = "#cd2393";
          break;
      default:
        color = "secondary";
    }
    return (
      <Pill variant="contained" color={color}>
        {!columnInfo[cl].format ? resource[cl] : columnInfo[cl].format(resource)}
      </Pill>
    );
  }

  renderToggle(cl) {
    const { resource, columnInfo, rowOptions } = this.props;
    return (
      <DropdownSelect options={rowOptions}>
        <Button variant="contained" color="secondary">
          {!columnInfo[cl].format ? resource[cl] : columnInfo[cl].format(resource)}
        </Button>
      </DropdownSelect>
    );
  }

  renderCategory(cl) {
    const { catID } = this.props;
    return <ResourceCategoryList ids={catID} />;
  }

  renderColumn(cl) {
    const { resource, columnInfo } = this.props;
    switch (columnInfo[cl].type) {
      case "Component":
        return React.createElement(columnInfo[cl].component, { resource });
      case "Label":
        return this.renderLabel(cl);
      case "TitleLabel":
        return this.renderTitleLabel(cl);
      case "Input":
        return this.renderInput(cl);
      case "Button":
        return this.renderButton(cl);
      case "Toggle":
        return this.renderToggle(cl);
      case "Category":
        return this.renderCategory(cl);
      case "Pill":
        return this.renderPill(cl);
      default:
        return null;
    }
  }

  render() {
    const { activeColumns, columnInfo, rowOptions, resource } = this.props;

    return (
      <Row>
        <OptionsColumn key="options">
          {rowOptions && (
            <DropdownSelect options={rowOptions} forwardedArg={resource}>
              <IconButton mini onClick={this.openSettings}>
                <Options />
              </IconButton>
            </DropdownSelect>
          )}
        </OptionsColumn>
        {activeColumns.map((cl) => (
          <Column
            key={cl}
            select={!!columnInfo[cl].onClick}
            onClick={() => columnInfo[cl].onClick && resource && columnInfo[cl].onClick(resource, cl)}
          >
            {this.renderColumn(cl)}
          </Column>
        ))}
      </Row>
    );
  }
}

const Row = styled.tr`
  height: 64px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(44, 46, 60, 0.05);
  text-align: left;
  margin: 5px 0;
`;

const Column = styled.td`
  word-break: break-all;
  transition: padding 0.25s ease;
  padding: 0 20px;
  vertical-align: middle;

  ${({ select }) =>
    select &&
    `
    cursor: pointer;
    user-select: none;
    &:hover{
      background-color: rgba(44,46,60,0.05);
      border-radius: 4px;
    }
  `};
`;

export const Label = styled.div`
  color: #2c2e3c;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.09px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TitleLabel = styled.div`
  color: #2c2e3c;
  font-size: 14px;
  letter-spacing: 0.09px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionsColumn = styled.td`
  width: 64px;
`;
