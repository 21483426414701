import React, { Component } from "react";
import { ActivityIndicator } from "../../../ui-kit/ActivityIndicator";
import ResourceFetch from "../../elements/ResourceFetch";
import ResourcePageError from "../../elements/ResourcePageError";
import ResourceRender from "../../elements/ResourceRender";
import Render from "./Render";

interface Props {
  resource: any;
  history: any;
  match: any;
  dispatch: () => void;
}
export class OrgLocationDashboard extends Component<Props> {
  dashboardFetch = React.memo(({ resource: loc, history }: Props) => (
    <>
      <ResourceFetch type="LocDashboard" ids={{ Location: loc.ID }} force />
      {loc && loc.Org ? (
        <ResourceRender
          type="LocDashboard"
          ids={{ Location: loc.ID }}
          denorm={true}
          compSuccess={Render}
          compError={ResourcePageError}
          forwardedProps={{ history, loc }}
        />
      ) : (
        <ActivityIndicator color="primary" />
      )}
    </>
  ));

  render() {
    const { match, history } = this.props;
    return (
      <>
        <ResourceFetch
          type="Location"
          ids={{ ID: match.params.lid }}
          extraData={{ Context: "Admin" }}
          force
        />
        <ResourceRender
          type="Location"
          ids={{ ID: match.params.lid }}
          denorm={true}
          compSuccess={this.dashboardFetch}
          compError={ResourcePageError}
          forwardedProps={{ history }}
        />
      </>
    );
  }
}
