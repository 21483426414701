import React, { Component } from "react";
import styled from "@emotion/styled";

// components
import ReferralAccordian from "../dialogs/ClientActionDialog/ReferralAccordian";
import LargeDottedButton from "../LargeDottedButton";
import { TabContainer, TabHeader, TabDetail } from "../dialogs/ClientActionDialog/index";

import LoadingIcon from "../icons/Loading";
import ResourceRender from "../elements/ResourceRender";
import ResourceFetch from "../elements/ResourceFetch";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import { BookingClient } from "sdk/dist/bookings_pb";
import { Label } from "../elements/Label";
import { NewThirdPartyForm } from "./NewThirdPartyForm";
import { ThirdPartyTemplateForm } from "./ThirdPartyTemplateForm";
import { ThirdPartyTemplateRow } from "./ThirdPartyTemplateRow";
import { tpiTemplateStore } from "../../stores/tpi_template-store";
import {
  ThirdPartyInvoiceTemplate,
  DeleteThirdPartyInvoiceTemplateRequst
} from "sdk/dist/third_party_invoice_templates_pb";
import { rpc, metadata } from "../../grpc";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

interface Props {
  clientId: string;
  resource?: any;
  rightMessage?: any;
  rightMessageProps?: any;
  disallowNew?: boolean;
  isLoading?: any;
  selRef: string;
  onSelect?: (provId: string) => void;
}

@observer
class ThirdPartyTemplate extends Component<Props> {
  @observable
  private adding = false;

  async componentDidMount() {
    await tpiTemplateStore.loadByClientId(this.props.clientId);
  }

  private async removeTemplate(template: ThirdPartyInvoiceTemplate.AsObject) {
    const req = new DeleteThirdPartyInvoiceTemplateRequst();
    req.setTemplateId(template.id);
    await rpc.thirdPartyInvoiceTemplate.delete(req, metadata());
    await tpiTemplateStore.delete(template);
  }

  @computed
  get thirdPartyTemplateByClient() {
    return tpiTemplateStore.getByClientId(this.props.clientId);
  }

  addCard = (e: any) => {
    this.adding = true;
  };
  closeNew = () => {
    this.adding = false;
  };

  render() {
    const { resource: client, rightMessage, disallowNew, onSelect, selRef, isLoading } = this.props;

    return (
      <TabContainer>
        <TabHeader>
          <Label>Third Party Invoices</Label>
        </TabHeader>

        {this.thirdPartyTemplateByClient.map((template) => (
          <ThirdPartyTemplateRow
            client={client}
            template={template}
            onRemove={() => this.removeTemplate(template)}
          />
        ))}

        {this.thirdPartyTemplateByClient.length === 0 && this.adding && (
          <ThirdPartyTemplateForm
            client={client}
            onRequestClose={() => (this.adding = false)}
            dfltCcy={ISOCurrencyName.AUD}
          />
        )}

        {this.thirdPartyTemplateByClient.length === 0 && !this.adding && (
          <LargeDottedButton onClick={() => (this.adding = true)}>
            ADD NEW THIRD PARTY INVOICE
          </LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}

interface ActualProps {
  clientId: string;
  selRef?: any;
  onSelect?: (provId: string) => void;
}

export default class Loader extends React.Component<ActualProps> {
  render() {
    return (
      <>
        <ResourceFetch type="Client" ids={this.props.clientId} force />
        <ResourceRender
          type="Client"
          ids={this.props.clientId}
          compSuccess={ThirdPartyTemplate}
          forwardedProps={this.props}
          denorm={true}
        />
      </>
    );
  }
}

const Small = styled.span`
  font-size: 10px;
  opacity: 0.4;
  margin-left: 10px;
`;
