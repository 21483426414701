import { AccountItem } from "sdk/dist/account_items_pb";
import { AccTableRowStatus, FiltersAccountTable } from "sdk/dist/accounts_table_pb";

export function aiStatusToText(status: AccountItem.Status): string {
  switch (status) {
    case AccountItem.Status.ACC_ITEM_CREATED:
      return "Created";
    case AccountItem.Status.ACC_ITEM_PROCESSING:
      return "Processing";
    case AccountItem.Status.ACC_ITEM_ERROR:
      return "Error";
    case AccountItem.Status.ACC_ITEM_COMPLETED:
      return "Completed";
    case AccountItem.Status.ACC_ITEM_CANCELLED:
      return "Cancelled";
    case AccountItem.Status.ACC_ITEM_REFUNDED:
      return "Refunded";
    default:
      return "Unknown";
  }
}

export function aiTypeToText(type: AccountItem.Type): string {
  switch (type) {
    case AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT:
      return "Credit Card";
    case AccountItem.Type.ACC_ITEM_FUND:
      return "Fund";
    case AccountItem.Type.ACC_ITEM_ORG_FUND_TOPUP:
      return "Org Fund Topup";
    case AccountItem.Type.ACC_ITEM_ORG_PAYOUT:
      return "Org Payout";
    case AccountItem.Type.ACC_ITEM_SUBSCRIPTION_FEE:
      return "Subscription fee";
    case AccountItem.Type.ACC_ITEM_THIRD_PARTY:
      return "Third Party";
    case AccountItem.Type.ACC_ITEM_PRODUCT:
      return "Product";
    default:
      return "Unknown";
  }
}

export function aiTypeToInternalType(acc_type: string): AccountItem.Type {
  switch (acc_type) {
    case "Charges":
      return AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT;
    case "Claims":
      return AccountItem.Type.ACC_ITEM_FUND;
    case "Org Fund Topup":
      return AccountItem.Type.ACC_ITEM_ORG_FUND_TOPUP;
    case "Org Payout":
      return AccountItem.Type.ACC_ITEM_ORG_PAYOUT;
    case "Subscription fee":
      return AccountItem.Type.ACC_ITEM_SUBSCRIPTION_FEE;
    case "Invoices":
      return AccountItem.Type.ACC_ITEM_THIRD_PARTY;
    case "Product":
      return AccountItem.Type.ACC_ITEM_PRODUCT;
    default:
      return AccountItem.Type.ACC_ITEM_TYPE_UNSPECIFIED;
  }
}

export function statusAccRowToText(status: AccTableRowStatus): string {
  switch (status) {
    case AccTableRowStatus.ACC_ROW_ALL:
      return "All";
    case AccTableRowStatus.ACC_ROW_UNPROCESSED:
      return "Unprocessed";
    case AccTableRowStatus.ACC_ROW_PROCESSING:
      return "Processing";
    case AccTableRowStatus.ACC_ROW_CREATED:
      return "Created";
    case AccTableRowStatus.ACC_ROW_COMPLETED:
      return "Completed";
    case AccTableRowStatus.ACC_ROW_CANCELLED:
      return "Cancelled";
    case AccTableRowStatus.ACC_ROW_PAYMENT_ERROR:
      return "Payment Error";
    case AccTableRowStatus.ACC_ROW_CLAIM_ERROR:
      return "Claim Error";
    case AccTableRowStatus.ACC_ROW_UNPAID_TPI:
      return "Unpaid (TPI)";
    case AccTableRowStatus.ACC_ROW_PAID_TPI:
      return "Paid (TPI)";
    default:
      return "Unknown";
  }
}

export function statusAccRowToDTO(status: string): AccTableRowStatus {
  switch (status) {
    case "All":
      return AccTableRowStatus.ACC_ROW_ALL;
    case "Unprocessed":
      return AccTableRowStatus.ACC_ROW_UNPROCESSED;
    case "Processing":
      return AccTableRowStatus.ACC_ROW_PROCESSING;
    case "Created":
      return AccTableRowStatus.ACC_ROW_CREATED;
    case "Completed":
      return AccTableRowStatus.ACC_ROW_COMPLETED;
    case "Cancelled":
      return AccTableRowStatus.ACC_ROW_CANCELLED;
    case "Payment Error":
      return AccTableRowStatus.ACC_ROW_PAYMENT_ERROR;
    case "Claim Error":
      return AccTableRowStatus.ACC_ROW_CLAIM_ERROR;
    case "Unpaid (TPI)":
      return AccTableRowStatus.ACC_ROW_UNPAID_TPI;
    case "Paid (TPI)":
      return AccTableRowStatus.ACC_ROW_PAID_TPI;
    default:
      return AccTableRowStatus.ACC_ROW_UNKNOWN;
  }
}

export function accItemTypeToText(accType: AccountItem.Type): string {
  switch (accType) {
    case AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT:
      return "Charges";
    case AccountItem.Type.ACC_ITEM_FUND:
      return "Claims";
    case AccountItem.Type.ACC_ITEM_ORG_FUND_TOPUP:
      return "Fund TopUps ";
    case AccountItem.Type.ACC_ITEM_ORG_PAYOUT:
      return "Org Payouts";
    case AccountItem.Type.ACC_ITEM_SUBSCRIPTION_FEE:
      return "Subscription";
    case AccountItem.Type.ACC_ITEM_THIRD_PARTY:
      return "Invoices";
    case AccountItem.Type.ACC_ITEM_PRODUCT:
      return "Products";
    default:
      return "Unspecified";
  }
}

export function accItemTypeToInternal(accType: string): AccountItem.Type {
  switch (accType) {
    case "Charges":
      return AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT;
    case "Claims":
      return AccountItem.Type.ACC_ITEM_FUND;
    case "Fund TopUps ":
      return AccountItem.Type.ACC_ITEM_ORG_FUND_TOPUP;
    case "Org Payouts":
      return AccountItem.Type.ACC_ITEM_ORG_PAYOUT;
    case "Subscription":
      return AccountItem.Type.ACC_ITEM_SUBSCRIPTION_FEE;
    case "Invoices":
      return AccountItem.Type.ACC_ITEM_THIRD_PARTY;
    case "Lyfe":
      return AccountItem.Type.ACC_ITEM_LYFE;
    case "Products":
      return AccountItem.Type.ACC_ITEM_PRODUCT;
    default:
      return AccountItem.Type.ACC_ITEM_TYPE_UNSPECIFIED;
  }
}

export function accTableTypeToText(type: FiltersAccountTable.Type): string {
  switch (type) {
    case FiltersAccountTable.Type.GROUP:
      return "Group";
    case FiltersAccountTable.Type.SINGLE:
      return "Single";
    case FiltersAccountTable.Type.PRODUCT:
      return "Product";
    default:
      return "Unknown";
  }
}

export function accTableTypeToDTO(type: string): FiltersAccountTable.Type {
  switch (type) {
    case "Group":
      return FiltersAccountTable.Type.GROUP;
    case "Single":
      return FiltersAccountTable.Type.SINGLE;
    case "Product":
      return FiltersAccountTable.Type.PRODUCT;
    default:
      return FiltersAccountTable.Type.UNSPECIFIED;
  }
}
