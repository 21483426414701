import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from "@emotion/styled";

import typography from "../../util/typography";
import * as ReduxDialogs from "../../redux/features/dialogs";
import { getResource } from "../../redux/features/resources/helpers";
import { breakpoints } from "../../util/consts";
import SectionsContainer from "../form/SectionsContainer";
import FooterContainer from "../form/FooterContainer";
import Section from "../form/Section";
import FormHeader from "../form/FormHeader";
import Button from "../Button";
import Funds from "./ClientActionDialog/Funds";
import Referrals from "./ClientActionDialog/Referrals";
import ResourceFetch from "../elements/ResourceFetch";
import ResourceRender from "../elements/ResourceRender";
import Dialog from "./Dialog";
import { ResourceTypes } from "../../redux/features/resources/definitions";
import { Cards } from "../stripe/CreditCard";
import { CreditCard } from "sdk/dist/credit_cards_pb";

interface Props {
  client: any;
  dialogId: string;
  data: any;
  dispatch: (func: any) => void;
}

interface State {
  resources: any;
}

class ClientPaymentMethodsDialog extends Component<Props, State> {
  render() {
    const { client } = this.props;
    return (
      <Dialog dialogId={this.props.dialogId}>
        <Container>
          <FormHeader title="Add Payment Options" />
          <Small>This is optional and can always be edited later</Small>
          <SectionsContainer>
            <ResourceFetch type="Client" ids={{ ID: client.ID }} />
            <ResourceRender
              type="Client"
              ids={{ ID: client.ID }}
              denorm={true}
              forwardedProps={this.props}
              compError={() => <div>error</div>}
              compSuccess={({ client }: any) => (
                <React.Fragment>
                  <Section>
                    <Cards ownerId={this.props.client.ID} ownerType={CreditCard.OwnerType.CLIENT} />
                  </Section>
                  <Section>
                    <Funds clientId={client} />
                  </Section>
                  <Section>
                    <Referrals client={client} />
                  </Section>
                </React.Fragment>
              )}
            />
          </SectionsContainer>
          <FooterContainer>
            <Button
              style={{ minWidth: 150 }}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={() => this.props.dispatch(ReduxDialogs.actions.close(this.props.dialogId))}
            >
              Done
            </Button>
          </FooterContainer>
        </Container>
      </Dialog>
    );
  }
}

function mapStateToProps(state: State, props: Props) {
  const client = getResource(
    state.resources,
    ResourceTypes.Client,
    {
      ID: props.data.client.ID
    },
    true
  );
  return { client };
}

export default compose(connect(mapStateToProps))(ClientPaymentMethodsDialog as any);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const Small = styled.div`
  ${typography.subtitle2};
  opacity: 0.6;
  letter-spacinh: 1.26px;
  margin-top: 1em;
`;
