
import { Component, Fragment } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import { differenceInMinutes, isWithinInterval } from "date-fns";
import memoize from "memoize-one";
import { breakpoints } from "./../../../util/consts";

// Utils
import { splitTime } from "./../../../util";

export default class CalendarTimeline extends Component {
  state = {
    now: new Date()
  };

  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.setState({ now: new Date() });
    }, 60 * 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  getTimeData = memoize((openingHours) => {
    const [startHour, startMinute] = splitTime(openingHours.OpensAt);
    const [endHour, endMinute] = splitTime(openingHours.ClosesAt);

    const startThirtyBlock = startHour * 2 + startMinute / 30;
    const endThirtyBlock = endHour * 2 + endMinute / 30;
    const numThirtyMinuteBlocks = endThirtyBlock - startThirtyBlock + 1;

    const openingHoursMap = [];
    for (let index = 0; index < numThirtyMinuteBlocks; index++) {
      const block = startThirtyBlock + index;
      const hours = Math.floor(block / 2);
      const hoursInTwelveHrTime = hours % 12 || 12;
      const parsedHours =
        hoursInTwelveHrTime < 10
          ? "0" + hoursInTwelveHrTime.toString()
          : hoursInTwelveHrTime.toString();
      const suffix = hours / 12 >= 1 ? "pm" : "am";
      const minutes = block % 2 === 1 ? "30" : "00";
      openingHoursMap.push({ hours: parsedHours, minutes, suffix });
    }

    return openingHoursMap;
  });

  getTimeLineStyle = memoize((date, openingHours, now) => {
    const base = 60;

    const openingTimeDate = new Date(date).setHours(...openingHours.OpensAt.split(":"), 0);
    const closingTimeDate = new Date(date).setHours(...openingHours.ClosesAt.split(":"), 0);
    const showTodayLine = isWithinInterval(now, { start: openingTimeDate, end: closingTimeDate });
    const minutesSinceOpen = differenceInMinutes(openingTimeDate, now);

    return {
      top: base + Math.abs(minutesSinceOpen) * 2 + "px",
      display: showTodayLine ? "initial" : "none"
    };
  });

  render() {
    const { now } = this.state;
    const { date, openingHours } = this.props;
    const openingHoursMap = this.getTimeData(openingHours);
    const timelineStyle = this.getTimeLineStyle(date, openingHours, now);

    return (
      <Container>
        <Spacer />
        <CurrentTime style={timelineStyle} />
        {openingHoursMap.map((t, i) => (
          <Block key={t.hours + ":" + t.minutes + t.suffix}>
            <Title>{t.hours + ":" + t.minutes + t.suffix}</Title>
            {i !== openingHoursMap.length - 1 && (
              <Fragment>
                <Subtitle first>{t.minutes === "00" ? 10 : 40}</Subtitle>
                <Subtitle>{t.minutes === "00" ? 20 : 50}</Subtitle>
              </Fragment>
            )}
          </Block>
        ))}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 49px;
  margin: 0 auto;
`;

const textBase = css`
  font-weight: 600;
  color: rgba(44, 46, 60, 0.38);
  letter-spacing: 0.09px;
  width: 100%;
`;

const Spacer = styled.div`
  height: 60px;
`;

const Block = styled.div`
  position: relative;
  height: 60px;

  &::after {
    content: "";
    border-bottom: #f4f8f9 2px solid;
    position: absolute;
    width: calc(100vw - 104px);
    pointer-events: none;
    left: 53px;
  }
`;

const Title = styled.span`
  ${textBase};
  color: grey;
  font-weight: bold;
  font-size: 11.82px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  top: -8px;
`;

const Subtitle = styled.span`
  ${textBase};
  font-weight: bold;
  font-size: 9.82px;
  line-height: 12px;
  text-align: end;
  position: absolute;
  top: ${({ first }) => (first ? "14px" : "34px")};

  &::after {
    content: "";
    border-bottom: #f4f8f9 2px dotted;
    position: absolute;
    width: 30px;
    pointer-events: none;
    top: calc(50% - 1px);
    left: 54px;
  }
`;

const CurrentTime = styled.div`
  height: 2px;
  background-color: #f00f51;
  position: absolute;
  z-index: 2;
  left: 95px;
  width: calc(100vw - 95px);

  &::before {
    content: "";
    height: 14px;
    width: 14px;
    border-radius: 7px;
    position: absolute;
    background-color: #f00f51;
    top: -6px;
    left: -6px;
  }

  ${breakpoints["phone-only"]} {
    left: 69px;
    width: calc(100vw - 69px);
  }
`;
