import { withFormik } from "formik";
import { values } from 'mobx';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import { metadata, rpc } from "../../../grpc";
import { GetLocationUserRequest } from "sdk/dist/location_user_pb";
import { Banner } from "../../../ui-kit/Banner";
import LoadingButton from "./../../../components/LoadingButton";
import {
  AccordianButton, AccordianContainer, AccordianForm, ButtonContainer, ButtonDetails, ButtonHead, NewHeader, NoSpacingIconButton, RolesContainer, Separator
} from "./../../../components/elements/AccordianElements";
import Select from "./../../../components/form/BaseSelect";
import CategoriesSelector from "./../../../components/form/CategoriesSelector";
import CheckRadioBox from "./../../../components/form/CheckRadioBox";
import Error from "./../../../components/form/Error";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";
import WeekSchedule from "./../../../components/form/WeekSchedule";
import ClearIcon from "./../../../components/icons/Clear";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { colors } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";

class LocationAccordian extends Component {
  state = {
    open: false
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleLocChange = (e) => {
    const { orgUser, setFieldValue, handleChange } = this.props;
    const loc = orgUser.Org.Locations.find((v) => v.ID === e.target.value);
    if (loc) {
      setFieldValue("ProviderSchedule", loc.Schedule);
      handleChange(e);
    }
  };

  async componentDidMount(){

    try {
    const req = new GetLocationUserRequest();
    req.setUserid(this.props.locUser.User.ID);
    req.setLocationid(this.props.locUser.Location.ID);
    const res = await rpc.locationUser.getLocationUser(req, metadata())
    this.props.values.Type = res.toObject().type
  }
    catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
  }

  renderContent() {
    const {
      id,
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      orgUser,
      locUser
    } = this.props;

    return (
      <AccordianForm
        id={`addstaff-${id}-form`}
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        {locUser ? null : (
          <Section>
            <Label>Location</Label>
            <Select
              id={`addstaff-${id}-Location`}
              name="Location"
              value={values.Location}
              onChange={this.handleLocChange}
              onBlur={handleBlur}
              error={touched.Location && errors.Location}
            >
              <option key="" value="" />
              {orgUser.Org.Locations.filter(
                (loc) => orgUser.Locations.findIndex((l2) => l2.Location.ID === loc.ID) < 0
              ).map((loc) => (
                <option key={loc.ID} value={loc.ID}>
                  {loc.Name}
                </option>
              ))}
            </Select>
            {!!errors.Location && touched.Location && <Error>{errors.Location}</Error>}
          </Section>
        )}

        <Section>
          <Label>Roles</Label>
          <RolesContainer>
            <CheckRadioBox
              id={`addstaff-${id}-Roles-Admin`}
              name="Roles"
              value="Admin"
              type="checkbox"
              values={values.Roles}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!locUser && !values.Location}
              error={touched.Roles && errors.Roles}
              // We can't delete this component because when user hits saves it will remove `admin` roles from his account. Therefore I added a new property to hide this field.
              // Below property was added to hide the button
              hidden
            >
              Admin
            </CheckRadioBox>
            <CheckRadioBox
              id={`addstaff-${id}-Roles-Receptionist`}
              name="Roles"
              value="Receptionist"
              type="checkbox"
              values={values.Roles}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!locUser && !values.Location}
              error={touched.Roles && errors.Roles}
            >
              Receptionist
            </CheckRadioBox>
            <CheckRadioBox
              id={`addstaff-${id}-Roles-Provider`}
              name="Roles"
              value="Provider"
              type="checkbox"
              values={values.Roles}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!locUser && !values.Location}
              error={touched.Roles && errors.Roles}
            >
              Provider
            </CheckRadioBox>
          </RolesContainer>
          {!!errors.Roles && touched.Roles && <Error>{errors.Roles}</Error>}
        </Section>
        <Section>
          {locUser === null ? null : (
            <React.Fragment>
              <Label>Status</Label>
              <RolesContainer>
                <CheckRadioBox
                  id={`addstaff-${id}-IsActive-Active`}
                  name="IsActive"
                  value="Active"
                  type="radio"
                  checked={values.IsActive === "Active"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.IsActive && errors.IsActive}
                >
                  Active
                </CheckRadioBox>
                <CheckRadioBox
                  id={`addstaff-${id}-IsActive-InActive`}
                  name="IsActive"
                  value="InActive"
                  type="radio"
                  checked={values.IsActive === "InActive"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.IsActive && errors.IsActive}
                >
                  Inactive
                </CheckRadioBox>
              </RolesContainer>
              {!!errors.IsActive && touched.IsActive && <Error>{errors.IsActive}</Error>}
            </React.Fragment>
          )}
        </Section>

        <Section>
          <Label htmlFor={`addstaff-${id}-ProviderCategories`}>Categories</Label>
          <CategoriesSelector
            id={`addstaff-${id}-ProviderCategories`}
            name="ProviderCategories"
            value={values.ProviderCategories}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.ProviderCategories && errors.ProviderCategories}
          />
          {!!errors.ProviderCategories && touched.ProviderCategories && (
            <Error>{errors.ProviderCategories}</Error>
          )}
        </Section>

        <Section>
          <WeekSchedule
            name="ProviderSchedule"
            value={values.ProviderSchedule}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.ProviderSchedule && errors.ProviderSchedule}
          />
          {!!errors.ProviderSchedule && touched.ProviderSchedule && (
            <Error>{errors.ProviderSchedule}</Error>
          )}
        </Section>

        {values.Roles.indexOf("Provider") >= 0 && process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== 'US' && (
          <React.Fragment>
            <Section>
              <Label htmlFor={`addstaff-${id}-ProviderFund_AUMedicare`}>
                Medicare Provider Number
              </Label>
              <Input
                id={`addstaff-${id}-ProviderFund_AUMedicare`}
                name="ProviderFund_AUMedicare"
                type="text"
                placeholder="000"
                value={values.ProviderFund_AUMedicare}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ProviderFund_AUMedicare && errors.ProviderFund_AUMedicare}
              />
              {!!errors.ProviderFund_AUMedicare && touched.ProviderFund_AUMedicare && (
                <Error>{errors.ProviderFund_AUMedicare}</Error>
              )}
            </Section>

            <Section>
              <Label htmlFor={`addstaff-${id}-ProviderFund_AUDVA`}>DVA Provider Number</Label>
              <Input
                id={`addstaff-${id}-ProviderFund_AUDVA`}
                name="ProviderFund_AUDVA"
                type="text"
                placeholder="000"
                value={values.ProviderFund_AUDVA}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ProviderFund_AUDVA && errors.ProviderFund_AUDVA}
              />
              {!!errors.ProviderFund_AUDVA && touched.ProviderFund_AUDVA && (
                <Error>{errors.ProviderFund_AUDVA}</Error>
              )}
            </Section>

            <Section>
              <Label htmlFor={`addstaff-${id}-ProviderFund_AUHICAPS`}>
                HICAPS/Medipass Provider Number
              </Label>
              <Input
                id={`addstaff-${id}-ProviderFund_AUHICAPS`}
                name="ProviderFund_AUHICAPS"
                type="text"
                placeholder="000"
                value={values.ProviderFund_AUHICAPS}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ProviderFund_AUHICAPS && errors.ProviderFund_AUHICAPS}
              />
              {!!errors.ProviderFund_AUHICAPS && touched.ProviderFund_AUHICAPS && (
                <Error>{errors.ProviderFund_AUHICAPS}</Error>
              )}
            </Section>
          </React.Fragment>
        )}

        { this.props.initialValues.IsActive === 'Active' &&
          this.props.initialValues.Roles.includes('Provider') &&
          this.props.values.IsActive === 'InActive' &&
          this.props.orgUser.Org.SubscriptionType === 'Pro' &&
          <Banner type='information'>
            We would recommend decreasing provider cap in case you deactivate the account. You can change the settings by going to organization business profile page to update the provider cap settings.
          </Banner>
        }

        <ButtonContainer>
          <LoadingButton
            style={{
              minWidth: 150
            }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
          >
            Save
          </LoadingButton>
        </ButtonContainer>
      </AccordianForm>
    );
  }

  render() {
    const { open } = this.state;
    const { locUser, closeNew } = this.props;

    return (
      <AccordianContainer>
        {!locUser ? null : (
          <AccordianButton onClick={this.toggleOpen}>
            <ButtonHead>{locUser.Location.Name}</ButtonHead>
            <Separator />
            <ButtonDetails>{getProviderID(locUser)}</ButtonDetails>
            <Separator />
            <ButtonDetails style={{ textAlign: "right" }}>
              {locUser.Roles.length > 1 ? "Multiple Roles" : locUser.Roles[0]}
            </ButtonDetails>
          </AccordianButton>
        )}
        {(open || !locUser) && (
          <React.Fragment>
            {locUser ? null : (
              <NewHeader>
                <ButtonHead>New Location</ButtonHead>
                <NoSpacingIconButton onClick={closeNew}>
                  <ClearIcon fill={colors.primary.main} />
                </NoSpacingIconButton>
              </NewHeader>
            )}
            {this.renderContent()}
          </React.Fragment>
        )}
      </AccordianContainer>
    );
  }
}

function getProviderID(locUser) {
  if (locUser.ProviderFund_AUMedicare && locUser.ProviderFund_AUMedicare.ProviderID) {
    return locUser.ProviderFund_AUMedicare.ProviderID;
  }
  if (locUser.ProviderFund_AUDVA && locUser.ProviderFund_AUDVA.ProviderID) {
    return locUser.ProviderFund_AUDVA.ProviderID;
  }
  if (locUser.ProviderFund_AUHICAPS && locUser.ProviderFund_AUHICAPS.ProviderID) {
    return locUser.ProviderFund_AUHICAPS.ProviderID;
  }
  return "N/A";
}

const mcareProvCoefs = [3, 5, 8, 4, 2, 1];
const mcarePLVMap = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "A": 10,
  "B": 11,
  "C": 12,
  "D": 13,
  "E": 14,
  "F": 15,
  "G": 16,
  "H": 17,
  "J": 18,
  "K": 19,
  "L": 20,
  "M": 21,
  "N": 22,
  "P": 23,
  "Q": 24,
  "R": 25,
  "T": 26,
  "U": 27,
  "V": 28,
  "W": 29,
  "X": 30,
  "Y": 31
};

const mcareCSIdx = ["Y", "X", "W", "T", "L", "K", "J", "H", "F", "B", "A"];
function checkMedicareProviderNo(pno) {
  if (!pno) {
    return true;
  }

  let pc = pno.trim();

  if (!/^[0-9]{5,6}[0-9ABCDEFGHJKLMNPQRTUVWXY][ABFHJKLTWXY]$/.test(pc)) {
    return false;
  }

  if (pc.length === 7) {
    pc = "0" + pc;
  }

  let val = 0;
  for (let i = 0; i < 6; i++) {
    val += mcareProvCoefs[i] * parseInt(pc[i]);
  }
  val += mcarePLVMap[pc[6]] * 6;
  return pc[7] === mcareCSIdx[val % 11];
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ locUser, orgUser }) => ({
    Location: !locUser ? "" : locUser.Location.ID,
    Roles: !locUser ? [] : locUser.Roles,
    // Type: "HEALTHCARE",
    IsActive: !locUser || locUser.IsActive ? "Active" : "InActive",
    // ProviderCategories: !locUser ? [] : locUser.ProviderCategories || [],
    ProviderCategories:
      locUser && Array.isArray(locUser.ProviderCategories)
        ? locUser.ProviderCategories.map((c) => c.ID)
        : [],
    ProviderSchedule: !locUser ? null : locUser.ProviderSchedule || locUser.Location.Schedule,
    ProviderFund_AUMedicare:
      !locUser || !locUser.ProviderFundProperties || !locUser.ProviderFundProperties.AUMedicare
        ? ""
        : locUser.ProviderFundProperties.AUMedicare.ProviderID || "",
    ProviderFund_AUDVA:
      !locUser || !locUser.ProviderFundProperties || !locUser.ProviderFundProperties.AUDVA
        ? ""
        : locUser.ProviderFundProperties.AUDVA.ProviderID || "",
    ProviderFund_AUHICAPS:
      !locUser || !locUser.ProviderFundProperties || !locUser.ProviderFundProperties.AUHICAPS
        ? ""
        : locUser.ProviderFundProperties.AUHICAPS.ProviderID || ""
  }),
  validationSchema: Yup.object().shape({
    Location: Yup.string().required("Location is required"),
    Roles: Yup.array()
      .of(Yup.string())
      .min(1, "At least one role must be specified")
      .required("Roles are required"),
    IsActive: Yup.string().required("Active required"),
    ProviderCategories: Yup.array()
      .of(Yup.string())
      .notRequired(),
    ProviderFund_AUMedicare: Yup.string()
      .test("medicare", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUDVA: Yup.string()
      .test("dva", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUHICAPS: Yup.string().notRequired()
  }),
  enableReinitialize: false,
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { dispatch, orgUser } = props;
    // Adding to location.
    try {
      const fundProperties = {};
      if (values.ProviderFund_AUMedicare !== "") {
        fundProperties.AUMedicare = {
          ProviderID: values.ProviderFund_AUMedicare.trim()
        };
      }
      if (values.ProviderFund_AUDVA !== "") {
        fundProperties.AUDVA = {
          ProviderID: values.ProviderFund_AUDVA.trim()
        };
      }
      if (values.ProviderFund_AUHICAPS !== "") {
        fundProperties.AUHICAPS = {
          ProviderID: values.ProviderFund_AUHICAPS.trim()
        };
      }
      await dispatch(
        ResourceActions.action(orgUser, "UpdateLocation", {
          Location: values.Location,
          IsActive: values.IsActive === "Active",
          Roles: values.Roles,
          ProviderCategories: values.Roles.indexOf("Provider") < 0 ? [] : values.ProviderCategories,
          ProviderSchedule: values.Roles.indexOf("Provider") < 0 ? null : values.ProviderSchedule,
          ProviderFundProperties: values.Roles.indexOf("Provider") < 0 ? null : fundProperties
        })
      );
      !props.locUser && props.closeNew();
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "StaffLocation"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(LocationAccordian);
