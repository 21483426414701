import { PureComponent } from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import memoized from "memoize-one";
import {
  format,
  startOfMonth,
  startOfWeek,
  getDate,
  isSameDay,
  addMonths,
  subMonths,
  eachDayOfInterval,
  endOfWeek,
  endOfMonth
} from "date-fns";

// utils
import { colors } from "../../../util/consts";
import typography from "../../../util/typography";

// Components
import IconButton from "../../IconButton";
import KeyboardArrowLeftIcon from "../../icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "../../icons/KeyboardArrowRight";

interface Props {
  date: Date;
  onChange: (d: Date) => void;
}

export class IFrameCalendar extends PureComponent<Props> {
  state = {
    month: new Date()
  };

  handleNextMonthClick = () => {
    this.setState((prevState: any) => ({ month: addMonths(prevState.month, 1) }));
  };

  handlePrevMonthClick = () => {
    this.setState((prevState: any) => ({ month: subMonths(prevState.month, 1) }));
  };

  getSelecetedMonthDates = memoized((date) =>
    eachDayOfInterval({
      start: startOfWeek(startOfMonth(date), { weekStartsOn: 0 }),
      end: endOfWeek(endOfMonth(date), { weekStartsOn: 0 })
    })
  );

  render() {
    const { month } = this.state;
    const { date } = this.props;
    const dates = this.getSelecetedMonthDates(month);

    return (
      <Container>
        <CalendarHeader>
          <CalendarTitle>{format(month, "MMMM yyyy")}</CalendarTitle>
          <div>
            <IconButton mini onClick={this.handlePrevMonthClick}>
              <KeyboardArrowLeftIcon size="small" />
            </IconButton>
            <IconButton mini onClick={this.handleNextMonthClick}>
              <KeyboardArrowRightIcon size="small" />
            </IconButton>
          </div>
        </CalendarHeader>
        <div>
          <CalendarRow>
            <CalendarItem>
              <CalendarDayText>S</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>M</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>T</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>W</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>T</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>F</CalendarDayText>
            </CalendarItem>
            <CalendarItem>
              <CalendarDayText>S</CalendarDayText>
            </CalendarItem>
          </CalendarRow>
          <DatesContainer>
            {dates.map((d) => (
              <CalendarItem
                key={d.toString()}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.onChange(d)}
              >
                <CalendarDateText
                  css={
                    isSameDay(date, d) &&
                    css`
                      background-color: ${colors.secondary.main};
                      border-radius: 50%;
                      height: 100%;
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: white;
                    `
                  }
                >
                  {getDate(d)}
                </CalendarDateText>
              </CalendarItem>
            ))}
          </DatesContainer>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  max-width: 366px;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CalendarTitle = styled.div`
  font-size: 14px;
  color: ${colors.surfaceText.highEmphasis};
  letter-spacing: 0.59px;
  font-weight: 600;
`;

const CalendarRow = styled.div`
  display: flex;
`;

const DatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CalendarItem = styled.div`
  flex: 0 0 calc(100% / 7);
  padding-top: calc(100% / 7);
  position: relative;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CalendarDayText = styled.span`
  ${typography.subtitle1};
  color: ${colors.surfaceText.lowEmphasis};
  font-weight: 600;
`;

const CalendarDateText = styled.span`
  ${typography.body1};
  line-height: 50%;
`;
