
import { PureComponent } from "react";
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

import { colors } from "./../util/consts";

const base = css`
  display: inline-block;
  padding: 6px 12px;
  font-size: 13.82px;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 18px;
  &:disabled {
    color: ${colors.blackText.disabled};
  }
`;

const text = (color) => css`
  color: ${color === "secondary" ? colors.secondary.main : colors.primary.main};
  background-color: transparent;
`;

const outlined = (color) => css`
  color: ${color === "secondary" ? colors.secondary.main : colors.tertiary.main};
  background-color: transparent;
  border: 1px solid ${color === "secondary" ? colors.secondary.main : colors.tertiary.main};
`;

const contained = (color) => css`
  color: ${colors.whiteText.highEmphasis};
  background-color: ${color === "secondary" ? colors.secondary.main : color};
`;

const variants = {
  text,
  outlined,
  contained
};

class Pill extends PureComponent {
  static propTypes = {
    color: PropTypes.oneOfType([PropTypes.string]),
    variant: PropTypes.oneOf(["text", "contained", "outlined"])
  };

  static defaultProps = {
    color: "primary",
    variant: "text"
  };

  getStyles = (color, variant) => css`
    ${base};
    ${variants[variant](color)}
  `;

  render() {
    const { color, variant, children, ...otherProps } = this.props;
    return <div css={this.getStyles(color, variant)} {...otherProps}>{children}</div>;
  }
}

export default Pill;
