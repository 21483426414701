import { css, jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { Component } from "react";

interface Props {
  title: string;
  data: number;
}

@observer
class Card extends Component<Props> {
  render() {
    const { title, data } = this.props;
    return (
      <div css={cardCss}>
        <h2>{data}</h2>
        <p>{title}</p>
      </div>
    );
  }
}

export default Card;

const cardCss = css`
  padding: 2rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
  display: flex;
  flex: 0 0 calc(calc(100% / 3) - 1rem);
  flex-direction: column;
  width: 30%;
  border: 2px solid #d0f2f3;
  text-align: center;
  background-color: white;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: bolder;
    letter-spacing: -0.015em;
  }

  @media (max-width: 40em) {
    flex: 0 0 calc(calc(100% / 2) - 1rem);
    width: 50%;
  }

  @media (max-width: 30em) {
    flex: 0 0 calc(calc(100%) - 1rem);
    width: 50%;
  }
`;

const blueCardCss = css`
  padding: 2rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
  display: flex;
  flex: 0 0 calc(calc(100% / 3) - 1rem);
  flex-direction: column;
  width: 30%;
  border: 2px solid #d0f2f3;
  text-align: center;
  background-color: #d0f2f3;

  @media (max-width: 40em) {
    flex: 0 0 calc(calc(100% / 2) - 1rem);
    width: 50%;
  }

  @media (max-width: 30em) {
    flex: 0 0 calc(calc(100%) - 1rem);
    width: 50%;
  }

  h2 {
    margin-bottom: 0.5rem;
    font-weight: bolder;
    letter-spacing: -0.015em;
  }
`;

const redCardCss = css`
  padding: 2rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
  display: flex;
  flex: 0 0 calc(calc(100% / 3) - 1rem);
  flex-direction: column;
  width: 30%;
  border: 2px solid #ffdfdf;
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;
    color: #ef3b24;
    font-weight: bolder;
    font-size: 54px;
    letter-spacing: -0.015em;
  }
  p {
    color: #ef3b24;
  }
  @media (max-width: 40em) {
    flex: 0 0 calc(calc(100% / 2) - 1rem);
    width: 50%;
  }

  @media (max-width: 30em) {
    flex: 0 0 calc(calc(100%) - 1rem);
    width: 50%;
  }
`;
