import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import LoadingIcon from "../../components/icons/Loading";
import { colors, hexToRgba } from "../../util/consts";
import typography from "../../util/typography";
import { useAsync } from "./../../hooks/useAsync";
import { sharedCountryService } from "./../../services/CountryService";

interface IProps {
  onChange: (target: any) => void;
  value?: string;
  disabled?: boolean;
  countryCodes?: string;
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  onBlur?: string;
  error?: boolean;
}

export const LocationSearchInput = (props: IProps) => {
  const countriesListFetcher = useAsync(async () =>
    sharedCountryService.getSupportedCountriesList()
  );

  return (
    <PlacesAutocomplete
      value={props.value || ""}
      onChange={(v) => props.onChange({ target: { id: props.id, name: props.name, value: v } })}
      searchOptions={{
        types: ["address"],
        componentRestrictions: {
          // Valid types string | string[] | undefined
          country: props.countryCodes
            ? props.countryCodes.toLowerCase()
            : countriesListFetcher.value
            ? (countriesListFetcher.value.map((c) => c.alpha2Code.toLowerCase()) as any)
            : [] // Types doesn't accept Array<string> but according to google docs Array<string> is valid value https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions.country
        }
      }}
      highlightFirstSuggestion={true}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Container
          css={css`
            ${suggestions.length > 0 && `border-radius: 4px 4px 0px 0px;`}
          `}
          disabled={props.disabled}
        >
          {React.createElement(Input, {
            ...getInputProps({
              placeholder: "Search Places ..."
            }),
            css: css`
              ${suggestions.length > 0 && `border-radius: 4px 4px 0px 0px;`};
            `,
            disabled: props.disabled
          })}
          {loading && (
            <LoadingContainer>
              <LoadingIcon color={colors.primary.main} size="small" />
            </LoadingContainer>
          )}
          {suggestions.length > 1 && (
            <SuggestionsList>
              {suggestions.map((suggestion, key) => (
                <ListItem
                  {...getSuggestionItemProps(suggestion)}
                  key={key}
                  css={css`
                    background-color: ${suggestion.active
                      ? hexToRgba(colors.primary.main, 0.05)
                      : null};
                  `}
                >
                  <ListItemText key={suggestion.id}>{suggestion.description}</ListItemText>
                </ListItem>
              ))}
            </SuggestionsList>
          )}
        </Container>
      )}
    </PlacesAutocomplete>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  border: 1px solid ${colors.primary[100]};
  border-radius: 4px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  /* padding: 10px 16px; */
  background-color: #fff;
  &:focus-within {
    border-color: ${colors.primary.main};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const SuggestionsList = styled.ul`
  position: absolute;
  z-index: 9;
  top: calc(100% + 1px);
  left: -1px;
  max-height: 260px;
  overflow-y: scroll;
  width: calc(100% + 2px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-left: 1px solid ${colors.primary.main};
  border-right: 1px solid ${colors.primary.main};
  border-bottom: 1px solid ${colors.primary.main};
  border-radius: 0px 0px 4px 4px;
`;

const ListItem = styled.span`
  height: 40px;
  padding: 14px;
  align-items: center;
  display: flex;
`;

const ListItemText = styled.span`
  ${typography.overline};
  letter-spacing: inherit;
`;

const Input = styled.input`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  width: 100%;
  padding: 10px 16px;
  color: ${colors.surfaceText.highEmphasis};
  outline: none;
  border: none;
  border-radius: 4px;
  flex: 0 1 auto;
  &:disabled {
    background-color: white;
    border: none;
  }
  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }
`;
