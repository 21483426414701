import styled from "@emotion/styled";
import qs from "qs";
import React, { Component, PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Paper } from "../../ui-kit/Paper";
import IconButton from "../IconButton";
import ChevronRight from "../icons/ChevronRight";
import Clear from "../icons/Clear";
import LockIcon from "./../../components/icons/Lock";
import { isOfType } from "./../../util";
import { breakpoints, colors } from "./../../util/consts";

interface Props {
  name: string;
  label?: string;
  component?: any;
  pageData?: any;
  extraProps?: any;
  setPage?: any;
  setData?: any;
  displayed?: any;
  history?: any;
  showInTree?: boolean;
}

export class Page extends PureComponent<Props> {
  static displayName = "Page";

  render() {
    const { pageData, extraProps, setPage, setData, component, displayed, history } = this.props;
    if (!displayed) {
      return null;
    } else {
      return React.createElement(component, {
        ...extraProps,
        pageData,
        setPage,
        setData,
        history
      });
    }
  }
}

interface PageGroupProps {
  label: string;
  children?: any;
  curPage?: any;
  extraProps?: any;
  setPage?: any;
  setData?: any;
  pageData?: any;
}

export class PageGroup extends PureComponent<PageGroupProps> {
  static displayName = "PageGroup";

  render() {
    const { children, curPage, extraProps, setPage, setData, pageData } = this.props;
    return React.Children.map(children, (ch) => {
      if (isOfType(ch, PageGroup)) {
        return React.cloneElement(ch, {
          curPage,
          extraProps,
          setPage,
          setData,
          pageData
        });
      } else if (isOfType(ch, Page)) {
        return React.cloneElement(ch, {
          extraProps,
          setPage,
          setData,
          pageData,
          displayed: ch.props.name === curPage
        });
      } else {
        return null;
      }
    });
  }
}

interface PageContainerProps {
  children?: any;
  initialPage?: any;
  initialData?: any;
  queryp?: any;
  location?: any;
  curPage?: any;
  entryBlacklist?: any;
  history?: any;
  noQuery?: any;
  blockNotSeen: any;
  extraProps: any;
  sidePanel: any;
  setIsDialogClosable?: any;
}

interface PageContainerState {
  curPage: any;
  waitFor: any;
  visited: any;
  isSidePanelOpen: any;
  sharedData: any;
  tree: any;
  pages: any;
  nonClosablePage: string[];
}

class PagesContainer extends Component<
  PageContainerProps & RouteComponentProps<PageContainerProps>,
  PageContainerState
> {
  constructor(props: PageContainerProps) {
    super(props as any);
    // Set the initial page based on the query parameter or first child.
    const { tree, pages } = PagesContainer.calcTreeState(props.children);
    this.state = {
      isSidePanelOpen: false,
      curPage: PagesContainer.getLocationPage(
        props,
        pages,
        props.initialPage || pages[0] ? props.initialPage || pages[0].props.name : "initial"
      ),
      waitFor: null,
      sharedData: {
        ...props.initialData
      },
      visited: [],
      tree,
      pages,
      nonClosablePage: ["verify"]
      // Hardcoding the value of nonClosablePage because we cannot pass a set of function
      // to `Page` component as all pages will be loaded in background when a dialog shows up. So
      // we can only rely on the state `curPage` to determine the name of the current page.
    };
    // If the page is on the initial blacklist then set the current page
    // to the first page.
    if (props.queryp) {
      const qp = qs.parse(props.location.search.slice(1));
      if ((props.entryBlacklist || []).indexOf(this.state.curPage) >= 0) {
        (this.state as any).curPage = pages[0].props.name;
        (this.state as any).waitFor = this.state.curPage;
      }
      if (!qp.hasOwnProperty(props.queryp) || qp[props.queryp] !== this.state.curPage) {
        qp[props.queryp] = this.state.curPage;
        props.history.replace(
          `${props.location.pathname}?${qs.stringify(qp)}${props.location.hash}`
        );
        (this.state as any).waitFor = this.state.curPage;
      }
    }
    // If the page is nonClosablePage then set isClosable to false
    this.setIsDialogClosableToFalse();
    (this.state as any).visited = [this.state.curPage];
  }

  componentDidUpdate(prevProps: PageContainerProps, prevStates: PageContainerState) {
    if (prevStates.curPage !== this.state.curPage) {
      this.setIsDialogClosableToFalse();
    }
  }

  static getDerivedStateFromProps(props: PageContainerProps, state: PageContainerState) {
    // Set the current page based on the latest query parameters or just
    // pass the current page.
    const { tree, pages } = PagesContainer.calcTreeState(props.children);
    const curPage = PagesContainer.getLocationPage(props, pages, state.curPage);
    if (state.waitFor !== null && state.waitFor !== curPage) {
      return null;
    }
    const visited = [...state.visited];
    if (visited.indexOf(curPage) < 0) {
      visited.push(curPage);
    }
    return {
      curPage,
      visited,
      tree,
      pages,
      waitFor: null
    };
  }
  static getLocationPage(props: PageContainerProps, pages: any, def: any) {
    // If using location/history then extract the current page from the query
    // parameters, otherwise return def.
    if (!props.noQuery) {
      const qp = qs.parse(props.location.search.slice(1));
      if (
        qp.hasOwnProperty(props.queryp) &&
        pages.findIndex((ch: any) => ch.props.name === qp[props.queryp]) >= 0
      ) {
        return qp[props.queryp];
      }
    }
    return def;
  }
  static calcTreeState(children: any) {
    const retTree: any = [];
    let retPages: any = [];

    React.Children.forEach(children, (ch) => {
      if (isOfType(ch, PageGroup)) {
        const { tree, pages } = PagesContainer.calcTreeState(ch.props.children);
        retTree.push({
          label: ch.props.label,
          showInTree: typeof ch.props.showInTree === "boolean" ? ch.props.showInTree : true,
          page: pages[0].props.name,
          children: tree
        });
        retPages = [...retPages, ...pages];
      } else if (isOfType(ch, Page)) {
        retTree.push({
          label: ch.props.label,
          showInTree: typeof ch.props.showInTree === "boolean" ? ch.props.showInTree : true,
          page: ch.props.name
        });
        retPages.push(ch);
      }
    });

    return {
      tree: retTree,
      pages: retPages
    };
  }
  setIsDialogClosableToFalse = () => {
    if (
      this.state.nonClosablePage.find((p) => p === this.state.curPage) &&
      this.props.setIsDialogClosable
    ) {
      this.props.setIsDialogClosable(false);
    }
  };
  setPage = (page: any, data: any, resetData?: any) => {
    const { location, history, queryp } = this.props;
    if (queryp) {
      const qp = qs.parse(location.search.slice(1));
      qp[queryp] = page;
      history.replace(`${location.pathname}?${qs.stringify(qp)}${location.hash}`);
      this.setState((prevState: any) => ({
        sharedData: !resetData ? { ...prevState.sharedData, ...data } : data,
        isSidePanelOpen: false
      }));
    } else {
      this.setState((prevState: any) => ({
        curPage: page,
        sharedData: !resetData ? { ...prevState.sharedData, ...data } : data,
        isSidePanelOpen: false
      }));
    }
  };
  setData = (data: any) => {
    this.setState((prevState) => ({
      sharedData: { ...prevState.sharedData, ...data }
    }));
  };
  pageActive(node: any) {
    const { curPage } = this.state;
    if (!node) {
      return false;
    }
    if (node.page === curPage) {
      return true;
    }
    if (!node.children) {
      return false;
    }
    for (let i = 0; i < node.children.length; i++) {
      if (this.pageActive(node.children[i])) {
        return true;
      }
    }
    return false;
  }
  renderSideTree(node: any, level: any) {
    const { visited } = this.state;
    const { blockNotSeen } = this.props;
    if (!node) {
      return null;
    }
    return node.map((nd: any, i: any) =>
      !nd.showInTree ? null : (
        <React.Fragment key={`${level}-${nd.page}`}>
          {level === 0 ? (
            <React.Fragment>
              {i === 0 ? null : <Divider />}
              <SideTopLevel
                onClick={() => this.setPage(nd.page, {})}
                marginTop={i !== 0 ? 30 : 0}
                selected={this.pageActive(nd)}
                disabled={blockNotSeen && visited.indexOf(nd.page) < 0}
              >
                <ButtonText>{`${i + 1}. ${nd.label}`}</ButtonText>
                {blockNotSeen && visited.indexOf(nd.page) < 0 ? (
                  <LockIcon width={17.74} height={17.74} />
                ) : null}
              </SideTopLevel>
            </React.Fragment>
          ) : (
            <SideNextLevel
              onClick={() => this.setPage(nd.page, {})}
              selected={this.pageActive(nd)}
              disabled={blockNotSeen && visited.indexOf(nd.page) < 0}
            >
              <ButtonText>{nd.label}</ButtonText>
              {blockNotSeen && visited.indexOf(nd.page) < 0 ? (
                <LockIcon width={12.82} height={12.82} />
              ) : null}
            </SideNextLevel>
          )}
          {this.renderSideTree(nd.children, level + 1)}
        </React.Fragment>
      )
    );
  }
  render() {
    const { isSidePanelOpen, curPage, sharedData, tree } = this.state;
    const { extraProps, sidePanel, children } = this.props;
    const isIframe = window.location.pathname.includes("/iframe/");

    const pages = React.Children.map(children, (ch) => {
      if (isOfType(ch, PageGroup)) {
        return React.cloneElement(ch, {
          curPage,
          extraProps,
          setPage: this.setPage,
          setData: this.setData,
          pageData: sharedData
        });
      } else if (isOfType(ch, Page)) {
        return React.cloneElement(ch, {
          extraProps,
          setPage: this.setPage,
          setData: this.setData,
          pageData: sharedData,
          displayed: ch.props.name === curPage
        });
      } else {
        return null;
      }
    });

    if (sidePanel) {
      return (
        <Paper width="full" color="secondary">
          <SideContainer>
            <SidePanel isOpen={isSidePanelOpen} isIframe={isIframe}>
              <MobileIconButton
                mini
                onClick={() => this.setState({ isSidePanelOpen: false })}
                style={{ top: 12, right: 24 }}
              >
                <Clear />
              </MobileIconButton>
              {this.renderSideTree(tree, 0)}
            </SidePanel>
            <SideContent>
              <MobileIconButton
                mini
                onClick={() => this.setState({ isSidePanelOpen: true })}
                style={{ top: 12, left: 0 }}
              >
                {<ChevronRight />}
              </MobileIconButton>
              {pages}
            </SideContent>
          </SideContainer>
        </Paper>
      );
    } else {
      return <Container>{pages}</Container>;
    }
  }
}

export default withRouter(PagesContainer);

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const SideContainer = styled.div`
  position: relative;
  max-width: 800px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
`;

const SidePanel = styled.div<{ isOpen: boolean; isIframe: boolean }>`
  z-index: 1;
  top: ${({ isIframe }) => (isIframe ? 0 : "4.313rem")};
  background-color: #f5f5f5;

  ${breakpoints["phone-only"]} {
    transform: translateX(${({ isOpen }) => (isOpen ? "0%" : "-100%")});
    transition: transform 500ms ease;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 60px 24px 48px;
  }

  ${breakpoints["tablet-up"]} {
    position: sticky;
    margin-right: 36px;
    padding: 60px 0 48px;
    align-self: flex-start;
  }
`;

const SideContent = styled.div`
  flex: 1 1 auto;
  width: 0;
  padding: 60px 0 48px;
`;

const SideTopLevel = styled.button<{ marginTop: number; selected: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: ${colors.primary.main};
  font-size: 17.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: 13px;
  opacity: ${({ selected }) => (selected ? 1 : 0.38)};
`;

const SideNextLevel = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: ${colors.primary.main};
  font-size: 12.82px;
  letter-spacing: 0.27px;
  line-height: 20px;
  margin-bottom: 7px;
  opacity: ${({ selected }) => (selected ? 1 : 0.38)};
`;

const Divider = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 150px;
  border: 1px solid rgba(44, 46, 60, 0.05);
  margin-top: 30px;
`;

const ButtonText = styled.div`
  text-select: none;
`;

const MobileIconButton = styled(IconButton)`
  position: absolute;

  ${breakpoints["tablet-up"]} {
    display: none;
  }
`;
