/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var applications_and_tokens_pb = require('./applications_and_tokens_pb.js')
const proto = {};
proto.proto = require('./physitrack_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.PhysitrackApiV1Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.PhysitrackApiV1PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.TokenAvailableRequest,
 *   !proto.proto.TokenAvailableResponse>}
 */
const methodDescriptor_PhysitrackApiV1_TokenAvailable = new grpc.web.MethodDescriptor(
  '/proto.PhysitrackApiV1/TokenAvailable',
  grpc.web.MethodType.UNARY,
  proto.proto.TokenAvailableRequest,
  proto.proto.TokenAvailableResponse,
  /**
   * @param {!proto.proto.TokenAvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TokenAvailableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.TokenAvailableRequest,
 *   !proto.proto.TokenAvailableResponse>}
 */
const methodInfo_PhysitrackApiV1_TokenAvailable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.TokenAvailableResponse,
  /**
   * @param {!proto.proto.TokenAvailableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TokenAvailableResponse.deserializeBinary
);


/**
 * @param {!proto.proto.TokenAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.TokenAvailableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TokenAvailableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.PhysitrackApiV1Client.prototype.tokenAvailable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.PhysitrackApiV1/TokenAvailable',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV1_TokenAvailable,
      callback);
};


/**
 * @param {!proto.proto.TokenAvailableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TokenAvailableResponse>}
 *     Promise that resolves to the response
 */
proto.proto.PhysitrackApiV1PromiseClient.prototype.tokenAvailable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.PhysitrackApiV1/TokenAvailable',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV1_TokenAvailable);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.PhysitrackApiV2Client =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.PhysitrackApiV2PromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetClientDetailsRequest,
 *   !proto.proto.GetClientDetailsResponse>}
 */
const methodDescriptor_PhysitrackApiV2_GetClientsDetails = new grpc.web.MethodDescriptor(
  '/proto.PhysitrackApiV2/GetClientsDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.GetClientDetailsRequest,
  proto.proto.GetClientDetailsResponse,
  /**
   * @param {!proto.proto.GetClientDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetClientDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetClientDetailsRequest,
 *   !proto.proto.GetClientDetailsResponse>}
 */
const methodInfo_PhysitrackApiV2_GetClientsDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.GetClientDetailsResponse,
  /**
   * @param {!proto.proto.GetClientDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetClientDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetClientDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.GetClientDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetClientDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.PhysitrackApiV2Client.prototype.getClientsDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.PhysitrackApiV2/GetClientsDetails',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV2_GetClientsDetails,
      callback);
};


/**
 * @param {!proto.proto.GetClientDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetClientDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.PhysitrackApiV2PromiseClient.prototype.getClientsDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.PhysitrackApiV2/GetClientsDetails',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV2_GetClientsDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateClientDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PhysitrackApiV2_UpdateClientsDetails = new grpc.web.MethodDescriptor(
  '/proto.PhysitrackApiV2/UpdateClientsDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateClientDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.UpdateClientDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateClientDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PhysitrackApiV2_UpdateClientsDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.UpdateClientDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateClientDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.PhysitrackApiV2Client.prototype.updateClientsDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.PhysitrackApiV2/UpdateClientsDetails',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV2_UpdateClientsDetails,
      callback);
};


/**
 * @param {!proto.proto.UpdateClientDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.PhysitrackApiV2PromiseClient.prototype.updateClientsDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.PhysitrackApiV2/UpdateClientsDetails',
      request,
      metadata || {},
      methodDescriptor_PhysitrackApiV2_UpdateClientsDetails);
};


module.exports = proto.proto;

