import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { AsyncButton } from "../ui-kit/AsyncButton";
import { FlexBox, FlexItem } from "../ui-kit/FlexBox";
import { AppIcon } from "../ui-kit/icons/AppIcon";
import CancelIcon from "../ui-kit/icons/CancelIcon";
import { Paper } from "../ui-kit/Paper";
import { Typography } from "../ui-kit/Typography";

interface IProps {
  id: string;
}

export const SubscriptionUpgradeMessage = (props: IProps) => {
  const [isMessageVisible, toggleMessageVisibility] = useState(true);
  const history = useHistory();

  if (!isMessageVisible) {
    return null;
  }
  return (
    <Paper color="neutral" rounded="sm">
      <Container>
        <Button onClick={() => toggleMessageVisibility(false)}>
          <CancelIcon />
        </Button>
        <FlexBox alignItems="center" justifyContent="between" wrap="wrap">
          <FlexItem>
            <TextContainer>
              <Typography.Body noPadding>
                Upgrade to Lyfe
                <SubscriptionButton
                  onClick={() => history.push(`/admin/orgs/${props.id}/edit?page=subtype`)}
                >
                  <AppIcon.Blue size="small" />{" "}
                  <Typography.Body color="blue" noPadding>
                    basic
                  </Typography.Body>{" "}
                </SubscriptionButton>
                or
                <SubscriptionButton
                  onClick={() => history.push(`/admin/orgs/${props.id}/edit?page=subtype`)}
                >
                  <AppIcon size="small" />{" "}
                  <Typography.Body color="primary" noPadding>
                    pro
                  </Typography.Body>{" "}
                </SubscriptionButton>
                to access the premium features that will super charge your business.
              </Typography.Body>
            </TextContainer>
          </FlexItem>

          <FlexItem>
            <AsyncButton
              variant="tertiary"
              rounded="full"
              size="md"
              onClick={() => history.push(`/admin/orgs/${props.id}/edit?page=subtype`)}
            >
              VIEW SUBSCRIPTION OPTIONS
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </Container>
    </Paper>
  );
};

const TextContainer = styled.div`
  div,
  svg {
    vertical-align: bottom;
    display: inline-block;
  }
`;

const Container = styled.div`
  padding: 1rem 1.5rem;
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  right: 4px;
  top: 3px;
`;

// CSS for Subscription link button
const SubscriptionButton = styled.button`
  padding: 0 3px;
`;
