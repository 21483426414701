import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { metadata, rpc } from "../grpc";
import { GetCurrenciesRequest } from "sdk/dist/currencies_pb";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class CurrencyService {
  @grpcResponseHandler()
  async getCurrencies(country?: string) {
    const req = new GetCurrenciesRequest();
    if (country) {
      const strValue = new StringValue();
      strValue.setValue(country);

      req.setCountry(strValue);
    }

    const res = await rpc.currencies.getCurrencies(req, metadata());
    return res.toObject().currenciesList;
  }
}

export const sharedCurrencyService = new CurrencyService();
