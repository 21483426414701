import * as grpcWeb from "grpc-web";
import { toastStore } from "../stores/toast-store";
import { Logger } from "./Logger";

const logger = new Logger("gRPC Service");

export const grpcResponseHandler = (props?: { successMessage?: string }): MethodDecorator => {
  // https://stackoverflow.com/questions/29775830/how-to-implement-a-typescript-decorator
  return function (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const childFunction = descriptor.value;

    descriptor.value = function (this: any, ...args: any[]) {
      const result = childFunction.apply(this, args);
      if (result instanceof Promise) {
        return result
          .then((res) => {
            props && props.successMessage && toastStore.success(props.successMessage);

            return res;
          })
          .catch((error: grpcWeb.Error) => {
            logger.error(
              `Class ${target.constructor.name}. Method ${propertyKey.toString()} - ${
                error.message
              }`,
              error
            );
            toastStore.grpcError(error);
          });
      }
      return descriptor;
    };
  };
};
