import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import * as Yup from "yup";
import { metadata, rpc } from "../../grpc";
import { useAsync } from "../../hooks/useAsync";
import { IOrganization } from "../../pages/create-organization/form/IOrganization";
import apiService, { APIError, HTTPMethod } from "../../redux/services/api";
import {
  AffiliateCodeRequest,
  SetAffiliateCodeRequest,
  SetAffiliateCodeResponse
} from "sdk/dist/affiliate_code_pb";
import { UserUIType } from "sdk/dist/location_user_pb";
import { sharedAddressService } from "../../services/AddressService";
import { sharedLocationService } from "../../services/LocationService";
import { sharedOrganizationService } from "../../services/OrganizationService";
import { sharedOrganizationUserService } from "../../services/OrganizationUserService";
import { stripeStore } from "../../stores/stripe-store";
import { toastStore } from "../../stores/toast-store";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Dropdown } from "../../ui-kit/Dropdown";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { TextInput } from "../../ui-kit/TextInput";
import { FormContainer } from "../../ui-kit/form/FormContainer";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "../../util/address";
import Form from "./../../components/form/Form";
import { sharedCurrencyService } from "./../../services/CurrencyService";
import { userService } from "./../../services/UserService";
import { tracking } from "./../../util/tracking";

interface IProps {
  organization: Partial<IOrganization>;
  setOrgDetails: (o: Partial<IOrganization>) => void;
  previousStep: () => void;
  nextStep: () => void;
}

export const PaymentForm = observer((props: IProps) => {
  const params = useParams<{ appName: string }>();
  const schema = Yup.object<IOrganization>().shape({
    TaxDetails: Yup.object<IOrganization["TaxDetails"]>().shape({
      EntityName:
        props.organization.SubscriptionType !== "Starter"
          ? Yup.string().required("Entity name is required")
          : Yup.string().notRequired()
    })
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<IOrganization>({
    resolver: yupResolver(schema),
    defaultValues: {
      TaxDetails: {
        ABN:
          props.organization.TaxDetails && props.organization.TaxDetails.ABN
            ? props.organization.TaxDetails.ABN
            : "",
        EntityName:
          props.organization.TaxDetails && props.organization.TaxDetails.EntityName
            ? props.organization.TaxDetails.EntityName
            : ""
      },
      Settings: {
        DefaultCurrency:
          props.organization.Settings && props.organization.Settings.DefaultCurrency
            ? props.organization.Settings.DefaultCurrency
            : ""
      }
    }
  });

  useEffect(() => {
    (async () => {
      stripeStore.loadScriptOnDom();
    })();
  }, []);

  useEffect(() => {
    watch();
  }, [watch]);

  const loginStatusFetcher = useAsync(async () => {
    return await userService.isUserLoggedIn();
  });

  const currencyFetcher = useAsync(async () => {
    const res = await sharedCurrencyService.getCurrencies(props.organization.Country);
    if (res && res.length > 0) {
      const countries = res.map((c) => c.isoName);
      setValue("Settings.DefaultCurrency", countries[0]);
      return countries;
    }
  });

  const onSubmit = async (data: IOrganization) => {
    try {
      // https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1171
      // hubspot integration - Disabled for now since API is throwing 403 http error
      // if (loginStatusFetcher.value && loginStatusFetcher.value.isLoggedIn) {
      //   await sharedHubspotService.addClient(
      //     props.organization.Name,
      //     props.organization.ContactEmail,
      //     props.organization.ContactPhone,
      //     props.organization.Address
      //   );
      // }
      // end hubspot integration

      let addrID;
      const address = props.organization.Address || data.Address;
      if (address) {
        const prDet = await findPlaceDetails(address, "Address");
        const { $Metadata, ...restObj } = {
          OwnerType: "Organisation",
          ...getAddressResourceFromPlaceDetails(prDet, address)
        };
        const prRes = await sharedAddressService.createAddress(restObj);
        if (prRes.Payload) {
          addrID = prRes.Payload.ID;
        } else {
          return setError("Address", {
            type: "validate",
            message: "Address is invalid"
          });
        }
      }

      // Create new organisation
      const orgRes = await sharedOrganizationService.createOrganization({
        Name: props.organization.Name.trim(),
        Description: props.organization.Description.trim(),
        ContactEmail: props.organization.ContactEmail.trim(),
        ContactPhone: props.organization.ContactPhone.trim(),
        Address: addrID || props.organization.AddressID,
        Logo: props.organization.Logo,
        Banner: props.organization.Banner,
        // By default we set the subscription to `Pro` & ProviderCap to `1`
        SubscriptionType: params.appName.toLowerCase() === "athletehub" ? "Pro" : "Starter",
        ProviderCap: 1,
        Settings: {
          RequireBookingApproval: true,
          NotificationDaysBefore: 1,
          PenaltyFeeFixed: 0,
          PenaltyFeeVar: 1000,
          PenaltyTimePeriod: 48,
          ClientPaysCCFee: false,
          ThirdPartyTACs: "",
          DefaultCurrency: data.Settings.DefaultCurrency
        },
        Integrations: {},
        Type: params.appName.toLowerCase() === "athletehub" ? "AthleteHub" : "Lyfe",
        TaxDetails: {
          ...data.TaxDetails,
          ABN:
            data.TaxDetails && data.TaxDetails.ABN
              ? data.TaxDetails.ABN.replace(/\s+/g, "").trim()
              : ""
        }
      });

      await AddAffiliateCode(
        props.organization.Affiliate ? props.organization.Affiliate : "",
        orgRes.PayloadID,
        !!(loginStatusFetcher.value && loginStatusFetcher.value.isLoggedIn),
        (m) =>
          setError("Affiliate", {
            type: "validate",
            message: m
          })
      );

      let newLocationAddressID;
      const newLocationAddress = props.organization.Address || data.Address;
      if (newLocationAddress) {
        const prDet = await findPlaceDetails(newLocationAddress, "Address");
        const { $Metadata, ...obj } = {
          OwnerType: "Location",
          ...getAddressResourceFromPlaceDetails(prDet, newLocationAddressID)
        };
        const prRes = await sharedAddressService.createAddress(obj);
        if (prRes.Payload) {
          newLocationAddressID = prRes.Payload.ID;
        } else {
          return setError("Address", {
            type: "validate",
            message: "Address is invalid"
          });
        }
      }

      // Create a location using business address
      const newLocation = await sharedLocationService.create({
        Address: newLocationAddressID,
        Org: orgRes.Payload.ID,
        Banner: orgRes.Payload.Banner.ID,
        Phone: orgRes.Payload.ContactPhone,
        Schedule: props.organization.Schedule
      });

      // Create new staff account for admin where basic information will be used from using business owner details
      const user = await sharedLocationService.addUser(newLocation.ID, {
        IsActive: true,
        ProviderCategories: [],
        ProviderSchedule: props.organization.Schedule,
        Roles: params.appName.toLowerCase() === "athletehub" ? ["Admin", "Provider"] : ["Admin"],
        User: {
          Email: props.organization.ContactEmail || "",
          FirstName: props.organization.Name || "",
          LastName: "",
          MobileCountryCode: props.organization.Country || "",
          MobileNumber: props.organization.ContactPhone || ""
        },
        ProviderSchedule: {
          Friday: { ClosesAt: "17:00:00", OpensAt: "09:00:00" },
          Monday: { ClosesAt: "17:00:00", OpensAt: "09:00:00" },
          Saturday: {},
          Sunday: {},
          Thursday: { ClosesAt: "17:00:00", OpensAt: "09:00:00" },
          Tuesday: { ClosesAt: "17:00:00", OpensAt: "09:00:00" },
          Wednesday: { ClosesAt: "17:00:00", OpensAt: "09:00:00" }
        }
      });

      // Set user type to Fitness
      await sharedOrganizationUserService.setUserType(
        orgRes.Payload.Id,
        user.User.ID,
        UserUIType.FITNESS
      );

      props.nextStep();
    } catch (error: any) {
      toastStore.error(error.message);
    } finally {
      tracking.organizationStepperFormCompleted("payment");
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer
        label="Payment Details"
        description="Please fill out the tax information relating to your business"
      >
        <TextInput
          label="Tax Information"
          subLabel="Please fill out the tax information relating to your business"
          placeholder="Business Entity Name"
          value={getValues("TaxDetails.EntityName")}
          onChange={(v) => setValue("TaxDetails.EntityName", v || "")}
          error={
            errors.TaxDetails && errors.TaxDetails.EntityName
              ? errors.TaxDetails.EntityName.message
              : ""
          }
          required={getValues("SubscriptionType") !== "Starter"}
        />

        <TextInput
          label="Business Identification Number (optional)"
          value={getValues("TaxDetails.ABN")}
          onChange={(v) => setValue("TaxDetails.ABN", v || "")}
        />

        <Dropdown
          label="Default Currency"
          subLabel="This currency will be used as the default for all transactions, this can later be changed"
          options={
            currencyFetcher.value ? currencyFetcher.value.map((c) => ({ label: c, value: c })) : []
          }
          value={getValues("Settings.DefaultCurrency")}
          onChange={(v) => setValue("Settings.DefaultCurrency", v || "")}
        />

        {!(
          loginStatusFetcher.value &&
          loginStatusFetcher.value.isLoggedIn &&
          !loginStatusFetcher.value.isActive
        ) ? (
          <TextInput
            label="Affiliate Code"
            placeholder="Enter you discount code"
            value={getValues("Affiliate")}
            onChange={(v) => setValue("Affiliate", v || "")}
            error={errors.Affiliate ? errors.Affiliate.message : ""}
          />
        ) : (
          ""
        )}

        <FlexBox direction="row" justifyContent="between">
          <FlexItem>
            <AsyncButton
              onClick={props.previousStep}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Previous
            </AsyncButton>
          </FlexItem>

          <FlexItem>
            <AsyncButton
              onClick={() => undefined}
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Submit
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </FormContainer>
    </Form>
  );
});

const AddAffiliateCode = async (
  code: string,
  orgId: string,
  isLoggedIn: boolean,
  setError: (m: string) => void
) => {
  if (isLoggedIn) {
    //Add Affiliate Code
    if (code) {
      const reqSetAffiliateOrg = new SetAffiliateCodeRequest();
      reqSetAffiliateOrg.setAffiliateCode(code.trim());
      reqSetAffiliateOrg.setOrgId(orgId);
      const resSetAffiliateOrg = await rpc.affiliateService.setOrgCode(
        reqSetAffiliateOrg,
        metadata()
      );

      switch (resSetAffiliateOrg.getAffiliateCodeStatus()) {
        case SetAffiliateCodeResponse.Status.APPLIED_SUCCESSFULLY:
          toastStore.success("Affiliate code applied successfully");
          break;
        default:
          toastStore.error("Affiliate code not applied");
          break;
      }
    }
  } else {
    // add affiliate code to db
    const reqAffiliate = new AffiliateCodeRequest();
    reqAffiliate.setAffiliateCode(code ? code.trim() : "");
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    const affiliateCodeExists = resAffiliate.toObject().exists;
    if (!affiliateCodeExists) {
      setError("Not a valid code");
      throw apiService.errorProcess(
        new APIError(HTTPMethod.Get, "affiliateService Grpc", {
          Status: `Error`,
          StatusCode: 400,
          Errors: [{ UserMessage: "Not a valid code" }]
        })
      );
    }

    //check if affiliate code successful or not
    if (code) {
      const reqSetAffiliateOrg = new SetAffiliateCodeRequest();
      reqSetAffiliateOrg.setAffiliateCode(code.trim());
      reqSetAffiliateOrg.setOrgId(orgId);
      const resSetAffiliateOrg = await rpc.affiliateService.setOrgCode(
        reqSetAffiliateOrg,
        metadata()
      );

      switch (resSetAffiliateOrg.getAffiliateCodeStatus()) {
        case SetAffiliateCodeResponse.Status.APPLIED_SUCCESSFULLY:
          toastStore.success("Affiliate code applied successfully");
          break;
        default:
          toastStore.error("Affiliate code not applied");
          break;
      }
    }
  }
};
