import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Error from "./../../components/form/Error";
import { createForm, updateArrayItem, removeArrayItem, addArrayItem } from "./../../forms/forms";
import {
  ThirdPartyInvoice,
  ThirdPartyInvoiceDetails,
  ThirdPartyInvoiceLineItem,
  TaxType,
  UpdateThirdPartyInvoiceRequest
} from "sdk/dist/third_party_invoices_pb";
import { LoadingIndicator } from "./../../util/loading";
import { AccordianContainer, AccordianForm, ButtonContainer } from "../elements/AccordianElements";
import Input from "../form/Input";
import Label from "../form/Label";
import Section from "../form/Section";
import LoadingButton from "../LoadingButton";
import SearchInput from "../form/SearchInput";
import { Booking, BookingClient } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "./../../grpc";
import { NewLineItemForm, emptyTpiLineItem } from "./NewLineItemForm";
import LargeDottedButton from "../LargeDottedButton";
import { chargeZero, chargeToProto } from "./../../util/money";
import { accItemStore } from "./../../stores/acc_item-store";
import { thirdPartyInvStore } from "./../../stores/third_party_inv-store";
import {
  HistoryThirdParty,
  ListHistoryLocationThirdPartyRequest,
  CreateHistoryThirdPartyRequest,
  ListHistoryClientThirdPartyRequest
} from "sdk/dist/history_third_parties_pb";
import { tpiDetailsSchema } from "./NewThirdPartyForm";
import { FundType } from "sdk/dist/offerings_pb";
import { toastStore } from "../../stores/toast-store";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

interface Props {
  booking: Booking.AsObject;
  client: BookingClient.AsObject;
  invoice: ThirdPartyInvoice.AsObject;
  dfltCcy: ISOCurrencyName;
  onRequestClose: () => void;
}

@observer
export class EditThirdPartyForm extends React.Component<Props> {
  @observable
  private history = new Array<HistoryThirdParty.AsObject>();

  @observable
  private indicator = new LoadingIndicator();

  async componentDidMount() {
    const { booking, client } = this.props;
    if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE) {
      try {
        const req = new ListHistoryClientThirdPartyRequest();
        req.setLocationId(booking.locationId);
        req.setClientId(client.clientId);
        const res = await rpc.historyThirdParties.listHistoryClient(req, metadata());

        if (res.toObject().itemsList.length > 0) {
          this.history = res.toObject().itemsList;
        } else {
          const req = new ListHistoryLocationThirdPartyRequest();
          req.setLocationId(booking.locationId);
          const res = await rpc.historyThirdParties.listHistoryLocation(req, metadata());
          this.history = res.toObject().itemsList;
        }
      } catch (err) {
        toastStore.grpcError(err);
      }
    } else {
      try {
        const req = new ListHistoryLocationThirdPartyRequest();
        req.setLocationId(booking.locationId);
        const res = await rpc.historyThirdParties.listHistoryLocation(req, metadata());
        this.history = res.toObject().itemsList;
      } catch (err) {
        toastStore.grpcError(err);
      }
    }
  }

  private onSubmit = async (details: ThirdPartyInvoiceDetails.AsObject) => {
    const { booking, client, onRequestClose, invoice } = this.props;
    // Edit third party invoice
    await this.indicator.while(async () => {
      const req = new UpdateThirdPartyInvoiceRequest();
      const detailsMsg = new ThirdPartyInvoiceDetails();
      req.setThirdPartyInvoiceId(invoice.id);
      req.setBookingId(booking.id);
      req.setClientId(client.clientId);
      detailsMsg.setClaimId(details.claimId);
      detailsMsg.setCompanyAbn(details.companyAbn.replace(/\s+/g, ""));
      detailsMsg.setCompanyEmailAddress(details.companyEmailAddress);
      detailsMsg.setCompanyName(details.companyName);
      detailsMsg.setProviderId(details.providerId);
      detailsMsg.setLineItemsList(
        details.lineItemsList.map((item) => {
          const itemMsg = new ThirdPartyInvoiceLineItem();
          itemMsg.setCode(item.code);
          itemMsg.setDescription(item.description);
          itemMsg.setCharge(chargeToProto(item.charge!));
          itemMsg.setTaxType(item.taxType);
          return itemMsg;
        })
      );
      req.setDetails(detailsMsg);
      const res = await rpc.thirdPartyInvoices.update(req, metadata());
      res.toObject().invoicesList.map((inv) => {
        accItemStore.add(inv.accountItem!);
        thirdPartyInvStore.add(inv);
      });
    });
    onRequestClose();
    // Create history hird party
    const reqHistory = new CreateHistoryThirdPartyRequest();
    reqHistory.setAbn(details.companyAbn.replace(/\s+/g, ""));
    reqHistory.setEmail(details.companyEmailAddress);
    reqHistory.setName(details.companyName);
    reqHistory.setLocationId(booking.locationId);
    if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE) {
      reqHistory.setClientId(client!.clientId);
    }
    await rpc.historyThirdParties.create(reqHistory, metadata());
  };

  initialLineItem(): Array<ThirdPartyInvoiceLineItem.AsObject> {
    const { invoice } = this.props;
    const initialLineItems = new Array<ThirdPartyInvoiceLineItem.AsObject>();
    invoice.details!.lineItemsList.map((item) => {
      initialLineItems.push({
        code: item.code,
        description: item.description,
        charge: item.charge,
        taxType: item.taxType
      });
    });
    return initialLineItems;
  }

  render() {
    const { invoice } = this.props;
    const Form = createForm<ThirdPartyInvoiceDetails.AsObject>();

    const initial: Partial<ThirdPartyInvoiceDetails.AsObject> = {
      claimId: invoice.details!.claimId,
      companyAbn: invoice.details!.companyAbn,
      companyEmailAddress: invoice.details!.companyEmailAddress,
      companyName: invoice.details!.companyName,
      providerId: invoice.details!.providerId,
      lineItemsList: this.initialLineItem()
    };
    return (
      <AccordianContainer>
        <Form
          initial={initial}
          schema={tpiDetailsSchema}
          onSubmit={this.onSubmit}
          component={AccordianForm}
        >
          {({ fields, updateField, errors }) => (
            <>
              <Section>
                <Label>Entity Name</Label>
                <SearchInput
                  items={this.history.map((tpi) => ({ value: tpi, label: tpi.name }))}
                  inputValue={fields.companyName || ""}
                  allowFreeText={true}
                  onInputValueChange={(value) => updateField({ companyName: value })}
                  setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                    updateField({
                      companyName: value.name,
                      companyAbn: value.abn,
                      companyEmailAddress: value.email
                    });
                  }}
                  placeholder="Enter the name of the entity you wish to invoice"
                />
                {!!errors.companyName && <Error>{errors.companyName}</Error>}
              </Section>
              <Section>
                <Label>Entity ABN</Label>
                <SearchInput
                  items={this.history.map((tpi) => ({ value: tpi, label: tpi.abn }))}
                  inputValue={fields.companyAbn || ""}
                  allowFreeText={true}
                  onInputValueChange={(value) => updateField({ companyAbn: value })}
                  setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                    updateField({
                      companyName: value.name,
                      companyAbn: value.abn,
                      companyEmailAddress: value.email
                    });
                  }}
                  placeholder="Enter the entity's ABN (optional)"
                />
                {!!errors.companyAbn && <Error>{errors.companyAbn}</Error>}
              </Section>
              <Section>
                <Label>Entity Email Address</Label>
                <SearchInput
                  items={this.history.map((tpi) => ({ value: tpi, label: tpi.email }))}
                  inputValue={fields.companyEmailAddress || ""}
                  allowFreeText={true}
                  onInputValueChange={(value) => updateField({ companyEmailAddress: value })}
                  setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                    updateField({
                      companyName: value.name,
                      companyAbn: value.abn,
                      companyEmailAddress: value.email
                    });
                  }}
                  placeholder="Enter the entity's Email Address (optional)"
                />
                {!!errors.companyEmailAddress && <Error>{errors.companyEmailAddress}</Error>}
              </Section>
              <Section>
                <Label>Provider ID</Label>
                <Input
                  type="text"
                  placeholder="Enter the ID of the provider (optional)"
                  value={fields.providerId}
                  onChange={(event: any) => updateField({ providerId: event.currentTarget.value })}
                />

                {!!errors.providerId && <Error>{errors.providerId}</Error>}
              </Section>
              <Section>
                <Label>Claim or ID number</Label>
                <Input
                  type="text"
                  placeholder="Enter the ID of the claim (optional)"
                  value={fields.claimId}
                  onChange={(event: any) => {
                    updateField({ claimId: event.currentTarget.value });
                  }}
                />
                {!!errors.claimId && <Error>{errors.claimId}</Error>}
              </Section>
              <Section>
                <Label>Line Items</Label>
                {fields.lineItemsList &&
                  fields.lineItemsList.map((lineItem: ThirdPartyInvoiceLineItem.AsObject, i) => (
                    <div key={i}>
                      <NewLineItemForm
                        key={i + "_lineitem"}
                        initial={lineItem}
                        onChange={(event: any) => {
                          updateField({
                            lineItemsList: updateArrayItem(fields.lineItemsList, i, event)
                          });
                        }}
                        onRequestDelete={
                          i === 0
                            ? undefined
                            : () =>
                                updateField({
                                  lineItemsList: removeArrayItem(fields.lineItemsList, i)
                                })
                        }
                        dfltCcy={this.props.dfltCcy}
                      />
                      <Error key={i + "_code"}>{errors["lineItemsList[" + i + "].code"]}</Error>
                      <Error key={i + "_description"}>
                        {errors["lineItemsList[" + i + "].description"]}
                      </Error>
                      <Error key={i + "_charge"}>{errors["lineItemsList[" + i + "].charge"]}</Error>
                    </div>
                  ))}

                <LargeDottedButton
                  type="button"
                  onClick={() =>
                    updateField({
                      lineItemsList: addArrayItem(
                        fields.lineItemsList,
                        emptyTpiLineItem(this.props.dfltCcy)
                      )
                    })
                  }
                >
                  ADD NEW ITEM
                </LargeDottedButton>
                {errors.lineItemsList && <Error>{errors.lineItemsList}</Error>}
              </Section>
              <ButtonContainer>
                <LoadingButton
                  style={{ minWidth: 150 }}
                  loading={this.indicator.isLoading()}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Save
                </LoadingButton>
              </ButtonContainer>
            </>
          )}
        </Form>
      </AccordianContainer>
    );
  }
}
