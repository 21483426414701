import * as Sentry from "@sentry/browser";
import * as grpcWeb from "grpc-web";
import { AppConfig } from "../AppConfig";

export class Logger {
  private service: string;

  constructor(_service: string) {
    this.service = _service;
  }

  warn(message: string) {
    console.warn(`${this.service}: ${message}`);
  }

  log(message: string) {
    console.log(`${this.service}: ${message}`);
  }

  error(message: string, error?: Error | grpcWeb.Error) {
    if (AppConfig.BOOKLYFE_ENVIRONMENT !== "development") {
      return error ? Sentry.captureException(error) : Sentry.captureMessage(message);
    }
    return console.error(`${this.service}: ${message}`);
  }

  debug(message: string) {
    console.debug(`${this.service}: ${message}`);
  }

  info(message: string) {
    console.info(`${this.service}: ${message}`);
  }
}
