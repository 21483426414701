import React, { Component } from "react";
import styled from "@emotion/styled";
import { format, isSameDay, addDays } from "date-fns";

// utils
import { breakpoints, colors, hexToRgba } from "./../util/consts";
import typography from "./../util/typography";
import ConsumerAptDialog from "../components/dialogs/ConsumerAptDialog";

// components
import Divider from "./../components/Divider";
import { restLocationService } from '../services/LocationService';
import { Modal } from "../ui-kit/Modal";
import { observer } from "mobx-react";
import { ActivityIndicator } from "../ui-kit/ActivityIndicator";
import { ILocation } from "../IRestApiResponse";

interface Props {
  resource: {
    Clients: any[];
    CreatedAt: string;
    EndDateTimeTZ: string;
    FriendlyID: string;
    FundRebate: number;
    FundTax: number;
    GroupOfferings: any[];
    ID: string;
    LastModifiedAt: string;
    Location: string;
    LyfeCreditCardFee: number;
    LyfeCreditCardTax: number;
    MaxGroupSize: number;
    MayChargeCancellationFee: true;
    Provider: {CreatedAt: string, FirstName: string, ID: string, LastModifiedAt: string, LastName: string};
    StartDateTimeTZ: string;
    Status: string;
    SubtotalCharge: number;
    SubtotalTax: number;
    Timezone: string;
    TotalCharge: number;
    TotalTax: number;
    Type: string;
    end_datetime: string;
    start_datetime: string;
  };
}

interface IState {
  loading: boolean;
  showAppointmentDialog: boolean;
  location?: ILocation;
}

@observer
export class AppointmentsCard extends Component<Props, IState> {

  state: IState = {
    location: undefined,
    showAppointmentDialog: false,
    loading: false,
  }

  async componentDidMount() {
    this.setState({loading: true});
    const location = await restLocationService.fetchLocation(this.props.resource.Location);
    this.setState({location: location, loading: false})
  }

  render() {

    if (this.state.loading || this.state.location === undefined) {
      return (
        <ActivityIndicator />
      )
    }
    const location: any = this.state.location;
    const offerings = this.props.resource.Type === "Single" ? this.props.resource.Clients[0].Offerings : this.props.resource.GroupOfferings;
    let image = undefined;
    if (location.Banner) {
      image = location.DownloadURL;
    } else if (location.Org && location.Org.Banner) {
      image = location.Org.Banner.DownloadURL;
    }

    let dayString = "";
    if (isSameDay(new Date(this.props.resource.start_datetime), new Date())) {
      dayString = "Today ";
    } else if (isSameDay(new Date(this.props.resource.start_datetime), addDays(new Date(), 1))) {
      dayString = "Tomorrow ";
    }


    return (
      <>
        <Container
          onClick={() => this.setState({showAppointmentDialog: true})}
        >
          <Modal
            onClose={() => {this.setState({showAppointmentDialog: false})}}
            isOpen={this.state.showAppointmentDialog}
          >
            <ConsumerAptDialog  booking={this.props.resource} location={location} />

            </Modal>
          <Image
            style={{
              backgroundImage: `url(${image})`
            }}
          />
          <Content>
            <CenterLeftContainer>
              <FakeProfile>
                {this.props.resource.Provider.FirstName[0]}
                {this.props.resource.Provider.LastName[0]}
              </FakeProfile>
              <ProviderName>
                {this.props.resource.Provider.FirstName} {this.props.resource.Provider.LastName}
              </ProviderName>
            </CenterLeftContainer>
            <Divider style={{ margin: "16px 0" }} />
            {location && (
              <>
                <div>
                  <BusinessType>
                    {(offerings || []).map((o: any, i:number) => (i > 0 ? ", " : "") + o.Offering.Name)}
                  </BusinessType>
                  <BusinessName>{location.Name}</BusinessName>
                  <BusinessAddress>{location.Address && location.Address.Raw}</BusinessAddress>
                </div>
                <Divider style={{ margin: "16px 0" }} />
              </>
            )}
            <div>
              <MainText
                style={{
                  fontWeight: 600,
                  marginBottom: 6
                }}
              >
                {dayString}
                {format(new Date(this.props.resource.start_datetime), "hh:mmaaaaa")}m
              </MainText>
              <MainText>{format(new Date(this.props.resource.start_datetime), "iiii do MMMM")}</MainText>
            </div>
          </Content>
        </Container>
      </>
    );
  }
}


const Container = styled.div`
  position: relative;
  border: 1px solid #f3f5fd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 ${hexToRgba(colors.primary.main, 0.1)};
  margin: 10px;
  flex-basis: 100%;
  cursor: pointer;

  ${breakpoints["tablet-up"]} {
    flex-basis: calc(50% - 20px);
  }

  ${breakpoints["laptop-up"]} {
    flex-basis: calc(33.33% - 20px);
  }

  ${breakpoints["desktop-up"]} {
    flex-basis: calc(25% - 20px);
  }
`;

const Image = styled.div`
  padding-top: 66.67%;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Content = styled.div`
  padding: 16px;
`;

const CenterLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProviderName = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 1.54px;
  ${typography.elipse};
`;

const BusinessType = styled.div`
  ${typography.overline};
  color: ${colors.secondary.main};
  font-weight: 600;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin-bottom: 5px;
  ${typography.elipse};
`;
const BusinessName = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 19px;
  ${typography.elipse};
`;
const BusinessAddress = styled.div`
  ${typography.body2};
  letter-spacing: 0.5px;
  line-height: 18px;
  line-height: 18px;
  opacity: 0.6;
  ${typography.elipse};
`;

const MainText = styled.div`
  ${typography.body1};
  line-height: 20px;
`;

const FakeProfile = styled.div`
  ${typography.overline};
  color: ${colors.whiteText.highEmphasis};
  letter-spacing: 0.09px;
  line-height: 32px;
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${colors.primary.main};
  margin-right: 18px;
`;
