import React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  variant?: "primary" | "danger" | "information" | "secondary";
  size?: "small" | "medium" | "large";
}

const CancelIcon = ({ size, variant, ...props }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${computeSize(size)} ${computeColor(variant)} fill-current cursor-pointer`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
};

CancelIcon.Solid = ({ size, variant, ...props }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${size === "small" ? "h-4 w-4" : "h-12 w-12"} ${computeColor(
        variant
      )} fill-current`}
      fill="none"
      viewBox="0 0 19 19"
      stroke="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default CancelIcon;

const computeColor = (variant: IProps["variant"]) => {
  switch (variant) {
    case "primary":
    default:
      return "text-primary-900";
    case "information":
      return "text-tertiary-200";
    case "danger":
      return "text-red-500";
    case "secondary":
      return "text-secondary-700";
  }
};

const computeSize = (variant: IProps["size"]) => {
  switch (variant) {
    case "small":
    default:
      return "h-4 w-4";
    case "large":
      return "h-12 w-12";
    case "medium":
      return "h-5 w-5";
  }
};
