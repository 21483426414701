import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import * as Yup from "yup";

import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import * as ReduxDialogs from "./../../redux/features/dialogs";

// components
import AdminSubHeader from "../../components/elements/AdminSubHeader";
import ResourceTable from "./../../components/elements/ResourceTable";
import { Label as RTLabel } from "./../../components/elements/ResourceTableCard";

import ResourceFetch from "./../../components/elements/ResourceFetch";
import ResourceRender from "./../../components/elements/ResourceRender";
import ResourcePageError from "./../../components/elements/ResourcePageError";

import { toPriceString } from "./../../util";

import { mapFund } from "./../../util/consts";
import { parseFormError } from "./../../util/form";
import { toastStore } from "../../stores/toast-store";

interface Props {
  resource: any;
  dispatch: (action: any)=>void;
}

interface State {
  filter: {
    Filter: string;
    Location: string;
    SortBy: string;
    SortDir: string;
    Archived: string;
  }
}
class OrgLocationOfferings extends Component<Props> {
  tableRef = React.createRef<any>();

  state = {
    filter: {
      Filter: "All",
      SortBy: "Name",
      SortDir: "Asc",
      Archived: "False"
    }
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (state.filter.Location !== props.resource.ID) {
      return {
        filter: {
          ...state.filter,
          Location: props.resource.ID
        }
      };
    }
    return null;
  };

  handleAdd = () => {
    this.props.dispatch(
      ReduxDialogs.offeringDialog(null, this.props.resource, () => {
        if (this.tableRef.current) {
          this.tableRef.current.refreshTable();
        }
      })
    );
  };

  handleSubPage = (sp:string) => {
    let flt = "";
    switch (sp) {
      case "Services & Products":
        flt = "All";
        break;
      case "Services":
        flt = "Services";
        break;
      case "Products":
        flt = "Products";
        break;
    }
    this.setState({
      filter: {
        ...this.state.filter,
        Filter: flt
      }
    });
  };

  handleSort = (sortColumn:string, sortDesc:string) => {
    this.setState({
      filter: {
        ...this.state.filter,
        SortBy: sortColumn,
        SortDir: sortDesc ? "Desc" : "Asc"
      }
    });
  };

  handleSearch = (val:string) => {
    this.setState({
      filter: {
        ...this.state.filter,
        Search: val
      }
    });
  };

  handleSelect = (res:any) => {
    if (!res) { return; }
    const { resource, dispatch } = this.props;
    dispatch(ReduxDialogs.offeringDialog(res.ID, resource, () => {}));
  };

  checkArchive = (action:string) => {
    const { dispatch } = this.props;
    return new Promise((resolve, reject) =>
      dispatch(ReduxDialogs.openAction(
        `Do you wish to ${action} this service/product?`,
        null,
        null,
        (check, yay, notify)=>resolve(yay),
        "Yes",
        "No"
      ))
    );
  }

  handleArchive = async (res:any) => {
    if (!res) { return; }
    const { dispatch } = this.props;
    try {
      const dr = await this.checkArchive("archive");
      if (!dr) { return; }
      await dispatch(ResourceActions.action(res, "Remove"));
      toastStore.success("Successfully archived product/service.");;
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
    }
  };

  handleRestore = async (res:any) => {
    if (!res) { return; }
    const { dispatch } = this.props;
    try {
      const dr = await this.checkArchive("restore");
      if (!dr) { return; }
      await dispatch(ResourceActions.action(res, "Remove"));
      toastStore.success("Successfully archived product/service.");
    } catch (err) {
      parseFormError(err, {}, () => {}, this.props);
    }
  };

  pullProductsAndServices = () => {
    const { resource } = this.props;
    this.props.dispatch(ReduxDialogs.openPullServices(null, resource.Org, resource, async () => {
      if (this.tableRef.current) {
        await this.tableRef.current.refreshTable();
      }
    }));
  }

  switchArchived = () => {
    this.setState({ filter: { ...this.state.filter, Archived: this.state.filter.Archived==="False"?"True":"False" } });
  }

  render() {
    const { filter } = this.state;

    const cols = [
      "Name",
      "Categories",
      "Type",
      "Level",
      "InternalCode",
      "Funds",
      "ServiceDuration",
      "Fee",
      "TaxRate",
      "Visibility"
    ];

    const ropts = filter.Archived==="False" ? [
      {
        label: "Edit",
        onClick: this.handleSelect
      },
      {
        label: "Archive",
        onClick: this.handleArchive
      }
    ] : [
      {
        label: "Restore",
        onClick: this.handleRestore
      }
    ];
    return (
      <React.Fragment>
        <AdminSubHeader
          type="table"
          subHeaders={["Services & Products", "Services", "Products"]}
          onSubPage={this.handleSubPage}
          settings={[
            {
              label: "Pull From Other Location",
              onClick: this.pullProductsAndServices
            },
            {
              label: filter.Archived==="False" ? "See Archived" : "See Standard",
              onClick: this.switchArchived
            }
          ]}
          onAdd={this.handleAdd}
          onSearch={this.handleSearch}
        />
        <ResourceTable
          ref={this.tableRef}
          listType="LocationOfferingsPage"
          resourceType="Offering"
          filter={filter}
          onSort={this.handleSort}
          columns={cols}
          columnInfo={{
            Type: {
              type: "Label",
              label: "Product/Service",
              weight: 0.5,
              min: 150,
              max: 200,
            },
            Name: {
              type: "TitleLabel",
              label: "Name",
              weight: 1.5,
              min: 200,
            },
            Level: {
              type: "Component",
              label: "Level",
              weight: 0.1,
              min: 170,
              max: 200,
              noOrder: true,
              component:React.memo(({ resource: res }: any) => {
              return <p className="text-xs">{res.level != undefined ? res.level : "N/A"}</p>
              }),
            },
            ServiceDuration: filter.Archived==="False" ? {
              type: "Input",
              label: "Duration",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => res.ServiceDuration || "N/A",
              prop: "ServiceDuration",
              validator: Yup.number().required("Duration is required"),
              finalVal: (v:string) => parseInt(v),
              inputProps: {
                type: "number"
              }
            } : {
              type: "Label",
              label: "Duration",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => res.ServiceDuration || "N/A"
            },
            Categories: {
              type: "Component",
              label: "Category",
              weight: 1.5,
              min: 150,
              component: React.memo(({ resource: res }: any) => {
                if (res.Categories.length === 0) {
                  return <RTLabel>None</RTLabel>;
                } else if (res.Categories.length > 1) {
                  return <RTLabel>Multiple</RTLabel>;
                } else {
                  return (
                    <ResourceRender
                      type="Taxonomy"
                      ids={{ ID: res.Categories[0] }}
                      compSuccess={({ resource: tax }:any) => <RTLabel>{tax.Value}</RTLabel>}
                    />
                  );
                }
              }),
              noOrder: true
            },
            InternalCode: filter.Archived==="False" ? {
              type: "Input",
              label: "Code",
              weight: 0.1,
              min: 150,
              max: 200,
              prop: "InternalCode",
              validator: Yup.string(),
              inputProps: {
                type: "text"
              }
            } : {
              type: "Label",
              label: "Code",
              weight: 0.1,
              min: 150,
              max: 200
            },
            Funds: {
              type: "Label",
              label: "Fund(s)",
              weight: 0.1,
              min: 200,
              max: 400,
              format: (res:any) => Object.keys(res.FundProperties).map((v)=>mapFund(v)).join("/")||"None",
              noOrder: true
            },
            Fee: filter.Archived==="False" ? {
              type: "Input",
              label: "Fee",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => toPriceString(res.Fee, true),
              prop: "Fee",
              validator: Yup.number().required("Fee is required"),
              initialVal: (v:number) => toPriceString(v, true),
              finalVal: (v:string) => Math.round(100*parseFloat(v)),
              inputProps: {
                type: "string"
              }
            } : {
              type: "Label",
              label: "Fee",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => toPriceString(res.Fee, true)
            },
            TaxRate: filter.Archived==="False" ? {
              type: "Input",
              label: "Tax Rate(%)",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => toPriceString(res.tax_rate, true),
              prop: "tax_rate",
              validator: Yup.number().required("tax_rate is required"),
              initialVal: (v:number) => toPriceString(v, true),
              finalVal: (v:string) => Math.round(100*parseFloat(v)),
              inputProps: {
                type: "string"
              }
            } : {
              type: "Label",
              label: "tax_rate",
              weight: 0.1,
              min: 150,
              max: 200,
              format: (res:any) => toPriceString(res.tax_rate, true)
            },
            Visibility: {
              type: "Pill",
              label: "Visibility",
              weight: 0.1,
              min: 170,
              max: 200,
              // eslint-disable-next-line
              format: (res:any) => (res.Status === "Deleted" ? "Archived" : (res.Visible ? "Visible" : "Hidden"))
            }
          }}
          rowOptions={ropts}
        />
      </React.Fragment>
    );
  }
}

const ConnectedOrgLocationOfferings = compose(
  connect()
  )(OrgLocationOfferings as any);

export default React.memo(({ match, history }:any) => (
  <React.Fragment>
    <ResourceFetch type="Location" ids={{ ID: match.params.lid }} extraData={{ Context: "Admin" }} />
    <ResourceRender
      type="Location"
      ids={{ ID: match.params.lid }}
      denorm={true}
      compSuccess={ConnectedOrgLocationOfferings}
      compError={ResourcePageError}
      forwardedProps={{ history }}
    />
  </React.Fragment>
));
