import React, { Component } from "react";
import styled from "@emotion/styled";

import ResourceListFetch from "./../../components/elements/ResourceListFetch";
import ResourceListRender from "./../../components/elements/ResourceListRender";
import ResourceRender from "./../../components/elements/ResourceRender";
import ResourceTableCard from "./../../components/elements/ResourceTableCard";

import IconButton from "./../../components/IconButton";
import DropdownCheck from "./../../components/DropdownCheck";

import Down from "./../../components/icons/Down";

class ResourceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curCtx: props.tblCtx,
      activeColumns: props.columns,
      sortColumn: props.columns[0],
      sortDesc: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tblCtx && props.tblCtx !== state.curCtx) {
      return {
        curCtx: props.tblCtx,
        activeColumns: props.columns,
        sortColumn: props.columns[0],
        sortDesc: false
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { sortColumn, sortDesc } = this.state;
    if (
      prevProps.tblCtx === this.props.tblCtx &&
      (sortColumn !== prevState.sortColumn || sortDesc !== prevState.sortDesc)
    ) {
      this.props.onSort && this.props.onSort(sortColumn, sortDesc);
    }
  }

  handleHeaderClick = (cl) => () => {
    const { sortColumn, sortDesc } = this.state;
    const { columnInfo } = this.props;
    if (columnInfo[cl].noOrder) {
      return;
    }

    if (sortColumn === cl) {
      this.setState({
        sortDesc: !sortDesc
      });
    } else {
      this.setState({
        sortColumn: cl,
        sortDesc: false
      });
    }
  };

  handleChangeActiveColumns = (val) => {
    if (val.length === 0) {
      return;
    }
    this.setState({ activeColumns: val });
  };

  renderHeader(tableColumnSizes) {
    const { columns, columnInfo } = this.props;
    const { activeColumns, sortColumn, sortDesc } = this.state;

    return (
      <HeaderRow>
        <HeaderDropdownColumn key="drop">
          <DropdownCheck
            align="right"
            options={columns.map((cl) => ({
              label: columnInfo[cl].label,
              value: cl
            }))}
            value={activeColumns}
            onChange={this.handleChangeActiveColumns}
          >
            <AddButton>+</AddButton>
          </DropdownCheck>
        </HeaderDropdownColumn>
        {activeColumns.map((cl, i) => (
          <HeaderColumn
            key={cl}
            twd={Math.round(tableColumnSizes[i])}
            twMin={columnInfo[cl].min}
            twMax={columnInfo[cl].max}
          >
            <CardContainerHeader
              onClick={this.handleHeaderClick(cl)}
              noOrder={columnInfo[cl].noOrder}
            >
              {columnInfo[cl].label}
              {sortColumn === cl && (
                <Down style={{ transform: !sortDesc ? null : "rotate(180deg)" }} />
              )}
            </CardContainerHeader>
          </HeaderColumn>
        ))}
      </HeaderRow>
    );
  }

  renderContent() {
    const { activeColumns } = this.state;
    const { list, resourceType } = this.props;
    return (list.IDs || []).map((rid) => (
      <ResourceRender
        key={rid}
        type={resourceType}
        ids={rid}
        denorm={true}
        compSuccess={ResourceTableCard}
        forwardedProps={{ ...this.props, activeColumns }}
      />
    ));
  }

  render() {
    const { list, columnInfo } = this.props;
    const { activeColumns } = this.state;

    const totalWeight = activeColumns.reduce((a, c) => a + columnInfo[c].weight, 0);
    const minTableSize =  activeColumns.reduce((a, c)=>a + columnInfo[c].min, 0);
    let tableSize = Math.max(window.innerWidth - 72, 1280);
    let extraTableSize = Math.max(tableSize-minTableSize-64, 0);
    const tableColumnSizes = activeColumns.map((c)=>{
      let colSize = columnInfo[c].min + Math.max((extraTableSize * columnInfo[c].weight) / totalWeight, 0);
      if (columnInfo[c].max) {
        colSize = Math.min(colSize, columnInfo[c].max);
      }
      return colSize;
    });
    const initTableSize = tableColumnSizes.reduce((a, c)=>a+c, 0) + 64;
    extraTableSize = tableSize - initTableSize;
    if (extraTableSize > 0) {
      const totalExtraWeight = activeColumns.reduce((a, c) => a + (!columnInfo[c].max ? columnInfo[c].weight : 0), 0);
      activeColumns.forEach((c, i)=>{
        if (columnInfo[c].max) {
          tableColumnSizes[i] += (extraTableSize * columnInfo[c].weight) / totalExtraWeight;
        }
      });
    } else if (extraTableSize < 0) {
      tableSize = initTableSize;
    }

    tableSize = Math.round(tableSize);

    // Render the table.
    return (
      <>
        <TableContainer colRows={list.IDs.length === 0}>
          <Table tableSize={tableSize} width={tableSize}>
            <tbody>
              {this.renderHeader(tableColumnSizes)}
              {this.renderContent()}
            </tbody>
          </Table>
        </TableContainer>
        {list.IDs.length === 0 && <NoItemsContainer>No Items Found</NoItemsContainer>}
      </>
    );
  }
}

const ResourceTableLoading = React.memo(() => <OtherContainer>Loading</OtherContainer>);

const ResourceTableError = React.memo(({ list }) => <OtherContainer>Error</OtherContainer>);

export default class ResourceTableWrapper extends Component {
  fetchRef = React.createRef();

  async refreshTable() {
    if (this.fetchRef.current) {
      await this.fetchRef.current.getList(true);
    }
  }

  render() {
    const { listType, filter, ...otherProps } = this.props;
    return (
      <React.Fragment>
        <ResourceListFetch ref={this.fetchRef} type={listType} listctx={filter} force />
        <ResourceListRender
          type={listType}
          listctx={filter}
          compSuccess={ResourceTable}
          compLoading={ResourceTableLoading}
          compError={ResourceTableError}
          forwardedProps={otherProps}
        />
      </React.Fragment>
    );
  }
}

const OtherContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 212px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #f4f8f9;
`;

const NoItemsContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 286px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #f4f8f9;
`;

const TableContainer = styled.div`
  padding: 0 36px;
  min-width: 100%;
  ${({ colRows }) => !colRows && "min-height: calc(100vh - 192px)"};
  border: none;
  outline: none;
  margin: 0 auto;
  background-color: #f4f8f9;
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
  padding: 0;
  width: ${({ tableSize })=>tableSize+"px"};
  border: none;
  outline: none;
  background-color: #f4f8f9;
`;

const HeaderRow = styled.tr`
  margin: 0;
  padding: 13px 0;
  border: none;
  outline: none;
  height: 0px;
`;

const HeaderColumn = styled.th`
  width: ${({ twd }) => twd}px;
  ${({ twMin }) => twMin && `max-width: ${twMin}px;`}
  ${({ twMax }) => twMax && `max-width: ${twMax}px;`}
  position: sticky;
  top: 0px;
  z-index: 3;
  background-color: #f4f8f9;
`;

const HeaderDropdownColumn = styled.th`
  position: sticky;
  top: 0px;
  width: 64px;
  z-index: 3;
  background-color: #f4f8f9;
`;

const CardContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #2c2e3c;
  font-size: 11.82px;
  font-weight: 600;
  letter-spacing: 0.09px;
  cursor: ${({ noOrder }) => (!noOrder ? "pointer" : "auto")};
  user-select: none;
  padding: 0 20px;
`;

const AddButton = styled(IconButton)`
  background-color: white;
  padding: 0 0 2px !important;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-weight: 600;
  font-size: x-large;
  margin: 0;
`;
