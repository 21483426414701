import React from "react";
import styled from "@emotion/styled";

import { colors } from "./../../util/consts";
import typography from "./../../util/typography";

class Tooltip extends React.PureComponent {
  render() {
    const { children, style } = this.props;
    return (
      <Container style={{...style}}>
        ?<Hover>{children}</Hover>
      </Container>
    );
  }
}

export default Tooltip;

const Hover = styled.div`
  display: none;
  max-width: 300px;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 10;
  bottom: calc(100% + 8px);
  border: 1px solid #636363;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  padding: 6px 10px;
  color: ${colors.surfaceText.highEmphasis};
  ${typography.body2};
`;

const Container = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  position: relative;
  cursor: pointer;

  &:hover ${Hover} {
    display: block;
  }
`;
