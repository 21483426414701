import React from "react";
import { Stack } from "../../../ui-kit/Stack";
import { ProviderOfferings } from "./index";
import ProductCard from "./ProductCard";

interface IProps {
  products: ProviderOfferings[];
}

const ProductsBlock: React.FC<IProps> = ({ products }) => {
  return (
    <>
      {products && (
        <div id="bio">
          <Stack
            span={1}
            direction="col"
            gap={[4, 4]}
            md={{ direction: "col", span: 2 }}
            xxl={{ direction: "col", span: 3 }}
          >
            {products.length
              ? products.map((product, key) => (
                  <ProductCard key={`${product.ID}-${key}`} product={product} />
                ))
              : "No products"}
          </Stack>
        </div>
      )}
    </>
  );
};

export default ProductsBlock;
