import React from "react";

interface IProps {
  variant?: "primary" | "danger" | "information";
  size?: "small" | "large";
  fill?: boolean;
}

export const ExclamationIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 ${computeColor(props.variant)}${props.fill && " fill-current"}`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>
  );
};

ExclamationIcon.Solid = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.size === "small" ? "h-4 w-4" : "h-12 w-12"} ${computeColor(
        props.variant
      )}${props.fill && " fill-current"}`}
      fill="none"
      viewBox="0 0 28 24"
      stroke="currentColor"
    >
      <path d="M27.104 20.176L15.932 1.49996C15.064 0.043959 12.936 0.043959 12.04 1.49996L0.895992 20.176C-8.04663e-06 21.688 1.09199 23.592 2.82799 23.592H25.172C26.936 23.592 28.028 21.688 27.104 20.176ZM12.824 8.66796C12.824 7.93996 13.412 7.32396 14.168 7.32396C14.896 7.32396 15.512 7.91196 15.512 8.66796V14.296C15.512 15.024 14.924 15.64 14.168 15.64C13.44 15.64 12.824 15.052 12.824 14.296V8.66796ZM14.14 19.812C13.384 19.812 12.796 19.196 12.796 18.468C12.796 17.712 13.412 17.124 14.14 17.124C14.896 17.124 15.484 17.74 15.484 18.468C15.484 19.196 14.896 19.812 14.14 19.812Z" />
    </svg>
  );
};

const computeColor = (variant: IProps["variant"]) => {
  switch (variant) {
    case "primary":
    default:
      return "text-primary-900";
    case "information":
      return "text-tertiary-200";
    case "danger":
      return "text-red-500";
  }
};
