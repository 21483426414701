import styled from "@emotion/styled";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions, Token } from "@stripe/stripe-js";
import React from "react";
import { toastStore } from "../stores/toast-store";
import { AsyncButton } from "./AsyncButton";
import { FlexBox, FlexItem } from "./FlexBox";

interface IProps {
  onSubmit: (token: Token) => void;
  isAddingNewCard: boolean;
  onCancel: () => void;
}

export const StripePaymentCard = (props: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const submitAction = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement === null) {
      return;
    }
    const result = await stripe.createToken(cardElement);
    if (result.error) {
      toastStore.error("Stripe error: \n" + result.error.message!);
    }

    if (!result.token) {
      toastStore.error("Stripe token undefined");
    } else {
      props.onSubmit(result.token);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitAction();
      }}
    >
      <FlexBox direction="col" spacing={[0, 3]}>
        <FlexItem>
          <StripeElementsContainer>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </StripeElementsContainer>
        </FlexItem>
        <FlexItem>
          <FlexBox direction="row-reverse" alignItems="end">
            <FlexItem>
              <AsyncButton.Action onClick={props.onCancel}>Cancel</AsyncButton.Action>
            </FlexItem>
          </FlexBox>
        </FlexItem>
        <FlexItem>
          <AsyncButton.PrimaryGradient
            isDisabled={!stripe}
            onClick={() => undefined}
            type="submit"
            rounded="full"
            size="md"
            fullWidth
          >
            SAVE
          </AsyncButton.PrimaryGradient>
        </FlexItem>
      </FlexBox>
    </form>
  );
};

const StripeElementsContainer = styled.div`
  -webkit-letter-spacing: 0.15px;
  -moz-letter-spacing: 0.15px;
  -ms-letter-spacing: 0.15px;
  letter-spacing: 0.15px;
  text-align: left;
  height: 44px;
  background-color: #fff;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #d2d5e3;
  border-radius: 4px;
  outline: none;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
`;

const CARD_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
  style: {
    base: {
      fontSize: "15.8px",
      color: "rgba(44, 46, 60, 1)",
      "::placeholder": {
        color: "#9e9fa4"
      },
      fontFamily: '"Open Sans" ,sans-serif',
      letterSpacing: "0.15px"
    }
  }
};
