import React from "react";
import { Typography } from "../Typography";

interface IProps {
  children: React.ReactNode;
  label: string;
  description: string | React.ReactNode;
}

export const FormContainer = (props: IProps) => {
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6 px-4 py-5 bg-white sm:p-6 shadow sm:rounded-md">
      <div className="md:col-span-1">
        <div>
          <Typography.H4 noPadding>{props.label}</Typography.H4>
          <Typography.Label>{props.description}</Typography.Label>
        </div>
      </div>
      <div className="mt-5 sm:mt-0 md:col-span-2">{props.children}</div>
    </div>
  );
};
