import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";

// Components
import GMPlacesAutocomplete from "./../../../components/form/GMPlacesAutocomplete";
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";

class OrgEditAccount_General extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Business Information"
          subtitle="Please fill out the relevant information about your business"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="editorg-name">Name</Label>
            <Input
              id="editorg-name"
              name="Name"
              type="text"
              placeholder="Name"
              autoFocus={true}
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Name && errors.Name}
            />
            {!!errors.Name && touched.Name && <Error>{errors.Name}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-email">Contact Email</Label>
            <Input
              id="editorg-email"
              name="ContactEmail"
              type="text"
              placeholder="some@example.com"
              value={values.ContactEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ContactEmail && errors.ContactEmail}
            />
            {!!errors.ContactEmail && touched.ContactEmail && <Error>{errors.ContactEmail}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-phone">Contact Phone</Label>
            <Input
              id="editorg-phone"
              name="ContactPhone"
              type="text"
              placeholder= {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? "03 1234 3210" : "406 750 9999"}
              value={values.ContactPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ContactPhone && errors.ContactPhone}
            />
            {!!errors.ContactPhone && touched.ContactPhone && <Error>{errors.ContactPhone}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-address">Address</Label>
            <GMPlacesAutocomplete
              id="editorg-address"
              name="Address"
              type="text"
              placeholder="Address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              css={{ margin: "0px !important" }}
              error={touched.Address && errors.Address}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }) => ({
    Name: org.Name || "",
    ContactEmail: org.ContactEmail || "",
    ContactPhone: org.ContactPhone || "",
    Address: org.Address.Raw || ""
  }),
  validationSchema: Yup.object().shape({
    Name: Yup.string().required("Name is required."),
    ContactEmail: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    ContactPhone: Yup.string().required("Phone is required."),
    Address: Yup.string().required("Address is required")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      // Find and create the address.
      if (props.org.Address.Raw !== values.Address) {
        const prDet = await findPlaceDetails(values.Address, "Address");
        const prUpdAddr = {
          OwnerType: "Organisation",
          ...props.org.Address,
          ...getAddressResourceFromPlaceDetails(prDet, values.Address)
        };
        await props.dispatch(ResourceActions.action(prUpdAddr, "Update", {}));
      }

      const updOrg = {
        ...props.org,
        Name: values.Name.trim(),
        ContactEmail: values.ContactEmail.trim(),
        ContactPhone: values.ContactPhone.trim()
      };

      await props.dispatch(ResourceActions.action(updOrg, "Update", {}));
      props.toastManager.add("Saved Successfully!", { appearance: "success" });
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountGeneral"
});

export default compose(
  connect((state) => ({})),
  withToastManager,
  formikEnhancer
)(OrgEditAccount_General);
