import styled from "@emotion/styled";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import { compose } from "recompose";
import { rebookDataStore } from "../../../mobx/stores/RebookDataStore";
import { IOrganization } from "../../../pages/create-organization/form/IOrganization";
import { sharedOrganizationService } from "../../../services/OrganizationService";
import { toDateTz } from "../../../util/timestamp";
import { RebookModal } from "../../modal/RebookModal";
import { PhysitrackButton } from "../ClientActionDialog/Physitrack/PhysitrackButton";
import { RightContent } from "../SplitDialog";
import Avatar from "./../../../components/Avatar";
import ButtonBase from "./../../../components/ButtonBase";
import LoadingIcon from "./../../../components/icons/Loading";
import { Tab, Tabs } from "./../../../components/Tabs";
import { metadata, rpc } from "./../../../grpc";
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import { ApproveBookingClientRequest, Booking, BookingClient } from "sdk/dist/bookings_pb";
import { bookingStore } from "./../../../stores/booking-store";
import { toastStore } from "./../../../stores/toast-store";
import { renderBookingStatus } from "./../../../util/bookings";
import { colors, hexToRgba } from "./../../../util/consts";
import typography from "./../../../util/typography";
import { Details } from "./Details";
import { Notes } from "./Notes";
import Offerings from "./Offerings";

interface IProps {
  booking: Booking.AsObject;
  client?: BookingClient.AsObject;
  orgID: string;
  dispatch?: any;
  onPayClick(): void;
}

interface IState {
  orgDetails?: IOrganization;
}

@observer
export class Entry extends Component<IProps, IState> {
  @observable
  private isLoading = false;

  state: IState = {
    orgDetails: undefined
  };

  async componentDidMount() {
    const res = await sharedOrganizationService.getOrganizationDetails(this.props.orgID);
    if (res) {
      this.setState({ orgDetails: res });
    }
  }

  cancelBooking = () => {
    const { booking, client, dispatch } = this.props;
    if (booking.type === Booking.Type.BOOKING_SINGLE && client) {
      dispatch(ReduxDialogs.openCancel(null, booking.id, client.clientId, () => {}));
    } else {
      dispatch(ReduxDialogs.openCancel(null, booking.id, undefined, () => {}));
    }
  };

  approveBooking = async () => {
    const { booking, client } = this.props;
    this.isLoading = true;
    try {
      const req = new ApproveBookingClientRequest();
      req.setBookingId(booking.id);
      req.setClientId(client!.clientId);
      const res = await rpc.bookingClients.approve(req, metadata());
      toastStore.success(`Client successfully approved`);
      bookingStore.add(res.toObject());
    } catch (err) {
      toastStore.grpcError(err);
    }
    this.isLoading = false;
  };

  rejectBooking = () => {
    const { booking, client, dispatch } = this.props;
    if (!client || this.isLoading) {
      return;
    }
    dispatch(ReduxDialogs.openReject(null, booking, client, () => {}));
  };

  renderButtons(client?: BookingClient.AsObject) {
    const { booking } = this.props;
    switch (booking.status) {
      case Booking.Status.BOOKING_CREATED:
        if (client) {
          switch (client.status) {
            case BookingClient.Status.CREATED:
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton isPrimary={true} onClick={this.rejectBooking}>
                    <LargeButtonText>Reject</LargeButtonText>
                  </LargeBottomButton>
                  <LargeBottomButton onClick={this.approveBooking}>
                    {this.isLoading ? (
                      <LoadingIcon width={16} height={16} color="#FFF" />
                    ) : (
                      <LargeButtonText>Approve</LargeButtonText>
                    )}
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
            case BookingClient.Status.APPROVED:
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton isPrimary={true} onClick={this.cancelBooking}>
                    <LargeButtonText>Cancel</LargeButtonText>
                  </LargeBottomButton>
                  <LargeBottomButton onClick={this.props.onPayClick}>
                    <LargeButtonText>Payment</LargeButtonText>
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
            case BookingClient.Status.CANCELLED:
            case BookingClient.Status.REJECTED:
              if (!client) {
                return null;
              }
              return (
                <RightBottomButtonContainer>
                  <LargeBottomButton onClick={this.props.onPayClick}>
                    <LargeButtonText>Charge Cancellation Fee</LargeButtonText>
                  </LargeBottomButton>
                </RightBottomButtonContainer>
              );
          }
        } else {
          return (
            <RightBottomButtonContainer>
              <LargeBottomButton isPrimary={true} onClick={this.cancelBooking}>
                <LargeButtonText>Cancel</LargeButtonText>
              </LargeBottomButton>
              <LargeBottomButton onClick={this.props.onPayClick}>
                <LargeButtonText>Payment</LargeButtonText>
              </LargeBottomButton>
            </RightBottomButtonContainer>
          );
        }
      case Booking.Status.BOOKING_CANCELLED:
        if (!client) {
          return null;
        }
        if (booking.clientsList[0].paymentStatus === BookingClient.PaymentStatus.COMPLETE) {
          <RightBottomButtonContainer>
            <LargeBottomButton onClick={this.props.onPayClick}>
              <LargeButtonText>Payment Details</LargeButtonText>
            </LargeBottomButton>
          </RightBottomButtonContainer>;
        } else {
          return (
            <RightBottomButtonContainer>
              <LargeBottomButton onClick={this.props.onPayClick}>
                <LargeButtonText>Charge Cancellation Fee</LargeButtonText>
              </LargeBottomButton>
            </RightBottomButtonContainer>
          );
        }
      case Booking.Status.BOOKING_PROCESSING:
      case Booking.Status.BOOKING_COMPLETED:
        return (
          <RightBottomButtonContainer>
            <LargeBottomButton onClick={this.props.onPayClick}>
              <LargeButtonText>Payment Details</LargeButtonText>
            </LargeBottomButton>
          </RightBottomButtonContainer>
        );
      default:
        return null;
    }
  }

  render() {
    const { booking, client } = this.props;
    // Change avatar letter to provider and title to the name of the service
    // https://gitlab.blitzm.systems/booklyfe/booklyfe-web-app/-/issues/1227
    const clientLetters = !client
      ? `${
          (booking.provider &&
            booking.provider.firstName &&
            booking.provider.firstName[0].toUpperCase()) ||
          ""
        }${
          (booking.provider &&
            booking.provider.lastName &&
            booking.provider.lastName[0].toUpperCase()) ||
          ""
        }`
      : `${client.firstName.charAt(0)}${client.lastName.charAt(0)}`;

    const clientTitle = !client
      ? (booking.offeringsList[0] &&
          booking.offeringsList[0].lineItem &&
          booking.offeringsList[0].lineItem.offering &&
          booking.offeringsList[0].lineItem.offering.name) ||
        ""
      : `${client.firstName} ${client.lastName}`;

    return (
      <React.Fragment>
        {rebookDataStore.isOpen && this.props.client && (
          <Popup
            modal
            open={rebookDataStore.isOpen}
            onClose={() => {
              rebookDataStore.isOpen = false;
            }}
            contentStyle={{
              width: "360px",
              height: "175px",
              borderRadius: "9px",
              borderWidth: "0px",
              margin: "auto auto auto auto"
            }}
          >
            {
              <RebookModal
                duration={this.props.booking.duration}
                startTime={this.props.booking.startDatetime}
                clientId={this.props.client.clientId}
                offeringId={this.props.booking.offeringsList[0].lineItem!.offering!.id}
                bookingId={this.props.booking.id}
                dispatch={this.props.dispatch}
                locationId={this.props.booking.locationId}
                providerId={this.props.booking.provider!.id}
              />
            }
          </Popup>
        )}

        <RightContent>
          <TitleContainer>
            <Avatar letters={clientLetters} size={100} lfrac={0.2369} />
            <div style={{ marginLeft: 28 }}>
              <TitleName>{clientTitle}</TitleName>
              <TitleTime>
                {toDateTz(booking.startDatetime!).format("hh:mma")} -{" "}
                {toDateTz(booking.endDatetime!).format("hh:mma")}
              </TitleTime>
              <TitleService>{renderBookingStatus(booking, client)}</TitleService>
              {booking.token &&
                booking.token!.isAvailable &&
                booking.token!.clientPhysitrack &&
                this.state.orgDetails &&
                this.state.orgDetails.SubscriptionType && (
                  <PhysitrackButton
                    tokenAndData={booking.token}
                    buttonLabel={"Start telehealth session in physitrack"}
                    isButtonDisabled={
                      this.state.orgDetails.SubscriptionType.toLowerCase() !== "pro"
                    }
                  />
                )}
            </div>
          </TitleContainer>
          <Tabs>
            <Tab
              label="Details"
              render={() => <Details bookingObj={booking} client={client} {...this.props} />}
            />
            <Tab
              label="Products &amp; Services"
              render={() => <Offerings bookingObj={booking} client={client} {...this.props} />}
            />
            {client && (
              <Tab label="Notes" render={() => <Notes booking={booking} client={client} />} />
            )}
          </Tabs>
          <ButtonPad />
        </RightContent>
        {this.renderButtons(client)}
      </React.Fragment>
    );
  }
}

export default compose(connect())(Entry as any);

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px;
  margin-bottom: 51px;
`;

export const TitleName = styled.div`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing;
  1.44px;
  line-height: 24px;
`;

export const TitleService = styled.div`
  ${typography.body2};
  letter-spacing: 1.17px;
  line-height: 18px;
`;

export const TitleTime = styled.div`
  ${typography.body2};
  letter-spacing: 1.17px;
  line-height: 18px;
  color: ${colors.secondary.main};
`;

export const ButtonPad = styled.div`
  height: 70px;
  width: 100%;
`;

export const RightBottomButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
`;

export const LargeButtonText = styled.span`
  ${typography.heading6};
  font-weight: 600;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${colors.whiteText.highEmphasis};
`;

export const LargeBottomButton = styled(ButtonBase)`
  flex: 1 0 100px;
  height: 100%;
  background-color: ${({ isPrimary }) => (isPrimary ? colors.primary.main : colors.secondary.main)};
  transition: opacity 0.2s ease;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.primary.main, 0.12)};
  }
`;

export const BackButton = styled(ButtonBase)`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  transition: opacity 0.2s ease;

  opacity: 0.6;
  color: #2c2e3c;
  font-size: 13.82px;
  letter-spacing: 0.25px;
  line-height: 20px;

  &:hover:enabled {
    opacity: 0.95;
  }

  &:focus {
    opacity: 0.95;
  }
`;
