/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var money_pb = require('./money_pb.js')

var account_items_pb = require('./account_items_pb.js')

var media_pb = require('./media_pb.js')
const proto = {};
proto.proto = require('./third_party_invoices_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ThirdPartyInvoicesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ThirdPartyInvoicesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodDescriptor_ThirdPartyInvoices_List = new grpc.web.MethodDescriptor(
  '/proto.ThirdPartyInvoices/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListThirdPartyInvoiceRequest,
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.ListThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.ListThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodInfo_ThirdPartyInvoices_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.ListThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListThirdPartyInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListThirdPartyInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ThirdPartyInvoicesClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/List',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_List,
      callback);
};


/**
 * @param {!proto.proto.ListThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListThirdPartyInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ThirdPartyInvoicesPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/List',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodDescriptor_ThirdPartyInvoices_Create = new grpc.web.MethodDescriptor(
  '/proto.ThirdPartyInvoices/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateThirdPartyInvoiceRequest,
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.CreateThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.CreateThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodInfo_ThirdPartyInvoices_Create = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.CreateThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListThirdPartyInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListThirdPartyInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ThirdPartyInvoicesClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Create',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListThirdPartyInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ThirdPartyInvoicesPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Create',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodDescriptor_ThirdPartyInvoices_Update = new grpc.web.MethodDescriptor(
  '/proto.ThirdPartyInvoices/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateThirdPartyInvoiceRequest,
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpdateThirdPartyInvoiceRequest,
 *   !proto.proto.ListThirdPartyInvoiceResponse>}
 */
const methodInfo_ThirdPartyInvoices_Update = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ListThirdPartyInvoiceResponse,
  /**
   * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ListThirdPartyInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListThirdPartyInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ThirdPartyInvoicesClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Update',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListThirdPartyInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ThirdPartyInvoicesPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Update',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteThirdPartyInvoiceRequest,
 *   !proto.proto.ThirdPartyInvoice>}
 */
const methodDescriptor_ThirdPartyInvoices_Delete = new grpc.web.MethodDescriptor(
  '/proto.ThirdPartyInvoices/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteThirdPartyInvoiceRequest,
  proto.proto.ThirdPartyInvoice,
  /**
   * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ThirdPartyInvoice.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.DeleteThirdPartyInvoiceRequest,
 *   !proto.proto.ThirdPartyInvoice>}
 */
const methodInfo_ThirdPartyInvoices_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ThirdPartyInvoice,
  /**
   * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ThirdPartyInvoice.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ThirdPartyInvoice)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ThirdPartyInvoice>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ThirdPartyInvoicesClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Delete',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteThirdPartyInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ThirdPartyInvoice>}
 *     Promise that resolves to the response
 */
proto.proto.ThirdPartyInvoicesPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/Delete',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.SetPaidInvoiceRequest,
 *   !proto.proto.ThirdPartyInvoice>}
 */
const methodDescriptor_ThirdPartyInvoices_SetPaid = new grpc.web.MethodDescriptor(
  '/proto.ThirdPartyInvoices/SetPaid',
  grpc.web.MethodType.UNARY,
  proto.proto.SetPaidInvoiceRequest,
  proto.proto.ThirdPartyInvoice,
  /**
   * @param {!proto.proto.SetPaidInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ThirdPartyInvoice.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.SetPaidInvoiceRequest,
 *   !proto.proto.ThirdPartyInvoice>}
 */
const methodInfo_ThirdPartyInvoices_SetPaid = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.ThirdPartyInvoice,
  /**
   * @param {!proto.proto.SetPaidInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ThirdPartyInvoice.deserializeBinary
);


/**
 * @param {!proto.proto.SetPaidInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.ThirdPartyInvoice)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ThirdPartyInvoice>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ThirdPartyInvoicesClient.prototype.setPaid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/SetPaid',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_SetPaid,
      callback);
};


/**
 * @param {!proto.proto.SetPaidInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ThirdPartyInvoice>}
 *     Promise that resolves to the response
 */
proto.proto.ThirdPartyInvoicesPromiseClient.prototype.setPaid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ThirdPartyInvoices/SetPaid',
      request,
      metadata || {},
      methodDescriptor_ThirdPartyInvoices_SetPaid);
};


module.exports = proto.proto;

