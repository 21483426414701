/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var bookings_pb = require('./bookings_pb.js')

var media_pb = require('./media_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.proto = require('./location_user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationUserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationUserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetLocationUserRequest,
 *   !proto.proto.GetLocationUserResponse>}
 */
const methodDescriptor_LocationUser_GetLocationUser = new grpc.web.MethodDescriptor(
  '/proto.LocationUser/GetLocationUser',
  grpc.web.MethodType.UNARY,
  proto.proto.GetLocationUserRequest,
  proto.proto.GetLocationUserResponse,
  /**
   * @param {!proto.proto.GetLocationUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLocationUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.GetLocationUserRequest,
 *   !proto.proto.GetLocationUserResponse>}
 */
const methodInfo_LocationUser_GetLocationUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.GetLocationUserResponse,
  /**
   * @param {!proto.proto.GetLocationUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLocationUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetLocationUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.GetLocationUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetLocationUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationUserClient.prototype.getLocationUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationUser/GetLocationUser',
      request,
      metadata || {},
      methodDescriptor_LocationUser_GetLocationUser,
      callback);
};


/**
 * @param {!proto.proto.GetLocationUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetLocationUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationUserPromiseClient.prototype.getLocationUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationUser/GetLocationUser',
      request,
      metadata || {},
      methodDescriptor_LocationUser_GetLocationUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpsertLocationUserRequest,
 *   !proto.proto.UpsertLocationUserResponse>}
 */
const methodDescriptor_LocationUser_UpsertLocationUser = new grpc.web.MethodDescriptor(
  '/proto.LocationUser/UpsertLocationUser',
  grpc.web.MethodType.UNARY,
  proto.proto.UpsertLocationUserRequest,
  proto.proto.UpsertLocationUserResponse,
  /**
   * @param {!proto.proto.UpsertLocationUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpsertLocationUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.UpsertLocationUserRequest,
 *   !proto.proto.UpsertLocationUserResponse>}
 */
const methodInfo_LocationUser_UpsertLocationUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.UpsertLocationUserResponse,
  /**
   * @param {!proto.proto.UpsertLocationUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpsertLocationUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpsertLocationUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.UpsertLocationUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpsertLocationUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationUserClient.prototype.upsertLocationUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationUser/UpsertLocationUser',
      request,
      metadata || {},
      methodDescriptor_LocationUser_UpsertLocationUser,
      callback);
};


/**
 * @param {!proto.proto.UpsertLocationUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpsertLocationUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationUserPromiseClient.prototype.upsertLocationUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationUser/UpsertLocationUser',
      request,
      metadata || {},
      methodDescriptor_LocationUser_UpsertLocationUser);
};


module.exports = proto.proto;

