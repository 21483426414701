// source: account_items.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var offerings_pb = require('./offerings_pb.js');
goog.object.extend(proto, offerings_pb);
var bookings_pb = require('./bookings_pb.js');
goog.object.extend(proto, bookings_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
goog.exportSymbol('proto.proto.AccLineItems', null, global);
goog.exportSymbol('proto.proto.AccountItem', null, global);
goog.exportSymbol('proto.proto.AccountItem.Status', null, global);
goog.exportSymbol('proto.proto.AccountItem.Type', null, global);
goog.exportSymbol('proto.proto.ListAccountItemsByBookingClientRequest', null, global);
goog.exportSymbol('proto.proto.ListAccountItemsResponse', null, global);
goog.exportSymbol('proto.proto.ListByLocationBookingDateRequest', null, global);
goog.exportSymbol('proto.proto.RetryAccountItemRequest', null, global);
goog.exportSymbol('proto.proto.RetryInvGenRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountItemsByBookingClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAccountItemsByBookingClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountItemsByBookingClientRequest.displayName = 'proto.proto.ListAccountItemsByBookingClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAccountItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAccountItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAccountItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAccountItemsResponse.displayName = 'proto.proto.ListAccountItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListByLocationBookingDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListByLocationBookingDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListByLocationBookingDateRequest.displayName = 'proto.proto.ListByLocationBookingDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountItem.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountItem.displayName = 'proto.proto.AccountItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccLineItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccLineItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccLineItems.displayName = 'proto.proto.AccLineItems';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RetryAccountItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RetryAccountItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RetryAccountItemRequest.displayName = 'proto.proto.RetryAccountItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RetryInvGenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RetryInvGenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RetryInvGenRequest.displayName = 'proto.proto.RetryInvGenRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountItemsByBookingClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountItemsByBookingClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountItemsByBookingClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountItemsByBookingClientRequest}
 */
proto.proto.ListAccountItemsByBookingClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountItemsByBookingClientRequest;
  return proto.proto.ListAccountItemsByBookingClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountItemsByBookingClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountItemsByBookingClientRequest}
 */
proto.proto.ListAccountItemsByBookingClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountItemsByBookingClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountItemsByBookingClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountItemsByBookingClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string booking_id = 1;
 * @return {string}
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListAccountItemsByBookingClientRequest} returns this
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListAccountItemsByBookingClientRequest} returns this
 */
proto.proto.ListAccountItemsByBookingClientRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAccountItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAccountItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAccountItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAccountItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accItemList: jspb.Message.toObjectList(msg.getAccItemList(),
    proto.proto.AccountItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAccountItemsResponse}
 */
proto.proto.ListAccountItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAccountItemsResponse;
  return proto.proto.ListAccountItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAccountItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAccountItemsResponse}
 */
proto.proto.ListAccountItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountItem;
      reader.readMessage(value,proto.proto.AccountItem.deserializeBinaryFromReader);
      msg.addAccItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAccountItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAccountItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAccountItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAccountItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountItem acc_item = 1;
 * @return {!Array<!proto.proto.AccountItem>}
 */
proto.proto.ListAccountItemsResponse.prototype.getAccItemList = function() {
  return /** @type{!Array<!proto.proto.AccountItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountItem, 1));
};


/**
 * @param {!Array<!proto.proto.AccountItem>} value
 * @return {!proto.proto.ListAccountItemsResponse} returns this
*/
proto.proto.ListAccountItemsResponse.prototype.setAccItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountItem}
 */
proto.proto.ListAccountItemsResponse.prototype.addAccItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAccountItemsResponse} returns this
 */
proto.proto.ListAccountItemsResponse.prototype.clearAccItemList = function() {
  return this.setAccItemList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListByLocationBookingDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListByLocationBookingDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListByLocationBookingDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bookingStartDate: (f = msg.getBookingStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bookingEndDate: (f = msg.getBookingEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListByLocationBookingDateRequest}
 */
proto.proto.ListByLocationBookingDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListByLocationBookingDateRequest;
  return proto.proto.ListByLocationBookingDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListByLocationBookingDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListByLocationBookingDateRequest}
 */
proto.proto.ListByLocationBookingDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBookingStartDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBookingEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListByLocationBookingDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListByLocationBookingDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListByLocationBookingDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBookingEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string location_id = 1;
 * @return {string}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListByLocationBookingDateRequest} returns this
 */
proto.proto.ListByLocationBookingDateRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp booking_start_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.getBookingStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ListByLocationBookingDateRequest} returns this
*/
proto.proto.ListByLocationBookingDateRequest.prototype.setBookingStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListByLocationBookingDateRequest} returns this
 */
proto.proto.ListByLocationBookingDateRequest.prototype.clearBookingStartDate = function() {
  return this.setBookingStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.hasBookingStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp booking_end_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.getBookingEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.ListByLocationBookingDateRequest} returns this
*/
proto.proto.ListByLocationBookingDateRequest.prototype.setBookingEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListByLocationBookingDateRequest} returns this
 */
proto.proto.ListByLocationBookingDateRequest.prototype.clearBookingEndDate = function() {
  return this.setBookingEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListByLocationBookingDateRequest.prototype.hasBookingEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountItem.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    booking: (f = msg.getBooking()) && bookings_pb.Booking.toObject(includeInstance, f),
    clientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fundType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    invDownloadUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastModifiedAt: (f = msg.getLastModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    organisationId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    type: jspb.Message.getFieldWithDefault(msg, 13, 0),
    total: (f = msg.getTotal()) && money_pb.Charge.toObject(includeInstance, f),
    totalFee: (f = msg.getTotalFee()) && money_pb.Charge.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.proto.AccLineItems.toObject, includeInstance),
    statusCode: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountItem}
 */
proto.proto.AccountItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountItem;
  return proto.proto.AccountItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountItem}
 */
proto.proto.AccountItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new bookings_pb.Booking;
      reader.readMessage(value,bookings_pb.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.AccountItem.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {!proto.proto.FundType} */ (reader.readEnum());
      msg.setFundType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvDownloadUrl(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModifiedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 13:
      var value = /** @type {!proto.proto.AccountItem.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 14:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 15:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setTotalFee(value);
      break;
    case 16:
      var value = new proto.proto.AccLineItems;
      reader.readMessage(value,proto.proto.AccLineItems.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      bookings_pb.Booking.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFundType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastModifiedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getTotalFee();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.proto.AccLineItems.serializeBinaryToWriter
    );
  }
  f = message.getStatusCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.AccountItem.Status = {
  ACC_ITEM_UNSPECIFIED: 0,
  ACC_ITEM_CREATED: 1,
  ACC_ITEM_PROCESSING: 2,
  ACC_ITEM_ERROR: 3,
  ACC_ITEM_COMPLETED: 4,
  ACC_ITEM_CANCELLED: 5,
  ACC_ITEM_REFUNDED: 6
};

/**
 * @enum {number}
 */
proto.proto.AccountItem.Type = {
  ACC_ITEM_TYPE_UNSPECIFIED: 0,
  ACC_ITEM_BOOKING_PAYMENT: 1,
  ACC_ITEM_FUND: 2,
  ACC_ITEM_ORG_FUND_TOPUP: 3,
  ACC_ITEM_ORG_PAYOUT: 4,
  ACC_ITEM_SUBSCRIPTION_FEE: 5,
  ACC_ITEM_THIRD_PARTY: 6,
  ACC_ITEM_LYFE: 7,
  ACC_ITEM_PRODUCT: 8
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Booking booking = 2;
 * @return {?proto.proto.Booking}
 */
proto.proto.AccountItem.prototype.getBooking = function() {
  return /** @type{?proto.proto.Booking} */ (
    jspb.Message.getWrapperField(this, bookings_pb.Booking, 2));
};


/**
 * @param {?proto.proto.Booking|undefined} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountItem.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.proto.AccountItem.Status}
 */
proto.proto.AccountItem.prototype.getStatus = function() {
  return /** @type {!proto.proto.AccountItem.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.AccountItem.Status} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string status_message = 5;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.AccountItem.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountItem.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FundType fund_type = 7;
 * @return {!proto.proto.FundType}
 */
proto.proto.AccountItem.prototype.getFundType = function() {
  return /** @type {!proto.proto.FundType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.proto.FundType} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setFundType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string invoice_id = 8;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string inv_download_url = 9;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getInvDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setInvDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp last_modified_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.AccountItem.prototype.getLastModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setLastModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearLastModifiedAt = function() {
  return this.setLastModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountItem.prototype.hasLastModifiedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string organisation_id = 11;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string location_id = 12;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Type type = 13;
 * @return {!proto.proto.AccountItem.Type}
 */
proto.proto.AccountItem.prototype.getType = function() {
  return /** @type {!proto.proto.AccountItem.Type} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.proto.AccountItem.Type} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional Charge total = 14;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccountItem.prototype.getTotal = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 14));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountItem.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Charge total_fee = 15;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccountItem.prototype.getTotalFee = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 15));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setTotalFee = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearTotalFee = function() {
  return this.setTotalFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountItem.prototype.hasTotalFee = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated AccLineItems line_items = 16;
 * @return {!Array<!proto.proto.AccLineItems>}
 */
proto.proto.AccountItem.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.proto.AccLineItems>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccLineItems, 16));
};


/**
 * @param {!Array<!proto.proto.AccLineItems>} value
 * @return {!proto.proto.AccountItem} returns this
*/
proto.proto.AccountItem.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.proto.AccLineItems=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccLineItems}
 */
proto.proto.AccountItem.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.proto.AccLineItems, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional string status_code = 17;
 * @return {string}
 */
proto.proto.AccountItem.prototype.getStatusCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountItem} returns this
 */
proto.proto.AccountItem.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccLineItems.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccLineItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccLineItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccLineItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    claimCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rebate: (f = msg.getRebate()) && money_pb.Charge.toObject(includeInstance, f),
    lyfeTransactionFee: (f = msg.getLyfeTransactionFee()) && money_pb.Charge.toObject(includeInstance, f),
    reimbursment: (f = msg.getReimbursment()) && money_pb.Money.toObject(includeInstance, f),
    remittance: (f = msg.getRemittance()) && money_pb.Money.toObject(includeInstance, f),
    subTotal: (f = msg.getSubTotal()) && money_pb.Charge.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && money_pb.Charge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccLineItems}
 */
proto.proto.AccLineItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccLineItems;
  return proto.proto.AccLineItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccLineItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccLineItems}
 */
proto.proto.AccLineItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClaimCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setRebate(value);
      break;
    case 5:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setLyfeTransactionFee(value);
      break;
    case 6:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setReimbursment(value);
      break;
    case 7:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setRemittance(value);
      break;
    case 8:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setSubTotal(value);
      break;
    case 9:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccLineItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccLineItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccLineItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccLineItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClaimCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRebate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getLyfeTransactionFee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getReimbursment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getRemittance();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getSubTotal();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.proto.AccLineItems.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string claim_code = 2;
 * @return {string}
 */
proto.proto.AccLineItems.prototype.getClaimCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.setClaimCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.proto.AccLineItems.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Charge rebate = 4;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccLineItems.prototype.getRebate = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 4));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setRebate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearRebate = function() {
  return this.setRebate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasRebate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Charge lyfe_transaction_fee = 5;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccLineItems.prototype.getLyfeTransactionFee = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 5));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setLyfeTransactionFee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearLyfeTransactionFee = function() {
  return this.setLyfeTransactionFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasLyfeTransactionFee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Money reimbursment = 6;
 * @return {?proto.proto.Money}
 */
proto.proto.AccLineItems.prototype.getReimbursment = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 6));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setReimbursment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearReimbursment = function() {
  return this.setReimbursment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasReimbursment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Money remittance = 7;
 * @return {?proto.proto.Money}
 */
proto.proto.AccLineItems.prototype.getRemittance = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 7));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setRemittance = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearRemittance = function() {
  return this.setRemittance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasRemittance = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Charge sub_total = 8;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccLineItems.prototype.getSubTotal = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 8));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setSubTotal = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearSubTotal = function() {
  return this.setSubTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasSubTotal = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Charge total = 9;
 * @return {?proto.proto.Charge}
 */
proto.proto.AccLineItems.prototype.getTotal = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 9));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.AccLineItems} returns this
*/
proto.proto.AccLineItems.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccLineItems} returns this
 */
proto.proto.AccLineItems.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccLineItems.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RetryAccountItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RetryAccountItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RetryAccountItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RetryAccountItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountItemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RetryAccountItemRequest}
 */
proto.proto.RetryAccountItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RetryAccountItemRequest;
  return proto.proto.RetryAccountItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RetryAccountItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RetryAccountItemRequest}
 */
proto.proto.RetryAccountItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RetryAccountItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RetryAccountItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RetryAccountItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RetryAccountItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_item_id = 1;
 * @return {string}
 */
proto.proto.RetryAccountItemRequest.prototype.getAccountItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RetryAccountItemRequest} returns this
 */
proto.proto.RetryAccountItemRequest.prototype.setAccountItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RetryInvGenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RetryInvGenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RetryInvGenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RetryInvGenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountItemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RetryInvGenRequest}
 */
proto.proto.RetryInvGenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RetryInvGenRequest;
  return proto.proto.RetryInvGenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RetryInvGenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RetryInvGenRequest}
 */
proto.proto.RetryInvGenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RetryInvGenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RetryInvGenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RetryInvGenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RetryInvGenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_item_id = 1;
 * @return {string}
 */
proto.proto.RetryInvGenRequest.prototype.getAccountItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RetryInvGenRequest} returns this
 */
proto.proto.RetryInvGenRequest.prototype.setAccountItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
