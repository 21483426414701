import React from "react";

interface IProps {
  messages: string[];
}

export const ErrorMessage = (props: IProps) => {
  return (
    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs">
      {props.messages.map((m) => `${m} \n`)}
    </span>
  );
};
