import React from "react";
import {
  AccordianContainer,
  AccordianEdit,
  ButtonHead,
  OptionsContainer,
  NoSpacingIconButton
} from "../elements/AccordianElements";
import Options from "../icons/Options";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { CloseButton } from "../elements/CloseButton";
import DropdownSelect from "../DropdownSelect";
import { ThirdPartyInvoiceTemplate } from "sdk/dist/third_party_invoice_templates_pb";
import { ThirdPartyTemplateEditForm } from "./ThirdPartyTemplateEditForm";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

interface Props {
  client: any;
  template: ThirdPartyInvoiceTemplate.AsObject;
  onRemove?: () => void;
}

@observer
export class ThirdPartyTemplateRow extends React.Component<Props> {
  @observable
  private isEditing = false;

  private editOptions() {
    return [this.edit, this.remove];
  }

  edit = {
    label: "Edit",
    onClick: () => {
      this.isEditing = true;
    }
  };

  remove = {
    label: "Remove",
    onClick: () => {
      this.props.onRemove!();
    }
  };

  render() {
    const { template } = this.props;
    return (
      <AccordianContainer>
        <AccordianEdit>
          <ButtonHead style={{ width: "25%" }}>{template.entityName}</ButtonHead>
          {this.isEditing ? (
            <CloseButton
              style={{
                position: "relative",
                padding: "2px"
              }}
              onClick={() => (this.isEditing = false)}
            />
          ) : (
            <OptionsContainer>
              <DropdownSelect options={this.editOptions()}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            </OptionsContainer>
          )}
        </AccordianEdit>
        {this.isEditing && (
          <ThirdPartyTemplateEditForm
            client={this.props.client}
            template={template}
            onRequestClose={() => {
              this.isEditing = false;
            }}
            dfltCcy={ISOCurrencyName.AUD}
          />
        )}
      </AccordianContainer>
    );
  }
}
