import { Stripe, loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";
import { observable } from 'mobx';
import { toastStore } from './toast-store';

class StripeStore {

  @observable
  private pkInstance: Stripe|null = null;
  @observable
  private skInstance: Stripe|null = null;

  async getPKInstance():Promise<Stripe|null> {
    if (this.pkInstance !== null){
      return this.pkInstance;
    }
    if (process.env.BOOKLYFE_STRIPE_KEYPK) {
      this.pkInstance = await loadStripe(process.env.BOOKLYFE_STRIPE_KEYPK);
      return this.pkInstance!;
    } 
    if (process.env.BOOKLYFE_ENVIRONMENT !== "development") {
      Sentry.captureMessage("stripe key undefined", Sentry.Severity.Error);
    } else {
      toastStore.error("stripe key undefined");
    }
    return null;
  }

  async getSKInstance():Promise<Stripe|null> {
    if (this.skInstance !== null){
      return this.skInstance;
    }
    if (process.env.BOOKLYFE_STRIPE_KEYSK) {
      this.skInstance = await loadStripe(process.env.BOOKLYFE_STRIPE_KEYSK);
      return this.skInstance!;
    } 
    if (process.env.BOOKLYFE_ENVIRONMENT !== "development") {
      Sentry.captureMessage("stripe key undefined", Sentry.Severity.Error);
    } else {
      toastStore.error("stripe key undefined");
    }
    return null;
  }

  async loadScriptOnDom(){
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.async = true;
    document.body.appendChild(script);
  }

}

export const stripeStore = new StripeStore();
