import { userStore } from "../mobx/stores/UserStore";
import { IOrganization } from "../pages/create-organization/form/IOrganization";
import apiService, { HTTPMethod } from "../redux/services/api";

class OrganizationService {
  async createOrganization(o: Partial<IOrganization>) {
    const res = await fetch("/api/v1/orgs", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(o)
    });

    const response = await res.json();

    if (response.Errors && response.Errors.length > 0) {
      throw new Error(response.Errors[0].UserMessage)
    }

    return response;
  }

  async validateToken(token: string, userId: string) {
    await apiService.performRequest({
      method: HTTPMethod.Post,
      path: `/api/v1/users/${userId}/validate`,
      data: {
        Type: "Email",
        Token: token
      }
    });
  }

  async resetToken(userId: string) {
    await apiService.performRequest({
      method: HTTPMethod.Post,
      path: `/api/v1/users/${userId}/validate`,
      data: {
        Type: "Email"
      }
    });
  }

  async getOrganizationDetails(id: string) {
    const res =  await fetch(`/api/v1/orgs/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userStore.token}`,
        "Content-Type": "application/json"
      }
    });

    if (res.ok){
      const apiRes = await res.json()
      return apiRes.Payload as IOrganization;
    } else {
      const apiErr = await res.json();
      throw new Error(apiErr.Errors[0].UserMessage);
    }
  }
}

export const sharedOrganizationService = new OrganizationService();
