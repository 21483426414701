import * as React from "react";
import { Cards } from "../../stripe/CreditCard";
import Funds from "./Funds";
import Referrals from "./Referrals";
import ThirdPartyTemplate from "../../third-party-invoices/ThirdPartyTemplate";
import { CreditCard } from "sdk/dist/credit_cards_pb";

interface Props {
  client: any;
}
export default class PaymentsTab extends React.Component<Props> {
  render() {
    if (this.props.client) {
      return (
        <React.Fragment>
          <Cards ownerId={this.props.client.ID} ownerType={CreditCard.OwnerType.CLIENT} />
          {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== "US" && (
            <>
              <Funds clientId={this.props.client.ID} />
              <Referrals client={this.props.client} />
              <ThirdPartyTemplate clientId={this.props.client.ID} />
            </>
          )}
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}
