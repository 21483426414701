import classNames from "classnames";
import { Error } from "grpc-web";
import React, { useCallback, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { sharedMediaService } from "../services/MediaService";
import { ActivityIndicator } from "./ActivityIndicator";
import { FlexBox, FlexItem } from "./FlexBox";
import { ErrorMessage } from "./form/ErrorMessage";
import { Typography } from "./Typography";

interface IProps {
  label: string;
  description?: string | React.ReactNode;
  acceptedFiles?: { format: Accept; label: string };
  value: string;
  onChange: (url: string) => void;
  error?: string;
  setError?: (message: string) => void;
  required?: boolean;
  variant?: "avatar";
}

export const MediaUpload = (props: IProps) => {
  const [isUploading, toggleUploadStatus] = useState(false);
  const [downloadURL, setDownloadURL] = useState("");

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      toggleUploadStatus(true);
      const media = await sharedMediaService.upload(acceptedFiles[0]);
      setDownloadURL(media.downloadUrl);
      props.onChange(media.id);
    } catch (error: Error) {
      props.setError && props.setError(error.messsage);
    } finally {
      toggleUploadStatus(false);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: props.acceptedFiles ? props.acceptedFiles.format : undefined,
    disabled: isUploading,
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false
  });

  return (
    <div className="mb-4 sm:mb-6">
      <FlexBox spacing={[0, 1]} direction="col">
        <FlexItem>
          <Typography.Body color="primary" noPadding>
            {props.label} {props.required && "*"}
          </Typography.Body>
          {props.description && (
            <Typography.Label weight="light" noPadding>
              {props.description}
            </Typography.Label>
          )}
        </FlexItem>
        <FlexItem>
          <div {...getRootProps()}>
            <label
              htmlFor={`${props.label.toLowerCase()}-file-upload`}
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <input
                id={`${props.label.toLowerCase()}-file-upload`}
                name={`${props.label.toLowerCase()}-file-upload`}
                required={props.required}
                {...getInputProps()}
                type="file"
              />
            </label>
            <div
              className={classNames(
                "mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md",
                !downloadURL ? "px-6 py-5" : ""
              )}
              onClick={open}
            >
              {downloadURL ? (
                <div>
                  <img src={downloadURL} alt={props.label.toLowerCase()} />
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  {isUploading ? (
                    <ActivityIndicator color="primary" size="md" />
                  ) : (
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  )}
                  <Typography.Label noPadding>Upload a file or drag and drop</Typography.Label>
                  <Typography.Label>
                    {props.acceptedFiles ? props.acceptedFiles.label : "PNG, JPG, GIF, ..."}
                  </Typography.Label>
                </div>
              )}
            </div>
          </div>
        </FlexItem>
        {props.error && (
          <FlexItem>
            <ErrorMessage messages={[props.error]} />
          </FlexItem>
        )}
      </FlexBox>
    </div>
  );
};
