import styled from "@emotion/styled";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import typography from "./../../util/typography";


export default class TermsOfUse extends Component {
  render() {
    return (
      <Container>
        <Header>
          <Title>Lyfe Group Pty Ltd - Terms Of Use</Title>
          <Subtitle>DATED: December 06, 2018</Subtitle>
        </Header>
        <Content>
          <Body>
            Your use of the <Link to="/">https://app.lyfe.io</Link> ("<strong>Lyfe Group</strong>
            ") owned by Lyfe Group Pty Ltd. ("<strong>we</strong>", "<strong>our</strong>" or "
            <strong>us</strong>") is subject to:
          </Body>
          <UnorderedList>
            <ListItem>these Terms of Use;</ListItem>
            <ListItem>our Privacy Statement; and</ListItem>
            <ListItem>
              any other terms, conditions, notices or disclaimers displayed on our Lyfe Group
              applications
            </ListItem>
          </UnorderedList>
          <SectionSubtitle>1. Contractual Relationship</SectionSubtitle>
          <Body>
            These Terms of Use (“Terms”) govern the access or use by you, an individual, from within
            any country in the world of websites, applications, content, products, and services (the
            “Services”) made available by Lyfe Group Pty Ltd.
          </Body>
          <Body style={{ textAlign: "center" }}>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.
          </Body>
          <Body>
            Your access and use of the Services constitutes your agreement to be bound by these
            Terms, which establishes a contractual relationship between you and Lyfe Group. If you
            do not agree to these Terms, you may not access or use the Services. These Terms
            expressly supersede prior agreements or arrangements with you. Lyfe Group may
            immediately terminate these Terms or any Services with respect to you, or generally
            cease offering or deny access to the Services or any portion thereof, at any time for
            any reason.
          </Body>
          <Body>
            Lyfe Group may amend the Terms related to the Services from time to time. Amendments
            will be effective upon Lyfe Group’s posting of such updated Terms at this location or
            the amended policies or supplemental terms on the applicable Service. Your continued
            access or use of the Services after such posting constitutes your consent to be bounded
            by the Terms, as amended.
          </Body>
          <Body>
            Our collection and use of personal information in connection with the Services is as
            provided in Lyfe Group Privacy Policy located at{" "}
            <Link to="/privacy-policy">https://www.app.lyfe.io/privacy-policy/</Link>
          </Body>
          <SectionSubtitle>2. THE SERVICES</SectionSubtitle>
          <Body>
            The Services constitute a technology platform that enables users of Lyfe Group’s
            application(s) provided as part of the Services (each, an “application”) to search,
            schedule, book and complete transactions for services provided by independent third
            party service providers who are business users of the Lyfe Group applications.
          </Body>
          <Body style={{ textAlign: "center" }}>
            YOU ACKNOWLEDGE THAT LYFE GROUP IS PURELY A TECHNOLOGY SERVICE WHICH DOES NOT PROVIDE
            HEALTH, WELLNESS, BEAUTY OR OTHER PHYSICAL SERVICES TO ITS USERS. AS SUCH HEALTH,
            WELLNESS, BEAUTY OR OTHER PHYSICAL SERVICES ARE PROVIDED BY INDEPENDENT THIRD PARTY LYFE
            GROUP USERS WHO ARE NOT EMPLOYED BY LYFE GROUP OR ANY OF ITS AFFILIATES.
          </Body>
          <SectionSubtitle>INTELLECTUAL PROPERTY RIGHTS</SectionSubtitle>
          <Body>
            All intellectual property rights in information, data and materials used or appearing on
            the Application including (without limitation) all software, tools, know-how, equipment
            or processes, trademarks, logos and other materials shall remain our sole and exclusive
            property. You acknowledge and agree that you shall not acquire any rights, title or
            interest in or to any of our intellectual property rights.
          </Body>
          <Body>
            The copyright to all content on this application including applets, graphics, images,
            layouts and text belongs to us or we have a license to use those materials.
          </Body>
          <Body>
            All trademarks, brands and logos generally identified either with the symbols TM or ®
            which are used on this application are either owned by us or we have a license to use
            them. Your access to our application does not license you to use those marks in any
            commercial way without our prior written permission.
          </Body>
          <Body>
            Any comment, feedback, idea or suggestion (called “comments”) which you provide to us
            through this application becomes our property. If in future, we use your Comments in
            promoting our application or in any other way, we will not be liable for any
            similarities that may appear from such use. Furthermore, you agree that we are entitled
            to use your comments for any commercial or non-commercial purpose without compensation
            to you or to any other person who has transmitted your comments. If you provide us with
            Comments, you acknowledge that you are responsible for the content of such material
            including its legality, originality and copyright.
          </Body>
          <SectionSubtitle>JURISDICTION</SectionSubtitle>
          <Body>
            These terms and conditions are to be governed by and construed in accordance with the
            laws of Victoria and any claim made by either party against the other which in any way
            arises out of these terms and conditions will be heard in Victoria and you agree to
            submit to the jurisdiction of those Courts.
          </Body>
          <Body>
            If any provision in these terms and conditions is invalid under any law the provision
            will be limited, narrowed, construed or altered as necessary to render it valid but only
            to the extent necessary to achieve such validity. If necessary, the invalid provision
            will be deleted from these terms and conditions and the remaining provisions will remain
            in full force and effect.
          </Body>
          <SectionSubtitle>THIRD PARTY SERVICE &amp; CONTENT</SectionSubtitle>
          <Body>
            The Services may be made available or accessed in connection with third party services
            and content that Lyfe Group does not control. You acknowledge that different terms of
            use and privacy policies may apply to your use of such third party services and content.
            Lyfe Group does not endorse such third-party services and content and in no event shall
            Lyfe Group be responsible or liable for any products or services of such third party
            providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation and/or their
            applicable international subsidiaries and affiliates will be third-party beneficiaries
            to this contract if you access the Services using applications developed for Apple,
            Android or Microsoft powered devices, respectively. These third party beneficiaries are
            not parties to this contract and are not responsible for the provision or support of the
            Services in any manner. Your access to the Services using these devices is subject to
            terms set forth in the applicable third party beneficiary’s terms of service.
          </Body>
          <Body>
            This application may from time to time contain hyperlinks to other applications. Such
            links are provided for convenience only and we take no responsibility for the content
            and maintenance of or privacy compliance by any linked application. Any hyperlink on our
            application to another application does not imply our endorsement, support, or
            sponsorship of the operator of that application nor of the information and/or products
            which they provide.
          </Body>
          <Body>
            Linking our application is not permitted, unless approval obtained from Lyfe Group. We
            reserve the right to serve you with notice if we become aware of such linking.
          </Body>
          <Body>By using this application, you acknowledge and agree to the following:</Body>
          <UnorderedList>
            <ListItem>
              Lyfe Group do not control, endorse, approve or warrant the merchantability or fitness
              for any particular purpose of any of the goods or services of third parties referred
              to on application or whose identities become known to you as a result of using the
              Services or otherwise;
            </ListItem>
            <ListItem>
              Lyfe Group do not warrant that anything on the application or any third-party
              application referred to or connected via the Lyfe Group application is accurate,
              complete or up to date and makes no performance warranty whatsoever concerning
              anything on or implied from them;
            </ListItem>
            <ListItem>
              Lyfe Group does not offer professional advice on the fitness of any goods, services or
              information supplied by any third parties;
            </ListItem>
            <ListItem>
              Lyfe Group do not warrant that the application or any information or communication we
              provides is or will be reliable, timely, error or fault free (including on all or any
              devices, browsers or browser versions), complete or accurate;
            </ListItem>
            <ListItem>
              Lyfe Group assume no responsibility, and shall not be liable for any damages resulting
              from viruses that may infect your computer equipment, other property damage, or
              consequential loss resulting from your access to or use of this application. Lyfe
              Group accepts no liability for damage to files, computer hardware or software because
              of downloading any material from this application.
            </ListItem>
            <ListItem>
              Lyfe Group shall not be liable for any loss or damage whether directly or indirectly
              incurred by you or any third person because of any failure or delay in Lyfe Group
              doing anything, including, but not limited to transmitting any information to you or
              removing any information from the application.
            </ListItem>
            <ListItem>
              Lyfe Group is provided 'as is' and 'as available'. This means that you agree that we
              exclusively reserve the right and may, at any time and without notice and any
              liability to you, modify or discontinue this application and its services or delete
              the data you provide, whether temporarily or permanently. We shall have no
              responsibility or liability for the timeliness, deletion, failure to store,
              inaccuracy, or improper delivery of any data or information.
            </ListItem>
            <ListItem>
              Lyfe Group provide no warranty to you that the services generally available through
              our application will be uninterrupted or error-free or that defects in the service
              will be corrected. You also understand that we cannot and do not guarantee or warrant
              to you that files available for downloading through the application or delivered via
              electronic mail through the application will be free of infection or viruses, worms,
              Trojan horses or other code that manifest contaminating or destructive properties.{" "}
            </ListItem>
            <ListItem>
              The application is updated on a regular basis and efforts are made to ensure all
              information is accurate and relevant. However, it is possible that errors may be found
              within the application, for which we are not responsible.
            </ListItem>
            <ListItem>
              The application may experience interruptions and access difficulties from time to time
              and that we will not be responsible for such interruptions or access difficulties.
            </ListItem>
          </UnorderedList>
          <SectionTitle>3. YOUR USE OF THE SERVICES</SectionTitle>
          <SectionSubtitle>CLIENT USER ACCOUNTS</SectionSubtitle>
          <Body>
            If you are an end-user/client user account holder (i.e. non-service provider/business
            account users), then you acknowledge and agree to the following:
          </Body>
          <UnorderedList>
            <ListItem>
              You will be required you to register with us in order to access some parts of the Lyfe
              Group application(s). Where you register with us, you must complete registration by
              providing certain information as set out on our membership/registration page.{" "}
            </ListItem>
            <ListItem>
              You agree to ensure that your registration and payment details are true and accurate
              at all times and you undertake to update your registration details from time to time
              when they change.
            </ListItem>
            <ListItem>
              You must create a password and a login name to become a registered user. Where you
              register with us, you must keep your registration details confidential; you will be
              responsible for any access to our applications using your registration details, even
              if the access is by another person.
            </ListItem>
            <ListItem>
              You are entirely responsible for any and all activities that occur under your login
              name. You may change your password at any time by following instructions. You may also
              delete services attached to your registration at your convenience.
            </ListItem>
            <ListItem>
              You must immediately notify us of any unauthorized use of your login name or any other
              breach of security known to you.
            </ListItem>
            <ListItem>
              You agree that the application may send you a push notification or SMS text messages
              as part of the normal business operation of use of the Services. You acknowledge that
              disabling push notification or (SMS) messages may impact your use of the Services, and
              specifically booking reminders which could have impact on having to pay cancellation
              fees for failing to show.
            </ListItem>
            <ListItem>
              Our services are provided to adults over the age of eighteen (18) years. By proceeding
              to use Lyfe Group services and/or purchases made through our applications, you
              acknowledge that you are over 18 years of age.
            </ListItem>
            <ListItem>
              All prices are in Australian Dollars (AUD) and are inclusive or exclusive of GST
              depending on whether a business is GST exempt due to the it being a medical service.
              GST exemption on our application is not under the control of Lyfe Group Pty Ltd, but
              rather the responsibly of the third party service provider/business user to set up
              their profile according to their legal GST exemption status.
            </ListItem>
            <ListItem>
              We reserve the right to terminate your registration at any time if you breach these
              terms and conditions.
            </ListItem>
          </UnorderedList>
          <SectionSubtitle>SERVICE PROVIDER/BUSINESS ACCOUNTS</SectionSubtitle>
          <Body>
            If you are a service provider/business user account holder (i.e. non- end-user/client
            user), then you acknowledge and agree to the following:
          </Body>
          <UnorderedList>
            <ListItem>
              You will be required you to register with us in order to access some parts of the Lyfe
              Group application(s). Where you register with us, you must complete registration by
              providing certain information as set out on our membership/registration page.
            </ListItem>
            <ListItem>
              You agree to ensure that your registration, services, pricing and payment details are
              true and accurate at all times and you undertake to update your registration details
              from time to time when they change.
            </ListItem>
            <ListItem>
              You must be sure your information is correct and legally compliant when setting up
              your profile. It is your responsibility to assign your account as GST exempt or not
              while building your profile. To choose the GST exempt option when not applicable may
              result in fees and penalties and you alone will be held responsible for the
              implications from the Australian Taxation Office (ATO) should any arise.
            </ListItem>
            <ListItem>
              You agree to pay the associated fee for use of the Lyfe Group technology and services
              upon registration and/or usage.
            </ListItem>
            <ListItem>
              Service fees including subscription fees, transaction, fund top-up pool and merchant
              fees are non-refundable.
            </ListItem>
            <ListItem>
              You must create a password and a login name to become a registered user. Where you
              register with us, you must keep your registration details confidential; you will be
              responsible for any access to our applications using your registration details, even
              if the access is by another person.
            </ListItem>
            <ListItem>
              You are entirely responsible for any and all activities that occur under your login
              name. You may change your password at any time by following instructions. You may also
              edit or delete services you offer and associated availability and pricing attached to
              your registered account at your convenience.
            </ListItem>
            <ListItem>
              You agree to immediately notify us of any unauthorized use of your login name or any
              other breach of security known to you.
            </ListItem>
            <ListItem>
              We reserve the right to terminate your registration at any time if you breach these
              terms and conditions.
            </ListItem>
          </UnorderedList>
          <SectionSubtitle>USE OF APPLICATION</SectionSubtitle>
          <Body>
            You agree that you will only use this application for the purposes of using the Services
            and for no other purpose, or as a third party service provider/business to enable use by
            your client users. We reserve the right to terminate or restrict your access immediately
            and indefinitely if we suspect that you are engaging in any such behavior or are in
            breach of any terms of these Terms. Without limiting the foregoing, in using our Lyfe
            Group application, you agree that you will:
          </Body>
          <UnorderedList>
            <ListItem>Not engage in any unlawful, infringing or offensive activity.</ListItem>
            <ListItem>
              Not post or transmit to or via the application any information or material or
              otherwise use the application for any activity which breaches any laws or regulations,
              infringes a third party’s rights or privacy or is contrary to any relevant standards
              or codes, including generally accepted community standards. You must also not permit
              or enable another person to do any of those things.
            </ListItem>
            <ListItem>
              Email or otherwise send any materials which Lyfe Group branding which are offensive,
              unlawful, harassing, libelous, defamatory, abusive, threatening, harmful, vulgar,
              obscene or otherwise objectionable.
            </ListItem>
            <ListItem>
              Not copy, translate, reproduce, communicate to the public, adapt, vary or modify
              anything on the application without our prior written consent.
            </ListItem>
            <ListItem>
              Not take any action that imposes an unreasonable or disproportionately large load on
              our infrastructure;
            </ListItem>
            <ListItem>
              Not damage, modify, interfere with, disrupt or destroy the files, data, passwords,
              devices or resources that belong to us or do anything that compromises the security of
              the Site;
            </ListItem>
            <ListItem>
              Not use the application to engage in misleading or deceptive on-line marketing
              practices;
            </ListItem>
            <ListItem>
              Not use the application to transmit junk mail, spam, chain letters or engage in other
              flooding techniques or mass distribution of unsolicited email; or
            </ListItem>
            <ListItem>
              You acknowledge and agree that the content on or accessible through this application
              is for informational purposes only and does not constitute health, wellness, beauty or
              other advice. If you are a service provider/business owner this means any advice you
              provide in your client sessions and captured in notes in not considered binding or
              liable to Lyfe Group.
            </ListItem>
            <ListItem>
              You are responsible for implementing sufficient procedures and checkpoints to satisfy
              your particular requirements for accuracy of data input and output, and for
              maintaining a means external to the application for the reconstruction of any lost
              data.{" "}
            </ListItem>
            <ListItem>
              You may not use data mining, robots, screen scraping, or similar data gathering and
              extraction tools on this Application for establishing, maintaining, advancing or
              reproducing information contained on our Application or on your own application or in
              any other publication, except with our prior written consent.
            </ListItem>
            <ListItem>
              We undertake to take all due care with any information which you may provide to us
              when accessing our application. However, we do not warrant and cannot ensure the
              security of any information that you may provide to us. Information you transmit to us
              is entirely at your own risk although we undertake to take reasonable steps to
              preserve such information in a secure manner.
            </ListItem>
            <ListItem>
              You are responsible for completing all transactions you participate in (including
              monitoring the status and complying with all relevant legal obligations);
            </ListItem>
          </UnorderedList>
          <SectionSubtitle>USER-GENERATED CONTENT</SectionSubtitle>
          <Body>
            If you submit, post, transmit or otherwise make any material (“user-generated content”)
            available via a Lyfe Group application, then by submitting your user-generated content
            you are agreeing to the following:
          </Body>
          <UnorderedList>
            <ListItem>
              Lyfe Group may, in Lyfe Group’s sole discretion, permit you from time to time to
              submit, upload, publish or otherwise make available to Lyfe Group through the Services
              textual and/or visual content and information, including commentary and feedback
              related to the Services, initiation of support requests, and submissions (“user
              content”).{" "}
            </ListItem>
            <ListItem>
              You agree to not provide user content that is defamatory, libelous, hateful, violent,
              obscene, pornographic, unlawful, or otherwise offensive, as determined by Lyfe Group
              in its sole discretion, whether or not such material may be protected by law. Lyfe
              Group may, but shall not be obligated to, review, monitor, or remove User content, at
              Lyfe Group’s sole discretion and at any time and for any reason, without notice to
              you.
            </ListItem>
            <ListItem>
              Any User content provided by you remains your property. However, by providing user
              content to Lyfe Group, you grant Lyfe Group a non-exclusive, worldwide, perpetual,
              irrevocable, transferrable, royalty-free license, with the right to sublicense, to
              use, copy, modify, create derivative works of, distribute, publicly display and
              otherwise exploit in any manner for the reasonable purposes of our business, and the
              right to sub-license those rights to others without further notice to or consent from
              you, and without the requirement of payment to you or any other person or entity. You
              also consent to any act or omission that would otherwise infringe any of your rights
              (including your moral rights) in your content.
            </ListItem>
            <ListItem>
              You represent and warrant that: (i) you either are the sole and exclusive owner of all
              user content or you have all rights, licenses, consents and releases necessary to
              grant Lyfe Group the license to the user content as set forth above; and (ii) neither
              the user content nor your submission, uploading, publishing or otherwise making
              available of such user content nor Lyfe Group’s use of the user content as permitted
              herein will infringe, misappropriate or violate a third party’s intellectual property
              or proprietary rights, or rights of publicity or privacy, or result in the violation
              of any applicable law or regulation.
            </ListItem>
            <ListItem>
              You warrant our exercise of the license rights above will not infringe the
              intellectual property rights of any person, and that the content is not defamatory and
              does not breach any law.
            </ListItem>
            <ListItem>
              We may monitor or review your content, but we are not obliged to do so. We may also
              alter or remove any of your content at any time, including to ensure the operational
              integrity of our services.
            </ListItem>
            <ListItem>
              Not upload/post to Lyfe Group application any material of any kind that contains
              computer worms, viruses or other types of malicious or harmful programs.
            </ListItem>
            <ListItem>
              Not provide us with complete and accurate contact details when using the Service.
            </ListItem>
          </UnorderedList>
          <SectionTitle>4. PAYMENT</SectionTitle>
          <Body>
            You understand that use of the Services may result in charges to you for the services or
            goods you receive from a Third Party Provider (“charges”). After you have received
            services or goods obtained through your use of the Service, Lyfe Group will facilitate
            your payment of the applicable Charges on behalf of the Third-Party Provider as such
            Third-Party Provider’s limited payment collection agent. Payment of the Charges in such
            manner shall be considered the same as payment made directly by you to the Third-Party
            Provider. Charges will be inclusive of applicable taxes where required by law. Charges
            paid by you are final and non-refundable.
          </Body>
          <Body>
            All Charges are due immediately and payment will be facilitated by Lyfe Group using the
            preferred payment method designated in your account, after which Lyfe Group will make an
            electronic copy available. If your primary account payment method is determined to be
            expired, invalid or otherwise not able to be charged, you agree that Lyfe Group may, as
            the Third Party Provider’s limited payment collection agent, use a secondary payment
            method in your account, if available.
          </Body>
          <Body>
            As between you and Lyfe Group, Lyfe Group reserves the right to establish, remove and/or
            revise charges for any or all services or goods obtained using the Services at any time
            in Lyfe Group’s sole discretion. Lyfe Group may from time to time provide certain
            service provide/business users with promotional offers and discounts that may result in
            different amounts charged for the same or similar services or goods obtained through the
            use of the services, and you agree that such promotional offers and discounts, unless
            also made available to you, shall have no bearing on your use of the Services or the
            charges applied to you. You may elect to cancel your request for services or goods from
            a Third Party Provider at any time prior to such Third Party Provider’s booking, in
            which case you may be charged a cancellation fee.
          </Body>
          <Body>
            This payment structure is intended to fully compensate the Third Party Provider for the
            services or goods provided. Lyfe Group does not designate any portion of your payment as
            a tip or gratuity to the Third Party Provider.
          </Body>
          <SectionTitle>DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY</SectionTitle>
          <SectionSubtitle>DISCLAIMERS</SectionSubtitle>
          <Body>
            Whilst we take all due care in providing our services, we do not provide any warranty
            either express or implied including without limitation warranties of merchantability or
            fitness for a particular purpose.
          </Body>
          <Body>
            To the extent permitted by law, any condition or warranty which would otherwise be
            implied into these terms and conditions is excluded.
          </Body>
          <Body>
            We also take all due care in ensuring that our application is free of any virus, worm,
            Trojan horse and/or malware, however we are not responsible for any damage to your data
            or computer system which arises in connection with your use of our application or any
            linked application.
          </Body>
          <Body>
            To the extent permitted by law, any condition or warranty which would otherwise be
            implied into these terms and conditions is excluded.
          </Body>
          <Body>
            From time to time we may host third party content on our application such as
            advertisements and endorsements belonging to other traders. Responsibility for the
            content of such material rests with the owners of that material and we are not
            responsible for any errors or omissions in such material.
          </Body>
          <SectionSubtitle>LIMITATION OF LIABILITY</SectionSubtitle>
          <Body>
            If you are not a consumer within the meaning of Schedule 2 of the C&amp;C Act then this
            clause applies to you. . If you are a consumer within the meaning of the C&amp;C Act
            then this clause has no effect whatsoever to in any way limit our liability or your
            rights.
          </Body>
          <Body>If you are not a consumer:-</Body>
          <UnorderedList>
            <ListItem>
              To the full extent permitted by law, our liability for breach of an implied warranty
              or condition is limited to the supply of the services again or payment of the costs of
              having those services supplied again.
            </ListItem>
            <ListItem>
              We accept no liability for any loss whatsoever including consequential loss suffered
              by you arising from services we have supplied.
            </ListItem>
            <ListItem>
              We do not accept liability for anything contained in the post of a user or in any form
              of communication which originates with a user and not with Us.
            </ListItem>
            <ListItem>
              We do not participate in any way in the transactions between our users.
            </ListItem>
          </UnorderedList>
          <SectionSubtitle>INDEMNITY</SectionSubtitle>
          <UnorderedList>
            <ListItem>
              By accessing our application, you agree to indemnify and hold us harmless from all
              claims, actions, damages, costs and expenses including legal fees arising from or in
              connection with your use of our application.
            </ListItem>
          </UnorderedList>
          <Body style={{ opacity: 0.6 }}>
            This Privacy Policy has been specifically drafted for and provided to Lyfe Group Pty Ltd
            by LawLive Pty Ltd (<a href="www.lawlive.com.au">www.lawlive.com.au</a>).
          </Body>
        </Content>
      </Container>
    );
  }
}

const Container = styled.div`
  max-width: 826px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Header = styled.div`
  padding: 50px 0;
`;

const Content = styled.div`
  padding: 30px 0;
`;

const UnorderedList = styled.ul`
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
`;

const ListItem = styled.li`
  ${typography.body1};
`;

const Title = styled.h2`
  ${typography.heading3};
`;

const Subtitle = styled.p`
  ${typography.subtitle1};
  font-weight: 600;
  opacity: 0.6;
  text-transform: uppercase;
  margin-top: 24px;
`;

const SectionTitle = styled.h6`
  ${typography.heading6};
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const SectionSubtitle = styled.p`
  ${typography.subtitle1};
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

const Body = styled.p`
  ${typography.body1};
  margin-bottom: 24px;
`;
