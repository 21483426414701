import styled from "@emotion/styled";
import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import Select from "../../../components/form/BaseSelect";
import { CARD_ELEMENT_OPTIONS, StripeElementsContainer } from "../../../components/stripe/CreditCard/NewCardFormStripe";
import { metadata, rpc } from "../../../grpc";
import { AffiliateCodeRequest, SetAffiliateCodeRequest, SetAffiliateCodeResponse } from "sdk/dist/affiliate_code_pb";
import { CreateCreditCardRequest, CreditCard } from "sdk/dist/credit_cards_pb";
import { stripeStore } from "../../../stores/stripe-store";
import { toastStore } from "../../../stores/toast-store";
import Button from "./../../../components/Button";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
import * as GlobalActions from "./../../../redux/features/global/actions";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { sharedCurrencyService } from './../../../services/CurrencyService';
import { validABN } from "./../../../util";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";
import { colors, currency, taxDetail } from "./../../../util/consts";
import { parseFormError } from "./../../../util/form";

let cardElement = null;
let stripeInstance = null;

class OrgCreateAccount_Payment extends React.Component {
  state={
    currencies: new Array()
  }

  async componentDidMount() {
    stripeStore.loadScriptOnDom();
    stripeInstance = await stripeStore.getPKInstance();
    if(stripeInstance !== null){
      var elements = stripeInstance.elements();
      cardElement = elements.create("card", { style: CARD_ELEMENT_OPTIONS.style.base });
      cardElement.mount("#card-element");
    }
    const res = await sharedCurrencyService.getCurrencies(this.props.pageData.Country);
    res && res.length && this.setState({ currencies: res.map((c) => c.isoName) })
  }

  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }

  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    const handleGoBack = (e) => {
      e.preventDefault();
      this.props.setPage("subscription")
    }

    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
              e.preventDefault();
              return false;
            }
            : handleSubmit
        }
      >
        <HoverDiv>
          <Col1> <FormHeader
            title={pageData.Subscription != "Starter-Kit" ? "Payment Details" : "Payment Details (optional)"}
            subtitle={process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === 'AU' ? "Please add a credit card for subscription fee billing or if you require bulk billing, HICAPS or DVA claiming" : ''}
          /> </Col1>
          {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === 'AU' ? <Col2><BlockSubtitle>?</BlockSubtitle><TooltipText> Your card will not be charged until after the 30 day free trail. You can cancel at anytime before 30 days and you will not be charged. </TooltipText></Col2> : ''}
        </HoverDiv>
        <SectionsContainer>
          <Section>
            <StripeElementsContainer>
              <div id="card-element"></div>
            </StripeElementsContainer>
          </Section>
        </SectionsContainer>
        <FormHeader title="Bank Account" subtitle="In order to recieve payments from your customers, please fill out your business' bank account details" />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-bd-aname">Account name</Label>
            <Input
              id="createorg-bd-aname"
              name="BankDetailsAU_AccountName"
              type="text"
              placeholder="Account name"
              value={values.BankDetailsAU_AccountName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountName && errors.BankDetailsAU_AccountName}
            />
            {!!errors.BankDetailsAU_AccountName && touched.BankDetailsAU_AccountName && (
              <Error>{errors.BankDetailsAU_AccountName}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-bd-bsb">{process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === 'AU' ? 'BSB' : 'Routing number'}</Label>
            <Input
              id="createorg-bd-bsb"
              name="BankDetailsAU_AccountBSB"
              type="text"
              placeholder="Routing number"
              value={values.BankDetailsAU_AccountBSB}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountBSB && errors.BankDetailsAU_AccountBSB}
            />
            {!!errors.BankDetailsAU_AccountBSB && touched.BankDetailsAU_AccountBSB && (
              <Error>{errors.BankDetailsAU_AccountBSB}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-bd-anum">Account number</Label>
            <Input
              id="createorg-bd-anum"
              name="BankDetailsAU_AccountNumber"
              type="text"
              placeholder="Account number"
              value={values.BankDetailsAU_AccountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.BankDetailsAU_AccountNumber && errors.BankDetailsAU_AccountNumber}
            />
            {!!errors.BankDetailsAU_AccountNumber && touched.BankDetailsAU_AccountNumber && (
              <Error>{errors.BankDetailsAU_AccountNumber}</Error>
            )}
          </Section>
        </SectionsContainer>
        <FormHeader
          title="Tax Information"
          subtitle="Please fill out the tax information relating to your business"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-td-ename">Entity name</Label>
            <Input
              id="createorg-td-ename"
              name="TaxDetailsAU_EntityName"
              type="text"
              placeholder="Entity name"
              value={values.TaxDetailsAU_EntityName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.TaxDetailsAU_EntityName && errors.TaxDetailsAU_EntityName}
            />
            {!!errors.TaxDetailsAU_EntityName && touched.TaxDetailsAU_EntityName && (
              <Error>{errors.TaxDetailsAU_EntityName}</Error>
            )}
          </Section>
          <Section>
            <Label htmlFor="createorg-td-abn">{taxDetail(pageData.Country || process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY)}</Label>
            <Input
              id="createorg-td-abn"
              name="TaxDetailsAU_ABN"
              type="text"
              placeholder="ABN"
              value={values.TaxDetailsAU_ABN}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.TaxDetailsAU_ABN && errors.TaxDetailsAU_ABN}
            />
            {!!errors.TaxDetailsAU_ABN && touched.TaxDetailsAU_ABN && (
              <Error>{errors.TaxDetailsAU_ABN}</Error>
            )}
          </Section>
          <Section>
          <FormHeader title="Default Currency" subtitle="This currency will be used as the default for all transactions, this can later be changed" />
              <Select
                id="editorg-currency"
                name="DefaultCurrency"
                value={values.DefaultCurrency}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {this.state.currencies.map((c, key) => (
                  <option key={key} value={c}>
                    {c}
                  </option>
                ))}
            </Select>
          </Section>
          {!pageData.isLoggedIn ? <div><FormHeader title="Affiliate Code" subtitle="Enter you discount code" />
          <Section>
            <br />
            <Label htmlFor="createorg-affiliate"></Label>
            <Input
              id="createorg-affiliate"
              name="Affiliate"
              type="text"
              placeholder="Enter affiliate code"
              value={values.Affiliate}
              onChange={this.handleValidateCode}
              error={touched.Affiliate && errors.Affiliate}
            />
            {!!errors.Affiliate && <Error>{errors.Affiliate}</Error>}
            </Section></div> : false}
        </SectionsContainer>
        <FooterContainer>
          <Button
            style={{ width: 150, marginRight: "5px", backgroundColor: "rgba(44,46,60,0.12)", color: "rgba(0,0,0,0.38)" }}
            type="button"
            onClick={(handleGoBack)}
          >
            Go Back
          </Button>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.TaxDetailsAU_EntityName}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const TooltipText = styled.span`
position: absolute;
visibility: hidden;
width: 240px;
background-color: #555;
padding: 10px;
color: #fff;
text-align: center;
border-radius: 6px;
z-index: 10;
transition: opacity .6s;
margin-left: 2px;
margin-top: -5px;
`
const BlockSubtitle = styled.div`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 20.82px;
  letter-spacing: 0.25px;
  line-height: 13px;
  margin-left: 6px;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

const HoverDiv = styled.div`
  display: flex;
    &:hover ${TooltipText} {
      visibility: visible;
    }
`;

const Col1 = styled.div`
  width: 90%;
`;

const Col2 = styled.div`
  width: 10%;
`;

const SendToHubspot = (pageData, props, values) => {
// hubspot post request
fetch(
  "https://api.hsforms.com/submissions/v3/integration/submit/4603694/34d176f9-1fdd-46db-8bfc-5a86837d949e",
  {
    method: "POST",
    body: JSON.stringify({
      fields: [
        {
          name: "firstname",
          value: pageData.Name.trim()
        },
        {
          name: "email",
          value: pageData.ContactEmail.trim()
        },
        {
          name: "company",
          value: pageData.Name.trim()
        },
        {
          name: "phone",
          value: pageData.ContactPhone.trim()
        },
        {
          name: "address",
          value: pageData.Address
        }
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to receive marketing communications from Example Company."
            }
          ]
        }
      }
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }
)
  .then((res) => res.json())
  .then((response) => console.log("Success:", "hubspot", JSON.stringify(response)))
  .catch((error) => console.error("Error:", "hubspot", error));
// end of hubspot integretation
}

const CreateOrg = async (pageData, values, props) =>{
  // Create the organisation.
  if (!pageData.isLoggedIn) {
    const prDet = await findPlaceDetails(pageData.Address, "Address");
    const prAddRes = {
      OwnerType: "Organisation",
      ...getAddressResourceFromPlaceDetails(prDet, values.Address)
    };
    const prRes = await props.dispatch(ResourceActions.action(prAddRes, "Create", {}));
    pageData.AddressID = prRes.Payload.ID;
  }

  const org = {
    $Metadata: { Type: "Organisation" },
    Name: pageData.Name.trim(),
    Description: pageData.Description.trim(),
    ContactEmail: pageData.ContactEmail.trim(),
    ContactPhone: pageData.ContactPhone.trim(),
    Address: pageData.AddressID,
    Logo: pageData.Logo,
    Banner: pageData.Banner,
    BankAccountDetails: {
      AccountName: values.BankDetailsAU_AccountName.trim(),
      BSB: values.BankDetailsAU_AccountBSB.trim(),
      AccountNumber: values.BankDetailsAU_AccountNumber.trim()
    },
    TaxDetails: {
      EntityName: values.TaxDetailsAU_EntityName.trim(),
      ABN: values.TaxDetailsAU_ABN.replace(/\s+/g, "").trim()
    },
    SubscriptionType: pageData.Subscription,
    Settings: {
      RequireBookingApproval: true,
      NotificationDaysBefore: 1,
      PenaltyFeeFixed: 0,
      PenaltyFeeVar: 1000,
      PenaltyTimePeriod: 48,
      ClientPaysCCFee: false,
      ThirdPartyTACs: "",
      DefaultCurrency: values.DefaultCurrency,
    },
    Integrations: {}
  };

  const orgCreate = await props.dispatch(ResourceActions.action(org, "Create", {}));

  await props.dispatch(
    ResourceActions.listAction(
      { $Metadata: { List: "AuthUserOrgs", Context: {} } },
      false,
      "Fetch"
    )
  );
  return await orgCreate
}

const AddCreditCard = async (pageData, orgCreate, values, props) => {
 // Add credit card to org
 const result = await stripeInstance.createToken(cardElement);
 if (result.error) {
   toastStore.error("Credit card couldn't be added, error: \n"+result.error.message+
   "\nYou can add it later from your business profile");
 } else {
     if (!result.token) {
       toastStore.error("Stripe token undefined");
     }
     if (orgCreate.Payload.ID){
       const req = new CreateCreditCardRequest();
       req.setToken(result.token.id);
       req.setOwner(orgCreate.Payload.ID);
       req.setOwnerType(CreditCard.OwnerType.ORGANISATION);
      const addCreditCard = await rpc.orgCards.create(req, metadata());
      return await addCreditCard;
     }
 }
}

const AddAffiliateCode = async (pageData, orgCreate, values, props) => {
      if (pageData.isLoggedIn) {
      //Add Affiliate Code
      if (pageData.Affiliate && pageData.Affiliate !== "") {
        const reqSetAffiliateOrg = new SetAffiliateCodeRequest();
        reqSetAffiliateOrg.setAffiliateCode(pageData.Affiliate.trim());
        reqSetAffiliateOrg.setOrgId(orgCreate.Payload.ID);
        const resSetAffiliateOrg = await rpc.affiliateService.setOrgCode(reqSetAffiliateOrg, metadata());

        switch (resSetAffiliateOrg.getAffiliateCodeStatus()) {
          case SetAffiliateCodeResponse.Status.APPLIED_SUCCESSFULLY:
            toastStore.success('Affiliate code applied successfully');
            break;
          default:
            toastStore.error('Affiliate code not applied');
            break;
        }
      }
    } else {
       // add affiliate code to db
       const reqAffiliate = new AffiliateCodeRequest();
       reqAffiliate.setAffiliateCode(values.Affiliate.trim());
       const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
       const affiliateCodeExists = resAffiliate.toObject().exists;
       if (!affiliateCodeExists) {
         setFieldError("Affiliate", "Not a valid code");
         throw apiService.errorProcess(
           new APIError(HTTPMethod.Get, 'affiliateService Grpc',
             {
               Status: `Error`,
               StatusCode: 400,
               Errors: [
                 { UserMessage: "Not a valid code" }
               ]
             }
           ));
       }

       //check if affiliate code successful or not
       if (values.Affiliate && values.Affiliate !== "") {
         const reqSetAffiliateOrg = new SetAffiliateCodeRequest();
         reqSetAffiliateOrg.setAffiliateCode(values.Affiliate.trim());
         reqSetAffiliateOrg.setOrgId(orgCreate.Payload.ID);
         const resSetAffiliateOrg = await rpc.affiliateService.setOrgCode(reqSetAffiliateOrg, metadata());

         switch (resSetAffiliateOrg.getAffiliateCodeStatus()) {
           case SetAffiliateCodeResponse.Status.APPLIED_SUCCESSFULLY:
             toastStore.success('Affiliate code applied successfully');
             break;
           default:
             toastStore.error('Affiliate code not applied');
             break;
         }
       }
    }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }) => ({
    TaxDetailsAU_EntityName: pageData.TaxDetailsAU_EntityName || "",
    TaxDetailsAU_ABN: pageData.TaxDetailsAU_ABN || "",
    BankDetailsAU_AccountName: pageData.BankDetailsAU_AccountName || "",
    BankDetailsAU_AccountBSB: pageData.BankDetailsAU_AccountBSB || "",
    BankDetailsAU_AccountNumber: pageData.BankDetailsAU_AccountNumber || "",
    DefaultCurrency: currency(pageData.Country? pageData.Country : process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY)
  }),
  validationSchema: ({ pageData }) => Yup.object().shape({
    TaxDetailsAU_EntityName: pageData.Subscription != "Starter-Kit" ? Yup.string().required("Entity name is required.") : Yup.string(),
    TaxDetailsAU_ABN: pageData.Country === 'AU' ? Yup.string()
      .test("ABN Check", "Invalid ABN Number", validABN)
      .required("ABN is required."): Yup.string().required(taxDetail(pageData.Country) + " is required"),
    BankDetailsAU_AccountName: Yup.string().required("Account name is required."),
    BankDetailsAU_AccountBSB: pageData.Country === 'AU' ? Yup.string()
      .matches(/^\s*[0-9]{6}\s*$/, "BSB must be 6 digits")
      .required("BSB is required") : Yup.string().required("Routing number is required"),
    BankDetailsAU_AccountNumber: pageData.Country === 'AU'? Yup.string()
      .matches(/^\s*[0-9]+\s*$/, "Account number must be made up of digits")
      .required("Account number is required") : Yup.string().required("Account number is required")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {

    const { pageData, dispatch } = props;
    try {
      if (pageData.isLoggedIn){
      SendToHubspot(pageData, props, values)
      }
      const orgCreate = await CreateOrg(pageData, values, props)
      AddCreditCard(pageData, orgCreate, values, props)
      AddAffiliateCode(pageData, orgCreate, values, props)

      dispatch(GlobalActions.setUserContext(orgCreate.Payload.ID, ""));
      toastStore.success("Organisation Created!");
      props.setPage("fundpool", { orgID: orgCreate.Payload.ID });
    } catch (err) {
      if (values && setFieldError){
        parseFormError(err, values, setFieldError, props);
      } else {
        toastStore.error(err);
      }
    }
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountPayment"
});

export default compose(
  connect((state) => ({})),
  formikEnhancer
)(OrgCreateAccount_Payment);
