import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router';
import { authService } from '../services/AuthService';
import { userStore } from '../mobx/stores/UserStore';
import apiService from '../redux/services/api';
import { ActivityIndicator } from '../ui-kit/ActivityIndicator';

export const ProtectedIframeRoute =  ({ children, ...rest } : RouteProps)  => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [invalidSession, setInvalidSession] = useState(false);
  const history = useHistory();

  const token = location.hash.split('token=')[1];
  if (!userStore.isValidToken(token)) {
    history.push('/iframe/invalid-session/');
  }

  useEffect(() => {
    async function fetchUser() {
      try {
        const payload = authService.parseJwtPayload(token);
        if (payload && payload.sub) {
          await apiService.userLoginWithToken(token)
        }
      } catch {
        // set invalid session
        setInvalidSession(true);
      } finally {
        setLoading(false);
      }
    }
    fetchUser()
  }, []);

  // load user
  if (loading) {
    return <ActivityIndicator />
  }

  if (invalidSession) {
    history.push('/iframe/invalid-session/');
  }

  return (
    <Route
      {...rest}
      render={() => children}
    />
  );
}
