import { observable, computed } from "mobx";
import { Metrics } from "sdk/dist/insight_metrics_pb";
import { RevenueRows } from "sdk/dist/revenue_table_pb";
import { Revenue } from "sdk/dist/insight_revenue_pb";
import { dataItemType } from "./../../components/routes/InsightDashboard/DataArea";
import { camelCaseToSentence } from "./../../util/string";
import { ClientData } from "sdk/dist/insight_client_pb";
import { ClientRows } from "sdk/dist/client_table_pb";
import { ReferralData } from "sdk/dist/insight_referral_pb";
import { ReferralRows } from "sdk/dist/referral_table_pb";
import { UserUIType } from "sdk/dist/location_user_pb";

class InsightDataStore {
  @observable
  private _metricsRes: Metrics = new Metrics();

  @observable
  private _revenueTableRes: RevenueRows = new RevenueRows();

  @observable
  private _clientTableRes: ClientRows = new ClientRows();

  @observable
  private _referralTableRes: ReferralRows = new ReferralRows();

  @observable
  private _revenueRes: Revenue = new Revenue();

  @observable
  private _clientRes: ClientData = new ClientData();

  @observable
  private _referralRes: ReferralData = new ReferralData();

  set metricsRes(metricsRes: Metrics) {
    this._metricsRes = metricsRes;
  }
  get metricsRes(): Metrics {
    return this._metricsRes;
  }
  set revenueRes(revenueRes: Revenue) {
    this._revenueRes = revenueRes;
  }

  get revenueRes(): Revenue {
    return this._revenueRes;
  }

  set clientRes(clientRes: ClientData) {
    this._clientRes = clientRes;
  }

  get clientRes(): ClientData {
    return this._clientRes;
  }

  set referralRes(referralRes: ReferralData) {
    this._referralRes = referralRes;
  }

  get referralRes(): ReferralData {
    return this._referralRes;
  }

  @computed
  get allRevenueItems(): Array<dataItemType> {
    let revenueObj = this._revenueRes.toObject();
    let excludedMetrics =
      localStorage.getItem("UserUIType") == String(UserUIType.FITNESS) ||
      process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US"
        ? ["Medicare Standard", "Dva", "Bulk Billing", "Hicaps"]
        : [];
    let objArray = Object.keys(revenueObj).map((i) => {
      return { title: camelCaseToSentence(i), statistic: parseFloat(revenueObj[i].toFixed(2)) };
    });
    objArray = objArray.filter((j) => {
      if (excludedMetrics.includes(j.title)) {
        return false;
      }
      return true;
    });
    return objArray;
  }

  @computed
  get allClientDataItems(): Array<dataItemType> {
    let clientDataObj = this._clientRes.toObject();
    return Object.keys(clientDataObj).map((i) => {
      return { title: camelCaseToSentence(i), statistic: parseFloat(clientDataObj[i].toFixed(2)) };
    });
  }

  @computed
  get allReferralDataItems(): Array<dataItemType> {
    let referralDataObj = this._referralRes.toObject();
    return Object.keys(referralDataObj).map((i) => {
      return {
        title: camelCaseToSentence(i),
        statistic: parseFloat(referralDataObj[i].toFixed(2))
      };
    });
  }

  set revenueTableRes(revenueTableRes: RevenueRows) {
    this._revenueTableRes = revenueTableRes;
  }

  @computed
  get revenueTableRes(): RevenueRows {
    return this._revenueTableRes;
  }

  set clientTableRes(clientTableRes: ClientRows) {
    this._clientTableRes = clientTableRes;
  }

  @computed
  get clientTableRes(): ClientRows {
    return this._clientTableRes;
  }

  @computed
  get referralTableRes(): ReferralRows {
    return this._referralTableRes;
  }

  set referralTableRes(referralTableRes: ReferralRows) {
    this._referralTableRes = referralTableRes;
  }

  @computed
  get allMetricsItem(): Array<dataItemType> {
    let metricObj = this._metricsRes.toObject();
    return Object.keys(metricObj).map((i) => {
      return { title: camelCaseToSentence(i), statistic: parseFloat(metricObj[i].toFixed(2)) };
    });
  }
}

export const sharedInsightDataStore = new InsightDataStore();
