interface ICountry {
  name: string;
  code: string;
  currency: string;
}

// Alpha code for countries https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export const supportedCountriesList: ICountry[] = [
  {
    name: "Australia",
    code: "AU",
    currency: "AUD"
  },
  {
    name: "Austria",
    code: "AT",
    currency: "EUR"
  },
  {
    name: "Belgium",
    code: "BE",
    currency: "EUR"
  },
  {
    name: "Canada",
    code: "CA",
    currency: "CAN"
  },
  {
    name: "Denmark",
    code: "DK",
    currency: "DKK"
  },
  {
    name: "Finland",
    code: "FI",
    currency: "EUR"
  },
  {
    name: "Germany",
    code: "DE",
    currency: "EUR"
  },
  {
    name: "Ireland",
    code: "IE",
    currency: "EUR"
  },
  {
    name: "Netherlands",
    code: "NL",
    currency: "EUR"
  },
  {
    name: "New Zealand",
    code: "NZ",
    currency: "NZD"
  },
  {
    name: "Norway",
    code: "NO",
    currency: "NOK"
  },
  {
    name: "Singapore",
    code: "SG",
    currency: "SGD"
  },
  {
    name: "Sweden",
    code: "SE",
    currency: "SEK"
  },
  {
    name: "Switzerland",
    code: "CH",
    currency: "CHF"
  },
  {
    name: "United Kingdom",
    code: "GB",
    currency: "GBP"
  },
  {
    name: "United States",
    code: "US",
    currency: "USD"
  }
];
