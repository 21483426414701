import classNames from "classnames";
import React from "react";

type Gap = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40 | 64;
type Span = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type Direction = "row" | "col";

interface IProps {
  gap?: [Gap, Gap];
  flow?: "row" | "col";
  direction: Direction;
  children: React.ReactNode;
  span: Span;
  xs?: { direction: Direction; span: Span };
  sm?: { direction: Direction; span: Span };
  md?: { direction: Direction; span: Span };
  lg?: { direction: Direction; span: Span };
  xl?: { direction: Direction; span: Span };
  xxl?: { direction: Direction; span: Span };
}

export const Stack = (props: IProps) => {
  return (
    <div
      className={classNames(
        "grid",
        props.gap && `gap-x-${props.gap[0]} gap-y-${props.gap[1]}`,
        props.flow && `grid-flow-${props.flow}`,
        `grid-${props.direction}s-${props.span}`,
        props.xs && `xs:grid-${props.xs.direction}s-${props.xs.span}`,
        props.sm && `sm:grid-${props.sm.direction}s-${props.sm.span}`,
        props.md && `md:grid-${props.md.direction}s-${props.md.span}`,
        props.lg && `lg:grid-${props.lg.direction}s-${props.lg.span}`,
        props.xl && `xl:grid-${props.xl.direction}s-${props.xl.span}`,
        props.xxl && `xxl:grid-${props.xxl.direction}s-${props.xxl.span}`
      )}
    >
      {props.children}
    </div>
  );
};

type ItemSpan = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | "full";

interface IStackItem {
  children: React.ReactNode;
  direction: "row" | "col";
  span: ItemSpan;
  start?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10;
  end?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10;
  xs?: { direction: "row" | "col"; span: ItemSpan };
  sm?: { direction: "row" | "col"; span: ItemSpan };
  md?: { direction: "row" | "col"; span: ItemSpan };
  lg?: { direction: "row" | "col"; span: ItemSpan };
  xl?: { direction: "row" | "col"; span: ItemSpan };
  xxl?: { direction: "row" | "col"; span: ItemSpan };
}

const StackItem = (props: IStackItem) => {
  return (
    <div
      className={classNames(
        `${props.direction}-span-${props.span}`,
        props.xs && `xs:${props.xs.direction}-span-${props.xs.span}`,
        props.sm && `sm:${props.sm.direction}-span-${props.sm.span}`,
        props.md && `md:${props.md.direction}-span-${props.md.span}`,
        props.lg && `lg:${props.lg.direction}-span-${props.lg.span}`,
        props.xl && `xl:${props.xl.direction}-span-${props.xl.span}`,
        props.xxl && `xxl:${props.xxl.direction}-span-${props.xxl.span}`,
        props.start && `${props.direction}-${props.start}`,
        props.end && `${props.direction}-${props.end}`
      )}
    >
      {props.children}
    </div>
  );
};

Stack.Item = StackItem;
