import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Form from "../../components/form/Form";
import { IOrganization } from "../../pages/create-organization/form/IOrganization";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { FormContainer } from "../../ui-kit/form/FormContainer";
import { MediaUpload } from "../../ui-kit/MediaUpload";
import { TextArea } from "../../ui-kit/TextArea";
import { tracking } from "../../util/tracking";

interface IProps {
  organization: Partial<IOrganization>;
  setOrgDetails: (o: Partial<IOrganization>) => void;
  nextStep: () => void;
  previousStep: () => void;
}

export const DescriptionForm = (props: IProps) => {
  const schema = Yup.object<IOrganization>().shape({
    Description: Yup.string().required("Description is required."),
    Logo: Yup.string().required("Logo is required."),
    Banner: Yup.string().required("Banner Image is required.")
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getValues,
    setValue,
    watch
  } = useForm<IOrganization>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      Description: props.organization.Description,
      Logo: props.organization.Logo,
      Banner: props.organization.Banner
    }
  });

  const onSubmit = (data: IOrganization) => {
    tracking.organizationStepperFormCompleted("description");
    props.setOrgDetails({ ...props.organization, ...data });
    props.nextStep();
  };

  useEffect(() => {
    watch();
  }, [watch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer
        label="Description"
        description="This will be part of your profile when people search for you."
      >
        <TextArea
          placeholder='Enter your business description - Example: “At Lyfe Health we take your health services seriously. We offer premium services from the top health providers in Australia. Our services include, Physiotherapy &amp; Psychology."'
          value={getValues("Description")}
          onChange={(v) => setValue("Description", v || "")}
          error={errors.Description ? errors.Description.message : ""}
          label="Description"
          required
        />

        <MediaUpload
          description={
            <>
              This will be displayed in your invoices and tax receipts. <br /> Ideal size for the
              image is 512px x 512px.
            </>
          }
          onChange={(url) => setValue("Logo", url)}
          acceptedFiles={{
            format: { "image/*": [] },
            label: "PNG, JPG, ..."
          }}
          error={errors.Logo ? errors.Logo.message : ""}
          setError={(m) =>
            setError("Logo", {
              message: m
            })
          }
          value={getValues("Logo")}
          label="Logo"
          required
        />

        <MediaUpload
          description={
            <>
              This photo is used as a banner and thumbnail image for your profile. It should
              represent who you are and not have a logo or text. Ideal size for the image is 1024px
              x 512px. If you are struggling to find an image you can get a{" "}
              <a href="https://unsplash.com/search/photos/health" target="_blank">
                free one from Unsplash
              </a>
            </>
          }
          onChange={(url) => setValue("Banner", url)}
          error={errors.Banner ? errors.Banner.message : ""}
          acceptedFiles={{
            format: { "image/*": [] },
            label: "PNG, JPG, ..."
          }}
          setError={(m) =>
            setError("Banner", {
              message: m
            })
          }
          value={getValues("Banner")}
          label="Banner"
          required
        />

        <FlexBox direction="row" justifyContent="between">
          <FlexItem>
            <AsyncButton
              onClick={props.previousStep}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Previous
            </AsyncButton>
          </FlexItem>

          <FlexItem>
            <AsyncButton
              onClick={() => undefined}
              isDisabled={isSubmitting}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
            >
              Next
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </FormContainer>
    </Form>
  );
};
