import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";
import { ResourceTypes } from "../../../redux/features/resources/definitions";

// Redux
// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import MobileInput from "./../../../components/form/MobileInput";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import { userStore } from "../../../../src/mobx/stores/UserStore";

class UserEditAccount_Settings extends Component {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Account Information"
          subtitle="Please fill out the relevant contact information about yourself"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="editorg-Email">Email</Label>
            <Input
              id="editorg-Email"
              name="Email"
              type="email"
              placeholder="some@example.com"
              value={values.Email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Email && errors.Email}
            />
            {!!errors.Email && touched.Email && <Error>{errors.Email}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-MobileNumber">Mobile Number</Label>
            <MobileInput
                  value={values.MobileNumber}
                  name="MobileNumber"
                  onChange={handleChange}
                  onCountryChange={(e)=>{values.MobileCountryCode = e}}
                  onPhoneChange={(e)=>{values.MobileNumber = e}}
                 />
            {!!errors.MobileNumber && touched.MobileNumber && <Error>{errors.MobileNumber}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-OldPassword">Password</Label>
            <Input
              id="editorg-OldPassword"
              name="OldPassword"
              type="password"
              placeholder="Enter your current password"
              value={values.OldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.OldPassword && errors.OldPassword}
            />
            {!!errors.OldPassword && touched.OldPassword && <Error>{errors.OldPassword}</Error>}
          </Section>
          <Section>
            <Label htmlFor="editorg-NewPassword">New password (if you want to change your current password)</Label>
            <Input
              id="editorg-NewPassword"
              name="NewPassword"
              type="password"
              placeholder="Enter a new password"
              value={values.NewPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.NewPassword && errors.NewPassword}
            />
            {!!errors.NewPassword && touched.NewPassword && <Error>{errors.NewPassword}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ user }) => ({
    Photo: user.photo ? user.photo.id : "",
    FirstName: user.firstName || "",
    LastName: user.lastName || "",
    Address: !user.address ? "" : user.address.Raw || "",
    DateOfBirth: user.dateOfBirth || "",
    Gender: user.gender || "Unknown",
    Email: user.email || "",
    MobileNumber: user.mobileNumber || "",
    MobileCountryCode: user.mobileCountryCode || "",
    NewPassword: "",
    OldPassword: ""
  }),
  validationSchema: Yup.object().shape({
    Email: Yup.string().email("Email is invalid."),
    MobileNumber: Yup.string(),
    NewPassword: Yup.string().min(8, "Passwords must be at least 8 characters long."),
    OldPassword: Yup.string().required(
      "You must enter your password to edit your account settings."
    )
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updUser = {
        ID: props.user.id,
        FundProperties: JSON.parse(props.user.fundProperties),
        Role: props.user.role,
        $Metadata: { Type: ResourceTypes.User },
        Photo: values.Photo || null,
        FirstName: values.FirstName || "",
        LastName: values.LastName || "",
        DateOfBirth: values.DateOfBirth,
        Gender: values.Gender,
        Email: !values.Email ? null : values.Email.trim(),
        MobileNumber: !values.MobileNumber ? null : values.MobileNumber,
        MobileCountryCode: !values.MobileNumber ? null : values.MobileCountryCode.trim(),
        Password: values.NewPassword !== "" ? values.NewPassword : null,
        OldPassword: values.OldPassword
      };
      await props.dispatch(ResourceActions.action(updUser, "Update", {}));

      resetForm({ ...values, NewPassword: "", OldPassword: "" });
      props.toastManager.add("Saved Successfully!", { appearance: "success" });

      // update/overwrite the user details
      if (userStore.user) {
        userStore.setUser({
          ...userStore.user,
          email: updUser.Email,
          mobileCountryCode: updUser.MobileCountryCode,
          mobileNumber: updUser.MobileNumber
        });
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "UserEditAccountSettings"
});

export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(UserEditAccount_Settings);
