import classNames from "classnames";
import React from "react";
import CancelIcon from "./icons/CancelIcon";
import { CheckCircleIcon } from "./icons/CheckCircle";
import { ExclamationIcon } from "./icons/ExclamationIcon";
import { InformationIcon } from "./icons/InformationIcon";
import { Typography } from "./Typography";

interface IProps {
  children: React.ReactNode;
  type: "success" | "danger" | "error" | "information";
}

export const Banner = (props: IProps) => {
  return (
    <div className={classNames(computeBorderContainer(props.type), "border-b-4 shadow bg-white")}>
      <div className="max-w-7xl mx-auto py-5 px-5">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center space-x-2 lg:space-x-4">
            <span className={classNames("flex rounded-lg")}>{getIcon(props.type)}</span>
            <div>{renderChildren(props.type, props.children)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getIcon = (type: IProps["type"]) => {
  switch (type) {
    case "success":
    default:
      return <CheckCircleIcon.Solid variant="primary" size="large" fill />;
    case "information":
      return <InformationIcon.Solid variant="information" size="large" fill />;
    case "error":
      return <CancelIcon.Solid variant="danger" size="large" />;
    case "danger":
      return <ExclamationIcon.Solid variant="danger" size="large" fill />;
  }
};

const computeBorderContainer = (type: IProps["type"]) => {
  switch (type) {
    case "success":
    default:
      return "border-primary-900";
    case "information":
      return "border-tertiary-200";
    case "error":
    case "danger":
      return "border-red-500";
  }
};

const renderChildren = (type: IProps["type"], c: React.ReactNode) => {
  switch (type) {
    case "success":
    default:
      return (
        <Typography.Label color="primary" weight="semi" noPadding>
          {c}
        </Typography.Label>
      );
    case "information":
      return (
        <Typography.Label color="tertiary" weight="semi" noPadding>
          {c}
        </Typography.Label>
      );
    case "error":
    case "danger":
      return (
        <Typography.Label color="danger" weight="semi" noPadding>
          {c}
        </Typography.Label>
      );
  }
};
