import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { FlexBox, FlexItem } from "../FlexBox";
import { CheckIcon } from "../icons/CheckIcon";
import { Typography } from "../Typography";
import { useAsync } from "./../../hooks/useAsync";
import { sharedCountryService } from "./../../services/CountryService";
import { ErrorMessage } from "./ErrorMessage";

interface IProps {
  onChange: (v: string) => void;
  value?: string;
  disabled?: boolean;
  countryCodes?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  label: string;
  required?: boolean;
}

export const AddressSearchWidget = (props: IProps) => {
  const countriesListFetcher = useAsync(async () =>
    sharedCountryService.getSupportedCountriesList()
  );

  return (
    <PlacesAutocomplete
      value={props.value || ""}
      onChange={(v) => props.onChange(v)}
      searchOptions={{
        types: ["address"],
        componentRestrictions: {
          // Valid types string | string[] | undefined
          country: props.countryCodes
            ? props.countryCodes.toLowerCase()
            : countriesListFetcher.value
            ? (countriesListFetcher.value.map((c) => c.alpha2Code.toLowerCase()) as any)
            : [] // Types doesn't accept Array<string> but according to google docs Array<string> is valid value https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions.country
        }
      }}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="mb-4 sm:mb-6">
          <FlexBox spacing={[0, 1]} direction="col">
            <FlexItem>
              <Typography.Body color="primary" noPadding>
                {props.label} {props.required && "*"}
              </Typography.Body>
            </FlexItem>
            <FlexItem>
              <Combobox value={getInputProps().value} onChange={(v) => props.onChange(v || "")}>
                <div className="relative">
                  <div
                    className={classNames(
                      "relative w-full border border-secondary-300 text-left bg-white rounded-md shadow-sm cursor-default focus:outline-none focus:ring-2 focus:ring-opacity-75 focus:ring-white focus:ring-offset-2 sm:text-sm overflow-hidden",
                      props.error
                        ? "ring-danger border-danger focus:bg-white"
                        : "focus:ring-primary-500 focus:border-primary-900 focus:bg-white"
                    )}
                  >
                    <Combobox.Input
                      className="w-full border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 h-10 sm:h-12 md:h-14 "
                      placeholder={props.placeholder}
                      {...getInputProps()}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" />
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options
                      className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-sm max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                      as="ul"
                    >
                      {loading ? (
                        <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                          Fetching results ...
                        </div>
                      ) : null}
                      {!loading && suggestions.length === 0 && getInputProps().value !== "" ? (
                        <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : null}
                      {!loading && suggestions.length
                        ? suggestions.map((suggestion, i) => (
                            <Combobox.Option
                              className={({ active }) =>
                                `cursor-default select-none relative py-2 pl-10 pr-4 ${
                                  active ? "bg-primary-100" : "text-gray-900"
                                }`
                              }
                              {...getSuggestionItemProps(suggestion)}
                              value={suggestion.description}
                              key={i}
                              as="li"
                            >
                              {({ selected, active }) => (
                                <>
                                  <Typography.Label noPadding weight={selected ? "bold" : "normal"}>
                                    {suggestion.description}
                                  </Typography.Label>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? "text-white" : "text-primary-100"
                                      }`}
                                    >
                                      <CheckIcon />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        : null}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </FlexItem>
            {props.error && (
              <FlexItem>
                <ErrorMessage messages={[props.error]} />
              </FlexItem>
            )}
          </FlexBox>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
