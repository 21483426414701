import {
  GetAccountTableRequest,
  AccountRow,
  ListAccountTableResponse
} from "sdk/dist/accounts_table_pb";
import { metadata, rpc } from "../../../grpc";
import { accTableFilters } from "../../../stores/filters_acc_table-store";
import { accItemTypeToText, statusAccRowToText } from "../../../util/account-items";
import { toastStore } from "../../../stores/toast-store";
import { setAccountFilterValues } from "../../../util/accounts-table-filters";

export interface ExportData {
  BookingId: string;
  FriendlyId: string;
  BookingType: string;
  Date: string;
  BookingStartTime: string;
  BookingEndTime: string;
  Organisation: string;
  Location: string;
  QtyClients: string;
  ClientId: string;
  ClientFirstName: string;
  ClientLastName: string;
  ClientEmail: string;
  ClientMobile: string;
  ClientStatus: string;
  ProviderID: string;
  ProviderFirstName: string;
  ProviderLastName: string;
  BusinessName: string;
  BusinessABN: string;
  ServiceId: string;
  ServiceName: string;
  PaymentType: string;
  FundType: string;
  TotalFee: string;
  TaxType: string;
  Amount: string;
  LyfeFee: string;
  GST: string;
  PaymentStatus: string;
  InvoiceDate: string;
  InvoiceNo: string;
}
export enum AccTableType {
  LocAccounts,
  OrgAccounts
}

export const accToCsv = async (accTableType: AccTableType): Promise<ExportData[]> => {
  const req = new GetAccountTableRequest();
  const filterValues = accTableFilters.getSelectedFilters();

  req.setPageSize(-1); // -1 means get all the bookings
  req.setPageNo(0);
  req.setFilters(setAccountFilterValues(filterValues));

  let res = new ListAccountTableResponse();
  const tempRows = new Array<ExportData>();
  try {
    if (accTableType === AccTableType.LocAccounts) {
      res = await rpc.accountTable.getBookings(req, metadata());
    } else if (accTableType === AccTableType.OrgAccounts) {
      res = await rpc.accountTable.getLyfePaymentsAndBookings(req, metadata());
    }
    res.toObject().accountrowsList.map((row: AccountRow.AsObject) => {
      const newRow: ExportData = {
        BookingId: row.id,
        FriendlyId: row.friendlyId,
        Date: row.bookingDate,
        BookingType: row.bookingType,
        BookingStartTime: row.bookingStartTime,
        BookingEndTime: row.bookingEndTime,
        Organisation: row.orgName,
        Location: row.locationName,
        QtyClients: row.noClients,
        ClientId: row.clientId,
        ClientFirstName: row.client.split(" ")[0] || "",
        ClientLastName: row.client.split(" ")[1] || "",
        ClientEmail: row.clientEmail,
        ClientMobile: row.clientMobile,
        ClientStatus: row.clientStatus,
        ProviderID: row.provicerId,
        ProviderFirstName: row.provider.split(" ")[0] || "",
        ProviderLastName: row.provider.split(" ")[1] || "",
        BusinessName: row.orgName,
        BusinessABN: row.orgAbn,
        ServiceId: row.offeringId,
        ServiceName: row.offeringName,
        PaymentType: accItemTypeToText(row.accItemType),
        FundType: row.fundType,
        TaxType: row.taxType,
        TotalFee: row.fee,
        Amount: row.feeNoGst,
        LyfeFee: row.booklyfeFee,
        GST: row.gst,
        PaymentStatus: statusAccRowToText(row.status),
        InvoiceDate: row.invoiceDate,
        InvoiceNo: row.invoiceNo
      };
      tempRows.push(newRow);
    });
  } catch (err) {
    toastStore.grpcError(err);
  }

  return Promise.resolve(tempRows);
};
