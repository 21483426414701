
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "./../../../util/form";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Custom components
import Button from "./../../../components/Button";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import Label from "./../../../components/form/Label";
import Input from "./../../../components/form/Input";
import Error from "./../../../components/form/Error";
import FormHeader from "./../../../components/form/FormHeader";
import Form from "./../../../components/form/Form";
import FooterContainer from "./../../../components/form/FooterContainer";

import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

class ResetPassword extends Component {
  moveToSignUp = () => {
    this.props.setPage("signup", {});
  };

  forgotYourPassword = () => {
    this.props.setPage("forgotpass", {});
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader
          title="Reset Your Password"
          subtitle="Please reset your password below before continuing."
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="login-OldPassword">Old Password</Label>
            <Input
              id="login-OldPassword"
              placeholder="8+ characters"
              type="password"
              name="OldPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.OldPassword}
            />
            {!!errors.OldPassword && touched.OldPassword && <Error>{errors.OldPassword}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-Password">New Password</Label>
            <Input
              id="login-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Password}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 200px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Set Password
          </Button>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    OldPassword: "",
    Password: ""
  }),
  validationSchema: Yup.object().shape({
    OldPassword: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter your old password."),
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter a password.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { authUser } = props;
      const updUser = {
        ...authUser,
        OldPassword: values.OldPassword,
        Password: values.Password,
      };

      const userRes = await props.dispatch(ResourceActions.action(updUser, "Update", {}));

      const updUserRes = userRes.Payload;
      if (
        updUserRes.Email &&
        (!updUserRes.ValidatedFields || updUserRes.ValidatedFields.indexOf("Email") < 0)
      ) {
        props.setPage("verify");
      } else if (
        updUserRes.MobileNumber &&
        (!updUserRes.ValidatedFields || updUserRes.ValidatedFields.indexOf("MobileNumber") < 0)
      ) {
        props.setPage("verify");
      } else {
        props.closeModal(userRes.Payload);
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

export default compose(
  connect((state)=>({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(ResetPassword);
