import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20"
  },
  medium: {
    width: "24",
    height: "24"
  },
  large: {
    width: "28",
    height: "28"
  }
};

export default class ChevronRight extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  };

  static defaultProps = {
    size: "medium"
  };

  render() {
    const { size, ...otherProps } = this.props;
    return (
      <svg
        enableBackground="new 0 0 32 32"
        id="Слой_1"
        version="1.1"
        viewBox="0 0 32 32"
        space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        {...sizeProps[size]}
        {...otherProps}
      >
        <path
          clipRule="evenodd"
          d="M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z"
          fill="#121313"
          fillRule="evenodd"
          id="Chevron_Right"
        />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    );
  }
}
