import classNames from "classnames";
import React from "react";
import styled from "styled-components";

type Color = "primary" | "secondary" | "warning" | "danger" | "neutral" | "invert";
type Metrics =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 32
  | 40
  | 64
  | "full"
  | "screen";

interface IProps {
  children: React.ReactNode;
  color?: Color;
  rounded?: "full" | "md" | "sm";
  width?: Metrics;
  height?: Metrics;
  overflow?: { x?: "auto" | "hidden" | "scroll"; y?: "auto" | "hidden" | "scroll" };
  shadow?: boolean;
}

export const Paper = (props: IProps) => {
  return (
    <StyledContainer
      // This is hacky CSS for iPhone browser to resolve the issue of content overlapping with iPhone Safari bottom toolbar
      // Please see video on issue #1231
      isDeviceIphone={
        window.navigator.userAgent.includes("iPhone") &&
        window.navigator.vendor.includes("Apple") &&
        props.height === "screen"
      }
      className={classNames(
        getColorIntent(props.color),
        getContainerShape(props.rounded),
        props.width ? `w-${props.width}` : "w-full",
        props.height && `h-${props.height}`,
        props.overflow && props.overflow.x && `overflow-x-${props.overflow.x}`,
        props.overflow && props.overflow.y && `overflow-y-${props.overflow.y}`,
        props.shadow && "shadow"
      )}
    >
      {props.children}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ isDeviceIphone: boolean }>`
  ${({ isDeviceIphone: isIOS }) => (isIOS ? "height: -webkit-fill-available;" : "")}
`;

const getColorIntent = (color?: Color) => {
  switch (color) {
    case "primary":
      return "bg-primary-900";
    case "secondary":
      return "bg-secondary-200";
    case "danger":
      return "bg-danger";
    case "warning":
      return "bg-warning";
    case "neutral":
      return "bg-white";
    case "invert":
      return "bg-primary-100";
    default:
      return "";
  }
};

const getContainerShape = (rounded?: IProps["rounded"]) => {
  switch (rounded) {
    case "full":
      return "rounded-full";
    case "md":
      return "rounded-md";
    case "sm":
      return "rounded-sm";
    default:
      return "rounded-none";
  }
};
