import classNames from "classnames";
import React from "react";

interface IProps {
  color?: "primary" | "white";
}

export const ChevronLeftIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        "h-6 w-6",
        props.color && props.color === "white" ? "text-white" : "text-primary-900"
      )}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
    </svg>
  );
};
