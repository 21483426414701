import styled from "@emotion/styled";
import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import Input from "../../../components/form/Input";
import Label from "../../../components/form/Label";
import { Organisation } from "../../../redux/features/resources/schema";
import { toastStore } from "../../../stores/toast-store";
import { Typography } from "../../../ui-kit/Typography";
import { calculateSubscriptionCost } from "../../../util/subscriptionCost";
import LoadingButton from "./../../../components/LoadingButton";
import Select from "./../../../components/form/BaseSelect";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader, { HeaderSubtitle } from "./../../../components/form/FormHeader";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { parseFormError } from "./../../../util/form";

interface Props {
  org: Organisation;
  values: any;
  errors: any;
  touched: any;
  dirty: any;
  handleChange: (state: any) => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  dispatch: Function;
  isSubmitting: boolean;
  pageData: any;
}
class OrgEditAccount_SubType extends Component<Props> {
  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Subscription"
          subtitle="Please choose the subscription you require for your business."
        />
        <SectionsContainer>
          <Section>
            <Select
              id="editorg-sub"
              name="Subscription"
              type="select"
              placeholder="Subscription"
              autoFocus={true}
              value={values.Subscription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Subscription && errors.Subscription}
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Starter">Starter</option>
              <option value="Basic">Pro | $9/mo./provider | 2% Transaction Fee</option>
              <option value="Pro">Premium | $19/mo./provider | 2% Transaction Fee</option>
            </Select>
            {!!errors.Subscription && touched.Subscription && <Error>{errors.Subscription}</Error>}
          </Section>
          <Section>
            <Label htmlFor="provider-count">Providers cap</Label>
            <Input
              id="provider-count"
              name="ProviderCap"
              type="number"
              min={1}
              placeholder="Number of provider(s) is required"
              value={values.ProviderCap}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ProviderCap && errors.ProviderCap}
              disabled={
                !values.Subscription ||
                (values.Subscription && values.Subscription.toLowerCase() === "starter")
              }
            />
            {!!errors.ProviderCap && touched.ProviderCap && <Error>{errors.ProviderCap}</Error>}
          </Section>
          <Section>
            <Label htmlFor="total-subscription-cost">Total subscription cost (Per Month)</Label>
            <Input
              value={`$${calculateSubscriptionCost(values.Subscription, values.ProviderCap)}`}
              id="total-subscription-cost"
              disabled
            />
          </Section>
          {values.Subscription && (
            <Section>
              <Typography.H3>Your subscription allows,</Typography.H3>
              <ul>
                <ListContent>
                  <HeaderSubtitle>
                    To add unlimited admins & receptionists to your organization
                  </HeaderSubtitle>
                </ListContent>
                <ListContent>
                  <HeaderSubtitle>
                    {values.Subscription.toLowerCase() === "starter"
                      ? "No providers. Please upgrade subscription to add providers to your organization"
                      : `To add upto ${values.ProviderCap} providers to your organization`}
                  </HeaderSubtitle>
                </ListContent>
              </ul>
            </Section>
          )}
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Subscription}
          >
            Save
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ org }: any) => ({
    Subscription: org.SubscriptionType,
    ProviderCap: org.ProviderCap
  }),
  validationSchema: Yup.object().shape({
    Subscription: Yup.string().required("Subscription is required."),
    ProviderCap: Yup.number().min(1, "Value should be greater than 1")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, resetForm, props }) => {
    try {
      const updOrg = {
        ...props.org,
        SubscriptionType: values.Subscription,
        ProviderCap: values.ProviderCap
      };

      await props.dispatch(
        ResourceActions.action(updOrg, "Action", {
          Action: "ChangeSubscription",
          SubscriptionType: values.Subscription,
          ProviderCap: values.ProviderCap
        })
      );
      toastStore.success("Saved Successfully!");
      resetForm(values);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountSubType"
});

export default compose(
  connect((state) => ({})),
  formikEnhancer
)(OrgEditAccount_SubType as any);

const ListContent = styled.li`
  list-style-type: circle;
  list-style-position: inside;
`;
