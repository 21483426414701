import { Tab } from "@headlessui/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

type Color = "primary" | "blue" | "orange";

interface ITab {
  label: string;
  detail: React.ReactNode;
  color: Color;
}

interface IProps {
  tabs: ITab[];
  activeTab?: number;
}

export const Tabs = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    props.activeTab && setActiveTab(props.activeTab);
  }, [props.activeTab]);

  return (
    <div className="w-full shadow-sm border-b-2 border-secondary-300">
      <Tab.Group onChange={(i) => setActiveTab(i)} defaultIndex={activeTab}>
        <Tab.List
          className={classNames(
            "flex justify-between border-b-8",
            getBorderIntent(props.tabs[activeTab].color)
          )}
        >
          {props.tabs.map((t, key) => (
            <Tab
              key={key}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 font-semibold px-1",
                  selected
                    ? `bg-white shadow text-white hover:shadow-none focus-within:outline-none focus-within:shadow-none ${getColorIntent(
                        props.tabs[activeTab].color
                      )}`
                    : "text-black bg-gray-200"
                )
              }
            >
              {t.label.toUpperCase()}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {props.tabs.map((t, key) => (
            <Tab.Panel key={key}>{t.detail}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

const getBorderIntent = (color?: Color) => {
  switch (color) {
    case "blue":
      return "border-blue-400";
    case "orange":
      return "border-tertiary-200";
    case "primary":
      return "border-primary-900";
    default:
      return "";
  }
};

const getColorIntent = (color?: Color) => {
  switch (color) {
    case "blue":
      return "bg-blue-400";
    case "orange":
      return "bg-tertiary-200";
    case "primary":
      return "bg-primary-900";
    default:
      return "";
  }
};
