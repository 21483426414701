import React, { PureComponent } from "react";

import { FundInvoice } from "./FundInvoice";
import { TabContainer, TabHeader } from "../../elements/Tabs";
import { AccountItem } from "sdk/dist/account_items_pb";
import { ClientInvoice } from "../ClientActionDialog/Invoices";

interface Props {
  invoices: Array<AccountItem.AsObject>;
}
export default class Failed extends PureComponent<Props> {
  render() {
    const { invoices } = this.props;

    const compPay = invoices.filter(
      (v) =>
        v.status === AccountItem.Status.ACC_ITEM_ERROR &&
        v.type == AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT
    );
    const compFunds = invoices.filter(
      (v) =>
        v.status === AccountItem.Status.ACC_ITEM_ERROR && v.type == AccountItem.Type.ACC_ITEM_FUND
    );

    return (
      <React.Fragment>
        {compPay.length > 0 && (
          <TabContainer>
            <TabHeader>Payments</TabHeader>
            {compPay.map((v: AccountItem.AsObject) => (
              <ClientInvoice key={v.id} invoice={v} {...this.props} />
            ))}
          </TabContainer>
        )}

        {compFunds.length > 0 && (
          <TabContainer>
            <TabHeader>Fund Claims</TabHeader>
            {compFunds.map((v: AccountItem.AsObject) => (
              <FundInvoice key={v.id} invoice={v} {...this.props} />
            ))}
          </TabContainer>
        )}
      </React.Fragment>
    );
  }
}
