import classNames from "classnames";
import React from "react";

interface IProps {
  size?: "small" | "medium" | "large";
}

export const AppIcon = (props: IProps) => {
  return (
    <svg
      className={classNames(computeIconSize(props.size))}
      viewBox="0 0 57 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16978 24.1257C5.16978 24.1257 23.3502 34.7237 24.0826 34.0775C24.298 33.9052 23.5225 31.9234 22.2731 29.1662C20.593 26.969 18.2235 24.5565 14.9493 22.6178C8.22857 18.6543 0 21.0238 0 21.0238C0 21.0238 2.58489 29.8124 8.57322 33.5174C15.5524 37.8256 24.4272 36.9209 24.4272 36.9209C24.4272 36.9209 24.7719 36.9639 25.1596 36.8778L5.16978 24.1257Z"
        fill="#7BD1B5"
        fillOpacity="0.4"
      />
      <path
        d="M41.4014 22.6178C37.8256 24.7719 36.3609 27.3998 34.6807 29.7262C33.6467 32.1388 32.742 33.2159 32.8282 33.3882C33.3021 34.2067 50.7932 23.9964 50.7932 23.9964L31.3634 36.8778C31.665 36.9209 31.9235 36.9209 31.9235 36.9209C31.9235 36.9209 40.7983 37.8256 47.7775 33.5174C53.7658 29.8555 56.3507 21.0238 56.3507 21.0238C56.3507 21.0238 48.1221 18.6543 41.4014 22.6178Z"
        fill="#7BD1B5"
        fillOpacity="0.4"
      />
      <path
        d="M17.1895 30.8894C20.7653 32.8281 23.781 34.336 24.0826 34.0775C24.815 33.4312 12.8814 9.65025 12.8814 9.65025C12.8814 9.65025 26.6244 34.4652 27.7876 34.5945C28.0461 32.3111 28.9939 23.8672 24.4703 15.0354C19.9468 6.28989 9.69335 3.40343 9.69335 3.40343C9.69335 3.40343 6.59148 14.4323 10.4688 22.2731C12.1921 25.7627 14.6908 28.6492 17.1895 30.8894Z"
        fill="#7BD1B5"
        fillOpacity="0.7"
      />
      <path
        d="M43.3831 9.521C43.3831 9.521 31.7942 33.2158 32.2681 34.0344C32.4404 34.379 35.6284 32.785 39.2903 30.7602C41.7029 28.563 44.2016 25.7197 45.8818 22.2731C49.7592 14.4323 46.6573 3.40343 46.6573 3.40343C46.6573 3.40343 36.3608 6.28989 31.8803 14.9924C26.9259 24.6426 28.52 33.8621 28.6061 35.0253C30.0709 34.379 43.3831 9.521 43.3831 9.521Z"
        fill="#7BD1B5"
        fillOpacity="0.7"
      />
      <path
        d="M28.1753 0C28.1753 0 20.8945 7.84084 20.7652 15.9832C20.5929 25.5043 26.7967 33.8621 26.7967 33.8621C26.7967 33.8621 27.3998 35.0684 28.2614 35.0684C28.9938 35.0684 29.4677 34.1637 29.64 33.9482C30.157 33.1297 35.4991 26.1505 35.4991 15.8971C35.456 6.80688 28.1753 0 28.1753 0Z"
        fill="#58C5A3"
      />
      <path d="M28.2183 7.97009L27.3567 35.1115H29.2954L28.2183 7.97009Z" fill="white" />
    </svg>
  );
};

const PrimaryInverseAppIcon = (props: IProps) => {
  return (
    <svg
      className={classNames(computeIconSize(props.size))}
      viewBox="0 0 57 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16978 24.1257C5.16978 24.1257 23.3502 34.7237 24.0826 34.0775C24.298 33.9052 23.5225 31.9234 22.2731 29.1662C20.593 26.969 18.2235 24.5565 14.9493 22.6178C8.22857 18.6543 0 21.0238 0 21.0238C0 21.0238 2.58489 29.8124 8.57322 33.5174C15.5524 37.8256 24.4272 36.9209 24.4272 36.9209C24.4272 36.9209 24.7719 36.9639 25.1596 36.8778L5.16978 24.1257Z"
        fill="#7BD1B5"
        fillOpacity="0.4"
      />
      <path
        d="M41.4014 22.6178C37.8256 24.7719 36.3609 27.3998 34.6807 29.7262C33.6467 32.1388 32.742 33.2159 32.8282 33.3882C33.3021 34.2067 50.7932 23.9964 50.7932 23.9964L31.3634 36.8778C31.665 36.9209 31.9235 36.9209 31.9235 36.9209C31.9235 36.9209 40.7983 37.8256 47.7775 33.5174C53.7658 29.8555 56.3507 21.0238 56.3507 21.0238C56.3507 21.0238 48.1221 18.6543 41.4014 22.6178Z"
        fill="#7BD1B5"
        fillOpacity="0.4"
      />
      <path
        d="M17.1895 30.8894C20.7653 32.8281 23.781 34.336 24.0826 34.0775C24.815 33.4312 12.8814 9.65025 12.8814 9.65025C12.8814 9.65025 26.6244 34.4652 27.7876 34.5945C28.0461 32.3111 28.9939 23.8672 24.4703 15.0354C19.9468 6.28989 9.69335 3.40343 9.69335 3.40343C9.69335 3.40343 6.59148 14.4323 10.4688 22.2731C12.1921 25.7627 14.6908 28.6492 17.1895 30.8894Z"
        fill="#7BD1B5"
        fillOpacity="0.7"
      />
      <path
        d="M43.3831 9.521C43.3831 9.521 31.7942 33.2158 32.2681 34.0344C32.4404 34.379 35.6284 32.785 39.2903 30.7602C41.7029 28.563 44.2016 25.7197 45.8818 22.2731C49.7592 14.4323 46.6573 3.40343 46.6573 3.40343C46.6573 3.40343 36.3608 6.28989 31.8803 14.9924C26.9259 24.6426 28.52 33.8621 28.6061 35.0253C30.0709 34.379 43.3831 9.521 43.3831 9.521Z"
        fill="#7BD1B5"
        fillOpacity="0.7"
      />
      <path
        d="M28.1753 0C28.1753 0 20.8945 7.84084 20.7652 15.9832C20.5929 25.5043 26.7967 33.8621 26.7967 33.8621C26.7967 33.8621 27.3998 35.0684 28.2614 35.0684C28.9938 35.0684 29.4677 34.1637 29.64 33.9482C30.157 33.1297 35.4991 26.1505 35.4991 15.8971C35.456 6.80688 28.1753 0 28.1753 0Z"
        fill="#58C5A3"
      />
      <path d="M28.2183 7.97009L27.3567 35.1115H29.2954L28.2183 7.97009Z" fill="white" />
    </svg>
  );
};

const OrangeAppIcon = (props: IProps) => {
  return (
    <svg
      className={classNames(computeIconSize(props.size))}
      viewBox="0 0 57 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16978 24.1257C5.16978 24.1257 23.3502 34.7237 24.0826 34.0775C24.298 33.9052 23.5225 31.9234 22.2731 29.1662C20.593 26.969 18.2235 24.5565 14.9493 22.6178C8.22857 18.6543 0 21.0238 0 21.0238C0 21.0238 2.58489 29.8124 8.57322 33.5174C15.5524 37.8256 24.4272 36.9209 24.4272 36.9209C24.4272 36.9209 24.7719 36.9639 25.1596 36.8778L5.16978 24.1257Z"
        fill="#FFA63E"
        fillOpacity="0.4"
      />
      <path
        d="M41.4014 22.6178C37.8256 24.7719 36.3609 27.3998 34.6807 29.7262C33.6467 32.1388 32.742 33.2159 32.8282 33.3882C33.3021 34.2067 50.7932 23.9964 50.7932 23.9964L31.3634 36.8778C31.665 36.9209 31.9235 36.9209 31.9235 36.9209C31.9235 36.9209 40.7983 37.8256 47.7775 33.5174C53.7658 29.8555 56.3507 21.0238 56.3507 21.0238C56.3507 21.0238 48.1221 18.6543 41.4014 22.6178Z"
        fill="#FFA63E"
        fillOpacity="0.4"
      />
      <path
        d="M17.1895 30.8895C20.7653 32.8281 23.781 34.336 24.0826 34.0775C24.815 33.4313 12.8814 9.65028 12.8814 9.65028C12.8814 9.65028 26.6244 34.4652 27.7876 34.5945C28.0461 32.3112 28.9939 23.8672 24.4703 15.0355C19.9468 6.28992 9.69335 3.40346 9.69335 3.40346C9.69335 3.40346 6.59148 14.4323 10.4688 22.2732C12.1921 25.7628 14.6908 28.6492 17.1895 30.8895Z"
        fill="#FFA63E"
        fillOpacity="0.7"
      />
      <path
        d="M43.3831 9.52103C43.3831 9.52103 31.7942 33.2159 32.2681 34.0344C32.4404 34.3791 35.6284 32.7851 39.2903 30.7602C41.7029 28.5631 44.2016 25.7197 45.8818 22.2732C49.7592 14.4323 46.6573 3.40346 46.6573 3.40346C46.6573 3.40346 36.3608 6.28992 31.8803 14.9924C26.9259 24.6426 28.52 33.8621 28.6061 35.0253C30.0709 34.3791 43.3831 9.52103 43.3831 9.52103Z"
        fill="#FFA63E"
        fillOpacity="0.7"
      />
      <path
        d="M28.1753 0C28.1753 0 20.8945 7.84084 20.7652 15.9832C20.5929 25.5043 26.7967 33.8621 26.7967 33.8621C26.7967 33.8621 27.3998 35.0684 28.2614 35.0684C28.9938 35.0684 29.4677 34.1637 29.64 33.9482C30.157 33.1297 35.4991 26.1505 35.4991 15.8971C35.456 6.80688 28.1753 0 28.1753 0Z"
        fill="#FFA63E"
      />
      <path d="M28.2184 7.97008L27.3568 35.1114H29.2955L28.2184 7.97008Z" fill="white" />
    </svg>
  );
};

const BlueAppIcon = (props: IProps) => {
  return (
    <svg
      className={classNames(computeIconSize(props.size))}
      viewBox="0 0 57 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16978 24.1257C5.16978 24.1257 23.3502 34.7237 24.0826 34.0775C24.298 33.9052 23.5225 31.9234 22.2731 29.1662C20.593 26.969 18.2235 24.5565 14.9493 22.6178C8.22857 18.6543 0 21.0238 0 21.0238C0 21.0238 2.58489 29.8124 8.57322 33.5174C15.5524 37.8256 24.4272 36.9209 24.4272 36.9209C24.4272 36.9209 24.7719 36.9639 25.1596 36.8778L5.16978 24.1257Z"
        fill="#73D7F7"
        fillOpacity="0.4"
      />
      <path
        d="M41.4014 22.6178C37.8256 24.7719 36.3609 27.3998 34.6807 29.7262C33.6467 32.1388 32.742 33.2159 32.8282 33.3882C33.3021 34.2067 50.7932 23.9964 50.7932 23.9964L31.3634 36.8778C31.665 36.9209 31.9235 36.9209 31.9235 36.9209C31.9235 36.9209 40.7983 37.8256 47.7775 33.5174C53.7658 29.8555 56.3507 21.0238 56.3507 21.0238C56.3507 21.0238 48.1221 18.6543 41.4014 22.6178Z"
        fill="#73D7F7"
        fillOpacity="0.4"
      />
      <path
        d="M17.1895 30.8895C20.7653 32.8281 23.781 34.336 24.0826 34.0775C24.815 33.4313 12.8814 9.65026 12.8814 9.65026C12.8814 9.65026 26.6244 34.4652 27.7876 34.5945C28.0461 32.3111 28.9939 23.8672 24.4703 15.0355C19.9468 6.28991 9.69335 3.40344 9.69335 3.40344C9.69335 3.40344 6.59148 14.4323 10.4688 22.2731C12.1921 25.7628 14.6908 28.6492 17.1895 30.8895Z"
        fill="#73D7F7"
        fillOpacity="0.7"
      />
      <path
        d="M43.3831 9.52102C43.3831 9.52102 31.7942 33.2159 32.2681 34.0344C32.4404 34.3791 35.6284 32.785 39.2903 30.7602C41.7029 28.5631 44.2016 25.7197 45.8818 22.2731C49.7592 14.4323 46.6573 3.40344 46.6573 3.40344C46.6573 3.40344 36.3608 6.2899 31.8803 14.9924C26.9259 24.6426 28.52 33.8621 28.6061 35.0253C30.0709 34.3791 43.3831 9.52102 43.3831 9.52102Z"
        fill="#73D7F7"
        fillOpacity="0.7"
      />
      <path
        d="M28.1753 0C28.1753 0 20.8945 7.84084 20.7652 15.9832C20.5929 25.5043 26.7967 33.8621 26.7967 33.8621C26.7967 33.8621 27.3998 35.0684 28.2614 35.0684C28.9938 35.0684 29.4677 34.1636 29.64 33.9482C30.157 33.1297 35.4991 26.1505 35.4991 15.8971C35.456 6.80688 28.1753 0 28.1753 0Z"
        fill="#73D7F7"
      />
      <path d="M28.2184 7.97011L27.3568 35.1115H29.2955L28.2184 7.97011Z" fill="white" />
    </svg>
  );
};

AppIcon.PrimaryInverse = PrimaryInverseAppIcon;
AppIcon.Orange = OrangeAppIcon;
AppIcon.Blue = BlueAppIcon;

const computeIconSize = (size?: IProps["size"]) => {
  switch (size) {
    case "small":
      return "h-8 w-8";
    default:
    case "medium":
      return "h-16 w-16";
    case "large":
      return "h-20 w-20";
  }
};
