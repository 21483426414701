// source: products.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
goog.exportSymbol('proto.proto.Order', null, global);
goog.exportSymbol('proto.proto.Product', null, global);
goog.exportSymbol('proto.proto.PurchaseRequest', null, global);
goog.exportSymbol('proto.proto.PurchaseResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Product.repeatedFields_, null);
};
goog.inherits(proto.proto.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Product.displayName = 'proto.proto.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Order.displayName = 'proto.proto.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PurchaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PurchaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PurchaseRequest.displayName = 'proto.proto.PurchaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PurchaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PurchaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PurchaseResponse.displayName = 'proto.proto.PurchaseResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Product.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    fee: (f = msg.getFee()) && money_pb.Charge.toObject(includeInstance, f),
    taxRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    image: (f = msg.getImage()) && media_pb.Media.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Product}
 */
proto.proto.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Product;
  return proto.proto.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Product}
 */
proto.proto.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 6:
      var value = new money_pb.Charge;
      reader.readMessage(value,money_pb.Charge.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 8:
      var value = new media_pb.Media;
      reader.readMessage(value,media_pb.Media.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      money_pb.Charge.serializeBinaryToWriter
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      media_pb.Media.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.proto.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.proto.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location_id = 4;
 * @return {string}
 */
proto.proto.Product.prototype.getLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string categories = 5;
 * @return {!Array<string>}
 */
proto.proto.Product.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional Charge fee = 6;
 * @return {?proto.proto.Charge}
 */
proto.proto.Product.prototype.getFee = function() {
  return /** @type{?proto.proto.Charge} */ (
    jspb.Message.getWrapperField(this, money_pb.Charge, 6));
};


/**
 * @param {?proto.proto.Charge|undefined} value
 * @return {!proto.proto.Product} returns this
*/
proto.proto.Product.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Product.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string tax_rate = 7;
 * @return {string}
 */
proto.proto.Product.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Media image = 8;
 * @return {?proto.proto.Media}
 */
proto.proto.Product.prototype.getImage = function() {
  return /** @type{?proto.proto.Media} */ (
    jspb.Message.getWrapperField(this, media_pb.Media, 8));
};


/**
 * @param {?proto.proto.Media|undefined} value
 * @return {!proto.proto.Product} returns this
*/
proto.proto.Product.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Product} returns this
 */
proto.proto.Product.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Product.prototype.hasImage = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    friendlyOrderNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    clientName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    clientAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paymentMethod: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ccLast4: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subtotal: jspb.Message.getFieldWithDefault(msg, 10, ""),
    saleTax: jspb.Message.getFieldWithDefault(msg, 11, ""),
    taxRate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    total: jspb.Message.getFieldWithDefault(msg, 13, ""),
    stripeFee: jspb.Message.getFieldWithDefault(msg, 14, ""),
    orgDfltCurrency: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Order}
 */
proto.proto.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Order;
  return proto.proto.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Order}
 */
proto.proto.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyOrderNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethod(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCcLast4(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtotal(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaleTax(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotal(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeFee(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgDfltCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFriendlyOrderNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getClientName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClientAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCcLast4();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubtotal();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSaleTax();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTotal();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStripeFee();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOrgDfltCurrency();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.proto.Order.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string friendly_order_no = 2;
 * @return {string}
 */
proto.proto.Order.prototype.getFriendlyOrderNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setFriendlyOrderNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.proto.Order.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 quantity = 4;
 * @return {number}
 */
proto.proto.Order.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string client_name = 5;
 * @return {string}
 */
proto.proto.Order.prototype.getClientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setClientName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_email = 6;
 * @return {string}
 */
proto.proto.Order.prototype.getClientEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setClientEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string client_address = 7;
 * @return {string}
 */
proto.proto.Order.prototype.getClientAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setClientAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string payment_method = 8;
 * @return {string}
 */
proto.proto.Order.prototype.getPaymentMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cc_last_4 = 9;
 * @return {string}
 */
proto.proto.Order.prototype.getCcLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setCcLast4 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string subtotal = 10;
 * @return {string}
 */
proto.proto.Order.prototype.getSubtotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setSubtotal = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sale_tax = 11;
 * @return {string}
 */
proto.proto.Order.prototype.getSaleTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setSaleTax = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string tax_rate = 12;
 * @return {string}
 */
proto.proto.Order.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string total = 13;
 * @return {string}
 */
proto.proto.Order.prototype.getTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string stripe_fee = 14;
 * @return {string}
 */
proto.proto.Order.prototype.getStripeFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setStripeFee = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string org_dflt_currency = 15;
 * @return {string}
 */
proto.proto.Order.prototype.getOrgDfltCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Order} returns this
 */
proto.proto.Order.prototype.setOrgDfltCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PurchaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PurchaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PurchaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PurchaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PurchaseRequest}
 */
proto.proto.PurchaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PurchaseRequest;
  return proto.proto.PurchaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PurchaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PurchaseRequest}
 */
proto.proto.PurchaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PurchaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PurchaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PurchaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PurchaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.proto.PurchaseRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PurchaseRequest} returns this
 */
proto.proto.PurchaseRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.proto.PurchaseRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PurchaseRequest} returns this
 */
proto.proto.PurchaseRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PurchaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PurchaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PurchaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PurchaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.proto.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PurchaseResponse}
 */
proto.proto.PurchaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PurchaseResponse;
  return proto.proto.PurchaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PurchaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PurchaseResponse}
 */
proto.proto.PurchaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Order;
      reader.readMessage(value,proto.proto.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PurchaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PurchaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PurchaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PurchaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.proto.Order}
 */
proto.proto.PurchaseResponse.prototype.getOrder = function() {
  return /** @type{?proto.proto.Order} */ (
    jspb.Message.getWrapperField(this, proto.proto.Order, 1));
};


/**
 * @param {?proto.proto.Order|undefined} value
 * @return {!proto.proto.PurchaseResponse} returns this
*/
proto.proto.PurchaseResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PurchaseResponse} returns this
 */
proto.proto.PurchaseResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PurchaseResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.proto);
