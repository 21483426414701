import { observer } from "mobx-react";
import React from "react";
import DropdownSelect from "../../DropdownSelect";
import {
  AccordianContainer,
  AccordianEdit,
  ButtonDetails,
  ButtonHead,
  NoSpacingIconButton,
  OptionsContainer,
  Separator
} from "../../elements/AccordianElements";
import Options from "../../icons/Options";
import { LightTooltip } from "../../elements/MaterialTooltip";
import { CreditCard } from "sdk/dist/credit_cards_pb";

interface Props {
  card: CreditCard.AsObject;
  selected?: boolean;
  onSetPrimary: () => void;
  onRemove: () => void;
  onClick?: () => void;
  isUserCC?: boolean;
}
@observer
export class CardRow extends React.Component<Props> {
  ccHelpText = "This CC is managed by the user. Please ask him/her to edit it.";
  private editOptions = [
    {
      label: "Set As Primary",
      onClick: () => this.props.onSetPrimary()
    },
    {
      label: "Remove",
      onClick: () => this.props.onRemove()
    }
  ];

  render() {
    const { card, isUserCC } = this.props;
    return (
      <AccordianContainer
        onClick={this.props.onClick}
        maySelect={!!this.props.onClick}
        selected={this.props.selected}
      >
        <AccordianEdit>
          <ButtonHead>
            {window.innerWidth < 700 ? `…` : `•••• •••• •••• `}
            {`${card.last4}`}
          </ButtonHead>
          <Separator />
          <ButtonDetails>{`${card.expirationMonth}/${card.expirationYear % 100}`}</ButtonDetails>
          <Separator />
          <ButtonDetails style={{ width: "80px" }}>
            {card.isPrimary ? (isUserCC ? "Primary (user)" : "Primary") : ""}
          </ButtonDetails>
          <OptionsContainer>
            {isUserCC ? (
              <LightTooltip
                disableFocusListener
                disableTouchListener
                title={this.ccHelpText}
                placement="left"
                style={{ width: "20px" }}
              >
                <p>?</p>
              </LightTooltip>
            ) : (
              <DropdownSelect options={this.editOptions} style={{ width: "20px" }}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            )}
          </OptionsContainer>
        </AccordianEdit>
      </AccordianContainer>
    );
  }
}
