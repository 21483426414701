import classNames from "classnames";
import React from "react";

interface IProps {
  color?: "primary" | "danger";
}

export const ChevronDownIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        "h-6 w-6",
        props.color && props.color === "danger" ? "text-danger" : "text-primary-900"
      )}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
    </svg>
  );
};
