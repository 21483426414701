import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { FloatingBanner } from "../../ui-kit/FloatingBanner";
import CancelIcon from "../../ui-kit/icons/CancelIcon";
import { Typography } from "../../ui-kit/Typography";

export const CookieBanner = () => {
  const [value, setValue] = useSessionStorage<boolean>("cookie-consent", false);
  const location = useLocation();
  const isIframe = location.pathname.includes("/iframe/");
  const appName =
    isIframe && location.pathname.replace("/iframe/", "").split("/").length > 0
      ? location.pathname.replace("/iframe/", "").split("/")[0]
      : "";

  if (!value)
    return (
      <FloatingBanner position="bottom">
        <FlexBox alignItems="center" justifyContent="between" spacing={[0, 2]}>
          <FlexItem>
            <Typography.Body>
              The site uses cookies to provide you with a greater user experience. By using Lyfe,
              you accept our{" "}
              <LinkText
                to={
                  isIframe
                    ? `/iframe/${appName.toLowerCase()}/privacy-policy${location.hash}`
                    : "/privacy-policy"
                }
              >
                use of cookies policy
              </LinkText>
              .
            </Typography.Body>
          </FlexItem>

          <FlexItem>
            <button onClick={() => setValue(true)}>
              <CancelIcon />
            </button>
          </FlexItem>
        </FlexBox>
      </FloatingBanner>
    );

  return null;
};

const LinkText = styled(Link)`
  text-decoration: underline;
`;
