
import { PureComponent } from "react";
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

import ButtonBase from "./ButtonBase";
import { colors, hexToRgba } from "./../util/consts";

const base = css`
  padding: 6px 16px;
  font-size: 13.82px;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 18px;
  &:disabled {
    color: ${colors.blackText.disabled};
  }
`;

const text = (color) => css`
  transition: background-color 0.2s ease;
  color: ${color === "secondary" ? colors.secondary.main : colors.primary.main};
  background-color: transparent;

  &:hover:enabled {
    background-color: ${colors.primary[50]};
  }

  &:focus {
    background-color: ${colors.primary[50]};
  }

  &:disabled {
    background-color: ${hexToRgba(colors.primary.main, 0.12)};
  }
`;

const outlined = (color) => css`
  transition: border-color 0.2s ease;
  color: ${color === "secondary" ? colors.secondary.main : colors.tertiary.main};
  background-color: transparent;
  border: 1px solid ${color === "secondary" ? colors.secondary.main : colors.tertiary.main};

  &:hover:enabled {
    border-color: ${hexToRgba(colors.tertiary.main, 0.12)};
  }

  &:focus {
    border-color: ${hexToRgba(colors.tertiary.main, 0.12)};
  }

  &:disabled {
    background-color: transparent;
    border: solid 1px ${hexToRgba(colors.tertiary.main, 0.12)};
  }
`;

const contained = (color) => css`
  transition: opacity 0.2s ease;
  color: ${colors.whiteText.highEmphasis};
  background-color: ${color === "secondary" ? colors.secondary.main : colors.tertiary.main};

  &:hover:enabled {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${hexToRgba(colors.primary.main, 0.12)};
  }
`;

const variants = {
  text,
  outlined,
  contained
};

class ButtonServices extends PureComponent {
  static propTypes = {
    color: PropTypes.oneOf(["primary", "secondary"]),
    variant: PropTypes.oneOf(["text", "contained", "outlined"])
  };

  static defaultProps = {
    color: "primary",
    variant: "text"
  };

  getStyles = (color, variant) => css`
    ${base};
    ${variants[variant](color)}
  `;

  render() {
    const { color, variant, ...otherProps } = this.props;
    return <ButtonBase css={this.getStyles(color, variant)} {...otherProps} />;
  }
}

export default ButtonServices;
