import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withToastManager } from "react-toast-notifications";

// Redux
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";

// Utils
import { toPriceString } from "./../../../util";
import { parseFormError } from "./../../../util/form";

// Components
import Form from "./../../../components/form/Form";
import Label from "./../../../components/form/Label";
import Info from "./../../../components/form/Info";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import Section from "./../../../components/form/Section";
import FormHeader from "./../../../components/form/FormHeader";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import LoadingButton from "./../../../components/LoadingButton";
import Select from "./../../../components/form/BaseSelect";

import { Table, Row, ColH, ColS } from "./SubDets";

class OrgEditAccount_FundPool extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }

  renderActive() {
    const { errors, handleSubmit, isSubmitting, pageData, org } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Fund Pool"
          subtitle={
            "Disable the fund pool by clicking below. " +
            "If you do any future Medicare bulk billing, HICAPS/Medipass, and/or TAC servies "+
            "will not be processed."
          }
        />
        <SectionsContainer>
          <Section>
            <Table>
              <tbody>
                <Row>
                  <ColH>Current Funds in Pool:</ColH>
                  <ColS>${toPriceString(org.CurrentFundPoolAmount, true)}</ColS>
                </Row>
              </tbody>
            </Table>
          </Section>
        </SectionsContainer>

        <FooterContainer>
          <LoadingButton
            style={{ width: 250 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={errors.Subscription && !pageData.Subscription}
          >
            Disable Fund Pool
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }

  renderInactive() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
                e.preventDefault();
                return false;
              }
            : handleSubmit
        }
      >
        <FormHeader
          title="Fund Pool"
          subtitle={
            "If you wish to enable Medicare bulk billing, HICAPS/Medipass, and/or TAC claiming " +
            "you must enable fund pooling below."
          }
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-fundpool">Initial Topup Fund Pool Amount</Label>
            <br />
            <Info>
              This specifies the initial amount of the fund pool from which Medicare bulk billing,
              HICAPS/Medipass, and/or TAC claiming fees are retrieved from. If the fund drops below
              30% of the topup amount then your organisation's credit card is charged the amount
              required to bring the balance back up. Future topup amounts will be automatically
              adjusted based on your daily usage. A good initial value would be roughly equal to
              your estimated daily total claims across your organistaion. For example, if you claim
              $1800/day across your organisation then select $2000 from the dropdown below.
            </Info>
            <Select
              id="createorg-fundpool"
              name="InitialFundPoolAmount"
              type="select"
              placeholder="InitialFundPoolAmount"
              autoFocus={true}
              value={values.InitialFundPoolAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.InitialFundPoolAmount && errors.InitialFundPoolAmount}
            >
              <option value="25000">$250</option>
              <option value="50000">$500</option>
              <option value="100000">$1000</option>
              <option value="200000">$2000</option>
              <option value="400000">$4000</option>
              <option value="800000">$8000</option>
              <option value="1600000">$16000</option>
            </Select>
            {!!errors.InitialFundPoolAmount && touched.InitialFundPoolAmount && (
              <Error>{errors.InitialFundPoolAmount}</Error>
            )}
          </Section>
        </SectionsContainer>

        <FooterContainer>
          <LoadingButton
            style={{ width: 250 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={errors.Subscription && !pageData.Subscription}
          >
            Enable Fund Pool
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }

  render() {
    const { org } = this.props;

    if (org.FundPoolActive) {
      return this.renderActive();
    } else {
      return this.renderInactive();
    }
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData, org }) => ({
    InitialFundPoolAmount: "25000"
  }),
  validationSchema: ({ org }) => {
    if (org.FundPoolActive) {
      return Yup.object();
    } else {
      return Yup.object().shape({
        InitialFundPoolAmount: Yup.string().required("Initial fund amount is required.")
      });
    }
  },
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const { org, toastManager } = props;
      if (org.FundPoolActive) {
        await props.dispatch(
          ResourceActions.action(org, "Action", {
            Action: "DeactivateFundPool"
          })
        );

        toastManager.add("Fund Pool Disabled!", { appearance: "success" });
      } else {
        await props.dispatch(
          ResourceActions.action(org, "Action", {
            Action: "ActivateFundPool",
            PoolTopupAmount: parseInt(values.InitialFundPoolAmount)
          })
        );

        toastManager.add("Fund Pool Enabled!", { appearance: "success" });
      }
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgEditAccountFundPool"
});

export default compose(
  connect(),
  withToastManager,
  formikEnhancer
)(OrgEditAccount_FundPool);
