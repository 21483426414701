import React, { PureComponent } from "react";
import PropTypes from "prop-types";

const sizeProps = {
  small: {
    width: "20",
    height: "20"
  },
  medium: {
    width: "24",
    height: "24"
  },
  large: {
    width: "28",
    height: "28"
  }
};

export default class Settings extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"])
  };

  static defaultProps = {
    size: "medium"
  };

  render() {
    const { isAppointmentPage, viewType, size, ...otherProps } = this.props;
    return (
      <>
      {isAppointmentPage === false ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          {...sizeProps[size]}
          {...otherProps}
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
        </svg>
      ) : (
        <>
        {/* calendar appointment page with the table icon */}
        {viewType === "calendar" &&
          (
            <svg width="18px" height="18px" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <g id="Apps">
                  <path d="M6,0H2C0.895,0,0,0.896,0,2v4c0,1.105,0.895,2,2,2h4c1.105,0,2-0.895,2-2V2C8,0.896,7.105,0,6,0z M6,6H2V2   h4V6z" fill="#121313"/>
                  <path d="M18,0h-4c-1.105,0-2,0.896-2,2v4c0,1.105,0.895,2,2,2h4c1.104,0,2-0.895,2-2V2C20,0.896,19.104,0,18,0z    M18,6h-4V2h4V6z" fill="#121313"/>
                  <path d="M30,0h-4c-1.105,0-2,0.896-2,2v4c0,1.105,0.895,2,2,2h4c1.104,0,2-0.895,2-2V2C32,0.896,31.104,0,30,0z    M30,6h-4V2h4V6z" fill="#121313"/>
                  <path clipRule="evenodd" d="M6,12H2c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.105,0,2-0.895,2-2v-4C8,12.896,7.105,12,6,12z M6,18H2v-4h4V18z" fill="#121313" fillRule="evenodd"/>
                  <path clipRule="evenodd" d="M18,12h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C20,12.896,19.104,12,18,12z M18,18h-4v-4h4V18z" fill="#121313" fillRule="evenodd"/>
                  <path clipRule="evenodd" d="M30,12h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C32,12.896,31.104,12,30,12z M30,18h-4v-4h4V18z" fill="#121313" fillRule="evenodd"/>
                  <path clipRule="evenodd" d="M6,24H2c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.105,0,2-0.895,2-2v-4C8,24.896,7.105,24,6,24z M6,30H2v-4h4V30z" fill="#121313" fillRule="evenodd"/>
                  <path clipRule="evenodd" d="M18,24h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C20,24.896,19.104,24,18,24z M18,30h-4v-4h4V30z" fill="#121313" fillRule="evenodd"/>
                  <path clipRule="evenodd" d="M30,24h-4c-1.105,0-2,0.895-2,2v4c0,1.105,0.895,2,2,2h4   c1.104,0,2-0.895,2-2v-4C32,24.896,31.104,24,30,24z M30,30h-4v-4h4V30z" fill="#121313" fillRule="evenodd"/>
              </g>
          </svg>
          )}

          {/* table appointment page with its the calendar icon */}
          {viewType === "table" &&
            (
              <svg width="20px" height="20px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="39.-Calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <rect id="Layer-1" stroke="#222F3E" strokeWidth="4" x="2" y="2" width="96" height="96" rx="3"></rect>
                    <rect id="Layer-2" stroke="#222F3E" strokeWidth="4" x="11.6" y="30.8" width="19.2" height="19.2"></rect>
                    <rect id="Layer-3" stroke="#222F3E" strokeWidth="4" x="30.8" y="30.8" width="19.2" height="19.2"></rect>
                    <rect id="Layer-4" stroke="#222F3E" strokeWidth="4" x="50" y="30.8" width="19.2" height="19.2"></rect>
                    <rect id="Layer-5" stroke="#222F3E" strokeWidth="4" x="69.2" y="30.8" width="19.2" height="19.2"></rect>
                    <rect id="Layer-6" stroke="#222F3E" strokeWidth="4" x="69.2" y="50" width="19.2" height="19.2"></rect>
                    <rect id="Layer-7" stroke="#222F3E" strokeWidth="4" x="50" y="50" width="19.2" height="19.2"></rect>
                    <rect id="Layer-8" stroke="#222F3E" strokeWidth="4" x="30.8" y="50" width="19.2" height="19.2"></rect>
                    <rect id="Layer-9" stroke="#222F3E" strokeWidth="4" x="11.6" y="50" width="19.2" height="19.2"></rect>
                    <rect id="Layer-10" stroke="#222F3E" strokeWidth="4" x="11.6" y="69.2" width="19.2" height="19.2"></rect>
                    <rect id="Layer-11" stroke="#222F3E" strokeWidth="4" x="30.8" y="69.2" width="19.2" height="19.2"></rect>
                    <rect id="Layer-12" stroke="#222F3E" strokeWidth="4" x="50" y="69.2" width="19.2" height="19.2"></rect>
                    <path d="M21.2,2 L30.8,2 L30.8,6.8 C30.8,9.4509668 28.6455726,11.6 26,11.6 L26,11.6 C23.3490332,11.6 21.2,9.44557261 21.2,6.8 L21.2,2 Z" id="Layer-13" stroke="#222F3E" strokeWidth="4"></path>
                    <path d="M69.2,2 L78.8,2 L78.8,6.8 C78.8,9.4509668 76.6455726,11.6 74,11.6 L74,11.6 C71.3490332,11.6 69.2,9.44557261 69.2,6.8 L69.2,2 Z" id="Layer-14" stroke="#222F3E" strokeWidth="4"></path>
                </g>
              </svg>
            )
          }
          </>
      )}
      </>
    );
  }
}
