import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  Filters,
  Column
} from "react-table";
import * as styled from "./../TableStyledComponents";
import { AccountRow } from "sdk/dist/accounts_table_pb";
import { accTableStore } from "../../../../stores/acc_table-store";
import { accTableFilters } from "../../../../stores/filters_acc_table-store";
import { observer } from "mobx-react";
import { BeatLoader } from "react-spinners";
import { colors } from "../../../../util/consts";
import * as dfns from "date-fns";
import * as filter from "./AccDataTableFilters";
import { TablePagination } from "../AccDataTablePagination";
import { accItemTypeToText } from "../../../../util/account-items";
import { AccountItem } from "sdk/dist/account_items_pb";
import { toDateTz } from "../../../../util/timestamp";

interface Props {
  locationId: string;
  onClick: (accRow: AccountRow.AsObject) => void;
  columns?: any;
}

interface TableProps {
  fetchData: ({ pageSize, pageIndex, filters }: FilterProps) => void;
  isLoading: boolean;
  totalRowCount: number;
  data: AccountRow.AsObject[];
}

type TProps = Props & TableProps;

interface FilterProps {
  pageSize: number;
  pageIndex: number;
  filters: Filters<AccountRow.AsObject>;
}

export const AccDataTable: React.FC<Props> = observer((props: Props) => {
  const columns: Column<AccountRow.AsObject>[] = useMemo(
    () => [
      {
        Header: "Booking/Purchase Date",
        accessor: "bookingDate",
        Cell: (c) => (
          <styled.DateTimeContainer>
            <styled.DateContent>
              {dfns.format(new Date(c.cell.value), "EEE, dd/MM/yyyy")}
            </styled.DateContent>
          </styled.DateTimeContainer>
        ),
        filter: "lastUpdate",
        Filter: filter.DateFromToColumnFilter
      },
      {
        Header: "Booking/Order No",
        accessor: "friendlyId",
        filter: "bookingId",
        Filter: filter.DefaultColumnFilter
      },
      {
        Header: "Type",
        accessor: "bookingType",
        filter: "bookingType",
        Filter: filter.SelectColumnFilter
      },
      {
        Header: "Funding Type",
        accessor: "fundType",
        filter: "fundType",
        Filter: filter.SelectColumnFilter
      },
      {
        Header: "Provider/Seller",
        accessor: "provider",
        Cell: (c) => (
          <styled.StyleUserBlock
            firstName={c.cell.value.split(" ")[0]}
            lastName={c.cell.value.split(" ")[1]}
            size={"small"}
          />
        ),
        filter: "provider",
        Filter: filter.DefaultColumnFilter
      },
      {
        Header: "Client",
        accessor: "client",
        Cell: (c) =>
          c.cell.value === undefined || c.cell.value === "" ? (
            // <>{c.row.original.noClients} Clients</>
            <styled.StyleUserBlock
              firstName={c.row.original.noClients + " Clients"}
              lastName={" "}
              size={"small"}
            />
          ) : (
            <styled.StyleUserBlock
              firstName={c.cell.value.split(" ")[0]}
              lastName={c.cell.value.split(" ")[1]}
              size={"small"}
            />
          ),
        filter: "client",
        Filter: filter.DefaultColumnFilter
      },
      {
        Header: "Fee",
        accessor: "fee",
        Cell: (c) => "$" + c.cell.value
        // Filter: filter.NumberRangeColumnFilter,
        // filter: "between"
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (c) => styled.RenderPill(c.cell.value),
        Filter: filter.SelectColumnFilter,
        filter: "status"
      },
      {
        Header: "Acc Type",
        accessor: "accItemType",
        Cell: (c) => accItemTypeToText(c.cell.value),
        filter: "accItemType",
        Filter: filter.SelectColumnFilter
      },
      // Next 2 lines is for creating dummy columns to store calendar value
      { accessor: "bookingDateFrom" },
      { accessor: "bookingDateTo" }
    ],
    []
  );

  const [isLoading, toggleLoadingState] = useState(false);
  const [data, storeData] = useState<AccountRow.AsObject[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    accTableFilters.setLocationId(props.locationId!! ? props.locationId : "");
  }, [props.locationId]);

  useEffect(() => {
    (async () => {
      const accItemType = accTableStore.getAccType();
      await accTableFilters.loadFilters(accItemType[0]);
    })();
  }, [accTableStore.getActiveTab()]);

  const fetchData = useCallback(async ({ pageSize, pageIndex, filters }: FilterProps) => {
    // This will get called when the table needs new data
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      // Set the loading state
      toggleLoadingState(true);
      await accTableStore
        .loadFromLocation(filters, pageSize, pageIndex)
        .then((response) => {
          response.accountrowsList.map((row, i) => {
            // convert bk Datetimes to user browser timezone before sending data to table
            const bkDateTz = toDateTz(new Date(row.bookingDate));
            response.accountrowsList[i].bookingDate = bkDateTz.format("MM/DD/YYYY");
          });
          storeData(response.accountrowsList);
          setRowCount(response.totalRowCount);
        })
        .catch(() => {
          storeData([]);
          setRowCount(0);
        });
      toggleLoadingState(false);
    }
  }, []);

  return (
    <styled.TableContainer>
      <styled.TableStyle tableSize={"100%"} width={"100%"}>
        <Table
          columns={columns}
          locationId={props.locationId}
          data={data}
          totalRowCount={rowCount}
          fetchData={fetchData}
          onClick={props.onClick}
          isLoading={isLoading}
        />
      </styled.TableStyle>
    </styled.TableContainer>
  );
});

const Table: React.FC<TProps> = observer((props) => {
  const { columns, data, isLoading, totalRowCount } = props;
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    // Get the state from the instance
    state: { pageSize, pageIndex, filters }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns: ["accItemType", "bookingDateFrom", "bookingDateTo"]
      },
      pageCount: totalRowCount,
      // Do not auto update table states automatically
      manualPagination: true,
      manualFilters: true,
      autoResetPage: true,
      autoResetFilters: false,
      autoResetGlobalFilter: false
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  // Debounce our fetchData call for 1000ms = 1s
  const onFetchDataDebounced = useAsyncDebounce(props.fetchData, 1000);
  useEffect(() => {
    accTableFilters.setSelectedFilters(filters);
    onFetchDataDebounced({ pageSize, pageIndex, filters });
  }, [pageSize, pageIndex, filters, onFetchDataDebounced]);

  // When AccType changes in Mobx store
  // * Reset all filter values
  // * Bring user back to 1st page
  useEffect(() => {
    gotoPage(0);
    setAllFilters([]);
    accTableFilters.resetTableFilters();
  }, [accTableStore.getActiveTab()]);

  // restart tabs when mounting component, to avoid issues when
  // jumping between org and loc account pages.
  useEffect(() => {
    accTableStore.setNewAccType(AccountItem.Type.ACC_ITEM_TYPE_UNSPECIFIED);
    accTableStore.setActiveTab(0);
  }, []);

  // Render the UI for your table
  return (
    <>
      <thead>
        {headerGroups.map((headerGroup) => (
          <styled.HeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <styled.HeaderColumn
                key={column.id}
                twd={styled.WidthPerColumn(column.id)}
                zIndex={column.id === "bookingDate" ? 3 : null}
              >
                <styled.CardContainerHeader key={column.id} alignItems={"start"}>
                  {column.id === "bookingDate" ? (
                    <div style={{ width: "200px" }}>{column.Header}</div>
                  ) : (
                    <>{column.Header}</>
                  )}
                  <div style={{ marginTop: "5px" }}>
                    {column.filter ? column.render("Filter") : null}
                  </div>
                </styled.CardContainerHeader>
              </styled.HeaderColumn>
            ))}
          </styled.HeaderRow>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {isLoading ? (
          <styled.HeaderRow>
            <styled.HeaderColumn colSpan={GetColumnSpanNumber(columns)}>
              <BeatLoader
                css={styled.OverrideSpinner}
                size={15}
                color={colors.tertiary.main}
                loading={isLoading}
              />
            </styled.HeaderColumn>
          </styled.HeaderRow>
        ) : data.length > 0 ? (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <styled.Row {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <React.Fragment key={i}>
                      {
                        <styled.Column
                          {...cell.getCellProps()}
                          select={!!cell}
                          onClick={() => {
                            props.onClick(row.original);
                          }}
                        >
                          <styled.Label>{cell.render("Cell")}</styled.Label>
                        </styled.Column>
                      }
                    </React.Fragment>
                  );
                })}
              </styled.Row>
            );
          })
        ) : (
          <tr>
            <td colSpan={GetColumnSpanNumber(columns)} style={{ textAlign: "center" }}>
              Not Items found
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <styled.HeaderRow>
          <TablePagination
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            totalRowCount={totalRowCount}
          />
        </styled.HeaderRow>
      </tfoot>
    </>
  );
});

const GetColumnSpanNumber = (columns: []): number => {
  const colSpan = Math.round(window.innerWidth / 180);
  if (colSpan > columns.length) {
    return columns.length;
  } else {
    return colSpan;
  }
};
