
import { jsx, css, keyframes } from "@emotion/core";
import { PureComponent } from "react";
import PropTypes from "prop-types";

const keyCircleRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const keyCircleDash = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
`;

const sizeProps = {
  small: {
    width: "20",
    height: "20",
  },
  medium: {
    width: "24",
    height: "24",
  },
  large: {
    width: "28",
    height: "28",
  }
};

export default class Loading extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    thickness: PropTypes.number,
    color: PropTypes.string,
  }

  static defaultProps = {
    size: "medium",
    thickness: 3.6,
    color: "#FFF"
  }

  render() {
    const { size, thickness, color, ...otherProps } = this.props;
    return (
      <svg
        css={css`
          animation: ${keyCircleRotate} 1.4s linear infinite;
        `}
        role="loading"
        viewBox="22 22 44 44"
        {...sizeProps[size]}
        {...otherProps}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          css={css`
            animation: ${keyCircleDash} 1.4s ease-in-out infinite;
            stroke-dasharray: 80px, 200px;
            stroke-dashoffset: 0px;
          `}
          stroke={color}
          cx={44}
          cy={44}
          r={(44 - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </svg>
    );
  }
}

