import { metadata, rpc } from "../grpc";
import { PurchaseRequest } from "sdk/dist/products_pb";

class PurchaseService {
  async confirmPurchase(id: string, quantity: number) {
    const req = new PurchaseRequest();
    req.setProductId(id);
    req.setQuantity(quantity);

    const res = await rpc.products.confirmPurchase(req, metadata());
    return res.toObject();
  }

  async getPreConfirmData(id: string, quantity: number) {
    const req = new PurchaseRequest();
    req.setProductId(id);
    req.setQuantity(quantity);

    const res = await rpc.products.getPreConfirmData(req, metadata());
    return res.toObject();
  }
}

export const sharedPurchaseService = new PurchaseService();
