import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ActivityIndicator } from "./ActivityIndicator";
import { FlexBox, FlexItem } from "./FlexBox";
import { Typography } from "./Typography";

interface IProps {
  isDisabled?: boolean;
  isSubmitting?: boolean;
  fullWidth?: boolean;
  onClick: (() => void) | (() => Promise<void>);
  variant?:
    | "primary"
    | "secondary"
    | "primary-gradient"
    | "inverted"
    | "transparent"
    | "tertiary"
    | "blue";
  rounded?: "sm" | "md" | "full";
  children: React.ReactNode;
  type?: "submit";
  size?: "sm" | "md" | "lg";
}

export const AsyncButton = (props: IProps) => {
  const [isLoading, setLoadingState] = useState(false);

  useEffect(() => {
    setLoadingState(!!props.isSubmitting);
  }, [props.isSubmitting]);

  return (
    <button
      className={classNames(
        "font-bold",
        getColorIntent(props.variant),
        getButtonShape(props.rounded),
        isLoading || !!props.isDisabled ? "disabled:opacity-50" : "",
        !!props.isDisabled ? "cursor-not-allowed" : "",
        isLoading ? " cursor-wait" : "",
        props.fullWidth && "w-full",
        computeSize(props.size)
      )}
      onClick={async () => {
        // NOTE: UI crashes if non promise onClick props is executed in try catch
        // Occurring due to redux onClick function
        if (props.onClick instanceof Promise) {
          try {
            setLoadingState(true);
            await props.onClick();
          } catch {
            // Log error or somethings else
          } finally {
            setLoadingState(false);
          }
        } else {
          setLoadingState(true);
          props.onClick();
          setLoadingState(false);
        }
      }}
      disabled={isLoading || props.isDisabled}
      type={props.type}
    >
      <FlexBox alignItems="center" justifyContent="center">
        {isLoading && (
          <FlexItem>
            <ActivityIndicator size="sm" />
          </FlexItem>
        )}
        <FlexItem>{props.children}</FlexItem>
      </FlexBox>
    </button>
  );
};

const PrimaryButton = (props: Omit<IProps, "spacing" | "variant">) => {
  return (
    <AsyncButton {...props} variant="primary">
      <Typography.H4 noPadding uppercase weight="bold">
        {props.children}
      </Typography.H4>
    </AsyncButton>
  );
};

const SecondaryButton = (props: Omit<IProps, "spacing" | "variant">) => {
  return (
    <AsyncButton variant="secondary" {...props}>
      <Typography.Label noPadding uppercase weight="bold">
        {props.children}
      </Typography.Label>
    </AsyncButton>
  );
};

const PrimaryGradientButton = (props: Omit<IProps, "spacing" | "variant">) => {
  return (
    <AsyncButton variant="primary-gradient" {...props}>
      <Typography.H4 noPadding uppercase weight="bold" color="neutral">
        {props.children}
      </Typography.H4>
    </AsyncButton>
  );
};

const ActionButton = (props: Omit<IProps, "size" | "variant">) => {
  return (
    <AsyncButton variant="transparent" {...props}>
      <Typography.Label noPadding weight="bold" color="primary" underline>
        {props.children}
      </Typography.Label>
    </AsyncButton>
  );
};

AsyncButton.Primary = PrimaryButton;
AsyncButton.PrimaryGradient = PrimaryGradientButton;
AsyncButton.Secondary = SecondaryButton;
AsyncButton.Action = ActionButton;

const getColorIntent = (color?: IProps["variant"]) => {
  switch (color) {
    case "primary":
      return "text-white bg-primary-900 hover:bg-primary-700";
    case "secondary":
      return "bg-secondary-200 hover:bg-primary-100";
    case "inverted":
      return "bg-white text-primary-900 hover:bg-primary-100";
    case "primary-gradient":
      return "text-white bg-gradient-to-r from-primary-300 to-primary-900 hover:bg-primary-900 hover:from-primary-900 hover:to-primary-900";
    case "transparent":
      return "bg-transparent";
    case "tertiary":
      return "bg-tertiary-200 hover:bg-tertiary-100 hover:opacity-80 text-white";
    case "blue":
      return "bg-blue-400 hover:bg-blue-300";
    default:
      return "text-grey-700 bg-primary-100";
  }
};

const getButtonShape = (rounded?: IProps["rounded"]) => {
  switch (rounded) {
    case "sm":
      return "rounded-sm";
    case "md":
      return "rounded-md";
    case "full":
      return "rounded-full";
    default:
      return "";
  }
};

const computeSize = (rounded?: IProps["size"]) => {
  switch (rounded) {
    case "sm":
      return "px-1 py-4";
    case "md":
      return "px-7 py-3";
    case "lg":
      return "px-10 py-2";
    default:
      return "px-0 py-0";
  }
};
