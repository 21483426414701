import React, { Component } from "react";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import Card from "./Card";
import uuid from "uuid";

export interface dataItemType {
  title: string;
  statistic: number;
}
interface Prop {
  items: Array<dataItemType>;
}
class DataArea extends Component<Prop> {
  render() {
    const { items } = this.props;
    return (
      <DataAreaDiv>
        {/* <Card title="total appointments" data={metric.getTotalAppointment()}></Card> */}
        {/* insight_metrics_service.go sets unimplemented features to -999 */}
        {items.map((i) =>
          i.statistic != -999 ? (
            <Card title={i.title} data={i.statistic} key={uuid.v4()}></Card>
          ) : (
            <React.Fragment key={uuid.v4()}></React.Fragment>
          )
        )}
      </DataAreaDiv>
    );
  }
}

export default DataArea;

const DataAreaDiv = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
